import React, {Fragment, memo, useContext, useEffect, useState} from "react";
import styled, { css } from "styled-components";
import { BodyText, BodyTitle } from "../../../../../../components/ReusableComponents/Text/Text";
import { initial } from "lodash";
import { BodyCSS } from "../../../../../../components/ReusableComponents/Card-Body/BodyCSS";
import { PrimaryButton, SecondaryButton } from "../../../../../../components/ReusableComponents/Button/Button";
import moment from "moment";
import { PriceAdjustContext } from "../../../../PriceAdjustment";
import { getOverrideMinstay, getOverridePrice } from "../../../../components/utils";

const RightClickMenuDiv = styled.button`
  width: fit-content;
  height: 30px;
  background: #283334;
  outline: none; 
  border: none;
  position: fixed;
  border-radius: 4px;
  left: ${({left}) => left};
  top: ${({top}) => top};
  cursor: pointer;
  z-index: 2;
  text-align: center;
  display: ${({$display}) => $display};
  padding: 0 8px;

  &:hover {
    background: ${({theme}) => theme.quibbleBlue};
  }
`;

const LabelText = styled(BodyText)`
  color: white;
`;

const OverrideModal = styled.div`
  ${BodyCSS}
  width: 268px;
  height: 240px;
  position: fixed;
  left: ${({left}) => left};
  top: ${({top}) => top};
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmModalHeader = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 16px;
`;

const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  background: none; 
  cursor: pointer;
  margin-bottom: 8px;
`;

const TextBody = styled.div`
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;
const ButtonDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  gap: 16px;
`;

const ButtonCSS = css`
  width: 110px;
  height: 44px;
`;

const OverrideInput = styled.input`
  width: 236px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  font-size: ${({theme}) => theme.s4};
  border: 1px solid #D9D9D9;
  text-align: center;
`;


const ConfirmButton = styled(PrimaryButton)`
  ${ButtonCSS}
`;

const BackButton = styled(SecondaryButton)`
  ${ButtonCSS}
`;

const MenuLabel = {
  Price: 'Price Override',
  "Min Stay": 'Min Stay Override',
}

const MenuTitle = {
  Price: 'Override Price',
  "Min Stay": 'Override Min Stay',
}

const currencySymbol = localStorage.getItem("currency");

const inputLabel = {
  Price:  `Price (${currencySymbol})`,
  "Min Stay": 'Minimum Stay',
}

const changesLabel = {
  Price:  `priceOverride`,
  "Min Stay": 'minStayOverride',
}

const RightClickMenu = ({menuProps, onAppyChanges, setSelectedDaysFunc}) => {
  const {x, y, data, isVisible, label} = menuProps;
  const [isContextVisible, setIsContextVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState(0);

  const {priceAdjust, adjustmentMenu, activeMarket, activeProperty} = useContext(PriceAdjustContext);


  useEffect(() => {
    const overrideValue = label === 'Price' ? getOverridePrice(priceAdjust, activeProperty,data) : getOverrideMinstay(priceAdjust, adjustmentMenu, activeMarket, activeProperty, data);
    if (overrideValue) {
      setInputValue(Number(overrideValue));
      return;
    };
    const value = label === 'Price' ? data?.price : data?.minStay;
    setInputValue(value)
  },[data, label, activeMarket, activeProperty ,adjustmentMenu, priceAdjust]);


  useEffect(() => {
    setIsContextVisible(isVisible);
  },[isVisible])

  const onShowModal = () => {
    setSelectedDaysFunc([data.date])
    setShowModal(true);
  };
 
  const onCancel = () => {
    setShowModal(false)
  };

  const onInputChange = (e) => {
    e.preventDefault();
    setInputValue(Math.abs(e.target.value));
  };

  const onOverride = () => {
    onAppyChanges(inputValue, changesLabel[label]);
    setShowModal(false)
  };

  const handleOnKeyPress = (e) => {
    const keyCode = e.which || e.charCode;
    if (keyCode === 13) {
      onAppyChanges(inputValue, changesLabel[label]);
      setShowModal(false)
    }
  };
  
  return (
    <Fragment>
      <RightClickMenuDiv onClick={onShowModal} $display={isContextVisible ? initial : 'none'} top={`${y}px`} left={`${x}px`}>
        <LabelText>{MenuLabel[label]}</LabelText>
      </RightClickMenuDiv> 
      {showModal && 
      <OverrideModal  top={`${y}px`} left={`${x}px`}>
        <ConfirmModalHeader>
          <BodyTitle margin="0px">{MenuTitle[label]}</BodyTitle>
          <CloseButton onClick={onCancel}><BodyTitle margin="0px"> x </BodyTitle></CloseButton>
        </ConfirmModalHeader>
        <TextBody>
            <BodyText>
              Date: {moment(data.date,'YYYY-MM-DD').format("MMMM DD, YYYY")}
            </BodyText>
            <BodyText>
              {inputLabel[label]}
            </BodyText>
        </TextBody>
        <OverrideInput value={inputValue} type='number' onChange={onInputChange} onKeyDown={handleOnKeyPress}/>
        <ButtonDiv>
          <BackButton onClick={onCancel}>
            Cancel
          </BackButton>
          <ConfirmButton onClick={onOverride}>
            Override
          </ConfirmButton>
        </ButtonDiv>
      </OverrideModal> 
      }
    </Fragment>
  )
}

export default memo(RightClickMenu);