import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
/** Custom Import */
import errorsPages from "../errorsPages/index";
import MyAccount from "./MyAccount/MyAccount";
import MyAccountPlan from './MyAccount/Plan';
import MyAccountPayment from './MyAccount/Payment';
import EditProfile from './MyAccount/EditProfile';
import RatesAdvisor from "../myPortfolio/index";

const SocialApps = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}profile`}/>
    <Route path={`${match.url}/myPortfolio`} component={RatesAdvisor} />
    <Route path={`${match.url}/reset-password`} component={asyncComponent(() => import('./ResetPassword'))}/>
    <Route exact path={`${match.url}/profile`} component={MyAccount}/>
    <Route exact path={`${match.url}/profile/plan`} component={MyAccountPlan}/>
    <Route exact path={`${match.url}/profile/plan/payment`} component={MyAccountPayment}/>
    <Route exact path={`${match.url}/profile/edit-profile`} component={EditProfile}/> 
    <Route path={`${match.url}/connection`} component={asyncComponent(() => import('./Connection'))}/>
    <Route exact component={errorsPages}/>
  </Switch>
);

export default SocialApps;
