import React from "react";
import CircularProgress from "../components/CircularProgress";
const OwnerRez = () => {
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container body-common-main">
        <div className="common-body-box body-center">
          <div className="gx-app-login-main-content">
            <div className="gx-loader-view">
              <CircularProgress/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerRez;
