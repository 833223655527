import React from 'react';
import styled from 'styled-components';
import { BodyTitle } from '../../../../../../components/ReusableComponents/Text/Text';
import CheckBox from '../../../../../../components/ReusableComponents/Checkbox/CheckBox';

const OptimizeGraphHeaderDiv = styled.div`
  width: 1066px;
  height: fit-content;
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OptionDiv = styled.div`
  width: fit-content;
  height: fit-content; 
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const OptimizeGraphHeader = ({showAdr, setShowAdr, showOptimize, setShowOptimize, graphMultiplier, setGraphMultiplier, showComps, setShowComps}) => {
  const onOptimizeChange = () => {
    setShowOptimize((prev) => !prev);
  };

  const onGraphMultiplerChange = () => {
    setGraphMultiplier((prev) => !prev);
  };

  const onAdrChange = () => {
    setShowAdr((prev) => !prev);
  };

  const onCompsChange = () => {
    setShowComps((prev) => !prev);
  }; 

  return (
    <OptimizeGraphHeaderDiv>
      <BodyTitle>
        Price Rates
      </BodyTitle>
      <OptionDiv>
        <span>Show: &nbsp;</span>
        <CheckBox checked={graphMultiplier} onCheckBoxChange={onGraphMultiplerChange}/>
          Multiplier Graph
          &nbsp;
          &nbsp;
        <CheckBox checked={showAdr} onCheckBoxChange={onAdrChange} />
          ADR
          &nbsp;
          &nbsp;
        <CheckBox checked={showOptimize} onCheckBoxChange={onOptimizeChange} />
          Optimized Price
          &nbsp;
          &nbsp;
        <CheckBox checked={showComps} onCheckBoxChange={onCompsChange} />
          Comps
          &nbsp;
          &nbsp;
      </OptionDiv>
    </OptimizeGraphHeaderDiv>
  )
}

export default OptimizeGraphHeader;