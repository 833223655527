import React, { useCallback } from 'react';
import styled from 'styled-components';
import { currencyFormatter } from "util/helper";

const BarDiv = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
`;


const CurrentDiv = styled.div`
  width: ${({width}) => width};
  height: 100%;
  background: ${({ backgroundColor, theme }) => backgroundColor || theme.quibbleYellow};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 4px;

  color: ${({theme}) => theme.hoverBlue};
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s2};
`;



const ExtendRenderBar = ({data, type, backgroundColor}) => {
  const currentWidth = Number((data * 100).toFixed(0));
  
  const renderBar = useCallback(() => {

    if (type === 'occupancy') {
      return (
        <CurrentDiv width={`${currentWidth}%`} backgroundColor={backgroundColor}>
          {currentWidth > 10 && `${currentWidth}%`}
        </CurrentDiv>
      )
    }

    return (
     <CurrentDiv width={`${currentWidth}%`} backgroundColor={backgroundColor}>
          {currentWidth > 10 && currencyFormatter(data)}
        </CurrentDiv>
    )


  },[ currentWidth, data, type, backgroundColor])


  return (
    <BarDiv>
      {renderBar()}
    </BarDiv>
  )
}

export default ExtendRenderBar;
