import { useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { useSelector } from 'react-redux';
import ExtendRenderBar from '../../../marketAnalytics/sharedComponents/MarketPropertyTable/ExtendRenderBar';
import Multiplier from './subComponents/Multiplier';
import moment from 'moment';
import { cloneDeep, remove, isNil } from 'lodash';



const NameLink = styled(BodyText)`
  margin: 0px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.quibbleBlue};
  }
`;

const EventsDetailsTableDiv = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 1776px;
  max-height: 706px;
  margin: 20px 0 0 0;
`;

const TableDiv = styled.div`
  min-width: 1056px;
  height: fit-content;
  max-height: 662px;
  `;

  const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

  const Loader = styled.div`
  border: 5px solid #f3f3f3;
  border-radius:50%;
  border-top: 5px solid ${({ theme }) => theme.quibbleBlue};
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
  margin-left: 30px;
  `;


const EventsDetailsTable = ({ searchString, onSelectEvent, multiplier, multiplierLoading}) => {
  const history = useHistory();
  const [selectedList, setSelectedList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);

  const { eventsTable, isFetchingEventsTable } = useSelector(({ events }) => events);

  const columns = [
    {
      label: 'name',
      width: '250px',
      align: 'left',
      customRender: (data) => (
        <NameLink onClick={(e) => {
          e.preventDefault();
          history.push({
            pathname: '/revenue/events-pricing/event',
            state: { eventId: data?._id }
          })
        }}>{data.name}</NameLink>
      ),
    },
    {
      label: 'date range',
      width: '200px',
      align: 'left',

      customRender: (data) => {
        const startDate = new Date(data?.startDate);
        const endDate = new Date(data?.endDate);

        const formatDateString = (date) => {
          return moment.utc(date).format('MM-DD-YYYY');
        };

        const formattedStartDate = formatDateString(startDate);
        const formattedEndDate = formatDateString(endDate);

        return `${formattedStartDate} - ${formattedEndDate}`;
      },
    },
    {
      label: 'occupancy',
      width: '80px',
      align: 'left',
      dataIndex: 'occ',
      customRender: (data) => <ExtendRenderBar data={data} type="occupancy" backgroundColor="#2ECADB" />
    },
    {
      label: 'minstay',
      width: '50px',
      align: 'right',
      dataIndex: 'minStay',
      customRender: (data) => (`${data}`)
    },
    {
      label: 'occ gap',
      width: '100px',
      align: 'right',
      dataIndex: 'occGap',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),
    },
    {
      label: 'status',
      width: '50px',
      align: 'center',
      dataIndex: 'status',
      customRender: (data) => {
        if (data?.toLowerCase() === 'active') {
          return <span style={{ color: '#4F951B' }}>Active</span>
        } else if (data?.toLowerCase() === 'disabled') {
          return <span style={{ color: '#F35A22' }}>Disabled</span>
        }
      }

    },
    {
      label: 'multiplier',
      width: '50px',
      align: 'center',
      customRender: (data) => <Multiplier data={data} selected={selectedList} multiplier={multiplier} />

    },
    {
      // label: <CaretDownOutlined/>, 
      width: '40px',
      align: 'center',
      customRender: (data) => {
        let eventMultiplier = !isNil(data?.multiplier) ? data.multiplier : 0;
        if(multiplierLoading===true) return <Loader/>
        if (selectedList.includes(data._id)) {
          const additionalMultiplier = multiplier ?? 0;
          eventMultiplier = eventMultiplier + additionalMultiplier;
        }
        return eventMultiplier ?? '-';
      }
    }
  ];

  const filterEventsTable = eventsTable.filter((data) => {
    const includesSearchString = data?.name?.toLowerCase().includes(searchString.toLowerCase());
    return includesSearchString;
  });

  const onTableRowclick = (value) => {
    let selected = cloneDeep(selectedList);
    let selectedEvts = cloneDeep(selectedEvent);

    const isSelected = selected.includes(value);

    if (isSelected) {
      remove(selected, s => s === value);
      remove(selectedEvts, s => s._id === value);
    } else {
      selected.push(value);
      selectedEvts.push(filterEventsTable?.filter(s => s._id === value)[0])
    }

    setSelectedList(selected);
    setSelectedEvent(selectedEvts);
    onSelectEvent(selectedEvts);
  };

  const onCheckBoxValues = useCallback((values) => {
    setCheckedValues(values);
  }, []);

  return (
    <EventsDetailsTableDiv>
      <TableDiv>
        <QuibbleTable
          columns={columns}
          withCheckBox={true}
          data={filterEventsTable}
          style={{ cursor: 'pointer' }}
          pagination={true}
          paginationSize={10}
          isLoading={isFetchingEventsTable}
          onCheckBoxChange={onCheckBoxValues}
          checkedRows={checkedValues}
          onRowClick={onTableRowclick}
          selectedList={selectedList}
          keyData="_id"
          columnFilter={false}
        />
      </TableDiv>
    </EventsDetailsTableDiv>
  )
}

export default EventsDetailsTable;
