import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';


const HostfullyDiv = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HostfullyButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;


const Hostfully = ({onSubmitForm }) => {

  const [hostfully, setHostfully] = useState('');
  
  const onFormChange = (_, {value}) => {
    setHostfully(value);
  };

  const onTokenSubmit = () => {
    const values = {
      agenciesId: hostfully,
    }
    onSubmitForm(values)
  };


  return (
    <HostfullyDiv>
      <FormItemInput formKey="agenciesId" value={hostfully}  label="Agencies ID" onChangeFunc={onFormChange} />
      <HostfullyButton disabled={!hostfully.length} onClick={onTokenSubmit}> Connect </HostfullyButton>
    </HostfullyDiv>
  )
}

export default Hostfully;