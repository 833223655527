import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
	hideMessage,
	// showAuthLoader,
	// userResendEmail
} from "../appRedux/actions";
import OTPInput from 'react-otp-input'
import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import SignupCard from "../components/SignupCard";
import AuthLogo from "../components/AuthLogo";
import AuthLoader from "../components/AuthLoader";
import useShowMessage from "../customHooks/useShowMessage";
import useMutation from '../components/customHook/useMutation';
import { useHistory } from 'react-router-dom';


// const FormItem = Form.Item;
const EmailVerification = (props) => {
	const dispatch = useDispatch();
	const { loader, alertMessage, showMessage, alertMessageType } = useSelector(({ auth }) => auth);
	const [otp, setOtp] = useState("")
	const history = useHistory();
	const queryString = require('query-string');

	const parsed = queryString.parse(props.location.search);
	const { request, loading: resendingOtp } = useMutation({
		method: "POST",
		url: '/api/resend_resend_otp',
		onComplete: (data) => {
			message.success('Otp Resent successfully')
			console.log(data)
		},
		onError: (e) => {
			message.error('Invalid verification request. Please check your email.')
			// console.log("Error", e)
		}
	})

	const { request: verifyOtp, loading: verifyingOtp } = useMutation({
		method: "POST",
		url: '/api/email_verification_using_otp',
		onComplete: (data) => {
			history.push(`/pms_detail?string=${data?.result?.userId}&status=success&message=email-verified'`);
		},
		onError: (e) => {
			message.error('Invalid otp verification. Please check your email.')
			// console.log("Error", e)
		}
	})

	const handleVerifyOtp = () => {
		const payload = { otp: Number(otp), otpToken: parsed.token }
		verifyOtp(payload)
	}

	useShowMessage(showMessage, alertMessageType, alertMessage);

	useEffect(() => {
		if (showMessage) {
			setTimeout(() => {
				dispatch(hideMessage());
			}, 100);
		}

		if (parsed.status === 'error') {
			message.error('This link has been expired.')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showMessage, parsed.status]);

	const handleOTPChange = (otp) => {
		setOtp(otp);
	};

	// const onFinish = values => {
	// 	const { validate_string } = values;
	// 	if (!validate_string) {
	// 		message.error('Invalid verification request. Please check your email.')
	// 		return;
	// 	}
	// 	dispatch(showAuthLoader());
	// 	dispatch(userResendEmail(values));
	// };

	const handleRequestResend = () => {
		request({ token: parsed.token })
	}

	return (
		<div className="app-signin-wrap signup">
			<SignupCard current={ 1 } title="Account Validation"/>
			<div className="app-signin-container verification">
				<div className="app-signin-form-container verification">
					<h1 className="app-text signin-logotext verification margin-bottm--medium" style={ { marginBottom: 16 } }>
						<IntlMessages id="app.userAuth.emailVerificationMessage"/>
					</h1>
					<span className="otp_container_input_style">
              <OTPInput
	              inputStyle="otp_input_style"
	              numInputs={ 4 }
	              onChange={ handleOTPChange }
	              renderSeparator={ <span>*</span> }
	              value={ otp }
	              inputType="password"
	              renderInput={ (props) => <input { ...props } /> }
	              shouldAutoFocus
              />
                <Button
	                loading={ resendingOtp }
	                disabled={ resendingOtp }
	                onClick={ handleRequestResend }
	                size="middle" colorLink="#000" type="text" style={ { alignSelf: "flex-end", position: 'relative' } }>
                  { resendingOtp ? 'Please wait...' : 'Resend Email' }
                </Button>
            </span>
					<br/>
					<Button onClick={ handleVerifyOtp } loading={ verifyingOtp } disabled={ verifyingOtp || otp.length < 4 }
					        className="app-button-primary signup verification" htmlType="submit">
						{ verifyingOtp ? "" : "Submit" }
					</Button>
					<br/>
					<h1 className="app-text signin-logotext verification">
						If you did not received the email, we can resend the email by clicking the link beside the otp box.
					</h1>
					{/*<Form*/ }
					{/*    initialValues={{*/ }
					{/*      remember: true,*/ }
					{/*      // validate_string: parsed.string,*/ }
					{/*      validate_string: 'xxx',*/ }
					{/*    }}*/ }
					{/*    name="emailVerification"*/ }
					{/*    onFinish={onFinish}*/ }
					{/*    className="form-signin"*/ }
					{/*  >*/ }
					{/*    <FormItem name="validate_string">*/ }
					{/*      <Input type="hidden" placeholder="validate string" />*/ }
					{/*    </FormItem>*/ }
					{/*    <FormItem*/ }
					{/*      className="form-item-signin verification"*/ }
					{/*    >*/ }
					{/*      <div className="form-item-div">*/ }
					{/*        <Button className="app-button-primary signup verification" htmlType="submit">*/ }
					{/*          Resend Email*/ }
					{/*        </Button>*/ }
					{/*      </div>*/ }
					{/*    </FormItem>*/ }
					{/*</Form>*/ }
					<AuthLoader loader={ loader }/>
				</div>
				<AuthLogo/>
			</div>
		</div>
	);
};


export default EmailVerification;
