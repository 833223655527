import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';
import _ from 'lodash';
import styled from "styled-components";

import { CardCSS, CardTitle } from "./CommonCSS";
import Chart from './subComponents/Chart';
import ChartHeader from './subComponents/ChartHeader';

import { getPortfolioAnalytics } from '../../../../../appRedux/actions/PortfolioAnalytics';
import SkLoaderOver from "../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver";

const Container = styled.div`
  ${CardCSS}
  width: ${props => props.columns === 2 ? 'calc(50% - 1rem)' : 'calc(100% - 1rem)'};
  position: relative;
`;

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.columns === 2 ? 'calc(100% - 169.5px)' : 'calc(90% - 169.5px)'};
  margin: 1rem auto 2rem auto;

  span {
    font-weight: 300;
    padding-right: 12px;
  }
`;

const ChartContainer = ({
  title,
  yAxes,
  xAxis,
  bars,
  lines,
  labels,
  metric,
  yLabelLeft,
  yLabelRight,
  year,
  columns,
  domain,
  tooltip,
  yTickFormatterLeft,
  yTickFormatterRight,
  revenueFormatter,
  formatOcc,
  formatText,
  hideDaysFilter,
  propertyId,
  hasAdjustPricingButton,
  hideSameStoreFilter
}) => {
  const dispatch = useDispatch();
  const metrics = useSelector((state) => state.portfolioAnalytics);
  const viewType = useSelector((state) => state.common.filters.viewType);

  const [view, setView] = useState('monthly');
  const [sameStore, setSameStore] = useState(null);

  useEffect(() => {
    dispatch(getPortfolioAnalytics(metric, year, view, sameStore, propertyId, viewType))
  }, [dispatch, view, metric, sameStore, year, propertyId, viewType])

  const changeView = (vw) => {
    setView(vw);
  }

  const changeSameStore = () => {
    if (!sameStore) setSameStore(true);
    else setSameStore(null);
  }

  return (
    <Container columns={columns}>
      <ContainerHeader columns={columns}>
        <CardTitle>{title}</CardTitle>
        {!hideSameStoreFilter && <span>Show same store only</span>}
        {!hideSameStoreFilter && <Switch checked={sameStore} onChange={changeSameStore} loading={false} />}
      </ContainerHeader>
      {metrics[metric]?.length > 0 && (
        <>
          <ChartHeader labels={labels} view={view} onChangeView={changeView} columns={columns} hideDaysFilter = {hideDaysFilter} loading={metrics.loading}/>
          <Chart
            data={_.get(metrics, metric)}
            bars={bars}
            lines={lines}
            yAxes={yAxes}
            xAxis={xAxis}
            view={view}
            yLabelRight={yLabelRight}
            yLabelLeft={yLabelLeft}
            columns={columns}
            domain={domain}
            tooltip={tooltip}
            yTickFormatterLeft={yTickFormatterLeft}
            yTickFormatterRight={yTickFormatterRight}
            revenueFormatter = {revenueFormatter}
            formatText={formatText}
            formatOcc = {formatOcc}
            year={year}
            hasAdjustPricingButton={hasAdjustPricingButton}
          />
        </>
      )} 
      <SkLoaderOver isLoading={!metrics[metric]?.length} />
    </Container>
  )
}

export default ChartContainer;
