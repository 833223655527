import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Chart, } from 'react-charts';

import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle, BodyText } from 'components/ReusableComponents/Text/Text';
import SkLoaderOver from 'components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';

import info from 'assets/images/insights/info.svg'
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import CheckBox from 'components/ReusableComponents/Checkbox/CheckBox';
import MpeIndicator from './MpeIndicator';



const RevenueMetricDiv = styled.div`
  ${BodyCSS}
  width: 890px;
  min-width: 890px;
  height: 100%;
  padding: ${({$padding}) => $padding};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TitleDiv = styled.div`
  width: 100%;
  padding: 0px 42px;
`;

const BlackText = styled(BodyText)`
  margin: 0px;
  color: black;
`;

const GridTextBottom = styled(BodyText)`
  position: absolute;
  font-size: 14px;
  bottom: 16px;
  margin: auto;
`;

const GridTextLeft = styled(BodyText)`
  position: absolute;
  font-size: 14px;
  transform: rotate(-90deg);
  top: 300px;
  left: ${({$sel}) => $sel};
  margin: auto;
`;

const Legend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 32px 0 0 0;
  padding: 0px 42px;
  position: relative;
`;

const LegendLabel = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LabelColor = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 8px;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: ${({$background}) => $background};
`;

const ButtonDiv = styled.div`
  width: fit-content;
  height: 34px;
  display: flex;
  position: absolute;
  top:0px;
  right: 32px;
`;

const SelButton = css`
  width: fit-content;
  height: 100%;
  border: 1px solid #1BB3CD;
  text-align: center;
  background: none;
  color: #1BB3CD;
  font-family: Commissioner;
  font-size: 16px;
  cursor: pointer;
  box-shadow: none;
  font-weight: 300;
  border-radius:${({$bRadius}) => $bRadius};

  &:hover, &.active, &:focus {
    background: #1BB3CD;
    color: white;
  }
`;

const ChartDiv = styled.div`
  width: 800px;
  height: 380px;
  padding: 32px 0;
  position: relative;
  box-sizing: border-box;
`;

const MPEDiv = styled.div`
  ${BodyCSS}
  border-radius: 4px;
  width: 168px;
  height: 1000px;
  max-height: ${({maxHeight}) => maxHeight};
  position: absolute;
  top: 48px;
  right: 48px;
  z-index: 1;
  background: white;
  transition: max-height 0.2s linear;
  gap: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

const MPETitle = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  border: none;
  outline: none;
  background: none;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 0px;
`;

const DownArrow = styled(DownOutlined)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 8px;
  margin: auto;
`;

const UpArrow = styled(UpOutlined)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 8px;
  margin: auto;
`;

const MPETextDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 0px 16px;
`;

const RevenueMetrics = ({revenueMetricsData, isLoading, monthYear, mpeObject, dateType}) => {

  const [chartSel, setChartSel] = useState("revenue");
  const [mpeToggle, setMpeToggle] = useState(false);
  const [mpeCheck, setMpeCheck] = useState('');
  const [leftVal, setLeftVal] = useState('');

  const currencySymbol = localStorage.getItem('currency');
  const currentYear = monthYear.getFullYear();

  useEffect(() => {
    if (mpeCheck.length ) {
      const revenueDiv = document.getElementById(chartSel);
      const chartDiv = revenueDiv.getElementsByClassName("tooltip-wrap");
      const divArray = Array.prototype.slice.call(chartDiv);
      const leftValueArray = divArray ? divArray.map((elem) => {
        return elem.style.left;
      }) : [];

      const leftVal = leftValueArray[0];
      setLeftVal(leftVal);
    };
  }, [mpeCheck, chartSel]);

  useEffect(() => {
    if (monthYear) {
      setMpeCheck('');
      setMpeToggle(false);
    }
  },[monthYear])

  const onSelButtonClick = (value) => {
    setChartSel(value);
    setMpeToggle(false);
    setMpeCheck('');
  };

  const revenueData = {
    revenue: revenueMetricsData.revenueChart,
    rent: revenueMetricsData.rentChart,
    revpar: revenueMetricsData.revparChart,
  };


  const revenueHardMax = Math.ceil(Math.max(...revenueMetricsData.revenueChart.map(item => Math.max(...item.data.map(o => o.secondary)))));
  const rentHardMax = Math.ceil(Math.max(...revenueMetricsData.rentChart.map(item => Math.max(...item.data.map(o => o.secondary)))));
  const revparHardMax = Math.ceil(Math.max(...revenueMetricsData.revparChart.map(item => Math.max(...item.data.map(o => o.secondary)))));
  
  const maxError = Math.max(...Object.values(mpeObject[chartSel]));
  const zeroDayValue = revenueData[chartSel][0]?.data[revenueData[chartSel][0]?.data?.length - 1]?.secondary;
  const maxPercentValue = Number(zeroDayValue) + (Number(zeroDayValue) * maxError.toFixed(2));
  const percentValue = Number(zeroDayValue) + (Number(zeroDayValue) * mpeObject[chartSel]?.[mpeCheck]?.toFixed(2));

  const yMax = {
    revenue: maxPercentValue > revenueHardMax  ? maxPercentValue : revenueHardMax,
    rent: maxPercentValue > rentHardMax  ? maxPercentValue : rentHardMax,
    revpar: maxPercentValue > revparHardMax  ? maxPercentValue : revparHardMax,
  };

  const topVal = (((percentValue/yMax[chartSel]) * 344).toFixed(0)) //344 is the height of the graph div.
  const heigtVal = Math.abs(((Math.abs(percentValue) - Math.abs(zeroDayValue))/yMax[chartSel]) * 344 ).toFixed(0);

  const chartData = {
    revenue: {
      data: revenueData.revenue,
      axes: [
        {
          primary: true,
          type: 'linear',
          id: 'revenueX',
          position: 'bottom',
          hardMax: 365,
          showGrid: true,
          format: d => `Day ${d}`
          
        },
        {
          type: 'linear',
          id: 'revenueY',
          position: 'left',
          hardMax: yMax.revenue,
          hardMin: 0,
          showGrid: true,
          format: d => `${currencySymbol}${d}`
        },
        {
          type: 'linear',
          id: 'xxx',
          position: 'right',
          showGrid: true,
          format: d => ``
        }
      ]
    },
    rent: {
      data: revenueData.rent,
      axes: [
        {
          primary: true,
          type: 'linear',
          id: 'rentX',
          position: 'bottom',
          hardMax: 365,
          showGrid: true,
          format: d => `Day ${d}`
        },
        {
          type: 'linear',
          id: 'rentY',
          position: 'left',
          hardMax: yMax.rent,
          hardMin: 0,
          showGrid: true,
          format: d => `${currencySymbol}${d}`
        },
        {
          type: 'linear',
          id: 'xxx',
          position: 'right',
          showGrid: true,
          format: d => ``
        }
      ]
    },
    revpar: {
      data: revenueData.revpar,
      axes: [
        {
          primary: true,
          type: 'linear',
          id: 'revparX',
          position: 'bottom',
          hardMax: 365,
          hardMin: 0,
          showGrid: true,
          format: d => `Day ${d}`
        },
        {
          type: 'linear',
          id: 'revparY',
          position: 'left',
          hardMax: yMax.revpar,
          hardMin: 0,
          showGrid: true,
          format: d => `${currencySymbol}${d}`
        },
        {
          type: 'linear',
          id: 'xxx',
          position: 'right',
          showGrid: true,
          format: d => ``
        }
      ]
    }
  }

  const textSel = {
    revenue: "Revenue",
    rent: "Rent",
    revpar: "RevPAR"
  }

  const series = (s) => ({
    type: s?.originalSeries?.isForecast ? 'bubble' : 'line',
    showPoints: false,
    precision: 0,
    showGrid: true,
});


  const onMPEClick = () => {
    setMpeToggle((prev) => !prev);
  };


  const toDate = new Date();

  const checkifValidDate = dateType === 'monthly' ? new Date(monthYear.getFullYear(), monthYear.getMonth(),) < new Date(toDate.getFullYear(), toDate.getMonth()) : monthYear.getFullYear() < toDate.getFullYear();

  const onCheckBoxChange = (checked, value) => {
    if (checked){
      setMpeCheck(value);
      return;
    };
      setMpeCheck('');
  }

  return (
    <RevenueMetricDiv $padding={isLoading ? '16px 32px' : '16px 0px'}>
     <GridTextBottom>
        Days to End of Month      
      </GridTextBottom>
      <GridTextLeft $sel={chartSel === 'rent' ? '8px' : '-4px'}>
        {textSel[chartSel]}
      </GridTextLeft>
      <TitleDiv>
        <BodyTitle>
          Revenue Metrics
        </BodyTitle>
      </TitleDiv>
      <Legend>
        <LegendLabel>
          <BlackText>
            Period Comparison
          </BlackText>
          <LabelColor>
            <Circle $background="#C6A1F5"/>
            <BodyText margin="0 8px 0 8px">
              {currentYear - 1}
            </BodyText>
            <Circle $background="#2ECADB"/>
            <BodyText margin="0 0 0 8px">
              {currentYear}
            </BodyText>
          </LabelColor>
        </LegendLabel>
        <ButtonDiv>
          <ButtonWithFunction className={chartSel==='revenue' &&  'active' } value="revenue" ButtonStyle={SelButton} label={"Revenue"} $bRadius="4px 0 0 4px" onClickFunc={onSelButtonClick}/>
          <ButtonWithFunction className={chartSel==='rent' && 'active' } value="rent" ButtonStyle={SelButton} label={"Rent"} $bRadius="0 0 0 0" onClickFunc={onSelButtonClick}/>
          <ButtonWithFunction className={chartSel==='revpar'  && 'active'} value="revpar" ButtonStyle={SelButton} label={"RevPAR"}  $bRadius="0px 4px 4px 0px" onClickFunc={onSelButtonClick}/>
        </ButtonDiv>
          {checkifValidDate ? <MPEDiv maxHeight={mpeToggle ? '208px': '40px'}>
          <MPETitle onClick={onMPEClick}>
            <BodyText>
              MPE
            </BodyText>
            <img src={info}  alt='info'/>
            {mpeToggle ? <UpArrow /> : <DownArrow/>}
          </MPETitle>
          <MPETextDiv>
            <CheckBox checked={mpeCheck === 'mpe15'} onCheckBoxChange={onCheckBoxChange}  value="mpe15"/>
            <BodyText margin="0px">
              15 day = {mpeObject?.[chartSel]?.mpe15?.toFixed(2)}
            </BodyText>
          </MPETextDiv>
          <MPETextDiv>
            <CheckBox checked={mpeCheck === 'mpe30'} onCheckBoxChange={onCheckBoxChange}  value="mpe30" />
            <BodyText margin="0px">
              30 day = {mpeObject?.[chartSel]?.mpe30?.toFixed(2)}
            </BodyText>
          </MPETextDiv>
          <MPETextDiv>
            <CheckBox checked={mpeCheck === 'mpe60'} onCheckBoxChange={onCheckBoxChange} value="mpe60" />
            <BodyText margin="0px">
              60 day = {mpeObject?.[chartSel]?.mpe60?.toFixed(2)}
            </BodyText>
          </MPETextDiv>
          <MPETextDiv>
          <CheckBox  checked={mpeCheck === 'mpe90'} onCheckBoxChange={onCheckBoxChange} value="mpe90" />
            <BodyText margin="0px">
              90 day = {mpeObject?.[chartSel]?.mpe90?.toFixed(2)}
            </BodyText>
          </MPETextDiv>
        </MPEDiv> : null}
      </Legend>
      {!isLoading ?<ChartDiv>
        {chartSel === 'revenue' ? <Chart  id="revenue" data={chartData[chartSel]?.data} series={series} axes={chartData[chartSel]?.axes} grouping="single" tooltip={{ align: "bottom" }} /> : null}
        {chartSel === 'rent' ? <Chart  id="rent" data={chartData[chartSel]?.data} series={series} axes={chartData[chartSel]?.axes} grouping="single" tooltip={{ align: "bottom" }}/> : null}
        {chartSel === 'revpar' ? <Chart  id="revpar" data={chartData[chartSel]?.data} series={series} axes={chartData[chartSel]?.axes} grouping="single" tooltip={{ align: "bottom" }}/> : null}
        {mpeCheck.length ? <MpeIndicator yAxis={topVal} xAxis={leftVal} value={mpeObject[chartSel]?.[mpeCheck]?.toFixed(2)} lineHeight={heigtVal} /> : null}
      </ChartDiv> : null}
      <SkLoaderOver isLoading={isLoading} type='card' />
    </RevenueMetricDiv>
  )
}

export default RevenueMetrics;