import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import CompetitorReplacementList from './CompetitorReplacementList';
import ImageRating from 'components/ReusableComponents/Image/ImageRating';

import quibbleRm from 'assets/images/quibblerm.png';
import piechartPurple from 'assets/images/market/piechartpurple.svg';

import { Modal } from 'antd';
import { showName } from '../../../../../../util/helper';

const StyledModal = styled(Modal)`
  font-family: Commissioner;
  
  .ant-modal-content {
    border-radius: 6px;
    overflow: hidden;
  }

  .ant-modal-header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    border-bottom: none;
  }

  .ant-modal-title {
    margin: 0;
    color: #333;
    font-size: 24px;
    font-weight: 600;
  }

  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-close {
    color: #333;
    font-size: 20px;
  }
`;

const PropertyContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #E8E8E8;
  padding: 8px;
  margin-top: 5px;
`;

const PropertyThumbnail = styled.div`
  width: 120px;
  height: 60px;
  position: relative;
  margin-right: 16px;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const PropertyDetails = styled.div`
  display: flex;
  flex-grow: 1;
`;

const DetailRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;

  div {
    flex: 1;
  }
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  div {
    margin: 5px;
  }
`;

const PropertyName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  max-width: calc(12ch + 1em);
`;

const PercentShareDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  color: ${({theme}) => theme.quibblePurple};
  font-family: Commissioner;
  font-weight: 300;

  img {
    width: 20px;
  }
`;

const ReplaceCompetitorModal = ({ open, onCancel, currentCompetitorId }) => {
  const [property, setProperty] = useState({});
  const [thumbnail, setThumbnail] = useState(quibbleRm);
  const [thumbnailRating, setThumbnailRating] = useState(0);

  const { propertyModel, propertyDetails } = useSelector(({ property }) => property);
  const { comparison } = propertyModel;

  useEffect(() => {
    const competitor = comparison?.find(comp => comp.airBnbId === currentCompetitorId);
    if (competitor) {
      setProperty(competitor);
      setThumbnail(competitor?.images[0]?.image_url || quibbleRm);
      setThumbnailRating(competitor?.images[0]?.score.toFixed(2) || 0);
    }
  }, [currentCompetitorId, comparison]);

  return (
    <StyledModal
      title="Replace Competitor Property"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={'80%'}
    >
      To replace:
      <PropertyContainer>
        <PropertyThumbnail>
          <ThumbnailImage src={thumbnail} alt="property image"/>
          <ImageRating value={thumbnailRating} variant="small"/>
        </PropertyThumbnail>
        <PropertyDetails>
          <DetailRow>
            <DetailColumn>
              <PropertyName>{showName(property?.name, 20) || 'No Property'}</PropertyName>
              <PercentShareDiv>
                <img src={piechartPurple} alt='piechart'/>
                {((property?.market_share * 100) || 0).toFixed(0)}% share
              </PercentShareDiv>
            </DetailColumn>
            <DetailColumn>
              <div>${property?.price} Night</div>
              <div>Min Stay: {property?.min_nights} nights</div>
            </DetailColumn>
            <DetailColumn>
              <div>Bedroom: {property?.bedrooms}</div>
              <div>Ocean View: {property?.landscape_views ? 'YES' : 'NO'}</div>
            </DetailColumn>
            <DetailColumn>
              <div>Rating: {property?.rating_value}</div>
              {/* <div>This house has a</div> */}
            </DetailColumn>
            <DetailColumn>
              <div>Reviews: {property?.review_count}</div>
              <div>Downtown: 20m</div>
            </DetailColumn>
            <DetailColumn>
              <div>Hot Tub/Jacuzzi: {property?.jacuzzi ? 'YES' : 'NO'}</div>
              <div>Pool: {property?.pool ? 'YES' : 'NO'}</div>
            </DetailColumn>
          </DetailRow>
        </PropertyDetails>
      </PropertyContainer>
      <CompetitorReplacementList propertyDetails={propertyDetails} currentCompetitors={property} onCancel={onCancel} />
    </StyledModal>
  );
};

export default ReplaceCompetitorModal;