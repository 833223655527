import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import PortfolioAnalyticsV2 from "./PortfolioAnalyticsV2/index";

/** Custom Import */
import errorsPages from "./../../errorsPages/index";

const Analytics = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/analytics`}/>
    <Route path={`${match.url}/overview`} component={asyncComponent(() => import('./Overview/index'))}/>
    <Route path={`${match.url}/marketanalytics`} component={asyncComponent(() => import('./MarketAnalytics/index'))}/>
    <Route path={`${match.url}/portfolioanalytics`} component={asyncComponent(() => import('./PortfolioAnalytics/index'))}/>
    <Route path={`${match.url}/portfolio-analytics`} component={PortfolioAnalyticsV2}/>
    <Route exact component={errorsPages}/>
  </Switch>
);

export default Analytics;
