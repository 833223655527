import React from 'react';
import styled from 'styled-components';
import { LoaderCSS } from './CommonCSS';


const MapLoaderDiv = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const Skeleton = styled.div`
  width: 100%;
  height: 100%;

  ${LoaderCSS}
`;

const MHMapLoader = () => {
  return (
    <MapLoaderDiv>
      <Skeleton />
    </MapLoaderDiv>
  )
}

export default MHMapLoader;