import styled, { css } from "styled-components";


export const CalendarDiv = css`
  font-size: ${({theme}) => theme.s4};
  font-weight: 300;
  min-width: 180px;
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
`;

export const CalendarDaysDiv = styled.div`
  min-width: fit-content;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const CalendarPropertyHeader = styled.div`
  ${CalendarDiv};
  min-width: 224px;
  width: 224px;
  background: ${({theme}) => theme.bodyBg};
  padding: 16px;
  font-weight: 400;
  font-size: ${({theme}) => theme.s3};
  color: ${({theme}) => theme.bodyFg};
  align-items: flex-start;
`;

export const CalendarHeader = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  z-index: 3;
  position: sticky;
  top: 0;
`;