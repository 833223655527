import styled from "styled-components";
import { lightScrollbar, darkScrollbar } from "../../../../components/ReusableComponents/Scrollbar/Scrollbar";

export const OptimizeBody = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 16px 84px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;
  overflow-x: hidden;

  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

`;