import React, { useState } from 'react';
import { Calendar, Button } from 'antd';

import styled from 'styled-components';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import moment from 'moment';

const CustomHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .ant-btn {
    margin: 0;
  }
`;

const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomHeaderFirst = ({ value, setValue }) => {
  return (
    <CustomHeaderContainer>
      <div>
        <Button type="text" onClick={() => setValue(prev => prev.clone().subtract(1, 'month'))} icon={<LeftOutlined />} />
      </div>
      <div>{value.format('MMM YYYY')}</div>
      <div>
      </div>
    </CustomHeaderContainer>
  );
};

const CustomHeaderSecond = ({ value, setValue }) => {
  return (
    <CustomHeaderContainer>
      <div>
      </div>
      <div>{value.format('MMM YYYY')}</div>
      <div>
        <Button type="text" onClick={() => setValue(prev => prev.clone().add(1, 'month'))} icon={<RightOutlined />} />
      </div>
    </CustomHeaderContainer>
  );
};

const DoubleMonthCalendar = () => {
  const [value, setValue] = useState(moment());

  const nextMonth = value.clone().add(1, 'month');

  return (
    <CalendarContainer>
      <div style={{ marginRight: '10px' }}>
        <Calendar
          fullscreen={false}
          headerRender={() => <CustomHeaderFirst value={value} setValue={setValue} />}
          value={value}
          onPanelChange={setValue}
        />
      </div>
      <div>
        <Calendar
          fullscreen={false}
          headerRender={() => <CustomHeaderSecond value={nextMonth} setValue={setValue} />}
          value={nextMonth}
          onPanelChange={(v) => setValue(v.subtract(1, 'month'))}
        />
      </div>
    </CalendarContainer>
  );
};

export default DoubleMonthCalendar;