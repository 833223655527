
import axios from "axios";
import {jwtExpiration} from "../../src/constants/GlobalConstant";
import serviceV2 from "../util/APIUtility";


let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });
export const reservation = {
  list: async (data) => {
    let dataVal  =   await service.post("/api/reservation", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  ratelist: async (data) => {
    let dataVal  =   await service.post("/api/list", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  bookingFeed: async (data) => {
    let dataVal  =   await service.post("/api/bookingFeed", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  listByBookingDate: async (data) => {
    let dataVal  =   await service.post("/api/listByBookingDate", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  listByCheckIn: async (data) => {
    let dataVal  =   await service.post("/api/listByCheckIn", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  listByCheckOut: async (data) => {
    let dataVal  =   await service.post("/api/listByCheckOut", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  updatePricing: async (options) => {
    let result = await serviceV2.post(`/api/calendar/listings`, options);
    return result;
  },
};
