import React, {memo} from 'react';
import styled from 'styled-components';

import { BodyCSS } from '../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from '../../../components/ReusableComponents/Text/Text';
import { dateDiffInDays } from '../../../util/helper';
import { useDispatch } from 'react-redux';
import { manage } from '../../../appRedux/actions';

const DemoDiv = styled.div`
  ${BodyCSS}
  width: 100%;
  height: fit-content;
  border: 1px solid #B0B0B0;
  max-width: 1661px;
  margin: 0 0 16px 0;
  box-shadow: none;
  display: ${({$display}) => $display};
  align-items: center;
  padding: 0 16px;
`;


const AddInfoButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: #00A4E6;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.01);
  }
`;


const DemoBanner = ({authData}) => {

  const dispatch = useDispatch();

  const {subscription} = authData;

  const onAddClick = () => {
    dispatch(manage())
  };


  
  return (
    <DemoDiv $display={subscription?.status === 'trialing' ? 'flex':'none'}>
      <BodyText>
        Hey there! Just a heads up that your trial ends in {dateDiffInDays(new Date(), new Date(subscription?.billingDate))} days. To keep accessing Quibble's features without interruption, 
        {<AddInfoButton onClick={onAddClick}> Add your payment information </AddInfoButton>}
        . 
        It's quick and easy! 
      </BodyText>
    </DemoDiv>
  );
}

export default memo(DemoBanner);