import React, { useCallback } from 'react';
import styled from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import GoogleMapReact from 'google-map-react';
import { BodyText } from 'components/ReusableComponents/Text/Text';

import marker from 'assets/images/generic/marker.svg';
import geocode from 'assets/images/generic/geocode.svg';
import navigation from 'assets/images/generic/navigation-grey.png';
import SkLoaderOver from '../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { isEmpty } from 'lodash';

import markerIcon from 'assets/images/generic/marker.png';

const PropertyMapLayout = styled.div`
  width: 100%;
  max-width: 1776px;
  height: fit-content;
  margin: 24px 0 0 0;
  position: relative;
`;

const PropertyMapDiv = styled.div`
  width: 100%;
  min-width: 1776px;
  height: 600px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  ${BodyCSS}
`;

const PropertyMapBody = styled.div`
  width: 100%;
  position: relative;
  height: 418px;
  border: 1px solid black;
`;

const AddressDetailsDiv = styled.div`
  width: fit-content;
  height: fit-content;
  margin: ${({margin}) => margin};

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AddressDetails = styled(BodyText)`
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  margin: 0 0 0 8px; 
`;

const MapMarker = styled.div`
  width: 14px;
  height: 14px;
  flex-shrink: 0;

  border-radius: 14px;
  background: #FFC805;
`;

const MarkerDiv = styled.div`
  width: fit-content;
  height: fit-content;
  margin: -60px 0 0 -30px;
`;

const PropertyMap = ({propertyDetails, isFetchingPropertyDetails, address}) => {

  const {latitude, longtitude, name, competitors} = propertyDetails;
  
  const loadMap = useCallback(() => {

    // const renderMarkers = (map, maps) => {
    //   const marker = new maps.Marker({
    //     position: { lat: latitude, lng:  longtitude },
    //     map,
    //   });
  
    //   return marker;
    // };

    // const mapMarker = new googleMapReact.maps.Map (
    //   document.getElementById("map") as HTMLElement,
    //   {
    //     zoom: 4,
    //     center: myLatLng,
    //   }
    // );

    
    return (
      <GoogleMapReact
      
        bootstrapURLKeys={{ key: "AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM" }}
        defaultCenter={{  lat: 40.7128, lng: -74.0060}}
        center={{  lat: latitude, lng: longtitude}}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
        options={
          {
            mapTypeId:"terrain",
            clickableIcons: false,
            fullscreenControl: false,
            scrollwheel: false,
            styles:[
              {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                  { "visibility": "off" }
                ]
              },
            ]
        }}
      >
        <MarkerDiv lat={latitude} lng={longtitude}>
          <img width={60} height={76} src={markerIcon} alt="marker icon"/>
        </MarkerDiv>
        {competitors?.map((competitor, index) => (
          <MapMarker
            key={`competitor-marker-${index}`}
            lat={competitor.lat}
            lng={competitor.long}
          />
        ))}      
      </GoogleMapReact>
    )
  }, [latitude, longtitude, competitors])
  

  return (
    <PropertyMapLayout>
      <PropertyMapDiv>
        <PropertyMapBody>
          {loadMap()}
        </PropertyMapBody>
        <AddressDetailsDiv margin="24px 0 0 0">
          <img src={marker} alt="marker" />
          <AddressDetails>
            Address: &nbsp; {address}
          </AddressDetails>
        </AddressDetailsDiv>
        <AddressDetailsDiv margin="16px 0 0 0">
          <img src={navigation} alt="navigation" />
          <AddressDetails >
            Location: &nbsp; {name}
          </AddressDetails>
        </AddressDetailsDiv>
        <AddressDetailsDiv margin="16px 0 0 0">
          <img src={geocode} alt="navigation" />
          <AddressDetails >
            Geocode: &nbsp; {`${latitude}, ${longtitude}`}
          </AddressDetails>
        </AddressDetailsDiv>
        <SkLoaderOver isLoading={isFetchingPropertyDetails || isEmpty(propertyDetails)} type='full' />
      </PropertyMapDiv>
    </PropertyMapLayout>
  )
}

export default PropertyMap;