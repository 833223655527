import axios from "axios";
import {jwtExpiration} from "../../src/constants/GlobalConstant";
import { getData, storeData } from "../util/indexDbHelper";

let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });
export const home = {
  listingWatch: async (data) => {
    let cachedData =  await getData("ListingWatchList", data)

    if(!cachedData) {
      let listingWatch  =  await service.post("/api/listingWatch", data, {headers: {'Content-Type': 'application/json'}});
      jwtExpiration(listingWatch);
      await storeData("ListingWatchList", listingWatch.data, data)
      return listingWatch;
    }

    let response =  { data: cachedData }
    return response
  },
  listingWatchBuilding: async (data) => {
    let listingWatchBuilding  =  await service.post("/api/listingWatchBuilding", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(listingWatchBuilding);
    return listingWatchBuilding;
  },
  marketsWatch: async (data) => {
    let cachedData =  await getData("MarketWatchList", data)

    if (!cachedData){
      let marketsWatch  =  await service.post("/api/marketsWatch", data, {headers: {'Content-Type': 'application/json'}});
      jwtExpiration(marketsWatch);
      await storeData("MarketWatchList", marketsWatch.data, data)
      return marketsWatch;
    }

    let response =  { data: cachedData }
    return response
  },
  notificationWatch: async (data) => {
    let cachedData = await getData("NotificationList", data)

    if(!cachedData){
      let notificationWatch  =  await service.post("/api/listingWatchNotification", data, {headers: {'Content-Type': 'application/json'}});
      jwtExpiration(notificationWatch);
      await storeData("NotificationList", notificationWatch.data , data)
      return notificationWatch;
    }

    let response = { data: cachedData }
    return response;
  },
};
