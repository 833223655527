import React from 'react';
import styled from 'styled-components';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';
import hiLowDot from 'assets/images/price-adjustment/highLowDot.svg';

const ChartHeader = styled.div`
  width: 1067px;
  min-height: 40px;
  margin: 0 0 -36px -9.2px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
`;

const BoxLabelDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BoxDiv = styled.div`
  width: 13px;
  height: 13px;
  background: #00A4E6;
`;

const OptimizedDiv = styled(BoxDiv)`
  background: #00BBD2;
`;

const LineDiv = styled.div`
  text-align: center;
  height: 16px;
  margin-bottom: 11px;
  width: 16px;
  border-bottom: 3px solid #00A4E6;
`;

const LastYearLineDiv = styled(LineDiv)`
  border-bottom: 3px solid #EDB548;
`;

const LineDivADR = styled(LineDiv)`
  border-bottom: 2px dashed;
  border-bottom-color: #FA7D7D;
`;


const GraphHeader = ({showAdr, showOptimize, showComps}) => {
  return (
    <ChartHeader>
      <BoxLabelDiv>
        <LineDiv />
        <BodyText margin="0 0 0 6px">
          Current
        </BodyText>
      </BoxLabelDiv>
      <BoxLabelDiv>
        <LastYearLineDiv />
        <BodyText margin="0 0 0 6px">
          Last Year
        </BodyText>
      </BoxLabelDiv>
      {showAdr && <BoxLabelDiv>
        <LineDivADR />
        <BodyText margin="0 0 0 6px">
          ADR
        </BodyText>
      </BoxLabelDiv>}
      {showOptimize &&  <BoxLabelDiv>
        <OptimizedDiv />
        <BodyText margin="0 0 0 6px">
          Optimized
        </BodyText>
      </BoxLabelDiv>}
      {showComps &&  <BoxLabelDiv>
        <img src={hiLowDot} alt="hilowdot"/>        
        <BodyText margin="0 0 0 6px">
          Comps
        </BodyText>
      </BoxLabelDiv>}
    </ChartHeader>
  )
}

export default GraphHeader;