import { useEffect, useState } from 'react'

//tracks the scroll position of the component referenced by the param
const useScrollPosition = (componentRef) => {

   const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })

   useEffect(() => {

    const componentToCheck =  componentRef?.current;
    
    function updatePosition() {
        setPosition((prev) => ({...prev, scrollX: componentToCheck.scrollLeft, scrollY: componentToCheck.scrollTop }))
    }

    if(componentToCheck) {
      componentToCheck.addEventListener('scroll', updatePosition);
    }


    return () =>  componentToCheck?.removeEventListener('scroll', updatePosition)
   }, [componentRef])

   return scrollPosition
}

export default useScrollPosition;