import React from 'react';
import styled, {css} from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { SecondaryButton, PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { BodyTitle, BodyText } from 'components/ReusableComponents/Text/Text';
import { message } from 'antd';

const ConfirmationModalBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  background: #000000BF;
  z-index: 100;

  display: ${({display}) => display};
  align-items: center;
  justify-content: center;
`;

const ConfirmModal = styled.div`
  ${BodyCSS}
  border-radius: 2px;
  width: 290px;
  height: 230px;
`;

const ConfirmModalHeader = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 16px;
`;

const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  background: none; 
  cursor: pointer;
  margin-bottom: 8px;
`;

const TextBody = styled.div`
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const ButtonDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  gap: 16px;
`;

const ButtonCSS = css`
  width: 120px;
  height: 44px;
`;

const ConfirmButton = styled(PrimaryButton)`
  ${ButtonCSS}
`;

const BackButton = styled(SecondaryButton)`
  ${ButtonCSS}
`;

const ConfirmationModal = ({visible=false, title, confirmText, messageAfter, onCancelFnc, onSubmitFunc, bodyTextFirst, bodyTextSecond}) => {
  const onCancel = () => {
    if (!onCancelFnc) return;
    onCancelFnc();
  };
  const onSubmit = () => {
    if (!onSubmitFunc) return;
    onSubmitFunc();
    onCancelFnc();
    if (!messageAfter) return;
    message.success(messageAfter, 2)
  };
  return (
    <ConfirmationModalBackdrop display={visible ? 'flex' : 'none'}>
      <ConfirmModal >
        <ConfirmModalHeader>
          <BodyTitle margin="0px">{title}</BodyTitle>
          <CloseButton onClick={onCancel}><BodyTitle margin="0px"> x </BodyTitle></CloseButton>
        </ConfirmModalHeader>
        <TextBody>
          <BodyText>
            {bodyTextFirst}
          </BodyText>
          <BodyText>
            {bodyTextSecond}
          </BodyText>
        </TextBody>
        <ButtonDiv>
          <BackButton onClick={onCancel}>
            Cancel
          </BackButton>
          <ConfirmButton onClick={onSubmit}>
            {confirmText}
          </ConfirmButton>
        </ButtonDiv>
      </ConfirmModal>
    </ConfirmationModalBackdrop>
  )
}

export default ConfirmationModal;