
/*** Config table */
export const DEFAULT_TABLE_PAGE_NUMBER = 1;
export const DEFAULT_TABLE_ITEMS_LIST = 10;
export const TABLE_LIST_ITEMS = ['10', '20', '30', '40'];
export const REACT_APP_WEBSITE_URL = 'http://192.168.1.118:3000/';
export const FULL_MONTH_NAME = ['January','February','March','April','May','June','July','August','September','October','November','December'];
export const HALF_MONTH_NAME = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
export const FULL_DAY_NAME = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const HALF_DAY_NAME = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const OWNER_REZ_SECRET_KEY = "s_r9tdb3y012njxi8qg80pepedw0a0gf3n";
export const OWNER_REZ_AUTHENTICATION_CLIENT_ID = "c_8alxu1afwqa2q7acbz0m75ypr2pkkpc8";
export const MYVR_AUTHENTICATION_CLIENT_ID = "eccc342b2cc08061c1448dc314705621";

/**USER CONFIGS */

/* REGEX EXPRESSIONS */
export const ALPHANUMERIC = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@!%&,.*?]).{6,12}";


export const FULL_NAME = "^[a-zA-ZÀ-ÖØ-öø-ÿ]+(?:[-'` ]?[a-zA-ZÀ-ÖØ-öø-ÿ]+)*$";
export const VALID_EMAIL = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";

/* eslint-disable-next-line */
export const EMAIL_VERIFY = "([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])"
export const NO_SPECIALCHARS_SPACE = /[^a-zA-Z ]/g;

/**USER ROLES */
export const ADMIN_ROLE = 'admin';
export const USER_ROLE = 'user';
export const TRIAL_ROLE = 'tUser';
export const FREEMIUM_ROLE = 'fUser';
export const ANALYTIC_ROLE = 'aUser';
export const VIP_ROLE = "vipUser";

/**CHARGEBEE CONFIG */
export const CHARGEBEE_SITE      =   process.env.CHARGEBEE_SITE
export const CHARGEBEE_API_KEY   =   process.env.CHARGEBEE_API_KEY

/*export const CHARGEBEE_SITE      =   'quibble-test'
export const CHARGEBEE_API_KEY   =   'test_M0aCptC8AxlvbE6H7NkJSZacdvcuzKs65'*/

/**CHARGEBEE PLANS */
export const CB_SUB_PLAN_ANALYTIC   =   'analytic-user-USD-Monthly'
export const CB_SUB_PLAN_TRIAL      =   'trial-user-USD-Monthly'
export const CB_SUB_PLAN_FREEMIUM   =   'freemium-user-USD-Monthly'

/**CHARGEBEE PRICES */
export const CB_PRICE_PLAN_ANALYTIC = 300 //every 100 is 1$.
export const CB_PRICE_PLAN_FREEMIUM = 0
export const CB_PRICE_PLAN_TRIAL = 0

/**BOOKING FEED */
export const PROPERTY = 'Property'
export const BOOKING_DATE = 'Booking Date'
export const CHECK_IN_DATE = 'Check-In Date'
export const CHECK_OUT_DATE = 'Check-Out Date'
export const GUEST_NAME = 'Guest Name'


export const jwtExpiration = (dataVal)=>{

    if(dataVal && dataVal.data && dataVal.data.statusCode === 'token_expire'){
        localStorage.clear();
        localStorage.setItem('token_message',dataVal.data.message);
        window.location.reload()
    }else if(dataVal && dataVal.data && dataVal.data.statusCode === 'user_authorized'){
        localStorage.clear();
        localStorage.setItem('token_message',dataVal.data.message);
        window.location.reload()
    }

}

export const GOOGLE_PLACE_AUTO_COMPLETE_KEY = 'AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM';

// Each of the items should be exactly the same with the filenames
export const AVAILABLE_COMPANY_LOGOS = [ 'airbnb', 'bookingengine', 'directbooking', 'booking', 'ebookers', 'egencia', 'expedia', 'homeaway', 'hotels', 'myvr', 'orbitz', 'plumguide', 'travelocity', 'vrbo', 'vacationrentals', 'abritel', 'agoda', 'americanexpress', 'atease', 'bellevueferienhaus', 'bnbme', 'ctrip', 'fewodirekt', 'sitemander', 'flipkey', 'glampinghub', 'rentalsunited', 'holidaylettings', 'homelidays', 'hopper', 'hostfully', 'housetrip', 'travelstaytion', 'marriott', 'mightyvacay', 'misterb&b', 'orbirental', 'ownerrez', 'zeusliving', 'porter', 'hometogo', 'riparide', 'holidu', 'stayz', 'stripe', 'nestpick', 'tripadvisor', 'wordpress', 'pf', 'bookingdotcom', 'direct homeaway' ]
// Amenities object for the markethunter amenities table
export const AMENITIES_LIST = { "essentials": "Towels, Sheets, and Toilet paper", "tv": 'TV/HDTV', "grill": 'BBQ Grill', "crib": "Crib", "baby": "Baby Amenities", "high chair": "High Chair", "sound system": "Sound System", "outdoor": "Outdoor furniture, and dining area", "air conditioning": "Air Conditioning", "kitchen": "Kitchen", "ac unit": "Air Conditioning", "entrance": "Private Entrance", "heating": "Heating", "living room": "Living Room", "children": "Children's Books and Toys", "ocean": "Ocean/ Ocean View", "breakfast": "Provided Breakfast", "cooking": "Cooking utensils", "dishes": "Dishes and Silverware", "shampoo": "Shampoo", "conditioner": "Conditioner", "workspace": "Dedicated Workspaces", "body soap": "Body Soap", "smoke alarm": "Smoke Alarm", "ev charger": "EV Charger", "carport": "Carport", "patio": "Patio/Balcony", "balcony": "Patio/Balcony", "coffee": "Coffee/Coffee Maker", "clothing storage": "Clothing Storage", "dedicated workspace": "Dedicated Workspace", "exercise": "Exercise Area / Gym", "long term": "Long term stays allowed", "gym": "Exercise Area / Gym", "fridge": "Refrigerator/Freezer", "refrigerator": "Refrigerator/Freezer", "freezer": "Refrigerator/Freezer", "wifi": "Wifi/Internet Connection", "dryer": "Dryer", "washer": "Washer", "backyard": "Backyard", "security camera": "Security Camera", "resort": "Resort Facilities", "oven": "Oven", "stove": "Stove", "game console": "Game Console", "parking": "Parking", "fireplace": "Fireplace", "fire pit": "Fire Pit", "bed linens": "Bed linens", "hot water": "Hot Water", "garage": "Garage", "pool table": "Pool Table", "pool": "Swimming Pool", "beach": "Beach Access", "hot tub": "Hot Tub", "bathtub": "Bathtub", "fan": "Fan / Ceiling Fan", "game": "Board Games / Game Console", }
// Amenities object for the markethunter amenities table
export const PRIMARY_AMENITIES = {
    "hot tub": "Hot Tub/Jacuzzi",
    "jacuzzi": "Hot Tub/Jacuzzi",
    "gym": "Gym",
    "exercise": "Exercise Area",
    "pool table": "Pool Table",
    "pool": "Swimming Pool",
    "piano": "Piano",
    "ping pong": "Ping Pong Table",
    "fireplace": "Fireplace",
    "waterfront": "Waterfront",
    "access": "Amenity Access",
    "view": "Amenity View",
    "kayak": "Kayak",
}

export const pickerMonthYear = (date) => {
    return `${FULL_MONTH_NAME[date.getMonth()]}-${date.getFullYear()}`;
};

export const pickerDateMonth = (date) => {

  const today = new Date(date);
  const day = today.getDate().toString().padStart(2, '0');
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = today.getFullYear();

  return `${day} - ${month} - ${year}`
}


const toDate = new Date();

export const TODAY_MONTH = toDate.getMonth() + 1;
export const TODAY_YEAR = toDate.getFullYear();

export const now = toDate,
      currentYr = TODAY_YEAR,
      currentMonth = ('0'+(now.getMonth()+1)).slice(-2),
      currentDay = now.getDate(),
      currentDate = `${currentYr}-${currentMonth}-${String(currentDay).length < 2 ? '0' + currentDay : currentDay}`,
      nowMonthYear = `${FULL_MONTH_NAME[now.getMonth()]}-${TODAY_YEAR}`;
