import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';

import { ReactComponent  as RatingIcon } from '../../../../../assets/images/markethunter/ratings-gray.svg';

const COLORS = ['#2ECADB', '#FFC805', '#F26262', '#9747FF'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const RentalTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const ChartContainer = styled.div`
  height: 220px;
  display: flex;
  padding: 10px 0;
`;

const LegendsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20%;

  padding: 20px 0;
`;

const PieChartContainer = styled.div`
  width: 60%;
`;

const Legend = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const LegendColor = styled.div`
  width: 15px;
  height: 15px;
`;

const LegendText = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 5px;
`;

const RatingsChart = (props) => {

  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)
    const rawData = props.data;

    if (rawData) {
      const total = rawData && Object.values(rawData).reduce((acc, val) => acc + val, 0);

      let mappedData = rawData && Object.entries(rawData).map(([key, value], index) => {
        let name;
        if (key === 'fourPointEightToFive') {
          name = '4.8 to 5.0';
        } else if (key === 'fourPointFiveToFourPointSeven') {
          name = '4.5 to 4.7';
        } else {
          name = '4.0 to 4.4';
        }

        return {
            name: name,
            value: Math.round(value * 100)
        };
      });

      mappedData && mappedData.push({
        name: "Other Ratings",
        value: Math.round((1 - total) * 100)
      });

      setChartData(mappedData);
      setIsLoading(false);
    }
  }, [props.data]);

  return (
    <>
    {isLoading ? <MHCardLoader /> :
    <RentalTypeContainer>
      <TitleContainer>
        <Title>Ratings Chart</Title>
      </TitleContainer>
      <ChartContainer>
        <LegendsContainer>
          <Legend>
            <LegendColor style={{ background: '#2ECADB' }} />
            <LegendText>
              <RatingIcon fill="#697273" alt='rating' />
              <span>4.8</span>
              <span>&nbsp;-&nbsp;</span>
              <RatingIcon fill="#697273" alt='rating' />
              <span>5.0</span>
            </LegendText>
          </Legend>
          <Legend>
            <LegendColor style={{ background: '#FFC805' }} />
            <LegendText>
              <RatingIcon fill="#697273" alt='rating' />
              <span>4.5</span>
              <span>&nbsp;-&nbsp;</span>
              <RatingIcon fill="#697273" alt='rating' />
              <span>4.7</span>
            </LegendText>
          </Legend>
          <Legend>
            <LegendColor style={{ background: '#F26262' }} />
            <LegendText>
              <RatingIcon fill="#697273" alt='rating' />
              <span>4.0</span>
              <span>&nbsp;-&nbsp;</span>
              <RatingIcon fill="#697273" alt='rating' />
              <span>4.4</span>
            </LegendText>
          </Legend>
        </LegendsContainer>
        <PieChartContainer>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Tooltip />
              <Pie
                data={chartData && chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {chartData && chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </PieChartContainer>
      </ChartContainer>
    </RentalTypeContainer>
    }
    </>
  );
}

export default RatingsChart;