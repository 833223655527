import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import info from 'assets/images/generic/info.svg';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import Status from '../subComponents/ListingTable/Status';
import Compare from '../subComponents/ListingTable/Compare';
import DynamicPricing from '../subComponents/ListingTable/DynamicPricing';
import SilentDynamicPricing from '../subComponents/ListingTable/SilentDynamicPricing';
import ListingName from '../subComponents/ListingTable/ListingName';
import { Tooltip } from 'antd';
import { BodyTitle } from '../../../components/ReusableComponents/Text/Text';
import { BodyCSS } from '../../../components/ReusableComponents/Card-Body/BodyCSS';
import { currencyFormatter } from "util/helper";
import DynamicPopUp from '../subComponents/ListingTable/DynamicPopup';


const ListingTableBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: fit-content;
  position: relative;
`;


export const ListingTableDiv = styled.div`
  min-width: 1600px;
  max-width: 1800px;;
  height: fit-content;
  max-height: 800px;
  transition: all 0.15s linear;
`;

const TableTitleDiv = styled.div`
  ${BodyCSS}
  width: 100%;
  height: 61px;
  padding: 16px;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TooltipDiv = styled.div`
  width: 340px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const ListingTable = ({searchString, selectedMarket, listingDrawer, setListingDrawer, setComparisonDetails, nothingSet, setNothingSet, updating, setUpdating, isDynamicPricingEnabled}) => {

  const { listings, isFetchingListings} = useSelector(({ markets }) => markets);
  const [popupParams, setPopupParams] = useState({
    id: '',
    left: 0,
    top: 0,
    isVisible: false,
    updating: false,
  })
  
  const listingsColumn =  [
    {
      label: 'property' ,
      width: '280px',
      align: 'left',
      sorter: true,
      sorterKey: 'name',
      customRender: (data) => (<ListingName value={data} />),
    },
    {
      label: '12 Months Avg' ,
      width: '150px',
      align: 'right',
      dataIndex: 'twelveMonthAverage',
      sorter: true,
      customRender: (data) => currencyFormatter(data),

    },
    // GP - commenting out this colum for the moment until we get the data in
    // {
    //   label: 'Market Avg' ,
    //   width: '150px',
    //   align: 'right',
    //   dataIndex: 'marketAverage',
    //   sorter: true,
    //   customRender: (data) => currencyFormatter(data),
    // },
    {
      label: <TitleDiv>Benchmark Price   
        <Tooltip 
          title={<TooltipDiv > 
             Quibble uses this price to find the appropriate competitors for this listing.  We will segment the nearby competitors from this range. 
          </TooltipDiv>} overlayInnerStyle={{backgroundColor:'black', color: '#E8E8E8'}} showArrow={false}><img src={info} alt='info' />
        </Tooltip> </TitleDiv> ,
      width: '210px',
      align: 'center',
      dataIndex: 'benchmarkPrice',
      sorter: true,
      customRender: (data) => currencyFormatter(data),
    },
    {
      label: 'Status' ,
      width: '100px',
      align: 'center',
      dataIndex: 'active',
      sorter: true,
      customRender: (data) => (<Status value={data} />),
    },
    {
      label: 'Compare' ,
      width: '130px',
      align: 'center',
      customRender: (data) => (<Compare value={data} setComparisonDetails={setComparisonDetails}/>),
    },
    {
      label: <TitleDiv> Dynamic Pricing
      <Tooltip 
        title={<TooltipDiv > 
         When this is turned on, the Optimized Pricing will be sent to the PMS as well as the minimum night stay controls. 
        </TooltipDiv>} 
        overlayInnerStyle={{backgroundColor:'black', color: '#E8E8E8'}}
        showArrow={false}>
          <img src={info} alt='info' />
      </Tooltip> </TitleDiv> ,
      width: '240px',
      align: 'center',
      customRender: (data, _, val, rowInd, colInd) => (<DynamicPricing value={data} rowIndex={rowInd} colIndex={colInd} setPopupParams={setPopupParams}  popupParams={popupParams}/>),
    },
    {
      label: <TitleDiv> Silent Dynamic Pricing
      <Tooltip 
        placement="topLeft"
        title={<TooltipDiv > 
         In silent mode, the pricing optimization will run but it will not send the update to the PMS.  You will be able to see the Optimized Price and review it. 
        </TooltipDiv>} overlayInnerStyle={{backgroundColor:'black', color: '#E8E8E8'}} showArrow={false}><img src={info} alt='info' />
      </Tooltip> </TitleDiv> ,
      width: '250px',
      align: 'center',
      sorter: true,
      sorterKey: 'isSilentDynamicPricingEnabled',
      customRender: (data) => (<SilentDynamicPricing updating={updating} setUpdating={setUpdating} value={data} setNothingSet={setNothingSet} listingDrawer={listingDrawer} setListingDrawer={setListingDrawer} nothingSet={nothingSet}/>),
    },
  ];

  const tableListingsMarket = useMemo(() => {
    if (selectedMarket === '') {
      return listings;
    }
    return  listings.filter((data) => data.market === selectedMarket );
  }, [listings, selectedMarket]);  

  const tableListings = useMemo(() => {
    let filteredListings = tableListingsMarket;
    if (searchString) {
      filteredListings = filteredListings.filter((data) => data.name.toLowerCase().includes(searchString.toLowerCase()));
    }
    if (isDynamicPricingEnabled) {
      filteredListings = filteredListings.filter((data) => data.isSilentDynamicPricingEnabled  === true);
    }
    return filteredListings;
  }, [tableListingsMarket, searchString, isDynamicPricingEnabled]); 

  
  return (
    <ListingTableBody>
      <ListingTableDiv>
        <TableTitleDiv>
          <BodyTitle>
            Listings
          </BodyTitle>
        </TableTitleDiv>
        <QuibbleTable isLoading={isFetchingListings && !updating} columns={listingsColumn} data={tableListings} maxHeight="65px"  pagination={true} paginationSize={20}/>
      </ListingTableDiv>
      <DynamicPopUp {...popupParams} setPopupParams={setPopupParams}/>
    </ListingTableBody>

  )
};

export default ListingTable;