import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
// import MarketFilter from './components/MarketFilter';
import MarketAnalyticsTable from './components/MarketAnalyticsTable';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import MarketPropertyFilter from '../sharedComponents/MarketPropertyFilter/MarketPropertyFilter';
import { now } from '../../../constants/GlobalConstant';

const MarketBody = styled(LayoutBody)`  
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const Market = () => {

  const [monthYear, setMonthYear] = useState(now);
  const [searchString, setSearchString] = useState('');

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, []);

  const setSearchStringFunc = useCallback((value) => {
    setSearchString(value);
  },[]);
  
  return (
    <MarketBody>
      <MarketPropertyFilter monthYear={monthYear} setMonthYearVal={setMonthYearVal} setSearchStringFunc={setSearchStringFunc}/>
      <MarketAnalyticsTable monthYear={monthYear} searchString={searchString} />
    </MarketBody>
  )
}

export default Market;

