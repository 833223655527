import React from 'react';
import styled from 'styled-components';

import DemandPrice from './subComponents/InsightsRevenue/DemandPrice';
import RevenueMetrics from './subComponents/InsightsRevenue/RevenueMetrics';

const InsightsPriceRevenueDiv = styled.div`
  width: 100%;
  max-width: 1800px;

  height: 588px;
  min-height: 588px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;


const InsightsPriceRevenue = ({priceRevData, revenueMetricsData, isLoading, monthYear, dateType, mpeObject}) => {
  return (
    <InsightsPriceRevenueDiv>
      <DemandPrice priceRevData={priceRevData} isLoading={isLoading} monthYear={monthYear}/>
      <RevenueMetrics dateType={dateType}  mpeObject={mpeObject} revenueMetricsData={revenueMetricsData} isLoading={isLoading} monthYear={monthYear}/>
    </InsightsPriceRevenueDiv>
  )
}


export default InsightsPriceRevenue;