import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import blurredBg from '../../../assets/images/blurredBg.png'

const CustomModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    margin-bottom:-12px;
  }

  .ant-modal-content {
    border: none;
  }

  .ant-modal-footer {
    border-top: none;
  }
`;
const ModalButton = styled(Button)`

`;


const Paywall = () => {
  const [visible] = useState(true);
  let history = useHistory();


  const handleOk = () => {
    history.push('/setting/profile/plan');
  };

    const handleCancel = () => {
    history.push('/');
  };

    return (
      <div>
        <img src={blurredBg} alt="Quibble RM Model Scenario Testing" />

        <CustomModal
          title={<span style={{fontSize:'36px', color:'#1BB3CD', border:'none', lineHeight:'40px', fontWeight:'lighter'}}>Whoops! This feature is currently unavailable on your Free Insights Plan</span>}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <div key="submit" style={{ display: 'flex', justifyContent: 'center' }}>
            <ModalButton size='large' onClick={handleOk} style={{ backgroundColor: '#1BB3CD', marginBottom:'1.5rem', color:'#ffffff'}}>
              Upgrade to Quibble Insights Pro
              </ModalButton>
            </div>
          ]}
          closable={true}
          className="custom-modal"
          width={810}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <div className="modal-content" style={{fontSize:'16px' }}>
            <p >We understand that you're trying to access a premium feature, which is not available on your current plan. However, we'd love to help you experience the full potential of our service.</p>
            <p>Consider upgrading to our <strong>Quibble Insights Pro</strong> to unlock this feature.</p>
          </div>
        </CustomModal>
      </div>
    );
  };

  export default Paywall;
