import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { BodyTextCSS, BodyTitle } from '../../../../../../components/ReusableComponents/Text/Text';
import { Select, Switch } from 'antd';
import { formatDate } from '../../../../../../util/localStorageHelper';
import SelectCalendar from 'components/ReusableComponents/Calendar/SelectCalendar';
import { currentDate } from '../../../../../../constants/GlobalConstant';

const ComparisonSettingsDiv = styled.div`
  min-width: 340px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 0 0 24px;
`;

const ShowCompetitor = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 0 0;

  ${BodyTextCSS}
  color: ${({theme}) => theme.quibbleBackgroundGrey};
`;

const ComparisonSettings = ({filterNumber, monthYear = new Date(), showFullFunc, changeFilterNumber, selectedDate, setSelectedDateFunc, setMonthYear}) => {
 
  const calendarArray = useMemo(() => {
    const firstDay = new Date(monthYear?.getFullYear(), monthYear?.getMonth(), 1);
    const startDateCopy = new Date(firstDay);
    const firstSunday = new Date(startDateCopy.setDate(startDateCopy.getDate() - startDateCopy.getDay()));

    return [...Array(42).keys()].map((_, index) => {
      const firstSundayDate = new Date(firstSunday);
      return formatDate(new Date(firstSundayDate.setDate(firstSundayDate.getDate() + index)))
    })
  }, [monthYear]);

  const onChangeSwitch = (value) => {
    showFullFunc(value);
  };

  const onChangeSelect = (value) => {
    changeFilterNumber(value)
  };

  const onCalendarChange = (value) => {
    setSelectedDateFunc(value);
  };


  const disableFunc = useCallback((data) => {
    const newDateCurrent = new Date(currentDate);
    const nextElevenMonths = new Date(newDateCurrent.setMonth(newDateCurrent.getMonth()+11));
    const dataSplit = data.split('-');
    return parseInt(dataSplit[1]) !==  monthYear?.getMonth() + 1 ||  new Date(data) < new Date(currentDate) || monthYear > nextElevenMonths;
  }, [monthYear]);

  return (
    <ComparisonSettingsDiv>
      <BodyTitle margin="0 0 4px 0">
        Settings
      </BodyTitle>
      <Select value={filterNumber} onChange={onChangeSelect}>
        <Select.Option value={0} >
          All Comparison
        </Select.Option>
        <Select.Option value={5} >
          Top 5
        </Select.Option>
        <Select.Option value={10} >
          Top 10
        </Select.Option>
      </Select> 
      <SelectCalendar calendarArray={calendarArray} monthYear={monthYear} setMonthYear={setMonthYear} selectedDates={selectedDate} setSelectedDaysFunc={onCalendarChange} disableFunc={disableFunc} showStrikeThrough={true}/>
      <ShowCompetitor> 
        <span>
          Show full competitor details 
        </span>
        <Switch onChange={onChangeSwitch}/>
      </ShowCompetitor>
    </ComparisonSettingsDiv>
  )
}

export default ComparisonSettings;