import {
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_COMPLETE,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_COMPLETE,
  GET_SUBSCRIPTION_CARDS,
  GET_SUBSCRIPTION_CARDS_COMPLETE,
} from "../../constants/ActionTypes.js";


const initialState = {
  isFetchingSubscriptionPlans: false,
  fetchingSubscriptionPlansError: null,
  subscriptionPlans: [],
  isFetchingTransactions: false,
  fetchingTransactionsError: null,
  transactions: [],
  isFetchingSubscriptionCards: false,
  fetchingSubscriptionCardsError: null,
  subscriptionCards: [],
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS: {
      return { ...state, isFetchingSubscriptionPlans: true, fetchingSubscriptionPlansError: null };
    }
    case GET_SUBSCRIPTION_PLANS_COMPLETE: {
      return {
        ...state,
        isFetchingSubscriptionPlans: false,
        fetchingSubscriptionPlansError: action?.error,
        subscriptionPlans: action?.subscriptionPlans
      };
    }
    case GET_TRANSACTIONS: {
      return { ...state, isFetchingTransactions: true, fetchingTransactionsError: null };
    }
    case GET_TRANSACTIONS_COMPLETE: {
      return {
        ...state,
        isFetchingTransactions: false,
        fetchingTransactionsError: action?.error,
        transactions: action?.transactions
      };
    }
    case GET_SUBSCRIPTION_CARDS: {
      return { ...state, isFetchingSubscriptionCards: true, fetchingSubscriptionCardsError: null };
    }
    case GET_SUBSCRIPTION_CARDS_COMPLETE: {
      return {
        ...state,
        isFetchingSubscriptionCards: false,
        fetchingSubscriptionCardsError: action?.error,
        subscriptionCards: action?.subscriptionCards
      };
    }
    default:
      return state;
  }
}

export default SubscriptionReducer;
