import React, { Fragment, useState, useCallback, Suspense, lazy } from 'react';
import styled, { css, useTheme } from 'styled-components';


import { UpperMetricDiv } from './MetricContainerTop';
import { SectionTitle } from './MetricContainerTop';
import PortfolioStar from 'assets/images/homepage/portfolio_star.png';
import PortfolioGift from 'assets/images/homepage/portfolio_gift.png';
import PortfolioBrokenHeart from 'assets/images/homepage/portfolio_brokenheart.png';
import PortfolioRain from 'assets/images/homepage/portfolio_rain.png';
import TextFormat from './subComponents/TextFormat';
import { Button } from '../../../components/ReusableComponents/Button/Button';
import ButtonWithFunction from '../../../components/ReusableComponents/Button/ButtonWithFunction';
import getMarketsClassification from '../../../util/getMarketsClassification';

import { TODAY_MONTH, FULL_MONTH_NAME, HALF_MONTH_NAME  } from '../../../constants/GlobalConstant';
import SkLoaderOver from '../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';

const PortfolioModal = lazy(() => import('./subComponents/PortfolioModal'));


const PortfolioContainerDiv = styled(UpperMetricDiv)`
  height: ${({propHeight}) => propHeight};
  display: flex;
  flex-direction: column;

  margin: 0;
`;

const PortfolioItemDiv = styled.div`
  width: 100%;
  height: max-content;
  margin: 20px 0 0 0;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const PortfolioItem = styled.div`
  flex: 1;
  width: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 0 0;
  color: ${({itemColor}) => itemColor};
`;

export const PortfolioItemImage = styled.img`
  width: 32px;
  height: 32px;
  image-rendering: -webkit-optimize-contrast;
`;

const PortfolioItemImageUnderpriced = styled(PortfolioItemImage)`
  width: 40px;
  height: 40px;
  margin: -8px 0 0 0;
`;

const PortfolioItemImageOverpriced = styled(PortfolioItemImage)`
  width: 35px;
  height: 35px;
  margin: -4px 0 0 0;
`;

const PortfolioItemImageIssues= styled(PortfolioItemImage)`
  width: 38px;
  height: 38px;
  margin: -7px 0 0 0;
`;

export const PortfolioItemLabel = styled.h1`
  color: inherit;
  font-family: Quicksand;
  font-size: ${({theme}) => theme.s3};
  font-weight: 600;
`;

const PortfolioItemDescription = styled.p`
  margin: 0px;
  color:  ${({theme}) => theme.bodyFg};
  font-size: 0.625rem;
  text-align: center;
`;

const PortfolioValueRedirectButton = css`
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  margin: 20px 0 0 0;
  cursor: pointer;
  color: inherit;
  box-shadow: none;

  &:hover, &.active {
    background: none;
    color: inherit;
  }
`;

const NewProductButton = css`
  ${PortfolioValueRedirectButton}
  margin: 0 0 0 0;
`;

const PortfolioItemValue = styled(PortfolioItemLabel)`
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s8};
  line-height: 100%;
  border-bottom: 1px solid currentColor;
`;

const LostoutText = styled.p`
  margin: 2px 0 0 0;
  color: inherit;
  font-size: ${({theme}) => theme.s3};
  text-align: center;
  font-weight: 400;
  max-width: 100px;
`;

const ProductIssuesNewLabel = styled(PortfolioItemLabel)`
  margin: 2px 0 0 4px;
`;

const PortfolioHorizLine = styled.div`
  width: 116px;
  min-height: 1px;
  color: #E5E5E5;
  background: #E5E5E5;
  margin: 8px 0 0 0;
  margin: ${({margin}) => margin};
`;

const ProductNewValue = styled(PortfolioItemValue)`
  margin: 0 0 0 0;
  font-size: ${({theme}) => theme.s5};
`;

// const PortfolioDivLine = styled.div`
//   width: 1px;
//   min-width: 1px;
//   color: #E5E5E5;
//   background: #E5E5E5;
//   position: absolute;
//   top: 0px;
//   ${({height,left}) => `
//     height: ${height};
//     left: ${left};
//   `}
// `;

const PortfolioItemFooter = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;

  div {
    flex: 1;
  }
`;

const PortfolioButton = styled(Button)`
  flex: 1;
  width: 240px;
  height: 40px;

  background: linear-gradient(95.04deg, #56C6D9 17.59%, #C2A3F1 36.66%, #C2A3F1 61.37%, #F1BD5B 84.09%);
`;

const NewPropertiesDiv = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


  color: #00A4E6;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
`;

const PortfolioContainer = ({portfolio, isFetchingPortfolio, onMyPortfolioRedirect, currencySymbol, monthYear, onPropertyDetailsRedirect, filters}) => {
 const {star, underpriced, underpricedLostOutRevenue, underpricedRecapturableRevenue, overpriced, overpricedLostOutRevenue, overpricedRecapturableRevenue, productIssues, markets} = portfolio;
 const monthYearString = monthYear().split(' ')[0];


 const filterMonthStringIndex = (FULL_MONTH_NAME.indexOf(monthYearString) + 1) ;
 const sameMonth = filterMonthStringIndex === TODAY_MONTH;
//  const prevMonthIndex =  FULL_MONTH_NAME.indexOf(monthYearString) === 0 ? 11 : (FULL_MONTH_NAME.indexOf(monthYearString) - 1);
//  const prevMonth = HALF_MONTH_NAME[filters.filterBy === 'year' ? TODAY_MONTH - 2 : prevMonthIndex];
const currentMonth = HALF_MONTH_NAME[filters.filterBy === 'year' ? TODAY_MONTH - 1 : filterMonthStringIndex - 1];

 const theme = useTheme();

 const [isModalVisibleAndCategory, setIsModalVisibleAndCategory] = useState({visible: false, category: 'star'});

 const openModal = (category) => {
    if (portfolio[category] > 0) {
      setIsModalVisibleAndCategory((prev) => ({...prev, visible: true, category}));
    }
 };

 const closeModal = useCallback(() => {
  setIsModalVisibleAndCategory((prev) => ({...prev, visible: false}));
}, []);

const modalData = getMarketsClassification(markets);


 return (
  <Fragment>
    <PortfolioContainerDiv propHeight={sameMonth ? '500px' : '400px'}>
      <SectionTitle> Portfolio Overview </SectionTitle>
        <PortfolioItemDiv>
          {/* <PortfolioDivLine left="178px" height={sameMonth ? "330px" : "250px"}/>
          <PortfolioDivLine left="348px" height={sameMonth ? "330px" : "250px"}/>
          <PortfolioDivLine left="516px" height={sameMonth ? "330px" : "250px"}/> */}
          <PortfolioItem itemColor={theme.quibbleBlue}>
            <PortfolioItemImage src={PortfolioStar} alt='portfolio star' />
            <PortfolioItemLabel >
              STARS
            </PortfolioItemLabel>
            <PortfolioItemDescription>
              Higher Occupancy than Market
            </PortfolioItemDescription>
            <PortfolioItemDescription>
              {`Higher ADR than Prior ${monthYearString}`}
            </PortfolioItemDescription>
            <ButtonWithFunction
              ButtonStyle={PortfolioValueRedirectButton}
              label={(
              <PortfolioItemValue>
                {star}
              </PortfolioItemValue>)}
              onClickFunc={openModal}
              value='star'
            />
          </PortfolioItem>
          <PortfolioItem itemColor={theme.quibblePurple}>
            <PortfolioItemImageUnderpriced src={PortfolioGift} alt='portfolio gift' />
            <PortfolioItemLabel>
              UNDERPRICED
            </PortfolioItemLabel>
            <PortfolioItemDescription>
              Higher Occupancy than Market
            </PortfolioItemDescription>
            <PortfolioItemDescription>
              {`Lower ADR than Prior ${monthYearString}`}
            </PortfolioItemDescription>
            <ButtonWithFunction
              ButtonStyle={PortfolioValueRedirectButton}
              label={(
              <PortfolioItemValue>
                {underpriced}
              </PortfolioItemValue>)}
              onClickFunc={openModal}
              value='underpriced'
            />
            <PortfolioHorizLine />
            <TextFormat value={underpricedLostOutRevenue} symbol={currencySymbol} color="purple" size='medium' otherStyle='margin: 20px 0 0 0'/>
            <LostoutText>lost revenue</LostoutText>
            {
              sameMonth ? (
              <>
                <PortfolioHorizLine margin="16px 0 0 0"/>
                <TextFormat value={underpricedRecapturableRevenue} symbol={currencySymbol} color="purple" size='medium' otherStyle='margin: 20px 0 0 0'/>
                <LostoutText>recapturable</LostoutText>
              </> ) : null
            }
          </PortfolioItem>
          <PortfolioItem itemColor={theme.quibbleGold}>
            <PortfolioItemImageOverpriced src={PortfolioBrokenHeart} alt='portfolio brokenheart' />
            <PortfolioItemLabel>
              OVERPRICED
            </PortfolioItemLabel>
            <PortfolioItemDescription>
              Lower Occupancy than Market
            </PortfolioItemDescription>
            <PortfolioItemDescription>
              {`Higher ADR than Prior ${monthYearString}`}
            </PortfolioItemDescription>
            <ButtonWithFunction
              ButtonStyle={PortfolioValueRedirectButton}
              label={(
              <PortfolioItemValue>
                {overpriced}
              </PortfolioItemValue>)}
              onClickFunc={openModal}
              value='overpriced'
            />
            <PortfolioHorizLine />
            <TextFormat value={overpricedLostOutRevenue} symbol={currencySymbol} color="gold" size='medium' otherStyle='margin: 20px 0 0 0'/>
            <LostoutText>lost revenue</LostoutText>
            {
              sameMonth ? (
              <>
                <PortfolioHorizLine margin="16px 0 0 0"/>
                <TextFormat value={overpricedRecapturableRevenue} symbol={currencySymbol} color="gold" size='medium' otherStyle='margin: 20px 0 0 0'/>
                <LostoutText>recapturable</LostoutText>
              </>
              ) : null
            }
          </PortfolioItem>
          <PortfolioItem theme={theme.isDefault ? theme.quibbleFontGrey : theme.disabledGrey}>
            <PortfolioItemImageIssues src={PortfolioRain} alt='portfolio rain' />
            <PortfolioItemLabel>
              PRODUCT ISSUES
            </PortfolioItemLabel>
            <PortfolioItemDescription>
              Lower Occupancy than Market
            </PortfolioItemDescription>
            <PortfolioItemDescription>
              {`Lower ADR than Prior ${monthYearString}`}
            </PortfolioItemDescription>
            <ButtonWithFunction
              ButtonStyle={PortfolioValueRedirectButton}
              label={(
              <PortfolioItemValue>
                {productIssues}
              </PortfolioItemValue>)}
              onClickFunc={openModal}
              value='productIssues'
            />
          </PortfolioItem>
        </PortfolioItemDiv>
        <PortfolioItemFooter>
          <div>

          </div>
          <PortfolioButton onClick={onMyPortfolioRedirect}>
            Rates Advisor
          </PortfolioButton>
          {portfolio.new > 0 ? (
            <NewPropertiesDiv>
              <ButtonWithFunction
                ButtonStyle={NewProductButton}
                label={(
                <ProductNewValue>
                  {portfolio.new}
                </ProductNewValue>)}
                onClickFunc={openModal}
                value='new'
              />
              <ProductIssuesNewLabel>
                New Properties
              </ProductIssuesNewLabel>
          </NewPropertiesDiv>
          ) : <div></div>}
        </PortfolioItemFooter>
      <SkLoaderOver isLoading={isFetchingPortfolio} />
    </PortfolioContainerDiv>
    <Suspense fallback={null}>
      <PortfolioModal filters={filters} onPropertyDetailsRedirect={onPropertyDetailsRedirect}  modalData={modalData}  currencySymbol={currencySymbol} month={currentMonth} isModalVisibleAndCategory={isModalVisibleAndCategory} handleClose={closeModal} handleCancel={closeModal} />
    </Suspense>
  </Fragment>
 )
}

export default PortfolioContainer;
