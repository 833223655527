import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from '../../../../../components/ReusableComponents/Text/Text';
import { isNil } from 'lodash';

const SignDiv = styled.div`
  width: 100%;
  height: 26px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
`;

const Middleborder = styled.div`
  width: 0.5px;
  height: 100%;
  position: absolute;
  border-right: 0.5px solid black;
  left: 0;
  right: 0;
  margin: auto;
  border-style: dotted;
`;

const PurpleDiv = styled.div`
  width: 50%;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const BlueDiv = styled(PurpleDiv)`
  justify-content: flex-start;
`;

const PropertyNegative = styled.div`
  width: ${({ colorWidth }) => colorWidth ? colorWidth : '100%'};
  background: #C6A1F5;
`;

const MutliplierTooltipDiv = styled.div`
  ${BodyCSS}
  width: 150px;
  height: fit-content;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CirclePortfolio = styled.span`
  width: 10px;
  height: 10px;
  background: #BDEBF9;
  border-radius: 100%;
`;

const CirclePortfolioNegative = styled(CirclePortfolio)`
  background: #E8D9FB;
`;

const CircleEvent = styled(CirclePortfolio)`
  background: #FFEBA6;
`;

const EventPositive = styled.div`
  width: ${({ colorWidth }) => colorWidth ? colorWidth : '100%'};
  background: #FCA484;
`;


const BodyTextTooltip = styled(BodyText)`
  align-self: flex-start;
  padding: 0 0 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0px 0px 8px 0;
`;


const Multiplier = ({ data, selected, multiplier }) => {
  const [value, setValue] = useState(data?.multiplier)
  const [eventSize, setEventSize] = useState(0);

  useEffect(() => {
    let eventMultiplier = !isNil(data?.multiplier) ? data.multiplier : 0;
    
    if (selected.includes(data._id)) {
      const additionalMultiplier = multiplier ?? 0;
      eventMultiplier = eventMultiplier + additionalMultiplier;
    }

    const size = `${Math.abs(eventMultiplier)}%`;
    setValue(eventMultiplier);
    setEventSize(size)
  }, [data, selected, multiplier]);

  return (
    <Tooltip title={<MutliplierTooltipDiv>
      <BodyText margin="8px 0 12px 0"> Multiplier Profile </BodyText>
      <BodyTextTooltip> {value >= 0 ? <> <CircleEvent />  {`Event: +${value}`} </> : <><CirclePortfolioNegative /> {`Event: ${value}`} </>} </BodyTextTooltip>
    </MutliplierTooltipDiv>
    } >
      <SignDiv>
        <PurpleDiv>
          {value < 0 ? <PropertyNegative colorWidth={eventSize} /> : null}
        </PurpleDiv>
        <BlueDiv>
          {value > 0 ? <EventPositive colorWidth={eventSize} /> : null}
        </BlueDiv>
        <Middleborder />
      </SignDiv>
    </Tooltip>
  )
}

export default Multiplier;