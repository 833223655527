import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Input, message} from "antd";
import IntlMessages from "util/IntlMessages";
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from "react-redux";
import { showAuthLoader,hideAuthLoader } from "../../../appRedux/actions";

import {user} from '../../../services/user';
import {auth} from '../../../services/auth';
import { ALPHANUMERIC } from "../../../constants/GlobalConstant";
import CircularProgress from "../../../components/CircularProgress";

import logo from '../../../assets/images/quibblerm.png'

const FormItem = Form.Item;


const ResetPassword = () => {
 const {forgotPasswordToken} = useParams();
 const {authUser, loader} = useSelector(({auth}) => auth);
 const dispatch = useDispatch();
 const history = useHistory();
 const [resetPasswordForm] = Form.useForm();


 const [tokenexpired, setTokenExpired] = useState(false);


 useEffect(() => {

  async function checkPasswordToken () {
    const {data} = await user.checkForgotPasswordToken({token:forgotPasswordToken});
    const {status, message: tokenResponseMessage} = data;


    /** if expired notify user expired and ask user to request again */
    if (!status) {
      if (tokenResponseMessage) {
        setTokenExpired(true);
        return;
      };
      history.push('/');
    }
  }

  if(!authUser) {
    /* if not authenticated, check forgotpassword token if expired or not */
    checkPasswordToken();
    return;
  };

  /*if authenticated and tried access reset password page outside the homepage */

  if(history.location.pathname !== "/setting/reset-password") {
    history.push('/home');
  };

 }, [authUser, forgotPasswordToken, history]);

const onResetPasswordClick = useCallback(async() => {

  try {
    await resetPasswordForm.validateFields();
    const {password, confirm} = resetPasswordForm.getFieldsValue();

    let token = localStorage.getItem("token");
    let id = localStorage.getItem("user_id");

    if (password !== confirm) {
      resetPasswordForm.setFields([
        {
          name: 'password',
          errors: ['passwords do not match.'],
        },
        {
          name: 'confirm',
          errors: ['passwords do not match.'],
        },
      ])

        return;
    }

    dispatch(showAuthLoader());

    const {data} = await auth.resetPassword({forgotPasswordToken, password, id, token,  });

    if (!data.status) {
      message.error(data.message, 10);
      dispatch(hideAuthLoader());
      return;
    };

    message.success(data.message, 5);
    resetPasswordForm.resetFields();
    history.push('/home');
    dispatch(hideAuthLoader());


  } catch (e) {
    message.error(e.toString(), 10);
    dispatch(hideAuthLoader());

  }
}, [forgotPasswordToken, resetPasswordForm, dispatch, history]);


const checkExpired = useCallback(() => {

  const proceedLogin = () => {
    history.push('/');
  };

  if (tokenexpired) {
    return (
      <>
        <h2>Request expired, please ask for a password reset request on the login page</h2>
        <Button className="gx-mt-4" type="primary" onClick={proceedLogin}>
          Go back to login
        </Button>
      </>
    )
  }
  
  return (
    <Form
      form={resetPasswordForm}
      initialValues={{ remember: true }}
      name="resetPasswordForm"
      className="gx-signin-form gx-form-row0">
        <FormItem
          name="password"
          rules={ [{required: true, message: 'Please input your new Password!'},
          {min: 6, message: 'Password should be at least 6 characters in length.'},
          {max: 12, message: 'Password should be 12 characters maximum.'},
          {pattern: ALPHANUMERIC, message: 'Password should be at least alphanumeric.'},
          ]}
        >
          <Input disabled={loader} className='gx-input-lineheight' type="password" placeholder="New Password"/>
        </FormItem>
        <FormItem  name="confirm" rules={ [{required: true, message: 'Please confirm your new password!'}]} >
          <Input disabled={loader}  className='gx-input-lineheight' placeholder="Retype New Password" type="password"/>
        </FormItem>
        <Button type="primary" onClick={onResetPasswordClick}>
          <IntlMessages id="app.userAuth.reset"/>
        </Button>
    </Form>
  )

}, [tokenexpired, onResetPasswordClick, resetPasswordForm, history, loader]);

const showLoader = useCallback(() => {
  if (loader) {
    return (
      <CircularProgress className="app-loader-view-small"/>
    )
  }
}, [loader])

  return (
    <>
    <div className="gx-login-container">
      <div className="gx-login-content reset-password">
        <div className="gx-login-header">
          <img src={logo} alt="wieldy" title="wieldy"/>
        </div>
        {showLoader()}
        <div className="gx-mb-4">
          <h2>Reset Password</h2>
          <p>{!tokenexpired && <IntlMessages id="appModule.enterPasswordReset"/>}</p>
        </div>
      {
        checkExpired()
      }
      </div>
    </div>

    </>
  );
};

export default ResetPassword;
