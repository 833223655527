import React, { useState } from 'react';
import { Line, Bar, XAxis, YAxis, ComposedChart, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import CustomTooltip from './CustomTooltip';

const labelStyle = {
  textAnchor: 'middle',
  fontSize: "18px",
  color: "#283334",
  fontWeight: 300
};

const Chart = ({
  data = [],
  view = 'monthly',
  bars,
  lines,
  yLabelLeft,
  yLabelRight,
  columns,
  domain,
  tooltip,
  yTickFormatterLeft,
  yTickFormatterRight,
  revenueFormatter,
  formatText,
  formatOcc,
  year,
  hasAdjustPricingButton,
}) => { 

  const [position, setPosition] = useState({ x: null, y: null });
  const [active, setActive] = useState(null);
  const [overrideTooltip, setOverrideTooltip] = useState(false);

  const setDataKey = (o) => {
    if (view === 'daily') {
      return moment().day(o.key).format('dddd');
    } else if (view === 'weekly') {
      return o.key;
    } else {
      return moment().month(o.key - 1).format('MMM')

    }
  }

  const handleOnMouseUp = (e) => {
    if (!hasAdjustPricingButton) return;
    if (overrideTooltip) {
      setOverrideTooltip(false);
      setActive(false);
      setPosition({ x: null, y: null })
    } else {
      setOverrideTooltip(true);
      setActive(true);
      setPosition({ x: e?.chartX, y: 100 })
    }
  }

  return (
    <ResponsiveContainer
      width={columns === 2 ? '100%' : '90%'}
      height={columns === 2 ? '80%' : '85%'}
      className="rc-container"
    >
      <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: columns === 2 ? 25 : 25.5,
          left: 20.5,
          bottom: 5,
        }}
        onMouseUp={handleOnMouseUp}
      >
        <XAxis dataKey={setDataKey} offset={20} stroke="#D9D9D9" padding={{ left: 20, right: 20 }} />
        <XAxis
          domain={['auto', 100]}
          xAxisId="top"
          orientation="top"
          type="number"
          tick={false}
          stroke="#D9D9D9"
        />
        <YAxis
          tickLine={false}
          stroke="#D9D9D9"
          label={{ value: yLabelLeft, angle: -90, position: 'left', style: labelStyle }}
          domain={domain}
          tickFormatter={formatText ? revenueFormatter : yTickFormatterLeft}
        />

        <YAxis
          domain={[0, 100]}
          yAxisId="right"
          orientation="right"
          tickLine={false}
          type="number"
          tick={bars.filter(bar => bar.yAxisId === 'right').length > 0 ? true : false}
          stroke="#D9D9D9"
          label={{ value: yLabelRight, angle: 90, position: 'right', style: labelStyle }}
          tickFormatter={formatOcc ? yTickFormatterRight : ''}
        />

        <Tooltip
          active={overrideTooltip ? active : null}
          position={overrideTooltip ? position : null}
          content={<CustomTooltip tooltip={tooltip} view={view} year={year} hasAdjustPricingButton={hasAdjustPricingButton} />}
        />

        {bars?.length > 0 && (
          bars.map((bar, ix) => {
            return (
              <Bar key={ix} {...bar} />
            )
          })
        )}

        {lines?.length > 0 && (
          lines.map((line) => {
            return (
              <Line key={line.dataKey} strokeWidth={1} {...line} />
            )
          })
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default Chart;
