import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import piechartPurple from 'assets/images/market/piechartpurple.svg';
import { lightScrollbar, darkScrollbar } from "components/ReusableComponents/Scrollbar/Scrollbar";
import ImageRating from '../../../../../../components/ReusableComponents/Image/ImageRating';
import quibbleRm from 'assets/images/quibblerm.png';
import { BodyTextCSS } from '../../../../../../components/ReusableComponents/Text/Text';
import { currencyFormatter, showName, debouncer } from '../../../../../../util/helper';
import SkLoaderOver from '../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { Dropdown, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import ReplaceCompetitorModal from './ReplaceCompetitorModal';

import { property } from '../../../../../../services/property';
import { useDispatch } from 'react-redux';
import { getPropertyModel } from '../../../../../../appRedux/actions';

const ComparisonTableDiv = styled.div`
  height: 100%;
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  ${({comparisonDivCSS}) => comparisonDivCSS};
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)}
`;

const PropertyDiv = styled.div`
  height: 80px;
  border-bottom: 1px solid ${({theme}) => theme.dirtyGrey};
  background: ${({theme}) => theme.quibbleHoverGrey};
  padding: 10px 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;

  ${({propertyDivCSS}) => propertyDivCSS};

  opacity: ${({isDisabled}) => isDisabled ? 0.4 : 1};
`;

const PropertyItem = styled.div`
  width: 134px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  ${BodyTextCSS}
`;

const PercentShareDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 0 0 0 0;


  font-size: ${({theme}) => theme.s4};
  color: ${({theme}) => theme.quibblePurple};
  font-family: Commissioner;
  font-weight: 300;
`;

const ThumbnailImageDiv = styled.div`
  width: 120px;
  height: 60px;
  position: relative;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const MoreOptionsDropdown = styled.div`
  color: #697273;
  position: relative;
  top: -20px;
  right: 0;
`;

const ComparisonTableFull = ({dataArray, isFetchingPropertyModel, isListing, propertyDetails}) => {

  const dispatch = useDispatch();
  
  const [modalVisible, setModalVisible] = useState(false);
  const [currentCompetitorId, setCurrentCompetitorId] = useState();


  const comparisonDivCSS = isListing ?  css`
    width: 700px;
    min-width: 700px;
  ` : css`
    min-width: 1040px;
  `;

  const propertyDivCSS = isListing ? css`
    width: 1040px;
  `: css`
    width: 100%;
  `;

  const items = [
    {
      key: '1',
      label: (
        <div>Replace</div>
      ),
      onClick: () => showModal(),
    },
    {
      key: '2',
      label: (
        <div>Auto-Replace</div>
      ),
      onClick: () => onAutoReplaceClick(),
    },
    {
      key: '3',
      label: (
        <div>Remove</div>
      ),
      onClick: () => onDeleteClick(),
    },
  ];
  
  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const clickDropDown = (id) => {
    setCurrentCompetitorId(id);
  };

  const autoReplaceCompetitor = async () => {
    try {
      const body = {
        sourceId: currentCompetitorId,
        auto: true,
      }
      const propertyId = propertyDetails.id;

      const res = await property.replacePropertyCompetitor({ propertyId, body });

      if (res.status === 200) {
        dispatch(getPropertyModel({propertyId}))
        message.destroy();
        message.success('Updated competitors saved.');
        return;
      }
      
      message.error('Updating competitors failed.');
    } catch (e) {
      message.destroy();
      console.error(e);
      message.error('Updating competitors error.');
    }
  };

  const onAutoReplaceClick = debouncer(autoReplaceCompetitor, 500);

  const deleteCompetitor = async () => {
    try {
      const body = {
        sourceId: currentCompetitorId,
        targetId: null
      }
      const propertyId = propertyDetails.id;

      const res = await property.replacePropertyCompetitor({ propertyId, body });

      if (res.status === 200) {
        dispatch(getPropertyModel({propertyId}))
        message.destroy();
        message.success('Updated competitors saved.');
        return;
      }

      message.error('Updating competitors failed.');
    } catch (e) {
      message.destroy();
      console.error(e);
      message.error('Updating competitors error.');
    }
  };

  const onDeleteClick = debouncer(deleteCompetitor, 500);

  return (
    <ComparisonTableDiv comparisonDivCSS={comparisonDivCSS}>
      {!isFetchingPropertyModel && dataArray?.map((data, index) => {
         const imageData = data?.images[0]?.image_url || quibbleRm;
         const imageDataRating = (data?.images[0]?.score)?.toFixed(0) || 0;
        return (
          <PropertyDiv propertyDivCSS={propertyDivCSS} key={`${data.name} ${index}`} isDisabled={!data.market_share || data.market_share === 0}>
            <PropertyItem>
              {showName(data?.name, 14) || 'No Property'}
              {
                data.market_share ?
                  <PercentShareDiv>
                    <img src={piechartPurple} alt='piechart' width={"16px"} height={"15px"}/>
                    {((data.market_share * 100) || 0).toFixed(0)}% share
                  </PercentShareDiv>
                  :
                  <span>Unavailable</span>
              }
            </PropertyItem>
            <ThumbnailImageDiv>
              <ThumbnailImage src={imageData} alt="property image" />
              <ImageRating value={imageDataRating} variant="small"/>
            </ThumbnailImageDiv>
            <PropertyItem>
              <span>{data.price && (`${currencyFormatter(data?.price)} Night`)}</span>
              <span>Min Stay: {data?.min_nights || 0} nights</span>
            </PropertyItem>
            <PropertyItem>
              <span> Bedroom: {data?.bedrooms || 0}</span>
              <span> Scenic Views: {data?.landscape_views ? 'YES' : 'NO'}</span>
            </PropertyItem>
            <PropertyItem>
              <span> Rating: {(data?.rating_value || 0).toFixed(1)}</span>
              {/* <span> {showName('This house has all the amenities', 18)} </span> */}
            </PropertyItem>
            <PropertyItem>
              <span> HT/Jacuzzi: {data?.jacuzzi ? 'YES' : 'NO'}</span>
              <span> Pool: {data?.pool ? 'YES' : 'NO'}</span>
              {/* <span> Downtown: 20m</span> */}
            </PropertyItem>
            <PropertyItem>
              <span> Reviews: {data?.review_count || 0}</span>
              {/* <span> Pool: NO</span> */}
            </PropertyItem>
            
            <MoreOptionsDropdown>
              <Dropdown onClick={(e) => { stopPropagation(e); clickDropDown(data.airBnbId); }} menu={{ items }} placement="bottomRight" trigger="click">
                <MoreOutlined />
              </Dropdown>
            </MoreOptionsDropdown>
          </PropertyDiv>
        )
      })}
      <SkLoaderOver isLoading={isFetchingPropertyModel} type='table' tableRows={10} />
      <ReplaceCompetitorModal open={modalVisible} onCancel={handleCancel} currentCompetitorId={currentCompetitorId} />
    </ComparisonTableDiv>
  )
}

export default ComparisonTableFull;