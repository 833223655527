import React,{useState} from "react";
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {message} from 'antd'

const ContainerDivvv = styled.div`
  display : flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;
const Divv = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
`;
const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SecondDiv = styled.div`
  width: 50%;
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const LeftParagraphText = styled.p`
  font-size: 2.25em;
  font-weight: 300;
  text-align: center;

`;
const LogoImage = styled.p`
  margin: 120px 0px;
  display: flex;
  justify-content: center;
`;
const Texts = styled.p`
  position: absolute;
  width: 100%;
  color: white;
  line-height: 30px;
  padding-top: 70px;
`;

const Text1 = styled.p`
  font-size: 36px;
  font-weight: 300;
  width: 100%;
  text-align: center;
  // max-width: 200px;

`;
const Text2 = styled.p`
  font-size: 54px;
  font-weight: 600;
  width: 100%;
  text-align:center;
  line-height: 10px;
`;
const SkyScraperImg = styled.img`
  height: 100vh;
  width: 100%;
`;

const PasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 54px;

`;
const PasswordInput = styled.input`
  width: 500px;
  padding: 16px 8px;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  outline:none;
`;

const Label1 = styled.label`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-weight: 300;
  width: 500px;
`;

const Label2 = styled.label`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-weight: 300;
  width: 500px;
  margin-top: 24px;
  margin-bottom: 5px;
`;

const MyButton = styled.button`
  padding: 16px 16px;
  margin-top: 24px;
  color: white;
  border:none;
  border-radius: 4px;
  background: #1BB3CD;
  font-size: 16px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity:  ${({ isDisabled }) => (isDisabled ? '30%' : '100%')};
`;

const SendOTP = ({ setStep }) => {
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [validEmail, setValidEmail] = useState(true);


  let baseURL = process.env.REACT_APP_API_URL;

  const OTPRequest = async (email) => {
    try {
      const response = await fetch(`${baseURL}/api/reset-password/otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.status === 200) {
        setEmail(email);
        message.success('Email successfully sent!');
        setTimeout(() => {
          setStep('verifyOTP', email);
        }, 3000)
      } else {
          message.error('Something went wrong. Please try again');

      }
    } catch (error) {
        message.error('An error has occured!');
        console.log(error);
    }
  };

  const validateEmail = (input) => {
  const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return emailPattern.test(input);
  };

  const handleInputChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setValidEmail(validateEmail(inputEmail))

    if (validEmail === true) {
      setDisabled(false)
    } else {
      setDisabled(true)
    };

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    OTPRequest(email);
    setEmail('');
    setDisabled(true);

  };

  return (

    <ContainerDivvv>
      <Divv>
        <FirstDiv>
          <div>
            <LogoImage>
            <img src="/assets/images/quibble-logo.svg" alt="" width="200px" height="90px"  />
            </LogoImage>
            <div>
              <LeftParagraphText>Forgot Password?</LeftParagraphText>
              <PasswordForm onSubmit={handleSubmit}>
                <Label1>Enter the email address you used to sign up to Quibble and we will send you a code to reset your password.</Label1>
                <Label2>Email</Label2>
                <PasswordInput type="email" value={email}
                  onChange={handleInputChange} />

                {!validEmail && <p style={{ color: 'red' }}>Please enter a valid email</p>}

                <MyButton type="submit" isDisabled = {disabled}>
                  Send
                </MyButton>


              </PasswordForm>
            </div>
          </div>

        </FirstDiv>
      </Divv>
      <SecondDiv>
          <Texts>
            <Text1>Take the guesswork out of pricing decisions </Text1>
            <Text2>Increase your revenue by 30% </Text2>
          </Texts>

          <SkyScraperImg src="/assets/images/skyscraper.png" alt=""/>
      </SecondDiv>
    </ContainerDivvv>

  )
}

export default SendOTP

SendOTP.propTypes = {
  setStep: PropTypes.func.isRequired,
};
