import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  USER_DASHBOARD,
  USER_DASHBOARD_SUCCESS,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_SUCCESS,
  GET_LOGIN_USER_DETAILS,
  GET_LOGIN_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  SHOW_ERROR_MESSAGES,
  GET_USERS_LISTING,
  GET_USERS_LISTING_SUCCESS,
  USER_PROFILE_ERROR_MESSAGES,
  USER_PROFILE_NOTIFY_ERROR_MESSAGES,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  UPDATE_USER_STATUS_SUCCESS,
  FIVE_HUNDRED_ERROR,
  FIVE_HUNDRED_ERROR_SUCCESS,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_INSIGHT_MODAL_TYPE,
  SET_INSIGHT_MODAL_TYPE,
  LISTINGS_WATCH,
  LISTINGS_WATCH_SUCCESS,
  LISTINGS_WATCH_BUILDING,
  LISTINGS_WATCH_BUILDING_SUCCESS,
  MARKET_WATCH,
  MARKET_WATCH_SUCCESS,
  NOTIFICATION_WATCH,
  NOTIFICATION_WATCH_SUCCESS,
  GET_USER_METRICS_SUMMARY,
  GET_USER_METRICS_SUMMARY_SUCCESS,
  GET_USER_PORTFOLIO,
  GET_USER_PORTFOLIO_SUCCESS,
  UPDATE_USER_PMS,
  UPDATE_USER_PMS_SUCCESS,
} from "../../constants/ActionTypes";

/*** Set initial state */
const INIT_STATE = {
  loader: false,
  preLoader : false,
  marketDataLoaded: false,
  listingWatchLoaded: false,
  dashboardDetailsLoaded : false,
  alertMessageType:false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  result: false,
  notificationDataLoaded: false,
  dashboardDetail : {},
  propertyData : [],
  authUserDetails       : {},
  userSelected       : {},
  errors : {},
  usersListData  : [],
  countryList:  {},
  marketsData : {},
  listingsData : {},
  notificationData : {},
  listingsDataBuilding : {},
  insightModalData: {},
  metricsSummary: {},
  isFetchingMetricSummary: false,
  portfolio: {
    star: 0,
    underpriced: 0,
    overpriced: 0,
    productIssues: 0,
    new: 0,
    underpricedLostOutRevenue: 0,
    overpricedLostOutRevenue: 0,
    markets: [],
  },
  isFetchingPortfolio: false,
};


const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_DASHBOARD: {
      return {
        ...state,
        preLoader : true,
        dashboardDetailsLoaded : false,
        dashboardDetail : {}
      }
    }

    case USER_DASHBOARD_SUCCESS: {
      return {
        ...state,
        result:true,
        preLoader : false,
        dashboardDetailsLoaded : true,
        dashboardDetail: action.payload
      }
    }

    case LISTINGS_WATCH: {
      return {
        ...state,
        preLoader : true,
        listingWatchLoaded: false,
        listingsData : {}
      }
    }

    case LISTINGS_WATCH_SUCCESS: {
      return {
        ...state,
        result:true,
        preLoader : false,
        listingWatchLoaded: true,
        listingsData: action.payload
      }
    }

    case NOTIFICATION_WATCH: {
      return {
        ...state,
        preLoader : true,
        notificationDataLoaded: false,
        notificationData : {}
      }
    }

    case NOTIFICATION_WATCH_SUCCESS: {
      return {
        ...state,
        result:true,
        preLoader : false,
        notificationDataLoaded: true,
        notificationData: action.payload
      }
    }

    case LISTINGS_WATCH_BUILDING: {
      return {
        ...state,
        preLoader : true,
        listingsDataBuilding : {}
      }
    }

    case LISTINGS_WATCH_BUILDING_SUCCESS: {
      return {
        ...state,
        result:true,
        preLoader : false,
        listingsDataBuilding: action.payload
      }
    }

    case MARKET_WATCH: {
      return {
        ...state,
        preLoader : true,
        marketDataLoaded: false,
        marketsData : {}
      }
    }

    case MARKET_WATCH_SUCCESS: {
      return {
        ...state,
        result:true,
        preLoader : false,
        marketDataLoaded: true,
        marketsData: action.payload
      }
    }


    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
        preLoader:true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
        preLoader:false
      }
    }

    case GET_LOGIN_USER_DETAILS: {
      return {
        ...state,
        loader: true,
        showMessage: true,
      }
    }

    case GET_LOGIN_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        authUserDetails: action.payload,
      }
    }

    case GET_USER_DETAILS: {
      return {
        ...state,
        loader: true,
        errors : {}
      }
    }

    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        userSelected: action.payload
      }
    }

    case USER_UPDATE_PROFILE: {
      return {
        ...state,
        loader : true,
        errors : {},
        userSelected: {}
      }
    }

    case USER_UPDATE_PROFILE_SUCCESS: {
      let newState = {
        userSelected : action.payload
      };

      if (action.isSelf) newState.authUserDetails = { ...state.authUserDetails, ...action.payload};

      return {
        ...state,
        loader: false,
        showMessage: false,
        ...newState
      }
    }

    case SHOW_ERROR_MESSAGES: {
      return {
        ...state,
        initURL: '/setting/update_profile',
        loader: false,
        showMessage: false,
        errors : action.payload
      }
    }

    case USER_PROFILE_ERROR_MESSAGES: {
      return {
        ...state,
        initURL: '/setting/update_profile',
        loader: false,
        showMessage: false,
        errors : action.payload
      }
    }

    case GET_USERS_LISTING: {
      return {
        ...state,
        loader        : true,
        usersListData : []
      }
    }

    case GET_USERS_LISTING_SUCCESS: {
      return {
        ...state,
        loader        : false,
        usersListData : action.payload
      }
    }

    case USER_PROFILE_NOTIFY_ERROR_MESSAGES: {
      return {
        ...state,
        alertMessageType:action.payload.status,
        alertMessage: action.payload.message,
        showMessage: true,
        loader: false
      }
    }

    case USER_CHANGE_PASSWORD: {
      return {
        ...state,
        loader : true,
        errors : {}
      }
    }

    case USER_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
      }
    }


    case UPDATE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
      }
    }


    case FIVE_HUNDRED_ERROR: {
      return {
        ...state,
        initURL: '/error-pages/error-500',
        loader: false,
        showMessage: false,
      }
    }

    case FIVE_HUNDRED_ERROR_SUCCESS: {
      return {
        ...state,
        initURL: ''
      }
    }

    case GET_COUNTRY_LIST: {
      return {
        ...state,
        loader : true,
        countryList : []
      }
    }
    case GET_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        countryList: action.payload,
      }
    }
    case GET_INSIGHT_MODAL_TYPE: {
      return {
        ...state,
        // loader : true,
        data : {}
      }
    }
    case SET_INSIGHT_MODAL_TYPE: {
      return {
        ...state,
        // loader: false,
        insightModalData: action.payload,
      }
    }
    case GET_USER_METRICS_SUMMARY: {
      return {
        ...state,
        isFetchingMetricSummary: true,
      }
    }
    case GET_USER_METRICS_SUMMARY_SUCCESS: {
      return {
        ...state,
        metricsSummary: action.payload,
        isFetchingMetricSummary: false,
      }
    }
    case GET_USER_PORTFOLIO: {
      return {
        ...state,
        isFetchingPortfolio: true,
      }
    }
    case GET_USER_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        portfolio: action.payload,
        isFetchingPortfolio: false,
      }
    }
    case UPDATE_USER_PMS: {
      return {
        ...state,
        loader: true,
        showMessage: false,
        alertMessage: '',
        alertMessageType: false,
      }
    }
    case UPDATE_USER_PMS_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: true,
        alertMessage: 'Successfully updated connection',
        alertMessageType: true,
      }
    }
    default:
      return state;
  }
}

export default UserReducer;
