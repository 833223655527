import React from 'react';
import styled from 'styled-components';
import { UpperMetricDiv } from './MetricContainerTop';
import { SectionTitle } from './MetricContainerTop';
import TextFormat from './subComponents/TextFormat';
import SkLoaderOver from '../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';

const LowerMetric = styled(UpperMetricDiv)`
  padding: ${({lowerMetricPadding}) => lowerMetricPadding};
  padding: 20px;

  margin: 0 0 0 16px;
`;

const LowerMetricItem = styled.div`
  width: 100%;

  flex: 1;
  display: flex;
  flex-direction: row;

  border-radius: 4px;
  background: #EFEFEF;
  padding: 32px 20px 20px;
  margin-bottom: 16px;
`;

// const UpperItem = styled(LowerMetricItem)`
//   border-radius: 10px 10px 0 0;
// `;

// const LowerItem = styled(LowerMetricItem)`
//   border-radius: 0 0 10px 10px;
//   margin-bottom: 0;
// `;

const ItemDiv = styled.div`
  height: 100%;
  width: 164px;
  display: flex;
  flex-direction: column;
  
  flex: 1;
`;

const ValueDiv = styled(ItemDiv)`
  width: 120px;
  justify-content: flex-start;
  align-items: center;
  
  flex: 1;
`;

const ProjectedDiv = styled(ValueDiv)`
  display: flex;
  width: 116px; 
  border-radius: ${({borderCss}) => borderCss};
  
  flex: 1;
`;

// const DividerLine = styled.div`
//   width: 354px; 
//   height: 1px;
//   color:  #E5E5E5;
//   background: #E5E5E5;
//   position: absolute;
//   top: 133px;
//   left: 0px; 
//   right: 0px;
//   margin: auto;
// `;

// const DividerLineSecond = styled(DividerLine)`
//   top: 266px;
// `;

const SubTitle = styled.p`
  margin: 0px;
  font-size: ${({theme}) => theme.s2};
  color: ${({theme}) => theme.bodyFg};
  font-weight: 400;

  color: #697273;
  font-family: Commissioner;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const RevParSub = styled(SubTitle)`
  margin: 4px 0 0 0;
`;


const MetricContainerBottom = ({metricsData, isFetchingMetricSummary}) => {
  return (
    <LowerMetric lowerMetricPadding={isFetchingMetricSummary ? '24px': '0px'}>
      {/* <DividerLine />
      <DividerLineSecond /> */}
      <LowerMetricItem>
        <ItemDiv borderCss='10px 0 0 0'>
          <SectionTitle>
            ADR
          </SectionTitle>
          <SubTitle>
            Average Daily Rate
          </SubTitle>
        </ItemDiv>
        <ValueDiv >
          <div>
            <TextFormat value={metricsData['ADR'].value} symbol={metricsData['ADR'].symbol} label='booked'/>
            <TextFormat value={metricsData['ADR'].diffValue} symbol={metricsData['ADR'].symbol} size='small' label='' type={metricsData['ADR'].diffSign} />
          </div>
        </ValueDiv>
        <ProjectedDiv borderCss='0 10px 0 0' >
          <TextFormat value={metricsData['ADR'].projectedValue} color='foreground' symbol={metricsData['ADR'].symbol} label='projected'/>
          {/* <TextFormat value={metricsData['ADR'].projectedDiff} symbol={metricsData['ADR'].symbol} size='medium' label='' type={metricsData['ADR'].projectedDiffSign} otherStyle='margin: 12px 0 0 0'/> */}
        </ProjectedDiv>
      </LowerMetricItem>
      <LowerMetricItem>
        <ItemDiv>
          <SectionTitle>
            Occupancy
          </SectionTitle>
        </ItemDiv>
        <ValueDiv>
          <div>
            <TextFormat value={metricsData['occupancy'].value} symbol={metricsData['occupancy'].symbol} label='booked'/>
            <TextFormat value={metricsData['occupancy'].diffValue} symbol={metricsData['occupancy'].symbol} size='small' label='' type={metricsData['occupancy'].diffSign} />
          </div>
        </ValueDiv>
        <ProjectedDiv >
          <TextFormat value={metricsData['occupancy'].projectedValue} color='foreground' symbol={metricsData['occupancy'].symbol} label='projected' />
          {/* <TextFormat value={metricsData['occupancy'].projectedDiff} symbol={metricsData['occupancy'].symbol} size='medium' label='' type={metricsData['occupancy'].projectedDiffSign} otherStyle='margin: 12px 0 0 0'/> */}
        </ProjectedDiv>
      </LowerMetricItem>
      <LowerMetricItem>
        <ItemDiv borderCss='0 0 0 10px'>
          <SectionTitle>
            RevPAR
          </SectionTitle>
          <SubTitle>
            Revenue per available room-night
          </SubTitle>
          <RevParSub>
            (ADR x Occupancy)
          </RevParSub>
        </ItemDiv>
        <ValueDiv >
          <div>
            <TextFormat value={metricsData['revPAR'].value} symbol={metricsData['revPAR'].symbol} label='booked'/>
            <TextFormat value={metricsData['revPAR'].diffValue} symbol={metricsData['revPAR'].symbol} size='small' label='' type={metricsData['revPAR'].diffSign} />
          </div>
        </ValueDiv>
        <ProjectedDiv borderCss='0 0 10px 0' >
          <TextFormat value={metricsData['revPAR'].projectedValue} color='foreground' symbol={metricsData['revPAR'].symbol} label='projected' />
          {/* <TextFormat value={metricsData['revPAR'].projectedDiff} symbol={metricsData['revPAR'].symbol} size='medium' label='' type={metricsData['revPAR'].projectedDiffSign} otherStyle='margin: 12px 0 0 0'/> */}
        </ProjectedDiv>
      </LowerMetricItem>
      <SkLoaderOver isLoading={isFetchingMetricSummary} />
  </LowerMetric>
  )
  
}

export default MetricContainerBottom;