import React from 'react';
import styled from 'styled-components';

const NewGroupTitle = styled.h1`
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
`;

const NestedPropertyContainer = styled.div`
  padding: 30px;
  width: 100%;
  border-radius: 8px;
`;

const PropertyItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ChildItem = styled(PropertyItem)`
  margin-left: 50px;
  position: relative;
`;

const PropertyImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 16px;
  object-fit: cover;
`;

const PropertyName = styled.div`
  font-size: 14px;
  color: #333;
`;

const GreyLineWrapper = styled.div`
  position: absolute;
  left: -30px;
  top: -30px;
  width: 30px;
  height: 60px;

  border-left: 1px solid #CDCDCD; 
  border-bottom: 1px solid #CDCDCD;
`;

const NestedPropertyDisplay = ({ parentProperty, childProperties, listings }) => {

  if (!Array.isArray(listings) || listings.length === 0) {
    return <div></div>;
  }

  return (
    <NestedPropertyContainer>
      <NewGroupTitle>Nested Property</NewGroupTitle>

      {/* Display Parent Property */}
      {listings
        .filter((listing) => listing.id === parentProperty)
        .map((listing) => (
          <PropertyItem key={listing.id}>
            <PropertyImage src={listing.image} alt={listing.name} />
            <PropertyName>{listing.name}</PropertyName>
          </PropertyItem>
        ))}

      {/* Display Child Properties */}
      {childProperties.map((childId) =>
        listings
          .filter((listing) => listing.id === childId)
          .map((listing) => (
            <ChildItem key={listing.id}>
              <GreyLineWrapper/>
              <PropertyImage src={listing.image} alt={listing.name} />
              <PropertyName>{listing.name}</PropertyName>
            </ChildItem>
          ))
      )}
    </NestedPropertyContainer>
  );
};

export default NestedPropertyDisplay;
