import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { OptimizeBody as OptimizedBodyCommon } from '../../CommonCSS';
import { SecondaryButton } from 'components/ReusableComponents/Button/Button';
import { BodyTitle } from "components/ReusableComponents/Text/Text";
import QuibbleMenu from 'components/ReusableComponents/Menu/Menu';
import SelectCalendar from 'components/ReusableComponents/Calendar/SelectCalendar';
import DatePickerWithFunction from 'components/ReusableComponents/DatePicker/DatePickerWithFunction';
import dollarIcon from 'assets/images/override/dollar.svg';
import house from 'assets/images/override/house.svg';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { debouncer } from 'util/helper';


const OptimizeBody = styled(OptimizedBodyCommon)`
  font-weight: 300;
  height: 100%;
`;

const OverrideSubtitle = styled(BodyTitle)`
  align-self: flex-start;
  font-size: 20px;
  padding-bottom: 1rem;
`;

const OverridesContainer = styled.div`
  align-self: flex-start;
  font-size: 18px;
  margin-top: 1rem;
  width: 100%;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 0;
`;

const InputCSS = css`
  width: 100%;
  height: 40px;
  outline: none;
  margin: 2px 0 0 0;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.s4};
  border: 1px solid #D9D9D9;
  text-align: center;

`;

const PriceOverrideInput = styled.input`
  ${InputCSS}
`;

const StayOverrideInput = styled.input`
  ${InputCSS}
`;

const AddOverrideButton = styled(SecondaryButton)`
  align-self: flex-start;
  height: 32px;
  margin-left: 0;
  margin-bottom: 1rem;
`;

const ClearAllButton = styled(SecondaryButton)`
  align-self: flex-start;
  color: #697273;
  height: 32px;
  font-weight: 300;
  margin-left: 0;
  margin-top: 1rem;
  padding: 0;
  border: 0;

  :hover {
    background-color: transparent;
    color: #283334;
  }
`;

const SubText = styled.p`
  font-size: 16px;
`;

const OverrideItemDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid gray;
  border-color: #E8E8E8;
  display: inline-flex;
  padding-top: 1rem;

  .anticon-info-circle {
    color: #B0B0B0;
    margin-right: 2rem;

    &:hover {
      color: black;
      cursor: pointer;
    } 
  }

  &.stay.header {
    .stay {
      margin-left: 7rem;
    }
  }
`;

const OverrideItemDate = styled.span`
`;

const OverrideItemValue = styled.span`
  margin-left: auto;
  &.stay {
    margin-right: 5rem;
  }
`;

const OverrideItemClose = styled(CloseOutlined)`
  color: #B0B0B0;

  :hover {
    cursor: pointer;
  }
  
  margin-left: 1rem;
  min-width: 24px;

  &.hide {
    visibility: hidden;
  }
`;

const OverrideDatePicker = styled(DatePickerWithFunction)`
  height: 40px;
  width: 100%;

  svg {
    height: 22px;
    width: 22px;
    color: #697273;
  }
`;

const OverrideCalendarDiv = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;

  .select-calendar {
    position: absolute;
  }
`;


const menuItems = [{ icon: <img src={dollarIcon} alt='dollar icon' />, label: 'Price', key: 'price' }, { icon: <img src={house} alt='house icon' />, label: 'Stay', key: 'stay' }];


const Overrides = ({ setSelectedDaysFunc, adjustmentMenu, monthYear, selectedDates, calendarArray, onAppyChanges, isDisabled }) => {

  const [overrideMenu, setOverrideMenu] = useState('price');
  const [overridePrice, setOverridePrice] = useState(0);
  const [overrideStay, setOverrideStay] = useState();
  const [datesWithPriceOverrides, setDatesWithPriceOverrides] = useState([]);
  const [datesWithMinStayOverrides, setDatesWithMinStayOverrides] = useState([]);
  const [removeFg, setRemoveFg] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [disabledDatePicker, setDisabledDatePicker] = useState(false);
  const ref = useRef(null);

  const property = useSelector(({ property }) => property);
  const markets = useSelector(({ markets }) => markets);

  const currency = localStorage.getItem('currency');

  const overrideSubtitle = `${overrideMenu === 'price' ? 'Price' : 'Min Stay'} Overrides`;

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    let updatedStayOverrides = [];

    if (adjustmentMenu === 'property') {
      const priceArr = _.chain(property)
        .get('propertyPricing')
        .filter(p => p.override !== 0)
        .value();

      const stayPropertyArr = _.chain(property)
        .get('propertyPricing')
        .filter(p => p.minStayOverride !== 0 || p.minStayOverrideMarket !== 0 || p.minStayOverridePortfolio !== 0)
        .value();

      for (let stayProperty of stayPropertyArr) {
        const index = _.findIndex(updatedStayOverrides, stayProperty.date);

        if (stayProperty.minStayOverride) {
          stayProperty.tooltip = 'Set by property';
          stayProperty.overrideMinStay = stayProperty.minStayOverride;
        } else if (stayProperty.minStayOverrideMarket) {
          stayProperty.tooltip = 'Set by market';
          stayProperty.overrideMinStay = stayProperty.minStayOverrideMarket;
          stayProperty.hideCloseButton = true;
        } else if (stayProperty.minStayOverridePortfolio) {
          stayProperty.tooltip = 'Set by portfolio';
          stayProperty.overrideMinStay = stayProperty.minStayOverridePortfolio;
          stayProperty.hideCloseButton = true;
        }

        if (index > -1) {
          updatedStayOverrides[index] = stayProperty;
        } else {
          updatedStayOverrides.push(stayProperty);
        }
      }

      setDatesWithMinStayOverrides(updatedStayOverrides);
      setDatesWithPriceOverrides(priceArr);
    }

    if (adjustmentMenu === 'market') {
      const stayMarketArr = _.chain(markets)
        .get('marketPricing')
        .filter(p => p.minStay > 0 || p.minStayPortfolio > 0)
        .value();

      for (let stayMarket of stayMarketArr) {
        const index = _.findIndex(updatedStayOverrides, stayMarket.date);

        if (stayMarket.minStay) {
          stayMarket.tooltip = 'Set by market';
          stayMarket.overrideMinStay = stayMarket.minStay;
        } else if (stayMarket.minStayPortfolio) {
          stayMarket.tooltip = 'Set by portfolio';
          stayMarket.overrideMinStay = stayMarket.minStayPortfolio;
          stayMarket.hideCloseButton = true;
        }

        if (index > -1) {
          updatedStayOverrides[index] = stayMarket;
        } else {
          updatedStayOverrides.push(stayMarket);
        }
      }

      setDatesWithMinStayOverrides(updatedStayOverrides);
    }

    if (adjustmentMenu === 'portfolio') {
      const stayPortfolioArr = _.chain(markets)
        .get('portfolioPricing')
        .filter(p => p.minStay > 0)
        .value();

      for (let stayPortfolio of stayPortfolioArr) {
        const index = _.findIndex(updatedStayOverrides, stayPortfolio.date);

        if (stayPortfolio.minStay) {
          stayPortfolio.overrideMinStay = stayPortfolio.minStay;
          stayPortfolio.tooltip = 'Set by portfolio';
        }

        if (index > -1) {
          updatedStayOverrides[index] = stayPortfolio;
        } else {
          updatedStayOverrides.push(stayPortfolio);
        }
      }

      setDatesWithMinStayOverrides(updatedStayOverrides);
    }

  }, [property, markets, adjustmentMenu])

  useEffect(() => {
    if (removeFg === true && overrideMenu === 'price') {
      onAppyChanges(0, 'priceOverride');
      setRemoveFg(false);
    } else if (removeFg === true && overrideMenu === 'stay') {
      onAppyChanges(0, 'minStayOverride');
      setRemoveFg(false);
    }
  }, [overrideMenu, setRemoveFg, removeFg, onAppyChanges])

  useEffect(() => {
    if (overrideMenu === 'price' && adjustmentMenu === 'property') {
      setDisabledDatePicker(false);
    } else if (overrideMenu === 'stay') {
      setDisabledDatePicker(false);
    } else {
      setDisabledDatePicker(true);
    }
  }, [adjustmentMenu, overrideMenu])

  const onMenuChange = (value) => {
    setOverrideMenu(value);
  };

  const addOverride = () => {
    if (overrideMenu === 'price') {
      let updatedDatesWithPriceOverrides = _.cloneDeep(datesWithPriceOverrides);
      for (let date of selectedDates) {
        const dateIndex = _.findIndex(datesWithPriceOverrides, { date });
        if (dateIndex > -1) {
          updatedDatesWithPriceOverrides[dateIndex].override = overridePrice;
        } else {
          updatedDatesWithPriceOverrides.push({ date, override: overridePrice });
        }
        updatedDatesWithPriceOverrides = _.sortBy(updatedDatesWithPriceOverrides, 'date');
        setDatesWithPriceOverrides(updatedDatesWithPriceOverrides);
      }

      onAppyChanges(overridePrice, 'priceOverride');
    } else if (overrideMenu === 'stay') {
      let updatedDatesWithStayOverrides = _.cloneDeep(datesWithMinStayOverrides);
      for (let date of selectedDates) {
        const dateIndex = _.findIndex(datesWithMinStayOverrides, { date });
        const tooltip = `Set by ${adjustmentMenu}`;
        if (dateIndex > -1) {
          updatedDatesWithStayOverrides[dateIndex].minStay = overrideStay;
          updatedDatesWithStayOverrides[dateIndex].overrideMinStay = overrideStay;
          updatedDatesWithStayOverrides[dateIndex].overrideMinStay = overrideStay;
          updatedDatesWithStayOverrides[dateIndex].tooltip = tooltip;
        } else {
          updatedDatesWithStayOverrides.push({ date, minStay: overrideStay, overrideMinStay: overrideStay, tooltip });
        }
        updatedDatesWithStayOverrides = _.sortBy(updatedDatesWithStayOverrides, 'date');
        setDatesWithMinStayOverrides(updatedDatesWithStayOverrides);
      }

      onAppyChanges(overrideStay, 'minStayOverride');
    }
  }

  const removeOverride = (date) => {
    if (overrideMenu === 'price') {
      const updatedDatesWithPriceOverrides = _.cloneDeep(datesWithPriceOverrides);
      setDatesWithPriceOverrides(updatedDatesWithPriceOverrides.filter(p => p.date !== date));
    } else if (overrideMenu === 'stay') {
      const updatedDatesWithStayOverrides = _.cloneDeep(datesWithMinStayOverrides);
      setDatesWithMinStayOverrides(updatedDatesWithStayOverrides.filter(p => p.date !== date));
    }
    setSelectedDaysFunc([date]);
    setRemoveFg(true);
  }

  const toggleCalendar = () => {
    if (!disabledDatePicker) {
      setShowCalendar(!showCalendar);
    }
  }

  const handleOutsideClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const OverrideItem = ({ date, value, hideCloseButton, tooltip, stay, header }) => {
    return (
      <OverrideItemDiv className={`${stay ? 'stay' : ''} ${header ? 'header' : ''}`}>
        <OverrideItemDate>{date}</OverrideItemDate>
        <OverrideItemValue className={stay ? 'stay' : ''}>{value}</OverrideItemValue>
        {tooltip && (<Tooltip title={tooltip} showArrow={false} color='#283334' overlayInnerStyle={{ color: 'white', fontWeight: '300' }} ><InfoCircleOutlined /></Tooltip>)}
        <OverrideItemClose className={hideCloseButton ? 'hide' : ''} onClick={() => removeOverride(date)} />
      </OverrideItemDiv>
    )
  }

  const renderOverrides = useCallback(() => {
    const clearAllOverrides = () => {
      if (overrideMenu === 'price') {
        const dates = _.chain(datesWithPriceOverrides)
          .map(d => d.date)
          .value();

        setDatesWithPriceOverrides([]);
        setSelectedDaysFunc(dates);
        setRemoveFg(true);
      } else if (overrideMenu === 'stay') {
        const dates = _.chain(datesWithMinStayOverrides)
          .filter(d => d.date && d.minStayOverride !== 0)
          .map(d => d.date)
          .value();

        const a = datesWithMinStayOverrides.filter(d => !_.includes(dates, d.date));
        setDatesWithMinStayOverrides(a);
        setSelectedDaysFunc(dates);
        setRemoveFg(true);
      }
    }

    if (adjustmentMenu === 'property' && overrideMenu === 'price') {
      return (
        <Fragment>
          <OverrideSubtitle>
            {overrideSubtitle}
          </OverrideSubtitle>
          {datesWithPriceOverrides?.length > 0 ? (
            <Fragment>
              <OverrideItem date={'Date'} value={'Price'} hideCloseButton />
              {datesWithPriceOverrides?.map(d => {
                return (
                  <OverrideItem key={d.date} date={d.date} value={`${currency}${d.override}`} />
                )
              })}
              <ClearAllButton onClick={clearAllOverrides}> Clear All </ClearAllButton>
            </Fragment>
          ) : (
            <p>No price overrides to show</p>
          )}
        </Fragment>
      )
    } else if (overrideMenu === 'stay') {
      return (
        <Fragment>
          <OverrideSubtitle>
            {overrideSubtitle}
          </OverrideSubtitle>
          {datesWithMinStayOverrides?.length > 0 ? (
            <Fragment>
              <OverrideItem date={'Date'} value={'Stay'} hideCloseButton stay header />
              {datesWithMinStayOverrides?.map(d => {
                return (
                  <OverrideItem key={d.date} date={d.date} value={d.overrideMinStay} tooltip={d.tooltip} hideCloseButton={d.hideCloseButton} stay />
                )
              })}
              <ClearAllButton onClick={clearAllOverrides}> Clear All </ClearAllButton>
            </Fragment>
          ) : (
            <p>No min nights overrides to show</p>
          )}
        </Fragment>
      )
    }
  }, [overrideMenu, datesWithPriceOverrides, adjustmentMenu, overrideSubtitle, datesWithMinStayOverrides, currency, setSelectedDaysFunc])

  const showInput = useCallback(() => {

    const onPriceOverrideChange = (e) => {
      e.preventDefault();
      setOverridePrice(Math.abs(e.target.value));
    };

    const onMinstayOverrideChange = (e) => {
      e.preventDefault();
      setOverrideStay(Math.abs(e.target.value));
    }

    const onPriceChangeDebounced = debouncer(onPriceOverrideChange, 100)
    const onMinStayChangeDebounced = debouncer(onMinstayOverrideChange, 100)

    if (overrideMenu === 'price') {
      return (
        <Fragment>
          <BodyText>
            {`Price ($)`}
          </BodyText>
          <PriceOverrideInput disabled={adjustmentMenu !== 'property'} type='number' onChange={onPriceChangeDebounced} />
        </Fragment>
      )
    }
    return (
      <Fragment>
        <BodyText>
          {`Minimum Stay`}
        </BodyText>
        <StayOverrideInput type='number' onChange={onMinStayChangeDebounced} />
      </Fragment>
    )
  }, [overrideMenu, adjustmentMenu]);

  return (
    <OptimizeBody>
      <QuibbleMenu menuItems={menuItems} onMenuChange={onMenuChange} menuAlignment="center" menuWidth="50%" margin="12px 0 1.5rem 0" />
      {overrideMenu === 'stay' && <SubText>Existing stay values on selected dates are overwritten with new entries.</SubText>}
      <OverrideCalendarDiv ref={ref}>
        <OverrideDatePicker onClick={toggleCalendar} open={false} placeholder={'Date'} disabled={disabledDatePicker} />
        {showCalendar && <SelectCalendar calendarArray={calendarArray} setSelectedDaysFunc={setSelectedDaysFunc} disableFunc={isDisabled} selectedDates={selectedDates} monthYear={monthYear} />}
      </OverrideCalendarDiv>
      <InputDiv>
        {showInput()}
      </InputDiv>
      <AddOverrideButton onClick={addOverride} >Apply</AddOverrideButton>
      <OverridesContainer>
        {renderOverrides()}
      </OverridesContainer>
    </OptimizeBody>
  )
}

export default Overrides;
