import React from 'react';
import { useDispatch } from 'react-redux';
import styled, {css} from 'styled-components';
import { getPropertyModel } from '../../../../appRedux/actions';
import { message } from 'antd';

const CompareBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  outline: none;
  border: none;
`;

const ModelPro = styled.button`
  width: fit-content;
  height: 24px;
  display: flex;
  background: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  ${({modelActive}) => modelActive};

  &:disabled {
    cursor: initial;
  }
`;

const PropSpan = styled.span`
  width: fit-content;
  height: fit-content;
  align-items: center;
  max-height: 15px;
  font-size: 12px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 0 0 8px;
  ${({proSpanActive}) => proSpanActive};
`;

const Compare = ({value, setComparisonDetails}) => {

  const dispatch = useDispatch();

  const modelActive = value.isSilentDynamicPricingEnabled ? css`
    border: 1px solid #99E7EE;
    color: #00BBD2;
  ` : css`
    border: 1px solid #B0B0B0;
    color: #B0B0B0;
  `;

  const proSpanActive = value.isSilentDynamicPricingEnabled ? css`
    background: #C2ECF3;
  `: css`
    background: #E8E8E8;
  `;

  const onModelClick = () => {
    if (value?.id?.length) {
      dispatch(getPropertyModel({propertyId: value.id}))
      setComparisonDetails((prev)=> ({...prev, isVisible: true, ...value}));
      return;
    }
    message.error('This property has no ID')
  };

  return (
    <CompareBody >
      <ModelPro disabled={!value.isSilentDynamicPricingEnabled} modelActive={modelActive}  onClick={onModelClick}>
        Model <PropSpan proSpanActive={proSpanActive}>Pro</PropSpan>
      </ModelPro>
    </CompareBody>
  )
}

export default Compare;