import { isEmpty } from "lodash";
import { checkSign } from "./helper";

const initialValues = {
  name: '',
  value: 0,
  projectedValue: 0,
  stayedNights: 0,
  futureNights: 0,
  formattedValue: '',
  formattedProjected: '',
  diff: '',
  diffSign: '',
  projectedDiffSign: '',
  crrency: '',
}

const calculateMetrics = (metricsSummary, currency) => {
  const { current, yoy, forecast, forecastDiffLastYear } = metricsSummary;

  if (!isEmpty(metricsSummary)) {

    const metricEmailData = {
      totalRevenue: {
        name: 'Total Revenue',
        value: (current?.totalRevenue || 0),
        projectedValue: (forecast?.totalRevenue || current?.totalRevenue || 0),
        stayedNights: (current?.stayedNightsRevenue || 0),
        futureNights:  (current?.futureNightsRevenue || 0),
        diffValue: (yoy?.totalRevenue || 0),
        projectedDiff: (forecastDiffLastYear?.totalRevenue || 0), 
        // formattedValue: currencyFormatterV2(current?.totalRevenue, currency),
        // formattedProjected: currencyFormatterV2(forecast?.totalRevenue, currency),
        // diff: `${checkSign(yoy?.totalRevenue) === 'positive' ? '+' : ''}${currencyFormatterV2(yoy?.totalRevenue, currency)}`,
        diffSign: checkSign(yoy?.totalRevenue),
        projectedDiffSign: checkSign(forecastDiffLastYear?.totalRevenue),
        symbol: currency,
      },
      totalRent: {
        name: 'Total Rent',
        value: (current?.totalRent || 0),
        projectedValue: (forecast?.totalRent  || current?.totalRent || 0),
        diffValue: (yoy?.totalRent || 0),
        projectedDiff: (forecastDiffLastYear?.totalRent || 0), 
        // formattedValue: currencyFormatterV2(current?.totalRent, currency),
        // formattedProjected: currencyFormatterV2(forecast?.totalRent, currency),
        // diff: `${checkSign(yoy?.totalRent) === 'positive' ? '+' : ''}${currencyFormatterV2(yoy?.totalRent, currency)}`,
        diffSign: checkSign(yoy?.totalRent),
        projectedDiffSign: checkSign(forecastDiffLastYear?.totalRent),
        symbol: currency,
      },
      ancillaryRevenue: {
        name: 'Ancillary Revenue',
        value: (current?.ancillaryRevenue || 0),
        projectedValue: (forecast?.ancillaryRevenue || current?.ancillaryRevenue || 0),
        diffValue: yoy?.ancillaryRevenue,
        projectedDiff: (forecastDiffLastYear?.ancillaryRevenue || 0), 
        // formattedValue: currencyFormatterV2(current?.ancillaryRevenue, currency),
        // formattedProjected: currencyFormatterV2(forecast?.ancillaryRevenue, currency),
        // diff: `${checkSign(yoy?.ancillaryRevenue) === 'positive' ? '+' : ''}${currencyFormatterV2(yoy?.ancillaryRevenue, currency)}`,
        diffSign: checkSign(yoy?.ancillaryRevenue),
        projectedDiffSign: checkSign(forecastDiffLastYear?.ancillaryRevenue),
        symbol: currency,
      },
      ADR: {
        name: 'ADR',
        value: (current?.adr || 0),
        projectedValue: (forecast?.adr || current?.adr || 0),
        diffValue: yoy?.adr,
        projectedDiff: (forecastDiffLastYear?.adr || 0), 
        // formattedValue: currencyFormatterV2(current?.adr, currency),
        // formattedProjected: currencyFormatterV2(forecast?.adr, currency),
        // diff: `${checkSign(yoy?.adr) === 'positive' ? '+' : ''}${currencyFormatterV2(yoy?.adr, currency)}`,
        diffSign: checkSign(yoy?.adr),
        projectedDiffSign: checkSign(forecastDiffLastYear?.adr),
        symbol: currency,
      },
      occupancy: {
        name: 'Occupancy',
        value: (current?.occupancy * 100 || 0).toFixed(0),
        projectedValue: (forecast?.occupancy * 100 || current?.occupancy  * 100|| 0).toFixed(0),
        diffValue: (yoy?.occupancy * 100 || 0).toFixed(0),
        projectedDiff: (forecastDiffLastYear?.occupancy * 100 || 0).toFixed(0), 
        // formattedValue: `${Math.round(current?.occupancy * 100)}%`,
        // formattedProjected: `${Math.round(forecast?.occupancy * 100)}%`,
        // diff: `${checkSign(yoy?.occupancy) === 'positive' ? '+' : ''}${Math.round(yoy?.occupancy * 100)}%`,
        diffSign: checkSign(yoy?.occupancy),
        projectedDiffSign: checkSign(forecastDiffLastYear?.occupancy),
        symbol: '%',
      },
      revPAR: {
        name: 'RevPAR',
        value: (current?.revpar || 0),
        projectedValue: (forecast?.revpar || current?.revpar || 0),
        diffValue: (yoy?.revpar || 0),
        projectedDiff: (forecastDiffLastYear?.revpar || 0), 
        // formattedValue: currencyFormatterV2(current?.revpar, currency),
        // formattedProjected: currencyFormatterV2(forecast?.revpar, currency),
        // diff: `${checkSign(yoy?.revpar) === 'positive' ? '+' : ''}${currencyFormatterV2(yoy?.revpar, currency)}`,
        diffSign: checkSign(yoy?.revpar),
        projectedDiffSign: checkSign(forecastDiffLastYear?.revpar),
        symbol: currency,
      }
    }
    return metricEmailData

  }

  return {totalRevenue: initialValues, totalRent: initialValues, ancillaryRevenue: initialValues, ADR: initialValues, occupancy: initialValues, revPAR: initialValues  };


}

export default calculateMetrics;