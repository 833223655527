import React from "react";
import styled from "styled-components";
import { Slider } from "antd";


const QuibbleSlider = styled(Slider)`
  width: 99%;
  margin: 12px 0 0 0;
`;

const SliderWithFunction = ({onChangeFunction, property, ...rest}) => {

  const onSliderChange = (value) => {
    onChangeFunction(property, value)
  };

  return (
    <QuibbleSlider {...rest} onChange={onSliderChange}>
    </QuibbleSlider>
  )
}

export default SliderWithFunction;