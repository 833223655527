import React from 'react';
import styled from 'styled-components';
import { AuthItemDiv, AuthTitle, Link } from '../../sharedComponents/CommonCss';
import checkmark from 'assets/images/generic/checkmark.svg';

const VerificationDiv = styled.div`
  ${AuthItemDiv}
  margin: 116px;
`;

const SucessTitle = styled(AuthTitle)`
  margin: 54px 0 0 0;

`;


const WeAreText= styled.div`
  max-width: 464px;
  margin: 54px 0 0 0;
  align-self: flex-start;
`;

const SuccessTitle = styled(AuthTitle)`
  margin: 54px 0 0 0;
`;



const FinishSignup = ({pmsType}) => {
  if(pmsType === "Escapia") return <EscapiaFinishSignup/>
  return (
    <VerificationDiv>
      <img src={checkmark} alt='checkmark' />
      <SucessTitle>
        PMS Connected Successfully
      </SucessTitle>
      <WeAreText>
        We are importing your data. We will email you when it is finished, and then you can log in normally.
      </WeAreText>
      <WeAreText>
        For any questions or concerns, please <Link href="mailto:hello@quibblerm.com"> contact us. </Link>
      </WeAreText>
      <WeAreText>
        Want to know more? Take a look at our documentation in the <Link href="https://quibblerm.com/help-center/">Help Center. </Link>
      </WeAreText>
    </VerificationDiv>
  )
}



const EscapiaFinishSignup = () => {
  return (
    <VerificationDiv>
      <img src={checkmark} alt='checkmark' />
      <SuccessTitle>
        Complete Your PMS Integration on QuibbleRM
      </SuccessTitle>
      <WeAreText>
        Thank you for signing up with QuibbleRM! To complete your PMS integration, click the link sent to your email and follow the instructions provided.
      </WeAreText>
      <WeAreText>
        If you have any questions or need further assistance, please <Link href="mailto:hello@quibblerm.com">contact us.</Link>
      </WeAreText>
      <WeAreText>
        For more details, check out our <Link href="https://quibblerm.com/help-center/">Help Center</Link>.
      </WeAreText>
    </VerificationDiv>
  );
};


export default FinishSignup;
