import React, {memo, useState, useEffect} from 'react';
import { AVAILABLE_COMPANY_LOGOS, NO_SPECIALCHARS_SPACE } from '../../../constants/GlobalConstant';

const RenderActiveLogo = ({name, fill}) => {

  const [logoSrc, setLogoSrc] = useState(null);

  useEffect(() => {
    const loadLogo = async () => {
      try {
        const parsedName = name.replace(NO_SPECIALCHARS_SPACE, "").toLowerCase();

        const logoToUse = AVAILABLE_COMPANY_LOGOS.find((value) => parsedName.includes(value));

        if (logoToUse) {
          const logo = await import(`../../../assets/images/reservation-sources/${logoToUse}.png`);
          setLogoSrc(logo.default);
        } else {
          setLogoSrc(null);
        }
      } catch (error) {
        setLogoSrc(null);
      }
    };

    loadLogo();
  }, [name]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    {
      logoSrc ? ( <img src={logoSrc} alt={`${name} logo`} className='app-add-contrast' /> ) : ''
    }
    <p style={{ color: fill, textTransform: 'capitalize' }}>{name}</p>
  </div>

  )
}

export default memo(RenderActiveLogo);
