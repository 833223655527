import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import { currencyFormatter } from "../../util/helper";

import { FULL_MONTH_NAME } from "constants/GlobalConstant";
import ReportViewer from './ReportViewer';
import useMutation from '../../components/customHook/useMutation';

export const ViewTitle = {
  rmView:"RM View",
  checkInView: "Check In",
  checkOutView: "Check Out",
}



const ReportsRoot = () => {


  const { request, loading, data, error } = useMutation({
    method: "POST",
    url: '/bulk_report',
    baseUri: 'report',
  })

  useEffect(() => {
    document.body.style.overflow = 'scroll';
    document.body.style.setProperty('overflow', 'scroll', 'important');
    const data = window?.payloadData || {}
    const values = {
      userId: data.userId,
      remove_pms_charge: data?.removePmsCharge,
      quarter: data?.quarter,
      propertyId: data.propertyId,
      year: data.year,
      month: data.month,
      viewType: data?.viewType,
    };
    request(values)
  }, []);

  if(loading) return null
  if(error) return <div id="error">An error occurred</div>
  if(!data) return null
  return <Reports propertyDetail={data} {...window?.payloadData}/>
}

const Reports = (props) => {

  console.log(props)
  // let persistedFilter = getData("filters") === null ? filters : getData("filters");

  const propertyDetail = props?.propertyDetail || {};

  function setMetrics(km, fields) {
    const newKm = { ...km, adr: km?.totalRent / km?.bookedNights };
    const currencyFields = ["adr", "ancillaryRevenue", "totalRevenue", "totalRent"];

    let kmClone = cloneDeep(newKm);
    Object.keys(kmClone).forEach((key) => {
      if (!fields.includes(key)) delete kmClone[key];
      else if (currencyFields.includes(key)) kmClone[key] = currencyFormatter(kmClone[key], propertyDetail.result.currency);
    });

    return kmClone;
  }

  function setPayload() {
    const metricsExport = props.keyMetrics.filter(km => km.checked).map(km => km.value);
    const metricsByMonthExport = props.keyMetricsByMonth.filter(km => km.checked).map(km => km.value);
    return {
      currentMonthCurrentYearADR: currencyFormatter(propertyDetail.currentMonthYearAdr, propertyDetail.result.currency),
      currentMonthCurrentYearOccupancy: propertyDetail.currentMonthCurrentYearOccupancy,
      currentMonthCurrentYearTotalRent: currencyFormatter(propertyDetail.currentMonthCurrentYearTotalRent, propertyDetail.result.currency),
      keyMetrics: {
        currentYear: setMetrics(propertyDetail.keyMetricTableCurrentYear, props.yearlyData ? metricsExport : []),
        last365: setMetrics(propertyDetail.keyMetricTableLast365Days, metricsExport),
        lastYear: setMetrics(propertyDetail.keyMetricTableLastYear, metricsExport),
      },
      keyMetricsByMonth: {
        currentMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonth, props.monthlyData ? metricsByMonthExport : []),
        lastMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonthLastYear, metricsByMonthExport),
      },
      month: FULL_MONTH_NAME[propertyDetail.month - 1],
      property: {
        name: propertyDetail.result?.name,
        city: propertyDetail.result?.city,
        imageUrl: propertyDetail.result?.image,
      },
      year: propertyDetail.year,
    };
  }

  return (
   <ReportViewer setPayload={setPayload} brandLogoUrl={props.brandLogoUrl} viewType={props.viewType} quarter={props.quarter} />
  );
};

export default ReportsRoot;
