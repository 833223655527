import React from 'react';
import styled from 'styled-components';
import InsightsProp from './subComponents/InsightsProperties/InsightsProp';
import inventory from 'assets/images/insights/inventory.svg'
import bookings from 'assets/images/insights/bookings.svg'
import activeproperties from 'assets/images/insights/activeproperties.svg'
import bookednights from 'assets/images/insights/bookednights.svg'


const InsightsPricePropertiesDiv = styled.div`
  width: 100%;
  max-width: 1800px;

  height: 98px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const InsightsProperties = ({InsightsPropsData, isLoading}) => {
  return (
    <InsightsPricePropertiesDiv>
      <InsightsProp isLoading={isLoading} logo={inventory} color="#78BA47" title={"Inventory growth"} value={`${InsightsPropsData.inventory}%`}/>
      <InsightsProp isLoading={isLoading} logo={bookings} color="#2ECADB" title={"Bookings"}  value={InsightsPropsData.bookings}/>
      <InsightsProp isLoading={isLoading} logo={activeproperties} color="#C6A1F5" title={"Active properties"} value={InsightsPropsData.activeProp}/>
      <InsightsProp isLoading={isLoading} logo={bookednights} color="#FFC805" title={"Booked nights"} value={InsightsPropsData.bookedNights}/>
    </InsightsPricePropertiesDiv>
  )
}

export default InsightsProperties;