import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components';
import { Switch } from 'antd';
import moment from 'moment';
import { CopyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SearchBox from '../../../../components/SearchBox';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import { getEventDetails } from '../../../../appRedux/actions';
import { Skeleton } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import RenderBar from '../../../marketAnalytics/sharedComponents/MarketPropertyTable/RenderBar';
import { events as eventsService } from '../../../../services/events';
import { debounce, cloneDeep, remove } from 'lodash';

// const { RangePicker } = DatePicker;

const Container = styled.div`
  gap:20px;
  background: white;
  padding: 20px;
  margin: 10px;
  font-family: Commissioner;
  font-weight: 100;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;

`;

const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 22px;

  .anticon {
    padding: 6px;
    &:hover {
      // border: 1px solid black;
      border-radius: 100px;
      background: #E8E8E8;

      svg {
        color: #00BBD2;
      }
    }
  }

`;

const TotalDetails = styled.div`
  display: flex;
  align-items: top;
  width:100%;
  gap: 20px;
  padding-top: 20px;
`;


const ImageFrame = styled.div`
  padding: 10px;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.dirtyGrey}
`;

const Details = styled.div`
  font-size: 16px;
`;

const MarketList = styled.div`
  background: white;
  padding: 10px 20px 10px 10px;
  margin: 0px 10px;
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Search = styled.div`
  width: 400px;
  padding: 10px;
`;

const Apply = styled.span`
  display: flex;
  gap: 10px;
  align-items:center;
`;

const TableContainer = styled.div`

`;

// const Button = styled.button`
//   padding: 16px 50px;
//   margin: 20px 10px;
//   font-size: 16px;
//   border: 1px solid ${({ theme }) => theme.quibbleBlue};
//   color: ${({ theme, blue }) => (blue ? 'white' : theme.quibbleBlue)};
//   background-color: ${({ theme, blue }) => (blue ? theme.quibbleBlue : 'white')};
//   border-radius: 4px;
//   cursor: pointer;
// `;

const ViewEvent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { eventDetails, isFetchingEventDetails } = useSelector(({ events }) => events);
  const [isCopied, setIsCopied] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [isUpdatingMarkets, setIsUpdatingMarkets] = useState(false);
  // const [dateRange, setDateRange] = useState([]);
  const [details, setDetails] = useState({});

  const eventId = history?.location?.state?.eventId;


  const marketData = eventDetails?.marketData

  useEffect(() => {
    if (!eventId) {
      history.push('/revenue/events-pricing');
      return;
    }

    dispatch(getEventDetails({ eventId }));
  }, [dispatch, eventId, history])

  useEffect(() => {
    if (eventDetails?.status === 'Active') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    setSelectedMarkets(eventDetails?.marketData?.filter(m => m.isEventApplied).map(m => m.market) || []);
    // setMinStay(eventDetails?.minStay);
    // setDateRange([moment.utc(eventDetails?.startDate), moment.utc(eventDetails?.endDate)])
    setDetails({
      name: eventDetails?.name,
      startDate: eventDetails?.startDate,
      endDate: eventDetails?.endDate,
      isRecurring: eventDetails?.isRecurring,
      markets: eventDetails?.markets,
      minStay: eventDetails?.minStay,
      imageUrl:eventDetails?.imageUrl

    })
  }, [eventDetails])

  const formatDateString = (date) => {
    return moment.utc(date).format('MM-DD-YYYY');
  };


  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(details.name);
      setIsCopied(true);
      alert('Event name copied to clipboard!');
    } catch (err) {
      console.error('Unable to copy event name', err);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this event?");

    if (confirmDelete) {
      try {
        await eventsService.deleteEventDetails({ eventId });
        history.push('/revenue/events-pricing');
      } catch (error) {
        console.error("Error deleting event", error);
      }
    }
    else {
      alert("Okay")
    }
  };


  const handleMarketChange = (checked, e) => {
    const m = e.target.getAttribute('data-market');

    if (!m) return;

    let sMarkets = cloneDeep(selectedMarkets);

    if (checked) {
      if (m === 'ALL') {
        sMarkets = eventDetails?.marketData?.map(m => m.market);
      } else {
        sMarkets.push(m);
      }
    } else {
      if (m === 'ALL') {
        sMarkets = [];
      } else {
        sMarkets = remove(sMarkets, s => s !== m);
      }
    }

    const update = debounce(async () => {
      try {
        const body = {
          markets: sMarkets
        };
        setIsUpdatingMarkets(true);
        await eventsService.updateEventDetails({ eventId, body, apply: true });
        // dispatch(getEventDetails({ eventId }));
        setIsUpdatingMarkets(false);
      } catch (error) {
        setIsUpdatingMarkets(false);
        console.error('Error making PUT request:', error);
      }
    }, 600)
    update();
    setSelectedMarkets(sMarkets);
  }

  const redirect =() => {
    history.goBack();
  }

  const goToEditPage =() => {
    history.push({
      pathname: '/revenue/events-pricing/edit',
      state: { eventId } 
    });
  }

  // const [minStay, setMinStay] = useState(toInteger(details.minStay));


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchString(value);

  }

  const handleIsActiveChange = (checked) => {
    setIsActive(checked);
    const update = debounce(async () => {
      try {
        const body = {
          status: checked ? 'Active' : 'Disabled'
        };
        await eventsService.updateEventDetails({ eventId, body, apply: true });
        dispatch(getEventDetails({ eventId }));
      } catch (error) {
        console.error('Error making PUT request:', error);
      }
    }, 1000)
    update();
  }

  const filterMarketsTable = isFetchingEventDetails === false
    ? marketData?.filter((data) => {
      const includesSearchString = data?.market?.toLowerCase().includes(searchString.toLowerCase());
      return includesSearchString;
    }) ?? []
    : [];


  const columns = [
    {
      label: 'Market',
      width: '200px',
      dataIndex: 'market',
      align: 'left'
    },
    {
      label: 'Occupancy',
      // dataIndex: 'occupancy',
      align: 'left',
      customRender: (data) => <RenderBar type="occupancy" tooltipLabel='Occupancy' current={data?.occupancy} market={data?.marketOccupancy} />

    },
    {
      label: 'OCC GAP',
      dataIndex: 'occGap',
      align: 'center',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),


    },
    {
      label: 'APPLY',
      dataIndex: 'market',
      align: 'center',
      customRender: (data) => {
        return (
          <Switch disabled={eventDetails?.status !== 'Active'} loading={isUpdatingMarkets} checked={selectedMarkets.includes(data)} data-market={data} onChange={handleMarketChange} />
        )
      }
    },
  ]


  return (
    <div>
      <Container>
        <Title>
          <Name>
            <LeftOutlined onClick={redirect}/>{isFetchingEventDetails  ? (
              <Skeleton.Input active={true} style={{ height: '30px', width: '10rem' }} />
            ) : details.name}
          </Name>
          <Options>
            <CopyOutlined onClick={handleCopyClick} style={{ color: isCopied && '#00BBD2', display: 'none' }} />
            <EditOutlined onClick={goToEditPage} />
            <DeleteOutlined onClick={handleDelete} />
            <Switch checked={isActive} onChange={handleIsActiveChange} />
          </Options>

        </Title>
        <TotalDetails>
          <ImageFrame>
            <img src={details.imageUrl || "/assets/images/events_placeholder.png" } alt="events-placeholder" width="250px" />
          </ImageFrame>
          <Details>
            <p style={{ whiteSpace: 'nowrap' }}>
              Markets: {(
                isFetchingEventDetails  ? (
                  <Skeleton.Input active={true} style={{ height: '18px', width: '10rem' }} />
                ) : 
                    eventDetails?.marketData?.length === selectedMarkets?.length ? 'All' :
                      selectedMarkets && selectedMarkets.length > 0 ? (
                        selectedMarkets.map((market, index) => (
                          <span key={market + index}>
                            {index > 0 && ', '}{market}
                          </span>
                        ))
                      ) : (
                        <span>-</span>
                      )
                  
              )}
            </p>

            <p >Multiplier: {isFetchingEventDetails ? (<Skeleton.Input active={true} style={{ height: '18px', width: '10rem' }} />):   (
              eventDetails?.multiplier || 0
            )}
            </p>

            <p style={{ whiteSpace: 'nowrap', width: '350px', display: 'flex', alignItems: 'center' }}>
              Min Stay: {
                  isFetchingEventDetails ? (<Skeleton.Input active={true} style={{ height: '18px', width: '10rem' }} />) : details?.minStay ?  details.minStay : 'None selected'
              }
            </p>

            <p style={{ whiteSpace: 'nowrap' }}>
              Date Range: {
                  isFetchingEventDetails ? (<Skeleton.Input active={true} style={{ height: '18px', width: '10rem' }} />) : `${formatDateString(details.startDate)} - ${formatDateString(details.endDate)}`
              }
            </p>

            <p style={{ whiteSpace: 'nowrap', display: 'flex', gap: '10px'}}>
              Recurring event: { 
                  isFetchingEventDetails ? (<Skeleton.Input active={true} style={{ height: '18px', width: '10rem' }} />) : (details.isRecurring === true ? <p>Yes</p> : <p>No</p>)
              }
            </p>

          </Details>
        </TotalDetails>


      </Container>

      <MarketList>
        <FirstLine>
          <Search>
            <SearchBox placeholder="Search" value={searchString} onChange={handleSearchChange} />
          </Search>
          <Apply>
            APPLY TO ALL
            <Switch disabled={eventDetails?.status !== 'Active'} checked={eventDetails?.marketData?.length === selectedMarkets?.length} loading={isUpdatingMarkets} data-market='ALL' onChange={handleMarketChange} />
          </Apply>
        </FirstLine>

        <TableContainer>
          <QuibbleTable
            columns={columns}
            data={filterMarketsTable}
            paginationSize={10} />
        </TableContainer>
      </MarketList>

    </div>

  );
};

export default ViewEvent;
