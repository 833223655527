import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { businessTools } from "../../services/businessTools";
import { FETCH_ERROR, GET_CURRENT_REVENUE_PLAN, GET_REVENUE_PLANS, SET_CURRENT_REVENUE_PLAN, SET_REVENUE_PLANS,  GET_REVENUE_PLANNING, GET_CITY_DETAILS, GET_CITY_DETAILS_V2, SET_CITY_DETAILS, SEARCH_CITY, SEARCH_CITY_V2, SET_SEARCHED_CITIES, GET_PORTFOLIO_GOALS, GET_PORTFOLIO_GOALS_SUCCESS, GET_MARKET_GOALS, GET_MARKET_GOALS_SUCCESS, GET_PROPERTY_GOALS, GET_PROPERTY_GOALS_SUCCESS  } from "../../constants/ActionTypes";
import { setRevenuePlanningData, showUserErrorMessages } from "../actions";

/** Request to get property listing */
const getPropertyRequest = async (data) =>
  await businessTools
    .list(data)
    .then((result) => result)
    .catch((error) => error);

const getCityDetailsRequest = async (cityId) =>
  await businessTools
    .getCityDetails(cityId)
    .then((result) => result)
    .catch((error) => error);

const getCityDetailsRequestV2 = async (cityId) =>
  await businessTools
    .getCityDetailsV2(cityId)
    .then((result) => result)
    .catch((error) => error);

const searchCityRequest = async (params) =>
  await businessTools
    .searchCity(params)
    .then((result) => result)
    .catch((error) => error);

const searchCityRequestV2 = async (params) =>
  await businessTools
    .searchCityV2(params)
    .then((result) => result)
    .catch((error) => error);

function* getPropertyData({ payload }) {
  try {
    let propertyList = yield call(getPropertyRequest, payload);
    yield put(setRevenuePlanningData(propertyList.data));
  } catch (error) {
    yield put(FETCH_ERROR(error));
  }
}

function* getCurrentRevenuePlan({ month, year }) {
  try {
    const params = {
      month,
      year,
    }
    const revenuePlans = yield call(businessTools.getRevenuePlans, { params: params });
    yield put({ type: SET_CURRENT_REVENUE_PLAN, currentRevenuePlan: revenuePlans[0] });
  } catch (err) {
    yield put({ type: SET_CURRENT_REVENUE_PLAN, currentRevenuePlan: null });
  }
}

function* getRevenuePlans() {
  try {
    const revenuePlans = yield call(businessTools.getRevenuePlans);
    yield put({ type: SET_REVENUE_PLANS, revenuePlans: revenuePlans });
  } catch (err) {
    yield put({ type: SET_REVENUE_PLANS, revenuePlans: [] });
  }
}

function* getCityDetails(data) {
  try {
    const cityDetails = yield call(getCityDetailsRequest, data.cityId);
    yield put({type: SET_CITY_DETAILS, data: cityDetails.data.data})
  } catch (err) {
    yield put({type: SET_CITY_DETAILS, data: {}})
  }
}

function* getCityDetailsV2(data) {
  try {
    const cityDetails = yield call(getCityDetailsRequestV2, data.cityId);
    yield put({type: SET_CITY_DETAILS, data: cityDetails.data.data})
  } catch (err) {
    yield put({type: SET_CITY_DETAILS, data: {}})
  }
}

function* searchCity(data) {
  try {
    const searchParams = {
      q: data.queryString,
      ac: true,
    }
    const searchedCities = yield call(searchCityRequest, searchParams);
    const searchedCityHits = searchedCities.data.data.hits;

    yield put({type: SET_SEARCHED_CITIES, data: searchedCityHits})
  } catch (err) {
    yield put({type: SET_SEARCHED_CITIES, data: []})
  }
}

function* searchCityV2(data) {
  try {
    const searchParams = {
      q: data.queryString,
      ac: true,
    }
    const searchedCities = yield call(searchCityRequestV2, searchParams);
    const searchedCityHits = searchedCities.data.data.hits;

    yield put({type: SET_SEARCHED_CITIES, data: searchedCityHits})
  } catch (err) {
    yield put({type: SET_SEARCHED_CITIES, data: []})
  }
}

function* getPortfolioGoals({payload}){
  try {
    const portfolioGoals = yield call(businessTools.getPortfolioGoals, { ...payload });
    yield put({ type: GET_PORTFOLIO_GOALS_SUCCESS, payload: portfolioGoals });
  } catch (err) {
    yield put({ type: GET_PORTFOLIO_GOALS_SUCCESS, payload: [] });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving portfolio goals.',
      })
    );
  }
}

function* getMarketGoals({payload}) {
  try {
    const marketGoals = yield call(businessTools.getMarketGoals, { ...payload });
    yield put({ type: GET_MARKET_GOALS_SUCCESS, payload: marketGoals });
  } catch (err) {
    yield put({ type: GET_MARKET_GOALS_SUCCESS, payload: [] });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving market goals.',
      })
    );
  }
}

function* getPropertyGoals({payload}) {
  try {
    const propertyGoals = yield call(businessTools.getPropertyGoals, { ...payload });
    yield put({ type: GET_PROPERTY_GOALS_SUCCESS, payload: propertyGoals });
  } catch (err) {
    yield put({ type: GET_PROPERTY_GOALS_SUCCESS, payload: [] });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving property goals.',
      })
    );
  }
}

export function* getPortfolioGoalsWatcher() {
  yield takeEvery(GET_PORTFOLIO_GOALS, getPortfolioGoals);
}

export function* getMarketGoalsWatcher() {
  yield takeEvery(GET_MARKET_GOALS, getMarketGoals);
}

export function* getPropertyGoalsWatcher() {
  yield takeEvery(GET_PROPERTY_GOALS, getPropertyGoals);
}

export function* getPropertyList() {
  yield takeEvery(GET_REVENUE_PLANNING, getPropertyData);
}
/** End :: Request to get property listing */

export function* getCurrentRevenuePlanWatcher() {
  yield takeEvery(GET_CURRENT_REVENUE_PLAN, getCurrentRevenuePlan);
}

export function* getRevenuePlansWatcher() {
  yield takeEvery(GET_REVENUE_PLANS, getRevenuePlans);
}

export function* getCityDetailsWatcher() {
  yield takeEvery(GET_CITY_DETAILS, getCityDetails)
}

export function* getCityDetailsWatcherV2() {
  yield takeEvery(GET_CITY_DETAILS_V2, getCityDetailsV2)
}

export function* searchCityWatcher() {
  yield takeEvery(SEARCH_CITY, searchCity)
}

export function* searchCityWatcherV2() {
  yield takeEvery(SEARCH_CITY_V2, searchCityV2)
}

/** Export all */
export default function* rootSaga() {
  yield all([
    fork(getPropertyList),
    fork(getCurrentRevenuePlanWatcher),
    fork(getRevenuePlansWatcher),
    fork(getCityDetailsWatcher),
    fork(getCityDetailsWatcherV2),
    fork(searchCityWatcher),
    fork(searchCityWatcherV2),
    fork(getPortfolioGoalsWatcher),
    fork(getMarketGoalsWatcher),
    fork(getPropertyGoalsWatcher),
  ]);
}
