import {
  USER_MARKETS_LIST,
  USER_MARKETS_LIST_SUCCESS,
  USER_MARKETS_YEARLY_DATA,
  USER_MARKETS_YEARLY_DATA_SUCCESS,
  UPDATE_MARKETS_STATUS,
  UPDATE_MARKETS_STATUS_SUCCESS,
  GET_MARKETS_DETAIL,
  SET_MARKETS_DETAIL,
  HIDE_MESSAGE,
  GET_CALENDAR_METRIC,
  SET_CALENDAR_METRIC,
  GET_MARKET_ANALYTICS,
  GET_MARKET_ANALYTICS_SUCCESS,
  GET_MARKET_PROPERTY_TABLE,
  GET_MARKET_PROPERTY_TABLE_SUCCESS,
  GET_MARKET_PRICING,
  GET_MARKET_PRICING_SUCCESS,
  GET_MARKET_DETAILS,
  GET_MARKET_DETAILS_SUCCESS,
  GET_PORTFOLIO_PRICING,
  GET_PORTFOLIO_PRICING_SUCCESS,
  GET_MARKET_PRICING_RULES,
  GET_MARKET_PRICING_RULES_SUCCESS,
  GET_PORTFOLIO_PRICING_RULES,
  GET_PORTFOLIO_PRICING_RULES_SUCCESS,
  GET_LISTINGS,
  GET_LISTINGS_SUCCESS,
} from "../../constants/ActionTypes";

/*** Set initial state */
const INIT_STATE = {
  loader                : false,
  textMessage           : '',
  propertyData          : [],
  propertyDetail        : {},
  propertyDataAnalysis  : {},
  marketYearlyDataLoaded: false,
  marketListLoaded: false,
  calendarMetricLoaded: false,
  calendarMetric: [],
  marketAnalytics: [],
  isFetchingMarketAnalytics: false,
  marketPropertyTable: [],
  isFetchingMarketPropertyTable: false,
  marketPricing: [],
  isFetchingMarketPricing: false,
  marketDetails: [],
  isFetchingMarketDetails: false,
  portfolioPricing: [],
  isFetchingPortfolioPricing: false,
  marketPricingRules: {},
  isFetchingMarketPricingRules: false,
  portfolioPricingRules: {},
  isFetchingPortfolioPricingRules: false,
  listings: [],
  isFetchingListings: false,
};


const MarketsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case USER_MARKETS_LIST: {
      return {
        ...state,
        loader       : true,
        marketListLoaded: false,
        propertyData : []
      }
    }

    case USER_MARKETS_LIST_SUCCESS: {
      return {
        ...state,
        loader       : false,
        marketListLoaded: true,
        propertyData : action.payload
      }
    }

    case GET_CALENDAR_METRIC: {
      return {
        ...state,
        loader       : true,
        calendarMetricLoaded: false,
        calendarMetric : []
      }
    }

    case SET_CALENDAR_METRIC: {
      return {
        ...state,
        loader       : false,
        calendarMetricLoaded: true,
        calendarMetric : action.payload
      }
    }

    case USER_MARKETS_YEARLY_DATA: {
      return {
        ...state,
        loader       : true,
        marketYearlyDataLoaded: false,
        propertyDataAnalysis : {}
      }
    }

    case USER_MARKETS_YEARLY_DATA_SUCCESS: {
      return {
        ...state,
        loader       : false,
        marketYearlyDataLoaded: true,
        propertyDataAnalysis : action.payload
      }
    }

    case UPDATE_MARKETS_STATUS: {
      return {
        ...state
      }
    }

    case UPDATE_MARKETS_STATUS_SUCCESS: {
      return {
        ...state,
        textMessage : action.payload
      }
    }

    case GET_MARKETS_DETAIL: {
      return {
        ...state,
        propertyDetail : {}
      }
    }


    case SET_MARKETS_DETAIL: {
      return {
        ...state,
        propertyDetail : action.payload
      }
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false, error: '', message: '', textMessage: ''
      }
    }

    case GET_MARKET_ANALYTICS: {
      return {
        ...state,
        isFetchingMarketAnalytics: true,
      }
    }

    case GET_MARKET_ANALYTICS_SUCCESS: {
      return {
        ...state,
        marketAnalytics: action.payload,
        isFetchingMarketAnalytics: false,
      }
    }

    case GET_MARKET_PROPERTY_TABLE: {
      return {
        ...state,
        isFetchingMarketPropertyTable: true,
      }
    }

    case GET_MARKET_PROPERTY_TABLE_SUCCESS: {
      return {
        ...state,
        marketPropertyTable: action.payload,
        isFetchingMarketPropertyTable: false,
      }
    }

    case GET_MARKET_PRICING: {
      return {
        ...state,
        isFetchingMarketPricing: true,
      }
    }

    case GET_MARKET_PRICING_SUCCESS: {
      return {
        ...state,
        marketPricing: action.payload,
        isFetchingMarketPricing: false,
      }
    }

    case GET_MARKET_DETAILS: {
      return {
        ...state,
        isFetchingMarketDetails: true,
      }
    }

    case GET_MARKET_DETAILS_SUCCESS: {
      return {
        ...state,
        marketDetails: action.payload,
        isFetchingMarketDetails: false,
      }
    }

    case GET_PORTFOLIO_PRICING: {
      return {
        ...state,
        isFetchingPortfolioPricing: true,
      }
    }

    case GET_PORTFOLIO_PRICING_SUCCESS: {
      return {
        ...state,
        portfolioPricing: action.payload,
        isFetchingPortfolioPricing: false,
      }
    }

    case GET_MARKET_PRICING_RULES: {
      return {
        ...state,
        isFetchingMarketPricingRules: true,
      }
    }

    case GET_MARKET_PRICING_RULES_SUCCESS: {
      return {
        ...state,
        marketPricingRules: action.payload,
        isFetchingMarketPricingRules: false,
      }
    }

    case GET_PORTFOLIO_PRICING_RULES: {
      return {
        ...state,
        isFetchingPortfolioPricingRules: true,
      }
    }

    case GET_PORTFOLIO_PRICING_RULES_SUCCESS: {
      return {
        ...state,
        portfolioPricingRules: action.payload,
        isFetchingPortfolioPricingRules: false,
      }
    }

    case GET_LISTINGS: {
      return {
        ...state,
        isFetchingListings: true,
      }
    }

    case GET_LISTINGS_SUCCESS: {
      return {
        ...state,
        listings: action.payload,
        isFetchingListings: false,
      }
    }

    default:
      return state;
  }
}

export default MarketsReducer;
