import React from 'react';
import styled from 'styled-components';
import { LoaderCSS } from './CommonCSS';

const TableDiv = styled.div`
  width: 100%;
  height: 12%;
  max-height: 64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
`;

const TableLoaderContent = styled.div`
  width: 18%;
  height: 48%;
  border-radius: 100px;
  ${LoaderCSS}
`;

const Border = styled.div`
  width: calc(100% - 32px);
  border-bottom: 1px solid #D9D9D9;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
`;



const TableLoader = ({tableRows=6}) => {
  return (
    <>
      {
        [...Array(tableRows).keys()].map((_, index) => (
          <TableDiv key={`${index}`}>
            <TableLoaderContent />
            <TableLoaderContent />
            <TableLoaderContent />
            <TableLoaderContent />
            <TableLoaderContent />
            <Border />
          </TableDiv>
        ))
      }
    </>
  )
}

export default TableLoader;