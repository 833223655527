import React, {memo} from 'react';
import styled from 'styled-components';
import SkLoaderOver from './SkLoaderOver';

const SkLoaderDistinctDiv = styled.div`
  width: 100%;
  height: 100%;
  min-height: 320px;
  position: relative;
  padding: 16px;
`;


const SkLoaderDistinct = ({isLoading=true, type, tableRows}) => {
  return (
    <SkLoaderDistinctDiv>
      <SkLoaderOver isLoading={isLoading} type={type} tableRows={tableRows}/> 
    </SkLoaderDistinctDiv>
  )
}

export default memo(SkLoaderDistinct);