import React from 'react';
import styled from 'styled-components';
import { RightOutlined, DownOutlined } from '@ant-design/icons';


const SubOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const HelpNavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
  font-family: Commissioner;
  font-weight: 100;
  width: 100%;
  height: 48px;
  min-height: 40px;
  border-radius: 4px;
  padding: 0 10px;
  border: none;
  outline: none;
  cursor:pointer;
  background: ${({ openOption, theme }) => (openOption ? theme.quibbleHoverGrey : 'inherit')};  
  transition: all 0.15s linear;
  transition: all 0.15s linear;
  position: relative;
  white-space: nowrap;
  color: #697273;


  &:hover {
    background: ${({ theme }) => (theme.quibbleBlue)};
    color: #697273;
  }
`;

const SubOption = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  gap: 10px;
  font-size: 16px;
  font-family: Commissioner;
  font-weight: 100;
  width: 100%;
  height: 48px;
  min-height: 40px;
  border-radius: 4px;
  padding: 0 10px;
  border: none;
  outline: none;
  cursor: ${({ disabledOption }) => disabledOption ? 'not-allowed' : 'pointer'};
  transition: all 0.15s linear;
  position: relative;
  white-space: break-word;
  background: ${({ theme, isActive }) => isActive ? theme.quibbleBlue:  'white'};
  color: ${({ theme, isActive, disabledOption }) => isActive ? theme.white: disabledOption ? theme.disabledGrey : ''};


  &:hover {
    background: ${({ theme, disabledOption }) => disabledOption ? 'inherit' : theme.quibbleBlue};
    color: ${({ theme, disabledOption }) => disabledOption ? theme.disabledGrey : theme.white};
  }
`;




const SubTopic = ({ title, subtopics, selectedSubOption, arrowUp, handleSubOptionClick, openOption, handleHelpNavClick }) => {
  return (
    <>
      <HelpNavButton onClick={() => handleHelpNavClick(title)} arrowUp={arrowUp} openOption={openOption} >
        {title} {openOption ? <DownOutlined /> : <RightOutlined />}
      </HelpNavButton>
      {openOption && (
        <SubOptionContainer>
          {title === 'Configurations' && (
            <SubOptionContainer>
              <p>LISTINGS MANAGEMENT</p>
            </SubOptionContainer>
          )} 
          {subtopics.map((subOption, index) => {
              const isDisabled = subOption.subVideos.length === 0 && subOption.subGuide.length === 0;
              return(
                <SubOption key={index} onClick={() => !isDisabled && handleSubOptionClick(subOption.label)} isActive={selectedSubOption === subOption.label} disabledOption={isDisabled}>
                  {subOption.label}
                </SubOption>
              )})}
              
          
        </SubOptionContainer>
      )}
    </>
  );
};

export default SubTopic;
