import axios from "axios";
import {jwtExpiration} from "../../src/constants/GlobalConstant";
import { getData, storeData } from "../util/indexDbHelper";
import serviceV2 from "../util/APIUtility";



let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });


const headers = { headers: { 'Content-Type': 'application/json' } }

export const events = {
  list: async (data) => {
    let cachedData = await getData('listData', data)

    if (!cachedData) {
      let dataVal = await service.post("/api/events", data, headers);
      jwtExpiration(dataVal);
      await storeData("listData", dataVal.data, data)
      return dataVal;
    }

    let response = { data: cachedData }
    return response;
  },

  getEventsTable: async (options) => {
    const { params } = options;
    let result = await serviceV2.get(`/api/events`, { params: { ...params } });
    return result.data.data;
  },

  getEventDetails: async (options) => {
    const {eventId, params} = options;
    let result = await serviceV2.get(`/api/events/${eventId}`, {params: {...params}});
    return result.data.data;
  },

  createEvent: async (options) => {
    const {body} = options;
    let result = await serviceV2.post(`/api/events`, body);
    return result.data.data;
  },

  deleteEventDetails: async (options) => {
    const {eventId, params} = options;
    let result = await serviceV2.delete(`/api/events/${eventId}`, {params: {...params}});
    return result.data.data;
  },

  updateEventDetails: async (options) => {
    const {eventId, body, apply} = options;
    let result = await serviceV2.put(`/api/events/${eventId}?apply=${apply}`, body);
    return result.data.data;
  },

  uploadEventImage: async (options) => {
    const { eventId, file } = options;

    const formData = new FormData();
    formData.append('event_image', file);

    const result = await serviceV2.post(`/api/events/${eventId}/upload`, formData);

    return result.data;
  },

};
