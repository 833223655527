import { useState } from "react";
import axiosBase  from '../../services/auth';
import { REPORT_API_UTIL } from '../../util/APIUtility';

const base = {
  default: axiosBase,
  report: REPORT_API_UTIL
}

function useMutationHook({ url, baseUri="default", method = "POST", onError, onComplete, routesToInvalidate = [] }) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [data, setData] = useState();
	const [headers, setHeaders] = useState();
  const [previousPayload, setPreviousPayload] = useState({});


	const post = async (payload) => {
		setLoading(true);

		try {
			const response = await base[baseUri].request({
				url: url,
				method,
				data: payload
			});
			setHeaders(response.headers);
			setData(response.data);
			onComplete?.(response.data, response.headers, payload);
			setError(null)
		} catch (error) {
			const err = error || "Unable to complete process, try again";
			onError?.(err, payload);
			setError(err);
		}
		setLoading(false);
	};

	const request = (newData, getPreviousPayload=()=>{}) => {
    getPreviousPayload(previousPayload)
		post(newData).finally(() => {
      setPreviousPayload(newData)
			// Optionally, you can add any cleanup or after-request logic here
		});
	};

	return {
		headers,
		loading,
		error,
		data,
		request,
    previousPayload
	};
}

export default useMutationHook;
