import styled from "styled-components";
import { BodyCSS } from "../../../components/ReusableComponents/Card-Body/BodyCSS";
import { BodyTitle } from "../../../components/ReusableComponents/Text/Text";


export const DescriptionBody = styled.div`
  width:  100%;
  max-width: 1776px;
  height: 236px;
  margin: ${({margin}) => margin || '24px 0 0 0'} ;
  display: flex;
  flex-direction: row;
  font-family: Commissioner;
`;

export const DetailsDiv = styled.div`
  width: 100%;
  min-width: 990px;
  max-width: 990px;
  height: 236px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  position: relative;
  ${BodyCSS}
`;

export const TableAnalyticsDiv = styled.div`
  min-width: 1800px;
  max-width: 1800px;
  height: fit-content;
  max-height: 662px;
  transition: all 0.15s linear;
`;

export const EmptyBodyText = styled(BodyTitle)`
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
`;