import service from "../util/APIUtility";

export const businessTools = {
  getRevenuePlans: async (options) => {
    const response = await service.get("/api/revenue_plans", options);
    return response.data.data;
  },
  createRevenuePlan: async (plan) => {
    const response = await service.post("/api/revenue_plans", plan);
    return response;
  },
  patchRevenuePlan: async (id, plan) => {
    const response = await service.patch(`/api/revenue_plans/${id}`, plan);
    return response;
  },
  deleteRevenuePlan: async (id) => {
    const response = await service.delete(`/api/revenue_plans/${id}`);
    return response;
  },
  getCityDetails: async (id) => {
    const response = await service.get(`/api/market_hunter/markets/${id}`);
    return response;
  },
  getCityDetailsV2: async (id) => {
    const response = await service.get(`/api/market_hunter/markets/${id}?v=2`);
    return response;
  },
  searchCity: async ({q,ac}) => {
    const response = await service.get(`/api/market_hunter/search?q=${q || ' '}&ac=${ac}`);
    return response;
  },
  searchCityV2: async ({q,ac}) => {
    const response = await service.get(`/api/market_hunter/search?v=2&q=${q || ' '}&ac=${ac}`);
    return response;
  },
  getPortfolioGoals: async (options) => {
    const { month, year } = options;
    const response = await service.get(`/api/goal-plans?view=portfolio&month=${month}&year=${year}`);
    return response.data.data;
  },
  getMarketGoals: async (options) => {
    const { month, year } = options;
    const response = await service.get(`/api/goal-plans?view=market&month=${month}&year=${year}`);
    return response.data.data;
  },
  getPropertyGoals: async (options) => {
    const { month, year } = options;
    const response = await service.get(`/api/goal-plans?view=property&month=${month}&year=${year}`);
    return response.data.data;
  },
  updatePropertyGoals: async (options) => {
    const { body } = options;
    let result = await service.put(`/api/goal-plans`, body);
    return result;
  },
  deletePropertyGoals: async (options) => {
    const { ids } = options;
    let result = await service.delete(`/api/goal-plans?ids=${ids}`);
    return result;
  }, 

};
