import React from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';

import rise from 'assets/images/generic/rise.svg'

const BarTooltipDiv = styled.div`
  ${BodyCSS}
  width: fit-content;
  height: fit-content;
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: none;
`;

const ValueDiv = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ValueText = styled(BodyText)`
  margin: 0px;
  color: ${({theme}) => theme.quibbleBlue};
`;

const MarketText = styled(ValueText)`
  color: ${({theme}) => theme.quibblePurple};
`;


const BarTooltip = ({label='', forecast, current, market, adjustmentMenu }) => {
  return (
    <BarTooltipDiv>
      <BodyText margin="0px">{label}</BodyText>
      <ValueDiv><ValueText>Forecast: {forecast} </ValueText> <img src={rise} alt="rise" /></ValueDiv>
      <ValueText>Current: {current} </ValueText>
      {adjustmentMenu !== "portfolio" && market && <MarketText>Market: {market}</MarketText>}
    </BarTooltipDiv>
  )
}

export default BarTooltip;