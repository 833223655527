import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { getDaysFromDayAndMonth } from 'util/helper';
import {difference} from 'lodash';

const SelectDayDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const DayButton = css`
  width: 46px;
  height: 24px;
  background: none;
  border-radius: 4px;
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s4};
  color: ${({theme}) => theme.quibbleBlue};
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  box-shadow: none;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover, &.active {
    color: ${({theme}) => theme.white};
    background:  ${({theme}) => theme.quibbleBlue};
  }
`;


const SelectDay = ({monthYear, setSelectedDaysFunc, selectedDates}) => {

  const selectedDatesWithDays =  useMemo(() => {
    if (selectedDates.length) {
      return selectedDates.map((date) => {
        const dateSplit = date.split('-');
        return new Date(dateSplit[0], Number(dateSplit[1])-1, dateSplit[2]).getDay();
      })
    }
    return [];
  }, [selectedDates]);

  const onSelectDay = (value) => {

    if (selectedDatesWithDays.includes(value)) {
      const getDaysOfMonth = getDaysFromDayAndMonth(value, monthYear.getMonth(), monthYear.getFullYear())
      const removedValues = difference([...selectedDates], [...getDaysOfMonth]);
      setSelectedDaysFunc(removedValues); 
    } else {
      const getDaysOfMonth = getDaysFromDayAndMonth(value, monthYear.getMonth(), monthYear.getFullYear());
      const newSelectedDays = [...new Set([...selectedDates, ...getDaysOfMonth])];
      setSelectedDaysFunc(newSelectedDays);
    }
  };

  return (
    <SelectDayDiv>
      <ButtonWithFunction className={selectedDatesWithDays.includes(0) && 'active'} ButtonStyle={DayButton} value={0} label={'Sun'} onClickFunc={onSelectDay}/>
      <ButtonWithFunction className={selectedDatesWithDays.includes(1) && 'active'} ButtonStyle={DayButton} value={1} label={'Mon'} onClickFunc={onSelectDay}/>
      <ButtonWithFunction className={selectedDatesWithDays.includes(2) && 'active'} ButtonStyle={DayButton} value={2} label={'Tue'} onClickFunc={onSelectDay}/>
      <ButtonWithFunction className={selectedDatesWithDays.includes(3) && 'active'} ButtonStyle={DayButton} value={3} label={'Wed'} onClickFunc={onSelectDay}/>
      <ButtonWithFunction className={selectedDatesWithDays.includes(4) && 'active'} ButtonStyle={DayButton} value={4} label={'Thu'} onClickFunc={onSelectDay}/>
      <ButtonWithFunction className={selectedDatesWithDays.includes(5) && 'active'} ButtonStyle={DayButton} value={5} label={'Fri'} onClickFunc={onSelectDay}/>
      <ButtonWithFunction className={selectedDatesWithDays.includes(6) && 'active'} ButtonStyle={DayButton} value={6} label={'Sat'} onClickFunc={onSelectDay}/>
    </SelectDayDiv>
  )
}

export default SelectDay;