import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LayoutBody } from '../../../components/ReusableComponents/Layout/LayoutBody';
import PropertyFilter from './components/PropertyDetailFilter';
import PropertyDescription from './components/PropertyDescription';
import PropertyMenu from './components/PropertyMenu';
import PropertyMap from './components/PropertyMap';
import { darkScrollbar, lightScrollbar } from '../../../components/ReusableComponents/Scrollbar/Scrollbar';
import PropertyContext from './components/PropertyContext';
// import { now } from '../../../constants/GlobalConstant';
import { getPropertyPricing } from '../../../appRedux/actions';
import PropertyHierarchy from './components/PropertyHierarchy';

const PropertyBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const PropertyDetail = () => {

  const { propertyDetails, isFetchingPropertyDetails, propertyOverviewMetrics, propertyModel } = useSelector(({ property }) => property);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [monthYear, setMonthYear] = useState(new Date());

  const {address} = propertyOverviewMetrics;

  const location = useLocation();
  const {state} = location;

  //model tab state
  const [model, setModel] = useState({
    price: 1,
    bedrooms: 1,
    ratingValue: 0,
    reviewCount: 0,
    // downtown: 0,
    minNights: 0,
    jacuzzi: false,
    pool: false,
    landscapeViews: false,
    imageScore: 0,
  });

  const dispatch = useDispatch();
  const month = monthYear.getMonth()+1;
  const year = monthYear.getFullYear();
  const propertyId = selectedProperty.length ? selectedProperty[0] : state?.propertyId;

  useEffect(() =>{
    dispatch(getPropertyPricing({month, year, propertyId}));
  },[month, year, propertyId, dispatch])

  //get model properties
  useEffect(() => {
      setModel({
        price: propertyModel?.price || 1,
        bedrooms: propertyModel?.bedrooms || 1,
        ratingValue: propertyModel?.rating_value || 0,
        reviewCount: propertyModel?.review_count || 0,
        // downtown: propertyModel?.dist || 0,
        minNights: propertyModel?.min_nights || 0,
        jacuzzi: propertyModel?.jacuzzi || false,
        pool: propertyModel?.pool || false,
        landscapeViews: propertyModel?.landscape_views || false,
        imageScore: propertyModel?.adjusted > 0 ? propertyModel?.adjusted :
        Number((propertyModel?.images?.reduce((output, current) => output + current.score, 0) /  propertyModel?.images?.length).toFixed(2)) || 0,
      })
  }, [propertyModel]);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value); 
  }, []);

  //using context to access model properties on filter, model calendar, and model info
  return (
    <PropertyBody>
      <PropertyContext.Provider value={{model, setModel}}>
        <PropertyFilter selectedProperty={selectedProperty} setSelectedProperty={setSelectedProperty} property={state} setMonthYearVal={setMonthYearVal} monthYear={monthYear} model={model} propertyDetails={propertyDetails}  />
        <PropertyHierarchy propertyDetails={propertyDetails} propertyId={propertyId} isFetchingPropertyDetails={isFetchingPropertyDetails} monthYear={monthYear} childIndex={state?.childIndex} />
        <PropertyDescription isFetchingPropertyDetails={isFetchingPropertyDetails} propertyDetails={propertyDetails} monthYear={monthYear}/>
        <PropertyMenu propertyModel={propertyModel} propertyId={propertyId}  monthYear={monthYear} setMonthYear={setMonthYear} isFetchingPropertyDetails={isFetchingPropertyDetails} propertyDetails={propertyDetails} />
        <PropertyMap propertyDetails={propertyDetails} isFetchingPropertyDetails={isFetchingPropertyDetails} address={address}/>
      </PropertyContext.Provider>
    </PropertyBody>
    )
}

export default PropertyDetail;
