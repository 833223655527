import React from 'react';

const HighDot = (props) => {
  const { cx, cy, value } = props;

  if (value > 0) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" x={cx - 10} y={cy - 3} width="19" height="6" viewBox="0 0 19 6" fill="none">
        <path d="M0.833333 3C0.833333 4.47276 2.02724 5.66667 3.5 5.66667C4.97276 5.66667 6.16667 4.47276 6.16667 3C6.16667 1.52724 4.97276 0.333333 3.5 0.333333C2.02724 0.333333 0.833333 1.52724 0.833333 3ZM12.8333 3C12.8333 4.47276 14.0272 5.66667 15.5 5.66667C16.9728 5.66667 18.1667 4.47276 18.1667 3C18.1667 1.52724 16.9728 0.333333 15.5 0.333333C14.0272 0.333333 12.8333 1.52724 12.8333 3ZM3.5 3.5H15.5V2.5H3.5V3.5Z" fill="#B479FF"/>
      </svg>  
    );
  }

  return null
}

export default HighDot;