import React, { useCallback } from 'react';
import styled from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Area, AreaChart, Tooltip,} from 'recharts';
import { currencyFormatter } from '../../../../../util/helper';
import SkLoaderOver from '../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';


const MetricChartDiv = styled.div`
  ${BodyCSS}
  width: 438px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  position: relative;
`;

const MetricTitle = styled.h1`
  font-size: 10px;
  font-family: Commissioner;
  color: #697273;
  margin: 0px;
  padding: 0px;
`;

const MetricLabelDiv = styled.div`
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MetricLabel = styled(MetricTitle)`
  font-size: 24px;
`;

const MetricDiff = styled(MetricTitle)`
  color: ${({color}) => color};
  font-size: 16px;
  margin: 0 0 0 8px;
`;

const ArrowUp = styled(ArrowUpOutlined)`
  font-size: 14px;
  margin: 0 0 0 4px;
`;

const ArrowDown = styled(ArrowDownOutlined)`
  font-size: 14px;
  margin: 0 0 0 4px;
  color: #F26262;
`;

const TooltipDiv = styled.div`
  ${BodyCSS}
  width: fit-content;
  height: auto;
  background: #283334;

  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 16px;
  gap: 4px;  
`;

const TooltipColorText = styled(BodyText)`
  color: white;
`;

const TooltipText = styled(TooltipColorText)`
  margin: 0px;
  text-transform: capitalize;
`;

const MetricChart = ({metricData=[], value, diffValue, title="title", isBlue=true, isLoading}) => {


  const isOccupancy = title === 'occupancy';

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload) {

      const tooltipPayload = payload[0].payload;

      return (
        <TooltipDiv>
          <TooltipColorText>
            {tooltipPayload?.name}
          </TooltipColorText>
          <TooltipText>
            {title}: {isOccupancy ? `${tooltipPayload?.data}%` : currencyFormatter(tooltipPayload?.data)}
          </TooltipText>
        </TooltipDiv>
      );
    }
    return null;
  };

  const isNegative = diffValue < 0;
  const isPositive = diffValue > 0;  

  const loadDefs = useCallback(() => {

    const gradientOffset = (data) => {
      const dataMax = Math.max(...metricData?.map((i) => i.data));
      const dataMin = Math.min(...metricData?.map((i) => i.data));
    
      if (dataMax <= 0) {
        return 0;
      }
      
      if (dataMin >= 0) {
        return 1;
      }  
    
      return dataMax / (dataMax - dataMin);
    };

    const off = gradientOffset();

    return (
      <defs>
        {isBlue ? (
          <linearGradient id={`${title.split(' ').join('')}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset={off/2} stopColor="#2ECADB" stopOpacity={1}/>
            <stop  offset={off} stopColor="#2ECADB" stopOpacity={0.5}/>
            <stop  offset={off} stopColor="#FFD572" stopOpacity={0.5}/>
            <stop  offset={off*2} stopColor="#FFD572" stopOpacity={1}/>
          </linearGradient> ): (
          <linearGradient id={`${title.split(' ').join('')}`} x1="0" y1="0" x2="0" y2="1">
             <stop offset={off/2} stopColor="#C6A1F5" stopOpacity={1}/>
              <stop  offset={off} stopColor="#C6A1F5" stopOpacity={0.5}/>
              <stop  offset={off} stopColor="#FFD572" stopOpacity={0.5}/>
              <stop  offset={off*2} stopColor="#FFD572" stopOpacity={1}/>
          </linearGradient>
          )}
      </defs>
    )
  }, [title, metricData, isBlue])

  return (
    <MetricChartDiv key={title}>
      <MetricTitle>
        {title.toUpperCase()}
      </MetricTitle>
      <MetricLabelDiv>
        <MetricLabel>
          {value ? isOccupancy ?  `${value}%` : currencyFormatter(value) : 0} 
        </MetricLabel>
        <MetricDiff color={isPositive ? "#78BA47" : "#F26262"}>
         {diffValue ? isOccupancy ? `${diffValue}%` :  currencyFormatter(diffValue) : 0} 
         {isPositive ? <ArrowUp /> : isNegative? <ArrowDown /> : null}
        </MetricDiff>
      </MetricLabelDiv>
        <AreaChart
          key={title}
          width={406}
          height={75}
          data={metricData}
        >                            
          <Tooltip content={<CustomTooltip />} />
          {
            loadDefs()
          }
          <Area
            key={title}
            dataKey={"data"}
            type="monotone"
            strokeWidth={0}
            fill={`url(#${title.split(' ').join('')})`}
            fillOpacity={1}
          />
        </AreaChart>
        <SkLoaderOver isLoading={isLoading} type='card' />
    </MetricChartDiv>
  )
}

export default MetricChart;