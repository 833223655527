import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { LayoutBody } from '../../components/ReusableComponents/Layout/LayoutBody';
import {
  darkScrollbar,
  lightScrollbar,
} from "components/ReusableComponents/Scrollbar/Scrollbar";
import WorkingQueueFilter from './components/WorkingQueueFilter';
import WorkingQueueTable from './components/WorkingQueueTable';
import WorkingQueuePreview from './components/WorkingQueuePreview';
// import DemoBanner from './components/DemoBanner';
import { useSelector } from 'react-redux';
import ActionableTable from './components/ActionableTable';
// import { getPropertyQueue } from '../../appRedux/actions';

const WorkingQueueBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const WorkingQueue = () => {
  // const [showWarning, setShowWarning] = useState(true);
  const [monthYear, setMonthYear] = useState(new Date());
  const [searchString, setSearchString] = useState('');
  const [showPreview, setShowPreview] = useState({
    visible: false,
    id: '',
    index: 0 ,
  });
  const [tableView, setTableView] = useState("list");

  const { propertyQueue, isFetchingPropertyQueue, actionableQueue, isFetchingActionableQueue  } = useSelector(({ property }) => property);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getPropertyQueue());
  // }, [dispatch]);

  return (
    <>
    <WorkingQueueBody>
    {/* {showWarning  && <DemoBanner setShowWarning={setShowWarning}/>} */}
      {
        showPreview.visible ? <WorkingQueuePreview monthYear={monthYear}  setMonthYear={setMonthYear} showPreview={showPreview}  setShowPreview={setShowPreview}/> :
        <Fragment>
          <WorkingQueueFilter numberOfQueue={propertyQueue.length } hasActionable={actionableQueue.length}  setSearchString={setSearchString} setTableView={setTableView} tableView={tableView}/>
          {tableView === 'list' ? <WorkingQueueTable  monthYear={monthYear}  isFetchingPropertyQueue={isFetchingPropertyQueue} workingQueueData={propertyQueue} setShowPreview={setShowPreview} searchString={searchString}/>
           : <ActionableTable monthYear={monthYear}  isFetchingActionableQueue={isFetchingActionableQueue} actionableData={actionableQueue} setShowPreview={setShowPreview} searchString={searchString}/>}
        </Fragment>
      }
    </WorkingQueueBody>
    </>
  )
}

export default WorkingQueue;