import { css } from "styled-components";
import { black, quibbleBlue, quibbleLightBlue, white, dirtyGrey  } from "../../../styles/stylesheet";

export const lightScrollbar = css`
&::-webkit-scrollbar {
  width:  8px;
  height: 8px;
}

&::-webkit-scrollbar-track {
  background: ${white};
  border-radius: 4px; 
}

&::-webkit-scrollbar-thumb {
  background: ${dirtyGrey};
  cursor: pointer;
  border-radius: 4px; 

  border-right: 2px ${white} solid;
  background-clip: padding-box;
}

&::-webkit-scrollbar-thumb:hover {
  background:  ${quibbleBlue};
}
`

export const darkScrollbar = css`
&::-webkit-scrollbar {
  width:  8px;
  height: 8px;
}

&::-webkit-scrollbar-track {
  background: ${black};
  border-radius: 4px; 
}

&::-webkit-scrollbar-thumb {
  background: ${quibbleLightBlue};
  cursor: pointer;
  border-radius: 4px; 

  border-right: 2px ${black} solid;
  background-clip: padding-box;
}

&::-webkit-scrollbar-thumb:hover {
  background:  ${quibbleBlue};
}

`;