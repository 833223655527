import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useSelector } from "react-redux";

import { PrimaryButton } from '../../../components/ReusableComponents/Button/Button';

import { Radio, Space, InputNumber, Form, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { user } from '../../../services/user';

const IntelCompSetsLookupContainer = styled.div`
  min-height: calc(100vh - 250px);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const IntelCompSetsLookupDescription = styled.text`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  display: flex;
`;

const SettingsContainer = styled.div`
  flex-grow: 1;
  margin: 25px 0;
  background: #fff;
  padding: 20px;

  .ant-space-item {
    margin-bottom: 16px;
  }

  .ant-form-horizontal .ant-row {
    margin: 0;
  }
`;

const SettingsText = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 20px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SaveButton = styled(PrimaryButton)`
  width: 190px;
  height: 50px;
  background: #1BB3CD;
  color: white;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-input-number {
    margin-left: 20px;
  }
`;

const InputLabel = styled.div`
  width: 60px;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;

  color: #F26262;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  .anticon {
    margin-right: 10px;
  }
`;

const IntelCompSetsLookup = () => {
  const parameters = useSelector((state) => state.users.authUserDetails.parameters);

  const {
    searchMode: initialSearchMode = 'city',
    bedsSelectionWeight = 0,
    distanceSelectionWeight = 0,
    adrSelectionWeight = 0
  } = parameters || {};

  const [isLoading, setIsLoading] = useState(false);

  const [mode, setMode] = useState(initialSearchMode);
  const [beds, setBeds] = useState(bedsSelectionWeight);
  const [adr, setADR] = useState(adrSelectionWeight);
  const [distance, setDistance] = useState(distanceSelectionWeight);
  const [inputError, setInputError] = useState(false);

  const onSearchModeChange = (e) => {
    setMode(e.target.value);
  };

  const onUpdateParameters = async () => {
    const bedsDecimal = beds / 100;
    const adrDecimal = adr / 100;
    const distanceDecimal = distance / 100;
  
    const body = {
      bedsSelectionWeight: bedsDecimal,
      adrSelectionWeight: adrDecimal,
      distanceSelectionWeight: distanceDecimal,
      searchMode: mode,
    };
  
    try {
      setIsLoading(true); 
  
      const res = await user.updateUserParameters({ body });
  
      if (res.status === 200) {
        message.destroy();
        message.success('Updated user parameters saved.');
        setIsLoading(false); 
        return;
      }
  
      setIsLoading(false); 
      message.error('Updating user parameters failed.');
    } catch (e) {
      setIsLoading(false); 
      message.destroy();
      message.error('Updating user parameters error.');
    }
  };

  useEffect(() => {
    const total = beds + adr + distance;
    setInputError(total !== 100);
  }, [beds, adr, distance]);

  useEffect(() => {
    const initialBeds = bedsSelectionWeight * 100;
    const initialADR = adrSelectionWeight * 100;
    const initialDistance = distanceSelectionWeight * 100;
  
    setBeds(initialBeds);
    setADR(initialADR);
    setDistance(initialDistance);
    setMode(initialSearchMode);
  }, [initialSearchMode, bedsSelectionWeight, adrSelectionWeight, distanceSelectionWeight]);
  

  return (
    <IntelCompSetsLookupContainer>
      <SettingsContainer>
        <IntelCompSetsLookupDescription>Fine-tune how we identify your key competitors.</IntelCompSetsLookupDescription>
        <SettingsText>Search Mode</SettingsText>
        <Radio.Group onChange={onSearchModeChange} value={mode}>
          <Space direction="vertical">
            <Radio value={'city'}>City</Radio>
            <Radio value={'radius'}>Radius</Radio>
          </Space>
        </Radio.Group>
        
        <SettingsText>Selection Weights</SettingsText>
        <Form>
          <Form.Item
            validateStatus={inputError && beds + adr + distance !== 100 ? "error" : ""}
          >
            <InputContainer>
              <InputLabel>Beds</InputLabel>
              <InputNumber
                min={0}
                value={beds}
                onChange={(value) => setBeds(value)}
                formatter={(value) => (`${value ? value : 0}%`)}
                parser={(value) => value?.replace('%', '')}
              />
            </InputContainer>
          </Form.Item>
          <Form.Item
            validateStatus={inputError && beds + adr + distance !== 100 ? "error" : ""}
          >
            <InputContainer>
              <InputLabel>ADR</InputLabel>
              <InputNumber
                min={0}
                value={adr}
                onChange={(value) => setADR(value)}
                formatter={(value) => (`${value ? value : 0}%`)}
                parser={(value) => value?.replace('%', '')}
              />
            </InputContainer>
          </Form.Item>
          <Form.Item
            validateStatus={inputError && beds + adr + distance !== 100 ? "error" : ""}
          >
            <InputContainer>
              <InputLabel>Distance</InputLabel>
              <InputNumber
                min={0}
                value={distance}
                onChange={(value) => setDistance(value)}
                formatter={(value) => (`${value ? value : 0}%`)}
                parser={(value) => value?.replace('%', '')}
              />
            </InputContainer>
          </Form.Item>
        </Form>
        {inputError && <ErrorMessage><InfoCircleOutlined />Total of 100% required</ErrorMessage>}
      </SettingsContainer>
      <ButtonContainer>
        <SaveButton disabled={isLoading || inputError} onClick={onUpdateParameters}>
          Save
        </SaveButton>
      </ButtonContainer>
    </IntelCompSetsLookupContainer>
  )
}

export default IntelCompSetsLookup;