import React from 'react';
import styled from 'styled-components';
import { Chart } from 'react-charts';

import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle, BodyText } from 'components/ReusableComponents/Text/Text';
import SkLoaderOver from '../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';


const PriceRevenueGraphDiv = styled.div`
  ${BodyCSS}
  width: 890px;
  min-width: 890px;
  height: 100%;
  padding: ${({$padding}) => $padding};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TitleDiv = styled.div`
  width: 100%;
  padding: 0px 42px;
`;

const BlackText = styled(BodyText)`
  margin: 0px;
  color: black;
`;

const GridTextBottom = styled(BodyText)`
  position: absolute;
  font-size: 14px;
  bottom: 16px;
  margin: auto;
`;

const GridTextRight = styled(BodyText)`
  position: absolute;
  font-size: 14px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  top: 248px;
  right: 12px;
  margin: auto;
`;

const GridTextLeft = styled(BodyText)`
  position: absolute;
  font-size: 14px;
  transform: rotate(-90deg);
  top: 300px;
  left: -28px;
  margin: auto;
`;

const Legend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 32px 0 0 0;
  padding: 0px 42px;
`;

const LegendLabel = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LabelColor = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 8px;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: ${({$background}) => $background};
`;

const PrevCircle = styled(Circle)`
  margin: 0 0 0 8px;

`;

const ChartDiv = styled.div`
  width: 800px;
  height: 380px;
  padding: 32px 0;
  box-sizing: border-box;
`;


const PriceRevenueGraph = ({priceRevData, isLoading, monthYear}) => {

  const currencySymbol = localStorage.getItem('currency')

  const axes = [
    {
      primary: true,
      type: 'linear',
      position: 'bottom',
      min: 0,
      showGrid: true,
      hardMax: 365,
      format: d => `Day ${d}`
    },
    {
      type: 'linear',
      id: 'Occupancy',
      hardMin: 0,
      hardMax: 100,
      position: 'left',
      showGrid: true,
      format: d => `${d+'%'}`,
    },
    {
      type: 'linear',
      id: 'ADR',
      hardMin: 0,
      position: 'right',
      showGrid: true,
      format: d => `${currencySymbol}${d}`,
    }
  ];

  //   /** Series for lines to set forcast value */
  // const series =() => ({
  //     type: "line",
  //     showPoints: false,
  //     precision: 0,
  //     showGrid:true,
  // });

  const series = (s) => ({
      type: s?.originalSeries?.isForecast ? 'bubble' : 'line',
      showPoints: false,
      precision: 0,
      showGrid: true,
  });

  const currentYear = monthYear.getFullYear();

  return (
    <PriceRevenueGraphDiv $padding={isLoading ? '16px 32px' : '16px 0px'}>
     <GridTextBottom>
        Days to End of Month      
      </GridTextBottom>
      <GridTextRight>
        Average Daily Rate (ADR)
      </GridTextRight>
      <GridTextLeft>
        Occupancy (OCC)
      </GridTextLeft>
      <TitleDiv>
        <BodyTitle>
          Demand & Price Behavior
        </BodyTitle>
      </TitleDiv>
      <Legend>
        <LegendLabel>
          <BlackText>
            Occupancy
          </BlackText>
          <LabelColor>
            <Circle $background="#2ECADB"/>
            <BodyText margin="0 0 0 8px">
              {currentYear}
            </BodyText>
            <PrevCircle $background="#9747FF"/>
            <BodyText margin="0 0 0 8px">
              {currentYear - 1}
            </BodyText> 
          </LabelColor>
        </LegendLabel>
        <LegendLabel>
          <BlackText>
            ADR
          </BlackText>
          <LabelColor>
            <Circle $background="#FFC805"/>
            <BodyText margin="0 0 0 8px">
              {currentYear}
            </BodyText>
            <PrevCircle $background="#C6A1F5"/>
            <BodyText margin="0 0 0 8px">
              {currentYear - 1}
            </BodyText> 
          </LabelColor>
        </LegendLabel>
        {/* <LegendLabel>
          <BlackText>
            Market
          </BlackText>
          <LabelColor>
            <Circle $background="#9747FF"/>
              &nbsp;
            <Circle $background="#C6A1F5"/>
            <BodyText margin="0 0 0 8px">
              2022
            </BodyText>
          </LabelColor>
        </LegendLabel> */}
      </Legend>
      {
        !isLoading ?  
        <ChartDiv>
          <Chart data={priceRevData} series={series} axes={axes} grouping="single" tooltip={{ align: "bottom" }}/>
        </ChartDiv> : null
      }
      <SkLoaderOver isLoading={isLoading} type='card' />
    </PriceRevenueGraphDiv>
  )
}

export default PriceRevenueGraph;