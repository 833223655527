import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from "axios";

import GoogleMapReact from 'google-map-react';

import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Checkbox, Button } from 'antd';

import rating from '../../../../../assets/images/markethunter/ratings.svg';

import airbnb_logo from '../../../../../assets/images/markethunter/airbnb-logo.svg';
import vrbo_logo from '../../../../../assets/images/markethunter/vrbo-logo.svg';
import roomtype from '../../../../../assets/images/markethunter/roomtype.svg';

import MarkerCardListingDetails from './MarkerCardListingDetails';
import MHMapLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHMapLoader';

const MapContainer = styled.div`
  height: 100%;
`;

const MapMarker = styled.div`
  width: 10px;
  height: 10px;
  flex-shrink: 0;

  border-radius: 10px;
  border: 1px solid #005B6A;
  background: #1BB3CD;
`;

const InfoCardContainer = styled.div`
  text-align: left;
  position: absolute;
  background-color: #FFF;
  border-radius: 6px;
  background: #FFF;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 270px;
  height: 380px;

  left: -130px;
  top: -390px;

  .ant-btn {
    margin: 0;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .image-selected-marker {
    width: 100%;
    object-fit: cover;
    height: 180px;
    padding: 5px;
  }

  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -15px; /* Adjust this value based on the size of your arrow */
    margin-bottom: -15px;
    border-width: 15px 15px 0; /* Adjust the size of your arrow */
    border-style: solid;
    border-color: #1BB3CD transparent transparent; /* Adjust the colors of your arrow */
  }

`;

const RoomTypeContainer = styled.div`
  border-radius: 4px;
  background: #FFF;

  /* Button Shadow */
  box-shadow: 0px 1px 4px 0px rgba(145, 165, 169, 0.50);

  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;

  position: absolute;
  top: 140px;
  left: 15px;
`;

const RoomTypeText = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const InfoCardInformationContainer = styled.div`
  flex-grow: 1;
  padding: 10px;

  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;

  flex-grow: 1;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;


  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const TitleHoverText = styled.div`
  opacity: 0.9;
  position: absolute;
  top: 150px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #283334;
  color: #fff;
  border-radius: 5px;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;

  padding: 5px;
  max-width: 200px;
`;

const StatusText = styled.div`
  margin-left: 10px;

  color: #00A638;
  font-family: Commissioner;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 10px;

  border-radius: 100px;
  background: #DDFFE9;

  height: 25px;
`;

const InformationContainer = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  display: flex;

  padding: 5px 0;
`;

const InfoTextDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HostInfoContaier = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 0px 0px 6px 6px;
  background: #1BB3CD;

  img {
    height: 40px;
    border-radius: 50%;
  }
`;

const HostedByText = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const MHGoogleMapFilters = styled.div`
  position: absolute;
  z-index: 1;

  display: flex;
  flex-direction: column;

  min-width: 25%;

  .ant-btn {
    max-width: 100px;
    margin: 10px;
  }
`;

const MapFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;

  color: #697273;
  font-size: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);

  padding: 16px;
  margin: 10px;

`;

const FilterHeader = styled.div`
  display: flex;
  align-items: flex-start;

  font-family: Commissioner;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;

  margin-bottom: 5px;
`;

const FilterContainer = styled.div`
  margin-bottom: 16px;
`;

const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper{
    margin-top: 5px;
    margin-left: 0;
  }
`;

const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewCheckBoxContainer = styled.div`
  display: flex;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
`;

const BedroomButtonContainer = styled.div`
  display: flex;


  .ant-btn {
    padding: 0 10px;
  }

  .ant-btn:not(:last-child) {
    margin-right: 5px;
  }
`;

// UTILS
const processOccupancyRate = (occupancyValue) => {
  let ret = 0;
  if (occupancyValue)
    ret = occupancyValue * 100;
  return ret;
}

const processListingType = (listingType) => {
  let result = '';
  switch (listingType) {
    case 'private_room':
      result = 'Private Room';
      break;
    case 'entire_home':
      result = 'Entire Home';
      break;
    default:
      break;
  }

  return result;
};

const getCoordinatesFromSearch = async (search) => {
  const { city, state, country } = search;
  const apiKey = 'AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM';
  const address = `${city}, ${state}, ${country}`;
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

  try {
    const response = await axios.get(apiUrl);
    const { results } = response.data;

    if (results && results.length > 0) {
      const { location } = results[0].geometry;
      return {
        lat: location.lat,
        lng: location.lng,
      };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const MHMap = (props) => {

  const [error, setError] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [center, setCenter] = useState({lat: 53.4112, lng: -2.9676});
  const [zoom] = useState(12);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredListings, setFilteredListings] = useState([]);

  const [selectedTypes, setSelectedTypes] = useState(['entire_home', 'private_room', 'shared_room']);
  const [selectedReviews, setSelectedReviews] = useState([{min: 4.01, max: 5.00 }, {min: 3.01, max: 4.00 }, {min: 2.01, max: 3.00 }, {min: 1.01, max: 2.00 }, {min: 0.01, max: 1.00 }]);
  const [selectedBedrooms, setSelectedBedrooms] = useState([0, 1, 2, 3, 4, 5]);


  const handleMarkerClick = (marker) => {
    setCenter(marker.position);
    setSelectedMarker(marker);
  };

  const closeInfoCard = () => {
    setSelectedMarker(null);
  };

  const setHoverOpen = () => {
    setIsHovered(true);
  };

  const setHoverClose = () => {
    setIsHovered(false);
  };

  const clickFilter = () => {
    if (openFilter) {
      setOpenFilter(false);
    } else {
      setOpenFilter(true);
    }
  };

  const onTypeChange = (type, value) => {
    setSelectedTypes((prevSelectedTypes) => {
      if (value) {
        return [...prevSelectedTypes, type];
      } else {
        return prevSelectedTypes.filter(selectedType => selectedType !== type);
      }
    });
  };

  const onRatingChange = (min, max) => {
    const isSelected = selectedReviews.some(
      (range) => range.min === min && range.max === max
    );

    if (isSelected) {
      setSelectedReviews((prevRanges) =>
        prevRanges.filter((range) => !(range.min === min && range.max === max))
      );
    } else {
      setSelectedReviews((prevRanges) => [...prevRanges, { min, max }]);
    }
  };

  const onBedroomChange = (bedroom) => {
    setSelectedBedrooms((prevClickedBedrooms) => {
      if (prevClickedBedrooms.includes(bedroom)) {
        return prevClickedBedrooms.filter((clickedBedroom) => clickedBedroom !== bedroom);
      } else {
        return [...prevClickedBedrooms, bedroom];
      }
    });
  };

  const clearAllFilters = () => {
    setSelectedTypes([]);
    setSelectedReviews([]);
    setSelectedBedrooms([]);
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchCoordinates = async () => {
      try {
        const coordinates = await getCoordinatesFromSearch(props.adm);

        if (coordinates) {
          setCenter(coordinates)
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchCoordinates();
  }, [props.adm, error]);

  useEffect(() => {
    const filteredData = props.listings.filter((item) => {
      const typeFilter = selectedTypes.length === 0 || selectedTypes.includes(item.type);
      const reviewFilter =
        selectedReviews.length === 0 ||
        selectedReviews.some(
          (range) => item.averageRating >= range.min && item.averageRating <= range.max
        );
      const bedroomFilter =
        selectedBedrooms.length === 0 || selectedBedrooms.includes(item.bedrooms);

      return typeFilter && reviewFilter && bedroomFilter;
    });

    setFilteredListings(filteredData);
    setSelectedMarker(null);
  }, [props.listings, selectedTypes, selectedReviews, selectedBedrooms]);

  return (
    <MapContainer>
      { isLoading ? <MHMapLoader /> :
      <>
        <MHGoogleMapFilters>
          <Button onClick={clickFilter} type="primary" icon={<FilterOutlined />}>Filter</Button>
          {openFilter && (
            <MapFiltersContainer>
              <FilterContainer>
                <FilterHeader>Type:</FilterHeader>
                <TypeContainer>
                  <Checkbox checked={selectedTypes.includes('entire_home')} onChange={(e) => onTypeChange('entire_home', e.target.checked)}>Entire Home</Checkbox>
                  <Checkbox checked={selectedTypes.includes('private_room')} onChange={(e) => onTypeChange('private_room', e.target.checked)}>Private Room</Checkbox>
                  <Checkbox checked={selectedTypes.includes('shared_room')} onChange={(e) => onTypeChange('shared_room', e.target.checked)}>Shared Room</Checkbox>
                </TypeContainer>
              </FilterContainer>
              <FilterContainer>
                <FilterHeader>Reviews:</FilterHeader>
                <ReviewsContainer>
                  <ReviewCheckBoxContainer>
                    <Checkbox
                      checked={selectedReviews.some(
                        (range) => range.min === 4.01 && range.max === 5.00
                      )}
                      onChange={() => onRatingChange(4.01, 5.00)}
                    >
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                    </Checkbox>
                  </ReviewCheckBoxContainer>
                  <ReviewCheckBoxContainer>
                  <Checkbox
                      checked={selectedReviews.some(
                        (range) => range.min === 3.01 && range.max === 4.00
                      )}
                      onChange={() => onRatingChange(3.01, 4.00)}
                    >
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                    </Checkbox>
                  </ReviewCheckBoxContainer>
                  <ReviewCheckBoxContainer>
                    <Checkbox
                      checked={selectedReviews.some(
                        (range) => range.min === 2.01 && range.max === 3.00
                      )}
                      onChange={() => onRatingChange(2.01, 3.00)}
                    >
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                    </Checkbox>
                  </ReviewCheckBoxContainer>
                  <ReviewCheckBoxContainer>
                    <Checkbox
                      checked={selectedReviews.some(
                        (range) => range.min === 1.01 && range.max === 2.00
                      )}
                      onChange={() => onRatingChange(1.01, 2.00)}
                    >
                      <img src={rating} alt="star" />
                      <img src={rating} alt="star" />
                    </Checkbox>
                  </ReviewCheckBoxContainer>
                  <ReviewCheckBoxContainer>
                    <Checkbox
                      checked={selectedReviews.some(
                        (range) => range.min === 0.01 && range.max === 1.00
                      )}
                      onChange={() => onRatingChange(0.01, 1.00)}
                    >
                      <img src={rating} alt="star" />
                    </Checkbox>
                  </ReviewCheckBoxContainer>
                </ReviewsContainer>
              </FilterContainer>
              <FilterContainer>
                <FilterHeader>Bedrooms:</FilterHeader>
                <BedroomButtonContainer>
                  <Button
                    type={selectedBedrooms.includes(0) ? 'primary' : 'default'}
                    onClick={() => onBedroomChange(0)}
                  >Studio</Button>
                  <Button
                    type={selectedBedrooms.includes(1) ? 'primary' : 'default'}
                    onClick={() => onBedroomChange(1)}
                  >1</Button>
                  <Button
                    type={selectedBedrooms.includes(2) ? 'primary' : 'default'}
                    onClick={() => onBedroomChange(2)}
                  >2</Button>
                  <Button
                    type={selectedBedrooms.includes(3) ? 'primary' : 'default'}
                    onClick={() => onBedroomChange(3)}
                  >3</Button>
                  <Button
                    type={selectedBedrooms.includes(4) ? 'primary' : 'default'}
                    onClick={() => onBedroomChange(4)}
                  >4</Button>
                  <Button
                    type={selectedBedrooms.includes(5) ? 'primary' : 'default'}
                    onClick={() => onBedroomChange(5)}
                  >5+</Button>
                </BedroomButtonContainer>
              </FilterContainer>
              <div>
                <Button size='large' onClick={() => clearAllFilters()}>Clear All</Button>
              </div>
            </MapFiltersContainer>
          )}
        </MHGoogleMapFilters>

        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM" }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
        >
          {filteredListings?.map((marker) => (
            <MapMarker
              key={marker.id}
              lat={marker.coordinates?.latitude}
              lng={marker.coordinates?.longitude}
              onClick={() => handleMarkerClick(marker)}
              style={{ background: marker.isUserListing ? '#1BB3CD' : '#9747FF' }}
            />
          ))}
          {selectedMarker && (
            <InfoCardContainer
              lat={selectedMarker.coordinates?.latitude}
              lng={selectedMarker.coordinates?.longitude}
            >
              <Button onClick={closeInfoCard} shape="circle" icon={<CloseOutlined />} />
              <img src={selectedMarker.picture} className='image-selected-marker' alt={selectedMarker.name} />
              <RoomTypeContainer>
                <img src={roomtype} alt="roomtype"/>
                <RoomTypeText>{processListingType(selectedMarker.type)}</RoomTypeText>
              </RoomTypeContainer>
              <InfoCardInformationContainer>
                <TitleContainer onMouseEnter={setHoverOpen} onMouseLeave={setHoverClose}>
                  <Title style={{ color: isHovered ? '#00BBD2' : '#283334' }}>{selectedMarker.name}</Title>
                  <StatusText>{selectedMarker.active ? 'Active' : 'Inactive' }</StatusText>
                  {isHovered && (
                    <TitleHoverText>{selectedMarker.name}</TitleHoverText>
                  )}
                </TitleContainer>
                <MarkerCardListingDetails selectedMarker={selectedMarker} />
                <InformationContainer>
                  <InfoTextDiv>
                  <div>
                    ADR: $254
                  </div>
                  <div>
                    Occupancy: {selectedMarker.occupancy && processOccupancyRate(selectedMarker.occupancy?.v)}%
                  </div>
                  </InfoTextDiv>
                  <img src={airbnb_logo} alt='airbnb_logo' />
                  <img src={vrbo_logo} alt='vrbo_logo' />
                </InformationContainer>
              </InfoCardInformationContainer>
              <HostInfoContaier>
                <HostedByText>Hosted By: {selectedMarker.host?.name}</HostedByText>
                <img src={selectedMarker.host?.picture} alt={selectedMarker.host?.name} />
              </HostInfoContaier>
            </InfoCardContainer>
          )}
        </GoogleMapReact>
      </>
      }
    </MapContainer>
  );
}

export default MHMap;