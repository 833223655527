import React from 'react';
import styled from 'styled-components';
import isUndefined from 'lodash/isUndefined';
import { SecondaryButton as Button } from '../../../../../../components/ReusableComponents/Button/Button'
import { useHistory } from 'react-router-dom'

const CustomTooltipContainer = styled.div`
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
  color: #697273;

  &.customtooltip--withbutton {
    pointer-events: auto;
  }
`;

const Title = styled.div`
  font-weight: 500;
  color: #697273;
`;

const Data = styled.div`
  font-weight: normal;
  line-height: 14px;
  margin: 8px 0;
`;

const Tooltip = styled.div`
  margin-bottom: 14px;
`;

const formatForecast = (forecast, isPercentage) => {
  if (isPercentage) {
    return (forecast * 100)?.toFixed(0);
  } else if (forecast >= 1000) {
    return forecast?.toLocaleString();
  } else {
    return forecast?.toFixed(0);
  }
}

const getCurrentWeekNumber = () => {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const diff = (now - startOfYear) + ((startOfYear.getDay() - 1) * 24 * 60 * 60 * 1000);
  const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
  return weekNumber;
}

const showForecast = (key, view, year) => {
  const currentDate = new Date();
  const currentWeek = getCurrentWeekNumber();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  if (view === 'monthly' && year === currentYear && key >= currentMonth) {
    return true;
  } else if (view === 'monthly' && year > currentYear && key < currentMonth) {
    return true;
  } else if (view === 'weekly' && year === currentYear && key >= currentWeek) {
    return true;
  } else if (view === 'weekly' && year > currentYear && key < currentWeek) {
    return true;
  } else {
    return false;
  }
}

const renderData = (data, payload, view, year) => {
  const key = payload[data.valueIndex]?.payload?.key;
  const isForecast = !isUndefined(data?.forecastKey);

  const isShowForecast = showForecast(key, view, year);

  if (isForecast && isShowForecast) {
    const forecast = payload[data.valueIndex]?.payload[data.forecastKey];
    const formattedForecast = formatForecast(forecast, data?.isForecastPercentage);

    if (formattedForecast) return <>{data.key}: {data.prefix}{formattedForecast}{data.suffix}</>;
    else return <></>;
  } else if (isForecast && !isShowForecast) {
    return <></>;
  } else {
    let metricValue = payload[data.valueIndex]?.value;

    return <>{data.key}: {data.prefix}{metricValue.toLocaleString()}{data.suffix}</>
  }
}



const CustomTooltip = ({ active, payload, tooltip, view, year, hasAdjustPricingButton }) => {
  const history = useHistory();

  const renderTooltipContent = (p, payload, view, year) => {

  const key = payload[0]?.payload?.key;
    const isShowForecast = showForecast(key, view, year);

    const dateObject = new Date(year,key-1,1);

    const AdjustPricing = () => {
      history.push(
      {
        pathname: "/price-adjustment",
        state: {date: dateObject},
      },);
    }

  if (p.showAdjustPriceButton) {
    return (
      <>
        {isShowForecast && <Button onClick={AdjustPricing}>Adjust Pricing</Button>}
      </>
    )
  } else {
    return (
      <>
        <Title>{p.title}</Title>
        {
          p.data.map((t, j) => {
            return (
              <Data key={j}>{renderData(t, payload, view, year)}</Data>
            )
          })
        }
      </>
    )
  }
}
  if (active && payload && payload.length && tooltip?.length) {
    return (
      <CustomTooltipContainer className={hasAdjustPricingButton ? `customtooltip--withbutton` : ''}>
        {tooltip.map((p, i) => {
          return (
            <Tooltip key={i} position={{ x: 100, y: 140 }}>
              {renderTooltipContent(p, payload, view, year)}
            </Tooltip>
          )
        })}
      </CustomTooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
