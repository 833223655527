/** Default Import */
import React from "react";
// import asyncComponent from "util/asyncComponent";
import {Route, Switch} from "react-router-dom";

/** Custom Import */
import errorsPages from "./../errorsPages/index";
// import Property from "./Property/Property";
import Market from './Market';
import MarketDetails from "./MarketDetails";

import Property from './Property'
import PropertyDetails from "./PropertyDetails";

const marketAnalytics = ({match}) => (
  <Switch>
    <Route path={`${match.url}/market`} component={Market}/>
    {/* <Route path={`${match.url}/property`} component={Property}/> */}
    <Route path={`${match.url}/market-details`} component={MarketDetails}/>
    <Route path={`${match.url}/property-details`} component={PropertyDetails}/>
    <Route path={`${match.url}/market-property-details`} component={PropertyDetails}/>
    <Route path={`${match.url}/property`} component={Property}/>
    <Route exact component={errorsPages}/>
  </Switch>
);
export default marketAnalytics;
