import {
  SHOW_MESSAGE,
  USER_DASHBOARD,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  USER_DASHBOARD_SUCCESS,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_SUCCESS,
  GET_LOGIN_USER_DETAILS,
  GET_LOGIN_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  SHOW_ERROR_MESSAGES,
  GET_USERS_LISTING,
  GET_USERS_LISTING_SUCCESS,
  USER_PROFILE_ERROR_MESSAGES,
  USER_PROFILE_NOTIFY_ERROR_MESSAGES,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  FIVE_HUNDRED_ERROR,
  FIVE_HUNDRED_ERROR_SUCCESS,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_INSIGHT_MODAL_TYPE,
  SET_INSIGHT_MODAL_TYPE,
  MARKET_WATCH,
  LISTINGS_WATCH,
  LISTINGS_WATCH_BUILDING,
  MARKET_WATCH_SUCCESS,
  LISTINGS_WATCH_SUCCESS,
  LISTINGS_WATCH_BUILDING_SUCCESS,
  NOTIFICATION_WATCH_SUCCESS,
  NOTIFICATION_WATCH,
  SET_EMAILFREQUENCY,
  GET_USER_METRICS_SUMMARY,
  GET_USER_PORTFOLIO,
  UPDATE_USER_PMS,
} from "constants/ActionTypes";

/*** user dashboard action */
export const userDashboard = (user) => {
  return {
    type: USER_DASHBOARD,
    payload: user,
  };
};

/*** user markets action */
export const marketWatch = (markets) => {
  return {
    type: MARKET_WATCH,
    payload: markets,
  };
};

/*** user listings action */
export const listingWatch = (listings) => {
  return {
    type: LISTINGS_WATCH,
    payload: listings,
  };
};

export const notificationWatch = (listings) => {
  return {
    type: NOTIFICATION_WATCH,
    payload: listings,
  };
};

/*** user listings building action */
export const listingWatchBuilding = (listingsBuilding) => {
  return {
    type: LISTINGS_WATCH_BUILDING,
    payload: listingsBuilding,
  };
};

export const updateMarketWatch = (markets) => {
  return {
    type: MARKET_WATCH_SUCCESS,
    payload: markets,
  };
};


export const updateListingWatch = (listings) => {
  return {
    type: LISTINGS_WATCH_SUCCESS,
    payload: listings,
  };
};

export const updateNotificationWatch = (listings) => {
  return {
    type: NOTIFICATION_WATCH_SUCCESS,
    payload: listings,
  };
};

export const updateListingWatchBuilding = (listingsBuilding) => {
  return {
    type: LISTINGS_WATCH_BUILDING_SUCCESS,
    payload: listingsBuilding,
  };
};


export const updateuserInfo = (user) => {
  return {
    type: USER_DASHBOARD_SUCCESS,
    payload: user,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const userUpdateProfile = (values, isSelf) => {
  return {
    type: USER_UPDATE_PROFILE,
    payload: values,
    isSelf: isSelf,
  };
};

export const userUpdateProfileSuccess = (authUser, isSelf) => {
  return {
    type: USER_UPDATE_PROFILE_SUCCESS,
    payload: authUser,
    isSelf: isSelf,
  };
};

/*** Get Login User Details  */
export const getLoginUser = (value) => {
  return {
    type: GET_LOGIN_USER_DETAILS,
    payload: value,
  };
};

export const getLoginUserSuccess = (authUser) => {
  return {
    type: GET_LOGIN_USER_DETAILS_SUCCESS,
    payload: authUser,
  };
};

/*** Get User Details  */
export const getUser = (value) => {
  return {
    type: GET_USER_DETAILS,
    payload: value,
  };
};

export const getUserSuccess = (userSelected) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: userSelected,
  };
};


/*** Get Login User Details  */
export const getCountryList = (value) => {
  return {
    type: GET_COUNTRY_LIST,
    payload: value,
  };
};

export const getCountryListSuccess = (value) => {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: value,
  };
};

export const getUsersLising = (values) => {
  return {
    type: GET_USERS_LISTING,
    payload: values,
  };
};

export const updateUsersList = (usersListing) => {
  return {
    type: GET_USERS_LISTING_SUCCESS,
    payload: usersListing,
  };
};

export const showErrorMessages = (messages) => {
  return {
    type: SHOW_ERROR_MESSAGES,
    payload: messages,
  };
};

export const showUserProfileErrorMessages = (messages) => {
  return {
    type: USER_PROFILE_ERROR_MESSAGES,
    payload: messages,
  };
};

export const showUserErrorMessages = (messages) => {
  return {
    type: USER_PROFILE_NOTIFY_ERROR_MESSAGES,
    payload: messages,
  };
};

export const userChangePassword = (values) => {
  return {
    type: USER_CHANGE_PASSWORD,
    payload: values,
  };
};

export const userChangePasswordSuccess = (authUser) => {
  return {
    type: USER_CHANGE_PASSWORD_SUCCESS,
    payload: authUser,
  };
};

export const updateUserStatus = (values) => {
  return {
    type: UPDATE_USER_STATUS,
    payload: values,
  };
};

export const updateUserStatusSuccess = (authUser) => {
  return {
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: authUser,
  };
};

export const fiveHundredErrorSuccess = (authUser) => {
  return {
    type: FIVE_HUNDRED_ERROR_SUCCESS,
  };
};

export const fiveHundredError = (authUser) => {
  return {
    type: FIVE_HUNDRED_ERROR,
    payload: authUser,
  };
};

// Insight Modal Data
export const getInsightModalData = (values) => {
  return {
    type: GET_INSIGHT_MODAL_TYPE,
    payload: values,
  };
};

// Insight Modal Data

export const setInsightModalData = (values) => {
  return {
    type: SET_INSIGHT_MODAL_TYPE,
    payload: values,
  };
};


export const setmailFrequency = (values) => {
  return {
    type: SET_EMAILFREQUENCY,
    payload: values,
  }
}

export const getUserMetricsSummary = (values) => {
  return {
    type: GET_USER_METRICS_SUMMARY,
    payload: values,
  }
}

export const getUserPortfolio = (values) => {
  return {
    type: GET_USER_PORTFOLIO,
    payload: values,
  }
}

export const updateUserPms = (values) => {
  return {
    type: UPDATE_USER_PMS,
    payload: values,
  }
}