import React, {useEffect} from 'react';
import styled, { css } from 'styled-components';

import listIcon from 'assets/images/generic/list.svg'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyQueue, getActionableQueue } from 'appRedux/actions';



const ListButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  background: none;
  margin: 0 10px 0 0;
  cursor: pointer;
  transition: all 0.2s linear;
  z-index: 50;
  position: relative;

  ${({positionCSS}) => positionCSS};

  &:hover {
    transform: scale(1.1);
  }
`;

const ListImg = styled.img`
  width: 17px;
  height: 22px;
  image-rendering: -webkit-optimize-contrast;
`;

const OrangeSpan = styled.span`
  width: fit-content;
  min-width: 16px;
  height: fit-content;
  min-height: 16px;
  padding: 4px;
  border-radius: 100px;
  background: #F35A22;
  color: white;
  text-align: center;
  font-size: 10px;

  position: absolute;
  right: -6px;
  top: -6px;
  // padding: 1px 0 0 0;

`;


const WorkingQueueMenu = ({isNative=false}) => {

  const history = useHistory();

  const { propertyQueue, actionableQueue  } = useSelector(({ property }) => property);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPropertyQueue());
    dispatch(getActionableQueue());
  }, [dispatch]);

  const onListClick = () => {
    history.push ('/workingqueue');
  };

  const positionCSS = isNative ? css`
    margin: 6px 16px 0 0;
    position: relative;
  ` : css`
    margin: 2px 12px 0 0;
  `;

  return (
    <ListButton positionCSS={positionCSS} onClick={onListClick}>
      <ListImg src={listIcon} alt="listIcon" />
        {propertyQueue.length || actionableQueue.length ? 
        <OrangeSpan>
        {propertyQueue.length +  actionableQueue.length}
      </OrangeSpan> :  null}
    </ListButton>
  )
}

export default WorkingQueueMenu;