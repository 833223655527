import React, {memo, useCallback} from 'react';
import styled, { css } from 'styled-components';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import { useHistory, useLocation } from 'react-router-dom';


const MenuButtonText = styled(BodyText)`
  color: inherit;
  transition: ${({transition}) => transition};
  font-size: ${({visibility}) => visibility};
`;

const IconSpan = styled.span`
  color: inherit;
  font-size: ${({theme}) => theme.s5};
  margin: ${({margin}) => margin};
`;

const ArrowSpan = styled.span`
  width: fit-content;
  height: fit-content;
  color: inherit;
  font-size: ${({theme}) => theme.s3};

  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
`;

const MenuButtonDiv = styled.button`
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-radius: 4px;
  padding: 0 10px;
  border: none;
  outline: none;
  cursor: pointer;
  background: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.15s linear;
  position: relative;
  white-space: nowrap;
  color: ${({theme}) => theme.bodyFg};

  &:disabled {
    color: ${({theme}) => theme.disabledGrey};
    &:hover {
      background: inherit;
      color: ${({theme}) => theme.disabledGrey};
    }
  };

  &:hover {
    background: ${({theme}) => theme.quibbleHoverGrey};
    color: ${({theme}) => theme.black};
  }

  &.active {
   ${({activeCSS}) => activeCSS};
  };
`;




const MenuButton = ({icon, label, sidebarCollapsed, withArrow, subUrls=[], menuValue, setActiveMenuFunc=()=>{}, isDisabled=false, isHoverActive, toggleCollapseNav, subList=[], onClick}) => {

  const history = useHistory();
  const location = useLocation();

  const isExpanded = menuValue?.includes(label) && !sidebarCollapsed;
  const isActive = (location.pathname === menuValue || subUrls.some((data) => data === location.pathname) )|| subList.filter((data) => (data.url === location.pathname || data?.subUrls?.some((data) => data === location.pathname))).length;

  const activeCSS = useCallback(() => {
    if (withArrow) {
      if (withArrow && sidebarCollapsed) {
        return css`
          background: ${({theme}) => theme.quibbleBlue}; 
          color: ${({theme}) => theme.white};
        `;
      };
        return css`
          background: ${({theme}) => theme.quibbleHoverGrey};
          color: ${({theme}) => theme.black};
        `;
      };

    return css`
      background: ${({theme}) => theme.quibbleBlue};
      color: ${({theme}) => theme.white};
    `;
  },[withArrow, sidebarCollapsed])


  const onMenuClick = useCallback(() => {
    if (label === 'Help Center' && onClick) {
      onClick();
      return;
    }

    if (withArrow) {
      setActiveMenuFunc(label);
      return;
    };
    history.push(menuValue)
  }, [setActiveMenuFunc, history, label, menuValue, withArrow, onClick]);

  const ShowArrow = useCallback(() => {
    if (sidebarCollapsed || !withArrow) return;

    if(isExpanded) {
      return (
        <ArrowSpan> <DownOutlined /> </ArrowSpan>
      )
    }

    return (
      <ArrowSpan> <RightOutlined /> </ArrowSpan>
    )
  }, [sidebarCollapsed, withArrow, isExpanded]);

  const showMenuText = useCallback(() => {
    if (sidebarCollapsed) {
      return isHoverActive ? '16px' : '0px'
    };
    return '16px';
  }, [sidebarCollapsed,isHoverActive])

  return (
    <MenuButtonDiv onClick={onMenuClick} className={isActive && 'active'} activeCSS={activeCSS()} disabled={isDisabled}>
      <IconSpan margin={sidebarCollapsed ? "0 0 0 0" : "0 12px 0 0"}>
        {icon || (!sidebarCollapsed && 'o')}
      </IconSpan>
      {<MenuButtonText transition={sidebarCollapsed ? 'none' : 'all 0.15s linear'} visibility={showMenuText()}>{label || '-'}</MenuButtonText>}
      {ShowArrow()}
    </MenuButtonDiv>
  )
}

export default memo(MenuButton);
