import React, { useCallback, useState, Suspense } from "react";
import styled from "styled-components";

import QuibbleMenu from 'components/ReusableComponents/Menu/Menu';
import IntelCompSetsLookup from "./IntelCompSetsLookup";
import PropertyGroups from "./PropertyGroups";

const ParametersContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const menuItems = [
  { label: 'Intel Comp Sets Lookup', key: 'intel_comp_sets' },
  { label: 'Property Groups', key: 'property_groups' },
  // { label: 'Settings Tab', key: 'settings2' },
  // { label: 'Settings Tab', key: 'settings3' },
];

const Parameters = () => {
  const [menuContent, setMenuContent] = useState('intel_comp_sets');

  const onMenuChange = useCallback((value) => {
    setMenuContent(value);
  }, []);

  const showMenuContent = useCallback(() => {
    if (menuContent === 'intel_comp_sets') {
      return (
        <Suspense fallback={ null }>
          <IntelCompSetsLookup />
        </Suspense>
      );
    }

    if (menuContent === 'property_groups') {
      return (
        <Suspense fallback={ null }>
          <PropertyGroups />
        </Suspense>
      );
    }
    return <></>;
  }, [menuContent])

  return (
    <ParametersContainer>
      <QuibbleMenu menuItems={menuItems} onMenuChange={onMenuChange} />
        {showMenuContent()}
    </ParametersContainer>
  )
}

export default Parameters;
