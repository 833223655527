import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';


const LodgifyDiv = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LodgifyButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;


const Lodgify = ({onSubmitForm }) => {

  const [lodgifyToken, setLodgifyToken] = useState('');
  
  const onFormChange = (_, {value}) => {
    setLodgifyToken(value);
  };

  const onTokenSubmit = () => {
    const values = {
      publicKey: lodgifyToken,
    }
    onSubmitForm(values)
  };


  return (
    <LodgifyDiv>
      <FormItemInput formKey="agenciesId" value={lodgifyToken}  label="Public API Key" onChangeFunc={onFormChange} />
      <LodgifyButton disabled={!lodgifyToken.length} onClick={onTokenSubmit}> Connect </LodgifyButton>
    </LodgifyDiv>
  )
}

export default Lodgify;