import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import userSagas from "./Users";
import marketsSagas from "./Markets";
import propertySagas from "./Property";
import reservationSagas from "./Reservation";
import businessTools from "./businessTools";
import ChargeBee from "./chargeBee"
import Subscription from "./Subscription";
import PortfolioAnalytics from "./PortfolioAnalytics";
import Events from './Events';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    marketsSagas(),
    propertySagas(),
    reservationSagas(),
    businessTools(),
    ChargeBee(),
    Subscription(),
    PortfolioAnalytics(),
    Events()
  ]);
}
