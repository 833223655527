import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import { DELETE_EVENT_DETAILS, GET_EVENTS_TABLE, GET_EVENTS_TABLE_SUCCESS, GET_EVENT_DETAILS, GET_EVENT_DETAILS_SUCCESS , UPDATE_EVENT_DETAILS } from "../../constants/ActionTypes"
import { events } from "../../services/events"
import { showUserErrorMessages, updateEventDetailsMessage } from "../actions";


function* getEventsTable({payload}){

  const params = payload?.params ?? {};
  
  try {
    const eventsTable = yield call(events.getEventsTable, { params: params });
    yield put({ type: GET_EVENTS_TABLE_SUCCESS, payload: eventsTable });
  } catch (err) {
    yield put({ type: GET_EVENTS_TABLE_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving events table.',
      })
    );
  }
};

export function* getEventsTableWatcher() {
  yield takeLatest(GET_EVENTS_TABLE, getEventsTable);
};

function* getEventDetails({payload}){
  try {
    const eventDetails= yield call(events.getEventDetails, { ...payload });
    yield put({ type: GET_EVENT_DETAILS_SUCCESS, payload: eventDetails });
  } catch (err) {
    yield put({ type: GET_EVENT_DETAILS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving event details.',
      })
    );
  }
};

export function* getEventDetailsWatcher() {
  yield takeLatest(GET_EVENT_DETAILS, getEventDetails);
};

const updateEventDetails = async (data) =>
  await events.update(data)
    .then(result => result)
    .catch(error => error);

function* updateEvent({payload}) {
  try {
    const eventStatus = yield call(updateEventDetails, payload);
    yield put(updateEventDetailsMessage(eventStatus.data.message));
  } catch (error) {
    yield put(updateEventDetailsMessage(error));
  }
}

export function* toUpdateEvent() {
  yield takeEvery(UPDATE_EVENT_DETAILS, updateEvent);
}

// function* deleteEventDetails({payload}){

//   const params = payload &&  {...payload?.params} ;

//   try {
//     const eventId = yield call(events.deleteEventDetails, { params: params });
//     yield put({ type: DELETE_EVENT_DETAILS, payload: eventId });
//   } catch (err) {
//     yield put({ type: DELETE_EVENT_DETAILS, payload: {} });
//     yield put(
//       showUserErrorMessages({
//         status: false,
//         message: 'Error deleting event',
//       })
//     );
//   }
// };

function* deleteEventDetails({ payload }) {
  try {
    const { eventId, params } = payload;
    yield call(events.deleteEventDetails, { eventId, params });
    yield put({ type: DELETE_EVENT_DETAILS, payload: eventId });
  } catch (err) {
    yield put({ type: DELETE_EVENT_DETAILS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error deleting event',
      })
    );
  }
}

export function* deleteEventDetailsWatcher() {
  yield takeLatest(DELETE_EVENT_DETAILS, deleteEventDetails);
};

export default function* rootSaga() {
  yield all([
    fork(getEventsTableWatcher),
    fork(getEventDetailsWatcher),
    fork(toUpdateEvent),
    fork(deleteEventDetailsWatcher)
  ]);
}
