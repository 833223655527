import React, {useState, memo, useEffect, Fragment} from 'react';
import styled, {css} from 'styled-components';
import ButtonWithFunction from '../Button/ButtonWithFunction';

const MenuBody = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  background: transparent;
  margin: ${({margin}) => margin || '0 0 8px 0'};
  justify-content: ${({alignment}) => alignment};
`;

const MenuButton = css`
  background: transparent;
  width: ${({ menuWidth, shouldExpand }) => (shouldExpand ? '100%' : menuWidth || 'fit-content')};
  height: 50px;
  max-height: 50px;
  border: none;
  outline: none;
  padding: 0 32px; 
  cursor: pointer;
  font-size: ${({theme}) => theme.s4};
  color: ${({theme}) => theme.bodyFg};
  font-family: Commissioner;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  transition: all 0.2s linear;
  box-shadow: none;

  &:hover, &:focus, &.active {
    background: #C2ECF3;
    color: black;
    box-shadow: 0px 4px 18px 0px rgba(145, 165, 169, 0.20);
  }
`;

const Iconspan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  font-size: ${({theme}) => theme.s5};
`;

const LabelSpan = styled.span`
  margin-left: 2px;
`;


const QuibbleMenu = ({
  menuItems = [],
  onMenuChange = () => {},
  menuAlignment = "left",
  menuWidth,
  margin,
  shouldExpandButtons = false, 
}) => {  const [activeMenu, setActiveMenu] = useState(0);

  useEffect(() => {
    if (menuItems.length) {
      setActiveMenu(menuItems[0].key || 0)
    }
  }, [menuItems])

  const onMenuClick = (value) => {
    setActiveMenu(value);
    onMenuChange(value);
  }

  return (
    <MenuBody alignment={menuAlignment === "left" ? "left" : "center"} margin={margin}>
      {menuItems.map((menuItem, index) => (
        <ButtonWithFunction
          ButtonStyle={MenuButton}
          menuWidth={menuWidth}
          shouldExpand={shouldExpandButtons}
          key={`${menuItem.key || index}`}
          label={<Fragment><Iconspan> {menuItem?.icon} </Iconspan> <LabelSpan> {menuItem?.label} </LabelSpan></Fragment> }
          className={(menuItem.key || index) === activeMenu && 'active' }
          onClickFunc={onMenuClick}
          value={menuItem.key || index}
        />
      ))}
    </MenuBody>
  )
}

export default memo(QuibbleMenu);
