import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { BodyText } from '../../../../components/ReusableComponents/Text/Text';

const FilterGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;

  .ant-input-number {
    margin: 0;
  }
`;

const BedroomButtonContainer = styled.div`
  display: flex;

  .ant-btn {
    display: flex;
    height: 30px;
    width: 50px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0;
  }

  .ant-btn:not(:first-child) {
    width: 30px;
  }

  .ant-btn:not(:last-child) {
    margin-right: 5px;
  }
`;

const BedroomFilter = ({ selectedBedrooms, onBedroomChange, label }) => {
  const handleBedroomChange = useCallback(
    (bedroom) => {
      let updatedBedrooms;

      if (bedroom === 0) {
        updatedBedrooms = [0];
      } else {
        if (selectedBedrooms.includes(bedroom)) {
          updatedBedrooms = selectedBedrooms.filter((clickedBedroom) => clickedBedroom !== bedroom);
        } else {
          updatedBedrooms = [...selectedBedrooms, bedroom];
        }

        if (updatedBedrooms.length === 0 || (updatedBedrooms.length === 1 && updatedBedrooms[0] === 0)) {
          updatedBedrooms = [0];
        } else {
          updatedBedrooms = updatedBedrooms.filter((bed) => bed !== 0);
        }
      }

      onBedroomChange(updatedBedrooms);
    },
    [selectedBedrooms, onBedroomChange]
  );

  return (
    <FilterGroup>
      <BodyText>{label}</BodyText>
      <BedroomButtonContainer>
        <Button
          type={selectedBedrooms.includes(0) ? 'primary' : 'default'}
          onClick={() => handleBedroomChange(0)}
        >
          Any
        </Button>
        <Button
          type={selectedBedrooms.includes(1) ? 'primary' : 'default'}
          onClick={() => handleBedroomChange(1)}
        >
          1
        </Button>
        <Button
          type={selectedBedrooms.includes(2) ? 'primary' : 'default'}
          onClick={() => handleBedroomChange(2)}
        >
          2
        </Button>
        <Button
          type={selectedBedrooms.includes(3) ? 'primary' : 'default'}
          onClick={() => handleBedroomChange(3)}
        >
          3
        </Button>
        <Button
          type={selectedBedrooms.includes(4) ? 'primary' : 'default'}
          onClick={() => handleBedroomChange(4)}
        >
          4
        </Button>
        <Button
          type={selectedBedrooms.includes(5) ? 'primary' : 'default'}
          onClick={() => handleBedroomChange(5)}
        >
          5+
        </Button>
      </BedroomButtonContainer>
    </FilterGroup>
  );
};

export default BedroomFilter;
