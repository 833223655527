import React, {useCallback} from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';

const DatePickerStyled = styled(DatePicker)`
  ${({DatepickerStyle}) => DatepickerStyle};
`;

const DatePickerWithFunction = ({DatepickerStyle, onChangeFunc=()=>{}, datePickerFuncValue, ...otherProps}) => {

  const onDatePickerChange = useCallback((_, dateString) => {
    onChangeFunc(dateString, datePickerFuncValue);
  }, [onChangeFunc, datePickerFuncValue])

  return (
    <DatePickerStyled 
      DatepickerStyle={DatepickerStyle}
      onChange={onDatePickerChange} 
      {...otherProps}
    />
  )
}

export default DatePickerWithFunction;
