import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import {markets} from "../../services/markets";
import {
  USER_MARKETS_LIST,
  GET_MARKETS_DETAIL,
  UPDATE_MARKETS_STATUS,
  USER_MARKETS_YEARLY_DATA,
} from "constants/ActionTypes";
import {
  setMarketsDetail,
  updateMarketsList,
  showMarketsMessage,
  setDataAnalysis,
  updateMarketMetric,
} from "../actions/Markets";
import { GET_CALENDAR_METRIC, GET_MARKET_ANALYTICS, GET_MARKET_ANALYTICS_SUCCESS, GET_MARKET_DETAILS, GET_MARKET_DETAILS_SUCCESS, GET_MARKET_PRICING, GET_MARKET_PRICING_SUCCESS, GET_MARKET_PROPERTY_TABLE, GET_MARKET_PROPERTY_TABLE_SUCCESS, GET_PORTFOLIO_PRICING, GET_PORTFOLIO_PRICING_SUCCESS, GET_MARKET_PRICING_RULES, GET_MARKET_PRICING_RULES_SUCCESS, GET_PORTFOLIO_PRICING_RULES, GET_PORTFOLIO_PRICING_RULES_SUCCESS, GET_LISTINGS, GET_LISTINGS_SUCCESS } from "../../constants/ActionTypes";
import { showUserErrorMessages } from "../actions";

/** Request to get markets listing */
const getUserMarketsRequest = async (data) =>
  await markets.list(data)
    .then(result => result)
    .catch(error => error);

function* getUserMarketsData({payload}) {
  try {
    let marketsList = yield call(getUserMarketsRequest, payload);
    yield put(updateMarketsList(marketsList.data));
  } catch (error) {
    yield put(showMarketsMessage(error));
  }
}

export function* toUserMarketsList() {
  yield takeEvery(USER_MARKETS_LIST, getUserMarketsData);
}
/** End :: Request to get markets listing */

/** Request to get markets listing */
const getCalendarMetricsRequest = async (data) =>
  await markets.calendarMetric(data)
    .then(result => result)
    .catch(error => error);

function* getCalendarMetricsData({payload}) {
  try {
    let marketsList = yield call(getCalendarMetricsRequest, payload);
    yield put(updateMarketMetric(marketsList.data));
  } catch (error) {
    yield put(showMarketsMessage(error));
  }
}

export function* toCalendarMetricsList() {
  yield takeEvery(GET_CALENDAR_METRIC, getCalendarMetricsData);
}
/** End :: Request to get markets listing */


/** Request to get yearly data analysis */
const getUserMarketsAnalysisRequest = async (data) =>
  await markets.dataAnalysis(data)
    .then(result => result)
    .catch(error => error);

function* getUserMarketsAnalysisData({payload}) {
  try {
    let marketsData = yield call(getUserMarketsAnalysisRequest, payload);
    yield put(setDataAnalysis(marketsData.data));
  } catch (error) {
    yield put(showMarketsMessage(error));
  }
}

export function* toUserMarketsAnalysis() {
  yield takeEvery(USER_MARKETS_YEARLY_DATA, getUserMarketsAnalysisData);
}
/** End :: Request to get yearly data analysis */




/** Start :: Request to update markets status */
const updateMarketsStatusRequest = async (data) =>
  await markets.update(data)
    .then(result => result)
    .catch(error => error);

function* updateMarketsStatus({payload}) {
  try {
    const marketsStatus = yield call(updateMarketsStatusRequest, payload);
    yield put(showMarketsMessage(marketsStatus.data.message));
  } catch (error) {
    yield put(showMarketsMessage(error));
  }
}

export function* toUpdateMarketsStatus() {
  yield takeEvery(UPDATE_MARKETS_STATUS, updateMarketsStatus);
}
/** End :: Request to update markets status */


/** Start :: Request to get markets detail */
const getMarketsDetailRequest = async (data) =>
  await markets.detail(data)
    .then(result => result)
    .catch(error => error);

function* getMarketsDetail({payload}) {
  try {
    const marketsDetail = yield call(getMarketsDetailRequest, payload);
    yield put(setMarketsDetail(marketsDetail.data.result ?? {}));
  } catch (error) {
    yield put(showMarketsMessage(error));
  }
}

export function* toGetMarketsDetail() {
  yield takeEvery(GET_MARKETS_DETAIL, getMarketsDetail);
}

function* getMarketAnalytics({payload}){
  try {
    const marketAnalytics = yield call(markets.getMarketAnalytics, { ...payload });
    yield put({ type: GET_MARKET_ANALYTICS_SUCCESS, payload: marketAnalytics });
  } catch (err) {
    yield put({ type: GET_MARKET_ANALYTICS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving market analytics.',
      })
    );
  }
};

export function* getMarketAnalyticsWatcher() {
  yield takeLatest(GET_MARKET_ANALYTICS, getMarketAnalytics);
};

function* getMarketPropertyTable({payload}){

  const params = payload ?  {...payload?.params,  includeQuadrants: true, view: 'property'} : {includeQuadrants: true, view: 'property'};

  try {
    const marketAnalytics = yield call(markets.getMarketAnalytics, { params: params });
    yield put({ type: GET_MARKET_PROPERTY_TABLE_SUCCESS, payload: marketAnalytics });
  } catch (err) {
    yield put({ type: GET_MARKET_PROPERTY_TABLE_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving market analytics table.',
      })
    );
  }
};

export function* getMarketPropertyTableWatcher() {
  yield takeLatest(GET_MARKET_PROPERTY_TABLE, getMarketPropertyTable);
};

function* getMarketPricing({payload}){
  try {
    const marketPricing = yield call(markets.marketPricing, { params:{...payload} });
    yield put({ type: GET_MARKET_PRICING_SUCCESS, payload: marketPricing });
  } catch (err) {
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving market pricing.',
      })
    );
  }
}

export function* getMarketPricingWatcher() {
  yield takeLatest(GET_MARKET_PRICING, getMarketPricing);
}

function* getPortfolioPricing({payload}){
  try {
    const portfolioPricing = yield call(markets.marketPricing, { params:{...payload} });
    yield put({ type: GET_PORTFOLIO_PRICING_SUCCESS, payload: portfolioPricing });
  } catch (err) {
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving portfolio pricing.',
      })
    );
  }
}

export function* getPortfolioPricingWatcher() {
  yield takeLatest(GET_PORTFOLIO_PRICING, getPortfolioPricing);
}

function* getMarketDetails({payload}){
  try {
    const marketAnalytics = yield call(markets.getMarketAnalytics, { ...payload });
    yield put({ type: GET_MARKET_DETAILS_SUCCESS, payload: marketAnalytics });
  } catch (err) {
    yield put({ type: GET_MARKET_DETAILS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving market analytics.',
      })
    );
  }
};

export function* getMarketDetailsWatcher() {
  yield takeLatest(GET_MARKET_DETAILS, getMarketDetails);
};

/** End :: Request to get markets detail */


function* getMarketPricingRules({payload}){
  try {
    const marketPricingRules = yield call(markets.getMarketPricingRules, { ...payload });
    yield put({ type: GET_MARKET_PRICING_RULES_SUCCESS, payload: marketPricingRules });
  } catch (err) {
    yield put({ type: GET_MARKET_PRICING_RULES_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving pricing rules.',
      })
    );
  }
}

export function* getMarketPricingRulesWatcher() {
  yield takeLatest(GET_MARKET_PRICING_RULES, getMarketPricingRules);
}


function* getPortfolioPricingRules({payload}){
  try {
    const portfolioPricingRules = yield call(markets.getPortfolioPricingRules, { ...payload });
    yield put({ type: GET_PORTFOLIO_PRICING_RULES_SUCCESS, payload: portfolioPricingRules });
  } catch (err) {
    yield put({ type: GET_PORTFOLIO_PRICING_RULES_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving pricing rules.',
      })
    );
  }
}

export function* getPorfolioPricingRulesWatcher() {
  yield takeLatest(GET_PORTFOLIO_PRICING_RULES, getPortfolioPricingRules);
}

function* getListings({payload}){
  try {
    const listings = yield call(markets.getListings, { ...payload });
    yield put({ type: GET_LISTINGS_SUCCESS, payload: listings });
  } catch (err) {
    yield put({ type: GET_LISTINGS_SUCCESS, payload: {} });
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving listings.',
      })
    );
  }
}

export function* getListingsWatcher() {
  yield takeLatest(GET_LISTINGS, getListings);
}



/** Export all */
export default function* rootSaga() {
  yield all([
    fork(toUpdateMarketsStatus),
    fork(toUserMarketsList),
    fork(toUserMarketsAnalysis),
    fork(toGetMarketsDetail),
    fork(toCalendarMetricsList),
    fork(getMarketAnalyticsWatcher),
    fork(getMarketPropertyTableWatcher),
    fork(getMarketPricingWatcher),
    fork(getMarketDetailsWatcher),
    fork(getPortfolioPricingWatcher),
    fork(getMarketPricingRulesWatcher),
    fork(getPorfolioPricingRulesWatcher),
    fork(getListingsWatcher),
  ]);
}
