import React, {memo, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import { currencyFormatter } from '../../../../../../../util/helper';


const ReservationBody = styled.div`
  width: ${({reservationWidth}) => reservationWidth};
  height: ${({height}) => height};
  background: rgba(27, 179, 205, 0.70);
  position: absolute;
  top: 0px;
  bottom: 0px;
  // right: 0px;
  left: ${({left}) => left};
  margin: auto;
  // border-radius: 100px;
  border-radius: ${({radius}) => radius};
  z-index: 2;
  font-weight: 300;
  font-size: ${({theme}) => theme.s3};
  color: ${({theme}) => theme.white};
  display: ${({showReservation}) => showReservation ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ReservationName = styled.span`
  font-weight: 500;
`;



const Reservations = ({showReservation=false, reservation, isMarket=false}) => {
  const {guest, total, length, isSunConti, isSatConti, adr, isSundayCheckout} = reservation;

  // const isSat = index === 6 && length === 1;
  
  //next 2 variables here tells what is the width of the blue reservation bar and its border radius or roundness

  const reservationWidth = useMemo(() => {

    if (isSundayCheckout) {
      return `33%`;
    };

    if (isSatConti) {
      return `${(100 * length) - 1}%`;
    };

    // if (isSat) {
    //   return '98%';
    // };

    if (length === 1) {
      return '133%';
    };

    return `${(100 * (length)) + 33}%`;

  }, [length, isSatConti, isSundayCheckout])

  const borderRadius = useMemo(() => {

    if (isSundayCheckout) {
      return '0 100px 100px 0';
    };

    if (isSunConti && isSatConti) {
      return '0px';
    };

    if (isSunConti) {
      return '0 100px 100px 0';
    };

    if (isSatConti) {
      return '100px 0 0 100px';
    };

    return '100px';
  }, [isSunConti, isSatConti, isSundayCheckout]);

  const showTotalAndAdr = useCallback(() => {
    if (isSundayCheckout) return null;

    if (total && adr) {
      return `Total Rent: ${currencyFormatter(total)} / ADR: ${currencyFormatter(adr)}`
    };

    if (total && !adr) {
       return `Total Rent: ${currencyFormatter(total)}`
    }

    if (!total && adr) {
        return `ADR: ${currencyFormatter(total)}`
    }

  }, [isSundayCheckout, total, adr])

    return (
      <ReservationBody left={"4px"} height={isMarket ? '44px' : '66px'} radius={borderRadius} showReservation={showReservation} reservationWidth={reservationWidth}>
        <ReservationName>{guest || ''}</ReservationName>
        {showTotalAndAdr()}
      </ReservationBody>
    )
}

export default memo(Reservations);