import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SHOW_ERROR_MESSAGES,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  RESEND_EMAIL_SUCCESS,
  SELECTED_PMS_SUCCESS,
  API_KEY_PMS_SUCCESS,
  SIGNUP_GOOGLE_USER_SUCCESS,
} from "../../constants/ActionTypes";

/*** Set initial state */
const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  errors : {},
  signupWithGoogle: '',
  loginType : {},
  alertMessageType : '',
  authUser: localStorage.getItem('user_id'),
};


const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        showMessage: false,
        authUser: action.payload,
      }
    }

    case RESEND_EMAIL_SUCCESS: {
      return {
        ...state,
        showMessage: true,
        loader: false,
        authUser: action.payload
      }
    }

    case SELECTED_PMS_SUCCESS: {
      return {
        ...state,
        showMessage: false,
        loader: false,
        authUser: action.payload,
        loginType: {},
        signupWithGoogle:null,
        alertMessageType : true
      }
    }

    

    case API_KEY_PMS_SUCCESS: {
      return {
        ...state,
        showMessage: false,
        loader: false,
        authUser: null,
        loginType: {},
        signupWithGoogle:null
      }
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload.message,
        showMessage: true,
        loader: false,
        alertMessageType : action.payload.status
      }
    }

    case SHOW_ERROR_MESSAGES: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
        showMessage: false,
        errors : action.payload
      }
    }

    case SIGNUP_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
        showMessage: false,
        signupWithGoogle: action.payload,
      }
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
        alertMessageType : false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        loginType: action.payload,
        authUser : null
      }
    }
   
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}

export default AuthReducer;
