import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import ButtonWithFunction from "../../../../components/ReusableComponents/Button/ButtonWithFunction";
import { useDispatch } from "react-redux";
import { getPropertyDetails, getPropertyOverviewMetrics, getPropertyTableMetrics } from "../../../../appRedux/actions";
import { useData } from "../../../../util/DataStore/DataContext";
import SkLoaderOver from "../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver";

const PropertyHierarDiv = styled.div`
  width: 100%;
  min-width: 20px;
  max-width: 1776px;
  margin: 10px 0 0 0;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 0 0 0 8px;
  position: relative;
`;

const PropertyHierarchyText = css`
  width: fit-content;
  height: fit-content;
  background: none;
  font-family: Commissioner;
  font-weight: 400;
  outline: none;
  border: none;
  color: #283334;
  border-radius: 0px;
  box-shadow: none;

  &: hover {
    background: none;
    color: #1BB3CD;
  }

  &.active {
    background: none;
    color: #1BB3CD;
    border-bottom: 1px solid #1BB3CD;
  }


`;

const PropertyHierarchy = ({propertyDetails, monthYear, isFetchingPropertyDetails, childIndex, propertyId}) => {
  const [sequence, setSequence] = useState([]);
  const dispatch = useDispatch();
  const {state} = useData();

  useEffect(() => {
    const {children, parent, siblings} = propertyDetails;
    
    const siblingsArray = siblings?.length ? siblings?.map((child) => ({id: child?.id, name: child?.name})) : [];

    const siblingsSequence = childIndex === 0 ? [{id: propertyDetails?.id, name: propertyDetails?.name}, ...siblingsArray] : siblingsArray.toSpliced(childIndex, 0, {id: propertyDetails?.id, name: propertyDetails?.name});

    if (!sequence.some((data) => data?.id === propertyDetails?.id) ) {
      if (children?.length) {
        setSequence([ {id: propertyDetails.id, name: propertyDetails?.name }, ...children.map((child) => ({id: child?.id, name: child?.name}))])
        return;
      };
      
      if (parent) {
        setSequence([ {id: parent.id, name: parent?.name },  ...siblingsSequence])
        return;
      }

      return setSequence([{id: propertyDetails.id, name: propertyDetails?.name }]);
    };

  }, [propertyDetails, childIndex, sequence])

  const onItemClick = (id) => {
    const month = monthYear.getMonth() + 1;
    const year =  monthYear.getFullYear();
    dispatch(getPropertyDetails({propertyId: id, params: {month, year}}));
    dispatch(getPropertyOverviewMetrics({propertyId: id, params: {
      month, year, viewType: state?.PROPERTY_DATA?.viewType || 'rmView'
    }}));
    dispatch(getPropertyTableMetrics({propertyId: id, params: {month, year}}));
  };

  return (
    <PropertyHierarDiv>
      {sequence.map((data) => <ButtonWithFunction disabled={propertyDetails?.id === data?.id && !isFetchingPropertyDetails} className={(propertyDetails?.id === data?.id && !isFetchingPropertyDetails) && 'active'} onClickFunc={onItemClick} value={data?.id} key={data?.id} ButtonStyle={PropertyHierarchyText} label={data?.name}/>)}
      <SkLoaderOver isLoading={isFetchingPropertyDetails && !sequence.some((data) => data?.id === propertyId)} type="full"/>
    </PropertyHierarDiv>
  )
}

export default PropertyHierarchy;