import { Tooltip } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';
import { currencyFormatter } from '../../../../../../util/helper';


const TooltipDiv = styled.div`
  width: fit-content;
  height: fit-content;
  box-shadow: none;
  display: flex;
  flex-direction: column;
`;


const LastYearPrice = ({value}) => {
  return (
    <Fragment>
      {value?.priceLastYear ? <Tooltip title={<TooltipDiv>
        <BodyText>
          Comparison Date: &nbsp; <b> {value?.dateShiftedLastYear?.replaceAll('-', '/') || ''}</b>
        </BodyText>
        {value?.leadTimeLastYear ? <BodyText>
          Lead time: &nbsp; <b> {value?.leadTimeLastYear} Days</b>
        </BodyText> : null}
      </TooltipDiv>}>
        {currencyFormatter(value?.priceLastYear)}
      </Tooltip> : '-'}
    </Fragment>
  )
}

export default LastYearPrice;