import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
const reportBaseURL = process.env.REACT_APP_API_REPORT_URI;

const APIUtil = axios.create({ baseURL, withCredentials: false});
const REPORT_API_UTIL = axios.create({ baseURL: reportBaseURL, withCredentials: false});

APIUtil.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers = { "Authorization": `Bearer ${ token }` };

  return config;
}, function (error) {
  return Promise.reject(error);
});

REPORT_API_UTIL.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers = { "Authorization": `Bearer ${ token }` };

  return config;
}, function (error) {
  return Promise.reject(error);
});


export {
  REPORT_API_UTIL
}
export default APIUtil;
