import { createGlobalStyle } from 'styled-components';
import './fonts.css';

//Apply global style to all components. 
//Also apply overrides here to apply theming to antd components that are unbound/not part to a specific component.
const GlobalStyle = createGlobalStyle`

  /* OVERALL/BODY GLOBAL STYLES */ 

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: white;
    font-family: Quicksand, Commissioner,Montserrat, Roboto, Sans-Serif;
    font-weight: 500;
  }

  /* OVERRIDES */


  ${({theme}) => `

    /* SIDEBAR/DRAWER OVERRIDES */
  
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none;
    }

    .ant-menu-title-content {
      transition: none;
    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      background: ${theme.quibbleBlack};
    }

    .ant-menu-item.ant-menu-item-disabled {
      color: ${theme.disabledGrey} !important;
  
      &:hover, &:focus {
        color: ${theme.disabledGrey} !important;
      }
    }

    .ant-menu-item.ant-menu-item-selected  {
      background: transparent !important;
      &:hover, &:focus {
        background: ${theme.quibbleBlue} !important;
        color: ${theme.black} !important;
      }
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected { 
      &:hover, &:focus {
        background: ${theme.quibbleBlue}!important;
        color: ${theme.black} !important;
      }
    }

    .ant-message {
      z-index: 2005;
    }

    /* TABLES/PICKERS/SELECTS OVERRIDES */
    

    input {
      font-weight: 600;
    }
    

    .ant-picker-input {
      min-height: 100%;
    }

    .ant-picker-content th,
    .ant-picker-content td {
      font-weight: 600 !important;
    }

    .ant-picker-header-view {
      font-weight: 600;
    }

    .ant-picker-panel-container {
      background: ${theme.bodyBg};
    }

    .ant-picker-header button {
      color: ${theme.menuFg};

      &:hover {
        color: ${theme.quibbleBlue} !important;
      }
    }

    .ant-picker-cell-in-view  {
      color: ${theme.menuFg};

      &:hover {
        .ant-picker-cell-inner {
          background: ${theme.quibbleBlue} !important;
        }
      }
    }

    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background: ${theme.quibbleBlue} !important;
        color: ${theme.black};
      }
    }

    .ant-picker-input > input {
      font-family: Commissioner;
      font-size: ${theme.s4};
      min-height: 100%;
      font-weight: 400;
    }
  
  
    .ant-picker-suffix {
      color: ${theme.quibbleFontGrey};
    }

    .ant-radio-checked .ant-radio-inner {
      border-color:${theme.quibbleBlue};
    }

    .ant-radio-inner::after {
      background-color: ${theme.quibbleBlue};
    }

    .ant-select-dropdown {
      background: ${theme.bodyBg};
      font-family: Commissioner;
    }

    .rc-virtual-list-holder {
      background: ${theme.white};
      font-family: Commissioner;

      &::-webkit-scrollbar {
        width:  8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: ${theme.white};
        border-radius: 4px; 
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.dirtyGrey};
        cursor: pointer;
        border-radius: 4px; 

        border-right: 2px ${theme.white} solid;
        background-clip: padding-box;
      }

      &::-webkit-scrollbar-thumb:hover {
        background:  ${theme.quibbleBlue};
      }
    }

    .ant-select-tree {
      background: ${theme.bodyBg};
      color: ${theme.menuFg};
    }

    .ant-select-tree .ant-select-tree-node-content-wrapper {
      font-weight: 500;
      &:hover {
        background: ${theme.quibbleBlue};
        color: ${theme.menuFg};
      }
    }

    .ant-select-multiple .ant-select-selection-item {
      background: ${theme.buttonBg};
      color: ${theme.buttonFg};

      .anticon.anticon-close {
        color: ${theme.buttonFg};
      }
    }

    .ant-select {
      color: ${theme.quibbleBlack};
      font-weight: 600;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      border-radius: 4px;
      min-height: 100% 
    }

    .ant-select-item {
      color: ${theme.bodyFg};
      font-weight: 400;
    }

    .ant-select-selection-item {
      font-weight: 400;
    }

    .ant-select-item-option-disabled {
      color: ${theme.disabledGrey};
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled),  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${theme.quibbleBlue};
      color: ${theme.bodyFg};
    }
    
    
    .ant-select-selection-placeholder {
      color: ${theme.quibblePlaceholderGrey} !important;
      font-weight: 500;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-weight: 400;
      line-height: none;
      display: flex;
      flex-diretion: row;
      align-items: center;
    }

    .ant-checkbox-wrapper {
      color: inherit;
    }

    .ant-checkbox-disabled + span  {
      color: ${theme.disabledGrey};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${theme.quibbleBlue};
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: ${theme.disabledGrey};
    }

    .ant-checkbox-inner {
      background-color: ${theme.bodyBg};
      border: 1px solid ${theme.bodyFg};    
    }

    /* INPUT OVERRIDES */
    .ant-input::placeholder {
      color: ${theme.quibblePlaceholderGrey} !important;
      font-weight: 500;
    }

    a, .gx-link {
      color: ${!theme.isDefault ? theme.quibbleBlue: '##038fde'}
    }

    /*SWITCH, SLIDERS OVERRIDES*/

    .ant-switch-handle::before {
      // background-color: ${theme.buttonFg};
    }
  
    .ant-switch {
      // background-color: ${theme.buttonBg};
    }
  
    .ant-switch-checked {
      background-color: ${theme.quibbleBlue};
    }

    .ant-switch-inner {
      color: ${theme.buttonFg};
      font-size: ${theme.s3};
      font-weight: 600;
    }

    /*TOOLTIP OVERRIDES*/

    .ant-tooltip {
      max-width: 1000px;
    }

    .ant-tooltip-inner {
      background-color: ${theme.white};
      color: ${theme.activeBlue};
    }

    .ant-tooltip-arrow-content {
      background-color: ${theme.white};
    }

    .ant-tooltip-arrow-content::before {
      background: ${theme.white};
    }

    .ant-slider {
      &:hover {
        .ant-slider-rail {
          background-color: ${theme.dirtyGrey};
        }
      }
    }

    .ant-slider-handle {
      border: solid 2px ${theme.activeBlue};
    }

    .ant-slider-rail {
      background-color: ${theme.dirtyGrey};
    }

    .ant-slider-track {
      background-color:  ${theme.activeBlue};
    }

    .dropdown-overlay--with-button {
      ul > li:last-child {
        background-color: white;

        &:hover {
          background-color: white;
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: #697273;
        &:after {
          border-color: #1BB3CD;
        }
      }
    }

  `}

`;
 
export default GlobalStyle;