import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { BodyCSS } from '../../../../components/ReusableComponents/Card-Body/BodyCSS';
import ButtonWithFunction from '../../../../components/ReusableComponents/Button/ButtonWithFunction';

import star from 'assets/images/market/quadrants/star.svg';
import starWhite from 'assets/images/market/quadrants/star-white.svg';

import gift from 'assets/images/market/quadrants/gift.svg';
import giftWhite from 'assets/images/market/quadrants/gift-white.svg';

import heart from 'assets/images/market/quadrants/heart.svg';
import heartWhite from 'assets/images/market/quadrants/heart-white.svg';

import rain from 'assets/images/market/quadrants/rain.svg';
import rainWhite from 'assets/images/market/quadrants/rain-white.svg';


const PropertyTableFilterDiv = styled.div`
  width: 100%;
  min-width: ${({width}) => width};
  ${BodyCSS}
  height: 70px;
  padding: 16px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${({theme}) => theme.quibbleHoverGrey};
  transition: all 0.15s linear;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

const ButtonIcon = styled.span`
  width: 21px;
  height: 21px;

  object-fit: fit;
  background-image: url(${star});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
  border: ${({border}) => border};
`;

const ButtonIconUnderPriced = styled(ButtonIcon)`
  background-image: url(${gift});
`;

const ButtonIconOverpriced = styled(ButtonIcon)` 
  background-image: url(${heart});
`;

const ButtonProductIssue = styled(ButtonIcon)`
  background-image: url(${rain});
`;

const WhiteTransform = css`
  ${ButtonIcon}{
    background-image: url(${starWhite});
  }
  ${ButtonIconUnderPriced} {
    background-image: url(${giftWhite});
  }
  ${ButtonIconOverpriced} {
    background-image: url(${heartWhite});
  }
  ${ButtonProductIssue} {
    background-image: url(${rainWhite});
  }
`;

const PropertyTableFilterButton = css`
  width: fit-content;
  height: 36px;
  padding: 8px;
  outline: none;
  border-radis: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: ${({theme}) => theme.s4};
  font-weight: 300;
  background: ${({theme}) => theme.white};
  color: ${({color}) => color};
  border: ${({color}) => `1px solid ${color}`};
  box-shadow: none;

  &:hover, &.active {
    color: ${({theme}) => theme.white};
    background: ${({color}) => color};
    ${WhiteTransform}
  };

  &:disabled {
    color: ${({theme}) => theme.white};
    background: ${({theme}) => theme.disabledGrey};
    ${WhiteTransform};
    opacity: 0.3;
  };
  
`;


const PropertyTableFilter = ({starsLength, underpricedLength, overpricedLength, productIssuesLength, activeButton, onFilterClick, width="100%" }) => {
  const theme = useTheme();
  
  const onClick = (value) => {
    if(!onFilterClick) return;
    onFilterClick(value)
  };

  return (
    <PropertyTableFilterDiv width={width}>
      <ButtonWithFunction className={activeButton === 'stars' && 'active'} value="stars" onClickFunc={onClick} ButtonStyle={PropertyTableFilterButton} color={theme.quibbleBlue} label={<><ButtonIcon /> Stars {starsLength && `(${starsLength})`} </> } disabled={!starsLength}/>
      <ButtonWithFunction className={activeButton === 'underpriced' && 'active'} value="underpriced" ButtonStyle={PropertyTableFilterButton} color={theme.quibblePurple} onClickFunc={onClick} label={<><ButtonIconUnderPriced /> Underpriced {underpricedLength && `(${underpricedLength})`} </>} disabled={!underpricedLength}/>
      <ButtonWithFunction className={activeButton === 'overpriced' && 'active'} value="overpriced" ButtonStyle={PropertyTableFilterButton} color={theme.quibbleYellow} onClickFunc={onClick} label={<><ButtonIconOverpriced /> Overpriced {overpricedLength && `(${overpricedLength})`} </> } disabled={!overpricedLength}/>
      <ButtonWithFunction className={activeButton === 'productIssues' && 'active'} value="productIssues" ButtonStyle={PropertyTableFilterButton} color={theme.quibbleOrange} onClickFunc={onClick} label={<><ButtonProductIssue /> Product Issues {productIssuesLength && `(${productIssuesLength})`} </> } disabled={!productIssuesLength}/>
    </PropertyTableFilterDiv>
  )
}

export default PropertyTableFilter;