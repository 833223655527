import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';

const MHChartsHeaderContainer = styled.div`
  height: 50px;
  min-height: 50px;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #f6f6f6;
`;

const MHTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`;

const MHTitleContainner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const MMSwitchContainer = styled.div`
  justify-content: flex-end;
  flex: 1;
  display: flex;
  align-items: center;
`;

const MHChartsHeader = (props) => {
  const [activeListings, setActiveListings] = useState([]);

  useEffect(() => {
    setActiveListings(props.data);
  }, [props.data]);

  return (
    <MHChartsHeaderContainer>
      <MHTitleContainner>
        <MHTitle>{activeListings && `${activeListings} Active Rentals`} </MHTitle>
      </MHTitleContainner>
      <MMSwitchContainer>
        Hide my Portfolio<Switch size="small" style={{ marginLeft: '10px' }}/>
      </MMSwitchContainer>
    </MHChartsHeaderContainer>
  );
}

export default MHChartsHeader;