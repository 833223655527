import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { InputNumber, Button } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { debouncer } from '../../../../../../util/helper';

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  outline: 1px solid #E8E8E8;
  border-radius: 5px;
  background: #fff;
  height: 40px;

  .ant-btn {
    border: none;
    outline: none;
    margin: 0 2px; !important;
    width: 25px;
  }

  .ant-input-number {
    flex-grow: 1;
    border: none;
    outline: none;
    margin-right: 0;
    width: 20px;
  }

  .ant-input-number-input {
    text-align: center !important;
    padding: 0;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .anticon svg {
    width: 100%;
  }

  .ant-btn-icon-only > * {
    font-size: 15px;
  }
`;

const IncrementDecrementInput = ({ disabled, inputValue, onInputChange, allowEmpty, allowDecimal, allowNegative }) => {
  const [value, setValue] = useState(inputValue || 0);
  const [isFocused, setIsFocused] = useState(false);

  const handleIncrement = () => {
    onInputChange(value + 1);
  };

  const handleDecrement = () => {
    if (allowNegative || value > 0) {
      onInputChange(value - 1);
    }
  };

  const handleChange = (newValue) => {
    if (allowEmpty && newValue === null) {
      onInputChange(newValue);
      return;
    }

    if (!isNaN(newValue) && (allowNegative || newValue >= 0) && newValue !== null) {
      onInputChange(parseFloat(newValue.toFixed(2)));
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (value === null || value === '') {
      onInputChange(0);
    }
  };

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const debounceOnChange = debouncer(handleChange, 500);

  const displayValue = isFocused ? (value === 0 ? '' : value) : (value === 0 ? '-' : value);

  return (
    <InputContainer style={{ backgroundColor: disabled ? '#E8E8E8' : '#fff'}}>
      <Button style={{ backgroundColor: disabled ? '#E8E8E8' : '#fff'}} disabled={disabled} onClick={handleDecrement} icon={<MinusCircleOutlined />}/>
      <InputNumber
        style={{ backgroundColor: disabled ? '#E8E8E8' : '#fff'}}
        value={displayValue}
        onChange={debounceOnChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        min={allowNegative ? undefined : 0}
        disabled={disabled}
        precision={allowDecimal ? 2 : 0}
      />
      <Button style={{ backgroundColor: disabled ? '#E8E8E8' : '#fff'}} disabled={disabled} onClick={handleIncrement} icon={<PlusCircleOutlined />} />
    </InputContainer>
  );
};

export default IncrementDecrementInput;
