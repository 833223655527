import React, { useContext } from 'react';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';
import { getMultiplier } from '../../../../components/utils';


const MultiplierValue = ({value}) => {
  
  const {priceAdjust, adjustmentMenu, activeMarket, activeProperty} = useContext(PriceAdjustContext);

  const multiplier = getMultiplier(priceAdjust,activeMarket, activeProperty, adjustmentMenu, value)

  return (
      <BodyText>{multiplier > 0 ? `+${multiplier}` : (multiplier || '')}</BodyText> 
  )
}

export default MultiplierValue;