import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';

import DatePickerWithFunction from 'components/ReusableComponents/DatePicker/DatePickerWithFunction';
import { FULL_MONTH_NAME } from 'constants/GlobalConstant';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import ButtonWithFunction from '../../../components/ReusableComponents/Button/ButtonWithFunction';
import { Select, Tooltip } from 'antd';
import { getMarketAnalytics, getMarketDetails, getMarketPricing, getMarketPricingRules, getPropertyPricingRules, getPropertyAnalytics, getPropertyDetails, getPropertyOverviewMetrics, getPropertyPricing } from '../../../appRedux/actions';
import { now, pickerMonthYear } from '../../../constants/GlobalConstant';
import { RedoOutlined } from '@ant-design/icons';
import { BodyText } from '../../../components/ReusableComponents/Text/Text';

const {Option} = Select;

const FilterBody = styled.div`
  width: 100%;
  max-width: 1776px;
  height: 48px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const AdjustmentButtonDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 0;
`;

const AdjustmenButton = css`
  width: fit-content;
  height: 48px;
  outline: none;
  padding: 12px;
  transition: all 0.15s linear;
  font-size: ${({theme}) => theme.s4};
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  background: ${({theme}) => theme.white};
  color: ${({theme}) => theme.quibbleBlue};
  cursor: pointer;
  font-weight: 300;
  box-shadow: none;

  &:hover, &.active {
    background: ${({theme}) => theme.quibbleBlue};
    color: ${({theme}) => theme.white};
  }

`;

const PortfolioAdjustment = css`
  ${AdjustmenButton}
  border-radius: 4px 0px 0px 4px;
`;

const MarketAdjustment = css`
  ${AdjustmenButton}
  border-radius: 0px;
`;

const PropertyAdjustment = css`
  ${AdjustmenButton}
  border-radius: 0px 4px 4px 0px;
`;

const DatePickerStyle = css`
  width: 200px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  margin: 0 0 0 0;
  font-family: Commissioner;
`;


const AdjustmentSelectMarket = styled(Select)`
  width: 216px;
  ${({ $adjustmentMenu }) => $adjustmentMenu === 'portfolio'  && css`
  display: none;
`}
`;

const AdjustmentSelectProperty = styled(Select)`
  width: 216px;
  ${({ $adjustmentMenu }) => ($adjustmentMenu === 'portfolio' || $adjustmentMenu === 'market') && css`
  display: none;
`}
`;

const ResetFilters = styled.button`
  width: 50px;
  height: 44px;
  margin: 2px 0 0 0;
  border-radius: 4px;
  transition: all 0.15s linear;
  font-size: ${({theme}) => theme.s5};
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  background: ${({theme}) => theme.white};
  color: ${({theme}) => theme.quibbleBlue};
  cursor: pointer;
  // font-weight: 300;
  box-shadow: none;

  &:hover, &.active {
    background: ${({theme}) => theme.quibbleBlue};
    color: ${({theme}) => theme.white};
  }

`;

const PriceAdjustmentFilter = ({
  monthYear,
  setActiveProperty,
  setMonthYearVal,
  setActiveMarket,
  activeProperty,
  activeMarket,
  adjustmentMenu,
  emptySelectedDates,
  setAdjustmentMenuState}) => {

  const dispatch = useDispatch();
  const {propertyAnalytics, isFetchingPropertyAnalytics, isFetchingMarketAnalytics, marketAnalytics } = useSelector(({ property, markets }) => ({...property, ...markets}));

  const propertyList = activeMarket.length ? propertyAnalytics.filter((property) => property.market === activeMarket) : propertyAnalytics;

  //for first load
  useEffect(() => {
    const month = monthYear.getMonth() + 1;
    const year =  monthYear.getFullYear();

    if (!propertyAnalytics.length && !isFetchingPropertyAnalytics) {
      dispatch(getPropertyAnalytics({params: {month: month, year: year}}));
    }
    if (!marketAnalytics.length && !isFetchingMarketAnalytics) {
      dispatch(getMarketAnalytics({params: {month: month, year: year}}));
    }

  }, [ dispatch, setMonthYearVal, monthYear, propertyAnalytics.length, marketAnalytics.length, isFetchingPropertyAnalytics, isFetchingMarketAnalytics]);


  //on select month
  const onDatePickerChange = (value) => {
    const dateSplit = value.split('-');
    const month = FULL_MONTH_NAME.indexOf(dateSplit[0]) + 1;
    const formatMonth = month < 10 ? `0${month}` : month;
    const selectedDate = new Date(dateSplit[1], month-1, 1);
    setMonthYearVal(selectedDate);

      if (activeMarket.length) {
        dispatch(getMarketPricing({month: Number(formatMonth),  year: Number(dateSplit[1]), market: activeMarket}));
        dispatch(getMarketDetails({params: { month :  Number(formatMonth), year: Number(dateSplit[1]), market: activeMarket}}));
        dispatch(getMarketPricingRules({params: {market: activeMarket}}));
      }
      if (activeProperty.length) {
        dispatch(getPropertyPricing({month: Number(formatMonth), year: Number(dateSplit[1]), propertyId: activeProperty}));
        dispatch(getPropertyDetails({propertyId: activeProperty, params: {month: Number(formatMonth), year: Number(dateSplit[1])}}));
        dispatch(getPropertyOverviewMetrics({propertyId: activeProperty, params: {month:  Number(formatMonth), year:  Number(dateSplit[1]), viewType: 'rmView'}}));
        dispatch(getPropertyPricingRules({params: {listingId: activeProperty}}))
      }
  };

  const onAdjustmentClick = useCallback((value) => {

    if (value === 'market' && !activeMarket.length && marketAnalytics.length) {
      const marketInitial = marketAnalytics[0].key;
      setActiveMarket(marketInitial);
      emptySelectedDates();

      const month = monthYear.getMonth() + 1;
      const year =  monthYear.getFullYear();
  
      dispatch(getMarketPricing({month, year, market:marketInitial}));
      dispatch(getMarketDetails({params: { month, year, market: marketInitial}}));
      dispatch(getMarketPricingRules({params: {market: marketInitial}}));
      setAdjustmentMenuState('market');
      return;
    };

    if (value === 'property' && !activeProperty.length && propertyList.length) {
      const propertyInitial = propertyList[0].id;
      setActiveProperty(propertyInitial);
      emptySelectedDates();

      const month = monthYear.getMonth() + 1;
      const year =  monthYear.getFullYear();

      const initial_property = propertyList[0];
      if (initial_property && initial_property.market) {
        setActiveMarket(initial_property.market);
      } else {
        setActiveMarket('');
      }

        
      dispatch(getPropertyPricing({month, year, propertyId: propertyInitial}));
      dispatch(getPropertyDetails({propertyId: propertyInitial, params: {month, year}}));
      dispatch(getPropertyOverviewMetrics({propertyId: propertyInitial, params: {month, year, viewType: 'rmView'}}));
      dispatch(getPropertyPricingRules({params: {listingId: propertyInitial}}))

      setAdjustmentMenuState('property');

      return;
    };
    
    
    emptySelectedDates();
    setAdjustmentMenuState(value);

  }, [emptySelectedDates, setAdjustmentMenuState, activeMarket, setActiveMarket, dispatch, marketAnalytics, monthYear, activeProperty, setActiveProperty, propertyList]);

  const onPropertyChange = (value) => {
    setActiveProperty(value);
    emptySelectedDates();


    const selectedProperty = propertyList.find(property => property.id === value);

    if (selectedProperty && selectedProperty.market) {
      setActiveMarket(selectedProperty.market);
    } else {
      setActiveMarket('');
    }
    
    const month = monthYear.getMonth() + 1;
    const year =  monthYear.getFullYear();

    dispatch(getPropertyPricing({month, year, propertyId: value}));
    dispatch(getPropertyDetails({propertyId: value, params: {month, year}}));
    dispatch(getPropertyOverviewMetrics({propertyId: value, params: {month, year, viewType: 'rmView'}}));
    dispatch(getPropertyPricingRules({params: {listingId: value}}))

    setAdjustmentMenuState('property');
  };

  const onMarketChange = (value) => {
    setActiveMarket(value);
    emptySelectedDates();

    const month = monthYear.getMonth() + 1;
    const year =  monthYear.getFullYear();

    if (adjustmentMenu ==='property'){
      const filteredProperties = propertyAnalytics.filter(property => property.market === value);
      if (filteredProperties.length > 0) {
        const newActiveProperty  = filteredProperties[0].id;
        setActiveProperty(newActiveProperty);
        dispatch(getPropertyPricing({month, year, propertyId: newActiveProperty}));
        dispatch(getPropertyDetails({propertyId: newActiveProperty, params: {month, year}}));
        dispatch(getPropertyOverviewMetrics({propertyId: newActiveProperty, params: {month, year, viewType: 'rmView'}}));
        dispatch(getPropertyPricingRules({params: {listingId: newActiveProperty}}))

      } else {
        setActiveProperty('');
      }
    
      } else{
        setAdjustmentMenuState('market');
        setActiveProperty('');
      }

    dispatch(getMarketPricing({month, year, market:value}));
    dispatch(getMarketDetails({params: { month, year, market: value}}));
    dispatch(getMarketPricingRules({params: {market: value}}));


  };

  const onResetClick = () => {
    setAdjustmentMenuState('portfolio');
    setActiveProperty('');
    setActiveMarket('');
    setMonthYearVal(now);
  };


  return (
    <FilterBody>
      <AdjustmentButtonDiv>
        <ButtonWithFunction className={adjustmentMenu==='portfolio' && 'active'} value="portfolio"  onClickFunc={onAdjustmentClick} ButtonStyle={PortfolioAdjustment} label="Portfolio"/>
        <ButtonWithFunction className={adjustmentMenu==='market' && 'active'} value="market" disabled={isFetchingMarketAnalytics || !marketAnalytics.length} onClickFunc={onAdjustmentClick} ButtonStyle={MarketAdjustment} label="Market"/>
        <ButtonWithFunction className={adjustmentMenu==='property' && 'active'} value="property" disabled={isFetchingPropertyAnalytics || !propertyList.length} onClickFunc={onAdjustmentClick} ButtonStyle={PropertyAdjustment} label="Property"/>
      </AdjustmentButtonDiv>
      <DatePickerWithFunction   DatepickerStyle={DatePickerStyle} picker="month" format={"MMMM-YYYY"}  value={moment(pickerMonthYear(monthYear), 'MMMM-YYYY')} onChangeFunc={onDatePickerChange} />
      {/* <AdjustmentSelect placeholder="Select Country"></AdjustmentSelect> */}
      <AdjustmentSelectMarket getPopupContainer={trigger => trigger.parentNode} $adjustmentMenu={adjustmentMenu} disabled={isFetchingMarketAnalytics || !marketAnalytics.length} value={activeMarket.length ? activeMarket : 'Select Market'} onChange={onMarketChange} placeholder="Select Market"> {marketAnalytics.map((data) => (<Option key={data.key} value={data.key}>{data.key}</Option>))} </AdjustmentSelectMarket>
      <AdjustmentSelectProperty getPopupContainer={trigger => trigger.parentNode} $adjustmentMenu={adjustmentMenu} disabled={isFetchingPropertyAnalytics || !propertyList.length} value={activeProperty.length ?  activeProperty : 'Select Property'} onChange={onPropertyChange} placeholder="Select Property">{propertyList.map((data) => (<Option key={data.id} value={data.id}>{data.key}</Option>))}</AdjustmentSelectProperty>
      <Tooltip placement="bottom" title={<BodyText>Reset Filters</BodyText>}>
        <ResetFilters onClick={onResetClick}>
          <RedoOutlined />
        </ResetFilters>
      </Tooltip>
    </FilterBody>
  )
}

export default PriceAdjustmentFilter;
