/* Color Variables */
export const white = '#FFFFFF';
export const dirtyWhite = '#D9D9D91A';
export const dirtyGrey = '#D9D9D9';
export const black = '#000000';
export const quibbleLightBlue = '#EEFBFC';
export const quibbleMidBlue = '#A8E9F0';
export const quibbleActiveBlue = '#18A0FB';
export const quibbleBlue = '#1BB3CD';
export const tableBarBlue = '#2ECADB';
export const quibbleBlueBg = '#C2ECF3';
export const quibbleBlack = '#041D24';
export const quibbleDarkGrey = '#253334';
export const disabledGrey = '#939899';
export const quibbleGrey = '#F6F6F6';
export const quibbleHoverGrey = '#E8E8E8';
export const quibbleDarkGreen = '#132C33';
export const quibblePlaceholderGrey = '#999999';
export const quibbleYellow = '#FFC805';
export const quibbleOrange = '#F38B64';
export const quibbleNavyBlue = '#144272';
export const quibbleRed = '#EC7272';
export const quibbleFontGrey = '#283334';
export const quibblePurple = '#B78FF4';
export const quibbleGold = '#D79E34';
export const hoverBlue = '#005B6A';
export const quibbleBackgroundGrey = '#697273';
export const overrideYellow = '#D69927';
export const blurryYellow = '#FFEED3';
export const blurryPurple = '#EBDCFF';
export const blurryBlue = '#BCF2FB';
export const activeBlue = '#00A4E6';
export const tableActiveBlue = '#F1FDFF';
export const activeGreenBg = '#DDFFE9';
export const activeGreen = '#00A638';
export const expandedTableFont = '#88A7C7';

/* Status Color */
export const quibbleLightPositive = '#78BA47';
export const quibbleLigthNegative = '#F26262';
export const quibbleDarkPositive = '#40B72D';
export const quibbleDarkNegative = '#FF5733';

/* Font Sizes */
export const fontSizes = {
  s1: '0.5rem', /* 8px */
  s2: '0.75rem', /* 12px */
  s3: '0.875rem', /* 14px */
  s4: '1rem', /* 16px */
  s5: '1.25rem', /* 20px */
  s6: '1.5rem', /* 24px */
  s7: '1.75rem', /* 28px */
  s8: '2rem', /* 32px */
  s9: '2.25rem', /* 36px */
  s10: '2.5rem', /* 40px */
  s11: '2.75rem', /* 44px */
  s12: '3rem', /* 48px */
  s13: '3.25rem', /* 52px */
  s14: '3.5rem', /* 56px */
  s15: '3.75rem', /* 60px */
  s16: '4rem', /* 64px */
};

/* Themes */
export const lightTheme = {
  isDefault: true,
  buttonBg: quibbleBlue,
  buttonFg: white,
  bodyBg: white,
  subBodyBg: quibbleGrey,
  bodyFg: quibbleFontGrey,
  menuFg: quibbleDarkGrey,
  layoutBg: quibbleGrey,
  layoutFg: quibbleBackgroundGrey,
  otherInfoBg: quibbleDarkGreen,
  otherInfoFg: white,


  tooltipBg: quibbleBlack,
  tooltipFg: white,
  positive: quibbleLightPositive,
  negative: quibbleLigthNegative,
  zero: black,

  /* Calendar Colors */
  calendarNoMetric: quibbleHoverGrey,
  calendarLowMetric: blurryYellow,
  calendarMediumMetric: blurryPurple,
  calendarHighMetric: blurryBlue,

  white,
  black,
  dirtyWhite,
  quibbleBlue,
  quibbleLightBlue,
  disabledGrey,
  quibbleMidBlue,
  quibbleDarkGrey,
  quibbleBlack,
  quibblePlaceholderGrey,
  quibbleYellow,
  quibbleOrange,
  quibbleNavyBlue,
  quibbleDarkGreen,
  quibbleRed,
  quibblePurple,
  quibbleGold,
  quibbleFontGrey,
  hoverBlue,
  quibbleHoverGrey,
  quibbleBackgroundGrey,
  dirtyGrey,
  activeBlue,
  tableActiveBlue,
  quibbleActiveBlue,
  overrideYellow,
  activeGreenBg,
  activeGreen,
  quibbleBlueBg,
  tableBarBlue,
  expandedTableFont,


  ...fontSizes,
  bodyShadow: '0px 4px 18px 0px rgba(145, 165, 169, 0.20)',
};

export const darkTheme = {
  isDefault: false,
  buttonBg: quibbleLightBlue,
  buttonFg: black,
  bodyBg: quibbleBlack,
  subBodyBg: dirtyWhite,
  bodyFg: white,
  menuFg: quibbleLightBlue,
  layoutBg: quibbleDarkGreen,
  layoutFg: white,
  otherInfoBg: quibbleGrey,
  otherInfoFg: quibbleFontGrey,


  tooltipBg: white,
  tooltipFg: quibbleFontGrey,
  positive: quibbleDarkPositive,
  negative: quibbleDarkNegative,
  zero: white,

  /* Calendar Colors */
  calendarNoMetric: quibbleHoverGrey,
  calendarLowMetric: blurryYellow,
  calendarMediumMetric: blurryPurple,
  calendarHighMetric: blurryBlue,

  white,
  black,
  dirtyWhite,
  quibbleBlue,
  quibbleLightBlue,
  disabledGrey,
  quibbleMidBlue,
  quibbleDarkGrey,
  quibbleBlack,
  quibblePlaceholderGrey,
  quibbleYellow,
  quibbleOrange,
  quibbleNavyBlue,
  quibbleDarkGreen,
  quibbleRed,
  quibblePurple,
  quibbleGold,
  quibbleFontGrey,
  hoverBlue,
  quibbleHoverGrey,
  quibbleBackgroundGrey,
  dirtyGrey,
  activeBlue,
  tableActiveBlue,
  quibbleActiveBlue,
  overrideYellow,
  activeGreenBg,
  activeGreen,
  quibbleBlueBg,
  tableBarBlue,
  expandedTableFont,
  ...fontSizes,
  bodyShadow: '0px 4px 18px 0px rgba(145, 165, 169, 0.20)',
};

 /* Media Queries (Responsiveness) --- using max-width since we are starting from web to mobile */
export const media = {
  bpxxxl: '@media (max-width: 1920px)', /*xx-large screen */
  bpxxl: '@media (max-width: 1439px)', /*x-large screen */
  bpxl: '@media (max-width: 1365px)', /*large screen */
  bpxl_p : '@media (max-width: 1365px) and (orientation: portrait)', /* standard to large screen sizes in portrait */
  bpl : '@media (max-width: 1279px)',  /*  small monitors to standard screens sizes */
  bpm : '@media (max-width: 991px)',  /*  tablets screens */
  bpm_l: '@media (max-width: 991px) and (orientation: landscape)',  /*  tablets in landscapes */
  bps: '@media (max-width: 767px)',  /*  large screen phones */
  bps_l: '@media (max-width: 767px) and (orientation: landscape)',  /*  large screen phones */
  bpxs: '@media (max-width: 479px)',  /*  small screen phones */
  bpxxs: '@media (max-width: 380px)',  /*  x-small screen phones */
  bpxxxs: '@media (max-width: 290px)',  /*  tiny screen and shouldn't be supported */
  landscape: '@media (orientation: landscape)',  /*  check if device is in landscape */
  portrait: '@media (orientation: portrait)',  /*  check if device in portrait */
};
// https://www.w3schools.com/css/css_rwd_mediaqueries.asp ---> check the link for background
// that is not the only background/source but the media queries above is a culmination from different sources and current experience


export const googleMapDark = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  }
]
