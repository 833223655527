import {
  GET_PORTFOLIO_ANALYTICS,
  SET_PORTFOLIO_ANALYTICS
} from "../../constants/ActionTypes.js";


const initialState = {
  occadr: [],
  revenue: [],
  reservations: [],
  los: [],
  leadTime: [],
  inventory: [],
  properties: [],
  loading: false
};

const PortfolioAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PORTFOLIO_ANALYTICS: {
      return {
        ...state,
        [action?.metric]: action?.data,
        loading: false
      };
    }
    case GET_PORTFOLIO_ANALYTICS: {
      return {
        ...state,
        loading: true
      };
    }

    default:
      return state;
  }
}

export default PortfolioAnalyticsReducer;
