import { GET_EVENTS_TABLE, GET_EVENTS_TABLE_SUCCESS, GET_EVENT_DETAILS, GET_EVENT_DETAILS_SUCCESS, UPDATE_EVENT_DETAILS, UPDATE_EVENT_DETAILS_SUCCESS, DELETE_EVENT_DETAILS } from "../../constants/ActionTypes"


const INIT_STATE = {
    eventsTable: [],
    isFetchingEventsTable: false,
}

const EventsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_EVENTS_TABLE: {
      return {
        ...state,
        isFetchingEventsTable: true,
      }
    }

    case GET_EVENTS_TABLE_SUCCESS: {
      return {
        ...state,
        eventsTable: action.payload,
        isFetchingEventsTable: false,
      }
    }

    case GET_EVENT_DETAILS: {
      return {
        ...state,
        isFetchingEventDetails: true,
        eventDetails: undefined,
      }
    }

    case GET_EVENT_DETAILS_SUCCESS: {
      return {
        ...state,
        eventDetails: action.payload,
        isFetchingEventDetails: false,
      }
    }

    case UPDATE_EVENT_DETAILS: {
      return {
        ...state
      }
    }

    case UPDATE_EVENT_DETAILS_SUCCESS: {
      return {
        ...state,
        eventDetails : action.payload
      }
    }

      case DELETE_EVENT_DETAILS: {
        return {
          ...state,
          eventId: action.payload

        }
    }

    default:
      return state;
  }

}

export default EventsReducer;
