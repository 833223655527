import React, { Fragment, useCallback, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isEmpty, isUndefined } from 'lodash';
import {useHistory} from 'react-router-dom';

import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { CalendarDaysDiv, CalendarHeader, CalendarPropertyHeader } from '../../../CommonCSS';
import CalendarProperty from './CalendarPropertyMenu';
import { lightScrollbar, darkScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { FULL_MONTH_NAME, now } from 'constants/GlobalConstant';
import CalendarDayTitle from './CalendarDayTitle';
import PropertyCalendarDay from './PropertyCalendarDay';
import SkLoaderDistinct from '../../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderDistinct';

const PropertyCalendarDiv = styled.div`
  ${BodyCSS};
  // background: ${({theme}) => theme.white};
  max-width: 1800px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: auto;
  box-shadow: none;
`;

const PropertyMenu = styled.div`
  min-width: 224px;
  width: 224px;
  height: 100%;
  border-radius: 4px 0 0 4px;
  display: flex;
  flex-direction: column;
`;

const PropertyListDiv = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  overflow: auto;
  overflow-x: hidden;
  ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar };
`;

const PropertyCalendarBody = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0 4px 4px 0;
  background: inherit;
  border-left: 1px solid ${({theme}) => theme.dirtyGrey};
  padding: 0px 16px 16px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar };
`;

const PropertyCalendarHeader = styled(CalendarHeader)`
  flex-direction: column;
`;

const CalendarMonthShow = styled.div`
  min-width: 180px;
  width: 100%;
  min-height: 52px;
  background: ${({theme}) => theme.bodyBg};
  color: none;
`;

const TitleSpan = styled(BodyText)`
  width: fit-content;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
`;

const AdjustPricesButton = styled.button`
  ${BodyCSS}
  outline: none;
  border: 1px solid ${({theme}) => theme.quibbleHoverGrey};
  width: 144px;
  height: 64px;
  position: fixed;
  z-index: 100;
  text-align: center;
  cursor: pointer;
  top: ${({top}) => top};
  left: ${({left}) => left};
  transition: all 0.15s linear;

  &:hover {
    background: ${({theme}) => theme.quibbleBlue};
    ${BodyText} {
      color: ${({theme}) => theme.white};
    }
  }
`;


const PropertyCalendar = ({calendarContent, selectedProperty, calendarWeekDates, onSetSelectedProperty, showReservations, onRightClick, inProperty=false}) => {

  const [selectedDates, setSelectedDates] = useState([]);
  const [clickMenuProps, setClickMenuProps] = useState({x: 0, y: 0, isVisible: false,})

  const propertyRef = useRef(null);
  const history = useHistory();

  const { propertyCalendarList, isFetchingPropertyCalendar } = useSelector(({ property }) => property);
  const {calendarDays, propertyName} = propertyCalendarList.length ? propertyCalendarList[0] : {calendarDays: []} ;
  const endDateCopy = new Date(calendarWeekDates.endDate);
  const endDateNew = inProperty ? new Date(endDateCopy.setDate(endDateCopy.getDate()))  : new Date(endDateCopy.setDate(endDateCopy.getDate() - 1));
  const monthYear = endDateNew ?  {year: endDateNew.getFullYear() , month: endDateNew.getMonth() } : {year: now.getFullYear() , month: now.getMonth() };

  useEffect(() => {
    if(propertyRef && propertyRef.current) {
      propertyRef.current.scrollIntoView();
    }
  }, [propertyRef])

  useEffect(() => {
    setSelectedDates([])
    setClickMenuProps({x: 0, y: 0, isVisible: false,})
  }, [selectedProperty])

  const renderCalendar = useCallback(() => {
    const slicedDays = [calendarDays.slice(1,8), calendarDays.slice(8,15), calendarDays.slice(15,22), calendarDays.slice(22,29), calendarDays.slice(29,36), calendarDays.slice(36,43)];

    const triggerRightClick = (date, e) => {
      setClickMenuProps((prev) => ({...prev, x: e.clientX + 18, y: e.clientY + 18, isVisible: true}));
      if (selectedDates.includes(date)) return;
      setSelectedDates((prev) => [...prev, date])
   };

    const onHandleLeftClick = (date) => {
      setClickMenuProps((prev) => ({...prev, isVisible: false}))
      if (selectedDates.includes(date)){
        setSelectedDates((prev) => prev.filter((dataDate) => dataDate !== date))
        return;
      }
      setSelectedDates((prev) => [...prev, date])
   };

    const reservationsArray = slicedDays.map((dayArray, propertyIndex) => (dayArray.reduce((outputData, current, index) => {
      const {reservation, date} = current;
      const dateSplit = date.split('-');

      const dayDate = new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2])
      const dayNumber = dayDate.getDay();

      if (index === 0 ) {
        //if sundays and check if it there's a reservation on the previous day
        if (propertyIndex === 0 ? !isEmpty(calendarDays[0]?.reservation) : !isEmpty(slicedDays[propertyIndex - 1][6]?.reservation)) {

          //check if there's reservation and check also if it's a continuation from yesterday
          if (!isEmpty(reservation)) {
            const objectData = {
              ...outputData[reservation.id],
              guest: reservation?.guest,
              index: !isUndefined(outputData[reservation.id]?.index) ? outputData[reservation.id]?.index : index,
              // total: (outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : 0) + reservation?.price,
              total: outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : reservation?.price,
              adr: outputData[reservation.id]?.adr ?  outputData[reservation.id]?.adr : reservation?.adr,
              length: (outputData[reservation.id]?.length ?  outputData[reservation.id]?.length : 0) + 1,
            };
            // if it's a continuation
            if (propertyIndex === 0 ?  reservation.id === calendarDays[0].reservation?.id : reservation.id === slicedDays[propertyIndex - 1][6].reservation?.id) {
              return {
                ...outputData,
                [reservation.id] : {
                  ...objectData,
                  //check if this reservation is a continutation of yesterday's
                  isSunConti: propertyIndex === 0 ?  reservation.id === calendarDays[0].reservation?.id : reservation.id === slicedDays[propertyIndex - 1][6].reservation?.id,
                }}
              //no continuation but there's a new reservation and  show checkout from yesterday's
              } else {
                return {
                  ...outputData,
                  [reservation.id] : {
                   ...objectData,
                    isSunConti: false,
                  },
                  [date]: {
                    index: 0,
                    length: 1,
                    isSundayCheckout: true,
                  }
                }
              }
          } else {
          //no reservation and only show checkout from yesterday's
            return {
              ...outputData,
              [date]: {
                  index: 0,
                  length: 1,
                  isSundayCheckout: true,
              }
            }
          } 
          //no reservation's yesterday but a new reservation for the current day       
        } else if (!isEmpty(reservation)) {
            return {
              ...outputData,
              [reservation.id] : {
                ...outputData[reservation.id],
                guest: reservation?.guest,
                index: !isUndefined(outputData[reservation.id]?.index) ? outputData[reservation.id]?.index : index,
                // total: (outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : 0) + reservation?.price,
                total: outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : reservation?.price,
                adr: outputData[reservation.id]?.adr ?  outputData[reservation.id]?.adr : reservation?.adr,
                length: (outputData[reservation.id]?.length ?  outputData[reservation.id]?.length : 0) + 1,
                isSunConti: false,
              }
            }
        }
      };

      return !isEmpty(reservation) ?  {
        ...outputData,
        [reservation.id] : {
          ...outputData[reservation.id],
          guest: reservation?.guest,
          index: !isUndefined(outputData[reservation.id]?.index) ? outputData[reservation.id]?.index : index,
          // total: (outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : 0) + reservation?.price,
          total: outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : reservation?.price,
          adr: outputData[reservation.id]?.adr ?  outputData[reservation.id]?.adr : reservation?.adr,
          length: (outputData[reservation.id]?.length ?  outputData[reservation.id]?.length : 0) + 1,
          // isSatConti: propertyIndex === 5 ? reservation.id === calendarDays[43]?.reservation?.id : reservation.id === slicedDays[propertyIndex + 1][0]?.reservation?.id,
          //check if it's saturday
          isSatConti: dayNumber === 6,
        }
      } : {
        ...outputData
      }
    },{})));

    return (
      <Fragment>
        {slicedDays.map((sliced, index) => (
          <CalendarDaysDiv key={`${index}`}>
           {sliced.map((data, dayIndex) => (<PropertyCalendarDay selectedDates={selectedDates} onHandleLeftClick={onHandleLeftClick} onContextMenuTrigger={triggerRightClick} showReservations={showReservations} reservation={reservationsArray[index]} selectedMonth={monthYear.month} key={data.date} data={data} dayIndex={dayIndex}/> ))}
         </CalendarDaysDiv>
        ))}
      </Fragment>
    )
  }, [calendarDays, monthYear.month, showReservations, selectedDates]);



  const showAdjustPricesButton = useCallback(() => {

    const onAdjustPricesClick = () => {
      if (inProperty) {
        onRightClick({ dates: [...selectedDates], propertyId: selectedProperty, propertyName});
        return;
      }

      history.push(
        {
          pathname: '/price-adjustment',
          state: { pushedDates: [...selectedDates], propertyId: selectedProperty},
        },);

    }

    if (clickMenuProps.isVisible) {
      return <AdjustPricesButton onClick={onAdjustPricesClick}  top={`${clickMenuProps.y}px`} left={`${clickMenuProps.x}px`}> <BodyText> Adjust Prices </BodyText> </AdjustPricesButton>
    }
    return null;
  }, [clickMenuProps, history, selectedDates, selectedProperty, propertyName, inProperty, onRightClick]);



  return (
    <>
    {showAdjustPricesButton()}
    <PropertyCalendarDiv>
      {!inProperty && <PropertyMenu>
        <CalendarPropertyHeader>
          PROPERTIES
        </CalendarPropertyHeader>
        <PropertyListDiv>
        {calendarContent.sort((a, b) => a.propertyName.localeCompare(b.propertyName)).map((calendarData) => (
           <CalendarProperty ref={propertyRef} key={calendarData.propertyId} isFetchingCalendarListing={isFetchingPropertyCalendar} selectedProperty={selectedProperty} onSetSelectedProperty={onSetSelectedProperty} bedrooms={calendarData.bedrooms} market={calendarData.market} propertyName={calendarData.propertyName} propertyId={calendarData.propertyId}/>
        ))}
        </PropertyListDiv>
      </PropertyMenu>}
      {isFetchingPropertyCalendar ? 
      <SkLoaderDistinct type={'calendar'} /> :
      <PropertyCalendarBody>
        <PropertyCalendarHeader>
          {
            !inProperty &&
          <CalendarDaysDiv>
            {[...Array(7).keys()].map((_, index) => (<CalendarMonthShow  key={`${index}`} />))}
            <TitleSpan> {`${FULL_MONTH_NAME[monthYear.month]} ${monthYear.year} - ${propertyName || ''}`} </TitleSpan>
          </CalendarDaysDiv>
          }
          <CalendarDaysDiv>
            {[...Array(7).keys()].map((_,index) => (<CalendarDayTitle date={''} key={`${index}`} index={index}/>))}
          </CalendarDaysDiv>
        </PropertyCalendarHeader>
        {renderCalendar()}
      </PropertyCalendarBody>}
    </PropertyCalendarDiv>
    </>
  )
}

export default PropertyCalendar;
