import React, {useEffect, useState, useContext} from 'react';
import { Switch, Tooltip, message } from 'antd';
import styled from 'styled-components';
import { markets } from '../../../../services/markets';
import edit from 'assets/images/generic/edit.svg';
import { useDispatch, useSelector } from 'react-redux';
import MainAppContext from '../../../../containers/App/MainAppContext';
import ApplyModal from '../Paywall/ApplyModal';
import { BodyText, BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import { PrimaryButton } from '../../../../components/ReusableComponents/Button/Button';
import useClickOutsideListener from '../../../../customHooks/useClickOutsideListener';
import { getListings } from '../../../../appRedux/actions';

const SilentDynamicBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const EditImage = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;

  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 8px;
  margin: auto;
`;

const TooltipBody = styled.div`
  width: 264px;
  height: 150px;
  position: relative;
  padding: 32px 16px;

  display: flex;
  flex-direction: column;
`;

const ClosebuttonTooltip = styled(EditImage)`
  margin: unset;
  right: 4px;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.1);
  };
`;

const TooltipActivate = styled(PrimaryButton)`
  width: 100%;
  height: 48px;
  margin: 8px 0 0 0;
`;



const SilentDynamicPricing = ({value, setListingDrawer, nothingSet, updating, setUpdating}) => {

  const [switchChecked, isSwitchChecked] = useState(false);
  const [applyVisible, setApplyVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const dispatch = useDispatch();


  const {setUpgradePaywall} = useContext(MainAppContext);


  const { authUserDetails } = useSelector(({ users }) => ({...users,}));
  const {subscription} = authUserDetails;


  useEffect(() => {
      isSwitchChecked(value.isSilentDynamicPricingEnabled);
  },[value.isSilentDynamicPricingEnabled]);


  const onSubmitDynamicPricing = async () => {

    const noId = !value.airBnbId ? !value.vrboId : false;

    try {

      if (!value.benchmarkPrice || noId) {
        setListingDrawer({...value, isVisible: true, isEdit: false })
        return;
      }
      

      isSwitchChecked(true);
      setUpdating(true);
      message.info('Updating...', 10);

      const dynamicSetResult = await markets.updateListingPricing({id: value.id, params: {isSilentDynamicPricingEnabled: true} });

      if (!dynamicSetResult.status){
        message.error('Error on Silent Dynamic Pricing update.');
        isSwitchChecked(false);
        setUpdating(false);
        return;
      }; 
      
      dispatch(getListings());
    }

    catch(e) {
      message.error('Error on Silent Dynamic Pricing update.')
    }

  }

  const onToggleSwitch = async(e) => {
    try {
      if (e) {

        if (subscription?.status === 'trialing' && !subscription?.defaultPaymentMethod) {
          setUpgradePaywall(true);
          return;
        };

        if (nothingSet) {
          setApplyVisible(true);
          return;
        };

        setTooltipVisible(true);

        return;
      }

      isSwitchChecked(false)
      setUpdating(true)
      message.info('Updating...', 10);
      
      const dynamicSetResult = await markets.updateListingPricing({id: value.id, params: {isSilentDynamicPricingEnabled: false} })

      if (!dynamicSetResult.status){
        message.error('Error on Silent Dynamic Pricing update. Check subscription')
        isSwitchChecked(true);
        setUpdating(false);
        return;
      }; 

      dispatch(getListings());

      return;
    } catch (e) {

      return;
    }
  };


  const onClickEdit = () => {
    setListingDrawer({...value, isVisible: true, isEdit: true})
  };


  const onTooltipClose = () => {
    setTooltipVisible(false);
  };

  const onActivateClick = () => {
    setTooltipVisible(false);
    onSubmitDynamicPricing();
  };


  const {ref} = useClickOutsideListener(onTooltipClose);



  return (
    <SilentDynamicBody>
      <Tooltip placement='bottom' title={
        <TooltipBody ref={ref} >
          <ClosebuttonTooltip  onClick={onTooltipClose}>
            <BodyTitle>
              x
            </BodyTitle>
          </ClosebuttonTooltip>
          <BodyText>
            Activate Silent Dynamic Pricing ($10/month).
          </BodyText>
          <TooltipActivate onClick={onActivateClick}>
            Activate
          </TooltipActivate>
        </TooltipBody>
      } open={tooltipVisible} showArrow={false}>
        <Switch disabled={updating} checked={switchChecked} onChange={onToggleSwitch}/>
      </Tooltip>
      {switchChecked && <EditImage onClick={onClickEdit}><img src={edit} alt="edit" /></EditImage>}
      <ApplyModal isVisible={applyVisible} setIsvisible={setApplyVisible} onSubmitDynamicPricing={onSubmitDynamicPricing} />
    </SilentDynamicBody>
  )
}

export default SilentDynamicPricing;