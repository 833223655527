import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../../appRedux/actions';

import { UpperMetricDiv } from './MetricContainerTop';
import { SectionTitle } from './MetricContainerTop';
import MarketOccupancyDiffToolTip from './subComponents/MarketOccupancyDiffTooltip';
import { currencyAbbreviationFormatter } from '../../../util/helper';
import { Button } from '../../../components/ReusableComponents/Button/Button';
import { MarketTableTitleToolTip, NameLink } from './subComponents/MarketTableHeaderSub';
import MarketTable from 'components/ReusableComponents/Table/Table';
import SkLoaderOver from '../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import Pagination from '../../../components/ReusableComponents/Table/Components/Pagination'; 

const MarketContainerDiv = styled(UpperMetricDiv)`
  width: 100%;
  min-height: ${({ minHeight }) => minHeight};
  height: max-content;
  margin: 32px 0;
  padding: 24px 0 24px 24px;
  padding-bottom: 0;

  .ant-table-wrapper {
    margin-top: -4px;
  }
`;

const DiffTitle = styled.div`
  width: 250px;
  margin-bottom: -5px;
  height: 60px;
  background: #ececec;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: ${({ theme }) => theme.s4};
  padding: 12px 0 0 0;
  border-radius: 0px 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MarketAnalyticsDiv = styled.div`
  width: auto;
  height: auto;
  position: absolute;
  bottom: 12px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MarketAnalyticsButton = styled(Button)`
  width: 185px;
  height: 40px;
`;

const HintText = styled.p`
  margin: 0 0 0 12px;
  max-width: 272px;
  font-size: ${({ theme }) => theme.s2};
`;

const MarketFilterSpan = styled.span`
  font-weight: 700;
`;

const MarketContainer = ({ portfolio, isFetchingPortfolio, currencySymbol, monthYear, onMarketsRedirect, onMyPortfolioRedirect, filters }) => {
  const { markets } = portfolio;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(0);
  const [paginationSize, setPaginationSize] = useState(5); 
  const [pageRange, setPageRange] = useState([]);

  const dataSrcMarkets = useMemo(() => {
    const separatedWithDiff = markets.map((data) => ({
      ...data,
      key: data.market,
      difference: ((data.currentOccupancy - data.marketOccupancy) * 100).toFixed(0),
      occuNowPrevDiff: ((data.currentOccupancy - data.lastOccupancy) * 100).toFixed(0),
    })).reduce((outputData, currentData) => {
      if (currentData.difference === 0 || currentData.difference === '0' || !currentData.difference) {
        return {
          ...outputData,
          withoutDiff: [...outputData['withoutDiff'], currentData],
        };
      }
      return {
        ...outputData,
        withDiff: [...outputData['withDiff'], currentData],
      };
    }, {
      withDiff: [],
      withoutDiff: [],
    });

    const sortWithDiff = separatedWithDiff.withDiff.sort((a, b) => a.difference - b.difference);
    const sortWithoutDiff = separatedWithDiff.withoutDiff.sort((a, b) => a.occuNowPrevDiff - b.occuNowPrevDiff);

    return [...sortWithDiff, ...sortWithoutDiff];
  }, [markets]);

  const columns = useCallback(() => {
    const showName = (data) => {
      onMyPortfolioRedirect();
      dispatch(setFilters({ ...filters, showMarket: [data] }));
    };

    return [
      {
        title: 'MARKET',
        key: 'MARKET',
        dataIndex: 'market',
        width: '140px',
        onCell: () => ({
          style: { textAlign: 'left', paddingLeft: '2px' },
        }),
        onHeaderCell: () => ({
          style: { textAlign: 'left', paddingBottom: '0px', paddingLeft: '4px', border: 'none' },
        }),
        render: (data, record) => NameLink(data, record.difference, theme, showName),
      },
      {
        title: MarketTableTitleToolTip("Markets' property count", 'UNITS'),
        key: 'UNITS',
        dataIndex: 'propertiesCount',
        sorter: (a, b) => a.propertiesCount - b.propertiesCount,
        onHeaderCell: () => ({
          style: { border: 'none' },
        }),
      },
      {
        title: MarketTableTitleToolTip("Current Occupancy for the Market", 'MY OCCUPANCY'),
        key: 'MY OCCUPANCY',
        dataIndex: 'currentOccupancy',
        sorter: (a, b) => a.currentOccupancy - b.currentOccupancy,
        onHeaderCell: () => ({
          style: { border: 'none' },
        }),
        render: (data) => `${(data * 100).toFixed(0)}%`,
      },
      {
        title: MarketTableTitleToolTip("Occupancy compared to Market Occupancy Position", 'VS MARKET OCCUPANCY'),
        key: 'VS MARKET OCCUPANCY',
        sorter: (a, b) => a.difference - b.difference,
        onHeaderCell: () => ({
          style: { border: 'none' },
        }),
        render: (data) => MarketOccupancyDiffToolTip(data, theme),
      },
      {
        title: MarketTableTitleToolTip("Current Average Daily Rate", 'MY ADR'),
        key: 'MY ADR',
        dataIndex: 'currentAdr',
        sorter: (a, b) => a.currentAdr - b.currentAdr,
        onHeaderCell: () => ({
          style: { border: 'none' },
        }),
        render: (data) => `${currencyAbbreviationFormatter(data, currencySymbol)}`,
      },
      {
        title: 'MY OCCUPANCY',
        key: 'PREV MY OCCUPANCY',
        dataIndex: 'lastOccupancy',
        onHeaderCell: () => ({
          style: { border: 'none', background: '#ECECEC' },
        }),
        onCell: () => ({
          style: { background: '#ECECEC', maxWidth: '125px', minWidth: '125px' },
        }),
        render: (data) => `${(data * 100).toFixed(0)}%`,
        width: '125px',
      },
      {
        title: 'MY ADR',
        key: 'PREV MY ADR',
        dataIndex: 'lastAdr',
        onHeaderCell: () => ({
          style: { border: 'none', background: '#ECECEC', paddingRight: '24px' },
        }),
        onCell: () => ({
          style: { background: '#ECECEC', paddingRight: '24px', maxWidth: '125px', minWidth: '125px' },
        }),
        render: (data) => `${currencyAbbreviationFormatter(data, currencySymbol)}`,
        width: '125px',
      },
    ];
  }, [currencySymbol, dispatch, filters, theme, onMyPortfolioRedirect]);

  const handleSetActivePage = (type, value) => {
    if (type === 'prev') {
      setActivePage((prev) => Math.max(prev - 1, 0));
    } else if (type === 'next') {
      setActivePage((prev) => prev + 1);
    } else if (type === 'direct') {
      setActivePage(value);
    }
  };

  const handlePaginationSizeChange = (size) => {
    setPaginationSize(size);
  };

  useEffect(() => {
    const calculatePageRange = () => {
      const start = activePage * paginationSize;
      const end = start + paginationSize;
      setPageRange(dataSrcMarkets.slice(start, end));
    };

    calculatePageRange();
  }, [activePage, paginationSize, dataSrcMarkets]);

  return (
    <MarketContainerDiv minHeight={isFetchingPortfolio ? '464px' : '260px'}>
      <SectionTitle>Market Health</SectionTitle>
      <DiffTitle>{monthYear(1)}</DiffTitle>
      <MarketTable 
        columns={columns()} 
        dataSource={pageRange} 
        showSorterTooltip={false} 
        pagination={false} 
      />
      <Pagination
        onSetActivePage={handleSetActivePage}
        pageRange={pageRange}
        activePage={activePage}
        totalLength={dataSrcMarkets.length}
        isLoading={isFetchingPortfolio}
        paginationSize={paginationSize}
        setIntPaginationSize={handlePaginationSizeChange}
        pageSizeOptions={["5", "10"]} 
      />
      <MarketAnalyticsDiv>
        <MarketAnalyticsButton onClick={onMarketsRedirect}>
          Market Analytics
        </MarketAnalyticsButton>
        <HintText>
          Hint: You can also filter every view (including this one) using the <MarketFilterSpan>Market Filter</MarketFilterSpan> at the top of any page
        </HintText>
      </MarketAnalyticsDiv>
      <SkLoaderOver isLoading={isFetchingPortfolio} type='table' tableRows={6} />
    </MarketContainerDiv>
  );
};

export default MarketContainer;
