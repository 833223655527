import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import {SecondaryButton} from '../../../../components/ReusableComponents/Button/Button';
import { getCalendarListings, getPropertyCalendar } from '../../../../appRedux/actions';
import _ from 'lodash';
import { formatDate } from '../../../../util/helper';
import DatePickerWithFunction from '../../../../components/ReusableComponents/DatePicker/DatePickerWithFunction';
import { FULL_MONTH_NAME, pickerMonthYear } from '../../../../constants/GlobalConstant';
import moment from 'moment';
import { FilterOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';

const PricingCalendarDiv = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FilterDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DatePickerStyle = css`
  width: 200px;
  height: 48px;
  border-radius: 4px;
  margin: 0 0 0 16px;
  font-family: Commissioner;
`;

const FilterButton = styled(SecondaryButton)`

  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover, &.active {
    background: ${({theme}) => theme.quibbleBlue};
    color: ${({theme}) => theme.white};
  }

`;


const PricingCalendarFilter = ({onShowMarketfilter, calendarWeekDates, onChangeDates, showMarketfilter, selectedProperty, setShowReservationsFunc}) => {

  const dispatch = useDispatch();

  const endDateCopy = new Date(calendarWeekDates.endDate)

  const onFilterClick = () => {
    onShowMarketfilter();
  }

  useEffect(() => {
    if (!_.isEmpty(calendarWeekDates) && !selectedProperty.length) {
      const {startDate, endDate} = calendarWeekDates;
      const params = {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      }
      dispatch(getCalendarListings(params))
    }
  }, [calendarWeekDates, selectedProperty, dispatch]);

  useEffect(() => {
    if(selectedProperty.length) {
      const {endDate} = calendarWeekDates;
      const endDateCopy = new Date(endDate);
      const endDateNew = new Date(endDateCopy.setDate(endDateCopy.getDate() - 1))
      const firstDay = new Date(endDateNew.getFullYear(), endDateNew.getMonth(), 1);
      const startDateCopy = new Date(firstDay);
      const saturday = new Date(startDateCopy.setDate(startDateCopy.getDate() - (startDateCopy.getDay() + 1)));
      const saturdayCopy = new Date(saturday);
      const newEndDate = new Date(saturday.setDate(saturday.getDate() + 43))
      const params = {
        startDate: formatDate(saturdayCopy),
        endDate: formatDate(newEndDate),
        listingId: selectedProperty
      }

      dispatch(getPropertyCalendar(params))
    }
  },[selectedProperty, calendarWeekDates, dispatch])

  const onDatePickerChange = (value) => {
    const dateSplit = value.split('-');
    const firstDay = new Date(dateSplit[1], FULL_MONTH_NAME.indexOf(dateSplit[0]), 1)
    const saturday = new Date(firstDay.setDate(firstDay.getDate() - (firstDay.getDay() + 1)));
    const saturdayCopy = new Date(saturday);
    const sundayDate = new Date(saturday.setDate(saturday.getDate() + 8));

    const newDateRange = {
      startDate: saturdayCopy,
      endDate: sundayDate,
    }

    onChangeDates("picker", newDateRange);
  }

  const onSwitchChange = () => {
    setShowReservationsFunc();
  }

  // console.log(pickerMonthYear(new Date(endDateCopy.setDate(endDateCopy.getDate() - 1))));

  return (
    <PricingCalendarDiv>
      <FilterDiv>
        <FilterButton onClick={onFilterClick} className={showMarketfilter && 'active'} >
          <FilterOutlined /> &nbsp; Filter
        </FilterButton>
        <DatePickerWithFunction DatepickerStyle={DatePickerStyle} onChangeFunc={onDatePickerChange}  value={moment(pickerMonthYear(new Date(endDateCopy.setDate(endDateCopy.getDate() - 1))), 'MMMM-YYYY')} format={"MMMM-YYYY"}  picker="month"/>
      </FilterDiv>
      <FilterDiv>
        <BodyText margin="0 16px 0 0">
          Show Reservations
        </BodyText>
        <Switch onChange={onSwitchChange}/>
      </FilterDiv>
    </PricingCalendarDiv>
  )
}

export default PricingCalendarFilter;