import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { Tooltip } from 'antd';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';
import { BodyCSS } from '../../../../../../components/ReusableComponents/Card-Body/BodyCSS';
import { isUndefined } from 'lodash';

const GraphMultiplierDiv = styled.div`
  width: 1082px;
  height: ${({height}) => height};
  max-height: 102px;
  margin: 16px 0 0 -26px;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  position: relative;
  background: #005B6A;
  padding: ${({padding}) => padding};
  overflow: hidden;
  transition: all 0.3s linear;
`;

const Middleborder = styled.div`
  width: 100%;
  height: 0.5px;
  position: absolute;
  border-bottom: 0.5px solid #d9d9d9;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const MutliplierTooltipDiv = styled.div`
  ${BodyCSS}
  width: 150px;
  height: fit-content;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CirclePortfolio = styled.span`
  width: 10px;
  height: 10px;
  background: #5BD7FF;
  border-radius: 100%;
`;

const CirclePortfolioNegative = styled(CirclePortfolio)`
  background: #E8D9FB;
`;

const CircleEvent = styled(CirclePortfolio)`
  background: #FCA484;
`;

const CircleMarket = styled(CirclePortfolio)`
  background: #00C1FE;
`;

const CircleMarketNegative = styled(CirclePortfolio)`
  background: #D7BDF8;
`;

const CircleProperty = styled(CirclePortfolio)`
  background: #15AAE9;
`;

const CirclePropertyNegative = styled(CirclePortfolio)`
  background: #C6A1F5;
`;

const BodyTextTooltip = styled(BodyText)`
  align-self: flex-start;
  padding: 0 0 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0px 0px 8px 0;
`;

const SignDiv = styled.div`
  width: 20px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const PurpleDiv = styled.div`
  width: 20px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;  

const PortfolioPositive = styled.div`
  width: 100%;
  height: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #5BD7FF;
`;

const MarketPositive = styled.div`
  width: 100%;
  height: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #00C1FE;
`;

const PropertyPositive = styled.div`
  width: 100%;
  height: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #15AAE9;
`;

const BlueDiv = styled(PurpleDiv)`
  justify-content: flex-end;
`;

const EventPositive = styled.div`
  width: 100%;
  height: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #FCA584;
`;


const PortfolioNegative = styled.div`
  width: 100%;
  height: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #E8D9FB;
`;

const MarketNegative = styled.div`
  width: 100%;
  height: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #D7BDF8;
`;

const PropertyNegative = styled.div`
  width: 100%;
  height: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #C6A1F5;
`;


const GraphMultiplier = ({propertyPricing, graphMultiplier}) => {

  const {priceAdjust, activeProperty} = useContext(PriceAdjustContext);

  const showMultiplerValues = useCallback(() => {
    
    return propertyPricing.map((value) => {

      const portfolioTooltip = !isUndefined(priceAdjust?.portfolio[value?.date]?.multiplier) ? priceAdjust?.portfolio[value?.date]?.multiplier : value?.multiplierPortfolio ;
      const propertyMarketTooltip =  priceAdjust.market[value?.market] ? !isUndefined(priceAdjust.market[value?.market][value?.date]?.multiplier) ? priceAdjust.market[value?.market][value?.date]?.multiplier : value.multiplierMarket : value.multiplierMarket;
      const propertyTooltip = priceAdjust.property[activeProperty] ? !isUndefined(priceAdjust.property[activeProperty][value?.date]?.multiplier) ?  priceAdjust.property[activeProperty][value?.date]?.multiplier : value.multiplier  :  value.multiplier;    
      const eventTooltip = value.multiplerEvent;

      const portfolioSize = eventTooltip ?  `${Math.abs(portfolioTooltip/4)}%` : `${Math.abs(portfolioTooltip/3)}%`;
      const marketSize =  eventTooltip ? `${Math.abs(propertyMarketTooltip/4)}%` : `${Math.abs(propertyMarketTooltip/3)}%`;
      const propertySize = eventTooltip ? `${Math.abs(propertyTooltip/4)}%` : `${Math.abs(propertyTooltip/3)}%`;
      const eventSize = `${Math.abs(eventTooltip/4)}%`;

      const checkIfHasValuePortPro = Math.abs(portfolioTooltip) > 0 || Math.abs(propertyTooltip) > 0;
      const checIfhasMarket = Math.abs(propertyMarketTooltip) > 0;
      const checkIfHasProperty = checkIfHasValuePortPro || Math.abs(propertyTooltip) > 0;
    
      const checkIfTooltip = checkIfHasProperty || checIfhasMarket ||  Math.abs(eventTooltip) > 0;

      const showLine =  portfolioTooltip !== 0 || propertyMarketTooltip !== 0  || propertyTooltip !==0 || eventTooltip !==0;
      
      return (
        <Tooltip key={value?.date} title={ checkIfTooltip ? 
          <MutliplierTooltipDiv>
            <BodyText margin="8px 0 12px 0"> Multiplier Profile </BodyText>
            <BodyTextTooltip> {portfolioTooltip >= 0 ? <> <CirclePortfolio/>  {`Portfolio: +${portfolioTooltip}`} </> : <><CirclePortfolioNegative /> {`Portfolio: ${portfolioTooltip}`} </> } </BodyTextTooltip>
            <BodyTextTooltip> {propertyMarketTooltip >= 0 ? <><CircleMarket/> {`Market: +${propertyMarketTooltip}`} </>  :<>  <CircleMarketNegative /> {`Market: ${propertyMarketTooltip}`} </>} </BodyTextTooltip>
            <BodyTextTooltip> {propertyTooltip >= 0 ? <><CircleProperty/> {`Property: +${propertyTooltip}`} </>   : <> <CirclePropertyNegative /> {`Property: ${propertyTooltip}`} </>}  </BodyTextTooltip>
            {eventTooltip ? <BodyTextTooltip> {eventTooltip >= 0 ? <><CircleEvent/> {`Event: +${eventTooltip}`} </>   : <> <CircleEvent /> {`Event: ${eventTooltip}`} </>}  </BodyTextTooltip> : null}
          </MutliplierTooltipDiv>
          : 
          null
        } >
          <SignDiv >
            <BlueDiv>
              {propertyTooltip > 0  ?  <PropertyPositive colorWidth={propertySize}/> : null}
              {propertyMarketTooltip > 0  ? <MarketPositive colorWidth={marketSize}/> : null}
              {portfolioTooltip > 0 && <PortfolioPositive colorWidth={portfolioSize}/>}
              {eventTooltip > 0 && <EventPositive colorWidth={eventSize}/>}
            </BlueDiv>
            <PurpleDiv>
              {propertyTooltip < 0  ?  <PropertyNegative colorWidth={propertySize}/> : null}
              {propertyMarketTooltip < 0  ? <MarketNegative colorWidth={marketSize}/> : null}
              {portfolioTooltip < 0 && <PortfolioNegative colorWidth={portfolioSize}/>}
              {eventTooltip < 0 && <EventPositive colorWidth={eventSize}/>}
            </PurpleDiv>
          </SignDiv>
          {showLine ? <Middleborder/>: ''}
        </Tooltip>
      )
     })
  }, [activeProperty, priceAdjust, propertyPricing ])

  return (
    <GraphMultiplierDiv height={graphMultiplier ? '1000px' : '0px'} padding={graphMultiplier ? '8px' : '0px'}>
      {showMultiplerValues()}
    </GraphMultiplierDiv>
  )
}

export default GraphMultiplier;