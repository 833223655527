import React, { Fragment, memo } from 'react';
import MarketCalendar from './MarketCalendar';
import PropertyCalendar from './PropertyCalendar';


const Calendar = ({calendarContent, onChangeDates, selectedProperty, calendarWeekDates, onSetSelectedProperty, isFetchingCalendarListing, showReservations}) => {

  return (
    <Fragment>
      {selectedProperty.length ? 
      <PropertyCalendar showReservations={showReservations} isFetchingCalendarListing={isFetchingCalendarListing} calendarContent={calendarContent} calendarWeekDates={calendarWeekDates} onChangeDates={onChangeDates} selectedProperty={selectedProperty} onSetSelectedProperty={onSetSelectedProperty}/> :
      <MarketCalendar showReservations={showReservations} isFetchingCalendarListing={isFetchingCalendarListing} calendarContent={calendarContent} onChangeDates={onChangeDates} selectedProperty={selectedProperty} onSetSelectedProperty={onSetSelectedProperty}/>}
    </Fragment>
  )
}

export default memo(Calendar);
