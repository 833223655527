import React, {Fragment, memo} from 'react';
import styled, { useTheme } from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle } from 'components/ReusableComponents/Text/Text';
import {currencyFormatter} from 'util/helper';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import SkLoaderOver from 'components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { isEmpty } from 'lodash';
import { EmptyBodyText } from '../CommonCSS';


const PropertyMetricDiv = styled.div`
  ${BodyCSS}
  width: 100%;
  max-width: 930px;
  height: ${({height}) => height};
  padding:  ${({padding}) => padding};
  display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
  position: relative;
`;

const BoxDivs = styled.div`
  width: fit-content;
  display: flex;
  margin: 16px 0 0 0;
  flex-direction: row;
  gap: 24px;
`;

const HoverDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute; 
  top: 0px;
  left: 0px;
  background: white;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 2;
`;

const MetricBox = styled.div`
  width: 202px;
  height: ${({height}) => height};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.dirtyGrey};
  position: relative;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover, &:focus {
    ${HoverDiv} {
      display: none;
    }
  }
`;

const MetricValueSpan = styled.span`
  color:${({color, theme}) => color || theme.bodyFg};
  font-size: ${({theme}) => theme.s8};
  font-weight: 300;
  margin: 0px;
  line-height: 100%;
`;

const BodyTextGrey = styled(BodyText)`
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  margin: 0 0 0 0;
`;

const ForecastDiv = styled.div`
  width: 100%;
  height: 66px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #1BB3CD;
  border-style: dotted;
  font-size: 14px;
  font-weight: 300;
  font-family: Commissioner;
  padding: 4px;
  box-sizing: border-box;
  color: ${({theme}) => theme.quibbleBlue};
  display: flex;
  align-items: center;
  justify-content:center;
`;

const GoalDiv = styled(ForecastDiv)`
  border: 1px solid #EDB548;
  color: #EDB548;
  border-style: dotted;
`;




const PropertyMetrics = ({monthYear, metrics, forecast, isFetchingMetrics, goal}) => {
  
  const {adr, occ, totalRent, totalRevenue} = metrics || {};
  const theme = useTheme();

  return (
    <PropertyMetricDiv height={'236px'} padding={'16px' }>
      {isEmpty(metrics) ? <EmptyBodyText>No Data Found</EmptyBodyText> : 
      <Fragment>
        <BodyTitle>
          {`${monthYear?.getFullYear() || 'year'} Metrics`}
        </BodyTitle>
        <BoxDivs>
          <MetricBox height="157px">
            <HoverDiv>
              <MetricValueSpan color={theme.quibbleBlue}>
                {`${currencyFormatter(totalRevenue) || '-'}`}
              </MetricValueSpan>
              <BodyTextGrey> Total Revenue </BodyTextGrey>
            </HoverDiv>
            <ForecastDiv>
              Projected: {`${currencyFormatter(forecast?.revenue) || '-'}`}
            </ForecastDiv>
            <GoalDiv>
              Goal: { goal?.revenue ? `${currencyFormatter(goal?.revenue)}` : '--'}
            </GoalDiv> 
          </MetricBox>
          <MetricBox height="157px">
            <HoverDiv>
              <MetricValueSpan color={'#FA6DFD'}>
                {`${currencyFormatter(totalRent) || '-'}`}
              </MetricValueSpan>
              <BodyTextGrey> Total Rent </BodyTextGrey>
              </HoverDiv>
            <ForecastDiv>
              Projected: {`${currencyFormatter(forecast?.rent) || '-'}`}
            </ForecastDiv>
            <GoalDiv>
              Goal: { goal?.rent ? `${currencyFormatter(goal?.rent)}` : '--'}
            </GoalDiv>
          </MetricBox>
          <MetricBox height="157px">
            <HoverDiv>
              <MetricValueSpan color={theme.quibblePurple}>
                {`${currencyFormatter(adr) || '-'} `}
              </MetricValueSpan>
              <BodyTextGrey> ADR </BodyTextGrey>
            </HoverDiv>
            <ForecastDiv>
              Projected: {`${currencyFormatter(forecast?.adr) || '-'}`}
            </ForecastDiv>
            <GoalDiv>
              Goal: { goal?.adr ? `${currencyFormatter(goal?.adr)}` : '--'}
            </GoalDiv>
          </MetricBox>
          <MetricBox height="157px">
            <HoverDiv>
              <MetricValueSpan color={theme.quibbleYellow}>
                {`${(occ * 100).toFixed(0)}%`}
              </MetricValueSpan>
              <BodyTextGrey> Occupancy </BodyTextGrey>
            </HoverDiv>
            <ForecastDiv>
              Projected:  {`${(forecast?.occ * 100).toFixed(0)}%`}
            </ForecastDiv>
            <GoalDiv>
              Goal:  { goal?.occupancy  ? `${(goal?.occupancy * 100).toFixed(0)}%` : '--'}
            </GoalDiv>
          </MetricBox>
        </BoxDivs>
      </Fragment>}
      <SkLoaderOver isLoading={isFetchingMetrics || isEmpty(metrics)} />
    </PropertyMetricDiv>
  )
}

export default memo(PropertyMetrics);