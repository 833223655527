import React, {useCallback, useState, useRef} from 'react';
import styled from 'styled-components';
import MarketHunterSearch from './components/MHSearch';
import { BodyDescriptionText, BodyTitleText } from '../../../components/ReusableComponents/Text/Text';
import { media } from '../../../styles/stylesheet';

import MarketHunter from './MarketHunter';

const MarketHunterBody = styled.div`
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  padding: 0 16px 16px 16px;
  margin: 0 0 8px 0;

  ${({searchTrigger, theme}) => searchTrigger &&  `
    justify-content: flex-start;
    flex-direction:row;

    ${media.bpxxl} {
      align-items: flex-start;
    }

    ${media.bpm} {
      margin: 56px 0 0 0;
      height: auto;
      flex-direction: column;
      align-items: center;
      padding: 0 0 0 0;
    }

  `}

`;

const MarketHunterTitleDescription = styled(BodyDescriptionText)`
  margin: 8px 0 0 0;
`;

const MarketHunterMain = (props) => {
  const {sidebarCollapsed, toggleCollapseNav} = props;

  const bodyRef = useRef();
  const [searchTrigger, setSearchTrigger] = useState(false);


  const changeSetTrigger = () => {
    setSearchTrigger(true);
  }

  const showMarketHunterComponents = useCallback(() => {
    if (searchTrigger) {
      return (
        <MarketHunter />
      )
    }
    return (
      <>
        <BodyTitleText> Market Hunter </BodyTitleText>
        <MarketHunterTitleDescription> Find the best places to grow your estate. </MarketHunterTitleDescription>
      </>
    );
  }, [searchTrigger])

  return (
    <MarketHunterBody ref={bodyRef} searchTrigger={searchTrigger}>
      {showMarketHunterComponents()}
        <MarketHunterSearch
        changeSetTrigger={changeSetTrigger}
        searchTrigger={searchTrigger}
        sidebarCollapsed={sidebarCollapsed}
        toggleCollapseNav={toggleCollapseNav}
        // onToggleShowFooter={onToggleShowFooter}
      />
    </MarketHunterBody>
  )
}

export default MarketHunterMain;