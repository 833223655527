import React from 'react';
import styled from 'styled-components';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import { Link } from 'react-router-dom';

const ListingNameDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListingImg = styled.img`
  width: 58px;
  height: 40px;
  image-rendering: -webkit-optimize-contrast;
  border-radius:4px;
`;

const NameLink = styled(BodyText)`
  margin: 0px 0 0 8px;
  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
  }
`;

const ListingName = ({value}) => {
  return (
    <ListingNameDiv>
      <ListingImg src={value.thumbnailurl} alt="thumbnail url" />
        {/* {value.name} */}
        <Link to={{ pathname: "/market-analytics/property-details", state: {date: new Date(), propertyId: value?.id}}}><NameLink>{value.name}</NameLink></Link>
    </ListingNameDiv>
  )
}

export default ListingName;