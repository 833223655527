import React, {memo} from 'react';
import { Slider } from 'antd';
import styled from 'styled-components';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';

const FilterRangeBody = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const TwoInputDiv = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 0 0;
  position: relative;

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
`;


const FilterInput = styled.input`
  width: 100px;
  height: 100%;
  outline: none;
  border: 1px solid black;
  font-size: ${({theme}) => theme.s4};
  font-weight: 400;
  border-radius: 4px;
  padding-left: ${({padding}) => padding};

  position: relative;
`;

const FilterSlider = styled(Slider)`
  width: 100%;
  margin: 10px 0 0 -1px;
`;

const Symbol = styled(BodyText)`
  position: absolute;
  top: 3px;
  bottom: 0px;
  right: ${({right}) => right};
  left: ${({left}) => left};
  margin: auto;
  z-index: 1;
`;

const FilterRange = ({filterValues, onRangeChange, property, label, isCurrency=false, min=0, max=100}) => {

  const onInputChange = (e) => {
    let value = e.target.value;
    const filterValuesCopy = {...filterValues};

    if (Number(value) > max) {
      value = 100;
    }

    if (Number(value) < min) {
      value = 0;
    }

    
    onRangeChange(property, [value, filterValuesCopy[property][1]]);
  }

  const onInputChangeSecond = (e) => {
    let value = e.target.value;
    const filterValuesCopy = {...filterValues};

    if (Number(value) > max) {
      value = 100;
    }

    if (Number(value) < min) {
      value = 0;
    }

    onRangeChange(property, [filterValuesCopy[property][0], value]);
  }

  const onSliderChange = (value) => {
    onRangeChange(property, value);
  }

  const currencySymbol = isCurrency ? localStorage.getItem("currency") : '%';

  return (
    <FilterRangeBody>
      <BodyText margin="0px">
        {label}
      </BodyText>
      <TwoInputDiv>
        <Symbol right={isCurrency ? "86px" : "8px"}>{currencySymbol}</Symbol>
        <Symbol left={isCurrency ? "6px" :"78px"}>{currencySymbol}</Symbol>
        <FilterInput padding={isCurrency ? "16px" : "6px"}  value={filterValues[property][0]} margin="0px 4px" onChange={onInputChange} type="number"/>
          <BodyText>
            to
          </BodyText>
        <FilterInput padding={isCurrency ? "16px" : "6px"} value={filterValues[property][1]} onChange={onInputChangeSecond} type="number"/>
      </TwoInputDiv>
      <FilterSlider  range value={filterValues[property]} min={min} max={max} step={1} onChange={onSliderChange}/>
    </FilterRangeBody>
  )
}

export default memo(FilterRange);