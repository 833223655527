import React, { Fragment } from "react"
import styled from "styled-components"
import { CaretLeftOutlined, FallOutlined, RiseOutlined } from "@ant-design/icons";
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from "../../../../../components/ReusableComponents/Text/Text";

const MpeDiv = styled.div`
  width: 200px;
  height: 40px; 
  background: none;
  position: absolute; 
  bottom: ${({yAxis}) => yAxis};
  left: ${({xAxis}) => xAxis};;
  display: flex;
  align-items: center;
`;

const MidLine = styled.div`
  width: 16px;
  height: 1px;
  border-bottom: 1px solid ${({$color}) => $color};
`;

const Caret = styled(CaretLeftOutlined)`
  font-size: 12px;
  color: ${({$color}) => $color};
  margin-left: -4px;
`;

const PercentBody = styled.div`
  ${BodyCSS}
  width: fit-content;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
`;

const PercentText = styled(BodyText)`
  color: ${({$color}) => $color};
  font-size: 14px;
  margin: 0px;
`;

const BrokenLine = styled.div`
  width: 2px;
  height: ${({$height}) => $height}; 
  position: absolute;
  border-left: 1px dashed ${({$color}) => $color}; 
  // border-style: dashed;
  left: ${({xAxis}) => xAxis};
  bottom: ${({yAxis}) => yAxis};
`;




const MpeIndicator = ({yAxis, xAxis, value, lineHeight}) => {
  const isPositive = value > 0;
  const color = isPositive ? '#78BA47' : '#F26262';
  
  return (
    <Fragment>
      <MpeDiv yAxis={`${yAxis - 22}px`} xAxis={xAxis}>
        <MidLine $color={color} />
        <Caret $color={color} />
        <PercentBody >
          <PercentText margin="0px" $color={color}>
            {isPositive ?  `+${(value * 100).toFixed(0)}%` : `${(value * 100).toFixed(0)}%`}
            &nbsp;
            {isPositive ? <RiseOutlined />  : <FallOutlined /> }
          </PercentText>
        </PercentBody>
      </MpeDiv>
      <BrokenLine $height={`${lineHeight}px`} yAxis={`${(isPositive ? yAxis - lineHeight : yAxis) - 2}px`} xAxis={xAxis}  $color={color} />
    </Fragment>
  )
}

export default MpeIndicator;