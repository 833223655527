import styled, {css} from "styled-components";
import { BodyCSS } from "components/ReusableComponents/Card-Body/BodyCSS";
import { BodyTitle } from "components/ReusableComponents/Text/Text";

export const CardCSS = css`
  ${BodyCSS}
  width: 100%;
  padding: 24px 30px;
`;


export const PageTitle = styled(BodyTitle)`
  font-family: Inter;
  font-size: ${({theme}) => theme.s9};
`;