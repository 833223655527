import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Modal, Checkbox, Tag, message, Table, Select, Switch } from 'antd';
import { LeftOutlined, RightOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import { PrimaryButton, SecondaryButton } from '../../../components/ReusableComponents/Button/Button';
import { getPropertyGroups, getPropertyGroupsListings } from '../../../appRedux/actions/Property';
import { property } from '../../../services/property';
import searchIcon from 'assets/images/generic/search.svg';
import GroupTable from './GroupTable';
// import TagModal from './TagModal';
import NestedPropertyDisplay from './NestedPropertyDisplay';

import { BodyText } from "components/ReusableComponents/Text/Text";

const { confirm } = Modal;
const { Option } = Select;

const PropertyGroupsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NewGroupContainer = styled.div`
  min-height: calc(100vh - 350px);
  margin: 25px 0;
  overflow: auto;

  .ant-space-item {
    margin-bottom: 16px;
  }

  .ant-form-horizontal .ant-row {
    margin: 0;
  }

  .ant-btn-icon-only {
    margin: 0;
  }

  .ant-space-item {
    margin-bottom: 0;
  }
`;

const ViewModeBody = styled.div`
  background: #fff;
  #quibbleTable {
    min-height: 0;
  }
`;

const NewGroupContainerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const SearchContainer = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const AddGroupButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AddGroupButton = styled(PrimaryButton)`
  height: 50px;
`;

const SearchInput = styled.input`
  width: 300px;
  height: 48px;
  padding: 0 0 0 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-size: ${({ theme }) => theme.s4};

  ::placeholder {
    color: #B0B0B0;
    opacity: 1;
  }
`;

const SearchImage = styled.img`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 8px;
  margin: auto;
`;

const AddEditContainer = styled.div`
  display: flex;
`;

const AddModeBody = styled.div`
  width: 50%;
  padding: 30px;
  background: #fff;
`;

const NestedPropertyContainer = styled.div`
  width: 50%;
`;

const NewGroupTitle = styled.h1`
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
`;

const NewGroupField = styled.div`
  width: 100%;
  margin-top: 10px;

  div {
    margin-bottom: 10px;
  }
`;

const NewGroupButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

// const AddListingsButton = styled(SecondaryButton)`
//   margin-top: 10px;
//   width: 200px;
//   height: 50px;
// `;

const SaveButton = styled(PrimaryButton)`
  width: 200px;
  height: 50px;
  background: #1BB3CD;
  color: white;
`;

const CancelButton = styled(SecondaryButton)`
  margin-right: 20px;
  width: 200px;
  height: 50px;
`;

const ModalHeaderDiv = styled.div`
  width: 100%;
  display: flex;

  .ant-btn {
    margin: 0;
  }
`;

const ModalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ClearAllButton = styled(SecondaryButton)`
  margin-right: 20px;
  width: 200px;
  height: 50px;
`;

const SelectButton = styled(PrimaryButton)`
  width: 200px;
  height: 50px;
  background: #1BB3CD;
  color: white;
`;

// const PreviewButton = styled(Button)`
//   color: #00A4E6;
//   padding-left: 0;
//   text-align: left;
//   width: 200px;
//   height: 50px;
//   margin: 0;
// `;

const SelectContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const MarketSelect = styled(Select)`
  width: 400px;
  height: 48px;
  font-size: ${({theme}) => theme.s4};
`;

const TypeSelect = styled(Select)`
  margin-right: 20px;
  height: 48px;
  font-size: ${({theme}) => theme.s4};
`;

const SwitchDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  margin: 0 20px;
`;

const AddChildButton = styled(SecondaryButton)`
  margin-top: 10px;
  height: 50px;
  border: none;

  &:hover, &:focus, &.active {
    background: #fff;
    color:  ${({theme}) => theme.quibbleBlue};
  }
`;

const ChildPropertiesDiv = styled.div`
  padding-left: 50px;
`;

const PropertyGroups = () => {
  const { propertyGroups, propertyGroupsListings } = useSelector(({ property }) => property);

  const dispatch = useDispatch();
  
  const [selectMarket, setSelectMarket] = useState([]);

  const [mode, setMode] = useState('view');
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [selectedListings, setSelectedListings] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [transformedPropertyGroups, setTransformedPropertyGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groupType, setGroupType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [parentId, setParentId] = useState('');
  const [editGroupId, setEditGroupId] = useState(null);

  const [typeFilter, setTypeFilter] = useState('');
  const [marketFilter, setMarketFilter] = useState('');
  const [expandAllRows, setExpandAllRows] = useState(false);
  const [showActiveOnly, setShowActiveOnly] = useState(false);

  const [childProperties, setChildProperties] = useState(['']);
  const [listingsMap, setListingsMap] = useState({});
  const [currentPropertyGroupData, setCurrentPropertyGroupData] = useState([]);

  useEffect(() => {
    dispatch(getPropertyGroups());
    dispatch(getPropertyGroupsListings());
  }, [dispatch]);

  useEffect(() => {
    if (propertyGroupsListings.length) {
      const markets = propertyGroupsListings.reduce((outputData, currentData) => {
        if (outputData.includes(currentData.city) || !currentData.city) return outputData;
        return [...outputData, currentData.city]
      }, []);

      setSelectMarket(markets);
    }
    
  }, [propertyGroupsListings]);

  useEffect(() => {
    if (propertyGroups) {
      const data = propertyGroups.map((group) => ({
        id: group?._id ?? 'N/A',
        groupName: group?.name ?? 'N/A',
        type: group?.type ?? 'N/A',
        listings: group?.listings?.map((listing) => ({
          id: listing?.id ?? 'N/A',
          name: listing?.name ?? 'N/A',
          city: listing?.city ?? 'N/A',
          propertyGroupTags: listing?.propertyGroupTags?.join(', ') ?? '',
          propertyGroupParent: listing?.propertyGroupParent ?? false,
          active: listing?.active ?? false,
        })) ?? [],
      }));
      setTransformedPropertyGroups(data);
    }
  }, [propertyGroups]);
  

  const handleSaveGroup = async () => {
    setIsLoading(true);
  
    const listingsData = [];
  
    if (parentId) {
      listingsData.push({
        id: parentId,
        parent: true, 
      });
    }
  
    childProperties.forEach((childId) => {
      if (childId) {
        listingsData.push({
          id: childId,
          parent: false, 
        });
      }
    });
  
    const body = {
      name: groupName || undefined,
      type: groupType || undefined,
      listings: listingsData.length > 0 ? listingsData : undefined,
    };
  
    try {
      let res;
      if (editGroupId) {
        res = await property.updatePropertyGroup({ body, id: editGroupId });
      } else {
        res = await property.createPropertyGroup({ body });
      }
  
      if (res.status === 200 || res.status === 201) {
        dispatch(getPropertyGroups());
        setMode('view');
        setGroupName('');
        setGroupType('');
        setSelectedListings([]);
        setSelectedTags([]);
        setParentId('');
        setChildProperties(['']);
        setEditGroupId(null);
        message.success(`Property group ${editGroupId ? 'updated' : 'created'} successfully.`);
      } else {
        message.error(`Failed to ${editGroupId ? 'update' : 'create'} property group.`);
      }
    } catch (error) {
      message.error(`An error occurred while ${editGroupId ? 'updating' : 'creating'} the property group.`);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleDeleteGroup = async (propertyGroupId) => {
    try {
      const res = await property.deletePropertyGroup(propertyGroupId);

      if (res.status === 200 || res.status === 201) {
        dispatch(getPropertyGroups());
        message.success('Property group deleted successfully.');
      } else {
        message.error('Failed to delete property group.');
      }
    } catch (error) {
      message.error('An error occurred while deleting the property group.');
    }
  };

  const fetchPropertyGroupById = async (groupId) => {
    try {
      const data = await property.getPropertyGroupById({ id: groupId });

      setCurrentPropertyGroupData(data);
  
      console.log(data);
  
      if (!data || !Array.isArray(data.listings)) {
        message.error('Failed to load property group data.');
        return;
      }
  
      setGroupName(data.name || '');
      setGroupType(data.type || '');
  
      const parentListing = data.listings.find((listing) => listing.propertyGroupParent);
      setParentId(parentListing ? parentListing.id : '');
  
      const childListings = data.listings
        .filter((listing) => !listing.propertyGroupParent)
        .map((listing) => listing.id);
      setChildProperties(childListings.length > 0 ? childListings : ['']);
  
      const listingsMap = data.listings.reduce((acc, listing) => {
        acc[listing.id] = listing.name;
        return acc;
      }, {});
  
      setListingsMap(listingsMap);
  
      setEditGroupId(groupId);
      setMode('edit');
    } catch (error) {
      message.error('Failed to load property group data.');
    }
  };

  const handleAddGroup = () => {
    setMode('add');
  };

  const handleCancel = () => {
    setMode('view');
    setEditGroupId(null);
  };

  // const handleAddListingClick = () => {
  //   setIsModalVisible(true);
  // };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelectListing = (listingId) => {
    setSelectedListings((prevSelected) =>
      prevSelected.includes(listingId)
        ? prevSelected.filter((id) => id !== listingId)
        : [...prevSelected, listingId]
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleSelectButtonClick = () => {
    setIsModalVisible(false);
    // setIsTagModalVisible(true);
  };

  // const handleTagModalApply = () => {
  //   setIsTagModalVisible(false);
  // };

  const handleClearAllButtonClick = () => {
    setSelectedListings([]);
  };

  const handleFinishClick = () => {
    handleSaveGroup(selectedTags, parentId);
  };

  const showDeleteConfirm = (propertyGroupId) => {
    confirm({
      title: `Are you sure delete this group?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteGroup(propertyGroupId);
      },
    });
  };

  const handleAddChildProperty = () => {
    setChildProperties([...childProperties, '']);
  };

  const handleChildPropertyChange = (value, index) => {
    const updatedChildren = [...childProperties];
    updatedChildren[index] = value;
    setChildProperties(updatedChildren);
  };

  const handleDeleteChildProperty = (index) => {
    const updatedChildren = [...childProperties];
    updatedChildren.splice(index, 1);
    setChildProperties(updatedChildren);
  };

  const filteredPropertyGroups = transformedPropertyGroups
    .filter((group) => group.groupName.toLowerCase().includes(searchText.toLowerCase()))
    .filter((group) => (typeFilter ? group.type === typeFilter : true))
    .filter((group) => (marketFilter ? group.listings.some(listing => listing.city === marketFilter) : true))
    .filter((group) => (showActiveOnly ? group.listings.some(listing => listing.active) : true));

  const filteredListings = propertyGroupsListings.filter((listing) =>
    listing?.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  const pageSize = 5;
  const paginatedListings = filteredListings.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const columns = [
    {
      title: 'Property',
      dataIndex: 'name',
      render: (text, record) => (
        <Checkbox
          checked={selectedListings.includes(record.id)}
          onChange={() => handleSelectListing(record.id)}
        >
          {text}
        </Checkbox>
      ),
    },
    {
      title: 'Market',
      dataIndex: 'market',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      align: 'center',
      width: '20%',
      render: (active) =>
        active ? <Tag color="green">ACTIVE</Tag> : <Tag color="gray">INACTIVE</Tag>,
    },
  ];

  return (
    <PropertyGroupsContainer>
      <NewGroupContainer>
        {mode === 'view' && (
          <ViewModeBody>
            <NewGroupContainerHeader>
              <SelectContainer>
                <TypeSelect
                  placeholder="Select Market"
                  value={typeFilter}
                  onChange={(value) => setTypeFilter(value)}
                >
                  <Option key={'All Types'} value={''}>All Types</Option>
                  <Option value="Nested">Nested</Option>
                  <Option value="Multi-unit">Multi-unit</Option>
                </TypeSelect>
                
                <MarketSelect value={marketFilter} placeholder="Select Market" onChange={(value) => setMarketFilter(value)}>
                  <Option key={'All Markets'} value={''}>All Markets</Option>
                  {selectMarket.map((data) => (<Option key={data} value={data}>{data}</Option>))}
                </MarketSelect>
              </SelectContainer>

              <SwitchDiv>
                <BodyText margin=" 0 8px 0 0">
                  Expand All
                </BodyText>
                <Switch checked={expandAllRows} onChange={(checked) => setExpandAllRows(checked)} />
              </SwitchDiv>

              <SwitchDiv>
                <BodyText margin=" 0 8px 0 0">
                  Active Properties
                </BodyText>
                <Switch checked={showActiveOnly} onChange={(checked) => setShowActiveOnly(checked)} />
              </SwitchDiv>
              
              <SearchContainer>
                <SearchInput
                  type="search"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearchChange}
                />
                <SearchImage src={searchIcon} alt="search icon" />
              </SearchContainer>

              <AddGroupButtonContainer>
                <AddGroupButton onClick={handleAddGroup}>
                  <PlusOutlined /> Group
                </AddGroupButton>
              </AddGroupButtonContainer>
            </NewGroupContainerHeader>
            <Divider />
            <GroupTable
              filteredPropertyGroups={filteredPropertyGroups}
              showDeleteConfirm={showDeleteConfirm}
              onEditGroup={fetchPropertyGroupById}
              expandAllRows={expandAllRows}
            />
          </ViewModeBody>
        )}
        <AddEditContainer>
        {(mode === 'add' || mode === 'edit') && (
          

          <AddModeBody>
            <NewGroupTitle>{mode === 'edit' ? 'Edit Group' : 'New Group'}</NewGroupTitle>
            {/* <NewGroupField>
              Group Name
              <Input value={groupName} onChange={(e) => setGroupName(e.target.value)} />
            </NewGroupField> */}
            <NewGroupField>
              <div>Type</div>
              <Select
                value={groupType}
                onChange={(value) => setGroupType(value)}
                style={{ width: '100%' }}
              >
                <Select.Option value="Nested">Nested</Select.Option>
                <Select.Option value="Multi-unit">Multi-unit</Select.Option>
              </Select>
            </NewGroupField>

            <NewGroupField>
              <div>Parent Property</div>
              <Select
                value={listingsMap[parentId] || parentId} 
                onChange={(value) => setParentId(value)}
                style={{ width: '100%' }}
                placeholder="Select Parent Property"
              >
                {propertyGroupsListings
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((listing) => (
                    <Option key={listing.id} value={listing.id}>
                      {listingsMap[listing.id] || listing.name} 
                    </Option>
                  ))}
              </Select>
            </NewGroupField>

            <ChildPropertiesDiv>
              <NewGroupField>
                <div>Child</div>
                {childProperties.map((child, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Select
                      value={listingsMap[child] || child} 
                      onChange={(value) => handleChildPropertyChange(value, index)}
                      style={{ width: '90%', margin: 0 }}
                      placeholder="Select Child Property"
                    >
                      {propertyGroupsListings
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((listing) => (
                          <Option key={listing.id} value={listing.id}>
                            {listingsMap[listing.id] || listing.name} 
                          </Option>
                        ))}
                    </Select>
                    <Button
                      style={{ marginBottom: '10px' }}
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteChildProperty(index)}
                    />
                  </div>
                ))}
                <AddChildButton type="text" onClick={handleAddChildProperty}>
                  <PlusOutlined style={{ marginRight: '10px' }} /> Add Child
                </AddChildButton>
              </NewGroupField>
            </ChildPropertiesDiv>

            {/* {selectedListings.length > 0 && (
              <NewGroupField>
                <div>{selectedListings.length} listings in this group</div>
                <PreviewButton type="link" onClick={handleSelectButtonClick}>
                  Preview
                </PreviewButton>
              </NewGroupField>
            )}
            <NewGroupField>
              Add listings to this group (Optional)
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <AddListingsButton onClick={handleAddListingClick}>
                  Add Listing
                </AddListingsButton>
              </div>
            </NewGroupField> */}

            
          </AddModeBody>
          
        
        )}
        {mode === 'edit' && (
          <NestedPropertyContainer>
            <NestedPropertyDisplay
              parentProperty={parentId}
              childProperties={childProperties}
              listings={currentPropertyGroupData.listings}
            />
          </NestedPropertyContainer>
        )}
        
        </AddEditContainer>
      </NewGroupContainer>

      {(mode === 'add' || mode === 'edit') && (
        <NewGroupButtonsContainer>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          <SaveButton onClick={handleFinishClick} loading={isLoading}>
            {mode === 'edit' ? 'Save Changes' : 'Add Group'}
          </SaveButton>
        </NewGroupButtonsContainer>
      )}

      <Modal
        title="Select Listings"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={800}
      >
        <ModalHeaderDiv>
          <SearchContainer style={{ flexGrow: '1', marginRight: '25px' }}>
            <SearchInput
              type="search"
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: '100%' }}
            />
            <SearchImage src={searchIcon} alt="search icon" />
          </SearchContainer>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="text"
              icon={<LeftOutlined />}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage * pageSize >= filteredListings.length}
            />
          </div>
        </ModalHeaderDiv>

        <Table
          dataSource={paginatedListings}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
        <ModalButtonContainer>
          <ClearAllButton onClick={handleClearAllButtonClick}>Clear All</ClearAllButton>
          <SelectButton onClick={handleSelectButtonClick}>Select</SelectButton>
        </ModalButtonContainer>
      </Modal>
      {/* <TagModal
        isVisible={isTagModalVisible}
        listings={selectedListings.map((listingId) =>
          propertyGroupsListings.find((l) => l.id === listingId)
        )}
        onCancel={() => setIsTagModalVisible(false)}
        onApply={handleTagModalApply}
        tags={selectedTags}
        parentId={parentId}
        onTagChange={(tags) => setSelectedTags(tags)}
        onParentChange={(parentId) => setParentId(parentId)}
      /> */}
    </PropertyGroupsContainer>
  );
};

export default PropertyGroups;
