import React, { useState } from 'react';
import styled from 'styled-components';
import SubscribeModal from './components/SubscribeModal';
import SubscriptionPlan from './components/SubscriptionPlan';


const PayWallBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.50);
  display: ${({$display}) => $display};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2001;
`;


const Subscription = ({isExpired, isUpgrade, setUpgradePaywall}) => {

  const [subscriptionMenu, setSubscriptionMenu] = useState('subscriptionDetails')
  
  return (
    <PayWallBackdrop $display={isExpired || isUpgrade ? 'flex' : 'none'}>
      {subscriptionMenu === 'subscriptionDetails' && <SubscribeModal isExpired={isExpired} isUpgrade={isUpgrade} setSubscriptionMenu={setSubscriptionMenu} setUpgradePaywall={setUpgradePaywall}/>}
      {subscriptionMenu === 'subscriptionPlan' && <SubscriptionPlan setSubscriptionMenu={setSubscriptionMenu} />}
    </PayWallBackdrop>
  )
};

export default Subscription;


