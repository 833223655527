import React from 'react';
import styled, { css } from 'styled-components';
// import {BodyTitle} from 'components/ReusableComponents/Text/Text';
import searchIcon from 'assets/images/generic/search.svg';
import ButtonWithFunction from '../../../components/ReusableComponents/Button/ButtonWithFunction';



const WorkingQueueFilterDiv = styled.div`
  width: 100%;
  max-width: 1600px;
  height: 48px;
`;

const WorkingQueueFilterFlex = styled.div`
  min-width: 1600px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;


const SearchImage = styled.img`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 16px;
  margin: auto;
`;

const SearchInput = styled.input`
  width: 300px;
  height: 100%;
  padding: 0 0 0 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-weight: 300;
  font-size: ${({theme}) => theme.s4};

  ::placeholder {
    color: #B0B0B0;
    opacity: 1; /* Firefox */
  }
`;

const SelectButtonsDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectButton1 = css`
  width: fit-content;
  height: 48px;
  text-align: center;
  padding: 8px 16px;
  border-radius: 4px 0 0 4px;
  background: white;
  border: 1px solid #00BBD2;
  color: #00BBD2;
  box-shadow: none;

  &:hover, &:focus, &.active {
    background: #00BBD2;
    color: white;
  }
`;

const SelectButton2 = css`
  ${SelectButton1}
  border-radius: 0 4px 4px 0;
  position: relative;
`;

const OrangeCircle = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #F35A22;

  position: absolute;
  top: 6px;
  right: 6px;
`;


const WorkingQueueFilter = ({setSearchString, numberOfQueue, hasActionable, setTableView, tableView}) => {

  const onInputChange = (e) => {
    e.preventDefault();
    const searchString = e.target.value;
    setSearchString(searchString);
  };

  const onSelectFunc = (value) => {
    setTableView(value);
  };


  return (
    <WorkingQueueFilterDiv>
      <WorkingQueueFilterFlex>
        {/* <BodyTitle>
          {numberOfQueue > 0 ? numberOfQueue > 1  ? `${numberOfQueue} Properties` : `1 Property` : 'No current Queue'} 
        </BodyTitle> */}
        <SelectButtonsDiv>
          <ButtonWithFunction className={tableView==='list' && 'active'} ButtonStyle={SelectButton1} onClickFunc={onSelectFunc} value="list" label="MyList" />
          <ButtonWithFunction className={tableView==='actionable' && 'active'} ButtonStyle={SelectButton2} onClickFunc={onSelectFunc} value="actionable" label="Actionable"> 
            {hasActionable &&  tableView==='list' ? <OrangeCircle/> : null}
          </ButtonWithFunction>
        </SelectButtonsDiv>
        <SearchInput type="search" onChange={onInputChange} placeholder='Search' />
        <SearchImage src={searchIcon}  alt="search icon"/>
      </WorkingQueueFilterFlex>
    </WorkingQueueFilterDiv>
  )
}

export default WorkingQueueFilter;