import React from 'react';
import styled from 'styled-components';
import { LoaderCSS } from './CommonCSS';

const CalendarLoaderDiv = styled.div`
  width: 100%;
  height: 100%;
  gap: 16px;
  
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 0;
`;

const LoadingMini = styled.div`
  width: 100%;
  height: 5%;
  border-radius: 100px;

  ${LoaderCSS}
`;

const LoadingLarge = styled(LoadingMini)`
  height: 15%;
  border-radius: 16px; 
`;


const CalendarLoader = () => {
  return (
    <CalendarLoaderDiv>
      <LoadingMini />
      <LoadingLarge />
      <LoadingLarge />
      <LoadingLarge />
      <LoadingLarge />
    </CalendarLoaderDiv>
  )
}

export default CalendarLoader;