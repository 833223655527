import React, { Fragment, useEffect, useState, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';


// Modules
import { Radio, DatePicker, TreeSelect, Tooltip } from 'antd';
import { FilterFilled, CaretUpOutlined, ShopOutlined, RedoOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { isEqual, isArray } from 'lodash';



// Reducers, actions, action types, stylesheet
import { setFilters, getUserMetricsSummary, getUserPortfolio,
  userDashboard,
} from "../../../appRedux/actions";
import { getData, storeItem } from '../../../util/localStorageHelper';
import { media } from '../../../styles/stylesheet';

//other components
import { lightScrollbar, darkScrollbar } from '../Scrollbar/Scrollbar';
import { Button, LiteButton } from '../../ReusableComponents/Button/Button';
import { currentYr, currentMonth } from '../../../constants/GlobalConstant';
import { MenuFoldOutlined } from '@ant-design/icons';
import { foldIconStyle } from '../Icons/Icons';

const { SHOW_PARENT } = TreeSelect;

const RadioGroup = styled(Radio.Group)`
    display: flex;
    flex-direction: row;
`;

const RadioButton = styled(LiteButton)`
    width: 80px;
    margin: 0px 0 8px 0px;
    border-radius: 4px 0 0 4px;


    ${media.bpxs}{
      width: 72px;
    }

`;

const RadioButtonYear = styled(RadioButton)`
  border-radius: 0px 4px 4px 0px;
`;

const PageFiltersBody = styled.div`
  visibility: ${({visibility}) => visibility };
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 16px 0 16px;
	margin: 0px 0 0 0;
  height: 0px;
  position: absolute;
  top: 0px;
  height: fit-content;
  z-index: 1000;
  background: ${({theme}) => theme.bodyBg};
  box-shadow: ${({theme}) => theme.isDefault ? 'rgba(0, 0, 0, 0.15) 1.95px 0.5px 1px' : 'rgba(255, 255, 255, 0.15) 1.95px 0.5px 1px' } ;

  ${media.bpl} {
    margin: 0 0 40px 0;
  }

	${media.bpm} {
		background: ${({theme}) => theme.bodyBg};
		padding: 0px;
		height: 80px;
		position: absolute;
		width: 100%;
		left: 0px;
		top: 64px;
		flex-direction: row;
		justify-content: space-around;
		z-index: 100;
		margin-top: ${({showFilterTab}) => showFilterTab ? '0px': '-164px'};
		box-shadow: ${({theme}) => theme.isDefault ? 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset' : 'rgba(255, 255, 255, 0.08) 0px 4px 6px 0px inset'} ;
		transition: margin 0.2s linear;
		border-bottom: ${({theme}) => theme.isDefault ? '0.5px solid black' : '0.5px solid grey'};
	};

	${media.bps} {
		background: ${({theme}) => theme.bodyBg};
		padding: 0px;
		height: 120px;
		flex-direction: column;
		justify-content: center;
	};

  ${media.bpxs} {
		height: 164px;
    margin-top: ${({showFilterTab}) => showFilterTab ? '0px': '-180px'};

	};
`;

const DateDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
  flex-wrap: wrap;
  padding: 16px 0 8px 0;
  z-index: 1000;

  ${media.bpl} {
    width: 65%;
  }

	${media.bpm} {
    width: 100%;
		top: 80px;
		position: static;
		margin: 0px 0 0px 0;
    justify-content: center;
	}

	${media.bps} {
		top: 80px;
		position: static;
		margin: 0px 0 12px 0;
	}

`;

const DateDash = styled.div`
	height: 0px;
	width: 8px;
	border: 1px solid ${({theme}) => theme.menuFg};
	margin: 0px 4px 0 4px;

  ${media.bpxs}{
    width: 2px;
	}
`;

const SearchMarketsDiv = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

`;

const SearchMarkets = styled(TreeSelect)`
	width: 340px;
	height: 36px;
	font-weight: 600;
	margin: 0 0 8px 0;

	${media.bpm} {
		width: 260px;
    margin: 0px 0 0 16px;
	}

  ${media.bps} {
    margin: 8px 0 0 0;
	}

	.ant-select-selection-placeholder {
		color: ${({theme}) => theme.black} !important;
	}

  .ant-select-selection-overflow {
    max-height: 40px;
    overflow: auto;
    ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar };
  }

`;

const FilterIcon = styled(FilterFilled)`
	font-size: ${({theme}) => theme.s6};
	color: ${({theme}) => theme.bodyFg};
	${media.bpxs} {
		font-size: ${({theme}) => theme.s5};
	}
`;

const HideFilterIcon = styled(CaretUpOutlined)`
	font-size: ${({theme}) => theme.s5};
	color: ${({theme}) => theme.buttonFg};
`;

const MobileFilterButton = styled.button`
	display: none;
	${media.bpm} {
    visibility: ${({visibility}) => visibility };
		display: inline;
		cursor: pointer;
		width: 56px;
		height: 56px;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		border: none;
		z-index: 100;
		background: ${({theme}) => theme.bodyBg};
		box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
		position: absolute;
		right: 24px;
		top: 88px;
	}
	${media.bpxs} {
		width: 44px;
		height: 44px;
	}
`;

const HideFilterButton = styled(MobileFilterButton)`
	${media.bpm}{
		width: 36px;
		height: 36px;
		top: 68px;
		left: 0px;
		right: 0px;
		margin: auto;
		z-index: 200;
		box-shadow: none;
		background: ${({theme}) => theme.buttonBg};
		box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
	}
	${media.bps}{
		top: 100px;
	}
  ${media.bpxs}{
		top: 140px;
	}
`;

const DatePickerFilter = styled(DatePicker)`
  width: 115px;
  margin: 0px 16px 8px 0;
`;

const ClearButton = styled(Button)`
  width: 36px;
  height: 30px;
  margin: 0 0 8px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: ${({theme}) => theme.s4};

  ${media.bpl} {
    margin: -8px 0 0 8px;
	}

  ${media.bpm} {
    margin: -2px 0 0 8px;
	}

  ${media.bps} {
    margin: 8px 0 0 8px;
	}

  ${media.bpxs} {
    width: 80px;
    margin: 8px 0 8px 2px;
	}

`;

const SameStoreButton = styled(Button)`
  padding: 8px;
  width: fit-content;
  height: 30px;
  margin: 0 8px 8px 0;
`;

const MenuFold = styled(MenuFoldOutlined)`
  ${foldIconStyle};
`;

//initial values
const localPersistedFilter = getData('filters'),
      persistedDate = (localPersistedFilter?.month) ? localPersistedFilter.month.split('-'): [],
      initialFilterBy = localPersistedFilter?.filterBy || "month",
      initialMonth = persistedDate.length ? `${persistedDate[0]}-${persistedDate[1]}` : `${currentYr}-${currentMonth}`,
      initialYear = localPersistedFilter?.year ? localPersistedFilter.year : `${currentYr}`,
      initialMarketValues = localPersistedFilter?.marketFilterValues || [],
      initialMarketTreeDataList = getData('defaultMarketList') || [],
      initialSameStore = localPersistedFilter?.sameStore || false;


const formatMarkets = (defaultMarketsData) => {
  return defaultMarketsData.map((parentData) => ({
    title: `${parentData.market}, ${parentData.state}`,
    key: `${parentData.market}`,
    value:  `${parentData.market}`,
    label: `${parentData.market}`,
    children: parentData.properties.map((childData) => ({title: `${childData.name}`, key: `${childData.id}`, value: `${childData.id}`, label: `${childData.name}`})),
  }))
};

//Main Filter Component
const PageFilters = ({data, toggleCollapseNav}) => {

  //util hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const page = location.pathname;

  const validPage = ['/main/analytics/overview',].includes(page);
  const [showFilterTab, setShowFilterTab] = useState(false);
  const withSameStore = ['/home', '/main/analytics/overview'].includes(page);
  // Data
  const { users, common } = useSelector(({ users, common }) => ({users, common}));
  const {filters, parentFilter} = common;
  const {portfolio} = users;
  const {markets} = portfolio;
  const {showMarket} = filters;
  const {viewType} = parentFilter;

  //filter states
  const [sameStore, setSameStore] = useState(initialSameStore);
  const [filterBy, setFilterBy] = useState(initialFilterBy)
  const [monthFilter, setMonthFilter] = useState(initialMonth);
  const [yearFilter, setYearFilter] = useState(initialYear);
  const [marketFilterValues, setMarketFilterValues] = useState(initialMarketValues);
  const [marketTreeDataList, setMarketTreeDataList] = useState(initialMarketTreeDataList);

  //states for current params used
  const [currentMetricSummaryParams, setMetricSummaryParams] = useState({});
  const [currentPortfolioParams, setPortfolioParams] = useState({});


  //property values from
  const properties = useMemo(() => {
    return marketFilterValues.reduce((outputData, currentData) => {
      const getIds =  marketTreeDataList.filter((marketData) => marketData.key === currentData);
      const propertiesIds = getIds.length ? getIds[0].children.map((propertyData) => propertyData.key) : [currentData]
      return [...outputData, ...propertiesIds]
    }, [])
  }, [marketFilterValues, marketTreeDataList])

  //useEffect to get values of the treelist for marketswatch
  useEffect(() => {
    if (properties.length === 0 && markets.length) {
      const formattedList = formatMarkets(markets);
      setMarketTreeDataList(formattedList);
      storeItem("defaultMarketList", formattedList)
    }
  }, [markets, properties.length])


  //on call functions on input/select change

  const toggleSameStore = () => {
    setSameStore((prev) => !prev);
  }

  const onFilterBy = (e) => {
      e.preventDefault();
      setFilterBy(e.target.value || 'month')
  };

  const filterByYear = (_ , dateString) => {
      setYearFilter(dateString)
  };

  const filterByMonth = (_, dateString) => {
      setMonthFilter(dateString)
  }

  const setShowFilter = () => {
      setShowFilterTab((prev) => !prev );
  }

  const onSelectTreeChange = (values) => {
    setMarketFilterValues(values);
    console.log("filter values >> " ,values);
  }


  const showPicker = useCallback(() => {

    const disabledDateFunctionMonth = (current) => {
      if (data) return false;

      let customDate = moment(
              isArray(data?.disableDate) ? data.disableDate[0].bookingDate : ''
      ).format("YYYY-MM-DD");
      let futureMonth = moment().add(13, "M");

      if (current && current < moment(customDate, "YYYY-MM-DD")) return true;
      if (current && current > moment(futureMonth, "YYYY-MM-DD")) return true;

      return false;
    };

    const disabledDateFunctionYear = (current) => {
      if (data) return false;
      let customDate = moment(
              isArray(data?.disableDate) ? data.disableDate[0].bookingDate : ''
      ).format("YYYY-MM-DD");
      let futureMonth = moment();
      if (current && current < moment(customDate, "YYYY-MM-DD")) {
              return true;
      } else if (
              current &&
              current > moment(futureMonth, "YYYY-MM-DD")
      ) {
              return true;
      } else {
              return false;
      }
    };

    const yearInMonth = monthFilter.split('-')[0];


    if (filterBy === "month") {
      return (
        <DatePickerFilter
          disabledDate={disabledDateFunctionMonth}
          format={"YYYY-MM"}
          picker="month"
          onChange={filterByMonth}
          placeholder="Select Month"
          value={moment("" + yearInMonth + "" + monthFilter, "YYYY-MM")}
          allowClear={false}
        />
      )
    }

    return (
      <DatePickerFilter
        disabledDate={disabledDateFunctionYear}
        format='YYYY'
        picker="year"
        onChange={filterByYear}
        placeholder="Select Year"
        value={moment(yearFilter)}
        allowClear={false}
      />
    )
  }, [filterBy, monthFilter, yearFilter, data])

  const onClearFilters = () => {
    setFilterBy("month");
    setMonthFilter(`${currentYr}-${currentMonth}`);
    setYearFilter(`${currentYr}`);
    setMarketFilterValues([]);
    setSameStore(false);
  }

  //on Market clicked on Market table
  useEffect(() => {
    if (showMarket && showMarket.length) {
      setMarketFilterValues(showMarket)
    }
  }, [showMarket])

  //on every filter change or other actions that would change the data/filter
  useEffect(() => {

    const monthSplitted = monthFilter.split('-');
    const daysInMonth = new Date(monthSplitted[0], monthSplitted[1], 0).getDate();
    const fullMonth = monthSplitted[0] + "-" + monthSplitted[1] + "-" + daysInMonth;
    const year = filterBy === "year" ? yearFilter : null;
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");


    const globalFilters =  filters;

    const newGlobalFilters = {
      filterBy: filterBy,
      intMonth: monthSplitted[1],
      month: fullMonth,
      newCurrentDate: fullMonth,
      year: year,
      properties: properties,
      sameStore,
      viewType: viewType,
      //persist market listing selection and values
      marketFilterValues: marketFilterValues,
      showMarket: [],
      page: page,
    }

    const joinedFilters = { ...globalFilters, ...newGlobalFilters };


    //check if there are changes on the filters to apply it on the global filters that is used by the previous filter
    if (!isEqual(globalFilters, joinedFilters) && validPage) {
      dispatch(setFilters(joinedFilters))


      //new version of filtering starts here, API calls happens here, depending on what page is using the filter
      const apiParamValues = {
        month: fullMonth,
        year,
        sameStore,
        properties: properties,
        user_id: userId,
        token: token,
        viewType: viewType,
        // country: values.country,
      }


      if (page === "/home") {

        //compare previous params to new one to check the need to call the API again.
        if (!isEqual(apiParamValues, currentMetricSummaryParams) && !isEqual(apiParamValues, currentPortfolioParams)) {

          dispatch(getUserMetricsSummary(apiParamValues));
          dispatch(getUserPortfolio(apiParamValues));
          setMetricSummaryParams(apiParamValues);
          setPortfolioParams(apiParamValues);
        }

        return;
      }


      if (page === "/setting/myPortfolio") {
        const altApiParamValues = {...apiParamValues, sameStore: false}
        if(!isEqual(altApiParamValues, currentPortfolioParams)) {
          dispatch(getUserPortfolio(altApiParamValues));
          setPortfolioParams(altApiParamValues);
        }

        return;
      }

      if (page === "/main/analytics/overview") {
        if (location.hostname === 'quibblerm.io') {
          return history.push("/insights");
        }
        dispatch(getUserPortfolio(apiParamValues));
        dispatch(userDashboard(apiParamValues));
        return;
      }

    }

  }, [filterBy, sameStore, monthFilter, yearFilter, dispatch, filters, page, properties, markets, marketFilterValues, validPage, viewType, currentMetricSummaryParams, currentPortfolioParams])

  const onToggleNav = useCallback(() => {
    toggleCollapseNav();
  }, [toggleCollapseNav])



  return (
    <Fragment>
      <MobileFilterButton visibility={validPage ? 'visible' : 'hidden'}  onClick={setShowFilter} showFilterTab={showFilterTab}>
        <FilterIcon />
      </MobileFilterButton>
      <PageFiltersBody  visibility={validPage ? 'visible' : 'hidden'} showFilterTab={showFilterTab}>
        <MenuFold onClick={onToggleNav} style={{paddingRight:'20px'}} />
        <HideFilterButton onClick={setShowFilter} >
          <HideFilterIcon />
        </HideFilterButton>
        <DateDiv>
          { withSameStore &&
        <Tooltip title={sameStore ? 'Show All' : 'Show Same-store'}>
          <SameStoreButton onClick={toggleSameStore}
            className={sameStore && 'active'}
          >
            <ShopOutlined />
          </SameStoreButton>
        </Tooltip>}
          <RadioGroup value={filterBy}>
            <RadioButton
              className={`${filterBy === 'month' && 'active'}`}
              onClick={onFilterBy}
              value="month"
              disabled={page==="/main/analytics/portfolioanalytics"}
            >
                Monthly
            </RadioButton>
            <RadioButtonYear
              className={`${filterBy === 'year' && 'active'}`}
              onClick={onFilterBy}
              value="year"
            >
                Yearly
            </RadioButtonYear>
          </RadioGroup>
          <DateDash />
          {showPicker()}
          {
            page==="/main/analytics/portfolioanalytics" ? null :
            <SearchMarketsDiv>
              <SearchMarkets
                placeholder="Filter by Markets"
                allowClear={true}
                treeCheckable
                showCheckedStrategy={SHOW_PARENT}
                treeData={marketTreeDataList}
                value={marketFilterValues}
                onChange={onSelectTreeChange}
              />
              <Tooltip title="Clear filters">
                <ClearButton onClick={onClearFilters}>
                  <RedoOutlined />
                </ClearButton>
              </Tooltip>
            </SearchMarketsDiv>
          }

        </DateDiv>
      </PageFiltersBody>
    </Fragment>
  )
}

export default memo(PageFilters);
