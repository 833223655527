import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import Loader from '../../../../components/ReusableComponents/Loader';

import { subscribe } from '../../../../appRedux/actions/Subscription';

import PaymentCard from './PaymentCard';

const PaymentPageContainer = styled.div`
`;

const PageRow = styled(Row)`
  padding: 0.5rem 0;
`;

const HeaderText = styled.h1`
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  color: #283334;
  margin-top: 1rem;
`;

export default function PaymentPage(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [submit, setSubmit] = useState(false);

  const price = location?.state?.price;
  const cycle = price?.cycle === 'monthly' ? 'Monthly' : 'Annual';

  useEffect(() => {
    if (!price) history.push('/setting/profile/')
  })

  const checkout = () => {
    dispatch(subscribe(price.id));
    setSubmit(true);
  }

  return (
    <PaymentPageContainer>
      <PageRow justify="center">
        <Col><HeaderText>Your subscription plan</HeaderText></Col>
      </PageRow>
      <PageRow justify='center'>
        <Col>
          <PaymentCard plan={price?.name} period={cycle} amount={price?.price} subtotal={price?.price} currency='$' onContinue={checkout} disableContinue={submit} discount={price?.discount} onApplyCoupon={() => { return; }} />
        </Col>
      </PageRow>
      <Loader loading={submit}/>
    </PaymentPageContainer>
  )
}