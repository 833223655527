import React, {memo, useCallback} from 'react';
import styled, { useTheme } from 'styled-components';
import { CalendarDiv } from '../../../CommonCSS';
import { currencyFormatter } from '../../../../../../../util/helper';
import { currentDate } from '../../../../../../../constants/GlobalConstant';
import moonImg from '../../../../../../../assets/images/pricing-calendar/moon.svg'
import Reservations from './Reservations';
import { isUndefined } from 'lodash';


const CalendarDayDiv = styled.button`
  border: none;
  outline: none;
  ${CalendarDiv}
  background: ${({background}) => background};
  color: ${({theme}) => theme.black};
  opacity: ${({opacity}) => opacity};
  position: relative;
`; 

const CalendarPMSSpan = styled.span`
  font-size:${({theme}) =>  theme.s4};
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  font-weight: 500;
  line-height: 100%;
`;

const MinNightsDiv = styled.div`
  display: flex;
  flex-direciton:row;
  align-items: center;
`;

const MinNightsSpan = styled(CalendarPMSSpan)`
  font-size:${({theme}) =>  theme.s3};
  margin: 0 0 0 2px;
`;

const MoonImg = styled.img`
  image-rendering: -webkit-optimize-contrast;
`;


const todayDate = new Date(currentDate);


const CalendarMarketDay = ({data, reservation, dayIndex, showReservations}) => {
  const {demand, rate, date} = data;

  const theme = useTheme();

  const getDate = new Date(date);
  const checkDatePrev = getDate < todayDate;

  const setBackground = useCallback(() => {
    const backgroundColor = {
      LOW: theme.calendarLowMetric,
      MEDIUM: theme.calendarMediumMetric,
      HIGH:  theme.calendarHighMetric,
      NA: theme.calendarNoMetric,
    }

    if (checkDatePrev)  {
      return theme.calendarNoMetric
    };

    if(demand && demand.length) {
      return backgroundColor[demand];
    }

    return theme.calendarNoMetric;

  }, [theme, checkDatePrev, demand])

  const currentReservation = Object.keys(reservation).filter((propertyKey) => reservation[propertyKey].index === dayIndex)[0];

  return (
    <CalendarDayDiv background={setBackground()} opacity={checkDatePrev && !showReservations ? '0.5': '1'}>
      <CalendarPMSSpan>
        {currencyFormatter(rate?.pms) || '-'}
      </CalendarPMSSpan>
      {rate?.minNights && (
        <MinNightsDiv>
          <MoonImg src={moonImg} alt="moonImg" />
          <MinNightsSpan>{rate?.minNights}</MinNightsSpan>
        </MinNightsDiv>
      )}
      <Reservations showReservation={!isUndefined(reservation[currentReservation]) && showReservations} reservation={reservation[currentReservation] || {}} isMarket={true}/>
    </CalendarDayDiv>
  )
}

export default memo(CalendarMarketDay);