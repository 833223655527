import React, { useCallback } from 'react';
import styled from 'styled-components';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';

import moment from "moment";
import { HALF_DAY_NAME } from "constants/GlobalConstant";
// import DayBuild from './subComponents/OptimizeTable/DayBuild';
import Multiplier from "./subComponents/OptimizeTable/Multiplier";
import RenderOverride from "./subComponents/OptimizeTable/RenderOverride";
import OccupancyBar from "./subComponents/OptimizeTable/OccupancyBar";
import RightClickMeu from "./subComponents/OptimizeTable/RightClickMenu";
import MultiplierValue from './subComponents/OptimizeTable/MultiplierValue';
import { CaretDownOutlined } from '@ant-design/icons';
import OptPriceGap from './subComponents/OptimizeTable/OptPriceGap';
import LastYearPrice from './subComponents/OptimizeTable/LastYearPrice';
import RenderBar from '../../../marketAnalytics/sharedComponents/MarketPropertyTable/RenderBar';

const OptimizeTableBody = styled.div`
  width: fit-content;
  height: 100%;
  min-width: 1400px;
  max-width: 1400px;
  background: ${({theme}) => theme.bodyBg};
  border-radius: 4px 0 0 4px;
`;


const OptimizeTable = ({showToTablePricing, onAppyChanges, adjustmentMenu, isLoading, selectedDates, setSelectedDaysFunc, disabledDates}) => {

  const optimizeTableProperty = [
    {
      label: 'Date',
      width: '110px',
      align: 'left',
      dataIndex: 'date',
      customRender : ((data) => data.replaceAll('-', '/')),
    },
    {
      label: 'Day',
      width: '60px',
      dataIndex: 'date',
      customRender: ((data) => HALF_DAY_NAME[moment(data, 'YYYY-MM-DD').day()]),
    },
    {
      label: 'Market Occ',
      width: '130px',
      align: 'left',
      dataIndex: 'marketOccupancy',
      customRender: ((data, isSelected,) => <OccupancyBar data={data} isSelected={isSelected} type="occupancy"/>),
    },
    // {
    //   label: '3 Day Build',
    //   width: '110px',
    //   dataIndex: 'threeDayBuild',
    //   customRender: ((data, isSelected, activeColor) => <DayBuild data={data} isSelected={isSelected} activeColor={activeColor}/>)
    // },
    // {
    //   label: '7 Day Build',
    //   width: '110px',
    //   dataIndex: 'sevenDayBuild',
    //   customRender: ((data, isSelected, activeColor) => <DayBuild data={data} isSelected={isSelected} activeColor={activeColor}/>)
    // },
    // {
    //   label: '14 Day Build',
    //   width: '120px',
    //   dataIndex: 'fourteenDayBuild',
    //   customRender: ((data, isSelected, activeColor) => <DayBuild data={data} isSelected={isSelected} activeColor={activeColor}/>)
    // },
    {
      label: 'Opt price',
      width: '100px',
      align: 'right',
      customRender: ((data) => <OptPriceGap value={data} mode={"optimize"}/>)
    },
    {
      label: 'Price',
      width: '90px',
      rightClickRender : ((menuProps) => <RightClickMeu menuProps={menuProps} onAppyChanges={onAppyChanges} />),
      customRender: ((data) => <RenderOverride data={data} mode="price" />)
    },
    {
      label: 'Price Gap',
      width: '100px',
      align: 'right',
      customRender: ((data) => <OptPriceGap value={data} mode={"gap"}/>)
    },
    {
      label: 'LY price',
      width: '100px',
      align: 'right',
      customRender: ((data) => <LastYearPrice value={data} />)
    },
    {
      label: 'Min Stay',
      width: '90px',
      align: 'right',
      rightClickRender : ((menuProps) => <RightClickMeu menuProps={menuProps} onAppyChanges={onAppyChanges} setSelectedDaysFunc={setSelectedDaysFunc}/>),
      customRender: ((data) => <RenderOverride data={data} mode="minStay" /> )
    },
    {
      label: 'Booking',
      width: '96px',
      align: 'left',
      dataIndex: 'booking',
    },
    {
      label: 'MULTIPLIER',
      width: '120px',
      customRender: ((data) => <Multiplier value={data}/>)
    },
    {
      label: <CaretDownOutlined />,
      width: '56px',
      customRender: ((data) => <MultiplierValue value={data}/>)
    },
  ];
  
  
  const optimizeTableMarket = [
    {
      label: 'Date',
      width: '118px',
      align: 'left',
      dataIndex: 'date',
      customRender : ((data) => data.replaceAll('-', '/')),
    },
    {
      label: 'Day',
      width: '60px',
      dataIndex: 'date',
      customRender: ((data) => HALF_DAY_NAME[moment(data, 'YYYY-MM-DD').day()]),
    },
    {
      label: 'Occupancy',
      width: '474px',
      align: 'left',
      // dataIndex: 'occupancy',
      // customRender: ((data, isSelected) => <OccupancyBar data={data} isSelected={isSelected} type="occupancy"/>),
      customRender: ((data,) => <RenderBar adjustmentMenu={adjustmentMenu} tooltipLabel="Occupancy" current={data?.occupancy}  forecast={data?.occupancyForecast} type="occupancy"/>),
    },
    // {
    //   label: '3 Day Build',
    //   width: '110px',
    //   dataIndex: 'threeDayBuild',
    //   customRender: ((data, isSelected, activeColor) => <DayBuild data={data} isSelected={isSelected} activeColor={activeColor}/>)
    // },
    // {
    //   label: '7 Day Build',
    //   width: '110px',
    //   dataIndex: 'sevenDayBuild',
    //   customRender: ((data, isSelected, activeColor) => <DayBuild data={data} isSelected={isSelected} activeColor={activeColor}/>)
    // },
    // {
    //   label: '14 Day Build',
    //   width: '120px',
    //   dataIndex: 'fourteenDayBuild',
    //   customRender: ((data, isSelected, activeColor) => <DayBuild data={data} isSelected={isSelected} activeColor={activeColor}/>)
    // },
    {
      label: 'Occ gap',
      width: '110px',
      align: 'right',
      dataIndex: 'occupancyGap',
      customRender: ((data) => `${(data * 100).toFixed(0)}%`),
    },
    {
      label: 'Min Stay',
      width: '100px',
      align: 'right',
      rightClickRender : ((menuProps) => <RightClickMeu menuProps={menuProps} onAppyChanges={onAppyChanges} setSelectedDaysFunc={setSelectedDaysFunc}/>),
      customRender: ((data) => <RenderOverride data={data} mode="minStay" /> )
    },
    {
      label: 'MULTIPLIER',
      width: '120px',
      customRender: ((data) => <Multiplier value={data}/>)
    },
    {
      label: <CaretDownOutlined/>,
      width: '56px',
      customRender: ((data) => <MultiplierValue value={data}/>)
    },
  ];
  

  const tableColumn = adjustmentMenu === "property"  ? optimizeTableProperty : optimizeTableMarket;

  const onTableRowclick = useCallback((value) => {
    setSelectedDaysFunc(value)
  }, [setSelectedDaysFunc]);

  return (
    <OptimizeTableBody>
      <QuibbleTable isLoading={isLoading} greyed={disabledDates}  columns={tableColumn} data={showToTablePricing} onRowClick={onTableRowclick} keyData="date" selectedList={selectedDates}/>
    </OptimizeTableBody>
  )
};

export default OptimizeTable;
