import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BodyText, BodyTitle } from '../../../../../../components/ReusableComponents/Text/Text';
import MinMaxInput from './MinMaxInput';
import { useSelector } from 'react-redux';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { isEmpty } from 'lodash';
import { message } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const MinMaxTitle = styled(BodyTitle)`
  align-self: flex-start;
`;

const MinMaxLabelDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 24px 0 12px 0;
`;

const BodyTextLabel = styled(BodyText)`
  font-size: 18px;
  margin: 0 0 0 44px;
`;

const MonthSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DaySection = styled.div`
  margin-left: 20px;
`;

const MonthHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
  margin-top: -32px;
`;

const monthIndex = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const dayIndex = {
  7: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

const Minmax = () => {

  const currencySymbol = localStorage.getItem("currency");
  const { propertyPricingRules } = useSelector(({ property }) => property);
  const [propMinMax, setPropMinMax] = useState({});
  const [expandedMonths, setExpandedMonths] = useState({});
  
  const {activeProperty, minMaxPrice, setMinMaxPrice} = useContext(PriceAdjustContext);

  const toggleMonth = (month) => {
    setExpandedMonths((prev) => ({
      ...prev,
      [month]: !prev[month],
    }));
  };

  useEffect(() => {
    const limits = propertyPricingRules?.limits;
  
  
    if (limits?.length) {
      const newPropMinMax = limits.reduce((outputData, currentData) => {
        const monthData = {
          min: currentData.min,
          max: currentData.max,
          days: {}
        };
  
        if (currentData?.day) {
          const dayData = currentData.day;
          
          Object.keys(dayData).forEach((dayKey) => {
            monthData.days[dayKey] = {
              min: dayData[dayKey]?.min,
              max: dayData[dayKey]?.max
            };
          });
        }
  
        return {
          ...outputData,
          [currentData?.month]: monthData
        };
      }, {});
      
      setPropMinMax(newPropMinMax);
      return;
    }
  
    setPropMinMax([]);
  
  }, [propertyPricingRules]);   

  const MinMaxChange = useCallback((data, month, type, day = null) => {
    const key = day ? [month, day] : [month];
    
    if (data === null) {
      setMinMaxPrice((prev) => (
        {
          [activeProperty]: {
            ...prev[activeProperty],
            [key]: {
              ...prev?.[activeProperty]?.[key],
              [type]: data,
            }
          }
        }
      ));
  
      return;
    }
  
    const minMaxForMonth = propMinMax?.[month] || [null, null];
    const minMaxForDay = day ? (propMinMax?.[key] || [null, null]) : minMaxForMonth;
  
    if (type === 'min' && (minMaxForDay[0] === data || data === 0)) {
      setMinMaxPrice((prev) => {
        const prevCopy = { ...prev };
        delete prevCopy[activeProperty]?.[key]?.min;
        if (isEmpty(prevCopy[activeProperty]?.[key])) delete prevCopy[activeProperty]?.[key];
        if (isEmpty(prevCopy[activeProperty])) delete prevCopy[activeProperty];
        return prevCopy;
      });
      return;
    }
  
    if (type === 'max' && (minMaxForDay[1] === data || data === 0)) {
      setMinMaxPrice((prev) => {
        const prevCopy = { ...prev };
        delete prevCopy[activeProperty]?.[key]?.max;
        if (isEmpty(prevCopy[activeProperty]?.[key])) delete prevCopy[activeProperty]?.[key];
        if (isEmpty(prevCopy[activeProperty])) delete prevCopy[activeProperty];
        return prevCopy;
      });
      return;
    }
  
    setMinMaxPrice((prev) => {
      if (prev?.[activeProperty]?.[key]?.max && type === 'min' && data > prev?.[activeProperty]?.[key].max) {
        message.error("min cannot be greater than max");
        return prev;
      }
  
      if ((!prev?.[activeProperty]?.[key]?.max && minMaxForDay[1]) && type === 'min' && data > minMaxForDay[1]) {
        message.error("min cannot be greater than max");
        return prev;
      }
  
      if (prev?.[activeProperty]?.[key]?.min && type === 'max' && data < prev?.[activeProperty]?.[key]?.min) {
        message.error("max cannot be lesser than min");
        return prev;
      }
  
      if ((!prev?.[activeProperty]?.[key]?.min && minMaxForDay[0]) && type === 'max' && data < minMaxForDay[0]) {
        message.error("max cannot be lesser than min");
        return prev;
      }
  
      return {
        [activeProperty]: {
          ...prev[activeProperty],
          [key]: {
            ...prev?.[activeProperty]?.[key],
            [type]: data,
          }
        }
      };
    });
  }, [activeProperty, setMinMaxPrice, propMinMax]);  

  const showMinMax = useCallback(() => {
    return Object.keys(monthIndex).map((month) => {
      const isExpanded = expandedMonths[month];

      const monthMin = minMaxPrice?.[activeProperty]?.[month]?.min !== undefined
        ? minMaxPrice?.[activeProperty]?.[month]?.min
        : propMinMax?.[month]?.min;

      const monthMax = minMaxPrice?.[activeProperty]?.[month]?.max !== undefined
        ? minMaxPrice?.[activeProperty]?.[month]?.max
        : propMinMax?.[month]?.max;
  
      return (
        <MonthSection key={month}>
          <MonthHeader>
            <IconWrapper onClick={() => toggleMonth(month)}>
              {isExpanded ? <UpOutlined /> : <DownOutlined />}
            </IconWrapper>
            <MinMaxInput
              key={`month-${month}`}
              month={month}
              minMax={[monthMin, monthMax]}
              label={monthIndex[month]}
              onInputChange={(data, month, type) => MinMaxChange(data, month, type)}
            />
          </MonthHeader>
  
          {isExpanded && (
            <DaySection>
              {Object.keys(dayIndex)
                .sort((a, b) => {
                  if (a === '7') return -1;
                  if (b === '7') return 1;
                  return a - b;
                })
                .map((day) => {
                  const key = [month, day];
                  const updatedValueMin = minMaxPrice?.[activeProperty]?.[key]?.min !== undefined && minMaxPrice?.[activeProperty]?.[key]?.min !== null
                    ? minMaxPrice?.[activeProperty]?.[key]?.min
                    : propMinMax?.[month]?.days?.[day]?.min !== undefined && propMinMax?.[month]?.days?.[day]?.min !== null
                    ? propMinMax?.[month]?.days?.[day]?.min
                    : propMinMax?.[month]?.min; // Fallback to month's min if day value is not set

                  const updatedValueMax = minMaxPrice?.[activeProperty]?.[key]?.max !== undefined && minMaxPrice?.[activeProperty]?.[key]?.max !== null
                    ? minMaxPrice?.[activeProperty]?.[key]?.max
                    : propMinMax?.[month]?.days?.[day]?.max !== undefined && propMinMax?.[month]?.days?.[day]?.max !== null
                    ? propMinMax?.[month]?.days?.[day]?.max
                    : propMinMax?.[month]?.max; // Fallback to month's max if day value is not set

                  return (
                    <MinMaxInput
                      key={`${month}-${day}`}
                      month={month}
                      day={day}
                      minMax={[updatedValueMin, updatedValueMax]}
                      label={dayIndex[day]}
                      onInputChange={(data, month, type, day) => MinMaxChange(data, month, type, day)}
                    />
                  );
                })}
            </DaySection>
          )}
        </MonthSection>
      );
    });
  }, [MinMaxChange, activeProperty, minMaxPrice, propMinMax, expandedMonths]);  

  return (
    <Fragment>
       <MinMaxTitle>
        Min/Max Price
      </MinMaxTitle>
      <MinMaxLabelDiv>
        <BodyTextLabel>
          Minimum ({currencySymbol})
        </BodyTextLabel>
        <BodyTextLabel>
          Maximum ({currencySymbol})
        </BodyTextLabel>
      </MinMaxLabelDiv>
      {showMinMax()}
    </Fragment>
  )
}

export default Minmax;