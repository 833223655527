import React, { useCallback, useState, lazy, Suspense } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import QuibbleMenu from 'components/ReusableComponents/Menu/Menu';
import { CreditCardOutlined, LinkOutlined, LockOutlined, AlertOutlined , UserOutlined, ReadOutlined} from "@ant-design/icons";
import AccountInfo from "./components/AccountDetails";
import QuibbleSpinLoader from "components/ReusableComponents/Loader/QuibbleSpinLoader";
import { LayoutBody } from "components/ReusableComponents/Layout/LayoutBody";


import Subscriptions from "./components/Subscriptions";
import { darkScrollbar, lightScrollbar } from "../../../components/ReusableComponents/Scrollbar/Scrollbar";

const Connections = lazy(() => import('./components/Connections'));
const Password = lazy(() => import('./components/Password'));
const Users = lazy(() => import('./components/Users'));
const Alerts = lazy(() => import('./components/Alerts'));
const BulkReports = lazy(() => import('./components/BulkReports'));


const MyAccountBody = styled(LayoutBody)`
  display: flex;
  flex-direciton: row;
  justify-content: flex-start;
  height: calc(100vh - 64px);
  overflow: auto;
  ${ ({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar) }
`;

const AccountMenuDiv = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 0 0 24px;
`;

const menuItems = [
  {
    icon: <CreditCardOutlined/>, label: 'Subscriptions and Billing', key: 'subscriptions'
  },
  { icon: <LinkOutlined/>, label: 'Connections', key: 'connections' },
  { icon: <LockOutlined/>, label: 'Password', key: 'password' },
  { icon: <UserOutlined/>, label: 'Users', key: 'user' },
  { icon: <AlertOutlined/>, label: 'Alerts', key: 'alerts' },
  { icon: <ReadOutlined/>, label: 'Bulk Reports', key: 'bulkReports' },
];


const MyAccount = () => {

  const authData = useSelector(({ users: { authUserDetails } }) => authUserDetails);
  const [menuContent, setMenuContent] = useState('subscriptions');

  const onMenuChange = useCallback((value) => {
    setMenuContent(value);
  }, []);

  const showMenuContent = useCallback(() => {
    if (menuContent === 'connections') {
      return (
        <Suspense fallback={ null }>
          <Connections authData={ authData }/>
        </Suspense>
      );
    }
    if (menuContent === 'password') {
      return (
        <Suspense fallback={ null }>
          <Password authData={ authData }/>
        </Suspense>
      );
    }
    if (menuContent === 'user') {
      return (
        <Suspense fallback={ null }>
          <Users authData={ authData }/>
        </Suspense>
      );
    }

    if (menuContent === 'alerts') {
      return (
        <Suspense fallback={ null }>
          <Alerts authData={ authData }/>
        </Suspense>
      );
    }

    if (menuContent === 'bulkReports') {
      return (
        <Suspense fallback={ null }>
          <BulkReports authData={ authData }/>
        </Suspense>
      );
    }
    return <Subscriptions/>
  }, [menuContent, authData])

  return (
    <MyAccountBody>
      {Object.keys(authData).length ?
      <>
        <AccountInfo authData={authData} />
        <AccountMenuDiv>
        <QuibbleMenu menuItems={menuItems}  onMenuChange={onMenuChange} />
        {showMenuContent()}
        </AccountMenuDiv>
      </> :
      <QuibbleSpinLoader loading={true} />}
    </MyAccountBody>
  )
}

export default MyAccount;
