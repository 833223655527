import {jwtExpiration} from "../../src/constants/GlobalConstant";
import axios from "axios";
import { clearAllCache } from "../util/indexDbHelper";

let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });
export const auth = {
  isLoggedIn: async () => {
    let dataVal  = await service.get("/api/user/is-logged-in");
    jwtExpiration(dataVal);
    return dataVal;
  },
  userSignUp: async (data) => {
    let dataVal  =  await service.post("/api/signup", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  resendEmail: async (data) => {
      let dataVal  =  await service.post("/api/resend_email", data, {headers: {'Content-Type': 'application/json'}});
    return dataVal;
  },
  profileSetup: async (data) => {
    let dataVal  =  await service.post("/api/profile_setup", data, {headers: {'Content-Type': 'application/json'}});
    return dataVal;
  },
  dashboard: async (data) => {
    let dataVal  =  await service.post("/api/dashboard", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  logIn: async (data) => {
    let dataVal  =  await service.post("/api/login", data, {headers: {'Content-Type': 'application/json'}});
    await clearAllCache();
    return dataVal;
  },
  logOut: async () => {
    // await clearAllCache();
    let dataVal  =  await service.post("/api/logout",{}, {headers: {'Content-Type': 'application/json'}});
    return dataVal;
  },
  resetPassword: async (data) => {
    let dataVal  =  await service.post("/api/resetpassword",data, {headers: {'Content-Type': 'application/json'}});
    return dataVal;
  },
  logError: async (data) => {
    let dataVal = await service.post("/api/logging", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  }
};
export default service
