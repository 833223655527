import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import threedots from 'assets/images/market/table/threedots.svg'
import { Tooltip, message } from 'antd';
import useClickOutsideListener from 'customHooks/useClickOutsideListener';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMarketDetails, getMarketPricing, getPropertyDetails, getPropertyOverviewMetrics, getPropertyPricing } from 'appRedux/actions';
import { property } from 'services/property';
import { useData } from '../../../util/DataStore/DataContext';
import { getPropertyQueue } from '../../../appRedux/actions';


const DotButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
`;

const DotImg = styled.img`
  width: 24px;
  height: 4px;
`;

const MenuDiv = styled.div`
  width: 146px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MenuButton = styled.button`
  width: 100%;
  height: 36px;
  border: none;
  outline: none;
  background: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: ${({theme}) => theme.quibbleBlue};
  }
`;


const TableMenu = ({data, monthYear, type="market", otherFunctions}) => {

  const [showMenu, setShowMenu] = useState(false);
  const {state} = useData();
  const [queueLoad, setQueueLoad] = useState(false);



  const history = useHistory();
  const dispatch = useDispatch();

  const onDotClick = () => {
    setShowMenu((prev) => !prev);
  };

  const closeUserMenu = () => {
    setShowMenu(false);
  };


  const tooltipMenu = useCallback(() => {
    

    const onViewClick = () => {
      if (type === "market") {
        history.push({
          pathname: '/market-analytics/market-details', 
          state: {date: monthYear, marketName: data.key},
        })
      } else if (type === "property" || type === "actionable") {
        history.push({
          pathname: '/market-analytics/property-details',
          state: {date: monthYear, propertyId: data.id},
        })
      } 
    };

    const onAdjustclick = () => {
      if (type === "market") {
        history.push(
          {
            pathname: "/price-adjustment",
            state: {marketKey: data.key || '', date: monthYear},
          },);
    
          const month = monthYear.getMonth() + 1;
          const year =  monthYear.getFullYear();
    
          dispatch(getMarketPricing({month, year, market:data.key}));
          dispatch(getMarketDetails({params: { month, year, market: data.key}}));
      } else {
          history.push(
            {
              pathname: "/price-adjustment",
              state: {propertyId: data.id || '', date: monthYear, actionable: type === 'actionable'},
            },);
      
            const month = monthYear.getMonth() + 1;
            const year =  monthYear.getFullYear();
        
            dispatch(getPropertyPricing({month, year, propertyId: data.id}));
            dispatch(getPropertyDetails({propertyId: data.id, params: {month, year}}));
            dispatch(getPropertyOverviewMetrics({propertyId: data.id, params: {month, year, viewType: state.viewType}}));   
      }
    };

    const retrievePropertyQueue = () => {
      setTimeout(function() {
        dispatch(getPropertyQueue());
      }, 2000);
    }

    const onAddQueueClick = async() => {
      if (type === "market") {
        try {
          setQueueLoad(true);
          await property.addPropertyQueue({markets: [data?.key]});
          message.success(`Successfully added market into the queue`, 3);
          retrievePropertyQueue();
          setQueueLoad(false);
        } catch (e) {
          message.error(`Error adding marketinto the queue`, 3);
          setQueueLoad(false);
        }
        return;
      } else if (type === "property") {
        try {
          setQueueLoad(true);
          await property.addPropertyQueue({properties: [data?.id]});
          message.success(`Successfully added property into the queue`, 3);
          retrievePropertyQueue();
          setQueueLoad(false);
        } catch(e) {
          setQueueLoad(false);
          message.error(`Error adding property into the queue`, 3)
        }

      }
    };

    const onDeleteClick = async() => {
      try {
        setQueueLoad(true);
        await property.deletePropertyQueue({properties: [data?.id]});
        message.success(`Successfully deleted property into the queue`, 3);
        if (otherFunctions) otherFunctions(data?.id);
        setQueueLoad(false);
      } catch (e) {
        setQueueLoad(false);
        message.error(`Error deleting property into the queue`, 3)
      }
      // retrievePropertyQueue();
    };

    return (
      <MenuDiv >
        { type !== 'workingqueue' && <MenuButton onClick={onViewClick}>
          <BodyText>
            View
          </BodyText>
        </MenuButton>}
        <MenuButton onClick={onAdjustclick}>
          <BodyText>
            Adjust Price
          </BodyText>
        </MenuButton>
        {type === 'workingqueue' ? 
        <MenuButton onClick={onDeleteClick}>
           <BodyText>
           {queueLoad ? 'Removing property...' : 'Remove Queue'}
           </BodyText>
         </MenuButton>
        : type === 'actionable' ? null :
        <MenuButton onClick={onAddQueueClick}>
          <BodyText>
          {queueLoad ?  'Adding to Queue...' : 'Add to Queue'}
          </BodyText>
        </MenuButton>}
      </MenuDiv>
    )
  }, [data, dispatch, history, monthYear, type, state.viewType, otherFunctions, queueLoad])


  const {ref} = useClickOutsideListener(closeUserMenu);



  return (
    <Tooltip  placement="bottomRight" open={showMenu} showArrow={false} title={tooltipMenu()}>
      <DotButton ref={ref}  onClick={onDotClick}>
        <DotImg src={threedots} alt='threedots' />
      </DotButton>
    </Tooltip>
    
  )
}

export default TableMenu;