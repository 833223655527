import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import PricingGraph from './subComponents/OptimizeGraph/PricingGraph';
import GraphHeader from './subComponents/OptimizeGraph/GraphHeader';
import OptimizeGraphHeader from './subComponents/OptimizeGraph/OptimizeGraphHeader';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import GraphMultiplier from './subComponents/OptimizeGraph/GraphMultiplier';
import { PriceAdjustContext } from '../../PriceAdjustment';
import { lightScrollbar, darkScrollbar } from '../../../../components/ReusableComponents/Scrollbar/Scrollbar';
import SkLoaderOver from '../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';



const OptimizeGraphBody = styled.div`
  width: fit-content;
  height: 100%;
  min-width: 1400px;
  max-width: 1400px;
  background: ${({theme}) => theme.bodyBg};
  border-radius: 4px 0 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 16px;
  position: relative;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

`;

const OptimizeGraph = ({propertyPricing, isLoading}) => {
  const [showOptimize, setShowOptimize] = useState(true);
  const [showAdr, setShowAdr] = useState(true);
  const [showComps, setShowComps] = useState(true);

  const {graphMultiplier, setGraphMultiplier , monthYear} = useContext(PriceAdjustContext);
  
  return (
    <OptimizeGraphBody>
      <OptimizeGraphHeader showAdr={showAdr} setShowAdr={setShowAdr} showOptimize={showOptimize} setShowOptimize={setShowOptimize} showComps={showComps} setShowComps={setShowComps} graphMultiplier={graphMultiplier} setGraphMultiplier={setGraphMultiplier} />
      {propertyPricing?.length ? <> <GraphHeader showAdr={showAdr} showOptimize={showOptimize} showComps={showComps} /> <PricingGraph showAdr={showAdr} showOptimize={showOptimize} showComps={showComps}  propertyPricing={propertyPricing} monthYear={monthYear} /> </> : <BodyText>Loading data..</BodyText> }
      <GraphMultiplier propertyPricing={propertyPricing} graphMultiplier={graphMultiplier} />
      <SkLoaderOver isLoading={isLoading} type='full'/>
    </OptimizeGraphBody>
  )
}

export default OptimizeGraph;