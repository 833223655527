import React from 'react';
import quibbleLogo from '../../assets/images/quibblerm.png';



const AuthLogo = () => {
  return (
    <div className="app-signin-form-container logo-container">
      <div className="app-signin - logobox">
        <img className="app-signin-quibble-logo" src={quibbleLogo} alt="quibble-logo" />
        <h1 className="app-text signin-logotext">
            Next-Generation Revenue
            Management Software
        </h1>
      </div>
    </div>
  )
}

export default AuthLogo;
