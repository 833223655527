import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../../components/ReusableComponents/Card-Body/BodyCSS';
import FilterRange from './FilterRange';
import ReviewFilter from './ReviewFilter';
import { Button } from '../../../../components/ReusableComponents/Button/Button';
import BedroomFilter from './BedroomFilter';

const FilterBody = styled.div`
  min-width: ${({width}) => width};
  transition: all 0.15s linear;
  ${BodyCSS}
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${({padding}) => padding};
  gap: 24px;
  margin: 6px 0 0 0;
`;


const AnalyticsFilter = ({isFilterVisible, onFilterChange}) => {

  const [filterValues, setFilterValues] = useState({
    occupancy: [0, 100],
    adr:[0, 5000],
    revenue: [0, 20000],
    blockedDays: [0, 100],
    rating: [1,2,3,4,5],
    bedrooms: [0],
  });

  const onRangeChange = useCallback((property, value) => {
    setFilterValues((prev) =>  {
      const newPrev = {...prev, [property]: value}
      onFilterChange(newPrev);
      return newPrev;
    });
  }, [onFilterChange])

  const onStarChange = useCallback((checked, value) => {
    if (checked) {
      setFilterValues((prev) => {
        if (prev.rating.includes(value)) {
            onFilterChange(prev);
            return prev;
        };
        const newPrev = {...prev, rating: [...prev.rating, value]};
        onFilterChange(newPrev);
        return newPrev;

      });
      return;
    };

    setFilterValues((prev) => {
      if (prev.rating.includes(value)) {
        const newPrev = {...prev, rating: prev.rating.filter((val) => val !== value)};
        onFilterChange(newPrev);
        return newPrev;
      };
      onFilterChange(prev);
      return prev;
    });
  }, [onFilterChange]);

  const onBedroomChange = useCallback(
    (bedrooms) => {
      setFilterValues((prev) => {
        const newPrev = { ...prev, bedrooms };
        onFilterChange(newPrev);
        return newPrev;
      });
    },
    [onFilterChange]
  );

  const onReset = () => {
    const resetValues = {
      occupancy: [0, 100],
      adr: [0, 5000],
      revenue: [0, 20000],
      blockedDays: [0, 100],
      rating: [1, 2, 3, 4, 5],
      bedrooms: [0],
    };

    onFilterChange(resetValues);
    setFilterValues(resetValues);
  };

  return (
    <FilterBody width={isFilterVisible ? '256px' : '0px'} padding={isFilterVisible ? '16px' : '0px'}>
      <FilterRange filterValues={filterValues} property='occupancy' label='OCCUPANCY RANGE' onRangeChange={onRangeChange}/>
      <FilterRange filterValues={filterValues} property='adr' label='ADR RANGE' min={0} max={5000} onRangeChange={onRangeChange} isCurrency={true}/>
      <FilterRange filterValues={filterValues} property='revenue' label='REVENUE RANGE' min={0} max={20000} onRangeChange={onRangeChange} isCurrency={true}/>
      <ReviewFilter filterValues={filterValues} onStarChange={onStarChange}/>
      <FilterRange filterValues={filterValues} property='blockedDays' label='BLOCKED DAYS RANGE' min={0} max={100} onRangeChange={onRangeChange} />
      <BedroomFilter selectedBedrooms={filterValues.bedrooms} onBedroomChange={onBedroomChange} label="BEDROOMS" />
      <Button onClick={onReset}> Clear All</Button>
    </FilterBody>
  )
}

export default memo(AnalyticsFilter);
