import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';

const RentalChannelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const ChartContainer = styled.div`
  height: 220px;
  display: flex;
  padding: 10px 0;
`;

const RadarChartContainer = styled.div`
  width: 60%;
`;

const LegendsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 5px;
  padding: 20px 0;
`;

const Legend = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LegendTitle = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const MarketRating = styled.div`
  color: #B989F6;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const PortfolioRating = styled.div`
  color: #1BB3CD;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const AttributeRatings = (props) => {

  const [chartData, setChartData] = useState([]);
  const [isEmptyPortfolio, setIsEmptyPortfolio] = useState(true);
  const [overallMarketRating, setOverallMarketRating] = useState(0);
  const [overallPortfolioRating, setOverallPortfolioRating] = useState(0);
  const [marketPercentage, setMarketPercentage] = useState(0);
  const [portfolioPercentage, setPortfolioPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    setIsLoading(true)
    const rawData = props.data;

    if (rawData) {
      function calculatePercentage(ratings) {
        const countOver4_5 = ratings && Object.entries(ratings)
          .filter(([subject, rating]) => subject !== "overall" && rating > 4.5)
          .length;
        const totalRatings = ratings && Object.keys(ratings).length - (ratings.overall ? 1 : 0);
        return (countOver4_5 / totalRatings) * 100;
      }

      const marketOverall = rawData?.market.overall;
      const portfolioOverall = rawData?.portfolio.overall;

      const marketPercentageOver4_5 = calculatePercentage(rawData?.market);
      const portfolioPercentageOver4_5 = calculatePercentage(rawData?.portfolio);

      const mappedData = rawData && Object.keys(rawData.market)
        .filter(subject => subject !== "overall")
        .map(subject => {
            return {
              subject: subject.toUpperCase(),
              market: rawData.market[subject],
              portfolio: rawData.portfolio[subject],
              fullMark: 5
            };
        });

      setChartData(mappedData);
      setOverallMarketRating(marketOverall);
      setOverallPortfolioRating(portfolioOverall);
      setMarketPercentage(marketPercentageOver4_5.toFixed(2));
      setPortfolioPercentage(portfolioPercentageOver4_5.toFixed(2));
      setIsEmptyPortfolio(props.isEmptyPortfolio)
      setIsLoading(false);
    }

  }, [props.data, props.isEmptyPortfolio]);

  return (
    <>
    {isLoading ? <MHCardLoader /> :
      <RentalChannelContainer>
        <TitleContainer>
          <Title>Attribute Ratings</Title>
        </TitleContainer>
        <ChartContainer>
          <RadarChartContainer>
            <ResponsiveContainer>
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 5]} />
              <Tooltip />
              <Radar name="Market" dataKey="market" stroke="#B989F6" fill="#B989F6" fillOpacity={0.3} />
              {!isEmptyPortfolio &&
                <Radar name="Portfolio" dataKey="portfolio" stroke="#2ECADB" fill="#2ECADB" fillOpacity={0.3} />
              }
            </RadarChart>
            </ResponsiveContainer>
          </RadarChartContainer>
          <LegendsContainer>
            <Legend>
              <LegendTitle>Overall Rating:</LegendTitle>
              <MarketRating>{overallMarketRating}</MarketRating>
              {!isEmptyPortfolio &&
                <PortfolioRating>{overallPortfolioRating}</PortfolioRating>
              }
            </Legend>
            <Legend>
              <LegendTitle>Rated at Least 4.5 Overall:</LegendTitle>
              <MarketRating>{marketPercentage}%</MarketRating>
              {!isEmptyPortfolio &&
                <PortfolioRating>{portfolioPercentage}%</PortfolioRating>
              }
            </Legend>
          </LegendsContainer>
        </ChartContainer>
      </RentalChannelContainer>
    }
    </>
  );
}

export default AttributeRatings;