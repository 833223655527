import React, { useCallback, useMemo } from 'react';
import styled, {css} from 'styled-components';
import { useSelector} from 'react-redux';
import { FULL_MONTH_NAME, currentDate } from 'constants/GlobalConstant';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { formatDate } from 'util/localStorageHelper';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';



const SelectCalendarDiv = styled.div`
  width: 340px;
  height: 332px;
  padding: 16px;
  border: 1px solid ${({theme}) => theme.quibbleHoverGrey};
  background: ${({theme}) => theme.white};
  display: flex;
  align-self: center;
  flex-direction: column;
  font-family: Roboto;
`;

const MonthDiv = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({theme}) => theme.s3};
  color: ${({theme}) => theme.bodyFg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

const LeftArrow = styled(LeftOutlined)`
  visibility: ${({$setMonthYear}) => $setMonthYear ? 'block': 'hidden'}
`;

const RightArrow = styled(RightOutlined)`
  visibility: ${({$setMonthYear}) => $setMonthYear ? 'block': 'hidden'}
`;

const DayNamesDiv = styled.div`
  width: 100%;
  margin: 12px 0 0 0;
  padding: 0 12px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemProperties = css`
  width: 30px;
  font-size: ${({theme}) => theme.s2};
  min-width: 30px;
  max-width: 30px;
  font-weight: 400;
  text-align: center;

`;

const ItemSpan = styled.div`
  color: ${({theme}) => theme.disabledGrey};
  ${ItemProperties}
`;

const DateSpan = css`
  ${ItemProperties}
  font-family: Roboto;
  height: 30px;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${({opacity}) => opacity};
  color: ${({theme}) => theme.bodyFg};

  &:hover, &.active {
    background: ${({theme}) => theme.quibbleActiveBlue};
    color: ${({theme}) => theme.white};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    background: none;
    color: ${({theme}) => theme.bodyFg};
  }

  &.strikethrough {
    text-decoration: line-through;
  }
`;


const SelectCalendar = ({selectedDates=[], monthYear, setSelectedDaysFunc=()=>{}, disableFunc, showStrikeThrough=false, setMonthYear}) => {

  const {propertyPricing} = useSelector(({property}) => ({...property}));


  const calendarArray = useMemo(() => {
    const firstDay = new Date(monthYear.getFullYear(), monthYear.getMonth(), 1);
    const startDateCopy = new Date(firstDay);
    const firstSunday = new Date(startDateCopy.setDate(startDateCopy.getDate() - startDateCopy.getDay()));

    return [...Array(42).keys()].map((_, index) => {
      const firstSundayDate = new Date(firstSunday);
      return formatDate(new Date(firstSundayDate.setDate(firstSundayDate.getDate() + index)))
    })
  }, [monthYear]);

  const renderCalendar = useCallback(() => {

    const onCalendarClick = (data) => {
      setSelectedDaysFunc(data) 
    };

    const calendarArraySliced = [calendarArray.slice(0,7), calendarArray.slice(7,14), calendarArray.slice(14,21), calendarArray.slice(21,28), calendarArray.slice(28,35), calendarArray.slice(35,42)];
    return calendarArraySliced.map((slicedArray, index) => (
      <DayNamesDiv key={`${index}`}>
        {
          slicedArray.map ((data) => {
            const dataSplit = data.split('-');
            const disabledSpan = disableFunc ? disableFunc(data) ||  parseInt(dataSplit[1]) !==  monthYear.getMonth() + 1 ||  new Date(data) < new Date(currentDate) : parseInt(dataSplit[1]) !==  monthYear.getMonth() + 1 ||  new Date(data) < new Date(currentDate);

            const dateIndex = propertyPricing.findIndex((pricingData) => pricingData.date === data);

            const shouldApplyStrikeThrough = dateIndex !== -1 ? propertyPricing[dateIndex]?.booking?.length && showStrikeThrough : false;

            return <ButtonWithFunction 
              onClickFunc={onCalendarClick} 
              value={data} 
              ButtonStyle={DateSpan} 
              label={parseInt(dataSplit[2])} 
              className={`${selectedDates.includes(data) && 'active'} ${shouldApplyStrikeThrough&& 'strikethrough'}`}
              key={data} 
              disabled={disabledSpan}
            /> 
          })
        }
      </DayNamesDiv>
    ))
  }, [calendarArray, selectedDates, monthYear, setSelectedDaysFunc, disableFunc, propertyPricing, showStrikeThrough]);

  	

  const handleLeftArrowClick = () => {
    setMonthYear(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
  };

  const handleRightArrowClick = () => {
    setMonthYear(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
  };




  return (
      <SelectCalendarDiv className='select-calendar'>
      <MonthDiv>
        <LeftArrow onClick={handleLeftArrowClick} $setMonthYear={setMonthYear}/>
        {FULL_MONTH_NAME[monthYear.getMonth()]}
        <RightArrow onClick={handleRightArrowClick} $setMonthYear={setMonthYear}/>
      </MonthDiv>
        <DayNamesDiv>
          <ItemSpan>SUN</ItemSpan> <ItemSpan>MON</ItemSpan> <ItemSpan>TUE</ItemSpan> <ItemSpan>WED</ItemSpan> <ItemSpan>THU</ItemSpan> <ItemSpan>FRI</ItemSpan> <ItemSpan>SAT</ItemSpan>
        </DayNamesDiv>
        {renderCalendar()}
      </SelectCalendarDiv>
  )
}

export default SelectCalendar;