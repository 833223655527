import React, { Fragment }  from 'react';
import styled from 'styled-components';
import { BodyText } from 'components/ReusableComponents/Text/Text';
// import CheckBox from 'components/ReusableComponents/Checkbox/CheckBox';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { message } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


// const NoPMSBody = styled.div`
//   width: 460px;
//   display: flex;
//   flex-direction: column;
//   margin: 24px 0 0 0;
// `;

// const CheckBoxDiv = styled.div`
//   width: fit-content;
//   height: fit-content;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   gap: 8px;
// `;

const LineDiv = styled.div`
  width: 460px;
  height: 1px;
  border-top: 1px solid #E8E8E8;

  margin: 32px 0 16px 0;
  position: relative;
  text-align: center;
`;

const OrText = styled(BodyText)`
  position: absolute;
  top: -4px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  margin: auto;
  width: fit-content;
  height: fit-content;
  background: white;
`;

const NoPMSButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px;
`;

const NoPMS = ({noPMSChecked, setNoPMSChecked, onSubmitForm}) => {

  const history = useHistory();

  // const onCheckBoxChange = (checked) => {
  //   setNoPMSChecked(checked);
  // };

  const onFinishSignup = () => {
    message.success('User Signup Successful');
    history.push('/');
 };

  return (
    <Fragment>
      {/* <NoPMSBody>
        <BodyText>
          Don't have a PMS?
        </BodyText>
        <CheckBoxDiv>
          <CheckBox checked={noPMSChecked} onCheckBoxChange={onCheckBoxChange} />
          <BodyText>
            Sign up for Market Hunter Only
          </BodyText>
        </CheckBoxDiv>
      </NoPMSBody> */}
      <LineDiv>
        <OrText> or </OrText>
      </LineDiv>
      <NoPMSButton 
      // disabled={!noPMSChecked}
      onClick={onFinishSignup} >
        Finish Signup Without PMS
      </NoPMSButton>
    </Fragment>
  )
}

export default NoPMS;