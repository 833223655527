import React from 'react';
import styled from 'styled-components';
import { LoaderCSS } from './CommonCSS';


const CardLoaderDiv = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const LoadingMini = styled.div`
  width: 20%;
  height: 10%;
  border-radius: 100px;
  ${LoaderCSS}
`;

const LoadingMed = styled(LoadingMini)`
  width: 40%;
  ${LoaderCSS}
`;

const LoadingLarge = styled.div`
  width: 100%;
  height: 70%;
  border-radius: 10px;
  ${LoaderCSS}
`;

const CardLoader = () => {
  return (
    <CardLoaderDiv>
      <LoadingMini />
      <LoadingMed />
      <LoadingLarge />
    </CardLoaderDiv>
  )
}

export default CardLoader;