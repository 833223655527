import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import subscription from "../../services/subscription";
import {
  SUBSCRIBE,
  MANAGE_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_COMPLETE,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_COMPLETE,
  GET_SUBSCRIPTION_CARDS,
  GET_SUBSCRIPTION_CARDS_COMPLETE,
} from "constants/ActionTypes";


function* checkout({ payload }) {
  try {
    const response = yield call(subscription.checkout, { price: payload });
    window.location.href = response.url;
  } catch (error) {
    console.error(error);
  }
}

function* manageSubscriptions() {
  try {
    const response = yield call(subscription.manage, {});
    window.location.href = response.url;
  } catch (error) {
    console.error(error);
  }
}

function* getSubscriptionPlans() {
  try {
    const response = yield call(subscription.getPlans);
    yield put({ type: GET_SUBSCRIPTION_PLANS_COMPLETE, subscriptionPlans: response });
  } catch (error) {
    yield put({ type: GET_SUBSCRIPTION_PLANS_COMPLETE, error: error });
  }
}

function* getTransactions() {
  try {
    const response = yield call(subscription.getTransactions);
    yield put({ type: GET_TRANSACTIONS_COMPLETE, transactions: response.transactions });
  } catch (error) {
    yield put({ type: GET_TRANSACTIONS_COMPLETE, error: error, transactions: [] });
  }
}

function* getSubscriptionCards() {
  try {
    const response = yield call(subscription.getCards);
    yield put({ type: GET_SUBSCRIPTION_CARDS_COMPLETE, subscriptionCards: response });
  } catch (error) {
    yield put({ type: GET_SUBSCRIPTION_CARDS_COMPLETE, error: error, subscriptionCards: [] });
  }
}

export function* watchCheckout() {
  yield takeEvery(SUBSCRIBE, checkout);
}

export function* watchManangeSubscriptions() {
  yield takeEvery(MANAGE_SUBSCRIPTIONS, manageSubscriptions);
}

export function* watchGetSubscriptionPlans() {
  yield takeEvery(GET_SUBSCRIPTION_PLANS, getSubscriptionPlans);
}

export function* watchGetTransactions() {
  yield takeEvery(GET_TRANSACTIONS, getTransactions);
}

export function* watchGetSubscriptionCards() {
  yield takeEvery(GET_SUBSCRIPTION_CARDS, getSubscriptionCards);
}

/** Export all */
export default function* rootSaga() {
  yield all([
    fork(watchCheckout),
    fork(watchManangeSubscriptions),
    fork(watchGetSubscriptionPlans),
    fork(watchGetTransactions),
    fork(watchGetSubscriptionCards),
  ]);
}
