import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import CustomProgress from '../CustomProgress';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';

const RentalTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

const LabelContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 10px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  alig-items: center;
  justify-content: center;
  width: 75%;

  position: relative;
`;

const HoverInfo = styled.div`
  opacity: 0.9;
  position: absolute;
  top: -40px;
  left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #FFF;
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
  border-radius: 5px;

  font-family: Commissioner;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;

  padding: 5px;
`;

const MarketText = styled.div`
  color: #B989F6;
`;

const PortfolioText = styled.div`
  color: #1BB3CD;
`;

const processPercentageValue = (num) => {
  let ret = num * 100;
  return ret.toFixed(0);
}

const RentalType = (props) => {

  const [chartData, setChartData] = useState(null);
  const [isEmptyPortfolio, setIsEmptyPortfolio] = useState(true);

  const [isHovered, setIsHovered] = useState(false);
  const [hoverValue, setHoverValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)

    if (props.data) {
      const rawData = props.data;
      setChartData(rawData)
      setIsEmptyPortfolio(props.isEmptyPortfolio)
      setIsLoading(false);
    }
  }, [props.data, props.isEmptyPortfolio]);

  const setHoverOpen = (value) => {
    setIsHovered(true);
    setHoverValue(value);
  };

  const setHoverClose = () => {
    setIsHovered(false);
    setHoverValue(null);
  };

  return (
    <>
    {isLoading ? <MHCardLoader /> :
    <RentalTypeContainer>
      <TitleContainer>
        <Title>Rental Type</Title>
      </TitleContainer>
      <BodyContainer>
        <ProgressContainer>
          <LabelContainer>Entire Home</LabelContainer>
          <ProgressBarContainer onMouseEnter={() => setHoverOpen('entireHome')} onMouseLeave={setHoverClose}>
            <CustomProgress width={chartData && processPercentageValue(chartData.entireHome?.market?.share)} type='market' />
            {!isEmptyPortfolio &&
              <CustomProgress width={chartData && processPercentageValue(chartData.entireHome?.portfolio?.share)} type='portfolio' />
            }
            {(isHovered && hoverValue === 'entireHome') && (
              <HoverInfo>
                <MarketText>{chartData && chartData.entireHome?.market.count}&nbsp;({chartData && processPercentageValue(chartData.entireHome?.market?.share)}%)</MarketText>
                {!isEmptyPortfolio &&
                  <PortfolioText>
                    {chartData && chartData.entireHome?.portfolio.count}&nbsp;({chartData && processPercentageValue(chartData.entireHome?.portfolio?.share)}%)
                  </PortfolioText>
                }
              </HoverInfo>
            )}
          </ProgressBarContainer>
        </ProgressContainer>
        <ProgressContainer>
          <LabelContainer>Private Room</LabelContainer>
          <ProgressBarContainer onMouseEnter={() => setHoverOpen('privateRoom')} onMouseLeave={setHoverClose}>
            <CustomProgress width={chartData && processPercentageValue(chartData.privateRoom?.market?.share)} type='market' />
            {!isEmptyPortfolio &&
              <CustomProgress width={chartData && processPercentageValue(chartData.privateRoom?.portfolio?.share)} type='portfolio' />
            }
            {(isHovered && hoverValue === 'privateRoom') && (
              <HoverInfo>
                <MarketText>{chartData && chartData.privateRoom?.market.count}&nbsp;({chartData && processPercentageValue(chartData.privateRoom?.market?.share)}%)</MarketText>
                {!isEmptyPortfolio &&
                  <PortfolioText>
                    {chartData && chartData.privateRoom?.portfolio.count}&nbsp;({chartData && processPercentageValue(chartData.privateRoom?.portfolio?.share)}%)
                  </PortfolioText>
                }
              </HoverInfo>
            )}
          </ProgressBarContainer>
        </ProgressContainer>
        <ProgressContainer>
          <LabelContainer>Shared Room</LabelContainer>
          <ProgressBarContainer onMouseEnter={() => setHoverOpen('sharedRoom')} onMouseLeave={setHoverClose}>
            <CustomProgress width={chartData && processPercentageValue(chartData.sharedRoom?.market?.share)} type='market' />
            {!isEmptyPortfolio &&
              <CustomProgress width={chartData && processPercentageValue(chartData.sharedRoom?.portfolio?.share)} type='portfolio' />
            }
            {(isHovered && hoverValue === 'sharedRoom') && (
              <HoverInfo>
                <MarketText>{chartData && chartData.sharedRoom?.market.count}&nbsp;({chartData && processPercentageValue(chartData.sharedRoom?.market?.share)}%)</MarketText>
                {!isEmptyPortfolio &&
                  <PortfolioText>
                    {chartData && chartData.sharedRoom?.portfolio.count}&nbsp;({chartData && processPercentageValue(chartData.sharedRoom?.portfolio?.share)}%)
                  </PortfolioText>
                }
              </HoverInfo>
            )}
          </ProgressBarContainer>
        </ProgressContainer>
      </BodyContainer>
    </RentalTypeContainer>
    }
    </>
  );
}

export default RentalType;