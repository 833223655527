import {
  SUBSCRIBE,
  MANAGE_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_PLANS,
  GET_TRANSACTIONS,
  GET_SUBSCRIPTION_CARDS,
} from "../../constants/ActionTypes";

export const subscribe = (value) => {
  return {
    type: SUBSCRIBE,
    payload: value,
  };
};

export const manage = (value) => {
  return {
    type: MANAGE_SUBSCRIPTIONS,
    payload: value,
  };
};

export const getSubscriptionPlans = () => {
  return {
    type: GET_SUBSCRIPTION_PLANS,
  };
};

export const getTransactions = () => {
  return {
    type: GET_TRANSACTIONS,
  };
};

export const getSubscriptionCards = () => {
  return {
    type: GET_SUBSCRIPTION_CARDS,
  };
};