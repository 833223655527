import React from 'react';
import styled from 'styled-components';
import IncrementDecrementInput from './IncrementDecrementInput';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';

const BodyTextLabel = styled(BodyText)`
  font-size: 18px;
  margin: 0 0 0 44px;
`;

const MonthLabel = styled(BodyTextLabel)`
  margin: 0 0 0 0px;
`;

const MinMaxDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 32px 0;
  position: relative;
`;


const InputDiv = styled.div`
  width: 124px;
  height: fit-content;
  position: absolute; 
  left: 44px;
`;

const InputSecondDiv = styled(InputDiv)`
  height: fit-content;
  left: 194px;
`;



const MinMaxInput = ({label, month, day, minMax=[], onInputChange }) => {
  const onMinInputChange = (data) => {
    if(isNaN(data)) return;
    onInputChange(data, month, 'min', day);
  };

  const onMaxInputChange = (data) => {
    if(isNaN(data)) return;
    onInputChange(data, month, 'max', day);
  };


  return (
    <MinMaxDiv>
      <MonthLabel>
        {label}
      </MonthLabel>
      <InputDiv>
        <IncrementDecrementInput allowEmpty={true} inputValue={minMax[0]} onInputChange={onMinInputChange}/>
      </InputDiv>
      <InputSecondDiv>
        <IncrementDecrementInput allowEmpty={true} inputValue={minMax[1]} onInputChange={onMaxInputChange}/>
      </InputSecondDiv>
    </MinMaxDiv>
  )
}

export default MinMaxInput;