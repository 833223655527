import React from 'react';
import styled from 'styled-components';
import MetricChart from './subComponents/InsightsMetrics/MetricChart';

const InsightsMetricDiv = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 158px;
  min-height: 158px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;



const InsightsMetric = ({chartData, valueData, isLoading}) => {

  const chartTR = chartData.map((data) => {
    return {
      name:  data?.name,
      data: data?.["Total Rent"],
    } 
  });

  const chartAncillary = chartData.map((data) => {
    return {
      name:  data?.name,
      data: data?.["Ancillary Revenue"],
    } 
  });

  const chartOccupancy = chartData.map((data) => {
    return {
      name:  data?.name,
      data: data?.["Occupancy"],
    } 
  });

  const chartADR = chartData.map((data) => {
    return {
      name:  data?.name,
      data: data?.["ADR"],
    } 
  });

  return (
    <InsightsMetricDiv>
      <MetricChart key='total rent' title='total rent' value={valueData.totalRent.current} diffValue={valueData.totalRent.diff} metricData={chartTR} isLoading={isLoading}/>
      <MetricChart key='ancillary revenue' title='ancillary revenue' value={valueData.ancillary.current} diffValue={valueData.ancillary.diff} metricData={chartAncillary} isBlue={false} isLoading={isLoading}/>
      <MetricChart key='occupancy' title='occupancy' value={valueData.occupancy.current} diffValue={valueData.occupancy.diff} metricData={chartOccupancy} isLoading={isLoading} />
      <MetricChart key='average daily rate' title='average daily rate' value={valueData.adr.current} diffValue={valueData.adr.diff} metricData={chartADR} isBlue={false} isLoading={isLoading}/>
    </InsightsMetricDiv>
  )
}


export default InsightsMetric;