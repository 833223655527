import styled, {css} from 'styled-components';

export const AuthItemDiv = css`
  width: fit-content;
  height: fit-content;
  min-width: 400px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 40px;
`;

export const AuthTitle = styled.span`
  font-size: 36px;
  font-weight: 300;
  font-family: Montserrat;
`;

export const FootText = styled.span`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 16px 0;
`;

export const Link = styled.a`
  color: ${({theme}) => theme.quibbleBlue};
  font-size: 16px;

  &:hover{
    text-decoration: underline;
  }
`;

export const ImgProp = styled.img`
  image-rendering: -webkit-optimize-contrast;

`;

export const FormDiv = styled.form`
  width: 100%;
`;