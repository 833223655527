import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import quibbleRm from 'assets/images/quibblerm.png';
import rating from 'assets/images/markethunter/ratings.svg';
import roomtype from 'assets/images/markethunter/roomtype.svg';
import pax from 'assets/images/markethunter/pax.svg';
import bath from 'assets/images/markethunter/bath.svg';
import bed from 'assets/images/markethunter/bed.svg';
import door from 'assets/images/markethunter/door.svg';
import { ReactComponent as MapViewIcon } from 'assets/images/mapview.svg';
import { ReactComponent as ListViewIcon } from 'assets/images/listview.svg';
import { List, Button, InputNumber, Slider, Input, message } from 'antd';
import { RightOutlined, FilterOutlined, AimOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import CompetitorInformationModal from './CompetitorInformationModal';
import CompetitorReplacementFilter from './CompetitorReplacementFilter';
import { property } from '../../../../../../services/property';
import { showName } from '../../../../../../util/helper';
import QuibbleMenu from '../../../../../../components/ReusableComponents/Menu/Menu';
import CompetitorReplacementParameters from './CompetitorReplacementParameters';

const defaultProps = { 
  zoom: 12
};


const CompetitorReplacementListDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  background: #E8E8E8;
  margin: 16px 0;

  display: flex;
  padding: 8px;

  flex-grow: 1;

  .ant-btn {
    margin: 0 16px 0 0;
    padding: 0;
    width: 100px;
  }
`;

const SearchGroup = styled.div`
  width: 100%;
  margin: 0 6px;
  display: flex;
  align-items: center;
  flex: 1;

  .ant-slider {
    flex-grow: 1;
  }

  .ant-input-number-input {
    text-align: center;
    padding: 0;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-btn {
    background: white;
    height: 36px;
    width: 36px;
    margin-left: 10px;
  }
`;

const SearchTitle = styled.div`
  white-space: nowrap;
  margin-right: 8px;
`;

const ChangeViewContainer = styled.div`
  display: flex;

  width: 25%;
  justify-content: flex-end;

  margin: auto;
  padding-left: 75px;

  svg {
    margin: 5px;
  }

  .ant-btn {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    margin: 0;
  }
`;

const SelectCompetitorReplacementContainer = styled.div`
  display: flex;

  .ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 8px;
  }

  .ant-list-bordered {
    margin-top: 8px;
    border: 1px solid #E8E8E8;
  }
`;

const FilterAndParameterContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  padding: 16px;
  font-size: 16px;
  font-weight: 300;

  border-radius: 6px;
  border: 1px solid #E8E8E8;
  background: #FFF;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const CompetitorList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: calc(100vh - 150px);
  overflow-y: auto;
`;

const CompetitorListContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ThumbnailImage = styled.img`
  width: 120px;
  height: 60px;
  border-radius: 4px;
  margin-right: 6px;
`;

const CompetitorDetails = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  div {
    flex: 1;
    margin: 5px;
  }
`;

const PropertyName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MapContainer = styled.div`
  flex-grow: 1;
  position: relative;

  min-height: calc(100vh - 120px);
`;

const SelectedProperty = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  p {
    margin: 0;
    color: #fff;
    display: flex;
    padding: 8px var(--CompSub-Share-5, 16px);
    align-items: center;
    gap: 16px;

    border-radius: 100px;
    background: #9747FF;

    .ant-btn {
      margin: 0;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-btn-circle {
      width: 18px;
      min-width: 18px;
      height: 18px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;

const MapView = styled.div`
  height: 100%;
`;

const MapMarker = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #005B6A;
  background: #1BB3CD;

  p {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 12px;
    color: #fff;
  }
`;

const InfoCardContainer = styled.div`
  position: absolute;
  background-color: #FFF;
  border-radius: 6px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 270px;
  left: -125px;
  top: -270px;

  .ant-btn {
    margin: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-selected-marker {
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 180px;
  }
`;

const AdrText = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 4px;
  border-radius: 4px;
  background: #9747FF;
`;

const InfoCardInformationContainer = styled.div`
  padding: 10px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const DetailsContainer = styled.div`
  display: flex;
  padding: 10px 0;

  div {
    flex: 1;
    display: flex;
    color: #697273;
    text-align: center;
    font-family: Commissioner;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-right: 10px;
    img {
      margin-right: 2px;
    }
  }
`;

const RoomTypeContainer = styled.div`
  border-radius: 4px;
  background: #FFF;

  /* Button Shadow */
  box-shadow: 0px 1px 4px 0px rgba(145, 165, 169, 0.50);

  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;

  position: absolute;
  top: 140px;
  left: 10px;
`;

const RoomTypeText = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const SelectButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
  .ant-btn {
    width: 120px;
    margin-top: 8px;
  }
`;


const CompetitorReplacementList = ({ currentCompetitors, onCancel }) => {
  const { propertyCompetitors, isFetchingPropertyCompetitors, propertyDetails, propertyModel } = useSelector(({ property }) => property);
  const { latitude, longtitude, name: propertyName, competitors } = propertyDetails;
  const { zoom } = defaultProps;
  const { comparison } = propertyModel;

  const [competitorsList, setCompetitorsList] = useState([]);
  const [filteredCompetitors, setFilteredCompetitors] = useState([]);
  const [filteredCurrentCompetitors, setFilteredCurrentCompetitors] = useState([]);
  const [selectedCompetitor, setSelectedCompetitor] = useState(null);
  const [openFilter, setOpenFilter] = useState(true);
  const [viewType, setViewType] = useState('listView');
  const [radiusValue, setRadiusValue] = useState(100);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectMode, setSelectMode] = useState('intelCompSet');
  const [updatingCompetitors, setUpdatingCompetitors] = useState(false);

  const [activeFilterTab, setActiveFilterTab] = useState('parameters');

  const [typeOfPlace, setTypeOfPlace] = useState('any');
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [minStay, setMinStay] = useState(1);
  const [selectedBedrooms, setSelectedBedrooms] = useState([0]);
  const [selectedBeds, setSelectedBeds] = useState([0]);
  const [selectedRatings, setSelectedRatings] = useState([{min: 4.01, max: 5.00 }, {min: 3.01, max: 4.00 }, {min: 2.01, max: 3.00 }, {min: 1.01, max: 2.00 }, {min: 0.01, max: 1.00 }]);
  const [selectedBathrooms, setSelectedBathrooms] = useState([0]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  useEffect(() => {
    if (Array.isArray(propertyCompetitors) && propertyCompetitors !== null) {
      
      const currentCompetitorsIDs = (comparison || []).map(property => property?.airBnbId || '');
  
      const sanitizedCompetitors = propertyCompetitors.map((competitor) => ({
        ...competitor,
        reviews: (competitor?.reviews === "null" || competitor?.reviews === null) ? 0 : competitor?.reviews,
        adr: competitor?.adr || 0,
        bedrooms: competitor?.bedrooms || 0,
        beds: competitor?.beds || 0,
        bathrooms_int: competitor?.bathrooms_int || 0,
        rating: competitor?.rating || 0,
        avg_minnights: competitor?.avg_minnights || 0,
      }));
  
      const sortedCompetitors = sanitizedCompetitors.slice().sort((a, b) => (a?.comp_score || 0) - (b?.comp_score || 0));
      setCompetitorsList(sanitizedCompetitors);
  
      let filtered = sortedCompetitors;
  
      // Exclude current competitors from the list
      filtered = filtered.filter(competitor => !currentCompetitorsIDs.includes(competitor?.listing_id || ''));
  
      if (typeOfPlace !== 'any') {
        filtered = filtered.filter(competitor => competitor?.room_and_property_type === typeOfPlace);
      }
  
      if (minPrice !== undefined) {
        filtered = filtered.filter(competitor => competitor?.adr >= minPrice);
      }
  
      if (maxPrice !== undefined) {
        filtered = filtered.filter(competitor => competitor?.adr <= maxPrice);
      }
  
      // Filter by number of bedrooms
      if (!(selectedBedrooms.length === 1 && selectedBedrooms[0] === 0)) {
        filtered = filtered.filter(competitor => {
          const bedroomCount = parseInt(competitor?.bedrooms, 10);
          if (selectedBedrooms.includes(5)) {
            return bedroomCount >= 5 || selectedBedrooms.includes(bedroomCount);
          }
          return selectedBedrooms.includes(bedroomCount);
        });
      }
  
      // Filter by number of beds
      if (!(selectedBeds.length === 1 && selectedBeds[0] === 0)) {
        filtered = filtered.filter(competitor => {
          const bedCount = parseInt(competitor?.beds, 10);
          if (selectedBeds.includes(5)) {
            return bedCount >= 5 || selectedBeds.includes(bedCount);
          }
          return selectedBeds.includes(bedCount);
        });
      }
  
      // Filter by number of bathrooms
      if (!(selectedBathrooms.length === 1 && selectedBathrooms[0] === 0)) {
        filtered = filtered.filter(competitor => {
          const bathroomCount = parseInt(competitor?.bathrooms_int, 10);
          if (selectedBathrooms.includes(5)) {
            return bathroomCount >= 5 || selectedBathrooms.includes(bathroomCount);
          }
          return selectedBathrooms.includes(bathroomCount);
        });
      }
  
      // Filter by minimum nights
      const hasMinNights = filtered.some(competitor => competitor?.avg_minnights !== undefined && competitor?.avg_minnights !== null);
      if (hasMinNights && minStay !== undefined) {
        filtered = filtered.filter(competitor => competitor?.avg_minnights >= minStay);
      }
  
      // Filter by rating
      const hasRating = filtered.some(competitor => competitor?.rating !== undefined && competitor?.rating !== null);
      if (hasRating) {
        filtered = filtered.filter(competitor => {
          const rating = competitor?.rating || 0;
          return selectedRatings.some(range => rating >= range.min && rating <= range.max);
        });
      }
  
      setFilteredCompetitors(filtered);
    }
  }, [propertyCompetitors, isFetchingPropertyCompetitors, typeOfPlace, minPrice, maxPrice, minStay, selectedBedrooms, selectedBeds, selectedBathrooms, selectedRatings, selectedAmenities, comparison]);
  
  useEffect(() => {
    if (Array.isArray(competitors) && competitors !== null) {
      const sanitizedCurrentCompetitors = competitors.map((competitor) => ({
        ...competitor,
        reviews: (competitor.reviews === "null" || competitor.reviews === null) ? 0 : competitor.reviews,
      }));

      setFilteredCurrentCompetitors(sanitizedCurrentCompetitors);
    }
  }, [competitors]);

  const clearAllFilters = () => {
    setTypeOfPlace('any');
    setSelectedBedrooms([0]);
    setSelectedBeds([0]);
    setSelectedBathrooms([0]);
    setMinStay(1);
    setMinPrice();
    setMaxPrice();
    setSelectedRatings([{min: 4.01, max: 5.00 }, {min: 3.01, max: 4.00 }, {min: 2.01, max: 3.00 }, {min: 1.01, max: 2.00 }, {min: 0.01, max: 1.00 }]);
  };

  const handleInfoCardContainerClick = () => {
    if (selectMode !== 'intelCompSet')
      setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = (marker, source) => {
    if (source === 'propertyCompetitors') {
      setSelectMode('propertyCompetitors')
      setSelectedCompetitor(marker.listing_id);
    } else {
      setSelectMode('intelCompSet')
      setSelectedCompetitor(null);
    }
    setSelectedMarker(marker);
  };

  const closeInfoCard = () => {
    setSelectedCompetitor(null);
    setSelectedMarker(null);
  };

  const handleSelectCompetitor = (id) => {
    if (selectedCompetitor === id) {
      setSelectedCompetitor(null);
    } else {
      setSelectedCompetitor(id);
    }
  };

  const clickFilter = () => {
    setOpenFilter(!openFilter);
  };

  const changeViewType = (type) => {
    setSelectedCompetitor(null);
    setSelectedMarker(null);
    setViewType(type);
  }

  const onRadiusChange = (newValue) => {
    setRadiusValue(newValue);
  };

  const onSearch = () => {
    const filteredCompetitorsRadiusValue = competitorsList.filter(competitor => competitor.distance < radiusValue);
    setFilteredCompetitors(filteredCompetitorsRadiusValue);
  };

  const onUpdatingSuccess = useCallback(() => {
    onCancel();
  }, [onCancel])

  const onClickSelect = async () => {
    if (selectedCompetitor) {
      try {
        setUpdatingCompetitors(true);
  
        const body = {
          sourceId: currentCompetitors?.airBnbId || 0,
          targetId: selectedCompetitor,
          auto: false,
        };
        const propertyId = propertyDetails?.id || 0;
  
        if (!propertyId) {
          throw new Error("Invalid property ID");
        }
  
        const res = await property.replacePropertyCompetitor({ propertyId, body });
  
        if (res?.status === 200) {
          message.destroy();
          message.success('Updated competitors saved.');
          onUpdatingSuccess();
        } else {
          message.error('Updating competitors failed.');
        }
      } catch (e) {
        message.destroy();
        console.error(e);
        message.error('Updating competitors error.');
      } finally {
        setUpdatingCompetitors(false);
        onUpdatingSuccess();
      }
    } else {
      message.error('Please select a competitor replacement.');
    }
  };

  const renderCurrentPropertyMarker = (map, maps) => {
    const marker = new maps.Marker({
      position: { lat: latitude, lng: longtitude },
      map,
    });

    return marker;
  };

  const menuItems = [
    { label: 'Parameters', key: 'parameters' },
    { label: 'Attributes', key: 'attributes' },
  ];

  return (
    <CompetitorReplacementListDiv>
      <SearchContainer>
        <Button onClick={clickFilter} type="primary" icon={<FilterOutlined />}>Filter</Button>
        <SearchGroup>
          <SearchTitle>Search From</SearchTitle>
          <Input
            value={propertyName}
            suffix={
              <AimOutlined  />
            }
          />
        </SearchGroup>
        <SearchGroup>
          <SearchTitle>Radius</SearchTitle>
          <InputNumber
            min={1}
            max={100}
            value={`${radiusValue}km`}
            onChange={onRadiusChange}
          />
          <Slider
            min={1}
            max={100}
            onChange={onRadiusChange}
            value={typeof radiusValue === 'number' ? radiusValue : 0}
          />
           <Button
            type="text"
            shape="circle"
            icon={<SearchOutlined /> }
            onClick={onSearch}
          />
        </SearchGroup>
        <ChangeViewContainer>
          <Button
            type="text"
            shape="circle"
            icon={<ListViewIcon fill={viewType === 'listView' ? '#00BBD2' :'#B0B0B0'}/> }
            onClick={() => {
              changeViewType('listView')
            }}
          />
          <Button
            type="text"
            shape="circle"
            icon={<MapViewIcon fill={viewType === 'mapView' ? '#00BBD2' :'#B0B0B0'}/> }
            onClick={() => {
              changeViewType('mapView')
            }}
          />
        </ChangeViewContainer>
      </SearchContainer>
      { (viewType === 'mapView' && selectedMarker ) &&
        <SelectedProperty>Selected Property: 
          <p>
            {`${selectedMarker.name}: ADR $${selectedMarker.adr.toFixed(0)}`}
            <Button 
              onClick={(event) => {
                event.stopPropagation();
                closeInfoCard();
              }} 
              shape="circle" 
              icon={<CloseOutlined />} 
            />
          </p>
        </SelectedProperty>
      }
      <SelectCompetitorReplacementContainer >
        {openFilter && (
          <FilterAndParameterContainer>
            <QuibbleMenu
              menuItems={menuItems}
              onMenuChange={(key) => setActiveFilterTab(key)}
              selectedKey={activeFilterTab}
              shouldExpandButtons={true}
            />
            {activeFilterTab === 'attributes' && (
              <CompetitorReplacementFilter
                viewType={viewType}
                typeOfPlace={typeOfPlace}
                setTypeOfPlace={setTypeOfPlace}
                minPrice={minPrice}
                maxPrice={maxPrice}
                setMinPrice={setMinPrice}
                setMaxPrice={setMaxPrice}
                minStay={minStay}
                setMinStay={setMinStay}
                selectedBedrooms={selectedBedrooms}
                setSelectedBedrooms={setSelectedBedrooms}
                selectedBeds={selectedBeds}
                setSelectedBeds={setSelectedBeds}
                selectedRatings={selectedRatings}
                setSelectedRatings={setSelectedRatings}
                selectedBathrooms={selectedBathrooms}
                setSelectedBathrooms={setSelectedBathrooms}
                selectedAmenities={selectedAmenities}
                setSelectedAmenities={setSelectedAmenities}
                clearAllFilters={clearAllFilters}
              />
            )}

            {activeFilterTab === 'parameters' && <CompetitorReplacementParameters />}
          </FilterAndParameterContainer>
        )}
        
        {
          viewType === 'listView' ? 
          
          <CompetitorList>
            Select competitor replacement
            <List
              bordered
              size="large"
              dataSource={filteredCompetitors}
              renderItem={(item) => (
                <List.Item 
                  key={item?.listing_id}
                  onClick={() => handleSelectCompetitor(item?.listing_id)}
                  style={{
                    backgroundColor: selectedCompetitor && selectedCompetitor === item?.listing_id
                      ? '#C6A1F5'
                      : 'inherit',
                    color: selectedCompetitor && selectedCompetitor === item?.listing_id
                      ? '#fff'
                      : 'inherit',
                  }}>
                  <CompetitorListContainer>
                    <ThumbnailImage src={item?.image_url || quibbleRm} alt="property image"/>
                    <CompetitorDetails>
                      <PropertyName>{showName(item?.name, 20) || 'No Property'}</PropertyName>
                      <div>${item?.adr?.toFixed(2) || 0} Night</div>
                      <div>Bedroom: {item?.bedrooms || 0}</div>
                      <div>Rating: {item?.rating || 0}</div>
                      <div>Reviews: {item?.reviews || 0}</div>
                      <div>Comp Score: {item?.comp_score?.toFixed(2) || 0}</div>
                    </CompetitorDetails>
                    <RightOutlined />
                  </CompetitorListContainer>
                </List.Item>
              )}
            />
          </CompetitorList>
          
          :

          <MapContainer>
            <MapView>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM" }}
                defaultCenter={{lat: latitude, lng: longtitude}}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => renderCurrentPropertyMarker (map, maps)}
              >
                {filteredCurrentCompetitors.map((marker) => (
                  <MapMarker
                    key={marker.listing_id}
                    lat={marker.lat}
                    lng={marker.long}
                    onClick={() => handleMarkerClick(marker, 'intelCompSet')}
                    style={{ background: '#FFC805' }}
                  />
                ))}
                {filteredCompetitors.map((marker, index) => (
                  <MapMarker
                    key={marker.listing_id}
                    lat={marker.lat}
                    lng={marker.long}
                    onClick={() => handleMarkerClick(marker, 'propertyCompetitors')}

                    style={{ background: index < 10 ? '#9747FF': '#1BB3CD' }}
                  ><p>{index < 10 && marker.adr.toFixed(0)}</p></MapMarker>
                ))}
                {selectedMarker && (
                  <InfoCardContainer
                    onClick={handleInfoCardContainerClick}
                    lat={selectedMarker.lat}
                    lng={selectedMarker.long}
                  >
                    <AdrText>
                      ADR ${selectedMarker.adr}
                    </AdrText>
                    <Button 
                      onClick={(event) => {
                        event.stopPropagation();
                        closeInfoCard();
                      }} 
                      shape="circle" 
                      icon={<CloseOutlined />} 
                    />
                    <img className="image-selected-marker" src={selectedMarker?.image_url || quibbleRm} alt={selectedMarker?.name || 'No Name'}/>
                    <RoomTypeContainer>
                      <img src={roomtype} alt="roomtype"/>
                      <RoomTypeText>Entire Home</RoomTypeText>
                    </RoomTypeContainer>
                    <InfoCardInformationContainer>
                      <TitleContainer>
                        <Title>{showName(selectedMarker?.name, 20) || 'No Property'}</Title>
                      </TitleContainer>
                      <DetailsContainer>
                        <div>
                          <img src={pax} alt='pax' />{selectedMarker.accommodates || 0}
                        </div>
                        <div>
                          <img src={bath} alt='bath' />{selectedMarker.bathrooms || 0}
                        </div>
                        <div>
                          <img src={bed} alt='bed' />{selectedMarker.beds || 0}
                        </div>
                        <div>
                          <img src={door} alt='door' />{selectedMarker.bedrooms || 0}
                        </div>
                        <div>
                          <img src={rating} alt='rating' />{selectedMarker.rating || 0}({selectedMarker.reviews || 0})
                        </div>
                      </DetailsContainer>
                    </InfoCardInformationContainer>
                  </InfoCardContainer>
                )}
              </GoogleMapReact>
            </MapView>
          </MapContainer>
        }
      </SelectCompetitorReplacementContainer>
      <CompetitorInformationModal 
        open={modalVisible}
        onCancel={handleModalClose}
        selectedMarker={selectedMarker}
      />
      
      <SelectButtonDiv>
        <Button onClick={onClickSelect} disabled={selectedCompetitor === null || updatingCompetitors} type="primary">Select</Button>
      </SelectButtonDiv>
    </CompetitorReplacementListDiv>
  );
};

export default CompetitorReplacementList;