import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #D9D9D9;
  margin: 2px;
`;

const ProgressBar = styled.div`
  height: 10px;
  text-align: center;
  line-height: 30px;
  color: white;
`;

const CustomProgress = ( props ) => {
  return (
    <ProgressBarContainer >
      <ProgressBar style={{ width: `${props.width}%`, backgroundColor: props.type === 'market' ? '#C6A1F5' : '#2ECADB' }} />
    </ProgressBarContainer>
  );
}

export default CustomProgress;