import {
 CHECKOUT_NEW_SUBSCRIPTION,
 CHECKOUT_NEW_SUBSCRIPTION_SUCCESS,
 GET_CUSTOMER,
 UPGRATE_SUBSCRIPTION
} from "constants/ActionTypes";
import { UPGRATE_SUBSCRIPTION_SUCESS } from "../../constants/ActionTypes";


/** Generate a New Subscription with Freemium Plan */
export const checkoutNewSubscription = (data) => {

  return {
    type: CHECKOUT_NEW_SUBSCRIPTION,
    payload: data,
  };
};

export const checkoutNewSubscriptionSucess = (hostedPage) => {
  return {
    type: CHECKOUT_NEW_SUBSCRIPTION_SUCCESS,
    payload: hostedPage,
  };
};



/** Get Customer Data */
export const getCustomer = (data) => {
  /*//to delete
  console.log("2. en actions");
  console.log(data)*/

  return {
    type: GET_CUSTOMER,
    payload: data,
  };
};

/** Update Customer's Plan */
export const updateCustomersPlan = (data) => {

  return {
    type: UPGRATE_SUBSCRIPTION,
    payload: data,
  };
};

/** Update Customer's Plan SUCCESS */
export const updateCustomersPlanSucess = (data) => {
  /*//to delete
  console.log("2. en actions");
  console.log(data)*/

  return {
    type: UPGRATE_SUBSCRIPTION_SUCESS,
    payload: data,
  };
};

