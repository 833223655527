import styled from "styled-components";
import { Table } from "antd";
import { lightScrollbar, darkScrollbar } from "../../../components/ReusableComponents/Scrollbar/Scrollbar";


const GoalPlanningTable = styled(Table)`
  font-weight: 600;
  margin: 4px 0 0 0;
  padding: 0px;
  background: ${({theme}) => theme.bodyBg};
  overflow: hidden;
  height: fit-content;
  
  .ant-table-cell {
    text-align: center;
    font-size: ${({theme}) => theme.s3};
    font-weight: 500 ;
    background: transparent;
    color: ${({theme}) => theme.bodyFg};
    height: 44px;
  }


  .ant-table-thead {
    color: ${({theme}) => theme.bodyFg};

    .ant-table-cell {
      font-size: ${({theme}) => theme.s2};
      font-weight: 400;
    }
  }

  .ant-table.ant-table-small.ant-table-has-fix-left {
    ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar }
  }
  

  .ant-table-content {
    background: ${({theme}) => theme.bodyBg};
    color: ${({theme}) => theme.bodyFg};
    overflow-y: hidden;
    ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({theme}) => theme.layoutBg};
  }

  .ant-table-pagination.ant-pagination {
    margin: 32px 24px 0 0;
  }

  .ant-pagination-item-link {
    color: ${({theme}) => theme.bodyFg};
  }

  .ant-pagination-item a {
    font-family: Commissioner;
    color: ${({theme}) => theme.bodyFg};
  }

  .ant-pagination-item-active a {
    color: ${({theme}) => theme.black};
  }

  .ant-select-item {
    color: ${({theme}) => theme.bodyFg};
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: ${({theme}) => theme.bodyFg};
    font-weight: 600;
    background: ${({theme}) => theme.layoutBg};
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: ${({theme}) => theme.bodyFg};
    font-weight: 600;
    background: ${({theme}) => theme.layoutBg};  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent !important;
  }

  .ant-table-thead th.ant-table-column-sort {
    background-color: transparent !important;
  }

  .ant-table-column-sorter-inner {
    margin: 0 0 0 4px;
  }


`;

export default GoalPlanningTable;