import React from 'react';
import styled from 'styled-components';
import TextFormat from './subComponents/TextFormat';
import {BodyCSS} from 'components/ReusableComponents/Card-Body/BodyCSS.js';
import SkLoaderOver from '../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';

export const UpperMetricDiv = styled.div`
  ${BodyCSS};
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 10px;
  position: relative;
  font-family: Commissioner;
  
  margin: 0 16px 0 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SectionTitle = styled.h1`
  margin: 0px;
  font-family: Quicksand;
  font-size: ${({theme}) => theme.s5};
  color: ${({theme}) => theme.bodyFg};
  font-weight: 600;

  color: #283334;
  font-family: Commissioner;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
`;

const RevenueDiv = styled.div`
  width: ${({widthPercentage}) => widthPercentage}%;
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BlueLineDiv = styled.div`
  width: 100%;
  height: 16px;
  border-right: 1px solid ${({theme}) => theme.quibbleBlue};
  margin: 0 0 12px 12px; 

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BlueLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.quibbleBlue};
`;

const BarDiv = styled.div`
  width: 100%; 
  margin: 0 0 0 0;

  display: flex;
  flex-direction: column;
`;

const BarDivision = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BarStayed = styled.div`
  width: ${({widthPercentage}) => widthPercentage}%;
  height: 20px;
  background: ${({theme}) => theme.quibbleBlue};
`;

const BarFuture = styled(BarStayed)`
  opacity: 0.45;  
`;

const BarMissed = styled(BarStayed)`
  background: none;
  border: 2px solid ${({theme}) => theme.quibbleBlue};
  border-width: 2px 2px 2px 0;
  border-style: dashed;
`;

const NightContainer = styled(RevenueDiv)`
  height: auto;
  margin: 2px 0 0 0;
  justify-content: space-between;
`;

const NightDiv = styled(BarDiv)`
  width: fit-content;
  align-items: flex-start;
`;

const NightLabel = styled.h1`
  margin: 0px;
  font-weight: 700;
  color: ${({theme}) => theme.quibbleBlue};
  font-size: ${({theme}) => theme.s2};
  margin: 0 8px 0 0;
`;

const NightFuture = styled(NightLabel)`
  align-self: flex-end;
  text-align: right;
  margin: 0 0 0 0;
`;

const ProjectedDiv = styled(RevenueDiv)`
  width: 100%;
  height: 54px;
  margin: 16px 0 0 0;
`;

const ProjectedItem = styled(BarDiv)`
  width: 50%;
  height: 100%;
  align-items: center;
  font-weight: 400;
  font-size: ${({theme}) => theme.s2};
`;

const ProjectedItemItalic = styled(ProjectedItem)`
  font-style: italic;
`;

const ProjectedSpan = styled.span`
  margin: 2px 0 0 0;
`;

const ProjectedBookedSpan = styled.span`
  font-weight: 600;
`;

const RentAnci = styled(RevenueDiv)`
  width: 100%;
  height: 140px;
  border-radius: 4px;
  padding: 20px 0 20px 0;
  background: #EFEFEF;
`;

const RentAnciLine = styled.div`
  width: 1px;
  height: 94px;
  color: #E5E5E5;
  background: #E5E5E5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

const RentAnciTitle = styled(SectionTitle)`
  font-size: ${({theme}) => theme.s3};
`;

const RentAnciValues = styled(RevenueDiv)`
  height: fit-content;
  margin: 12px 0 0 0;
  width: 100%;
  justify-content: space-evenly;
`;

const RentAnciDiffs = styled(RentAnciValues)`
  margin: 8px 0 0 0;
`;



const MetricContainerTop = ({metricsData, monthYear, isFetchingMetricSummary}) => {

  const stayedPercentage = ((metricsData['totalRevenue'].stayedNights/metricsData['totalRevenue'].projectedValue) * 100).toFixed(0);
  const futurePercentage = ((metricsData['totalRevenue'].futureNights/metricsData['totalRevenue'].projectedValue) * 100).toFixed(0);
  const missedPercentage = (((metricsData['totalRevenue'].projectedValue - metricsData['totalRevenue'].value)/metricsData['totalRevenue'].projectedValue) * 100).toFixed(0);
  const sumOfStayedandFuture = Number(stayedPercentage) + Number(futurePercentage);

  return (
    <UpperMetricDiv>
      <SectionTitle>{metricsData['totalRevenue'].name || 'Total Revenue'}  </SectionTitle>
        <RevenueDiv widthPercentage={'100%'}>
          <TextFormat value={metricsData['totalRevenue'].value} label="booked" symbol={metricsData['totalRevenue'].symbol}/>
          <BlueLineDiv>
            <BlueLine />
          </BlueLineDiv>
        </RevenueDiv>
        <BarDiv>
          <TextFormat value={metricsData['totalRevenue'].projectedValue} symbol={metricsData['totalRevenue'].symbol} direction="row" size='horizontal' color='foreground' otherStyle='align-self: flex-end;' label='projected'/>
          <BarDivision>
            <BarStayed widthPercentage={stayedPercentage}/>
            <BarFuture widthPercentage={futurePercentage}/>
            <BarMissed widthPercentage={missedPercentage}/>
          </BarDivision>
          <NightContainer widthPercentage={sumOfStayedandFuture}>
            {  
            metricsData['totalRevenue'].stayedNights > 0 
            &&  
            <NightDiv>
              <NightLabel>
                STAYED NIGHTS
              </NightLabel>
              <TextFormat value={metricsData['totalRevenue'].stayedNights}  symbol={metricsData['totalRevenue'].symbol} size="small" label=""/>
            </NightDiv>}
            {
              metricsData['totalRevenue'].futureNights > 0 
              && 
              <NightDiv>
              <NightFuture>
                FUTURE NIGHTS
              </NightFuture>
              <TextFormat value={metricsData['totalRevenue'].futureNights} symbol={metricsData['totalRevenue'].symbol} size="small" label="" otherStyle='align-self: flex-end;' />
            </NightDiv>
            }
          </NightContainer>
        </BarDiv>
        <ProjectedDiv>
          <ProjectedItem>
            <TextFormat value={metricsData['totalRevenue'].diffValue} symbol={metricsData['totalRevenue'].symbol} size="medium" label='' type={metricsData['totalRevenue'].diffSign}/>
            <ProjectedSpan><ProjectedBookedSpan>booked</ProjectedBookedSpan> vs</ProjectedSpan>
            {monthYear(1)}
          </ProjectedItem>
          <ProjectedItemItalic>
            <TextFormat value={metricsData['totalRevenue'].projectedDiff} symbol={metricsData['totalRevenue'].symbol} size="medium" label='' type={metricsData['totalRevenue'].projectedDiffSign}/>
            <ProjectedSpan><ProjectedBookedSpan>projected</ProjectedBookedSpan> vs</ProjectedSpan>
            {monthYear(1)}
          </ProjectedItemItalic>
        </ProjectedDiv>
        <RentAnci>
          <RentAnciLine />
          <ProjectedItem>
            <RentAnciTitle>RENT</RentAnciTitle>
            <RentAnciValues >
              <TextFormat value={metricsData['totalRent'].value} symbol={metricsData['totalRent'].symbol} size="medium" label='booked'/>
              <TextFormat value={metricsData['totalRent'].projectedValue} symbol={metricsData['totalRent'].symbol} size="medium" color='foreground' label='projected'/>
            </RentAnciValues>
            <RentAnciDiffs >
              <TextFormat value={metricsData['totalRent'].diffValue}  symbol={metricsData['totalRent'].symbol} type={metricsData['totalRent'].diffSign} size="small"/>
              <TextFormat value={metricsData['totalRent'].projectedDiff} symbol={metricsData['totalRent'].symbol} type={metricsData['totalRent'].projectedDiffSign} size="small"/>
            </RentAnciDiffs>
          </ProjectedItem>
          <ProjectedItem>
            <RentAnciTitle>ANCILLARY</RentAnciTitle>
            <RentAnciValues >
              <TextFormat value={metricsData['ancillaryRevenue'].value} symbol={metricsData['ancillaryRevenue'].symbol} size="medium" label='booked'/>
              <TextFormat value={metricsData['ancillaryRevenue'].projectedValue} symbol={metricsData['ancillaryRevenue'].symbol} size="medium" color='foreground' label='projected'/>
            </RentAnciValues>
            <RentAnciDiffs >
              <TextFormat value={metricsData['ancillaryRevenue'].diffValue}  symbol={metricsData['ancillaryRevenue'].symbol} type={metricsData['ancillaryRevenue'].diffSign} size="small"/>
              <TextFormat value={metricsData['ancillaryRevenue'].projectedDiff} symbol={metricsData['ancillaryRevenue'].symbol} type={metricsData['ancillaryRevenue'].projectedDiffSign} size="small"/>
            </RentAnciDiffs>
          </ProjectedItem>
        </RentAnci>
        <SkLoaderOver isLoading={isFetchingMetricSummary} />
    </UpperMetricDiv>
  )
}

export default MetricContainerTop;