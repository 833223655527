import { DELETE_EVENT_DETAILS, GET_EVENTS_TABLE, GET_EVENT_DETAILS, UPDATE_EVENT_DETAILS, UPDATE_EVENT_DETAILS_SUCCESS } from "../../constants/ActionTypes"


export const getEventsTable = (values) => {
  return {
    type: GET_EVENTS_TABLE,
    payload: values,
  }
};

export const getEventDetails = (values) => {
  return {
    type: GET_EVENT_DETAILS,
    payload: values,
  }
};

export const updateEventDetails = (value) => {
  return {
    type: UPDATE_EVENT_DETAILS,
    payload: value,
  };
};

/*** update events status success*/
export const updateEventDetailsMessage = (response) => {
  return {
    type: UPDATE_EVENT_DETAILS_SUCCESS,
    payload: response
  };
};

export const deleteEventDetail = (eventId) => {
  return {
    type: DELETE_EVENT_DETAILS,
    payload: eventId,
  }
}
