import React, { useCallback, useState, memo } from 'react';
import styled, {css} from 'styled-components';
import { debouncer } from '../../../../util/helper';
import { useDispatch, useSelector } from "react-redux";
import { searchCityV2, getCityDetailsV2, clearCity } from '../../../../appRedux/actions';
import { Select } from 'antd';
import { Button } from '../../../../components/ReusableComponents/Button/Button';
import {SearchOutlined} from '@ant-design/icons';
import { media } from '../../../../styles/stylesheet';

const onTriggerCSS = css`
  position: absolute;
  margin: 0 0 0 0;
  top: 80px;
  left: 42px;

  ${media.bpm} {
    top: 88px;
    left: 0;
    right: 0;
    margin: auto;
    align-self: center;
    width: 354px;
    z-index: 100;
  }

  ${media.bpxxs} {
   width: 280px;
  }
`;

const MarketHunterSearchDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0 0 0;
  align-items: center;
  z-index: 1000;

  ${({searchtrigger}) => searchtrigger }

  .ant-select-arrow {
    button {
      background: #fff !important;
      color: grey;
      margin-right: -9px;
    }
  }
`;

const MarketHunterSearchInput = styled(Select)`
  width: 320px;
  border-radius: none;
  text-align: left;

  ${media.bpxxs} {
    width: 246px;
   }
`;

const MarketHunterSearchButton = styled(Button)`
  width: 34px;
  height: 34px;
  margin: 0 0 0 4px;
`;

const NoResultDiv = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: ${({theme}) => theme.bodyFg};
`;


const MarketHunterSearch = ({changeSetTrigger, searchTrigger, sidebarCollapsed, toggleCollapseNav}) => {

  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  const {
    marketHunterSearchCities,
  } = useSelector(({ businessTools }) => businessTools);

  const onInputChange = useCallback((data) => {
    setSearchText(data);
    if (data !== '')
      dispatch(searchCityV2(data));
  }, [dispatch])


  const searchTriggerProps = useCallback(() => {
    if (searchTrigger) {
      return onTriggerCSS;
    }

    return undefined;
  }, [searchTrigger])

  const handleChange = (searchValue) => {
    dispatch(clearCity());
    dispatch(getCityDetailsV2(searchValue));
    changeSetTrigger();

    if (!sidebarCollapsed) {
      toggleCollapseNav();
    }

  };

  const onClearclick = () => {
    setSearchText('')
  }

  const showSearchOptions = useCallback(() => (
    marketHunterSearchCities.map((city) => {
      return {
        label: `${city.city}, ${city.state}, ${city.country}`,
        value: city._id,
      }
    })) , [marketHunterSearchCities])

  const showNoResultAndButton = useCallback(() => {
    if (searchText.split(' ').join('').length && !marketHunterSearchCities.length ) {
      return {
        noResult: <NoResultDiv> No Result Found </NoResultDiv>,
        button: <MarketHunterSearchButton> X </MarketHunterSearchButton>
      }
    }

    return {
      noResult: null,
      button: <MarketHunterSearchButton onClick={onClearclick} type="primary" > <SearchOutlined /> </MarketHunterSearchButton>
    }
  }, [marketHunterSearchCities.length, searchText])

  const debouncedOnType = debouncer(onInputChange, 300);

  return (
    <MarketHunterSearchDiv searchtrigger={searchTriggerProps()}>
        <MarketHunterSearchInput
          suffixIcon={showNoResultAndButton().button}
          showSearch
          placeholder="Search City"
          defaultActiveFirstOption={false}
          // showArrow={false}
          filterOption={false}
          onChange={handleChange}
          onSearch={debouncedOnType}
          notFoundContent={showNoResultAndButton().noResult}
          options={showSearchOptions().map((d) => d)}
        />

    </MarketHunterSearchDiv>
  )
}

export default memo(MarketHunterSearch);