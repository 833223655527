export const getMarketsClassification = (markets) => {

  const marketData = markets.reduce((outputData, currentData) => {

    const withMapMarketOcc = currentData.properties.map((data) => ({...data, marketOccupancy: currentData.currentOccupancy, city: currentData.market}))
  
    return {
      star: [...outputData.star, ...withMapMarketOcc.filter((data) => (data.classification === 'star'))],
      underpriced: [...outputData['underpriced'], ...withMapMarketOcc.filter((data) => (data.classification === 'underpriced'))],
      overpriced: [...outputData['overpriced'], ...withMapMarketOcc.filter((data) => (data.classification === 'overpriced'))],
      productIssues: [...outputData['productIssues'], ...withMapMarketOcc.filter((data) => (data.classification === 'issue'))],
      new: [...outputData['new'], ...withMapMarketOcc.filter((data) => (data.classification === 'new'))],
    }
  }, {star: [], underpriced: [], overpriced: [], productIssues: [], new: [] });

  return marketData;
  
};

export default getMarketsClassification;