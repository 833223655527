import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import QuibbleLogo from 'assets/images/generic/logo.svg';
import MiniLogo from 'assets/images/MiniLogo.svg'


const NavBarLogoDiv = styled.div`
  width: 100%;
  height: 68px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: ${({theme,sidebarCollapsed}) => (sidebarCollapsed ===false ? `1px solid ${theme.quibbleHoverGrey}`: 'none')}
`;

const CollapseButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  font-size: ${({theme}) => theme.s6};
  color: ${({theme}) => theme.bodyFg};
  cursor: pointer;

  position: absolute;
  top: 16px;
  bottom: 0px;
  left: unset;
  right: 8px;
`;


const LogoSection = ({sidebarCollapsed}) => {

  const history = useHistory();

  const goHome = () => {
    history.push('/home')
  }
  return (
    <NavBarLogoDiv sidebarCollapsed={sidebarCollapsed}>
      {!sidebarCollapsed && <img src={QuibbleLogo} alt="quibbleLogo" onClick={goHome}/>}
      <CollapseButton>
        {/* <MenuFoldOutlined /> */}
        {sidebarCollapsed && <img src={MiniLogo} width="40px" height="40px" alt="quibbleLogo" onClick={goHome}/>}
      </CollapseButton>
   </NavBarLogoDiv>
  )
}

export default LogoSection;
