import styled from "styled-components";
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';


export const PropertyMenuDiv = styled.div`
  width: 100%;
  height: fit-content;
  min-width: 1776px;
  margin: 24px 0 0 0;
`;


export const ComparisonInfo = styled.div`
  ${BodyCSS}
  min-width: 340px;
  height: 100%;
  margin: 0 0 0 0; 
  padding: 24px; 
  border: 1px solid ${({theme}) => theme.dirtyGrey};
  border-radius: unset;
  box-shadow: none;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`;