import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import DatePickerWithFunction from 'components/ReusableComponents/DatePicker/DatePickerWithFunction';
import { FULL_MONTH_NAME } from 'constants/GlobalConstant';
import moment from 'moment';
import { getMarketDetails, getMarketPricing, getMarketPropertyTable } from '../../../../appRedux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import searchIcon from 'assets/images/generic/search.svg';
import dollarIcon from 'assets/images/override/dollar.svg';
import { MarketFilterItemDiv, SearchImage, SearchInput } from '../../sharedComponents/MarketPropertyFilter/MarketPropertyFilter';
import { pickerMonthYear } from '../../../../constants/GlobalConstant';


const FilterBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DatePickerStyle = css`
  width: 200px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  margin: 0 0 0 0;
  font-family: Commissioner;
`;

const MarketDetailItemDiv = styled(MarketFilterItemDiv)`
  gap: 10px;
`;

const MarketDetailSearchDiv = styled(SearchImage)`
  right: 72px;
`;

const DollarButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  cursor: pointer;
`;  

const MarketDetailFilter = ({monthYear, setMonthYearVal, market, setSearchStringFunc}) => {

  const {date, marketName} = market || {};
  const { marketDetails} = useSelector(({ markets }) => markets);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!marketName) history.push('/home');
  }, [marketName, history]);

  //for first load
  useEffect(() => {
    if(date) {
      const month = date.getMonth() + 1;
      const year =  date.getFullYear();
      setMonthYearVal(date);
      dispatch(getMarketDetails({params: { year: year, market: marketName}}));
      dispatch(getMarketPropertyTable({params: { month: month, year: year, market: marketName}}));
    }
  }, [date, marketName ,dispatch, setMonthYearVal]);


  //on select month
  const onDatePickerChange = (value) => {
    const dateSplit = value.split('-');
    const month = FULL_MONTH_NAME.indexOf(dateSplit[0]) + 1;
    // const formatMonth = month < 9 ? `0${month}` : month; 
    // const newDate = `${dateSplit[1]}-${formatMonth}-01`;
    const selectedDate = new Date(dateSplit[1], month-1, 1);
    setMonthYearVal(selectedDate);
    dispatch(getMarketDetails({params: { year: Number(dateSplit[1]), market: marketName}}));
    dispatch(getMarketPropertyTable({params: { month: month, year: Number(dateSplit[1]), market: marketName}}));
  };

  const onInputChange = (e) => {
    e.preventDefault();
    const searchString = e.target.value;
    setSearchStringFunc(searchString);
  };

  const onDollarCLick = () => {
    history.push(
      {
        pathname: "/price-adjustment",
        state: {marketKey: marketDetails[0]?.key || '', date: monthYear},
      },);

      const month = monthYear.getMonth() + 1;
      const year =  monthYear.getFullYear();

      dispatch(getMarketPricing({month, year, market: marketDetails[0]?.key}));
      dispatch(getMarketDetails({params: { year, market: marketDetails[0]?.key}}));
  };

  return (
    <FilterBody>
      <DatePickerWithFunction   DatepickerStyle={DatePickerStyle} picker="month" format={"MMMM-YYYY"}  value={moment(pickerMonthYear(monthYear), 'MMMM-YYYY')} onChangeFunc={onDatePickerChange} />
      <MarketDetailItemDiv>
        <SearchInput type="search" placeholder="Search" onChange={onInputChange}/>
        <MarketDetailSearchDiv src={searchIcon}  alt="search icon"/>
        <DollarButton onClick={onDollarCLick}>
          <img src={dollarIcon} alt="dollar icon" width={"40px"}  heigth="40px"/>
        </DollarButton>
      </MarketDetailItemDiv>
    </FilterBody>
  )
}

export default MarketDetailFilter;