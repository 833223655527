import React, { useCallback, useState, Suspense, lazy, Fragment } from 'react';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import QuibbleLogMini from 'assets/images/quibbleLogin.svg';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { Link } from 'react-router-dom';

import Notifications from './components/Notifications';
import { foldIconStyle } from '../../components/ReusableComponents/Icons/Icons';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import WorkingQueue from './components/WorkingQueueMenu';


const HeaderDropMenu = lazy(() => import('./components/HeaderV3Menu'));
const ChangeViewModal = lazy(() => import('./components/ChangeViewModal'));


const HeaderContainer = styled.div`
  width: 100%;
  min-height: 60px;
  height: 60px;
  background: ${({theme}) => theme.bodyBg};
  box-shadow: 0px 4px 18px 0px rgba(145, 165, 169, 0.20);
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
`;

const HeaderTitleDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items:center;
`;

const HeaderTitle = styled(BodyText)`
  font-weight: 400;
`;

const HeaderForwardSlash = styled(HeaderTitle)`
  color: #D9D9D9;
  margin: 0px 4px;
`;

const HeaderLink = styled(Link)`
  color: ${({theme}) => theme.quibbleBlue};
  font-size: ${({theme}) => theme.s4};
  font-family: Commissioner;
  font-weight: 400;

  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
  }
`;

const HeaderMenuDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;

  border: none;
  outline: none;
  background: inherit;
`;

const HeaderMenu = styled(HeaderMenuDiv)`
  cursor: pointer;
  padding: 0 4px;
`;

const Brandlogo = styled.img`
  background: white;
  border-radius: 100%;
  border: 1px solid ${({theme}) => theme.quibbleBlue};
`;

const DownLogo = styled(DownOutlined)`
  color: ${({theme}) => theme.bodyFg};
  font-size: 10px;
  margin: 2px 0 0 0;
`;

const RightDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const MenuFold = styled(MenuFoldOutlined)`
  ${foldIconStyle};
`;
const MenuUnFold = styled(MenuUnfoldOutlined)`
  ${foldIconStyle};
`;

const QuibbleHeader = ({onToggleTheme, toggleCollapseNav, sidebarCollapsed}) => {
  const { eventDetails, isFetchingEventDetails } = useSelector(({ events }) => events);

  const storeData = useSelector(({users:{authUserDetails}, common, property: {propertyDetails, isFetchingPropertyDetails}, markets: {marketDetails, isFetchingMarketDetails}}) =>
    ({...authUserDetails, ...common,  ...propertyDetails, isFetchingPropertyDetails, isFetchingMarketDetails, marketDetails, }));
  const {first_name, brand_logo, pathname, name: propertyName, isFetchingPropertyDetails, marketDetails,  isFetchingMarketDetails } = storeData;

  const [showDrop, setShowDrop] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  // const[monthYear] = useState(new Date());


  const onHeaderDropCollapse = useCallback(() => {
    setShowDrop(false);
  }, []);

  const onHeaderDrop = useCallback(() => {
    setShowDrop(prev =>!prev);
  }, []);

  const hideModal = useCallback(() => {
		setShowViewModal(false);
	}, []);

  const showViewModalFunc = useCallback(() => {
    setShowViewModal(true);
  },[])

  const showTitle = useCallback(() => {

    const titleLookUp = {
      '/home': ['Dashboard'],
      '/setting/profile': ['My Account'],
      '/setting/profile/plan': [<HeaderLink to="/setting/profile">My Account</HeaderLink>, 'Plans'],
      '/setting/profile/plan/payment': [<HeaderLink to="/setting/profile">My Account</HeaderLink>, <HeaderLink to="/setting/profile/plan">Plans</HeaderLink>, 'Payment'],
      '/setting/profile/edit-profile':[<HeaderLink to="/setting/profile">My Account</HeaderLink>, 'Edit Profile'],
      '/market-analytics/property': ['Property'],
      '/market-analytics/market': ['Market'],
      '/market-analytics/property-details': [<HeaderLink to="/market-analytics/property">Property</HeaderLink>, isFetchingPropertyDetails ? 'Fetching Property' : propertyName],
      '/market-analytics/market-details': [<HeaderLink to="/market-analytics/market">Market</HeaderLink>, isFetchingMarketDetails ? 'Fetching Market' : marketDetails[0]?.key],
      '/market-analytics/market-property-details': [<HeaderLink to="/market-analytics/market">Market</HeaderLink>, isFetchingMarketDetails ? 'Fetching Market' : <HeaderLink to={{ pathname: "/market-analytics/market-details", state: {date: new Date(), marketName: marketDetails[0]?.key }}}>{marketDetails[0]?.key}</HeaderLink>,  isFetchingPropertyDetails ? 'Fetching Property' : propertyName],
      '/revenue/pricing-calendar': ['Pricing Calendar'],
      '/revenue/events-pricing': ['Events Pricing'],
      '/revenue/events-pricing/new': [<HeaderLink to="/revenue/events-pricing">Events Pricing</HeaderLink>, 'Add Event'],
      '/revenue/events-pricing/edit': [<HeaderLink to="/revenue/events-pricing">Events Pricing</HeaderLink>, 'Edit Event'],
      '/revenue/events-pricing/event': [<HeaderLink to="/revenue/events-pricing">Events Pricing</HeaderLink>, isFetchingEventDetails ? 'Fetching Event' : eventDetails?.name],
      '/revenue/bookingFeed':['Booking Feed'],
      '/businessTools/property-optimize': ['Optimize Property'],
      '/main/analytics/portfolio-analytics': ['Portfolio Analytics'],
      '/price-adjustment': ['Price Adjustment'],
      '/settings/listings': ['Listings'],
      '/settings/parameters': ['Parameters'],
      '/workingqueue' : ['Working Queue'],
      '/businessTools/planning': ['Goal Planning'],
      '/setting/myPortfolio': ['Rates Advisor'],
      // '/revenue/bookingFeed': ['Booking Feed'],
      '/insights': ['Insights'],
    };

    const lookupTitle = titleLookUp[pathname] || [];
    const lastLookupIndex = lookupTitle.length - 1;

    if (!lookupTitle.length) {
      return (
      <HeaderTitle>
        {'Quibble'}
      </HeaderTitle>);
    }

    if (lookupTitle.length === 1) {
      return (
        <HeaderTitle>
          {lookupTitle}
        </HeaderTitle>
      )
    }

    return lookupTitle.map((titleText, index) => {
      if (index === lastLookupIndex) {
        return (
          <HeaderTitle key={`${titleText} ${index}`}>
            {titleText}
          </HeaderTitle>
        )
      }

      return (
        <Fragment key={`${titleText} ${index}`}>
          {titleText}
          <HeaderForwardSlash key={`${titleText} ${index}`}>
            /
          </HeaderForwardSlash>
        </Fragment>
      )
    })

  }, [pathname, propertyName, marketDetails, isFetchingPropertyDetails, isFetchingMarketDetails, eventDetails, isFetchingEventDetails]);

  const onToggleNav = useCallback(() => {
    toggleCollapseNav();
  }, [toggleCollapseNav])


  return (
    <HeaderContainer>
      <HeaderTitleDiv>
        {sidebarCollapsed ? <MenuUnFold onClick={onToggleNav} style={{paddingRight:'20px'}} />:  <MenuFold onClick={onToggleNav} style={{paddingRight:'20px'}} /> }
        {showTitle()}
      </HeaderTitleDiv>
      <RightDiv>
        <WorkingQueue />
        <Notifications />
        <HeaderMenuDiv
          onClick={onHeaderDrop}
        >
          <HeaderMenu >
            <Brandlogo alt="brand logo" src={brand_logo || QuibbleLogMini} width="36px" height="36px"/>
            <HeaderTitle margin='0 10px'> {first_name || 'Quibble User'}</HeaderTitle>
            <DownLogo />
          </HeaderMenu>
          <Suspense fallback={null}>
            <HeaderDropMenu showViewModalFunc={showViewModalFunc} onToggleTheme={onToggleTheme} showDrop={showDrop} setShowDrop={onHeaderDropCollapse}/>
          </Suspense>
        </HeaderMenuDiv>
        <Suspense fallback={null}>
          <ChangeViewModal visibleModal={showViewModal} hideModal={hideModal} />
        </Suspense>
      </RightDiv>
    </HeaderContainer>
  );
}

export default QuibbleHeader;
