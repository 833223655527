import styled, { css } from "styled-components";
import quibbleRm from 'assets/images/quibblerm.png';


export const NextButton = css`
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 2px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  background: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.25s linear;

  &:hover {
    transform: scale(1.1);
    background: none;
  }
`;

export const PrevButton = css`
  ${NextButton}
  right: unset;
  left: 2px;
`;


export const NextButtonImg = styled.img`
  width: fit-content;
  height: fit-content;
`;

export const PrevButtonImg = styled(NextButtonImg)`
  transform: rotate(180deg);
`;

export const ImageBackground = css`
  object-fit: fit;
  background-image: url(${quibbleRm});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
`;