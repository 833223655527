import {
  GET_CURRENT_REVENUE_PLAN,
  GET_REVENUE_PLANNING,
  GET_REVENUE_PLANS,
  SET_CURRENT_REVENUE_PLAN,
  SET_REVENUE_PLANNING,
  SET_REVENUE_PLANS,
  SET_CITY_DETAILS,
  SET_SEARCHED_CITIES,
  GET_PORTFOLIO_GOALS,
  GET_PORTFOLIO_GOALS_SUCCESS,
  GET_MARKET_GOALS,
  GET_MARKET_GOALS_SUCCESS,
  GET_PROPERTY_GOALS,
  GET_PROPERTY_GOALS_SUCCESS,
} from "../../constants/ActionTypes";

/*** Set initial state */
const INIT_STATE = {
  loader: false,
  textMessage: "",
  revenueData: [],
  propertyDetail: {},
  propertyDataAnalysis: {},
  isFetchingCurrentRevenuePlan: false,
  isFetchingRevenuePlans: false,
  isCreatingRevenuePlan: false,
  isEditingRevenuePlan: false,
  currentRevenuePlan: {},
  revenuePlans: [],
  marketHunterCityDetails: {
    _id: '',
    city: '',
    country: '',
    state: '',
    cityId: '',
    countryId: '',
    stateId: '',
    listings: [],
    marketSummaries: [{}, {}, {}], 
    totalActiveHosts: null,
    totalActiveListings: null,
  },
  marketHunterSearchCities: [],
  portfolioGoals: [],
  marketGoals: [],
  propertyGoals: [],
  isFetchingPortfolioGoals: false,
  isFetchingMarketGoals: false,
  isFetchingPropertyGoals: false,
};

const BusinessToolsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REVENUE_PLANNING: {
      return {
        ...state,
        loader: true,
        revenueData: [],
      };
    }

    case SET_REVENUE_PLANNING: {
      return {
        ...state,
        loader: false,
        revenueData: action.payload,
      };
    }

    case GET_CURRENT_REVENUE_PLAN: {
      return {
        ...state,
        currentRevenuePlan: action.payload,
        isFetchingCurrentRevenuePlan: true,
      };
    }

    case SET_CURRENT_REVENUE_PLAN: {
      return {
        ...state,
        currentRevenuePlan: action.currentRevenuePlan,
        isFetchingCurrentRevenuePlan: false,
      };
    }

    case GET_REVENUE_PLANS: {
      return {
        ...state,
        isFetchingRevenuePlans: true,
      };
    }

    case SET_REVENUE_PLANS: {
      return {
        ...state,
        revenuePlans: action.revenuePlans,
        isFetchingRevenuePlans: false,
      };
    }

    case SET_CITY_DETAILS: {
      return {
        ...state,
        marketHunterCityDetails: {
          ...action.data,
        }      
      };
    }

    case SET_SEARCHED_CITIES: {
      return {
        ...state,
        marketHunterSearchCities: [
          ...action.data
        ]     
      };
    }

    case GET_PORTFOLIO_GOALS: {
      return {
        ...state,
        isFetchingPortfolioGoals: true,
      };
    }

    case GET_PORTFOLIO_GOALS_SUCCESS: {
      return {
        ...state,
        portfolioGoals: action.payload,
        isFetchingPortfolioGoals: false,
      }
    }

    case GET_MARKET_GOALS: {
      return {
        ...state,
        isFetchingMarketGoals: true,
      };
    }
    
    case GET_MARKET_GOALS_SUCCESS: {
      return {
        ...state,
        marketGoals: action.payload,
        isFetchingMarketGoals: false,
      }
    }

    case GET_PROPERTY_GOALS: {
      return {
        ...state,
        isFetchingPropertyGoals: true,
      };
    }

    case GET_PROPERTY_GOALS_SUCCESS: {
      return {
        ...state,
        propertyGoals: action.payload,
        isFetchingPropertyGoals: false,
      }
    }

    default:
      return state;
  }
};

export default BusinessToolsReducer;
