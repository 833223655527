import React from 'react';
import styled from 'styled-components';
import ThumbnailLoader from './Thumbnail';
import CardLoader from './Card';

const FullDescDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const FullDescLoader = () => {
  return (
    <FullDescDiv>
      <ThumbnailLoader />
      <CardLoader />
    </FullDescDiv>
  )
}

export default FullDescLoader;