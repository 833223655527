import styled from 'styled-components';

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;

  border-right: 1px solid #FFF;
  background: #9747FF;

  color: #FFFFFF;

  padding: 10px;
`;

const Text = styled.div`
  color: #FFF;
  font-family: Commissioner;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px; /* 100% */
`;

const CustomBarWithOpacity = ( props ) => {
  const opac = props.opacityPercent / 100;
  return (
    <BarContainer style={{ opacity: opac, backgroundColor: props.type === 'market' ? '#C6A1F5' : '#2ECADB' }} >
      <Text>{props.text}</Text>
    </BarContainer>
  );
}

export default CustomBarWithOpacity;