import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import airbnb from '../../../../../assets/images/markethunter/airbnb.png';
import vrbo from '../../../../../assets/images/markethunter/vrbo.png';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';

const RentalChannelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const SubTitle = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ChannelsContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ChannelItemContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
`;

const PercentText = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  margin-top: 10px;
`;

const processPercentageValue = (num) => {
  let ret = num * 100;
  return ret.toFixed(0);
}

const RentalChannel = (props) => {

  const [chartData, setChartData] = useState([]);
  const [entireHomeRental, setEntireHomeRentals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)

    if (props.data) {
      const rawData = props.data;
      setChartData(rawData)
      setIsLoading(false);
    }
  }, [props.data]);

  useEffect(() => {
    setEntireHomeRentals(props.homeRentalPercentage);
  }, [props.homeRentalPercentage]);

  return (
    <>
    {isLoading ? <MHCardLoader /> :
      <RentalChannelContainer>
        <TitleContainer>
          <Title>Rental Channel</Title>
          <SubTitle>{entireHomeRental && `${processPercentageValue(entireHomeRental)}% Entire Home rentals`}</SubTitle>
        </TitleContainer>
        <ChannelsContainer>
          <ChannelItemContainer>
            <img src={airbnb} alt="airbnb" />
            <PercentText>{chartData && processPercentageValue(chartData.airbnb)}%</PercentText>
          </ChannelItemContainer>
          <ChannelItemContainer>
            <img src={vrbo} alt="vrbo" />
            <PercentText>{chartData && processPercentageValue(chartData.vrbo)}%</PercentText>
          </ChannelItemContainer>
          <ChannelItemContainer>
            Listed of Both
            <PercentText>{chartData && processPercentageValue(chartData.both)}%</PercentText>
          </ChannelItemContainer>
        </ChannelsContainer>
      </RentalChannelContainer>
    }
    </>
  );
}

export default RentalChannel;