
import axios from "axios";
import {jwtExpiration} from "../../src/constants/GlobalConstant";
import { getData, storeData } from "../util/indexDbHelper";
import serviceV2 from "../util/APIUtility";


let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });



const headers = {headers: {'Content-Type': 'application/json'}}
export const markets = {
  list: async (data) => {
    let cachedData = await getData('listData', data)

    if(!cachedData){
      let dataVal  = await service.post("/api/markets", data, headers);
      jwtExpiration(dataVal);
      await storeData("listData", dataVal.data, data)
      return dataVal;
    }

   let response = { data: cachedData }
   return response;
  },
  calendarMetric: async (data) => {
    let dataVal  = await service.post("/api/calendar-metric", data, headers);
    jwtExpiration(dataVal);
    console.log("calendarMetric >> ", dataVal);
    return dataVal;
  },
  dataAnalysis: async (data) => {
    let cachedData = await getData("dataAnalysis", data)

    if (!cachedData){
      let dataVal  = await service.post("/api/markets/dataAnalysis", data, headers);
      jwtExpiration(dataVal);
      await storeData("dataAnalysis", dataVal.data, data);
      return dataVal;
    }

    let response = { data: cachedData }
    return response;

  },
  getMarketAnalytics: async (options) => {
    const {params} = options;
    let result = await serviceV2.get(`/api/market-analytics`, {params: {...params}});
    return result.data.data;
  },
  marketPricing: async (options) => {
    let result = await serviceV2.get(`/api/pricing`, options);
    return result.data.data;
  },
  getMarketPricingRules: async (options) => {
    const { params } = options
    let result = await serviceV2.get(`/api/pricing/rules`, {params: { ...params }});
    return result.data.data;
  },
  getPortfolioPricingRules: async () => {
    let result = await serviceV2.get(`/api/pricing/rules`);
    return result.data.data;
  },
  getListings: async () => {
    let result = await serviceV2.get(`/api/listings`);
    return result.data.data;
  },
  toggleListingPricing: async (options) => {
    const {id, params} = options;
    try {
      let result = await serviceV2.post(`/api/listings/${id}/manage`, params);
      return result.status === 200 ?  {status: true} : {status: false};
    }catch (e) {
      return {status: false}
    }
  },
  updateListingPricing: async (options) => {
    const {id, params} = options;
    try {
      let result = await serviceV2.put(`/api/listings/${id}/manage`, params);
      return result.status === 200 ?  {status: true} : {status: false};
    }catch (e) {
      return {status: false}
    }
  },
};
