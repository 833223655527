import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "../components/CircularProgress";

import {
  showAuthLoader,
  updateUserPMS
} from "../appRedux/actions";
const MyvrAuthentication = (props) => {
  /* eslint-disable no-unused-vars */
  const {authUser,alertMessageType, showMessage, alertMessage} = useSelector(({auth}) => auth);
  const dispatch = useDispatch();
  const history  = useHistory();
  const queryString = require('query-string');
  const search = queryString.parse(props.location.search);
  let code  = (search.code)  ? search.code : '';
  let string  = (search.string)  ? search.string : '';


  useEffect(() => {
    dispatch(showAuthLoader());
    dispatch(updateUserPMS({code : code, string : string, pms : 'MyVr'}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    history.push('/signin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[alertMessageType]);


  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container body-common-main">
        <div className="common-body-box body-center">
          <div className="gx-app-login-main-content">
            <div className="gx-loader-view">
              <CircularProgress/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default MyvrAuthentication;
