import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';


const RentalSizeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const SubTitle = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const RentalSize = (props) => {

  const [chartData, setChartData] = useState([]);
  const [isEmptyPortfolio, setIsEmptyPortfolio] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    if (props.data) {
      const rawData = props.data;
      setChartData(rawData)
      setIsEmptyPortfolio(props.isEmptyPortfolio)
      setIsLoading(false);
    }
  }, [props.data, props.isEmptyPortfolio]);

  useEffect(() => {

    setIsLoading(true)
    const rawData = props.data

    // const maxMarketCount = rawData && Math.max(...Object.values(rawData).map(size => size.market.count));
    // const maxPortfolioCount = rawData && Math.max(...Object.values(rawData).map(size => size.portfolio.count));
    // const maxCount = Math.max(maxMarketCount, maxPortfolioCount);

    if (rawData) {
      const mappedData = rawData && Object.entries(rawData).map(([key, value]) => {
        const Market = value.market.count;
        const Portfolio = value.portfolio.count;
        const name = key === "br5plus" ? "5+" : key.replace('br', '') || 'Studio';
        return { name, Market, Portfolio };
      });
      setChartData(mappedData)
      setIsLoading(false);
    }
  }, [props.data]);

  return (
    <>
    {isLoading ? <MHCardLoader /> :
      <RentalSizeContainer>
        <TitleContainer>
          <Title>Rental Size</Title>
          <SubTitle>
            {props.averageBedrooms && `${props.averageBedrooms.toFixed(2)} Bedrooms / `}
            {props.averageGuests && `${props.averageGuests.toFixed(2)} Guests on average`}
          </SubTitle>
        </TitleContainer>
        <div style={{ height: '200px', padding: '10px 0' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={10}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="Market" fill="#B989F6" activeBar={<Rectangle fill="pink" stroke="blue" />} />
            {!isEmptyPortfolio &&
              <Bar dataKey="Portfolio" fill="#2ECADB" activeBar={<Rectangle fill="gold" stroke="purple" />} />
            }
          </BarChart>
        </ResponsiveContainer>
        </div>
      </RentalSizeContainer>
    }
    </>
  );
}

export default RentalSize;