import React, { useState } from 'react';
import ChartContainer from './components/ChartContainer';
import FilterToolsContainer from './components/FilterToolsContainer';
import styled from 'styled-components';
const PortfolioAnalyticsBody = styled.div`
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 1.5rem;
  padding-bottom: 1rem;
`;

const PortfolioAnalytics = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [columns, setColumns] = useState(1);
  const [hiddenCharts, setHiddenCharts] = useState([]);

  const currency = localStorage.getItem('currency');

  const changeYear = (year) => {
    setYear(year);
  }

  const changeColumns = (col) => {
    setColumns(col);
  }

  const defaultIntParser = (o, value) => parseInt(o[value]?.toFixed(0) || 0);

  const charts = [ 
    {
      title: "Portfolio ADR & Occupancy",
      metric: "occadr",
      yLabelLeft: "Average Daily Rate (ADR)",
      yLabelRight: "Occupancy (OCC)", 
      domain: [0, dataMax => Math.round((dataMax * 2) / 100) * 100],
      yTickFormatterLeft: (number) => { return currency + number.toString() },
      yTickFormatterRight: (number) => { return number.toString() + '%'},
      formatOcc: true,
      hasAdjustPricingButton: true,
      disabledCheckbox: true,
      bars: [
        {
          dataKey: (o) => (o.lastYearOccupancy * 100)?.toFixed(0),
          fill: "#C6A1F5",
          yAxisId: "right",
        },
        {
          dataKey: (o) => (o.currentYearOccupancy * 100)?.toFixed(0),
          fill: "#2ECADB",
          yAxisId: "right",
          stackId: "stackOcc"
        },
        {
          dataKey: (o) => ((o.forecastOccupancy - o.currentYearOccupancy) * 100)?.toFixed(0) || 0,
          fill: "#99E7EE",
          yAxisId: "right",
          stackId: "stackOcc"
        },
      ],
      lines: [
        {
          dataKey: (o) => defaultIntParser(o, 'lastYearAdr'),
          stroke: "#B989F6",
          type: "monotone",
        },
        {
          dataKey: (o) => defaultIntParser(o, 'currentYearAdr'),
          stroke: "#1BB3CD",
          type: "monotone",
        },
        {
          dataKey: (o) => (o && o.forecastAdr !== undefined) ? o.forecastAdr.toFixed(0) : 0,
          stroke: "#1BB3CD",
          strokeDasharray: "4 4 4",
          type: "monotone",
        }
      ],
      labels: [
        {
          image: "chart_bar",
          label: "OCC",
        },
        {
          image: "chart_line",
          label: "ADR",
        },
        {
          image: "chart_rectangle_purple",
          label: year - 1,
        },
        {
          image: "chart_rectangle_teal",
          label: year,
        },
        {
          image: "chart_rectangle_forecast",
          label: "Forecast",
        }
      ],
      tooltip: [
        {
          title: "OCC",
          data: [
            {
              key: "Forecast",
              valueIndex: 2,
              suffix: "%",
              forecastKey: "forecastOccupancy",
              isForecastPercentage: true,
            },
            {
              key: year,
              valueIndex: 1,
              suffix: "%",
            },
            {
              key: year - 1,
              valueIndex: 0,
              suffix: "%",
            }
          ]
        },
        {
          title: "ADR",
          data: [
            {
              key: "Forecast",
              valueIndex: 5,
              prefix: currency,
              forecastKey: "forecastAdr",
            },
            {
              key: year,
              valueIndex: 4,
              prefix: currency,
            },
            {
              key: year - 1,
              valueIndex: 3,
              prefix: currency,
            }
          ]
        },
        {
          showAdjustPriceButton: true
        }
      ],
    },
    {
      title: "Total Revenue",
      metric: "revenue",
      yLabelLeft: "Revenue",
      domain: [0, dataMax => Math.round((dataMax * 1.25) / 100) * 100],
      formatText: true,
      revenueFormatter: function (number) {
        if (number >= 1000000) {
          return currency + (Math.round(number / 1000000)) + 'M';
        } else if (number >= 1000 && number < 1000000) {
          return currency + (Math.round(number / 1000)) + 'K';
        }
        else {
          return currency + number.toString();
        }
      },
      bars: [
        {
          dataKey: (o) => defaultIntParser(o, 'lastYearTotalRevenue'),
          fill: "#C6A1F5",
        },
        {
          dataKey: (o) => defaultIntParser(o, 'currentYearTotalRevenue'),
          fill: "#2ECADB",
          stackId: "stackRevenue"
        },
        {
          dataKey: (o) => parseInt((o.forecastRevenue - o.currentYearTotalRevenue), 0),
          fill: "#99E7EE",
          stackId: "stackRevenue"
        },
      ],
      labels: [
        {
          image: "chart_rectangle_purple",
          label: year - 1,
        },
        {
          image: "chart_rectangle_teal",
          label: year,
        },
        {
          image: "chart_rectangle_forecast",
          label: "Forecast",
        }
      ],
      tooltip: [
        {
          data: [
            {
              key: "Forecast",
              valueIndex: 2,
              prefix: currency,
              forecastKey: "forecastRevenue",
            },
            {
              key: year,
              valueIndex: 1,
              prefix: currency,
            },
            {
              key: year - 1,
              valueIndex: 0,
              prefix: currency,
            }
          ]
        }
      ]
    },
    {
      title: "Bookings (Reservations)",
      metric: "reservations",
      yLabelLeft: "Reservations Count",
      domain: [0, dataMax => Math.round((dataMax * 2) / 10) * 10],
      bars: [
        {
          dataKey: "lastYearOwnerReservations",
          fill: "#C6A1F5",
          stackId: "lastYearReservations"
        },
        {
          dataKey: "lastYearGuestReservations",
          fill: "#FFE5A9",
          stackId: "lastYearReservations"
        },
        {
          dataKey: "currentYearOwnerReservations",
          fill: "#00A4E6",
          stackId: "currentYearReservations"
        },
        {
          dataKey: "currentYearGuestReservations",
          fill: "#AAE0FF",
          stackId: "currentYearReservations"
        },
      ],
      labels: [
        {
          image: "chart_rectangle_purple",
          label: `${year - 1}: Owner Bookings`,
        },
        {
          image: "chart_rectangle_yellow",
          label: `${year - 1}: Guest Bookings`,
        },
        {
          image: "chart_rectangle_blue",
          label: `${year}: Owner Bookings`,
        },
        {
          image: "chart_rectangle_lightblue",
          label: `${year}: Guest Bookings`,
        }
      ],
      tooltip: [
        {
          title: "Guests Bookings",
          data: [
            {
              key: year,
              valueIndex: 3,
            },
            {
              key: year - 1,
              valueIndex: 1,
            }
          ]
        },
        {
          title: "Owner Bookings",
          data: [
            {
              key: year,
              valueIndex: 2,
            },
            {
              key: year - 1,
              valueIndex: 0,
            }
          ]
        }
      ]
    },
    {
      title: "Length of Stay",
      metric: "los",
      yLabelLeft: "Average Days",
      domain: [0, dataMax => Math.round((dataMax * 1.25) / 10) * 10],
      hideDaysFilter: true,
      bars: [
        {
          dataKey: (o) => defaultIntParser(o, 'losLastYear'),
          fill: "#C6A1F5",
        },
        {
          dataKey: (o) => defaultIntParser(o, 'losThisYear'),
          fill: "#2ECADB",
        },
      ],
      labels: [
        {
          image: "chart_rectangle_purple",
          label: year - 1,
        },
        {
          image: "chart_rectangle_teal",
          label: year,
        },
      ],
      tooltip: [
        {
          data: [
            {
              key: year,
              valueIndex: 1,
            },
            {
              key: year - 1,
              valueIndex: 0,
            }
          ]
        }
      ]
    },
    {
      title: "Lead Time",
      metric: "leadTime",
      yLabelLeft: "Days in Advance",
      domain: [0, dataMax => Math.round((dataMax * 1.25) / 10) * 10],
      bars: [
        {
          dataKey: (o) => defaultIntParser(o, 'leadTimeLastYear'),
          fill: "#C6A1F5",
        },
        {
          dataKey: (o) => defaultIntParser(o, 'leadTimeCurrentYear'),
          fill: "#2ECADB",
        },
      ],
      labels: [
        {
          image: "chart_rectangle_purple",
          label: year - 1,
        },
        {
          image: "chart_rectangle_teal",
          label: year,
        },
      ],
      tooltip: [
        {
          data: [
            {
              key: year,
              valueIndex: 1,
            },
            {
              key: year - 1,
              valueIndex: 0,
            }
          ]
        }
      ],
    },
    {
      title: "Inventory Growth",
      metric: "inventory",
      yLabelLeft: "Total Nights",
      domain: [0, dataMax => Math.round((dataMax * 2) / 100) * 100],
      bars: [
        {
          dataKey: (o) => defaultIntParser(o, 'lastYearCount'),
          fill: "#C6A1F5",
        },
        {
          dataKey: (o) => defaultIntParser(o, 'currentYearCount'),
          fill: "#2ECADB",
        },
      ],
      labels: [
        {
          image: "chart_rectangle_purple",
          label: year - 1,
        },
        {
          image: "chart_rectangle_teal",
          label: year,
        },
      ],
      tooltip: [
        {
          data: [
            {
              key: year,
              valueIndex: 1,
            },
            {
              key: year - 1,
              valueIndex: 0,
            }
          ]
        }
      ],
    },
    {
      title: "Active Properties",
      metric: "properties",
      yLabelLeft: "Properties count",
      domain: [0, dataMax => Math.round((dataMax * 2) / 10) * 10],
      bars: [
        {
          dataKey: (o) => defaultIntParser(o, 'totalLastYearCount'),
          fill: "#C6A1F5",
        },
        {
          dataKey: (o) => defaultIntParser(o, 'totalCurrentYearCount'),
          fill: "#2ECADB",
        },
      ],
      labels: [
        {
          image: "chart_rectangle_purple",
          label: year - 1,
        },
        {
          image: "chart_rectangle_teal",
          label: year,
        },
      ],
      tooltip: [
        {
          data: [
            {
              key: year,
              valueIndex: 1,
            },
            {
              key: year - 1,
              valueIndex: 0,
            }
          ]
        }
      ],
    },
  ]

  const filterChartVisibility = (intHiddenCharts) => {
    setHiddenCharts(intHiddenCharts);
  }

  return (
    <PortfolioAnalyticsBody>
      <FilterToolsContainer
        charts={charts}
        onChangeYear={changeYear}
        onChangeColumn={changeColumns}
        column={columns}
        hiddenCharts={hiddenCharts}
        onFilterChartVisibility={filterChartVisibility}
      />
      <Content>
        {charts.map((chart, i) => {
          if (!hiddenCharts.includes(i)) {
            return (
              <>
                <ChartContainer {...chart} year={year} columns={columns} key={i} />
              </>
            )
          } else {
            return <></>
          }
        })}
      </Content>
    </PortfolioAnalyticsBody>
  )
}

export default PortfolioAnalytics;
