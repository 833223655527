import React, { useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { BodyCSS } from "../../../../components/ReusableComponents/Card-Body/BodyCSS";
import QuibbleLogoMini from '../../../../assets/images/quibble_house.png';
import companyIcon from '../../../../assets/images/profile/companyIcon.png';
import locationIcon from '../../../../assets/images/profile/locationIcon.png';
import planIcon from '../../../../assets/images/profile/planIcon.png';
import loyaltyIcon from '../../../../assets/images/profile/loyaltyIcon.png';
import { ADMIN_ROLE, TRIAL_ROLE, FREEMIUM_ROLE, ANALYTIC_ROLE } from "../../../../constants/GlobalConstant";
import { SecondaryButton } from "../../../../components/ReusableComponents/Button/Button";

const AccountInfoDiv = styled.div`
  min-width: 330px;
  width: 330px;
  height: 550px;
  ${BodyCSS}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BrandLogoContainer = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: 40px 0 0 0;
  object-fit: cover;
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  background: white;
`;

const FirstNameSpan = styled.span`
  margin: 10px 0 0 0;
  font-family: Commissioner;
  font-weight: 400;
  font-size: ${({theme}) => theme.s6};
  color:${({theme}) => theme.bodyFg};
`;

const EmailSpan = styled(FirstNameSpan)`
  margin: 8px 0 0 0;
  font-size: ${({theme}) => theme.s4};
`;

const AccountInfoLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0 10px 0;
  border-top: 1px solid ${({theme}) => theme.quibbleHoverGrey};
`;

const InfoDiv = styled.div`
  width: 100%;
  height: fit-content;
  margin: 18px 0 0 0;
  padding: 0 0 0 22px;
  font-size: ${({theme}) => theme.s4};
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoIcon = styled.img`
  width: 24px;
  height: 20px;
  margin: 0 14px 0 0;
`;

const LocationIconImg = styled.img`
  width: 22px;
  height: 22px;
  margin: 0 16px 0 0;
`;

const LoyaltyIconImg = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 18px 0 0;
`;

const EditButton = styled(SecondaryButton)`
  margin: 28px 0 0 0;
`;


const currentPlan = {
  [FREEMIUM_ROLE]: 'Q-Insights',
  [ANALYTIC_ROLE]: 'Q-Insights Pro',
  [ADMIN_ROLE]: 'Admin',
  [TRIAL_ROLE]: 'Trial',
}

const AccountInfo = ({authData}) => {

  const {first_name, email, last_name, brand_logo, company, address, role, created} = authData;
  const history = useHistory();

  const LoadName = useCallback(() => {
    if (!first_name && !last_name) {
      return 'User'
    }
    else if (first_name && !last_name) {
      return first_name
    }
    return first_name + ' '  + last_name;
  }, [first_name, last_name]);

  const onEditProfileClick = () => {
    history.push('/setting/profile/edit-profile');
  };

  return (
    <AccountInfoDiv >
      <BrandLogoContainer  src={brand_logo || QuibbleLogoMini} alt="brand-logo"/>
      <FirstNameSpan>{LoadName()}</FirstNameSpan>
      <EmailSpan>{email}</EmailSpan>
      <AccountInfoLine />
      <InfoDiv> <InfoIcon src={companyIcon} alt="companyIcon" />  Company: {company}  </InfoDiv>
      <InfoDiv> <LocationIconImg src={locationIcon} alt="locationIcon" />  Location: {address || 'N/A'}  </InfoDiv>
      <InfoDiv> <LocationIconImg src={planIcon} alt="planIcon" />  Current Plan: {currentPlan[role] || "Not Asigned"}  </InfoDiv>
      <InfoDiv> <LoyaltyIconImg src={loyaltyIcon} alt="loyaltyIcon" />  Loyalty Date: {new Date(created).toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"})} </InfoDiv>
      <EditButton onClick={onEditProfileClick}>Edit Profile</EditButton>
    </AccountInfoDiv>
  )
}


export default AccountInfo;
