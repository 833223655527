import {Tooltip} from 'antd';
import styled from 'styled-components';
import { checkSign } from '../../../../util/helper';


const NameButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-align: left;
  color: ${({colorType}) => colorType};

  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
  }

`;

export const MarketTableTitleToolTip = (tooltipText, title) => {
  return (
    <Tooltip title={tooltipText}>
      {title}
    </Tooltip> 
  )
}

export const NameLink = (text, diffValue, theme, onClickFunc) => {

  const onClickNameButton = () => {
    onClickFunc(text);
  };
 
  return (
    <NameButton colorType={checkSign(diffValue) === 'zero' ? 'inherit' : theme[checkSign(diffValue)]} onClick={onClickNameButton}>
      {text}
    </NameButton>
  )
}