import React from 'react'
import styled from 'styled-components';
import NoMetric from "assets/images/pricing-calendar/nometric.svg"
import LowMetric from "assets/images/pricing-calendar/lowmetric.svg"
import MediumMetric from "assets/images/pricing-calendar/mediummetric.svg"
import HighMetric from "assets/images/pricing-calendar/highmetric.svg"
import MinStay from "assets/images/pricing-calendar/minstay.svg"


const FullContainer = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 16px 0 0 0;
  display: flex;
  padding: 0 0px 0px 0;
  max-height: 24px;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  display:flex;
  align-items: center;
  max-height: 100%;
`;

const SubContainer = styled.div`
  display:flex;
  align-items:center;
  color: ${({theme}) => theme.bodyFg};
  gap:15px;
`;

const Metric = styled.p`
`;

const MetricContainer= styled.div`
  display:flex;
  gap:5px;
  align-items:center;
  height: 100%;

`;
const Image= styled.img`
  margin-bottom: 11px;
`;


const CalendarFooter = () => {
  return (
    <FullContainer>
      <LeftContainer>
        <SubContainer>
            <p>Demand:</p>
            <MetricContainer>
              <Image src={NoMetric} />
              <Metric>No Metric</Metric>
            </MetricContainer>

            <MetricContainer>
              <Image src={LowMetric} />
              <Metric>Low</Metric>
            </MetricContainer>

            <MetricContainer>
              <Image src={MediumMetric} />
              <Metric>Medium</Metric>
            </MetricContainer>

            <MetricContainer>
              <Image src={HighMetric} />
              <Metric>High</Metric>
            </MetricContainer>

            <Metric>|</Metric>

            <MetricContainer>
              <Image src={MinStay} />
              <Metric>Min Stay</Metric>
            </MetricContainer>
        </SubContainer>
      </LeftContainer>
    </FullContainer>

  )
}

export default CalendarFooter
