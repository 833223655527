import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Radio, Space, InputNumber, Slider, Checkbox } from 'antd';
import rating from 'assets/images/markethunter/ratings.svg';

const AMENITIES = ['Jacuzzi', 'Pool', 'Ocean View', 'Air Conditioning', 'WiFi', 'Kitchen', 'Heating', 'Pets Allowed'];
const BEDROOM_OPTIONS = [0, 1, 2, 3, 4, 5];
const BED_OPTIONS = [0, 1, 2, 3, 4, 5];
const BATHROOM_OPTIONS = [0, 1, 2, 3, 4, 5];
const STAR_RATINGS = [
  { min: 4.01, max: 5.00 },
  { min: 3.01, max: 4.00 },
  { min: 2.01, max: 3.00 },
  { min: 1.01, max: 2.00 },
  { min: 0.01, max: 1.00 },
];

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FilterGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const FilterTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const AmenitiesCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 2px 0;
  }
`;

const RatingsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatingsCheckBoxContainer = styled.div`
  display: flex;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
`;

const BedroomButtonContainer = styled.div`
  display: flex;
  .ant-btn {
    display: flex;
    height: 30px;
    width: 50px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 0;
  }
`;

const CompetitorReplacementFilter = ({
  viewType,
  typeOfPlace,
  setTypeOfPlace,
  minPrice,
  maxPrice,
  setMinPrice,
  setMaxPrice,
  minStay,
  setMinStay,
  selectedBedrooms,
  setSelectedBedrooms,
  selectedBeds,
  setSelectedBeds,
  selectedRatings,
  setSelectedRatings,
  selectedBathrooms,
  setSelectedBathrooms,
  setSelectedAmenities,
  clearAllFilters
}) => {
  const [typeOfProperty, setTypeOfProperty] = useState('house');

  const onTypeOfPlaceChange = (e) => {
    setTypeOfPlace(e.target.value);
  };

  const onTypeOfPropertyChange = (e) => {
    setTypeOfProperty(e.target.value);
  };

  const onMinChange = (value) => {
    setMinPrice(value);
  };

  const onMaxChange = (value) => {
    setMaxPrice(value);
  };

  const onMinStayChange = (value) => {
    setMinStay(value);
  };

  const onRatingChange = (min, max) => {
    const isSelected = selectedRatings.some(
      (range) => range.min === min && range.max === max
    );
    if (isSelected) {
      setSelectedRatings((prevRanges) =>
        prevRanges.filter((range) => !(range.min === min && range.max === max))
      );
    } else {
      setSelectedRatings((prevRanges) => [...prevRanges, { min, max }]);
    }
  };

  const onBedroomChange = (bedroom) => {
    setSelectedBedrooms((prevClickedBedrooms) => {
      let updatedBedrooms;
      if (bedroom === 0) {
        return [0];
      } else {
        if (prevClickedBedrooms.includes(bedroom)) {
          updatedBedrooms = prevClickedBedrooms.filter((clickedBedroom) => clickedBedroom !== bedroom);
        } else {
          updatedBedrooms = [...prevClickedBedrooms, bedroom];
        }
        return updatedBedrooms.length === 0 || (updatedBedrooms.length === 1 && updatedBedrooms[0] === 0)
          ? [0]
          : updatedBedrooms.filter(bed => bed !== 0);
      }
    });
  };

  const onBedChange = (bed) => {
    setSelectedBeds((prevClickedBeds) => {
      let updatedBeds;
      if (bed === 0) {
        return [0];
      } else {
        if (prevClickedBeds.includes(bed)) {
          updatedBeds = prevClickedBeds.filter((clickedBed) => clickedBed !== bed);
        } else {
          updatedBeds = [...prevClickedBeds, bed];
        }
        return updatedBeds.length === 0 || (updatedBeds.length === 1 && updatedBeds[0] === 0)
          ? [0]
          : updatedBeds.filter(b => b !== 0);
      }
    });
  };

  const onBathroomChange = (bathroom) => {
    setSelectedBathrooms((prevClickedBathrooms) => {
      let updatedBathrooms;
      if (bathroom === 0) {
        return [0];
      } else {
        if (prevClickedBathrooms.includes(bathroom)) {
          updatedBathrooms = prevClickedBathrooms.filter((clickedBathroom) => clickedBathroom !== bathroom);
        } else {
          updatedBathrooms = [...prevClickedBathrooms, bathroom];
        }
        return updatedBathrooms.length === 0 || (updatedBathrooms.length === 1 && updatedBathrooms[0] === 0)
          ? [0]
          : updatedBathrooms.filter(bath => bath !== 0);
      }
    });
  };

  const onAmenityChange = (amenity) => {
    setSelectedAmenities((prevSelectedAmenities) => {
      if (prevSelectedAmenities.includes(amenity)) {
        return prevSelectedAmenities.filter((selectedAmenity) => selectedAmenity !== amenity);
      } else {
        return [...prevSelectedAmenities, amenity];
      }
    });
  };

  const handleClearAll = () => {
    clearAllFilters();
  };

  return (
    <FilterContainer style={{ position: viewType === 'mapView' ? 'absolute' : 'relative', zIndex: 1, margin: viewType === 'mapView' ? '15px' : '0 15px 0 0' }}>
      <FilterGroup>
        <FilterTitle>Type OF Place</FilterTitle>
        <Radio.Group onChange={onTypeOfPlaceChange} value={typeOfPlace}>
          <Space direction="vertical">
            <Radio value={'any'}>Any</Radio>
            <Radio value={'Entire home/apt'}>Entire Home</Radio>
            <Radio value={'Private room'}>Room</Radio>
          </Space>
        </Radio.Group>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Type OF Property</FilterTitle>
        <Radio.Group onChange={onTypeOfPropertyChange} value={typeOfProperty}>
          <Space direction="vertical">
            <Radio value={'house'}>House</Radio>
            <Radio value={'apartment'}>Apartment</Radio>
            <Radio value={'guest_house'}>Guest House</Radio>
            <Radio value={'hotel'}>Hotel</Radio>
          </Space>
        </Radio.Group>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Price Range</FilterTitle>
        <InputNumber min={0} value={minPrice} onChange={onMinChange} placeholder='Min' /> 
        &nbsp;to&nbsp;
        <InputNumber min={minPrice || 0} value={maxPrice} onChange={onMaxChange} placeholder='Max' /> 
      </FilterGroup>
      {/* <FilterGroup>
        <FilterTitle>Min Stay</FilterTitle>
        {minStay} Nights
        <Slider
          min={1}
          max={5}
          onChange={onMinStayChange}
          value={typeof minStay === 'number' ? minStay : 0}
        />
      </FilterGroup> */}
      <FilterGroup>
        <FilterTitle>Amenities</FilterTitle>
        <AmenitiesCheckBox>
          {AMENITIES.map(amenity => (
            <Checkbox key={amenity} onChange={() => onAmenityChange(amenity)}>{amenity}</Checkbox>
          ))}
        </AmenitiesCheckBox>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Rating</FilterTitle>
        <RatingsContainer>
          {STAR_RATINGS.map((range, idx) => (
            <RatingsCheckBoxContainer key={idx}>
              <Checkbox
                checked={selectedRatings.some(rating => rating.min === range.min && rating.max === range.max)}
                onChange={() => onRatingChange(range.min, range.max)}
              >
                {Array.from({ length: Math.round(range.max) }, (_, i) => <img key={i} src={rating} alt="star" />)}
              </Checkbox>
            </RatingsCheckBoxContainer>
          ))}
        </RatingsContainer>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Bedrooms</FilterTitle>
        <BedroomButtonContainer>
          {BEDROOM_OPTIONS.map(num => (
            <Button
              key={num}
              type={selectedBedrooms.includes(num) ? 'primary' : 'default'}
              onClick={() => onBedroomChange(num)}
            >
              {num === 0 ? 'Any' : num === 5 ? '5+' : num}
            </Button>
          ))}
        </BedroomButtonContainer>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Beds</FilterTitle>
        <BedroomButtonContainer>
          {BED_OPTIONS.map(num => (
            <Button
              key={num}
              type={selectedBeds.includes(num) ? 'primary' : 'default'}
              onClick={() => onBedChange(num)}
            >
              {num === 0 ? 'Any' : num === 5 ? '5+' : num}
            </Button>
          ))}
        </BedroomButtonContainer>
      </FilterGroup>
      <FilterGroup>
        <FilterTitle>Bathrooms</FilterTitle>
        <BedroomButtonContainer>
          {BATHROOM_OPTIONS.map(num => (
            <Button
              key={num}
              type={selectedBathrooms.includes(num) ? 'primary' : 'default'}
              onClick={() => onBathroomChange(num)}
            >
              {num === 0 ? 'Any' : num === 5 ? '5+' : num}
            </Button>
          ))}
        </BedroomButtonContainer>
      </FilterGroup>
      <div>
        <Button size='large' onClick={handleClearAll}>Clear All</Button>
      </div>
    </FilterContainer>
  );
};

export default CompetitorReplacementFilter;
