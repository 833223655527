import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import MenuButton from './subComponents/MenuButton';
import { CompassOutlined, HomeOutlined, LineChartOutlined, SettingOutlined, ToolOutlined, QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { lightScrollbar, darkScrollbar } from "components/ReusableComponents/Scrollbar/Scrollbar";
import MenuGroup from './subComponents/MenuGroup';
import { Modal } from 'antd';
import YoutubeVideoList from '../../../components/HelpCenter/YoutubeVideoList'
import HelpGuides from '../../../components/HelpCenter/HelpGuides';
import SubTopic from '../../../components/HelpCenter/HelpSubOptions';
import { generalSubHelp, analyticsSubHelp, revenueSubHelp, getConfigurationsSubMenu } from "../../../components/HelpCenter/data.js"



const NavbarMenuDiv = styled.div`
  width: 100%;
  height: 100%;
  gap: 10px;
  overflow: ${({overflow}) => overflow};
  ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar };
  display: flex;
  flex-direction: column;
  box-shadow: 36px 0px #91A5A940 25%;
`;

const NavGroupContainer = styled.div`
  padding: 16px 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BottomNavGroupContainer = styled.div`
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid #D9D9D9;
`;

const NavGroup = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: visible;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 80vh;
    overflow: scroll;
    margin: auto;
    border-radius: 0;
  }
  .ant-modal-body {
    flex: 1;
    padding: 0; 
  }
  .ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; // Ensure modal wrap takes full height
  }
`;

const HelpContainer = styled.div`
  display: flex;
`;

const HelpNav = styled.div`
  width:27%;
  height: fit-content;
  position: sticky;
  top:0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  padding: 20px 20px 0px 20px;
`;

const Tutorials = styled.div`
  background-color: ${({theme}) => theme.quibbleHoverGrey};
  width: 75%;
  height: 100%; 
  flex: 1;
  padding: 0px 20px 0px 30px;

`;

const TutorialsNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 20px 0px;
  position: sticky;
  top:0px;
  background-color: ${({theme}) => theme.quibbleHoverGrey};

`;

const TutorialButtons = styled.div`
  display: flex;

`;

const LeftTutorialButton = styled.button`
  background: ${({ theme, activeButton, disabledVideo }) => disabledVideo ? "inherit" : activeButton === "video-tutorials" ? theme.quibbleBlue : "inherit"};
  color: ${({ theme, activeButton, disabledVideo }) => disabledVideo ? theme.disabledGrey : activeButton === "video-tutorials" ? 'white' : theme.quibbleBlue};
  border: 1px solid ${({ theme, disabledVideo }) => disabledVideo ? theme.disabledGrey : theme.quibbleBlue};
  font-family: Commissioner;
  font-weight: 100;
  height: 40px;
  border-radius: 4px 0px 0px 4px;
  padding: 0 15px;
  outline: none;
  cursor: ${({ disabledVideo }) => disabledVideo ? 'not-allowed' : 'pointer'};
  transition: all 0.15s linear;
  white-space: nowrap;

  &:hover {
    background: ${({ theme, disabledVideo }) => disabledVideo ? "inherit" : theme.quibbleBlue};
    color: ${({ theme, disabledVideo }) => disabledVideo ? theme.disabledGrey : theme.white};
  }
`;


const RightTutorialButton = styled.button`
  background: ${({ theme, activeButton, disabledHelpGuide }) => disabledHelpGuide ? "inherit" : activeButton === "help-guides" ? theme.quibbleBlue : "inherit"};
  color: ${({ theme, activeButton, disabledHelpGuide }) => disabledHelpGuide ? theme.disabledGrey : activeButton === "help-guides" ? 'white' : theme.quibbleBlue};
  border: 1px solid ${({ theme, disabledHelpGuide }) => disabledHelpGuide ? theme.disabledGrey : theme.quibbleBlue};
  font-family: Commissioner;
  font-weight: 100;
  height: 40px;
  border-radius: 0px 4px 4px 0px;
  padding: 0 15px;
  outline: none;
  cursor: ${({ disabledHelpGuide }) => disabledHelpGuide ? 'not-allowed' : 'pointer'};
  transition: all 0.15s linear;
  white-space: nowrap;

  &:hover {
    background: ${({ theme, disabledHelpGuide }) => disabledHelpGuide ? "inherit" : theme.quibbleBlue};
    color: ${({ theme, disabledHelpGuide }) => disabledHelpGuide ? theme.disabledGrey : theme.white};
  }
`;

const AnalyticsSubMenu = [{label: 'Insights', url: '/insights'}, {label: 'Portfolio', url:'/main/analytics/portfolio-analytics'}, {label: 'Market', url: '/market-analytics/market', subUrls:['/market-analytics/market-details', '/market-analytics/market-property-details']}, {label: 'Property', url: '/market-analytics/property', subUrls: ['/market-analytics/property-details']}, {label: 'Ancillary', url:  "/revenue/ancillary", isDisabled: true} , {label: 'Rates Advisor', url:  "/setting/myPortfolio"} , {label: 'Booking Feed', url: "/revenue/bookingFeed"},];
const RevenueSubMenu = [{label: 'Price Adjustment', url: "/price-adjustment"}, {label: 'Events Pricing', url: "/revenue/events-pricing"}, {label: 'Pricing Calendar', url: "/revenue/pricing-calendar", isDisabled:true}];
const BusinessSubMenu = [{label: 'Market Hunter', url: "/businessTools/markethunter", isDisabled: true}, {label: 'Property Forecaster', url: "/businessTools/propertyForecaster", isDisabled: true}, {label: 'Property Scout', url: "/businessTools/propertyForecaster", isDisabled: true}, {label: 'Goal Planning', url: "/businessTools/planning", isDisabled:false}];
const SettingsSubMenu = [{label: 'Listings Management', url:  "/settings/listings"}, {label: 'Parameters', url:  "/settings/parameters"}];

const NavbarMenu = ({sidebarCollapsed, toggleCollapseNav}) => {


  const [activeMenu, setActiveMenu] = useState([]);
  const [activeHoverMenu, setActiveHoverMenu] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton]= useState('video-tutorials');

  const [openOption, setOpenOption] = useState('General');
  const [arrowUp, setArrowUp] = useState(false);
  const [selectedSubOption, setSelectedSubOption] = useState(generalSubHelp[0].label);
  const [disabledVideo, setDisabledVideo] = useState(false);
  const [disabledHelpGuide, setDisabledHelpGuide] = useState(false);
  const [disabledOption, setDisabledOption] = useState(false);

  const [pms, setPms] = useState(useSelector((state) => state.users.authUserDetails.pms))

  const ConfigurationsSubMenu = getConfigurationsSubMenu(pms); 

  const PmsInfo = useSelector((state) => state.users.authUserDetails.pms)

  useEffect(()=>{
    setPms(PmsInfo)
  },[PmsInfo])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setActiveMenuFunc = useCallback((value) => {
    setActiveMenu((prev) => prev.includes(value) ? prev.filter((data) => data!==value) : [...prev, value])
  }, [])

  const onHoverNavGroup = (e) => {
    const label = e.currentTarget.getAttribute('label');
    setActiveHoverMenu(label);
  };

  const onHoverLeave = () => {
    setActiveHoverMenu('');
  };

  const handleButtonClick = (value) => {
    if (value === 'video-tutorials' && disabledVideo) return;
    if (value === 'help-guides' && disabledHelpGuide) return;
  
    setActiveButton(value);
  };

  const handleSubOptionClick = (subOption) => {
    const selectedSub = [
      ...generalSubHelp,
      ...analyticsSubHelp,
      ...revenueSubHelp, 
      ...ConfigurationsSubMenu
    ].find(item => item.label === subOption);
  
    if ((selectedSub?.subVideos?.length === 0) && (selectedSub?.subGuide?.length === 0)) {
      return; 
    }
  
    setSelectedSubOption(subOption);
  };
  

  const handleHelpNavClick = useCallback((option) => {
    setOpenOption(prevOption => (prevOption === option ? null : option));
    setArrowUp(prev => !prev)
  }, []);


  useEffect(() => {
    const selectedSubVideo = [
      ...generalSubHelp,
      ...analyticsSubHelp,
      ...revenueSubHelp, 
      ...ConfigurationsSubMenu
    ].find(subVideo => subVideo.label === selectedSubOption);
  
    const selectedSubHelp = [
      ...generalSubHelp,
      ...analyticsSubHelp,
      ...revenueSubHelp, 
      ...ConfigurationsSubMenu
    ].find(subHelp => subHelp.label === selectedSubOption);
  

    const newDisabledVideo = selectedSubVideo ? selectedSubVideo.subVideos.length === 0 : true;
    const newDisabledHelpGuide = selectedSubHelp ? selectedSubHelp.subGuide.length === 0 : true;

    setDisabledVideo(newDisabledVideo);
    setDisabledHelpGuide(newDisabledHelpGuide);


    if (newDisabledVideo && !newDisabledHelpGuide) {
      setActiveButton("help-guides");
    } else if (newDisabledHelpGuide && !newDisabledVideo) {
      setActiveButton("video-tutorials");
    } else if (!newDisabledVideo && !newDisabledHelpGuide) {
      setActiveButton(activeButton);
      setDisabledOption(true);
    }

  }, [ConfigurationsSubMenu, selectedSubOption, activeButton]);


  const renderContent = () => {
    switch (activeButton) {
      case "video-tutorials":
        const selectedSubVideo = [
          ...generalSubHelp,
          ...analyticsSubHelp,
          ...revenueSubHelp, 
          ...ConfigurationsSubMenu
        ].find(subVideo => subVideo.label === selectedSubOption);

  
        return selectedSubVideo ? <YoutubeVideoList videos={selectedSubVideo.subVideos} /> : <YoutubeVideoList videos={generalSubHelp[0].subVideos}/>;
        case 'help-guides':
          const selectedSubHelp = [
            ...generalSubHelp,
            ...analyticsSubHelp,
            ...revenueSubHelp, 
            ...ConfigurationsSubMenu
          ].find(subHelp => subHelp.label === selectedSubOption);

    
          return selectedSubHelp ? <HelpGuides guides={selectedSubHelp.subGuide} /> : <div>Select a sub-option to see its content</div>;
        default:
          return <YoutubeVideoList videos={generalSubHelp[0].subVideos}/>;
    }
  }

  return (
    <NavbarMenuDiv overflow={sidebarCollapsed ? 'visible' : 'auto'}>
      <NavGroupContainer>
        <MenuButton toggleCollapseNav={toggleCollapseNav} icon={<HomeOutlined />} label="Home" sidebarCollapsed={sidebarCollapsed} menuValue="/home"/>
        <NavGroup onMouseOver={onHoverNavGroup} onMouseLeave={onHoverLeave} label="Analytics">
          <MenuButton toggleCollapseNav={toggleCollapseNav}  icon={<LineChartOutlined />} label="Analytics" subList={AnalyticsSubMenu} withArrow sidebarCollapsed={sidebarCollapsed} menuValue={activeMenu} setActiveMenuFunc={setActiveMenuFunc}/>
          <MenuGroup
            sidebarCollapsed={sidebarCollapsed}
            expandMenu={activeMenu.includes("Analytics")}
            menuArray={AnalyticsSubMenu}
            isHoverActive={activeHoverMenu === 'Analytics'}
            toggleCollapseNav={toggleCollapseNav}
          />
        </NavGroup>
        <NavGroup onMouseOver={onHoverNavGroup} onMouseLeave={onHoverLeave} label="Revenue And Pricing">
          <MenuButton toggleCollapseNav={toggleCollapseNav}  icon={<CompassOutlined />} label="Revenue And Pricing" subList={RevenueSubMenu} withArrow sidebarCollapsed={sidebarCollapsed} menuValue={activeMenu} setActiveMenuFunc={setActiveMenuFunc}/>
          <MenuGroup
            sidebarCollapsed={sidebarCollapsed}
            expandMenu={activeMenu.includes("Revenue And Pricing")}
            menuArray={RevenueSubMenu}
            isHoverActive={activeHoverMenu === 'Revenue And Pricing'}
            toggleCollapseNav={toggleCollapseNav}
          />
        </NavGroup>
        <NavGroup onMouseOver={onHoverNavGroup} onMouseLeave={onHoverLeave} label="Business Tools">
          <MenuButton toggleCollapseNav={toggleCollapseNav}  icon={<ToolOutlined />} label="Business Tools" subList={BusinessSubMenu} withArrow sidebarCollapsed={sidebarCollapsed} menuValue={activeMenu} setActiveMenuFunc={setActiveMenuFunc}/>
          <MenuGroup
            sidebarCollapsed={sidebarCollapsed}
            expandMenu={activeMenu.includes("Business Tools")}
            menuArray={BusinessSubMenu}
            isHoverActive={activeHoverMenu === 'Business Tools'}
            toggleCollapseNav={toggleCollapseNav}
          />
        </NavGroup>
      </NavGroupContainer>
      <BottomNavGroupContainer>
        <MenuButton toggleCollapseNav={toggleCollapseNav} icon={<QuestionCircleOutlined />} label="Help Center" sidebarCollapsed={sidebarCollapsed} onClick={showModal} />
        <NavGroup toggleCollapseNav={toggleCollapseNav}  onMouseOver={onHoverNavGroup} onMouseLeave={onHoverLeave} label="Settings">
          <MenuButton icon={<SettingOutlined />} label="Settings" subList={SettingsSubMenu} withArrow sidebarCollapsed={sidebarCollapsed} menuValue={activeMenu} setActiveMenuFunc={setActiveMenuFunc}/>
          <MenuGroup
            sidebarCollapsed={sidebarCollapsed}
            expandMenu={activeMenu.includes("Settings")}
            menuArray={SettingsSubMenu}
            isHoverActive={activeHoverMenu === 'Settings'}
            toggleCollapseNav={toggleCollapseNav}
            isBottomNav={true}
          />
        </NavGroup>

        <StyledModal open={isModalOpen} footer={null} width="80vw" height="80vh"  closable={false}>


          <HelpContainer>
            <HelpNav>
            <SubTopic
                title="General"
                subtopics={generalSubHelp}
                selectedSubOption={selectedSubOption}
                handleSubOptionClick={handleSubOptionClick}
                openOption={openOption === 'General'}
                handleHelpNavClick={handleHelpNavClick}
                arrowUp={arrowUp}
                disabledOption={disabledOption}
              />
              <SubTopic
                title="Analytics"
                subtopics={analyticsSubHelp}
                selectedSubOption={selectedSubOption}
                handleSubOptionClick={handleSubOptionClick}
                openOption={openOption === 'Analytics'}
                handleHelpNavClick={handleHelpNavClick}
                arrowUp={arrowUp}
                disabledOption={disabledOption}
              />
              <SubTopic
                title="Revenue and Pricing"
                subtopics={revenueSubHelp}
                selectedSubOption={selectedSubOption}
                handleSubOptionClick={handleSubOptionClick}
                openOption={openOption === 'Revenue and Pricing'}
                handleHelpNavClick={handleHelpNavClick}
                arrowUp={arrowUp}
                disabledOption={disabledOption}
              />

              <SubTopic
                title="Configurations"
                subtopics={ConfigurationsSubMenu}
                selectedSubOption={selectedSubOption}
                handleSubOptionClick={handleSubOptionClick}
                openOption={openOption === 'Configurations'}
                handleHelpNavClick={handleHelpNavClick}
                arrowUp={arrowUp}
                disabledOption={disabledOption}
              />
            </HelpNav>
            <Tutorials>
              <TutorialsNav>
                <TutorialButtons>
                  <LeftTutorialButton onClick={() => handleButtonClick('video-tutorials')} activeButton={activeButton} disabledVideo={disabledVideo}>
                    Video Tutorials and Guide</LeftTutorialButton>
                  <RightTutorialButton onClick={() => handleButtonClick('help-guides')} activeButton={activeButton} disabledHelpGuide={disabledHelpGuide}>Help and Guides</RightTutorialButton>
                </TutorialButtons>
                <CloseOutlined onClick={handleCancel} style={{fontSize:'20px'}}/>
            </TutorialsNav>
            {renderContent()}
            </Tutorials>
          </HelpContainer>
        </StyledModal>
      </BottomNavGroupContainer>
    </NavbarMenuDiv>
  )
}

export default NavbarMenu;
