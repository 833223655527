import styled from 'styled-components';


import pax from '../../../../../assets/images/markethunter/pax.svg';
import bath from '../../../../../assets/images/markethunter/bath.svg';
import bed from '../../../../../assets/images/markethunter/bed.svg';
import door from '../../../../../assets/images/markethunter/door.svg';
import ratings from '../../../../../assets/images/markethunter/ratings.svg';


const DetailsContainer = styled.div`
  display: flex;
  padding: 10px 0;

  div {
    flex: 1;
    display: flex;

    color: #697273;
    text-align: center;
    font-family: Commissioner;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    img {
      margin-right: 2px;
    }
  }
`;

const MarkerCardListingDetails = (props) => {

  const { selectedMarker } = props;

  return (
    <DetailsContainer>
      <div>
        <img src={pax} alt='pax' />{selectedMarker.accommodates}
      </div>
      <div>
        <img src={bath} alt='bath' />{selectedMarker.bathrooms}
      </div>
      <div>
        <img src={bed} alt='bed' />{selectedMarker.beds}
      </div>
      <div>
        <img src={door} alt='door' />{selectedMarker.bedrooms}
      </div>
      <div>
        <img src={ratings} alt='ratings' />{selectedMarker.averageRating || ''}({selectedMarker.reviews || ''})
      </div>
    </DetailsContainer>
  );
}

export default MarkerCardListingDetails;