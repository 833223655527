import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {reservation} from "../../services/reservation";
import {USER_RESERVATION_LIST,USER_RATES_LIST, USER_BOOKING_FEED, USER_BOOKING_FEED_SUCCESS} from "constants/ActionTypes";
import {updateReservationList, showReservationMessage,updateRatesList} from "../../appRedux/actions/Reservation";


/** Request to get reservation listing */
const getUserReservationRequest = async (data) =>
  await reservation.list(data)
    .then(result => result)
    .catch(error => error);
function* getUserReservationData({payload}) {
  try {
    let propertyList = yield call(getUserReservationRequest, payload);
    yield put(updateReservationList(propertyList.data));
  } catch (error) {
    yield put(showReservationMessage(error));
  }
}
export function* toUserReservationList() {
  yield takeEvery(USER_RESERVATION_LIST, getUserReservationData);
}
/** End :: Request to get reservation listing */

/** Request to get Booking Feed listing */
const getUserReservations = async (data) =>
  await reservation.bookingFeed(data)
    .then(result => result)
    .catch(error => error);
function* getUserBookingData({ payload }) {
  try {
    let reservationList = yield call(getUserReservations, payload);
    yield put({ type: USER_BOOKING_FEED_SUCCESS, payload: reservationList.data });
  } catch (error) {
    yield put(showReservationMessage(error));
  }
}
export function* toUserBookingFeedList() {
  yield takeEvery(USER_BOOKING_FEED, getUserBookingData);
}
/** End :: Request to get Booking Feed  listing */




/** Request to get rate listing */
  const getUserRateRequest = async (data) =>
  await reservation.ratelist(data).then(result => result).catch(error => error);

  function* getUserRateListData({payload}) {
    try {
      let rateList = yield call(getUserRateRequest, payload);
      yield put(updateRatesList(rateList.data));
    } catch (error) {
      yield put(showReservationMessage(error));
    }
  }
  export function* toUserRateList() {
    yield takeEvery(USER_RATES_LIST, getUserRateListData);
  }
/** End :: Request to get rate listing */



/** Export all */
export default function* rootSaga() {
  yield all([
    fork(toUserBookingFeedList),
    fork(toUserReservationList),
    fork(toUserRateList)
  ]);
}
