import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import { Input, Select } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import { message } from 'antd';
import Upload from 'antd/lib/upload/Upload';
import Union from '../../../../assets/images/Union.svg'
import { events as eventsService } from '../../../../services/events';
import { getEventDetails } from '../../../../appRedux/actions';
import CheckBox from '../../../../components/ReusableComponents/Checkbox/CheckBox';


const { RangePicker } = DatePicker;
const { Option } = Select;

const TotalDiv = styled.div`
  background: white;
  margin: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

const Details = styled.p`
  font-size: 21px;
`;

const Left = styled.div`
  width: 60%;
`;

const Form = styled.form`

`;

const FormLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  font-size: 16px;
  gap:8px;
`;

const Settings = styled.p`
  font-size: 21px;
  padding-top: 30px;
`;

const Right = styled.div`
  width: 30%;
`;

const UploadFrame = styled.div`  
  padding: ${({ imageUrl }) => (imageUrl? '0px' : '10px 10px 0px 10px')};
  ${({ theme }) => ` { border: 1px solid ${theme.quibbleHoverGrey} }`};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
`;

const Frame = styled.div`
  ${({ theme }) => ` { background: ${theme.quibbleHoverGrey} }`};
  height: 250px;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const RecurringEvent = styled.span`
//   font-size: 16px;
//   padding: 20px 0px;
//   display: flex;
//   gap: 30px;
//   margin: 1rem 0;
//   color: ${(props) => props.theme.disabledGrey};
// `;

// const Conditions = styled.div`
//   margin-top: 10px;
//   padding: 10px;
//   display: flex;
//   justify-content: space-between;
//   ${({ theme }) => ` { background: ${theme.quibbleHoverGrey} }`};
//   align-items: center;
// `;

// const LeftCondition = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 10px;

// .ant-select-selection-selected-value {
//   background-color: transparent;
// }
// `;

// const TopConditions = styled.div`
//   font-size: 16px;
//   color: black;
// `;

// const BottomConditions = styled.div`
//   font-size: 14px;
//     ${({ theme }) => ` { color: ${theme.DisabledGrey} }`};
// `;

const ActionButtons = styled.div`
  display: flex;
  gap: 20px;
  padding: 110px 0px 0px 0px;

`;

const Button = styled.button`
  padding: 16px 70px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.quibbleBlue};
  color: ${({ theme, blue }) => (blue ? 'white' : theme.quibbleBlue)};
  background-color: ${({ theme, blue }) => (blue ? theme.quibbleBlue : 'white')};
  border-radius: 4px;
  cursor: pointer;
`;

const CheckLabelDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

`;


const EditEvent = () => {
  const markets = useSelector((state) => state.users.authUserDetails.cityList);
  const [imageUrl, setImageUrl] = useState('');
  const [fileList, setFileList] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [minStayCheck, setMinStayCheck] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { eventDetails,
    //  isFetchingEventDetails 
    }
      = useSelector(({ events }) => events);
  // const [isActive, setIsActive] = useState(false);
  // const [dateRange, setDateRange] = useState([]);
  const [details, setDetails] = useState({});

  const eventId = history?.location?.state?.eventId;

  // let token  = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    name: '',
    type: '',
    startDate: '',
    endDate: '',
    imgUrl: '',
    markets: [],
    minStay: 2,
    isRecurring: false,
  });


  useEffect(() => {
    if (!eventId) {
      history.push('/revenue/events-pricing');
      return;
    }

    dispatch(getEventDetails({ eventId }));
  }, [dispatch, eventId, history])

  useEffect(() => {
    if (eventDetails?.status === 'Active') {
      // setIsActive(true);
    } else {
      // setIsActive(false);
    }

    setSelectedMarkets(eventDetails?.marketData?.filter(m => m.isEventApplied).map(m => m.market) || []);
    // setMinStay(eventDetails?.minStay);
    // setDateRange([moment.utc(eventDetails?.startDate), moment.utc(eventDetails?.endDate)]);

    if (eventDetails?.minStay) {
      setMinStayCheck(true);
    };

    setDetails({
      name: eventDetails?.name,
      type: eventDetails?.type,
      startDate: eventDetails?.startDate,
      endDate: eventDetails?.endDate,
      isRecurring: eventDetails?.isRecurring,
      markets: eventDetails?.markets,
      minStay: eventDetails?.minStay,
      imageUrl: eventDetails?.imageUrl,
    })

    setFormData({
      name: eventDetails?.name,
      type: eventDetails?.type,
      startDate: eventDetails?.startDate,
      endDate: eventDetails?.endDate,
      isRecurring: eventDetails?.isRecurring,
      markets: eventDetails?.markets,
      minStay: eventDetails?.minStay,
      imageUrl: eventDetails?.imageUrl,
    })
  }, [eventDetails])


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
  }

  const beforeUpload = (file) => {
    setFileList([file]);
    setImageUrl(URL.createObjectURL(file));
    return false;
  };

  const handleRemoveImage = (e) => {
    e.stopPropagation();
    setFileList([]);
    setImageUrl('');
  };
  
  const handleUploadChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      setImageUrl(info);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates.map(date => date.toDate());

    const formattedStartDate = moment.utc(start).format('YYYY-MM-DD');
    const formattedEndDate = moment.utc(end).format('YYYY-MM-DD');

    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));

    setDetails((prevDetails) => ({
        ...prevDetails,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));
  }

  const handleEdit = async (e) => {
    e.preventDefault();

    message.loading('Updating event...', 5);

    try {
      const body = formData;

      if (!minStayCheck) {
        delete body.minStay;
      }

      await eventsService.updateEventDetails({ eventId, body, apply: true });
    
      if (fileList.length > 0) {
        await eventsService.uploadEventImage({ eventId: eventId, file: fileList[0] });
      }  

      message.destroy();
      message.success("Event successfully updated");
      history.push('/revenue/events-pricing');

    } catch (error) {
      message.destroy();
      message.error('An error occurred');
      console.error('Error making PUT request:', error);
    }
  };

  const cancel = () => {
    history.push('/revenue/events-pricing');
  }

  const handleSelectMarkets = (selected) => {
    let sMarkets = selected;
    if (selected.includes('All')) {
      sMarkets = markets?.map(m => m.city);
    }

    setSelectedMarkets(sMarkets);
    formData.markets = sMarkets;
  }

  const formatStartDate = details?.startDate ? new Date(details.startDate) : new Date();
  const formatEndDate = details?.endDate ? new Date(details.endDate) : new Date();

  const startDateMonth = formatStartDate.getMonth() + 1;
  const endDateMonth = formatEndDate.getMonth() + 1;

  const stringDateStart = `${startDateMonth < 10 ? 0 :''}${startDateMonth}-${formatStartDate.getDate()}-${formatStartDate.getFullYear()}`;
  const endDateStart = `${endDateMonth < 10 ? 0 :''}${endDateMonth}-${formatEndDate.getDate()}-${formatEndDate.getFullYear()}`;

  
  const onMinstayCheckChange = (checked) => {
    setMinStayCheck(checked);
  };

  
  const MinStayChangeNegative = () =>{
    let newMinStay = formData.minStay - 1;
    if (newMinStay < 0 || !newMinStay) newMinStay = 0;
    setFormData((prev) => ({...prev, minStay: newMinStay}))
  }

  const MinStayChangePositive = () => {
    let newMinStay = formData.minStay + 1;
    if (newMinStay < 0 || !newMinStay) newMinStay = 1;
    setFormData((prev) => ({...prev, minStay: newMinStay}))
  }

  return (
    <TotalDiv>
      <Left>
        <Details>Details</Details>
        <Form onSubmit={handleEdit}>
          <FormLabel>
            <label>Event/Holiday Name</label>
            <Input type="text" name="name" value={details.name} onChange={handleChange} style={{ height: '40px' }} />
          </FormLabel>

          <FormLabel>
            <label>Type</label>
            <Select     
            onChange={(selectedValues) => {
            setFormData((prevData) => ({
                ...prevData,
                type: selectedValues,
            }));
            setDetails((prevDetails) => ({
                ...prevDetails,
                type: selectedValues,
              }));
            }} 
            style={{ height: '40px' }} 
            value={details.type}>
              <Option value="event">Event</Option>
              <Option value="holiday">Holiday</Option>
            </Select>
          </FormLabel>

          <FormLabel>
            <label>Market</label>
            <Select
              mode="multiple"
              onChange={handleSelectMarkets}
              style={{ height: '40px' }}
              value={selectedMarkets}
              allowClear={true}
            >
              <Option key="All">All</Option>
              {
                markets && markets.map((market) => (
                  <Option
                    key={market.city}
                  >{market.city}</Option>
                ))
              }
            </Select>
          </FormLabel>

          <Settings>Settings</Settings>

          <FormLabel>
            <CheckLabelDiv>
              <CheckBox checked={minStayCheck} onCheckBoxChange={onMinstayCheckChange}/>
              <label>Minimum Stay</label>
            </CheckLabelDiv>
            <Input type="text" name="minStay" value={formData?.minStay || 0} 
            disabled={!minStayCheck}
            prefix={<MinusCircleOutlined onClick={MinStayChangeNegative} style={{paddingRight: '110px'}}/>} 
            suffix={<PlusCircleOutlined onClick={MinStayChangePositive} />} 
            onChange={() => { }} style={{ width: '300px', height: '40px', }} />

          </FormLabel>

          <FormLabel>
            <label>Date Range</label>
            <RangePicker style={{ width: '300px' }} format={'MM-DD-YYYY'} onChange={handleDateRangeChange} value={details.startDate && details.endDate ? [moment.utc(details.startDate), moment.utc(details.endDate)] : null} />
          </FormLabel>

            {/* Commenting out until we implement the functionality - GRP */}
          {/* <RecurringEvent>
            Recurring event
            <Switch onChange={(checked) => formData.isRecurring = checked} disabled={true} />
          </RecurringEvent>

          <Conditions>
            <LeftCondition>
              <TopConditions>Conditions</TopConditions>
              <BottomConditions>
                The multiplier will be applied to increase the price but only if certain conditions are met
              </BottomConditions>
            </LeftCondition>

            <Switch disabled />

          </Conditions>  */}

          <ActionButtons>
            <Button type="reset" onClick={cancel}>Cancel</Button>
            <Button onClick={handleEdit} blue>Save</Button>
          </ActionButtons>
        </Form>
      </Left>

      <Right>

        <UploadFrame imageUrl={imageUrl || details.imageUrl}>
          <Frame >
          {imageUrl || details.imageUrl ? (
              <img src={imageUrl || details.imageUrl} alt="Uploaded" style={{ width: '100%', height: '100%' }} />
            ) : (
              <img src={Union} alt="Default" style={{ width: '50px', height: '50px' }} />
            )}
          </Frame>
          <Upload
            listType="picture"
            beforeUpload={beforeUpload}
            onChange={handleUploadChange}
            fileList={fileList}
            onRemove={handleRemoveImage}
            showUploadList={false}
          >
      {fileList.length === 0 ? (
        <p style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', textAlign: 'center', cursor: 'pointer', gap: '10px' }}>
          <UploadOutlined /> Upload Image
        </p>
      ) : (
          <div style={{marginTop:'10px'}}>
            <DeleteOutlined onClick={(e) => handleRemoveImage(e)} style={{ fontSize: '20px', color: 'red', cursor: 'pointer'}} />
          </div>
      )}
          </Upload>
        </UploadFrame>


      </Right>
    </TotalDiv>
  )
}
export default EditEvent
