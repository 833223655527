import React, {memo} from  'react';
import styled, { useTheme } from 'styled-components';
import { CalendarDiv } from '../../../CommonCSS';
import { HALF_MONTH_NAME } from '../../../../../../../constants/GlobalConstant';

const CalendarTitleDiv = styled.div`
  ${CalendarDiv}
  background: ${({theme}) => theme.quibbleBackgroundGrey};
  color: ${({theme}) => theme.white};
`;

const DayText =  styled.span`
  font-weight: 500;
`;

const DayLabel = styled.span`
  font-size: ${({fontSize}) => fontSize};
`;

const dayLabel = {
  0: 'SUN',
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT',
}


const CalendarDayTitle = ({date,index}) => {
  const theme = useTheme();
  const dateSplit = date?.split('-');

  return (
    <CalendarTitleDiv>
      <DayText>{date.length ? `${HALF_MONTH_NAME[Number(dateSplit[1]-1)]}  ${dateSplit[2]}`: ''}</DayText>
      <DayLabel fontSize={date.length ? theme.s2 : theme.s4}>{dayLabel[index]}</DayLabel>
    </CalendarTitleDiv>
  )
}

export default memo(CalendarDayTitle);