// db.js
import Dexie from 'dexie';

const db = new Dexie('myDatabase');

db.version(23).stores({
  MarketWatchList: '++id, activeMarkets, *marketsList, message, status, totalMarkets, expiryTime, defaultSize, date, month, year, *properties, filterBy, sameStore', // Primary key and indexed props
  ListingWatchList: '++id, *propertyList, message, status, totalProperties, expiryTime, defaultSize, date, month, year, *properties, filterBy, sameStore', // Primary key and indexed props
  ListData: '++id, proratedType, hideInactiveProperties , activeProperties, buildingCount, message, *propertyList, status, totalProperties, expiryTime, date, month, year, *properties, filterBy, sameStore', // Primary key and indexed props
  AnalysisData: '++id, *currentYear, *disabledDate, *lastYear, expiryTime, date, month, year, *properties, filterBy, sameStore', // Primary key and indexed props
  DashboardData: '++id, *ALTALOSREVENUE, activeProperties, availableNights, bookedNights, *currentSupplyAndDemandBehavior, *currentYear, *currentYearChartOCCADR, *currentYearMonthData, *dailyIntakeCurrent, *dailyIntakeLast, *disableDate, inventoryGrowth, *lastSupplyAndDemandBehavior, *lastYear, *lastYearChartOCCADR, *marketListing, *propertyListingForMap, *recentActivity, *reservationPerSource, totalReservations, expiryTime, date, month, year, *properties, filterBy, sameStore', // Primary key and indexed props
  NotificationListData: '++id, message, *propertyList, status, totalProperties, expiryTime, date, month, year, *properties, filterBy, sameStore' , // Primary key and indexed props
  ModalData: '++id, *activeProperty, *bookedNights, *bookingGraph, *inventoryGrowth, searchByYear, expiryTime, date, month, year, *properties, filterBy, sameStore', // Primary key and indexed props
  UserPropertiesData: '++id, activeProperty, *currentYear, *lastYear, message, *propertyList, status, totalListingCount, totalProperties, expiryTime, userId', // Primary key and indexed props
});

export default db;