import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';

const EscapiaDiv = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EscapiaButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;

const Escapia = ({ onSubmitForm, stepOne, loading }) => {
  const [escapiaParams, setEscapiaParams] = useState({
    propertyManagerId: '',
    password: ''
  });

  const onFormChange = (formKey, { value }) => {
    if (formKey === "propertyManagerId") {
      if (isNaN(value)) return
    }
    setEscapiaParams((prev) => ({
      ...prev,
      [formKey]: value,
    }));
  };

  const onTokenSubmit = () => {
    const values = {
      ...escapiaParams,
    }
    onSubmitForm(values)
  };

  return (
    <EscapiaDiv>
      { !stepOne && <>
        <FormItemInput formKey="propertyManagerId" value={ escapiaParams.propertyManagerId } label="Property Manager ID"
                       onChangeFunc={ onFormChange }/>
        <FormItemInput formKey="password" isPassword value={ escapiaParams.password } label="Password"
                       onChangeFunc={ onFormChange }/>
      </> }
      <EscapiaButton disabled={loading} onClick={ onTokenSubmit }>
        { !stepOne ? `Submit` : "Processed" }
      </EscapiaButton>
    </EscapiaDiv>
  )
}

export default Escapia;
