import React, {useState, memo, useCallback, useEffect, Fragment} from 'react';
import styled from 'styled-components';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { BodyText } from '../Text/Text';

const FormDiv = styled.div`
  margin: ${({margin}) => margin};
  width: 100%;
  display: flex;
  flex-direction: column;

  input[type=number]::-webkit-inner-spin-button {
    width: 42px;
    height: 42px;
    cursor: pointer;
    margin-right: 2px;
  }
`;

const InputDiv = styled.div`
  width: 100%; 
  height: 48px;
  position: relative;
  background ${({theme}) => theme.white};
`;

const FormInput = styled.input`
  width: inherit;
  height: inherit;
  outline: none;
  border: none;
  background: inherit;
  font-size: ${({theme}) => theme.s4};
  border-radius: 4px;
  border: 1px solid #697273;
  font-weight:400; 
  padding: 0 0 0 8px;

  ${({formstyles}) => formstyles};
`;

const IconButton = styled.button`
  width: fit-content;
  height: fit-content;
  font-size: ${({theme}) => theme.s5};
  color:  #697273;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: none;
  box-shadow: none;

  &:hover, &.active {
    background: none;
    color: #697273;
  }    

  position: absolute;
  top: 10px;
  right: 8px;
`;

const ErrorText = styled.span`
  font-size: ${({theme}) => theme.s3};
  color: ${({theme}) => theme.quibbleRed};
  margin: 2px 0 0 0;
`;

const FormItem = ({
  label="Form Label", 
  isPassword=false, 
  type="text", 
  onChangeFunc = () => {}, 
  formKey, 
  value=null,
  formItemRules={
    isRequiredAndEmpty: false,
    min: 0,
    max: 0,
    patternCheck: {
      pattern: null,
      errorMessage: '',
    }, 
},
otherStyles,
margin,
...otherProps
}) => {

  const [showNewEye, setShowNewEye] = useState(true);
  const [formError, setFormError] = useState([false, '']);

  const {isRequiredAndEmpty, min, max, patternCheck} = formItemRules;

  useEffect(() => {

    //check if empty, parent should provide boolean first to verify empty
    if (isRequiredAndEmpty) {
      setFormError([true, `Field is required.`]);
    } 

  }, [isRequiredAndEmpty])

  const onEyeClick = () => {
    setShowNewEye((prev) => !prev);
  };

  const onInputChange = (e) => {
    e.preventDefault();

    const value = e.target.value;

    //check minimum values of char

    if (min && min > 0 && value.length < min) {
      setFormError([true, `Field must be at least ${min} characters.`]);
      onChangeFunc(formKey, {value, error: true});
      return;
    }

    if (max && max > 0 && value.length > max) {
      setFormError([true, `Field must be only maximum ${max} characters`]);
      onChangeFunc(formKey, {value, error: true});
      return;
    }

    if (patternCheck && patternCheck.pattern) {
      const regExPattern = new RegExp(patternCheck.pattern);
      const testResult = regExPattern.test(value);
      if (!testResult) {
        setFormError([true, patternCheck.errorMessage.length ? patternCheck.errorMessage : `Field doesn't match pattern.`]);
        onChangeFunc(formKey, {value, error: true});
        return;
      }
    }

    setFormError([false, '']);
    onChangeFunc(formKey,{value, error: false});
  }


  const showPasswordIcon = useCallback(() => {
    return isPassword ? (
      <IconButton type='button' onClick={onEyeClick}>
        {showNewEye ? <EyeOutlined /> : <EyeInvisibleOutlined />} 
      </IconButton>
    ) : null;
  }, [isPassword, showNewEye]);

  const setInputType = useCallback(() => {
    if (isPassword) {
      return showNewEye ? "password" : "text";
    }
    
    return type || "text";
  }, [isPassword, showNewEye, type])

  return (
    <FormDiv margin={margin ? margin : ' 24px 0 0 0'}>
      {
        typeof label === 'string' ? 
        <BodyText>
        {label}
        </BodyText> :
        <Fragment>
        {label}
        </Fragment>
      }
      
      <InputDiv>
        <FormInput {...otherProps} value={value} type={setInputType()} onChange={onInputChange} autoComplete={isPassword ? "off": "on"}  formstyles={otherStyles}/>
        {showPasswordIcon()}
      </InputDiv>
      {formError[0] && <ErrorText>{formError[1]}</ErrorText>}
    </FormDiv>
  )
}

export default memo(FormItem);