import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import nextButton from 'assets/images/generic/nextbutton.svg';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import ImageRating from './ImageRating';
import SkLoaderOver from '../SkeletonLoader/SkLoaderOver';
import { ImageBackground, NextButton, NextButtonImg, PrevButton, PrevButtonImg } from './CommonCSS';

import { Button } from 'antd';

import { ReactComponent  as ShowAllPhotosIcon } from 'assets/images/show_all_photos.svg';

const ImageFullPreview = lazy(() => import('./ImageFullPreview'));


const ImageCarouselContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;

  .ant-btn {
    display: flex;
    align-items: center;
  }
`;

const ImagesContainer = styled.button`
  width: fit-content;
  height: 100%;
  display: flex;
  border: none;
  padding: 0px;
  outline: none;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  margin-left: ${({margin}) => `${margin}px`};
  transition: ${({transition}) => transition};
`;

const ImageCarouselItem = styled.img`
  width: 300px;
  min-width: 300px;
  height: 150px;
  ${ImageBackground}
`;


const ImageCarousel = ({imageArray=[], isLoading=false, buttonText}) => {

  const [imageIndex, setImageIndex] = useState(0);
  const [imageReArranged, setImageReArranged ] = useState([]);
  const [margin, setMargin] = useState(-300);
  const [transition, setTransition] = useState(false);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if(imageArray.length) {

      if (imageArray.length < 2) {
        setImageReArranged(imageArray);
        setImageIndex(0);
        setMargin(0);
        return;
      }

      const imageArrayStart = imageArray.slice(0, imageArray.length/2 + 1);
      const imageArrayEnd = imageArray.slice(imageArray.length/2 + 1, imageArray.length);
      const newArray = [...imageArrayEnd, ...imageArrayStart ].map((data, index) => ({...data, dataIndex: index}));
      setImageReArranged(newArray);
      const middleIndex = Number((newArray.length/2 - 1).toFixed(0));
      setImageIndex(middleIndex);
      setMargin(-300 * middleIndex );
    }
  },[imageArray])

  const onArrowclick = (value) => {
    if (value === 'next') {
      
      if (imageIndex === imageReArranged.length - 1 ) {
        setTransition(false);
        setMargin(0);
        setImageIndex(0);
        return;
      }
      setTransition(true);
      setMargin((prev) => prev - 300);
      setImageIndex((prev) => prev + 1);
      return;
    }
      
    if (value === 'prev') {

      if (imageIndex === 0 ) {
        setTransition(false);
        setMargin(-300 * (imageReArranged.length-1));
        setImageIndex(imageReArranged.length - 1);
        return;
      }
      setTransition(true);
      setMargin((prev) => prev + 300);
      setImageIndex((prev) => prev - 1);
      return;
    }
  }

  const showImage = useCallback(() => {

    const onShowPreview = () => {
      setPreview(true);
    };

    return (
      <>
      { buttonText ? <Button onClick={onShowPreview} icon={<ShowAllPhotosIcon/>}>{buttonText}</Button>
        :
        <ImagesContainer onClick={onShowPreview} margin={margin}  transition={transition ? 'margin 0.3s linear' : 'unset' } >
          {imageReArranged.map((image, index) => ( <ImageCarouselItem key={`${image?.imageData} ${index}`} src={image?.imageData} alt="quibble image" />))}
        </ImagesContainer>
      }
      </>
    ) 
  }, [margin, imageReArranged, transition, buttonText])

  const onClosePrev = useCallback(() => {
    setPreview(false);
  }, []);
  
  const imageCarouselCotainerStyles = {
    true: {
      position: 'absolute',
      bottom: '0',
      right: '0'
    },
    false: {
      width: '300px',
      minHeight: '150px',
      height: '150px',
      position: 'relative'
    },
  };

  return (
    <ImageCarouselContainer style={ buttonText ? imageCarouselCotainerStyles.true : imageCarouselCotainerStyles.false }>
      {showImage()}
      {
        !buttonText && (
        <>
          {imageReArranged.length > 1 && 
            <>
              <ButtonWithFunction value="next" ButtonStyle={NextButton} label={<NextButtonImg src={nextButton} alt="next" />} onClickFunc={onArrowclick}/>
              <ButtonWithFunction value="prev"  ButtonStyle={PrevButton} label={<PrevButtonImg src={nextButton} alt="prev" />}  onClickFunc={onArrowclick} /> 
            </> }
            {imageArray[imageIndex]?.rating >= 0 && (
              <ImageRating value={imageReArranged[imageIndex]?.rating?.toFixed(2)} />
            )}
        </>
        )
      }
      <SkLoaderOver isLoading={isLoading} type='thumbnail' />
      <Suspense fallback={null}>
        <ImageFullPreview visible={preview} onCloseClick={onClosePrev} imageArray={imageReArranged} currentIndex={imageIndex} onArrowclick={onArrowclick} />
      </Suspense>
    </ImageCarouselContainer>
  )
}

export default ImageCarousel;