/* eslint-disable require-yield */
import { all, fork, put, call, takeEvery } from "redux-saga/effects";
import { chargeBeeRequest } from "../../services/chargeBeeRequest";
import { user } from "../../services/user";
import {CHARGEBEE_SITE} from '../../constants/GlobalConstant'

import {
  CHECKOUT_NEW_SUBSCRIPTION,
  UPGRATE_SUBSCRIPTION
} from "constants/ActionTypes";

import {
  showAuthMessage,
  showUserProfileErrorMessages,
  userUpdateProfileSuccess,
  showUserErrorMessages,
} from "../../appRedux/actions/Users";

const cleanEmail = (email) =>{
  // eslint-disable-next-line no-useless-escape
  return email.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
}

/** Request to checkout a new subscription */
const checkoutNewSubscriptionRequest = async (data) =>
  await chargeBeeRequest
    .generateCheckoutNewUrl(data)
    .then((result) => result)
    .catch((error) => error);

/** Request to checkout a new subscription */
const checkoutExistingSubscriptionRequest = async (data) =>
  await chargeBeeRequest
    .generateCheckoutExistingPlan(data)
    .then((result) => result)
    .catch((error) => error);

function* checkoutNewSubscriptionModal({ payload }) {
  //to delete
  //console.log("4. Llega al saga con el payload ")
  //console.log(payload)
  try {

    const cbInstance = window.Chargebee.init({site: CHARGEBEE_SITE});

    cbInstance.openCheckout({
      hostedPage: async () => {

        let response = null;
        if(payload?.subscription_id === undefined || payload?.subscription_id === ""){
          response = await checkoutNewSubscriptionRequest(payload);
          payload.subscription_id = cleanEmail(payload.email) + "sub"; //set the cb_subscription_id in our database based in the one created in the back with the email
        }else{
          response = await checkoutExistingSubscriptionRequest(payload);
        }

        console.debug(response.data);
        return response?.data.result?.hosted_page;
      },
      async success(hostedPageId) {
        var updatedUser = await updateSubscriptionInDBRequest(payload);
        if (updatedUser.data.status) {
          localStorage.setItem("currency", updatedUser.data.result);
          await put(showUserErrorMessages({
            status: true,
            message: updatedUser.data.message,
          }))
          await put(userUpdateProfileSuccess(
            updatedUser.data.result ? updatedUser.data.result : ""
          ))

        } else {
          if (updatedUser.data.errors && Object.keys(updatedUser.data.errors).length > 0) {
            await put(showUserProfileErrorMessages(updatedUser.data.errors))
          } else {
            await put(showUserErrorMessages({
              status: false,
              message: updatedUser.data.message,
            }))
          }
        }
      },
      close:() => {
        window.location.reload();
      },
      step(step) {
        //console.log("checkout", step);
      }
    });

  } catch (error) {
    console.error(error);
  }
}


export function* checkoutNewSubscription() {
  yield takeEvery(CHECKOUT_NEW_SUBSCRIPTION, checkoutNewSubscriptionModal);
}

/** End :: Request to checkout a new subscription */

/** Request to checkout a new subscription */

const updateUserProfileDetailsRequest = async (data) =>
  await user
    .userProfileUpdate(data)
    .then((authUser) => authUser)
    .catch((error) => error);

const updateSubscriptionInDBRequest = async (data) =>
await user
  .updateSubscriptionInDB(data)
  .then((authUser) => authUser)
  .catch((error) => error);


const upgrateSubscriptionRequest = async (data) =>
  await chargeBeeRequest
    .upgradeSubscription(data)
    .then((result) => result)
    .catch((error) => error);


//TODO: crear las funciones de mostrar mensaje en el action, reducer y saga
function* upgrateSubscription({ payload }) {
  try {
    const updatedSuscription = yield call(upgrateSubscriptionRequest, payload);

    if(updatedSuscription?.data?.status){
      var updatedUser = yield call(updateUserProfileDetailsRequest, payload);
    }

    if (updatedUser?.data?.status) {
      localStorage.setItem("currency", updatedUser?.data?.result);
      yield put(
        showUserErrorMessages({
          status: true,
          message: updatedUser?.data?.message,
        })
      );
      yield put(
        userUpdateProfileSuccess(
          updatedUser?.data?.result ? updatedUser.data.result : ""
        )
      );
    } else {
      if (
        updatedUser?.data?.errors &&
        Object.keys(updatedUser?.data?.errors).length > 0
      ) {
        yield put(showUserProfileErrorMessages(updatedUser?.data?.errors));
      } else {
        yield put(
          showUserErrorMessages({
            status: false,
            message: updatedUser?.data?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


export function* upgrateSubscriptionPLan() {
  yield takeEvery(UPGRATE_SUBSCRIPTION, upgrateSubscription);
}

/** End :: Request to checkout a new subscription */

/** Export all */
export default function* rootSaga() {
  yield all([
    fork(checkoutNewSubscription),
    fork(upgrateSubscriptionPLan)
  ]);
}
