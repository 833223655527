import React from 'react';
import styled from 'styled-components';
import swap from 'assets/images/generic/swap.svg';
import swapWhite from 'assets/images/generic/swap-white.svg';

const SwitchBody = styled.div`
  width: 100%;
  max-width: 1776px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background: none;
  margin: 0 0 8px 0;
`;

const SwapSpan = styled.div`
  width: 14px;
  height: 14px;
  object-fit: fit;
  background-image: url(${swap});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
`;

const SwitchButton = styled.button`
  width: 196px;
  height: inherit;
  outline: none;
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  color: ${({theme}) => theme.quibbleBlue};
  font-size: ${({theme}) => theme.s4};
  font-weight: 500;
  backround: none;
  cursor: pointer;
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border: 1px solid ${({theme}) => theme.hoverBlue};
    background: ${({theme}) => theme.hoverBlue};
    color: white;

    ${SwapSpan} {
      background-image: url(${swapWhite});
    }
  }
`;


const PriceTableGraphSwitch = ({priceAdjustToggle, showPriceAdjustGraph}) => {
  return (
    <SwitchBody>
      <SwitchButton onClick={priceAdjustToggle}>
        { showPriceAdjustGraph ? 'Price Table View': 'Price Graph View'} <SwapSpan />
      </SwitchButton>
    </SwitchBody>
  )
}

export default PriceTableGraphSwitch;

