import React, { Fragment, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { currencyFormatter } from 'util/helper';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { getOverrideMinstay, getOverridePrice } from '../../../../components/utils';
import { Tooltip } from 'antd';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';

const OverrideSpan = styled.span`
  color: #FFA800;
  font-style: italic;
  font-weight: 700;
`;

const PriceSpan = styled.span`
  color: ${({theme}) => theme.quibbleBlue};
`;

const MinStaySpan = styled.span`
  color: ${({theme, $color}) => $color || theme.quibbleBlue};
  font-style: italic;
  font-weight: 700;
`;

const TooltipItem = styled(BodyText)`
  color: white;
`;

const renderLut = {
  Property: {
    color: '#FFA800',
  },
  Market: {
    color: '#9747FF',
  },
  Portfolio: {
    color: '#EF6AE2',
  },
  Event: {
    color: '#C1AE01',
  },
  default: {
    color: '#1BB3CD',
  }
}



const RenderOverride = ({data, mode}) => {

  const {priceAdjust, activeProperty, adjustmentMenu, activeMarket} = useContext(PriceAdjustContext);

  const renderOverrideData = useCallback(() => {


    if (mode === 'minStay') {
      const minstayOverride = getOverrideMinstay(priceAdjust, adjustmentMenu, activeMarket, activeProperty, data);

      const minstaySetBy = data?.minStaySetBy || 'default';
      const tooltipText = data?.minStaySetByRule ? `Set by ${minstaySetBy} - ${data?.minStaySetByRule}` : `Set by ${minstaySetBy}`;

      if (minstayOverride) {
        return (
          <Tooltip color='black'  showArrow={false} title={<TooltipItem> Set by Override </TooltipItem>}><OverrideSpan> {minstayOverride} </OverrideSpan>  </Tooltip>
        )
      } else if (data?.minStayComputed) {
        return (
          minstaySetBy !== 'default' ? <Tooltip color='black' showArrow={false} title={<TooltipItem> {tooltipText}</TooltipItem>}> <MinStaySpan $color={renderLut[minstaySetBy].color}>{data?.minStayComputed }</MinStaySpan> </Tooltip> : <PriceSpan $color={renderLut['default'].color}>{data?.minStayComputed }</PriceSpan>
        )
      } else {
        return (<PriceSpan $color={renderLut['default'].color}>{data?.minStay}</PriceSpan>)
      }

      // return (
      //   minstayOverride ? 
      //   <Tooltip color='black'  showArrow={false} title={<TooltipItem> Set by Override </TooltipItem>}><OverrideSpan> {minstayOverride} </OverrideSpan>  </Tooltip> : <PriceSpan>{data?.minStayComputed }</PriceSpan> 
      //  ) ;
    }

    if (priceAdjust[activeProperty] && priceAdjust[activeProperty][data?.date] &&  priceAdjust[activeProperty][data?.date].override) {
      return <Tooltip color='black'  showArrow={false} title={<TooltipItem> Set by Override </TooltipItem>}> <OverrideSpan>{currencyFormatter(priceAdjust[activeProperty][data?.date].override)}</OverrideSpan> </Tooltip> 
    };

    const price = data.price;
    const priceOverride = getOverridePrice(priceAdjust, activeProperty, data);
    return priceOverride ? 
    <Tooltip color='black' showArrow={false}  title={<TooltipItem> Set by Override </TooltipItem>}> <OverrideSpan> {currencyFormatter(priceOverride)} </OverrideSpan> </Tooltip>  : <PriceSpan>{currencyFormatter(price)}</PriceSpan> 
  }, [mode, activeProperty,  data, priceAdjust, activeMarket, adjustmentMenu])

  return (
    <Fragment>
        {renderOverrideData()}
    </Fragment>
  ) 
}

export default RenderOverride;
