import React from "react";

import Widget from "components/Widget/index";

const EcommerceStatus = ({icon, title, subTitle, color, colorTitle, colorSubTitle, description,onClick}) => {
  return (
    <Widget styleName={`gx-card-full gx-py-4 gx-px-2 gx-bg-${color}`}>
      <div className="gx-flex-row gx-justify-content-center dashBox" onClick={onClick} >
          <span
            className={`gx-size-60 gx-border gx-border-${colorTitle} gx-text-${colorTitle} gx-flex-row gx-justify-content-center iconBox gx-align-items-center gx-rounded-circle`}>
            <i className={`icon icon-${icon} gx-fs-xxxl`}/>
          </span>
          <div className="gx-text-center">
          
            <h2 className={`gx-fs-xxxl gx-font-weight-medium gx-text-${colorTitle}`}>{title}</h2>
            <p className={`gx-mb-0 gx-text-${colorSubTitle}`}>{subTitle} {(description) ? <span className={`gx-mb-0 gx-text-${colorSubTitle}`}>(Owner - {description})</span> : ''}</p>
            
          </div>
      </div>

    </Widget>
  );
};

export default EcommerceStatus;
