import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SecondaryButton, PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from "components/ReusableComponents/Text/Text";
import { CaretDownOutlined } from '@ant-design/icons';
import ConfirmationModal from '../../priceAdjustment/components/ConfirmationModal';

const Container = styled.div`
  background-color: white;
  margin: 20px 0 0 0;
  width: 40%;
  height: fit-content;
`;

const Title = styled.p`
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.dirtyGrey};
`;

const Description = styled.p`
  padding-left: 30px;
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.quibbleBackgroundGrey};
`;

const BaseText = styled.p`
  padding-left: 30px;
  font-size: ${({ fontSize }) => fontSize || '14px'};
`;

const MultiplierButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 0px 0px 30px 20px;
  width: 95%;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  width:100%;
`;

const MultiplierButton = styled.button`
  padding: 0.375rem;
  width: 30%;
  font-size: 16px;
  color: ${(props) => props.theme.quibbleBlue}};
  border: 1px solid ${(props) => props.theme.quibbleBlue}};
  background-color: white;
  border-radius: 4px;

  &:hover{
    background-color:  ${(props) => props.theme.quibbleBlue};
    color: white;
    cursor: pointer;
  }
`;

const BottomButtonsDiv = styled.div`
  width: 92%;
  background: inherit;
  height: 76px;
  padding-left: 33px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ResetButton = styled(SecondaryButton)`
  width: 50%;
  height: 44px;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'}};
`;

const SaveButton = styled(PrimaryButton)`
  width: 100%;
  height: 44px;
  border-radius: 4px 0 0 4px;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'}};
`;


const ArrowButton = styled(PrimaryButton)`
  width: 20%;
  height: 44px;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.white};
  background: rgba(0, 91, 106, 0.2);
  border-radius: 0 4px 4px 0;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'}};
`;

const SaveButtonDiv = styled.div`
  width: 50%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.quibbleBlue};
  border-radius: 4px;
`;

const SaveMenuDiv = styled.div`
  ${BodyCSS}
  width: 162px;
  height: 80px;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  transition: max-height 0.15s linear;

  position: absolute;
  bottom: 62px;
  right: 16px;
`;

const SaveMenuButton = styled.button`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  text-align: left;
  background: ${({ theme }) => theme.quibbleBlue};

`;

const PriceAdjustmentMultiplier = ({ onMultiplierChange, disabled, onSave, onSaveAndSend }) => {
  const [value, setValue] = useState(0);
  const [showSend, setShowSend] = useState(false);
  const [modalSettings, setModallSettings] = useState({
    visible: false,
    title: '',
    confirmText: '',
    onCancelFnc: null,
    onSubmitFunc: null,
    bodyTextFirst: '',
    bodyTextSecond: '',
  })

  const changeMultiplier = useCallback((val) => {
    let multiplier = value + val;
    if (multiplier > 100 || multiplier < -100) return;
    setValue(multiplier);
    onMultiplierChange(multiplier);
  }, [setValue, onMultiplierChange, value]);

  const onCloseModal = () => {
    setModallSettings({
      visible: false,
      title: '',
      confirmText: '',
      onCancelFnc: null,
      onSubmitFunc: null,
      bodyTextFirst: '',
      bodyTextSecond: '',
    })
  };

  const onSaveClick = () => {
    setModallSettings((prev) => ({
      ...prev,
      visible: true,
      title: 'Save Rules',
      confirmText: 'Save',
      onCancelFnc: onCloseModal,
      onSubmitFunc: onSave,
      bodyTextFirst: 'Are you sure you want to save the rules?',
    }))
    setShowSend(false);
  }

  const onSaveMenuClick = () => {
    setShowSend(!showSend)
  }

  const onSaveWithSend = () => {
    setModallSettings((prev) => ({
      ...prev,
      visible: true,
      title: 'Save and Send Rules',
      confirmText: 'Confirm',
      onCancelFnc: onCloseModal,
      onSubmitFunc: onSaveAndSend,
      bodyTextFirst: 'Are you sure you want to save the rules and send it to your PMS?',
      bodyTextSecond: 'This will also send to your PMS.',
    }))
    setShowSend(false);
  }

  const onResetClick = () => {
    setValue(0);
    onMultiplierChange(0);
  }

  return (
    <Container>
      <Title>PRICE ADJUSTMENT</Title>
      <Description>Adjust prices by percentage</Description>
      <BaseText fontSize="20px">Multiplier: {value}</BaseText>

      <MultiplierButtons>
        <Row>
          <MultiplierButton onClick={() => changeMultiplier(+1)} positive >
            +1
          </MultiplierButton>
          <MultiplierButton onClick={() => changeMultiplier(+5)} positive >
            +5
          </MultiplierButton>
          <MultiplierButton onClick={() => changeMultiplier(+10)} positive >
            +10
          </MultiplierButton>
        </Row>

        <Row>
          <MultiplierButton onClick={() => changeMultiplier(-1)} > -1 </MultiplierButton>
          <MultiplierButton onClick={() => changeMultiplier(-5)}> -5 </MultiplierButton>
          <MultiplierButton onClick={() => changeMultiplier(-10)} > -10 </MultiplierButton>
        </Row>
      </MultiplierButtons>
      <BottomButtonsDiv>
        <ResetButton onClick={onResetClick} >
          Reset
        </ResetButton>
        <SaveButtonDiv>
          <SaveButton onClick={onSaveClick} >
            Save
          </SaveButton>
          <ArrowButton onClick={onSaveMenuClick}>
            <CaretDownOutlined />
          </ArrowButton>
        </SaveButtonDiv>
        <SaveMenuDiv maxHeight={showSend ? '80px' : '0px'} >
          <SaveMenuButton onClick={onSaveClick}>
            <BodyText>
              Save
            </BodyText>
          </SaveMenuButton>
          <SaveMenuButton onClick={onSaveWithSend}>
            <BodyText>
              Save and Send
            </BodyText>
          </SaveMenuButton>
        </SaveMenuDiv>
      </BottomButtonsDiv>
      <ConfirmationModal {...modalSettings}/>
    </Container>
  );
};

export default PriceAdjustmentMultiplier;
