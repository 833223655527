import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Users from "./Users";
import Property from "./Property";
import Markets from "./Markets";
import Events from "./Events";
import Reservation from "./Reservation";
import BusinessTools from './businessTools';
import Subscription from './Subscription';
import PortfolioAnalytics from './PortfolioAnalytics';


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  users  : Users,
  markets  : Markets,
  property  : Property,
  reservation  : Reservation,
  businessTools  : BusinessTools,
  subscription: Subscription,
  portfolioAnalytics: PortfolioAnalytics,
  events: Events,
});

export default createRootReducer
