import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { AppstoreOutlined, CheckOutlined, ToolOutlined, DollarOutlined } from '@ant-design/icons';
import { DatePicker, Dropdown, Checkbox, Tooltip } from 'antd';
import moment from 'moment';
import { SecondaryButton as Button } from '../../../../../components/ReusableComponents/Button/Button'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem 0.5rem 2rem;

  .ant-picker {
    height: 3rem;
  }

  .ant-picker-input > input {
    font-weight: 300;
  }
`

const FilterContainer = styled.div`
`;

const ToolsContainer = styled.div`
  margin-left: auto;
  padding-right: 8px;

  .anticon {
    padding: 12px;
    border: 0;
    border-radius: 50px;
    svg {
      height: 24px;
      width: 24px;
    }

    &:hover {
      background-color: #E8E8E8;
      svg {
        fill: #1BB3CD;
      }
    }
  }

  .ant-dropdown-open {
    background-color: #1BB3CD !important;
    svg {
      fill: white !important;
    }
  }
`

const DropdownItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 8px;

  .anticon {
    padding-right: 16px;
    svg {
      fill: #1BB3CD;
    }
  }

  img {
    padding-right: 8px;
  }

  span {
    font-size: 1rem;
    padding-right: 8px;
  }

  .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
  }

  &.dropdown-item--button {
    display: block;
    button {
      margin: auto;
      margin-top: 1rem;
    }
  }
`;


const FilterToolsContainer = ({
  charts,
  column,
  hiddenCharts,
  onChangeYear,
  onChangeColumn,
  onFilterChartVisibility,
}) => {
  const PUBLIC_IMAGES = process.env.PUBLIC_URL + '/assets/images';

  const [date, setDate] = useState(moment());
  const [isFilterVisibilityDropdownOpen, setIsFilterVisibilityDropdownOpen] = useState(false);
  const [intHiddenCharts, setInHiddenCharts] = useState([]);

  const history = useHistory();

  const redirectPage = () =>{
    history.push('/price-adjustment')
  }

  const changeYear = (date) => {
    setDate(date);
    onChangeYear(date.year());
  }

  const changeColumn = (e) => {
    const value = parseInt(e.currentTarget.dataset.value);
    if (value === column) return;
    else onChangeColumn(value);
  }

  const disabledDates = (d) => {
    const nextYear = moment().year() + 1;
    return d.year() > nextYear;
  }

  const filterChartVisibility = (e) => {
    let clonedHiddenCharts = _.cloneDeep(intHiddenCharts);
    const checked = e.target.checked;
    const value = parseInt(e.target.value);
    if (checked) {
      _.remove(clonedHiddenCharts, (n) => n === value);
    } else {
      clonedHiddenCharts.push(value);
      clonedHiddenCharts = _.uniq(clonedHiddenCharts);
    }

    setInHiddenCharts(clonedHiddenCharts);
  }

  const submitChartVisiblityFilters = () => {
    onFilterChartVisibility(intHiddenCharts);
    setIsFilterVisibilityDropdownOpen(!isFilterVisibilityDropdownOpen);
  }

  const toggleFilterVisibilityDropdownOpen = () => {
    if (!_.isEqual(hiddenCharts, intHiddenCharts)) {
      setInHiddenCharts(hiddenCharts);
    }
    setIsFilterVisibilityDropdownOpen(!isFilterVisibilityDropdownOpen);
  }

  const columnItems = [
    {
      key: '1',
      label: (
        <DropdownItem data-value={1} onClick={changeColumn}>
          <CheckOutlined style={{ visibility: column === 2 ? 'hidden' : 'initial' }} />
          <img src={`${PUBLIC_IMAGES}/column_one.svg`} alt="column_one" />
          <span>1 Column</span>
        </DropdownItem>
      ),
    },
    {
      key: '2',
      label: (
        <DropdownItem data-value={2} onClick={changeColumn}>
          <CheckOutlined style={{ visibility: column === 1 ? 'hidden' : 'initial' }} />
          <img src={`${PUBLIC_IMAGES}/column_two.svg`} alt="column_two" />
          <span>2 Columns</span>
        </DropdownItem>
      )
    },
  ]

  const chartVisibilityItems = charts?.map((chart, ix) => {
    return {
      key: ix,
      label: (
        <DropdownItem>
          <Checkbox value={ix} disabled={chart?.disabledCheckbox} checked={chart?.disabledCheckbox || !intHiddenCharts.includes(ix)} onClick={filterChartVisibility}>{chart.title}</Checkbox>
        </DropdownItem>
      ),
    }
  });

  chartVisibilityItems.push({
    key: charts.length,
    label: (
      <DropdownItem className='dropdown-item--button'>
        <Button onClick={submitChartVisiblityFilters}>Apply</Button>
      </DropdownItem>
    )
  })

  return (
    <Container>
      <FilterContainer>
        <DatePicker
          value={date}
          onChange={changeYear}
          picker="year"
          disabledDate={disabledDates}
        /> 
      </FilterContainer>
      <ToolsContainer>
        <Tooltip title="Adjust Pricing" placement="bottom" overlayInnerStyle={{backgroundColor:'black', color: '#E8E8E8'}} showArrow={false}> 
          <DollarOutlined onClick={redirectPage}  />
        </Tooltip>
        <Dropdown
          open={isFilterVisibilityDropdownOpen}
          menu={{ items: chartVisibilityItems }}
          trigger={['click']}
          overlayClassName='dropdown-overlay--with-button'
          onClick={toggleFilterVisibilityDropdownOpen}
        >
          <Tooltip title="Configure Charts" placement="bottom" overlayInnerStyle={{backgroundColor:'black', color: '#E8E8E8'}} showArrow={false}> 
          <ToolOutlined />
          </Tooltip>
        </Dropdown>
        <Dropdown
          menu={{ items: columnItems }}
          trigger={['click']}
        >
          <Tooltip title="Layout" placement="bottom" overlayInnerStyle={{backgroundColor:'black', color: '#E8E8E8'}} showArrow={false}> 
          <AppstoreOutlined />
          </Tooltip>
        </Dropdown>
      </ToolsContainer>
    </Container >
  )
}

export default FilterToolsContainer;