
import axios from "axios";
import {jwtExpiration} from "../../src/constants/GlobalConstant";
import { getData, storeData } from "../util/indexDbHelper";

import serviceV2 from "../util/APIUtility";

let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });
export const user = {
  userProfile: async (data) => {
    let dataVal  =  await service.post("/api/user_profile", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  dashboard: async (data) => {
    let dataVal  =  await service.post("/api/analytics", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;

  },
  userProfileUpdate: async (data) => {
    let dataVal  =  await service.post("/api/update_user_profile", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  updateSubscriptionInDB: async (data) => {
    let dataVal  =  await service.post("/api/update_subscription_in_db", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  getUserDetail: async (data) => {
    let dataVal  =  await service.post("/api/user_data", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  getUserLists: async (data) => {
    let dataVal  =  await service.post("/api/user_list", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  userChangePassword: async (data) => {
    let dataVal  =  await service.post("/api/user_change_password", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  userUpdateStatusValue: async (data) => {
    let dataVal  =  await service.post("/api/update_user_status", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  getCountryDetail: async (data) => {
    let dataVal  =  await service.post("/api/get_country_data", data, {headers: {'Content-Type': 'application/json'}});
    jwtExpiration(dataVal);
    return dataVal;
  },
  getModalData: async (data) => {
    let cachedData = await getData('modalData', data);

    if(!cachedData) {
      let dataVal  =  await service.post("/api/getInsightModalData", data, {headers: {'Content-Type': 'application/json'}});
      jwtExpiration(dataVal);
      await storeData('modalData', dataVal.data, data);
      return dataVal;
    }

    let response = { data: cachedData }
    return response;
    
  },
  getForgotPasswordStatus: async (data) => {
    let dataVal  =  await service.post("/api/getForgotPasswordStatus", data, {headers: {'Content-Type': 'application/json'}});
    return dataVal;
  },
  checkForgotPasswordToken: async (data) => {
    let dataVal  =  await service.get("/api/checkForgotPasswordToken", {params: data}, {headers: {'Content-Type': 'application/json'}});
    return dataVal;
  },
  setRevenueSummary: async (data) => {
    let dataVal  =  await service.post("/api/setRevenueSummarySchedule",  data, {headers: {'Content-Type': 'application/json'}});
    return dataVal;
  },
  getUserMetricsSummary: async (options) => {
    let result = await serviceV2.get(`/api/users/self/metrics_summary`, options);
    return result.data.data;
  },
  getUserPortfolio: async (options) => {
    let result = await serviceV2.get(`/api/users/self/portfolio`, options);
    return result.data.data;
  },
  requestDemoCall: async (options) => {
    let result = await serviceV2.put(`/api/users/self/requestDemoCall`, options);
    return result;
  },
  updateUserParameters: async (options) => {
    const { body } = options;
    let result = await serviceV2.post(`/api/users/parameters`, body);
    return result;
  },
  getCurrencies: async (options) => {
    let result = await serviceV2.get(`/api/listings/currencies`, options);
    return result.data.data;
  },
};
