import React, {memo, useCallback} from 'react';
import styled, { css } from "styled-components";
import MenuButton from './MenuButton';
import { BodyCSS } from '../../../../components/ReusableComponents/Card-Body/BodyCSS';

const MenuGroupDiv = styled.div`
  width: 100%;
  height: 1000px;
  padding: ${({padding}) => padding};
  display: flex;
  flex-direction: column;
  max-height: ${({expandHeight}) => expandHeight};
  transition: ${({defaultTransition}) => defaultTransition};
  overflow: hidden;
  gap: 8px;
  ${({hoverCss}) => hoverCss};
`;

const MenuGroup = ({sidebarCollapsed, expandMenu, menuArray, isHoverActive, toggleCollapseNav, isBottomNav }) => {

  const hoverCss = useCallback(() => {
    if (!sidebarCollapsed) return null;
    if (!isHoverActive) return null;
    return css`
      ${BodyCSS}
      position: absolute;
      width: 198px;
      height: fit-content;
      max-height: ${(menuArray.length * 48) + 6 }px;
      padding: 8px;
      box-sizing: border-box;
      transition: all 0.15s linear;
      ${isBottomNav ? 'bottom: 0px;' : 'top: 0px;'}
      left: 40px;
      gap: 8px;
    `;
  }, [sidebarCollapsed, menuArray.length, isHoverActive, isBottomNav]);

  return (
    <MenuGroupDiv defaultTransition={sidebarCollapsed ? 'unset' : 'all 0.15s linear'} padding={expandMenu && !sidebarCollapsed ? '8px 0' : '0px'} expandHeight={expandMenu && !sidebarCollapsed ? `${menuArray.length * 48 + 6}px` : '0px'}  hoverCss={hoverCss}>
      {menuArray.map((data)=> (<MenuButton toggleCollapseNav={toggleCollapseNav} key={data.label}  sidebarCollapsed={sidebarCollapsed} label={data.label} menuValue={data.url} subUrls={data.subUrls} isDisabled={data.isDisabled} isHoverActive={isHoverActive} />))}
    </MenuGroupDiv>
  )
}

export default memo(MenuGroup);