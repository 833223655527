import React, { useState } from 'react';
import SendOTP from '../SendOTP'
import VerifyOTP from '../VerifyOTP';
import ResetPasswordV2 from '../ResetPasswordV2';

const ForgotPasswordV2 = () => {
  const [step, setStep] = useState('sendOTP');
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');

  const handleSetStep = (stepValue, emailValue = '', otp='') => {
    setStep(stepValue);
    setEmail(emailValue);
    setOTP(otp);
  };
  return (
    <div>
      {step === 'sendOTP' && <SendOTP setStep={handleSetStep} />}
      {step === 'verifyOTP' && <VerifyOTP email={email} setStep={handleSetStep} />}
      {step === 'resetPassword' && <ResetPasswordV2 email = {email} otp={otp} />}
    </div>
  );
};

export default ForgotPasswordV2;
