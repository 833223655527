/* Custom Hook that you can use if you want a component to do an action when a click event happening outside of it*/

import { useCallback, useEffect, useRef } from 'react';

const useClickOutsideListener = (onOutsideClick) => {
    const ref = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onOutsideClick();
        }
    }, [onOutsideClick]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return { ref };
}

export default useClickOutsideListener;