import React from 'react';
import styled from 'styled-components';
import { LoaderCSS } from './CommonCSS';

const FullBodyLoaderDiv = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;

  ${LoaderCSS}
`;

const FullBodyLoader = () => {
  return (
    <FullBodyLoaderDiv />
  )
}

export default FullBodyLoader;