
import React from "react";

import {Route, Switch} from "react-router-dom";

import errorsPages from "./../errorsPages/index";

import Listings from "../listings/Listings";
import Parameters from "./Parameters/Parameters";

const settings = ({match}) => (
  <Switch>
    <Route path={`${match.url}/listings`} component={Listings}/>
    <Route path={`${match.url}/parameters`} component={Parameters}/>
    <Route exact component={errorsPages}/>
  </Switch>
);
export default settings;
