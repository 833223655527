import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from '../../../components/ReusableComponents/Scrollbar/Scrollbar';
import MarketPropertyFilter from '../sharedComponents/MarketPropertyFilter/MarketPropertyFilter';
import PropertyAnalyticsTable from './components/PropertyAnalyticsTable';
import { now } from '../../../constants/GlobalConstant';

const PropertyBody = styled(LayoutBody)`  
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const Property = () => {

  const [monthYear, setMonthYear] = useState(now);
  const [searchString, setSearchString] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [dynamicPricing, setDyanmicPricing] = useState(false);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, []);

  const setSearchStringFunc = useCallback((value) => {
    setSearchString(value)
  },[]);

  const onFilterToggle = useCallback(() => {
    setShowFilter((prev) => !prev);
  }, []);

  return (
    <PropertyBody>
      <MarketPropertyFilter dynamicPricing={dynamicPricing} setDyanmicPricing={setDyanmicPricing} monthYear={monthYear} setMonthYearVal={setMonthYearVal} setSearchStringFunc={setSearchStringFunc} isProperty={true} onFilterToggle={onFilterToggle} showFilter={showFilter}/>
      <PropertyAnalyticsTable dynamicPricing={dynamicPricing} monthYear={monthYear} searchString={searchString} showFilter={showFilter} />
    </PropertyBody>
  )
}

export default Property;
