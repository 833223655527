import React, {memo} from 'react';
import styled from 'styled-components';
import {BodyCSS} from '../Card-Body/BodyCSS';
import CardLoader from './Components/Card';
import ThumbnailLoader from './Components/Thumbnail';
import FullDescLoader from './Components/FullDesc';
import FullBodyLoader from './Components/FullBody';
import TableLoader from './Components/Table';
import { useMemo } from 'react';
import CalendarLoader from './Components/Calendar';

const LoaderBody = styled.div`
  ${BodyCSS}
  box-shadow: none;
  border-radius: inherit;
  padding: inherit;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${({display}) => display};
`;

const SkLoaderOver = ({isLoading, type="card", tableRows}) => {

  const loader = useMemo(() => ({
    card: <CardLoader />,
    thumbnail: <ThumbnailLoader />,
    description: <FullDescLoader />,
    full: <FullBodyLoader />,
    table: <TableLoader tableRows={tableRows} />,
    calendar: <CalendarLoader />
  }), [tableRows])

  return (
    <LoaderBody display={isLoading ? 'initial' : 'none'}>
      {loader[type] || <CardLoader />}
    </LoaderBody>
  )
}

export default memo(SkLoaderOver);