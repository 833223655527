import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AuthItemDiv, AuthTitle, ImgProp } from '../sharedComponents/CommonCss';
import { BodyText } from '../../../components/ReusableComponents/Text/Text';
import clip from '../../../assets/images/generic/clip.svg'
import escapia from '../../../assets/images/pms/logo_escapia.png';
import { useParams } from 'react-router-dom';


import Escapia from '../Signup/subComponentes/PMS/Escapia';
import { message } from 'antd';
import FinishSignup from '../Signup/components/FinishSignup';
import useMutation from '../../../components/customHook/useMutation';

const PMSDiv = styled.div`
  ${ AuthItemDiv }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SelectPMSButton = styled.button`
  width: 460px;
  height: 70px;
  background: none;
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  cursor: pointer;
  margin: 54px 0 0 0;
  position: relative;
`;

const ClipImg = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto;
`;


const PMSImages = {
  '': <BodyText> Choose Property Management System </BodyText>,
  Escapia: <ImgProp src={ escapia } alt='escapia' width='124px' height='32px'/>
}

const PMS = () => {
  const { token, email } = useParams()
  const  [isCompleted, setIsCompleted] = useState(false)

  const { request, loading, data, error } = useMutation({
    method: "POST",
    url: 'api/escapia_profile_setup',
    onComplete: (data) => {
      setIsCompleted(true)
      console.log(data.message)
      message.success(data.message, 10);
    },
    onError: (e) => {
      message.error(e?.response?.data.message || "Error Completing request", 10);
    }
  })
  const onSubmitForm = async (values) => {
    try {
      const payload = {
        userId: token,
        ...values,
        propertyManagerId: Number(values.propertyManagerId),
      }
      request(payload)
      console.log(payload)
      // const updateProfile = await auth.profileSetup({
      //   ...values,
      //   pms: dispatchPMS,
      //   type: dispatchPMS,
      //   string: signupString,
      // })

      // if (updateProfile.data.status){
      //   message.success('Update PMS successful');
      //   setAuthMenuFunc('finish')
      //   return;
      // };

      // message.error(updateProfile.data.errors.errors[0] || 'Error on update PMS attempt')

    } catch (e) {
      message.error('Update PMS error. Please try again')
    }

  }

  const PMSContent = {
    Escapia: <Escapia loading={loading} onSubmitForm={ onSubmitForm }/>,
  };


  return (
    <PMSDiv>
      {!isCompleted &&<>
        <AuthTitle>
          Complete Pms Connection
        </AuthTitle>
        <SelectPMSButton>
          { PMSImages['Escapia'] } <br/>
          { email }
          <ClipImg src={ clip } alt='clip'/>
        </SelectPMSButton>
        { PMSContent['Escapia'] }
      </> }
      { isCompleted && <FinishSignup  />}
    </PMSDiv>
  )
}

export default PMS;
