import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import PricingCalendarFilter from './components/CalendarFilter';
import CalendarBody from './components/CalendarBody';
import _ from 'lodash';
import { currentDate } from 'constants/GlobalConstant';
import CalendarFooter from './components/CalendarFooter';


const PricingCalendarDiv = styled(LayoutBody)`
  display: flex;
  height: calc(100vh - 64px);
  max-height: ${({maxHeight}) => maxHeight};
  flex-direction: column;
  align-items: center;
  font-family: Commissioner;
`;

const todayDate = new Date(currentDate),
      saturday = new Date(todayDate.setDate(todayDate.getDate() - (todayDate.getDay() + 1))),
      saturdayCopy = new Date(saturday),
      nextSunday = new Date(saturdayCopy.setDate(saturdayCopy.getDate() + 8));

const PricingCalendar = () => {

	const { calendarListing, isFetchingCalendarListing } = useSelector(({ property }) => property);

  const [showMarketfilter, setShowMarketFilter] = useState(false);
  const [calendarContent, setCalendarContent] = useState([]);
  const [calendarListingReduced, setCalendarListingReduced] = useState({});
  const [calendarWeekDates, setCalendarWeekDates] = useState({startDate: saturday, endDate: nextSunday });
  const [showMarketObject, setShowMarketObject] = useState({});
  const [selectedProperty, setSelectedProperty] = useState('');
  const [showReservations, setShowReservations] = useState(false);

  useEffect(() => {
    if (calendarListing.length) {
      const reducedList = calendarListing.reduce((outputData, currentData) => {
        const {market, ...rest} = currentData;
        return {
          ...outputData,
          [market]: outputData[market] ? [...outputData[market], {...rest} ]: [{...rest}]
        }
      }, {})

      setCalendarListingReduced(reducedList);
    }

  }, [calendarListing]);

  useEffect(() => {
    if(!_.isEmpty(showMarketObject)) {
      const marketObjectsKeys = Object.keys(showMarketObject);
      const reducedMarketsFilterList = marketObjectsKeys.reduce((outputData, currentData) => {
        return [...outputData, ...showMarketObject[currentData]]
      }, [])

      if (!reducedMarketsFilterList.length) {
        setCalendarContent(calendarListing);
        return;
      };
      const filterCalendarListing = calendarListing.filter((data) => reducedMarketsFilterList.includes(data.propertyName));
      const arrangedCalendar = filterCalendarListing.sort((a, b) => a.propertyName.localeCompare(b.propertyName))
      setCalendarContent(arrangedCalendar);
      return;
    }

    // say something here
    const arrangedCalendarListing = calendarListing.sort((a, b) => a.propertyName.localeCompare(b.propertyName));
    setCalendarContent(arrangedCalendarListing);
  }, [showMarketObject, calendarListing])



  const onChangeDates = useCallback((mode, pickerValue) => {
    if (mode === 'prev') {
      setCalendarWeekDates((prev) => {
        const {startDate, endDate} = prev;
        const prevSunday = startDate.setDate(startDate.getDate() - 7);
        const prevSaturday = endDate.setDate(endDate.getDate() - 7);

        return {
          startDate: new Date(prevSunday),
          endDate: new Date(prevSaturday),
        }
      });
      return;
    };

    if (mode === 'next') {
      setCalendarWeekDates((prev) => {
        const {startDate, endDate} = prev;
        const prevSunday = startDate.setDate(startDate.getDate() + 7);
        const prevSaturday = endDate.setDate(endDate.getDate() + 7);

        return {
          startDate: new Date(prevSunday),
          endDate: new Date(prevSaturday),
        }
      });
      return;
    };

    if(mode === 'picker') {
      setCalendarWeekDates(pickerValue);
      return;
    }

  }, [])


  const onShowMarketfilter = useCallback(() => {
    setShowMarketFilter((prev) => !prev);
  }, []);

  const onSetSelectedProperty = useCallback((id) => {
    setSelectedProperty(id);
  }, []);



  const onMarketFilterChange = useCallback((values, marketLabel) => {
    if(marketLabel === 'clear') {
      setShowMarketObject({});
      return;
    };
    setShowMarketObject((prev) => ({...prev, [marketLabel]: values}));
  }, []);

  const setShowReservationsFunc = useCallback(() => {
    setShowReservations((prev) => !prev);
  }, []);



  return (
      <PricingCalendarDiv maxHeight={selectedProperty.length ? '930px' : 'calc(100vh - 180px)'}>
        <PricingCalendarFilter selectedProperty={selectedProperty} onShowMarketfilter={onShowMarketfilter} showMarketfilter={showMarketfilter} onChangeDates={onChangeDates} calendarWeekDates={calendarWeekDates}  setShowReservationsFunc={setShowReservationsFunc}/>
          <CalendarBody
            onChangeDates={onChangeDates}
            showMarketObject={showMarketObject}
            onMarketFilterChange={onMarketFilterChange}
            calendarContent={calendarContent}
            showMarketfilter={showMarketfilter}
            calendarListingReduced={calendarListingReduced}
            isFetchingCalendarListing={isFetchingCalendarListing}
            selectedProperty={selectedProperty}
            onSetSelectedProperty={onSetSelectedProperty}
            calendarWeekDates={calendarWeekDates}
            showReservations={showReservations}
          />
        <CalendarFooter/>
    </PricingCalendarDiv>

  )
}

export default PricingCalendar;
