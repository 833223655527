import React from 'react';
import styled from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import LogoSection from './components/LogoSection';
import NavbarMenu from './components/NavbarMenu';

const NavBarBody = styled.aside`
  ${BodyCSS}
  border-radius: unset;
  min-width: ${({drawerWidth}) => drawerWidth};
  max-width: 276px;
  position: relative;
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;

  transition: min-width 0.15s linear;
`;



const Navbar = ({toggleCollapseNav, sidebarCollapsed }) => {
  return (
    <NavBarBody drawerWidth={sidebarCollapsed ? '68px' : '276px'}>
     <LogoSection toggleCollapseNav={toggleCollapseNav} sidebarCollapsed={sidebarCollapsed} />
     <NavbarMenu sidebarCollapsed={sidebarCollapsed} toggleCollapseNav={toggleCollapseNav} />
    </NavBarBody>
  )
}

export default Navbar;
