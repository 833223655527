import React, {memo, useCallback} from 'react';
import styled, { useTheme } from 'styled-components';
import { currencyFormatter } from '../../../../../../../util/helper';
import moonImg from 'assets/images/pricing-calendar/moon.svg'
import moonWhite from 'assets/images/pricing-calendar/moon-white.svg'
import { currentDate } from '../../../../../../../constants/GlobalConstant';
import Reservations from './Reservations';
import { isUndefined } from 'lodash';


const CalendarDayDiv = styled.button`
  border: none;
  outline: none;
  width: none;
  min-width: 180px;
  width: 100%;
  min-height: 116px;
  height: 100%;
  background: ${({background}) => background};
  color: ${({fontColor}) => fontColor};
  opacity: ${({opacity}) => opacity || '1'};
  border-right: 1px solid white;
  border-bottom: 1px solid white;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: ${({overflow}) => overflow};
`; 

const CalendarPMSSpan = styled.span`
  font-size:${({theme}) =>  theme.s5};
  color: inherit
  font-weight: 400;
  line-height: 100%;
`;

const DateSpan = styled.span`
  font-family: Commissioner;
  color: inherit
  font-size: ${({theme}) =>  theme.s3};

  position: absolute;
  top: 6px;
  left: 8px;
`;

const MinNightsDiv = styled.div`
  display: flex;
  flex-direciton:row;
  align-items: center;

  position: absolute;
  top: 6px;
  right: 8px;
`;

const MinNightsSpan = styled(CalendarPMSSpan)`
  font-size:${({theme}) =>  theme.s3};
  margin: 0 0 0 2px;
`;

const MoonImg = styled.img`
  image-rendering: -webkit-optimize-contrast;
`;

const todayDate = new Date(currentDate);

const CalendarPropertyDay = ({data, selectedMonth, reservation, dayIndex, showReservations, onContextMenuTrigger, onHandleLeftClick, selectedDates}) => {
  const {demand, rate, date} = data;

  const theme = useTheme();

  const isSelected = selectedDates.includes(date);

  const dateSplit = date.split('-');

  const getDate = new Date(date);
  const checkDatePrev = getDate < todayDate;

  const checkNotEqualMonth = (selectedMonth + 1) !== Number(dateSplit[1]) || checkDatePrev;
  // const checkNotEqualMonth =  checkDatePrev;


  const setBackground = useCallback(() => {
    const backgroundColor = {
      LOW: theme.calendarLowMetric,
      MEDIUM: theme.calendarMediumMetric,
      HIGH:  theme.calendarHighMetric,
      NA: theme.calendarNoMetric,
    }

    if (isSelected) {
      return theme.activeBlue;
    }

    if (checkNotEqualMonth)  {
      return theme.calendarNoMetric
    };

    if(demand && demand.length) {
      return backgroundColor[demand];
    }

    return theme.calendarNoMetric;

  }, [theme, checkNotEqualMonth, demand, isSelected]);

  //get the keys of the reservations for the day
  const currentReservations = Object.keys(reservation).filter((reservationKey) => reservation[reservationKey].index === dayIndex);

  const disabledDay = checkNotEqualMonth;

  const opacityVisible =  !disabledDay ||  showReservations;


  const onHandleContextMenu = (e) => {
    e.preventDefault();
    if (disabledDay) return;
    onContextMenuTrigger(date,e);
  }

  const onClickHandle = () => {
    onHandleLeftClick(date);
  };

  
  return (
    <CalendarDayDiv fontColor={isSelected ? theme.white : theme.quibbleBackgroundGrey} onClick={onClickHandle} onContextMenu={onHandleContextMenu}  background={setBackground()} disabled={disabledDay} opacity={opacityVisible ? '1': '0.5'} overflow={dayIndex===6 ? 'hidden' : 'visible' }>
      <CalendarPMSSpan>
        {currencyFormatter(rate?.pms) || '-'}
      </CalendarPMSSpan>      
      <DateSpan>
        {dateSplit[2] || '-'}
      </DateSpan>
      <MinNightsDiv>
        <MoonImg src={isSelected ? moonWhite : moonImg} alt="moonImg" />
        <MinNightsSpan>{rate?.minNights}</MinNightsSpan>
      </MinNightsDiv>
      {
        /* since there is a possibility for a checkout on a sunday and a checkin on the same sunday there's a possibility of 2 reservation items on that day that's why it's an array */
        currentReservations.map((reservationId, index) => (
          <Reservations showReservation={!isUndefined(reservation[currentReservations[index]]) && showReservations} reservation={reservation[reservationId] || {}}/>
        ))
      }
    </CalendarDayDiv>
  )
}

export default memo(CalendarPropertyDay);