import React, { createContext, useContext, useReducer } from 'react';

// Initial state
const initialState = {
  viewType: 'rmView',
  quarter: 0,
  remove_pms_charge: true,
};

// Create a generic context
const DataContext = createContext();

// Define a reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VIEW_TYPE":
      return {
        ...state,
        viewType: action.payload,
      };
    case "SET_QUARTER":
      return {
        ...state,
        quarter: action.payload,
      };
    case "SET_REMOVE_PMS_CHARGE":
      return {
        ...state,
        remove_pms_charge: action.payload,
      };
    // other cases
    default:
      return state;
  }
};

// Define a context provider component
export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

// Create a custom hook to access the context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  const { state, dispatch } = context;

  return { state, updateData: dispatch };
};
