import styled, { css } from "styled-components";
import { BodyCSS } from "../../../../../components/ReusableComponents/Card-Body/BodyCSS";

export const CardCSS = css`
  ${BodyCSS}
  margin: auto;
  padding: 24px 30px;
  height: 659px;

  margin-top: 1rem;

  @media screen and (max-width: 991px) {
  }

  .rc-container {
    margin: auto;
    padding-top: 2rem;
    padding-left: 5.2px;

    text.recharts-text {
      fill: #283334;
    }
  }
`;

export const CardTitle = styled.h1`
  font-size: ${({ theme }) => theme.s6};
  color: ${({ theme }) => theme.bodyFg};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-right: auto;
`;

export const PageTitle = styled(CardTitle)`
  font-family: Inter;
  font-size: ${({ theme }) => theme.s9};
`;
