import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { BodyText } from '../../../../../components/ReusableComponents/Text/Text';


const GuestyDiv = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GuestyButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;

const ConnectButton = styled(PrimaryButton)`
  width: 460px;
  margin: 32px 0px;
  background: none;
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  color: ${({theme}) => theme.quibbleBlue};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #E8E8E8;

  margin: 32px 0 0 0;
  position: relative;
  text-align: center;
`;

const OrText = styled(BodyText)`
  position: absolute;
  top: -4px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  margin: auto;
  width: fit-content;
  height: fit-content;
  background: white;
`;


const GuestyWithID = ({onSubmitForm, onChangePMS}) => {

  const [guestyParams, setGuestyParams] = useState({
    client_id: '',
    client_secret: '',
  });
  
  const onFormChange = (formKey, {value}) => {
    setGuestyParams((prev) => ({
      ...prev,
      [formKey]: value,
    }));
  };

  const onTokenSubmit = () => {
    const values = {
      ...guestyParams,
    }
    onSubmitForm(values)
  };

  const onChangeClient = () => {
    onChangePMS('Guesty');
  };

  return (
    <GuestyDiv>
      <FormItemInput formKey="client_id" value={guestyParams.client_id}  label="Client ID" onChangeFunc={onFormChange} />
      <FormItemInput formKey="client_secret" value={guestyParams.client_secret}  label="API Key" onChangeFunc={onFormChange} />
      <GuestyButton disabled={!guestyParams.client_id.length || !guestyParams.client_secret.length} onClick={onTokenSubmit}> Submit </GuestyButton>
      <LineDiv>
        <OrText> or </OrText>
      </LineDiv>
      <ConnectButton onClick={onChangeClient}> Connect Using Integration Token</ConnectButton>
    </GuestyDiv>
  )
}

export default GuestyWithID;