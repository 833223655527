import React, { useState, useRef } from "react";
import styled from "styled-components";
import { message } from "antd";

import { AuthItemDiv, AuthTitle, FootText} from '../../sharedComponents/CommonCss';
import SkLoaderOver from 'components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { BodyText } from "../../../../components/ReusableComponents/Text/Text";
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';
import useMutation from 'components/customHook/useMutation';


const VerificationDiv = styled.div`
  ${AuthItemDiv}
  margin: 100px;
`;

const OTPDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-diretion: row;
  align-items: center;
  gap: 16px;
`;

const OTPInput = styled.input`
  width: 40px;
  height: 50px;
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  outline: none;
  margin: 54px 0 0 0;
  text-align: center;
  font-size: 24px;
`;

const SubmitButton = styled(PrimaryButton)`
  width: 188px;
  margin: 32px 0 16px 0;
`;

const ResendButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;

  color: ${({theme}) => theme.quibbleBlue};
  font-size: 16px;

  &:hover{
    text-decoration: underline;
  }

  &:disabled {
    color: ${({theme}) => theme.disabledGrey};
    text-decoration: none;
  }
`;

const Verification = ({createToken, setAuthMenuFunc, setSignupString}) => {
  const [OTPValues, setOTPValues] = useState([null, null , null, null]);
  const OTPHasNull = OTPValues.some((data) => data === null);

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  const refArray = [input1, input2, input3, input4];

  const onInputChange = (index) => (e) => {
    const value = e?.nativeEvent?.data;
    if (Number(value) > 9  || Number(value)  < 0) return;
    setOTPValues((prev) => {
      const TempOTPValues = prev;
      TempOTPValues[index] = value;
      return [...TempOTPValues]
    })

    if (index === 3) {
      input1.current.focus();
      return;
    }

    refArray[index + 1].current.focus();
  };

  const { request: requestResend, loading: resendingOtp } = useMutation({
		method: "POST",
		url: '/api/resend_resend_otp',
		onComplete: () => {
			message.success('OTP Resend request succesful, please check your email.')
		},
		onError: (e) => {
			message.error('Invalid verification request. Please check your email.')
		}
	})

  const { request: verifyOtp, loading: verifyingOtp } = useMutation({
		method: "POST",
		url: '/api/email_verification_using_otp',
		onComplete: (data) => {
      setSignupString(data?.result?.userId)
      setAuthMenuFunc('PMS')
		},
		onError: (e) => {
			message.error('Invalid otp verification. Please check your email.')
		}
	})

  const onSubmitVerify = () => {
    const payload = { otp: Number(OTPValues.join('')), otpToken: createToken}
		verifyOtp(payload)
  };

  const onResend = () => {
    requestResend({ token: createToken})
  }
  

  return (
    <VerificationDiv>
        <AuthTitle>
          Verification
        </AuthTitle>
        <BodyText>
          Your One Time Passcode has been sent to your registered email
        </BodyText>
        <OTPDiv>
          <OTPInput ref={input1} value={OTPValues[0]} onChange={onInputChange(0)} />
          <OTPInput ref={input2} value={OTPValues[1]} onChange={onInputChange(1)}/>
          <OTPInput ref={input3} value={OTPValues[2]} onChange={onInputChange(2)}  />
          <OTPInput ref={input4} value={OTPValues[3]} onChange={onInputChange(3)} />
        </OTPDiv>
        <SubmitButton disabled={OTPHasNull || verifyingOtp} onClick={onSubmitVerify}>
          Verify
        </SubmitButton>
        <FootText>
          Didn't receive an email? <ResendButton disabled={resendingOtp} onClick={onResend}> Resend </ResendButton> 30s
        </FootText>
       <SkLoaderOver isLoading={!createToken.length} type='calendar' />
    </VerificationDiv>
  )
}

export default Verification;