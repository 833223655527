import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import TableMenu from 'components/ReusableComponents/TableMenus/TableMenu';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
// import AdjustQueueMenu from 'components/ReusableComponents/TableMenus/AdjustQueueMenu';
import { useDispatch } from 'react-redux';
import { getPropertyCompetitors, getPropertyDetails, getPropertyModel, getPropertyOverviewMetrics, getPropertyPricing, getPropertyTableMetrics, setWorkingQeue } from '../../../appRedux/actions';
import { useData } from '../../../util/DataStore/DataContext';
import { currencyFormatter } from '../../../util/helper';


const ActionableTableBody = styled.div`
  width: 100%;
  max-width: 1600px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: ${({gap}) => gap};
`;


const ActionableTableParentDiv = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 1600px;
  margin: 0 0 0 0;
`;

const NameButton = css`
  width: fit-content;
  height: fit-content;;
  border: none;
  margin: 0px;
  padding: 0px;
  background: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    background: inherit;
    color: inherit;
  }
`;

const NameLink = styled(BodyText)`
  margin: 0px;
  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
  }
`;

const ActionableTable = ({actionableData, searchString, setShowPreview, isFetchingActionableQueue, monthYear}) => {

  const [checkedValues, setCheckedValues] = useState([]);
  
  const dispatch = useDispatch();
  const {state} = useData();


  const onNameClick = useCallback(({propertyId, index}) => {
    const month = monthYear.getMonth() + 1;
    const year =  monthYear.getFullYear();

    dispatch(getPropertyPricing({month, year, propertyId}));
    dispatch(getPropertyDetails({propertyId, params: {month, year}}));
    dispatch(getPropertyOverviewMetrics({propertyId, params: {
      month, year, viewType: state.viewType
    }}));
    dispatch(getPropertyTableMetrics({propertyId, params: {month, year}}));
    dispatch(getPropertyModel({propertyId}));
    dispatch(getPropertyCompetitors({propertyId}));


    setShowPreview({visible: true, id: propertyId, index});
  }, [setShowPreview, dispatch, state, monthYear]);

  const onRemoveQeueFromMenu = (id) => {
    dispatch(setWorkingQeue(actionableData.filter((data) => data?.id !== id)));
  };

  const actionableTable =  [
    {
      label: 'property' ,
      width: '400px',
      align: 'left',
      sorter: true,
      sorterKey: 'name',
      customRender: (data, arg1, arg2, index ) => (<ButtonWithFunction onClickFunc={onNameClick} ButtonStyle={NameButton} value={{propertyId: data.id, index: index}} label={<NameLink>{data.name}</NameLink>} />),
    },
    {
      label: 'date',
      width: '200px',
      dataIndex: 'date',
      sorter: true,
    },
    {
      label: 'day',
      width: '200px',
      align: 'center',
      dataIndex: 'day',
    },
    {
      label: 'opt price',
      width: '400px',
      sorter: true,
      sorterKey: 'optimizedPrice',
      customRender: (data) => (currencyFormatter(data.optimizedPrice)),
    },
    {
      label: <CaretDownOutlined />,
      width: '24px',
      customRender: (data) => <TableMenu otherFunctions={onRemoveQeueFromMenu} data={data} monthYear={new Date(data.date)} type="actionable"/>
    }
  ];

  const onCheckBoxValues = useCallback((values) => {
    setCheckedValues(values);
  }, []);

  // const clearCheckValues = useCallback(() => {
  //   dispatch(setWorkingQeue(actionableData.filter((_,index) => !checkedValues.includes(index))))
  //   setCheckedValues([]);
  // }, [dispatch, actionableData, checkedValues]);

  const filteractionableData = actionableData.filter((data) => data?.name?.toLowerCase().includes(searchString));

  // const checkedValuesData = checkedValues.map((data) => {
  //   return filteractionableData[data];
  // });
  
  return (
    <ActionableTableBody>
      <ActionableTableParentDiv>
        {/* <AdjustQueueMenu type='workingqueue' adjustQueueData={checkedValuesData} otherFunctions={clearCheckValues}/> */}
        <QuibbleTable isLoading={isFetchingActionableQueue} columns={actionableTable} data={filteractionableData} checkedRows={checkedValues} withCheckBox={true} pagination={true} paginationSize={10}  onCheckBoxChange={onCheckBoxValues} />
      </ActionableTableParentDiv>
    </ActionableTableBody>
  )
}

export default ActionableTable;