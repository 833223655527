import React, { Fragment, useCallback } from 'react';
import styled from 'styled-components';
import {BodyText} from 'components/ReusableComponents/Text/Text'
// import PropertyImage from './subComponents/PropertyImage';
// import PropertyImage from 'components/ReusableComponents/Image/PropertyImage';
import ImageCarousel from 'components/ReusableComponents/Image/ImageCarousel';
import SwitchButton from 'components/ReusableComponents/Switch/SwitchButton';
import {Button} from 'components/ReusableComponents/Button/Button';
import piechartblue from 'assets/images/market/piechartblue.svg';
import { ComparisonInfo } from '../../CommonCSS';

import SliderWithFunction from '../../../../../../components/ReusableComponents/Slider/SliderWithFunction';
import { currencyFormatter } from '../../../../../../util/helper';
import { useDispatch } from 'react-redux';
import { getPropertyModel, getPropertyCompetitors } from '../../../../../../appRedux/actions';
import FormItemInput from '../../../../../../components/ReusableComponents/Form/FormItemInput';

const PropertyNameText = styled(BodyText)`
  margin: 0 0 22px 0;
`;

const PropertyNameBoldText = styled(BodyText)`
  font-weight: 500;
  margin: 12px 0 0 0;
`;

const SliderLabel = styled(BodyText)`
  align-self: flex-start;
  margin: 20px 0 0 0;
`;

const AmenitiesDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0 0 0;
`;

const PercentShareDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 18px 0 0 0;
  // margin: 56px 0 0 0;


  font-size: ${({theme}) => theme.s6};
  color: ${({theme}) => theme.quibbleBlue};
  font-family: Commissioner;
  font-weight: 300;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin: 16px 0 0 0;
`;

const PropertyInfo = ({propertyDetails, propertyModel, selectedDate, model, setModel, isListing}) => {

  const {name, tumbnailUrl, id} = propertyDetails;
  const {images} = propertyModel;

  const dispatch = useDispatch();

  const imageArray = images && images.length ? images.map((data) => ({imageData : data?.image_url, rating: data?.score })) : [{imageData: tumbnailUrl, rating: 0}];

  const submit = () => {
    dispatch(getPropertyModel({propertyId: id, params: { ...model, date: selectedDate }}))
    dispatch(getPropertyCompetitors({propertyId: id}));
  }; 

  const onSliderFunction = useCallback((property, value) => {
    setModel((prev) => ({...prev, [property]: value, }))
  },[setModel]);

  const onInputChange = useCallback((property, data) => {
    if(data.value < 0) {
      return;
    };
    setModel((prev) => ({...prev, [property]: data.value, }))
  },[setModel]);

  return (
    <ComparisonInfo>
      <PropertyNameText>
        {name}
      </PropertyNameText>
      <ImageCarousel imageArray={imageArray} />
      <PropertyNameBoldText>
        {currencyFormatter(model.price)} Night
      </PropertyNameBoldText>
      {!isListing && 
      <Fragment>
        <FormItemInput value={model.price} label='Price:' margin={"0px 0px 0px 0px"} type='number'  formKey="price" onChangeFunc={onInputChange}/>
        {/* <SliderWithFunction value={model.price} min={1} max={5000} property="price" onChangeFunction={onSliderFunction}  /> */}
        <SliderLabel>
          Image Score: {model.imageScore}
        </SliderLabel>
        <SliderWithFunction value={model.imageScore} min={1} max={10} step={0.1} property="imageScore" onChangeFunction={onSliderFunction}  />
      </Fragment> }
      <SliderLabel>
        Bedrooms: {model.bedrooms}
      </SliderLabel>
      <SliderWithFunction value={model.bedrooms} min={1} max={10} property="bedrooms" onChangeFunction={onSliderFunction}  />
      <SliderLabel>
        Rating: {model.ratingValue}
      </SliderLabel>
      <SliderWithFunction value={model.ratingValue} min={0} max={5} step={0.1} property="ratingValue" onChangeFunction={onSliderFunction}  />
      <SliderLabel>
        Reviews: {model.reviewCount}
      </SliderLabel>
      <SliderWithFunction value={model.reviewCount} min={0} max={100} property="reviewCount" onChangeFunction={onSliderFunction}  />
      {/* <SliderLabel>
        Downtown: {model.downtown} km
      </SliderLabel>
      <SliderWithFunction value={model.downtown} min={0} max={100} property="downtown" onChangeFunction={onSliderFunction}  /> */}
      <SliderLabel>
        Min Stay: {model.minNights} nights
      </SliderLabel>
      <SliderWithFunction value={model.minNights} min={0} max={20} property="minNights" onChangeFunction={onSliderFunction}  />
      {/* <SliderLabel>
        This house has all the amenities
      </SliderLabel> */}
      <SliderLabel>
        Scenic Views: {model.landscapeViews ? 'YES' : 'NO'}
      </SliderLabel>
      {/* <AmenitiesDiv>
        <BodyText>
          Scenic Views: 
        </BodyText>
        <SwitchButton value={model.landscapeViews} property='landscapeViews' onSwitchFunc={onSliderFunction} />
      </AmenitiesDiv> */}
      <AmenitiesDiv>
        <BodyText>
        Hot Tub/Jacuzzi:
        </BodyText>
        <SwitchButton value={model.jacuzzi} property='jacuzzi' onSwitchFunc={onSliderFunction} />
      </AmenitiesDiv>
      <AmenitiesDiv>
        <BodyText>
          Pool:
        </BodyText>
        <SwitchButton value={model.pool} property='pool' onSwitchFunc={onSliderFunction} />
      </AmenitiesDiv> 
      <SubmitButton onClick={submit}> Submit </SubmitButton>
      <PercentShareDiv>
        <img src={piechartblue} alt='piechart'/>
        {((propertyModel?.market_share || 0) * 100).toFixed(0)}% share
      </PercentShareDiv>
    </ComparisonInfo>
  )
}

export default PropertyInfo;