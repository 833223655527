import React from 'react';
import styled from 'styled-components';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import { PrimaryButton } from '../../../../components/ReusableComponents/Button/Button';
import { CloseButton, ModalBody, ModalClose, ModalContent, ModalContentbody, ModalHeader, ModalTitle } from '../../../../containers/Subscription/components/CommonCSS' ;

const ContinueButton = styled(PrimaryButton)`
  width: 146px;
  height: 50px;
  border-radius: 6px;
  // background: #1BB3CD;
  color: white;
  font-size: 1rem;
  margin: 42px 0 0 0;

  outline: none;
  border: none;
  cursor: pointer;

  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  align-self: flex-end;
`;

const ModalContentApply = styled(ModalContent)`
  align-items: flex-start;
`;

const ModalHeaderApply = styled(ModalHeader)`
  text-align: left;
`;

const ClosebuttonApply = styled(CloseButton)`
  position: absolute;
  top: 0px;
  right: 4px;
`;

const ModalContentBodyApply = styled(ModalContentbody)`
  text-align: left;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;


const ApplyModal = ({isVisible, setIsvisible, onSubmitDynamicPricing}) => {

  const onCloseModal = () => {
    setIsvisible(false);
  };

  const onContinue = () => {
    setIsvisible(false);
    onSubmitDynamicPricing();
  };

  return (
    <ModalBody $display={isVisible ? 'flex' : 'none'}>
      <ModalContentApply>
        <ModalHeaderApply>
          <ModalTitle>
            Apply Silent Dynamic Pricing?
          </ModalTitle>
          <ClosebuttonApply onClick={onCloseModal}>
            <ModalClose>
              X
            </ModalClose>
          </ClosebuttonApply>
        </ModalHeaderApply>
        <ModalContentBodyApply>
          <BodyText>
            This advanced feature is an add-on priced at <BoldSpan>$10/month per property listing</BoldSpan>. There's no charge for listings where this feature remains off.
          </BodyText>
          <ContinueButton onClick={onContinue}>
            Continue
          </ContinueButton>
        </ModalContentBodyApply>
      </ModalContentApply>
    </ModalBody>
  )
}

export default ApplyModal;