import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Select } from 'antd';
import DatePickerWithFunction from 'components/ReusableComponents/DatePicker/DatePickerWithFunction';
import { FULL_MONTH_NAME } from 'constants/GlobalConstant';
import moment from 'moment';
import { getPropertyDetails, getPropertyModel, getPropertyCompetitors, getPropertyOverviewMetrics, getPropertyTableMetrics, getPropertyAnalytics } from '../../../../appRedux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { currentDate, now } from '../../../../constants/GlobalConstant';
import { useData } from '../../../../util/DataStore/DataContext';
import { lightScrollbar, darkScrollbar } from "components/ReusableComponents/Scrollbar/Scrollbar";
import useClickOutsideListener from '../../../../customHooks/useClickOutsideListener';
import { LeftOutlined } from '@ant-design/icons';


const {Option} = Select;


const FilterBody = styled.div`
  width: 100%;
  max-width: 1776px;
  height: 48px;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
`;

const DatePickerStyle = css`
  width: 200px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  margin: 0 0 0 0;
  font-family: Commissioner;
`;

const SelectDiv = styled.div`
  width: 156px;
  height: 100%;
  position: relative;
  margin: 0 8px 0 0;
`;

const ButtonCSS = css`
  border-radius: 4px;
  outline: none;
  border: 1px solid #00BBD2;
  background: #F6F6F6;
  color: #00BBD2;
  text-align: center;
  font-family: Commissioner;
  cursor: pointer;

  &:hover, &.active {
    background: #00BBD2;
    color: white;
  }

  &:disabled {
    border: none;
    background: ${({theme}) => theme.disabledGrey};
    color: ${({theme}) => theme.white};
  }

`;

const SelectButton = styled.button`
  ${ButtonCSS}
  width: 156px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const BackButton = styled.button`
  ${ButtonCSS}
  width: 48px;
  height: 48px;
  margin: 0 8px 0 0;
`;

const AdjustmentSelectProperty = styled(Select)`
  width: 40px;
  height: 100%;
  margin: 0 8px 0 0;
  position: relative;
  font-family: Commissioner;

  .rc-virtual-list-holder {
      ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
  }
`;

const Boldspan = styled.span`
  font-weight: 600;
`;

const PropertyFilter = ({monthYear, setMonthYearVal, property, model, propertyDetails, selectedProperty, setSelectedProperty}) => {

  const {propertyAnalytics, isFetchingPropertyAnalytics} = useSelector(({ property}) => ({...property,}));
  const [showSelect, setShowSelect] = useState(false)

  const {state} = useData();
  const {propertyId} = property || {};

  const history = useHistory();

  const dispatch = useDispatch();
  const {firstReservationBookedDate} = propertyDetails;

  useEffect(() => {
    if (!property) history.push('/home');
  }, [property, history]);

  useEffect(() => {
    const month = now.getMonth() + 1;
    const year =  now.getFullYear();
    setSelectedProperty((prev) => [propertyId, ...prev]);
    dispatch(getPropertyAnalytics({params: {month: month, year: year}}));
  }, [propertyId, setSelectedProperty, dispatch])

  //on change month year
  useEffect(() => {
    if(monthYear && selectedProperty.length) {
      const month = monthYear.getMonth() + 1;
      const year =  monthYear.getFullYear();
      dispatch(getPropertyDetails({propertyId: selectedProperty[0], params: {month, year}}));
      dispatch(getPropertyOverviewMetrics({propertyId: selectedProperty[0], params: {
        month, year, viewType: state?.PROPERTY_DATA?.viewType || 'rmView'
      }}));
      dispatch(getPropertyTableMetrics({propertyId: selectedProperty[0], params: {month, year}}));
    
    };
  }, [selectedProperty ,dispatch,state?.PROPERTY_DATA?.viewType, monthYear]);

  useEffect(() => {
    if (Array.isArray(selectedProperty) && selectedProperty.length > 0 && selectedProperty[0] !== undefined && selectedProperty[0] !== null) {
      dispatch(getPropertyModel({ propertyId: selectedProperty[0] }));
      dispatch(getPropertyCompetitors({ propertyId: selectedProperty[0] }));
    }
  }, [dispatch, selectedProperty]);

  //on select month
  const onDatePickerChange = (value) => {
    const dateSplit = value.split('-');
    const month = FULL_MONTH_NAME.indexOf(dateSplit[0]) + 1;
    const formatMonth = month < 10 ? `0${month}` : month;
    const newDate = `${dateSplit[1]}-${formatMonth}-01`;
    const selectedDate = moment(newDate, 'YYYY-MM-DD').startOf('D').utc().toDate();
    setMonthYearVal(selectedDate);

    //model conditions
    const newDateCurrent = new Date(currentDate);

    const nextElevenMonths = new Date(newDateCurrent.setMonth(newDateCurrent.getMonth() + 11));
    if (selectedDate.getMonth() === new Date(currentDate).getMonth() && selectedDate.getFullYear() === new Date(currentDate).getFullYear()) {
      dispatch(getPropertyModel({propertyId: selectedProperty[0], params: { ...model, date: currentDate }}))
      return;
    };

    if ( selectedDate >= new Date(currentDate) && selectedDate <= nextElevenMonths ) {
      dispatch(getPropertyModel({propertyId: selectedProperty[0], params: { ...model, date: newDate }}))
      return;
    }

  };

  const disabledDate = current => {
    const anyDate = new Date(current);

    const futureDateLimit = new Date();
    futureDateLimit.setMonth(futureDateLimit.getMonth() + 11);

    const reservationDate = new Date(firstReservationBookedDate);


    if (anyDate < reservationDate) {
      return true;
    }

    if (anyDate > futureDateLimit) {
      return true;
    }
    return false;

  };

  const onPropertyChange = (value) => {
    setSelectedProperty((prev) => [value, ...prev]);
    setShowSelect(false); 
  };

  const onSelectClick = () => {
    setShowSelect((prev) => !prev); 
  };

  const onSelectClose = () => {
    setShowSelect(false); 
  };

  const onBackClick = () => {
    const newQeue = [...selectedProperty];
    newQeue.shift(); 
    setSelectedProperty(newQeue);
  };

  const {ref} = useClickOutsideListener(onSelectClose);

  return (
    <FilterBody>
      <BackButton disabled={selectedProperty.length === 1} onClick={onBackClick}>
        <LeftOutlined />
      </BackButton>
      <SelectDiv ref={ref}>
        <AdjustmentSelectProperty optionLabelProp="children"  defaultActiveFirstOption={false} onChange={onPropertyChange} getPopupContainer={trigger => trigger.parentNode} open={showSelect} dropdownMatchSelectWidth={false}> 
          {propertyAnalytics.map((data) => (<Option disabled={selectedProperty[0] === data.id} key={data.id} value={data.id}> <Boldspan>{data.key} </Boldspan>&nbsp; &nbsp; - &nbsp; &nbsp; {data.market}</Option>))} 
        </AdjustmentSelectProperty>
        <SelectButton disabled={isFetchingPropertyAnalytics} className={showSelect === true && 'active'} onClick={onSelectClick}> { isFetchingPropertyAnalytics ? 'Fetching Properties' : 'Change Property'} </SelectButton>
      </SelectDiv>
      <DatePickerWithFunction DatepickerStyle={DatePickerStyle} picker="month" format={"MMMM-YYYY"} value={moment(monthYear, 'MMMM-YYYY').startOf('M').add(1, 'd')} onChangeFunc={onDatePickerChange} disabledDate={disabledDate} />
    </FilterBody>
  );
}

export default PropertyFilter;
