import React, {Suspense, lazy, memo} from 'react';
import styled from 'styled-components';
import Calendar from './subComponents/CalendarBody/Calendar/Calendar';
import { media } from '../../../../styles/stylesheet';

const MarketSelectionFilter = lazy(() => import('./subComponents/CalendarBody/MarketSelection/MarketSelectionFilter'));

const CalendarBodyDiv = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.bpxxxl} {
    justify-content: flex-start;
  }

`;

const CalendarBody = ({showMarketfilter,showReservations, calendarContent, calendarListingReduced, onMarketFilterChange, onChangeDates, showMarketObject, isFetchingCalendarListing, calendarWeekDates, selectedProperty, onSetSelectedProperty}) => {
  return (
    <CalendarBodyDiv>
      <Suspense fallback={null}>
        <MarketSelectionFilter showMarketObject={showMarketObject}  onMarketFilterChange={onMarketFilterChange} showMarketfilter={showMarketfilter} calendarListingReduced={calendarListingReduced} />
      </Suspense>
      <Calendar showReservations={showReservations} isFetchingCalendarListing={isFetchingCalendarListing}  onChangeDates={onChangeDates} calendarContent={calendarContent} calendarWeekDates={calendarWeekDates} selectedProperty={selectedProperty} onSetSelectedProperty={onSetSelectedProperty}/>
    </CalendarBodyDiv>
  )
}

export default memo(CalendarBody);