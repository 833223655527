import React, { useState, useEffect } from 'react';
import { Table, Button, Tag, Dropdown, Menu } from 'antd';
import { EllipsisOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const CustomTag = styled(Tag)`
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 14px;
  border: none;

  &.active {
    background-color: #e6ffed;  
    color: #52c41a;            
  }

  &.inactive, &.unknown {
    background-color: #D9D9D9;
    color: #8c8c8c; 
  }
`;

const BlueLineWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -35px;
  width: 30px;
  height: 67.5px;

  border-left: 1px solid #99E7EE; 
  border-bottom: 1px solid #99E7EE;
`;

const GroupTable = ({ filteredPropertyGroups, showDeleteConfirm, onEditGroup, expandAllRows }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (expandAllRows) {
      const allRowKeys = filteredPropertyGroups.map(group => group.id);
      setExpandedRowKeys(allRowKeys);
    } else {
      setExpandedRowKeys([]); 
    }
  }, [expandAllRows, filteredPropertyGroups]);

  const expandedRowRender = (group, type) => {
    const nonParentListings = group.listings.filter(listing => !listing.propertyGroupParent);
    
    const expandedColumns = [
      {
        title: 'Listing Name',
        dataIndex: 'name',
        key: 'name',
        width: '450px',
        render: (text, record) => (
          <span><BlueLineWrapper />{text}</span>
        ),
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        width: '300px',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '300px',
        render: (text) => <span>{type}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'active',
        key: 'active',
        width: '300px',
        render: (active) => (
          <CustomTag className={active ? 'active' : 'inactive'}>
            {active ? 'ACTIVE' : 'INACTIVE'}
          </CustomTag>
        ),
      },
      {
        title: '',
      },
    ];

    const expandedDataSource = nonParentListings.map((listing) => ({
      key: listing.id,
      ...listing,
    }));

    return (
      <>
        <Table
          columns={expandedColumns}
          dataSource={expandedDataSource}
          pagination={false}
          showHeader={false} 
          style={{ margin: '20px 0 20px 50px', padding: 0, background: '#FBFBFB' }}
        />
      </>
    );
  };

  const columns = [
    {
      title: 'Property',
      key: 'parentListing',
      align: 'left',
      width: '500px',
      render: (group) => {
        const parentListing = group.listings.find(listing => listing.propertyGroupParent);
        return parentListing ? parentListing.name : 'No Parent Listing';
      },
    },
    {
      title: 'Market',
      key: 'parentListingCity',
      width: '300px',
      render: (group) => {
        const parentListing = group.listings.find(listing => listing.propertyGroupParent);
        return parentListing ? parentListing.city : 'No City';
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '300px',
      key: 'type',
    },
    {
      title: 'Status',
      key: 'activeStatus',
      width: '300px',
      render: (group) => {
        const parentListing = group.listings.find(listing => listing.propertyGroupParent);
        if (parentListing) {
          return parentListing.active ? (
            <CustomTag className="active">ACTIVE</CustomTag>
          ) : (
            <CustomTag className="inactive">INACTIVE</CustomTag>
          );
        } else {
          return <CustomTag className="unknown">Unknown</CustomTag>;
        }
      },
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (record) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => onEditGroup(record.id)}>
              Edit
            </Menu.Item>
            <Menu.Item onClick={() => showDeleteConfirm(record.id)}>
              Ungroup
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const customExpandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <UpOutlined onClick={(e) => onExpand(record, e)} />
    ) : (
      <DownOutlined onClick={(e) => onExpand(record, e)} />
    );

  return (
    <Table
      rowKey="id"
      pagination={{ pageSize: 10, showSizeChanger: true }}
      dataSource={filteredPropertyGroups}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => expandedRowRender(record, record.type),
        expandIcon: customExpandIcon,
        expandedRowKeys, // Pass the expanded row keys to control which rows are expanded
        onExpand: (expanded, record) => {
          if (expanded) {
            setExpandedRowKeys((prev) => [...prev, record.id]);
          } else {
            setExpandedRowKeys((prev) => prev.filter((id) => id !== record.id));
          }
        },
      }}
    />
  );
};

export default GroupTable;
