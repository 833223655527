

export const storeItem = (name, data) => {
    if(!isSupported()){
        console.log( ' <<<<< local storage not supported >>>>>.' );
        return false;
    }
    try {
        localStorage.setItem(name, JSON.stringify(data));
        return true
    } catch (error) {
        return false;
    }
}

export const getData = ( name ) => {
    if(!localStorage.getItem(name)) return null;

    try {
       
        let data = JSON.parse(localStorage.getItem(name));
        return data;

    } catch (error) {
        return null;
    }
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}



const isSupported = () => {
    var mod = 'modernizr';
    try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);

        if(typeof JSON == "undefined") return false;

        return true;
    } catch(e) {
        return false;
    }
}