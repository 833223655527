import { css, keyframes } from "styled-components";

const placeHolderShimmer  = keyframes`
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
`;

export const LoaderCSS = css`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 60%, rgba(255, 255, 255, 0) 40%), #D9D9D9;
  background-repeat: repeat-y;        
  background-size: 20% 100%;        
  background-position: 0 0;     
`;