import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import star from 'assets/images/market/quadrants/star.svg';
import gift from 'assets/images/market/quadrants/gift.svg';
import heart from 'assets/images/market/quadrants/heart.svg';
import rain from 'assets/images/market/quadrants/rain.svg';

const ClassificationButton = styled(Button)`
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 20px;
    height: 20px;
    filter: brightness(0) invert(0.5);
  }

  &.active {
    ${({ activeFilter }) => {
      switch (activeFilter) {
        case 'underpriced':
          return `
            background: #B78EF4;
            border-color: #B78EF4;
            color: #fff;

            img {
              filter: brightness(0) invert(1);
            }
          `;
        case 'overpriced':
          return `
            background: #EDB548;
            border-color: #EDB548;
            color: #fff;

            img {
              filter: brightness(0) invert(1);
            }
          `;
        case 'productIssues':
          return `
            background: #F38B64;
            border-color: #F38B64;
            color: #fff;

            img {
              filter: brightness(0) invert(1);
            }
          `;
        case 'star':
        default:
          return `
            background: #00BBD2;
            border-color: #00BBD2;
            color: #fff;

            img {
              filter: brightness(0) invert(1);
            }
          `;
      }
    }}
  }
`;

const ClassificationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ClassificationButtons = ({ activeFilter, setActiveFilter, myPortfolioData }) => {
  return (
    <ClassificationButtonsWrapper>
      <ClassificationButton
        className={activeFilter === 'star' ? 'active' : ''}
        onClick={() => setActiveFilter('star')}
        activeFilter={activeFilter}
      >
        <img src={star} alt="Star" />
        Stars ({myPortfolioData?.star?.length || 0})
      </ClassificationButton>

      <ClassificationButton
        className={activeFilter === 'underpriced' ? 'active' : ''}
        onClick={() => setActiveFilter('underpriced')}
        activeFilter={activeFilter}
      >
        <img src={gift} alt="Underpriced" />
        Underpriced ({myPortfolioData?.underpriced?.length || 0})
      </ClassificationButton>

      <ClassificationButton
        className={activeFilter === 'overpriced' ? 'active' : ''}
        onClick={() => setActiveFilter('overpriced')}
        activeFilter={activeFilter}
      >
        <img src={heart} alt="Overpriced" />
        Overpriced ({myPortfolioData?.overpriced?.length || 0})
      </ClassificationButton>

      <ClassificationButton
        className={activeFilter === 'productIssues' ? 'active' : ''}
        onClick={() => setActiveFilter('productIssues')}
        activeFilter={activeFilter}
      >
        <img src={rain} alt="Product Issues" />
        Product Issues ({myPortfolioData?.productIssues?.length || 0})
      </ClassificationButton>
    </ClassificationButtonsWrapper>
  );
};

export default ClassificationButtons;
