import React from "react";
import { DescriptionBody } from "../../sharedComponents/CommonCSS";
import MarketPropertyMetrics from "../../sharedComponents/MarketPropertyMetrics/MarketPropertyMetrics";
import MarketInfo from "./subComponents/MarketDescription/MarketInfo";
import { useSelector } from "react-redux";
import styled from "styled-components";

const MarketDescriptionBody = styled(DescriptionBody)`
  height: 200px;
`;


const MarketDescription = ({monthYear}) => {

  const { marketDetails, isFetchingMarketDetails} = useSelector(({ markets }) => markets);

  const metrics = {
    adr: marketDetails[0]?.currentYearAdr,
    occ: marketDetails[0]?.currentYearOccupancy,
    totalRent: marketDetails[0]?.currentYearTotalRevenue,
  };

  return (
    <MarketDescriptionBody>
      <MarketInfo marketDetails={marketDetails[0] || {}} isFetchingMarketDetails={isFetchingMarketDetails}/>
      <MarketPropertyMetrics monthYear={monthYear} metrics={metrics} isFetchingMetrics={isFetchingMarketDetails} isMarket={true}/>
    </MarketDescriptionBody>
  )
}

export default MarketDescription;
