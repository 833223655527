import {
  USER_MARKETS_LIST,
  USER_MARKETS_LIST_SUCCESS,
  USER_MARKETS_YEARLY_DATA,
  USER_MARKETS_YEARLY_DATA_SUCCESS,
  UPDATE_MARKETS_STATUS,
  UPDATE_MARKETS_STATUS_SUCCESS,
  GET_MARKETS_DETAIL,
  SET_MARKETS_DETAIL,
  GET_CALENDAR_METRIC,
  SET_CALENDAR_METRIC
} from "constants/ActionTypes";
import { GET_MARKET_ANALYTICS, GET_MARKET_DETAILS, GET_MARKET_PRICING, GET_MARKET_PROPERTY_TABLE, GET_PORTFOLIO_PRICING, HIDE_MESSAGE, GET_MARKET_PRICING_RULES, GET_PORTFOLIO_PRICING_RULES, GET_LISTINGS } from "../../constants/ActionTypes";

/*** markets list */
export const getMarketsList = (request) => {
  return {
    type    : USER_MARKETS_LIST,
    payload : request
  };
};

/*** markets list success*/
export const updateMarketsList = (response) => {
  return {
    type: USER_MARKETS_LIST_SUCCESS,
    payload: response
  };
};

/*** calendar metric list */
export const getMarketMetric = (request) => {
  return {
    type    : GET_CALENDAR_METRIC,
    payload : request
  };
};

/*** calendar metric list success*/
export const updateMarketMetric = (response) => {
  return {
    type: SET_CALENDAR_METRIC,
    payload: response
  };
};

/*** Get last and current year data */
export const getDataAnalysis = (request) => {
  return {
    type    : USER_MARKETS_YEARLY_DATA,
    payload : request
  };
};

/*** set last and current year data */
export const setDataAnalysis = (response) => {
  return {
    type: USER_MARKETS_YEARLY_DATA_SUCCESS,
    payload: response
  };
};

/*** update markets status*/
export const updateMarketsStatus = (request) => {
  return {
    type: UPDATE_MARKETS_STATUS,
    payload: request
  };
};

/*** Get markets detail */
export const getMarketsDetail = (request) => {
  return {
    type: GET_MARKETS_DETAIL,
    payload: request
  };
};

/*** Set markets detail */
export const setMarketsDetail = (request) => {
  return {
    type: SET_MARKETS_DETAIL,
    payload: request
  };
};

/*** update markets status success*/
export const showMarketsMessage = (response) => {
  return {
    type: UPDATE_MARKETS_STATUS_SUCCESS,
    payload: response
  };
};

/*** update markets status success*/
export const hideMarketsMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const getMarketAnalytics = (values) => {
  return {
    type: GET_MARKET_ANALYTICS,
    payload: values,
  }
};

export const getMarketPropertyTable = (values) => {
  return {
    type: GET_MARKET_PROPERTY_TABLE,
    payload: values,
  }
};

export const getMarketPricing = (values) => {
  return {
    type: GET_MARKET_PRICING,
    payload: values,
  }
};


export const getPortfolioPricing = (values) => {
  return {
    type: GET_PORTFOLIO_PRICING,
    payload: values,
  }
};


export const getMarketDetails = (values) => {
  return {
    type: GET_MARKET_DETAILS,
    payload: values,
  }
};

export const getMarketPricingRules = (values) => {
  return {
    type: GET_MARKET_PRICING_RULES,
    payload: values,
  }
};

export const getPortfolioPricingRules = (values) => {
  return {
    type: GET_PORTFOLIO_PRICING_RULES,
    payload: values,
  }
};

export const getListings = (values) => {
  return {
    type: GET_LISTINGS,
    payload: values,
  }
}
