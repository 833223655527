import React, {memo, useMemo, useCallback} from 'react';
import styled, {useTheme, css} from 'styled-components';
import { abbreviationFormatter } from '../../../../util/helper';

import ArrowUp from '../../../../assets/images/ArrowUp.svg';
import ArrowDown from '../../../../assets/images/ArrowDown.svg';

const TextFormatDiv = styled.div`
  width: fit-content;
  font-family: Commissioner;
  display: flex;
  color: ${({color}) => color};
  flex-direction: ${({direction}) => direction};
  ${({otherStyle}) => otherStyle};
`;

const ValueStyle = styled.h1`
  margin: 0;
  color: ${({typeColor}) => typeColor || 'inherit'}; 
  ${({fontSize}) => fontSize};
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ValueSymbol = styled.span`
  font-family: Commissioner;
  ${({fontSize}) => fontSize};
`;

const LabelStyle = styled.span`
  color: #697273;
  font-weight: 500;
  font-size: 12px;
  ${({fontSize}) => fontSize};
`;

const CaretPositive = styled.img.attrs({ src: ArrowUp })`
  width: ${({size}) => size.width};
  height: ${({size}) => size.height};
  color: ${({theme}) => theme.positive};
  align-self: center;
`;

const CaretNegative = styled.img.attrs({ src: ArrowDown })`
  width: ${({size}) => size.width};
  height: ${({size}) => size.height};
  color: ${({theme}) => theme.negative};
  align-self: center;
`;

const TextFormat = ({value, symbol, color="blue", direction="column", size="large", label="", type="", otherStyle=''}) => {
  const theme = useTheme();
  const absValue = Math.abs(value);

  const colorObject = {
    [color]: color,
    blue: theme.quibbleBlue,
    foreground: theme.bodyFg,
    purple: theme.quibblePurple,
    gold: theme.quibbleGold,
  }

  const typeColor = {
    positive: theme.positive,
    negative: theme.negative,
  }

  const fontSize = useMemo(() => {
    return {
      large: {
        mainText: css`
          font-size: ${theme.s8};
          font-weight: 600;
        `,
        symbol: css`
          font-size: ${theme.s8};
          font-weight: 300;
        `,
        label: css`
          font-size: ${theme.s2};
        `,
        width: '24px',
        height: '24px'
      },
      medium: {
        mainText: css`
          font-size: ${theme.s5};
          font-weight: ${type.length ? '500' : '600' };
        `,
        symbol: css`
          font-size: ${theme.s5};
          font-weight:  ${type.length ? '300' : '400' };
        `,
        label: css`
          font-size: ${theme.s2};
        `,
        width: '16px',
        height: '16px'
      },
      small: {
        mainText: css`
          font-size: ${theme.s3};
          font-weight: 500;
        `,
        symbol: css`
          font-size: ${theme.s3};
          font-weight: 300;
        `,
        label: css`
          font-size: ${theme.s2};
        `,
        width: '12px',
        height: '12px'
      },
      horizontal: {
        mainText: css`
          font-size: ${theme.s4};
          font-weight: 500;
        `,
        symbol: css`
          font-size: ${theme.s4};
          font-weight: 300;
        `,
        label: css`
          font-size: ${theme.s4};
          font-weight: 400;
          margin: 0 0 0 2px;
        `,
        width: '16px',
        height: '16px'
      }
    }
  }, [type, theme]);

  const typeSign = {
    positive: <CaretPositive size={fontSize[size]} />,
    negative: <CaretNegative size={fontSize[size]} />
  };

  const ValueWithSymbol = useCallback(() => {
    if (symbol === '%') {
      return (
        <>{abbreviationFormatter(absValue)}<ValueSymbol fontSize={fontSize[size].symbol}>{symbol}</ValueSymbol></>
      );
    }

    return <><ValueSymbol fontSize={fontSize[size].symbol}>{symbol}</ValueSymbol>{abbreviationFormatter(absValue)}</>;
  }, [symbol, fontSize, size, absValue]);

  return (
    <TextFormatDiv color={colorObject[color]} direction={direction} otherStyle={css`${otherStyle}`}>
      <ValueStyle label={label} typeColor={typeColor[type]} fontSize={fontSize[size].mainText}>
        {ValueWithSymbol()}
        {typeSign[type]}
      </ValueStyle>
      <LabelStyle label={label} fontSize={fontSize[size].label}>{label}</LabelStyle>
    </TextFormatDiv>
  );
}

export default memo(TextFormat);
