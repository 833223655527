import styled from 'styled-components';

export const LayoutBody = styled.div`
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.layoutBg};
  padding: 12px 24px 12px 24px; 
  box-sizing: border-box;
  position: relative;
`;
