import React, { useState } from 'react';
import styled from 'styled-components';

import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const CollapseContainer = styled.div`
  width: 100%;
  color: #545454;

  .ant-collapse-header, .ant-collapse-content-box {
    padding: 5px 0 !important;
    background-color: #fff;
  }

  .ant-collapse {
    padding: 0 !important;
  }
`;

const ExpandableText = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseChange = () => {
    setIsCollapsed(!isCollapsed);
  };

  const titleStyle = {
    color: isCollapsed ? '#545454' : '#00A4E6',
    cursor: 'pointer',
  };

  const caretStyle = {
    transition: 'transform 0.3s',
    transform: isCollapsed ? 'rotate(0deg)' : 'rotate(-180deg)',
  };


  const title = 'More Information';
  const additionalInfo = [
    `Close In: bookings made for stay dates that range from now to the selected period (let’s say 30 days). These could also be referred to as last-minute bookings.`,
    'Standard: bookings made between the Close In and Far Out dates.',
    'Far Out: bookings made more than the window period (let’s say 120 days) in advance before the desired stay date.'];

  return (
    <CollapseContainer>
      <Collapse
        bordered={false}
        activeKey={isCollapsed ? [] : ['1']}
        onChange={handleCollapseChange}
      >
        <Panel
          showArrow={false}
          header={<span onClick={handleCollapseChange} style={titleStyle}>{title} <DownOutlined style={caretStyle} /></span>}
          key="1"
        >
          <ul>
            {additionalInfo.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Panel>
      </Collapse>
    </CollapseContainer>
  );
};

export default ExpandableText;
