import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

import errorsPages from "../errorsPages/index";

const SocialApps = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/contacts`}/>
    <Route path={`${match.url}/list`} component={asyncComponent(() => import('./Users'))}/>
    <Route path={`${match.url}/update_profile/:user_id`} component={asyncComponent(() => import('./EditUser'))}/>
    <Route exact component={errorsPages}/>

  </Switch>
);

export default SocialApps;
