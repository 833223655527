import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { BodyCSS } from '../../Card-Body/BodyCSS';
import { BodyText } from '../../Text/Text';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Select } from "antd";

const Option = Select.Option;

const PaginationContainer = styled.div`
  width: 100%;
  min-height: 68px;
  ${BodyCSS}
  border-radius: 0px;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.quibbleHoverGrey};
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0px;
  gap: 10px;
`;

const PaginationBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0px;
  gap: 10px;
`;

const PaginationText = styled(BodyText)`
  color: ${({ theme }) => theme.quibbleBackgroundGrey};
  margin: 0px;
`;

const ControlDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 4px;
`;

const PageButton = css`
  width: 36px;
  height: 36px;
  border-radius: 2px;
  color: ${({ theme }) => theme.quibbleBackgroundGrey};
  background: ${({ theme }) => theme.quibbleHoverGrey};
  box-shadow: none;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.quibbleBlue};
  }
`;

const NextButton = css`
  ${PageButton}
  &:hover {
    color: ${({ theme }) => theme.quibbleBlue};
    background: none;
    transform: scale(1.2);
  }
  background: none;
`;

const Pagination = ({
  onSetActivePage,
  pageRange,
  activePage,
  totalLength,
  isLoading,
  paginationSize,
  setIntPaginationSize,
  pageSizeOptions = ["10", "20", "30", "40", "50"], 
}) => {
  const startItemIndex = totalLength === 0 ? 0 : activePage * paginationSize + 1;
  const endItemIndex = Math.min((activePage + 1) * paginationSize, totalLength);

  // const onArrowClick = (value) => {
  //   onSetActivePage(value);
  // };

  const handleChange = (value) => {
    setIntPaginationSize(value);
  };

  return (
    <PaginationContainer>
      {pageRange && pageRange.length && !isLoading ? (
        <PaginationBody>
          <PaginationText>Rows per page</PaginationText>
          <Select defaultValue={paginationSize} onChange={handleChange}>
            {pageSizeOptions.map((size) => (
              <Option key={size} value={parseInt(size, 10)}>
                {size}
              </Option>
            ))}
          </Select>

          <PaginationText>
            {totalLength > 0
              ? `${startItemIndex} - ${endItemIndex} of ${totalLength}`
              : `0 of ${totalLength}`}
          </PaginationText>

          <ControlDiv>
            <ButtonWithFunction
              value={'prev'}
              onClickFunc={() => onSetActivePage('prev')}
              ButtonStyle={NextButton}
              label={<LeftOutlined />}
            />
            <ButtonWithFunction
              value={'next'}
              onClickFunc={() => onSetActivePage('next')}
              ButtonStyle={NextButton}
              label={<RightOutlined />}
            />
          </ControlDiv>
        </PaginationBody>
      ) : isLoading ? (
        <p>Loading Pagination...</p>
      ) : (
        ''
      )}
    </PaginationContainer>
  );
};

export default memo(Pagination);
