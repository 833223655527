import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import DrawerMenu from './subComponents/OptimizeDrawer/DrawerMenu';
import PriceOptimize from './subComponents/OptimizeDrawer/PriceOptimize';
import Overrides from './subComponents/OptimizeDrawer/Overrides';
import { SecondaryButton, PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { lightScrollbar, darkScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import { formatDate } from 'util/localStorageHelper';
import SkLoaderDistinct from 'components/ReusableComponents/SkeletonLoader/SkLoaderDistinct';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from "components/ReusableComponents/Text/Text";
import {CaretDownOutlined} from '@ant-design/icons';
import ConfirmationModal from '../../components/ConfirmationModal';
import Stay from './subComponents/OptimizeDrawer/Stay';




const OptimizeDrawerBody = styled.div`
  max-width: 372px;
  min-width: 372px;
  height: 100%;
  background: ${({theme}) => theme.bodyBg};
  border-radius: 0 4px 4px 0;
  border-left: 1px solid ${({theme}) => theme.dirtyGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

`;

const BottomButtonsDiv = styled.div`
  width: 100%;
  background: inherit;
  height: 76px;
  padding: 16px;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;

const ResetButton = styled(SecondaryButton)`
  width: 162px;
  height: 44px;
`;

const SaveButton = styled(PrimaryButton)`
  width: 126px;
  height: 44px;
  border-radius: 4px 0 0 4px;
`;

const ArrowButton = styled(PrimaryButton)`
  width: 36px;
  height: 44px;
  border: none;
  outline: none;
  color: ${({theme}) => theme.white};
  background: rgba(0, 91, 106, 0.2);
  border-radius: 0 4px 4px 0;
`;

const SaveButtonDiv = styled.div`
  widt: 162px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  background: ${({theme}) => theme.quibbleBlue};
  border-radius: 4px;
`;

const SaveMenuDiv = styled.div`
  ${BodyCSS}
  width: 162px;
  height: 80px;
  max-height: ${({maxHeight}) => maxHeight};
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  transition: max-height 0.15s linear;

  position: absolute;
  bottom: 62px;
  right: 16px;
`;

const SaveMenuButton = styled.button`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  text-align: left;

  &:hover {
    background: ${({theme}) => theme.quibbleHoverGrey};
  }
`;
const OptimizeDrawer = ({selectedDates, adjustmentMenu, monthYear, setMonthYear, setSelectedDaysFunc, onAppyChanges, onReset, onSave, savingData, isFetchingPropertyPricing, disabledDates}) => {
 
  const [activeMenu, setActiveMenu] = useState('PRICE');
  const [showSend, setShowSend] = useState(false);
  const [modalSettings, setModallSettings] = useState({
    visible: false,
    title: '',
    confirmText: '',
    onCancelFnc: null,
    onSubmitFunc: null,
    bodyTextFirst: '',
    bodyTextSecond: '',
  })

  const onSetActiveMenu = useCallback((data) => {
    setActiveMenu(data);
  }, []);


  const calendarArray = useMemo(() => {
    const firstDay = new Date(monthYear.getFullYear(), monthYear.getMonth(), 1);
    const startDateCopy = new Date(firstDay);
    const firstSunday = new Date(startDateCopy.setDate(startDateCopy.getDate() - startDateCopy.getDay()));

    return [...Array(42).keys()].map((_, index) => {
      const firstSundayDate = new Date(firstSunday);
      return formatDate(new Date(firstSundayDate.setDate(firstSundayDate.getDate() + index)))
    })
  }, [monthYear]);

  const onSaveMenuClick = () => {
    setShowSend((prev) => !prev);
  };

  const onCloseModal = () => {
    setModallSettings({
      visible: false,
      title: '',
      confirmText: '',
      onCancelFnc: null,
      onSubmitFunc: null,
      bodyTextFirst: '',
      bodyTextSecond: '',
    })
  };

  const onResetClick = () => {
    setModallSettings((prev) => ({
      ...prev,
      visible: true,
      title: 'Clear All',
      confirmText: 'Clear All',
      onCancelFnc: onCloseModal,
      onSubmitFunc: onReset,
      bodyTextFirst: 'Are you sure you want to clear all changes?',
      bodyTextSecond: 'This action cannot be undone.',
      messageAfter: 'Reset done.'
    }))

    setShowSend(false);
  };

  const onSaveClick = () => {
    setModallSettings((prev) => ({
      ...prev,
      visible: true,
      title: 'Save Rules',
      confirmText: 'Save',
      onCancelFnc: onCloseModal,
      onSubmitFunc: onSave,
      bodyTextFirst: 'Are you sure you want to save the rules?',
    }))
    setShowSend(false);
  };

  const saveAndSend = () => {
    onSave(true)
  }

  const onSaveWithSend = () => {
    setModallSettings((prev) => ({
      ...prev,
      visible: true,
      title: 'Save and Send Rules',
      confirmText: 'Confirm',
      onCancelFnc: onCloseModal,
      onSubmitFunc: saveAndSend,
      bodyTextFirst: 'Are you sure you want to save the rules and send it to your PMS?',
      bodyTextSecond: 'This will also send to your PMS.',
    }))
    setShowSend(false);
  }
 
  const isDisabled = (data) => {
    return disabledDates.includes(data);
  };

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, [setMonthYear]);


  return (
    <OptimizeDrawerBody>
     {isFetchingPropertyPricing ? <SkLoaderDistinct isLoading={true} type={"calendar"} /> :
     <>
      <DrawerMenu onSetActiveMenu={onSetActiveMenu} activeMenu={activeMenu} />
      {activeMenu === 'PRICE' && <PriceOptimize calendarArray={calendarArray} isDisabled={isDisabled} setSelectedDaysFunc={setSelectedDaysFunc} selectedDates={selectedDates} monthYear={monthYear} setMonthYear={setMonthYearVal} onAppyChanges={onAppyChanges}/>}
      {activeMenu === 'STAY' && <Stay adjustmentMenu={adjustmentMenu} />}
      {activeMenu === 'OVERRIDES' && <Overrides adjustmentMenu={adjustmentMenu} isDisabled={isDisabled} calendarArray={calendarArray} setSelectedDaysFunc={setSelectedDaysFunc} selectedDates={selectedDates} monthYear={monthYear} onAppyChanges={onAppyChanges}/>}
      <BottomButtonsDiv>
        <ResetButton onClick={onResetClick}  disabled={savingData}>
          Reset
        </ResetButton>
        <SaveButtonDiv>
          <SaveButton onClick={onSaveClick}  disabled={savingData}>
            Save
          </SaveButton>
          <ArrowButton onClick={onSaveMenuClick} disabled={savingData}>
            <CaretDownOutlined />
          </ArrowButton>
        </SaveButtonDiv>
        <SaveMenuDiv maxHeight={showSend ? '80px' : '0px'} >
          <SaveMenuButton onClick={onSaveClick}>
            <BodyText>
              Save
            </BodyText>
          </SaveMenuButton>
          <SaveMenuButton onClick={onSaveWithSend}>
          <BodyText>
              Save and Send
            </BodyText>
          </SaveMenuButton>
        </SaveMenuDiv>
      </BottomButtonsDiv>
      </>
      }
      <ConfirmationModal {...modalSettings}/>
    </OptimizeDrawerBody>
  )
}

export default OptimizeDrawer;