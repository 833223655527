import db from "./db"
import { getData as getLocalFilters } from "./localStorageHelper"
import { isEqual } from "lodash"

let currentStore = '';
const date = new Date() // Get current date
const now = date.getTime(); // Get current Time

export const storeData = async ( storeName, data, filters ) => {
    currentStore = storeName;
    
    try {

        let firstDay = new Date( date.getFullYear(), new Date().getMonth(), 1); //get the first day of the month
        const filterBy = getLocalFilters("filters") !== null && getLocalFilters("filters") !== undefined ? getLocalFilters("filters").filterBy : "month" // Get the date filterBy value
        const properties = filters?.properties ? filters.properties : []
        let selectedDate = filters?.month ? filters?.month : filters?.date ? filters?.date : date

        if(filterBy === "year" ){
            firstDay = date.getFullYear()
            selectedDate = filters?.year ? filters?.year : firstDay
        }

        const expiryTime = selectedDate < firstDay ? date.setDate(new Date().getDate() + 14) : now + getTimeToMidnightFromNowInMS();
        // get the current database 
        const dbVar = selectDatabase(currentStore)

        if(!dbVar) return false;

        const allData = await dbVar.toArray()
        const marketPage =  currentStore === "listData" ? true : false
        const currentData = await cacheExist(allData, filterBy, filters, properties, dbVar, now, marketPage)
        
        if ( currentData ) return true;

        return await cacheData(data, expiryTime, filterBy, filters, dbVar, marketPage );
    } catch (error) {
        console.log(error)
        return false;
    }
}


export const getData = async ( storeName, filters ) =>{
    currentStore = storeName;

    
    const filterBy = getLocalFilters("filters") !== null ? getLocalFilters("filters").filterBy : "month"
    const properties = filters.properties !== null && filters.properties !== undefined ? filters.properties : []

    try {

        const dbVar = selectDatabase(currentStore)
        if(!dbVar) return false;
        const allData = await dbVar.toArray();
        const isMarketPage = currentStore === "listData" ? true : false;
        let currentData = await getCurrentData(allData, filterBy, filters, properties, now, dbVar, isMarketPage)
        if(!currentData ) return false;
        return currentData[0];

    } catch (error) {
        console.log(error)
        return false;
    }
}


export const clearAllCache = async () => {
    
    try {

        await db.MarketWatchList.clear();
        await db.ListingWatchList.clear();
        await db.ListData.clear();
        await db.AnalysisData.clear();
        await db.DashboardData.clear();
        await db.NotificationListData.clear();
        await db.ModalData.clear();  

        return true;

    } catch (error) {
        console.log("error >> ", error)
        return false;
    }
}

export const forceClearAllCache = async () => {
    
    try {

        await clearAllCache();
        await db.UserPropertiesData()

        return true;
    } catch (error) {
        console.log("error >> ", error)
        return false;
    }
}

function getNextMidnightTime() {
    var midnight = new Date()
    midnight.setHours(24);
    midnight.setMinutes(0);
    midnight.setSeconds(0);
    midnight.setMilliseconds(0);

    return midnight.getTime()
}

function getTimeToMidnightFromNowInMS() {
    return getNextMidnightTime() - new Date().getTime()
}

var getCurrentData = async function (allData, filterBy, filters, properties, now, dbVar, isMarketPage = false) {

    let currentData = []
        if(currentStore === "userProperties"){
            for(let i=0; i<allData.length; i++){
                if(filters.userId === allData[i].userId) currentData.push(allData[i]);
            }
            return currentData;
        }
    
        for (let i = 0; i < allData.length; i++) {
            const element = allData[i];

            if (filterBy === "month" && element.filterBy === "month"){
                if(element.month === filters.month && isEqual(element.properties , properties)){
                    if(filters.sameStore === element.sameStore) currentData.push(element)
                }
            }

            if (filterBy === "year" && element.filterBy === "year"){
                if(element.year === filters.year && isEqual(element.properties , properties) ){
                    if(filters.sameStore === element.sameStore) currentData.push(element)
                }
            }
        }

        if(currentData.length < 1 ) return false;

        if(isMarketPage){
            if (filters.hideInactiveProperties === false || filters.proratedType !== 'city' ){

                let finalData = currentData.filter((item, index)=> {
                  return item.proratedType === filters.proratedType && item.hideInactiveProperties === filters.hideInactiveProperties
                })

                if( finalData.length === 0 ) return false

                if(now > finalData[0].expiryTime){ 
                    await dbVar.delete(currentData[0].id);
                    return false;
                }

                return finalData;
            }
        }

    return currentData;
}

var cacheExist = async function (allData, filterBy, filters, properties, dbVar, now, marketPage = false, returnData = false) {

    let currentData = await getCurrentData(allData, filterBy, filters, properties, now, dbVar, marketPage)
    if ( currentData?.length > 0 && now < currentData[0]?.expiryTime ){
        return returnData ? currentData[0] : true
    }

    if(currentData?.length > 0 && now > currentData[0]?.expiryTime){ 
        await dbVar.delete(currentData[0].id);
        return false;
    }

    return false;
}

var cacheData = async function (data, expiryTime, filterBy, filters, dbVar, marketPage = false) {
    let newData = {}

    if(!dbVar) return false;

    if(currentStore === 'userProperties'){
        newData = {
            ...data,
            expiryTime,
            cachedOn: new Date(),
            userId: filters?.userId ? filters.userId : ''
        }
    }

    if(currentStore !== 'userProperties') {
        newData = { 
            ...data, 
            expiryTime,  
            month : filterBy === "month" ? filters.month : null,
            year: filterBy === "year" ? filters.year : null, 
            properties: filters.properties !== null && filters.properties !== undefined ? filters.properties : [] ,
            filterBy,
            hideInactiveProperties: marketPage ? filters.hideInactiveProperties : null,
            proratedType: marketPage ? filters.proratedType : null,
            sameStore: filters.sameStore,
        }
    }

    await dbVar.add(newData);
    return true;
}

var  selectDatabase = function ( storeName ) {

    switch (storeName) {
        case "modalData": 
         return db.ModalData
        case "MarketWatchList":
            return db.MarketWatchList;
        case "ListingWatchList":
            return db.ListingWatchList;
        case "dashboardData" :
            return db.DashboardData;
        case "listData" :
            return db.ListData;
        case "dataAnalysis" :
            return db.AnalysisData;
        case "NotificationList" :
            return db.NotificationListData;
        case "userProperties" :
            return db.UserPropertiesData;
        default :
            return false;
    }
}


