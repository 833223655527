import React from 'react';

const CustomTick = ({x,y, payload,}) => {

  const name = payload?.value;

 

  return (
      <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fontSize={"14px"} width="20px" fontWeight={400} >
            {name}
          </text>
      </g>
  )
}

export default CustomTick;