import {
  USER_RESERVATION_LIST,
  USER_RESERVATION_LIST_SUCCESS,
  USER_RATES_LIST,
  USER_RATES_LIST_SUCCESS,
  USER_BOOKING_FEED,
  USER_BOOKING_FEED_SUCCESS,
} from "constants/ActionTypes";
import { HIDE_MESSAGE, SHOW_MESSAGE } from "../../constants/ActionTypes";

/*** Reservation list */
export const getReservationList = (request) => {
  return {
    type    : USER_RESERVATION_LIST,
    payload : request
  };
};

/*** Reservation list success*/
export const updateReservationList = (response) => {
  return {
    type: USER_RESERVATION_LIST_SUCCESS,
    payload: response
  };
};

/*** Booking Feed list */
export const getBookingFeed = (request) => {
  return {
    type: USER_BOOKING_FEED,
    payload: request,
  };
};

/*** Bookinfeed list success*/
export const updateBookingFeedList = (response) => {
  return {
    type: USER_BOOKING_FEED_SUCCESS,
    payload: response
  };
};

/*** update Reservation  success*/
export const showReservationMessage = (response) => {
  return {
    type: SHOW_MESSAGE,
    payload: response
  };
};

/*** update property status success*/
export const hideReservationMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};


/*** getUserRates list */
export const getUserRates = (request) => {
  return {
    type    : USER_RATES_LIST,
    payload : request
  };
};

/*** Rates list success*/
export const updateRatesList = (response) => {
  return {
    type: USER_RATES_LIST_SUCCESS,
    payload: response
  };
};
