
import React, {Fragment, useCallback} from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//utils
import formatMetrics from "../../util/formatMetrics";
import { TODAY_MONTH, TODAY_YEAR, FULL_MONTH_NAME, now } from "../../constants/GlobalConstant";
import { getData } from "../../util/localStorageHelper";

//Components
import {Button} from 'components/ReusableComponents/Button/Button.js';
import MetricContainerTop from "./components/MetricContainerTop";
import MetricContainerBottom from "./components/MetricContainerBottom";
import PortfolioContainer from "./components/PortfolioContainer";
import MarketContainer from "./components/MarketContainer";
import { media } from "../../styles/stylesheet";
import { lightScrollbar, darkScrollbar } from "../../components/ReusableComponents/Scrollbar/Scrollbar";
import DemoBanner from "./components/DemoBanner";
import PageFilters from "../../components/ReusableComponents/Filters/Filters";

const HomepageDiv = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  overflow-x: auto;
  overflow-y: auto;

  ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar }

`;

const TitleDiv = styled.div`
  width: 100%;
  padding: 0 0 0 16px;
  margin: 8px 0 0 0;

  ${media.bpl} {
    padding: 48px 0 0 16px;
  }

  ${media.bpm} {
    padding: 16px 0 0 16px;
  }
`;

const InformationBody = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  ${media.bpxxl} {
    justify-content: ${({justification}) => justification};
  };

  ${media.bpxl} {
    justify-content: flex-start;
  };
`;

const HomePageBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const MetricsBody = styled(HomePageBody)`
  width: 100%;
  height: 500px;

  display: flex;
  flex-direction: row;
`;


// const HomepageTitle = styled.h1`
//   font-size: ${({theme}) => theme.s8};
//   font-family: Commissioner;
//   color: ${({theme}) => theme.zero};
//   align-self: flex-start;
//   margin: 0px 0px 0px 4px;
// `;


const HomeButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin: 24px 0px;
`;

const PortfolioBody = styled(HomePageBody)`
  width: 100%;
`;




const Homepage = (props) => {

  const {sidebarCollapsed} = props;
  const { users, common } = useSelector(({ users, common }) => ({users, common}));
  const {metricsSummary, portfolio, isFetchingMetricSummary, isFetchingPortfolio, authUserDetails} = users;
  const {filters} = common;
  const currencySymbol = localStorage.getItem("currency");
  const persistedFilter = getData("filters") === null ? filters : getData("filters");
  const metricsData = formatMetrics(metricsSummary, currencySymbol);

  const history = useHistory();

  const onAnalyticsRedirect = useCallback(() => {
    history.push("main/analytics/portfolio-analytics");
  }, [history]);

  const onMyPortfolioRedirect = useCallback(() => {
    history.push("setting/myPortfolio");
  }, [history]);

  const onMarketsRedirect = useCallback(() => {
    history.push("market-analytics/market");
  }, [history]);


  const onPropertyDetailsRedirect = useCallback((id) => {
    // history.push("/revenue/propertiesDetail?key="+id)
    history.push(
      {
        pathname: "/market-analytics/property-details",
        state: {propertyId: id, date: new Date(now)},
      },);
  }, [history])


  const HomepageMonthYear = useCallback((diff=0) => {

    if (persistedFilter.filterBy === "month") {
      return `${FULL_MONTH_NAME[persistedFilter.intMonth ? persistedFilter.intMonth -1 : TODAY_MONTH - 1 ]} ${persistedFilter.month ? persistedFilter.month.split('-')[0] - diff : (TODAY_YEAR - diff)}`
    }

    if (persistedFilter.filterBy === "year") {
      return `${persistedFilter.year - diff || (TODAY_YEAR - diff)}`
    }

    return `${FULL_MONTH_NAME[TODAY_MONTH-1]} ${(TODAY_YEAR - diff)}`
  }, [persistedFilter.filterBy,  persistedFilter.intMonth, persistedFilter.year, persistedFilter.month]);


  const ContainerJustification = sidebarCollapsed ? 'center' : 'flex-start';

  return (
    <Fragment>
      <TitleDiv>
        {/* <HomepageTitle> {HomepageMonthYear()} </HomepageTitle> */}
      </TitleDiv>
      <HomepageDiv >
        <PageFilters/>
       <DemoBanner  authData={authUserDetails} /> 
        <InformationBody justification={ContainerJustification}>
          <MetricsBody >
            <MetricContainerTop isFetchingMetricSummary={isFetchingMetricSummary} metricsData={metricsData} monthYear={HomepageMonthYear} />
            {/* <HomeButton onClick={onAnalyticsRedirect}> Portfolio Analytics </HomeButton> */}
            <MetricContainerBottom isFetchingMetricSummary={isFetchingMetricSummary}  metricsData={metricsData} />
          </MetricsBody>
          <PortfolioBody>
            <MarketContainer portfolio={portfolio} isFetchingPortfolio={isFetchingPortfolio} currencySymbol={currencySymbol} onMarketsRedirect={onMarketsRedirect} onMyPortfolioRedirect={onMyPortfolioRedirect}  monthYear={HomepageMonthYear} filters={filters}/>
            <PortfolioContainer  portfolio={portfolio} isFetchingPortfolio={isFetchingPortfolio} onMyPortfolioRedirect={onMyPortfolioRedirect} currencySymbol={currencySymbol}  monthYear={HomepageMonthYear} onPropertyDetailsRedirect={onPropertyDetailsRedirect} filters={filters}/>
          </PortfolioBody>
        </InformationBody>
      </HomepageDiv>
    </Fragment>
  );
};

export default Homepage;
