import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const StyledFrame = styled.iframe`
  width: 100%;
  height: 315px;
`;

const StyledModal = styled(Modal)`
    margin: auto;

  .ant-modal-content{
    border-radius: 0;
  }

  .ant-modal-body {
    flex: 1;
    height: 80vh;
    padding: 0px 20px 20px 20px; 
    background-color: ${({theme}) => theme.quibbleHoverGrey};

  }
  .ant-modal-header{
    border:none;
    background-color: ${({theme}) => theme.quibbleHoverGrey};
    color: ${({theme}) => theme.quibbleFontGrey};
  }

  .ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const VideoContainer = styled.div`
  width: calc(50% - 10px);

`;

const VideoListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  min-height: 80vh;
  max-height: 120vh;
`;

const VideoTitle = styled.p`
  font-size: 18px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 100;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;

  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
    
  }
`;

const ViewFrame = styled.iframe`
  display: ${({isLoading}) => isLoading ? 'none' : 'block'}
`;

const Loader = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;


const VideoList = ({videos}) => {

    const [currentVideo, setCurrentVideo] = useState({title:'', image:''});
    const [modalIsOpen, setModalIsOpen] = useState(false);  
    const [isLoading, setIsLoading] = useState(true);

    const openModal = (videoUrl) => {
        setCurrentVideo(videoUrl);
        setModalIsOpen(true);    setIsLoading(true);

    };

    useEffect(() => {
        if (currentVideo.url) {
          setModalIsOpen(true);
        }
      }, [currentVideo]);

  const closeModal = () => {
    setCurrentVideo({title:'', videoUrl:''});
    setModalIsOpen(false);
  };

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  return (
    <VideoListContainer>
        {videos.map((video) => (
            <VideoContainer key={video.videoUrl} onClick={() => openModal(video)}>
                <StyledFrame width="560" height="315" src={video.videoUrl}  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen ><span style={{background: 'transparent', width:'100%', height: '100%'}}></span></StyledFrame>
                <VideoTitle>{video.title}</VideoTitle>

            </VideoContainer>
        ))}

        <StyledModal
        open={modalIsOpen} 
        title={
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <LeftOutlined style={{ marginRight: 8 }} onClick={closeModal} />
              {currentVideo.title}
            </div>
          }
        onCancel={closeModal} footer={null} width="80vw"  >
        {isLoading ===true && <Loader>Loading...</Loader>}


        <ViewFrame
          width="100%"
          height="100%"
          src={currentVideo.videoUrl}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen          
          onLoad={handleVideoLoad}
          isLoading={isLoading}

        ></ViewFrame>
      </StyledModal>
    </VideoListContainer>
  );
};

export default VideoList;
