import React from 'react';
import styled from 'styled-components';
import { PropertyMenuDiv } from '../../CommonCSS';
import PriceAdjustment from '../../../../../priceAdjustment/PriceAdjustment';

const OptimizeBody = styled(PropertyMenuDiv)`
  margin: 0 0 0 0;
`;

const PropertyMenuOptimize = ({propertyId, monthYear, setMonthYear, pricingDates}) => {

  return (
    <OptimizeBody>
      <PriceAdjustment isProperty={true} propertyMonthYear={monthYear} setPropertyMonthYear={setMonthYear} propertyValue={propertyId} chosenDates={pricingDates} />
    </OptimizeBody> 
  )
}

export default PropertyMenuOptimize;