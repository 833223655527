import React, { useState, useRef } from 'react';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import Modal from 'antd/lib/modal/Modal';
import { Radio, Checkbox, Switch, Button, Form, Space } from 'antd';
import { cloneDeep } from 'lodash';

import { useData } from '../../../../util/DataStore/DataContext';
import { currencyFormatter } from "../../../../util/helper";
import { useReactToPrint } from 'react-to-print';
import OwnerReportPDF from './OwnerReportPDF';
import { getQuarter } from './OwnerReportPDF/OwnerReportPDF';
import { FULL_MONTH_NAME } from "constants/GlobalConstant";
// import { getData } from '../../../../util/localStorageHelper';
// import filters from '../../../../components/ReusableComponents/Filters/Filters';

const OwnerReportModal = styled(Modal)`

  .ant-modal-header {
    border-bottom: 1px solid #D9D9D9;
  }
  .ant-modal-body {
    padding: 0 16px;
  }
`;

const SubTitle = styled.div`
  padding: 8px 16px;
  background-color: #e8e8e8;
  margin: 5px 0px;
`;

const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 16px 16px;
`;

const SwitchDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxStyle = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ checked }) => checked && 'transparent'};
    border-color: ${({ checked }) => checked && 'black'};
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ checked, theme }) => checked && theme.quibbleBlue};
  }
`;

export const ViewTitle = {
  rmView:"RM View",
  checkInView: "Check In",
  checkOutView: "Check Out",
}

const OwnerReportModalV2 = (props) => {
  const KEY_METRICS = [
    { checked: true, value: "totalRevenue", label: "Total Revenue", isCurrency: true },
    { checked: true, value: "totalRent", label: "Total Rent", isCurrency: true },
    { checked: true, value: "ancillaryRevenue", label: "Ancillary Revenue", isCurrency: true },
    { checked: true, value: "adr", label: "Average Daily Rate (ADR)", isCurrency: true },
    { checked: true, value: "reservation", label: "Reservations" },
    { checked: true, value: "bookedNights", label: "Booked Nights" },
    { checked: true, value: "ownerBooking", label: "Owner Reservations" },
    { checked: true, value: "blockedDay", label: "Blocked Days" },
    { checked: true, value: "alos", label: "Average Length of Stay" },
  ];

  // const [open, setOpen] = useState(false);
  const [keyMetrics, setKeyMetrics] = useState(KEY_METRICS);
  const [keyMetricsByMonth, setKeyMetricsByMonth] = useState(KEY_METRICS);
  const [allChecked, setAllChecked] = useState(true);
  const [monthlyData, setMonthlyData] = useState(true);
  const [yearlyData, setYearlyData] = useState(true);

  const { generatingPropertyReport } = useSelector(({ property }) => property);
  // let persistedFilter = getData("filters") === null ? filters : getData("filters");

  const propertyDetail = props?.propertyDetail || {};
  const userDetails = useSelector(({ users: { authUserDetails } }) => authUserDetails);
  const { state, updateData } = useData();

  const componentRef = useRef();

  const closeModal = () => {
    if (generatingPropertyReport) return
     props.onCloseModal();
  }

  const handleUpdateQuarter = (e) => {
    updateData({
      type: "SET_QUARTER",
      payload: Number(e.target.value),
    });
  };

  const handleUpdateViewType = (e) => {
    updateData({
      type: "SET_VIEW_TYPE",
      payload: e.target.value,
    });
  };

  const handlePmsChargeChange = (e) => {
    updateData({
      type: "SET_REMOVE_PMS_CHARGE",
      payload: e.target.checked,
    });
  }

  const filterTitle = getQuarter(state?.PROPERTY_DATA?.quarter, `${FULL_MONTH_NAME[props.month - 1]}_${props.year}`, props.year, state?.PROPERTY_DATA?.quarter === 5);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => closeModal(),
    documentTitle: `${propertyDetail?.result?.name}_${filterTitle}`?.replace?.(/\s/g, '_') || "report"
  });

  const applyFilter =  () => {
    handleUpdateFilter();
  }

  const onClickOk = () => {
      handlePrint();
  };

  const toggleKeyMetrics = (e, key) => {
    const updateMetrics = (metrics) => {
      const updatedMetrics = cloneDeep(metrics);
      const index = updatedMetrics.findIndex(km => km.value === key);
      updatedMetrics[index].checked = e.target.checked;
      return updatedMetrics;
    };

    const updatedKeyMetrics = updateMetrics(keyMetrics);
    const updatedKeyMetricsByMonth = updateMetrics(keyMetricsByMonth);

    setKeyMetrics(updatedKeyMetrics);
    setKeyMetricsByMonth(updatedKeyMetricsByMonth);

    if (!e.target.checked) {
      setAllChecked(false);
    } else {
      const allChecked = updatedKeyMetrics.every(km => km.checked) && updatedKeyMetricsByMonth.every(km => km.checked);
      setAllChecked(allChecked);
    }
  };

  const toggleAllKeyMetrics = (e) => {
    const checked = e.target.checked;
    const updatedKeyMetrics = keyMetrics.map(km => ({ ...km, checked }));
    const updatedKeyMetricsByMonth = keyMetricsByMonth.map(km => ({ ...km, checked }));

    setKeyMetrics(updatedKeyMetrics);
    setKeyMetricsByMonth(updatedKeyMetricsByMonth);
    setAllChecked(checked);
  };

  const handleMonthlyDataToggle = (checked) => {
    setMonthlyData(checked);
  };

  const handleYearlyDataToggle = (checked) => {
    setYearlyData(checked);
  };

  function setMetrics(km, fields) {
    const newKm = { ...km, adr: km?.totalRent / km?.bookedNights };
    const currencyFields = ["adr", "ancillaryRevenue", "totalRevenue", "totalRent"];

    let kmClone = cloneDeep(newKm);
    Object.keys(kmClone).forEach((key) => {
      if (!fields.includes(key)) delete kmClone[key];
      else if (currencyFields.includes(key)) kmClone[key] = currencyFormatter(kmClone[key]);
    });

    return kmClone;
  }

  const handleUpdateFilter = () => {
    props.updateFilter()
  }

  function setPayload() {
    const metricsExport = keyMetrics.filter(km => km.checked).map(km => km.value);
    const metricsByMonthExport = keyMetricsByMonth.filter(km => km.checked).map(km => km.value);

    let payload = {
      brandLogoUrl: userDetails.brand_logo,
      companyName: userDetails.company,
      currentMonthCurrentYearADR: currencyFormatter(propertyDetail.currentMonthYearAdr),
      currentMonthCurrentYearOccupancy: propertyDetail.currentMonthCurrentYearOccupancy,
      currentMonthCurrentYearTotalRent: currencyFormatter(propertyDetail.currentMonthCurrentYearTotalRent),
      keyMetrics:  {
        currentYear: setMetrics(propertyDetail.keyMetricTableCurrentYear,yearlyData ? metricsExport : []),
        last365: setMetrics(propertyDetail.keyMetricTableLast365Days, metricsExport),
        lastYear: setMetrics(propertyDetail.keyMetricTableLastYear, metricsExport),
      },
      keyMetricsByMonth:  {
        currentMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonth, monthlyData ? metricsByMonthExport : []),
        lastMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonthLastYear, metricsByMonthExport),
      },
      month: FULL_MONTH_NAME[props.month - 1],
      property: {
        name: propertyDetail.result?.name,
        city: propertyDetail.result?.city,
        imageUrl: propertyDetail.result?.image,
      },
      token: localStorage.getItem('token'),
      year: props.year,
    };
    return payload;
  }

  return (
    <OwnerReportModal
      open={props.isExportModalVisible}
      title="Download Report"
      onCancel={ closeModal }
      footer={[
        <Button key="back" onClick={closeModal} style={{ width: '45%' }}>
          Cancel
        </Button>,
        !props.isNewFilter ? <Button loading={props.loading} onClick={onClickOk} key="submit" style={{ backgroundColor: '#1bb3cd', color: 'white', width: '45%' }}>
          Download
        </Button>: <Button loading={props.loading} onClick={applyFilter} key="submit" style={{ backgroundColor: '#1bb3cd', color: 'white', width: '45%' }}>
          Apply Filter
        </Button>
      ]}
      width={"360px"}
    >
      <SubTitle>View Mode</SubTitle>
      <ItemDiv>
        <Radio.Group
          name="radiogroup"
          onChange={handleUpdateViewType}
          value={state.viewType}
        >
          <Space direction="vertical">
            <Radio value={"rmView"}>Revenue Manager</Radio>
            <Radio value={"checkInView"}>Check-in Date</Radio>
            <Radio value={"checkOutView"}>Check-out Date</Radio>
          </Space>
        </Radio.Group>
      </ItemDiv>

      <SubTitle>Download Options</SubTitle>
      <ItemDiv>
        <Radio.Group
          onChange={handleUpdateQuarter}
          value={state.quarter}
          name="radiogroup"
          defaultValue={0}
        >
          <Space direction="vertical">
            <Radio value={0}>Current Month & Year</Radio>
            <Radio value={1}>First Quarter & Year</Radio>
            <Radio value={2}>Second Quarter & Year</Radio>
            <Radio value={3}>Third Quarter & Year</Radio>
            <Radio value={4}>Fourth Quarter & Year</Radio>
          </Space>
        </Radio.Group>
      </ItemDiv>

      <SubTitle>Metrics</SubTitle>
      <ItemDiv>
      <SwitchDiv>
          Monthly Data <Switch checked={monthlyData} onChange={handleMonthlyDataToggle} />
        </SwitchDiv>
        <SwitchDiv>
          Yearly Data <Switch checked={yearlyData} onChange={handleYearlyDataToggle} />
        </SwitchDiv>
      </ItemDiv>
      <div>
        <Form.Item style={{ paddingLeft: '1.5rem', marginBottom: 0, fontWeight: "lighter" }}>
          <CheckboxStyle style={{ paddingLeft: '6px' }} checked={allChecked} onChange={toggleAllKeyMetrics}>
            All
          </CheckboxStyle>
        </Form.Item>
        {
          keyMetrics.map(km => (
            <Form.Item key={km.value} style={{ paddingLeft: '1.5rem', marginBottom: 0, fontWeight: "lighter" }}>
              <CheckboxStyle style={{ paddingLeft: '6px' }} checked={km.checked} onChange={(e) => toggleKeyMetrics(e, km.value)} data-metric={km.value}>
                {km.label}
              </CheckboxStyle>
            </Form.Item>
          ))
        }
      </div>
      <SubTitle>Channel Fees</SubTitle>
      <ItemDiv>
        <CheckboxStyle style={{paddingLeft:'10px'}} checked={state.remove_pms_charge} onChange={handlePmsChargeChange}>Remove Channel Fees</CheckboxStyle>
      </ItemDiv>
      <div className="report-container">
        <OwnerReportPDF year={props.year} ref={componentRef} setPayload={setPayload} />
      </div>
    </OwnerReportModal>
  );
};

export default OwnerReportModalV2;
