import styled from 'styled-components';
import {Tooltip, Tag} from 'antd';

const ToolTipFirstTag = styled(Tag)`
  line-height: 1.2;
  margin: 0;
`;


const VSMarketOccupancyText = styled.div`
  margin: 0;
  color: ${({textColor}) => textColor};
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const PercentSpan = styled.span`
  font-size: 10px;
  font-weight: 700;
`;


const MarketOccupancyDiffToolTip = (data, theme) => {

  const {marketOccupancy, market, difference} = data;
  
  let color = "warning";
  let description = "level with";
  let occupancy = "PAR";

  const occupancyValue = Math.abs(difference);

  if (difference > 0) {
    color = "success";
    description = "more than";
    occupancy = occupancyValue + "%";
  } else if (difference < 0) {
    color = "error";
    description = "less than";
    occupancy = occupancyValue + "%";
  }

  if (marketOccupancy > 0 ) {
    return (
      <Tooltip
        title={(
              <span>
                {market} is{" "}
                <ToolTipFirstTag
                  color="processing"
                >
                  {(marketOccupancy * 100).toFixed(0)}% occupied
                </ToolTipFirstTag>
                , while the occupancy of your properties in this
                location is{" "}
                <ToolTipFirstTag
                  color={color}
                >
                  {" "}
                  {difference ? occupancy : ""} {description}
                </ToolTipFirstTag>{" "}
                the city occupancy.
              </span>
          )}
        >
      {
      difference > 0 ? (
        <VSMarketOccupancyText textColor='inherit'>
          {`+${occupancyValue}`}<PercentSpan>%</PercentSpan>
        </VSMarketOccupancyText>
      ) : (
        <VSMarketOccupancyText textColor={theme.negative}>
          (&nbsp;{`${occupancyValue}`}<PercentSpan>%</PercentSpan>&nbsp;)
        </VSMarketOccupancyText>
      )
    }
    </Tooltip>
    )
  }
  
  return "-"
} 

export default MarketOccupancyDiffToolTip;