import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import moment from 'moment';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';



const RentalTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const SubTitle = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const Occupancy = (props) => {

  const [chartData, setChartData] = useState([]);
  const [isEmptyPortfolio, setIsEmptyPortfolio] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    setIsLoading(true)
    const rawData = props.data

    const convertData = (originalData) => {

      const marketData = originalData && originalData.market;
      const portfolioData = originalData && originalData.portfolio;

      const result = [];

      marketData && marketData.forEach((entry, index) => {
        const portfolioPV = portfolioData[index] ? portfolioData[index].value : 0;

        const currentDate = moment();
        const entryDate = moment(entry.date);
        const daysDifference = currentDate.diff(entryDate, 'days');

        result.push({
            name: `${daysDifference} days`,
            Market: entry.occupancy * 100,
            Portfolio: portfolioPV * 100
        });
      });

      return result.reverse();
    };

    let mappedData = null;

    if (rawData) {
      mappedData = convertData(rawData);
      setIsEmptyPortfolio(props.isEmptyPortfolio)
      setIsLoading(false)
    }

    if (mappedData)
      setChartData(mappedData);

  }, [props.data, props.isEmptyPortfolio]);

  return (
    <>
    {isLoading ? <MHCardLoader /> :
      <RentalTypeContainer>
        <TitleContainer>
          <Title>Occupancy</Title>
          <SubTitle>21 day trend</SubTitle>
        </TitleContainer>
        <div style={{ height: '200px', padding: '10px 0' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" padding={{ left: 30 }} />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="Market" stroke="#B989F6" activeDot={{ r: 8 }} />
              {!isEmptyPortfolio &&
                <Line type="monotone" dataKey="Portfolio" stroke="#2ECADB" activeDot={{ r: 8 }} />
              }
            </LineChart>
          </ResponsiveContainer>
        </div>
      </RentalTypeContainer>
    }
    </>
  );
}

export default Occupancy;