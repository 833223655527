import service from "../util/APIUtility";
import serviceV2 from "../util/APIUtility";

export const subscriptionService = {
  checkout: async (data) => {
    const response = await service.post(`/api/subscription/checkout`, data);
    return response.data.data;
  },
  manage: async () => {
    const response = await service.post(`/api/subscription/manage`);
    return response.data.data;
  },
  getPlans: async () => {
    const response = await service.get(`/api/subscription/plans`);
    return response.data.data;
  },
  getTransactions: async () => {
    const response = await service.get(`/api/subscription/transactions`);
    return response.data.data;
  },
  getCards: async () => {
    const response = await service.get(`/api/subscription/cards`);
    return response.data.data;
  },
  subscribe: async (options) => {
    let result = await serviceV2.post(`/api/subscription`, options);
    return result.data;
  },
};

export default subscriptionService;
