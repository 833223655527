import React from 'react';

import usa from 'assets/images/flags/usa.svg';
import uk from 'assets/images/flags/uk.svg';
import nz from 'assets/images/flags/nz.svg';
import mexico from 'assets/images/flags/mexico.svg';
import japan from 'assets/images/flags/japan.svg';
import europe from 'assets/images/flags/europe.svg';
import china from 'assets/images/flags/china.svg';
import canada from 'assets/images/flags/canada.svg';
import au from 'assets/images/flags/au.svg';

const flagMap = {
  USD: usa,
  MXN: mexico, 
  CNY: china,
  EUR: europe,
  CAD: canada,
  JPY: japan,
  GBP: uk,
  NZD: nz,
  AUD: au,
}




const CurrencyFlag = ({currency='USD'}) => {

  return (
    <img src={flagMap[currency]} alt="flag" />
  )
}

export default CurrencyFlag;