import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import CustomBarWithOpacity from '../CustomBarWithOpacity';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';

const RentalSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const CustomBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px; /* 100% */
  margin-top: 20px;

  display: flex;
`;

const RentalSettings = (props) => {

  const [isEmptyPortfolio, setIsEmptyPortfolio] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setIsEmptyPortfolio(props.isEmptyPortfolio)
    setIsLoading(false);
  }, [props.isEmptyPortfolio]);

  return (
    <>
      {isLoading ? <MHCardLoader /> :
        <RentalSettingsContainer>
          <TitleContainer>
            <Title>Rental Settings</Title>
          </TitleContainer>
          <Text>Cancellation Policy</Text>
          <BodyContainer>
            <CustomBarContainer>
              <CustomBarWithOpacity opacityPercent={100} text='Flexible' type='market' />
              <CustomBarWithOpacity opacityPercent={66} text='Moderate' type='market' />
              <CustomBarWithOpacity opacityPercent={30} text='Strict' type='market' />
            </CustomBarContainer>
            {!isEmptyPortfolio &&
              <CustomBarContainer>
                <CustomBarWithOpacity opacityPercent={100} text='Flexible' type='portfolio' />
                <CustomBarWithOpacity opacityPercent={66} text='Moderate' type='portfolio' />
                <CustomBarWithOpacity opacityPercent={30} text='Strict' type='portfolio' />
              </CustomBarContainer>
            }
          </BodyContainer>
          <Text>Minimum Stay</Text>
          <BodyContainer>
            <CustomBarContainer>
              <CustomBarWithOpacity opacityPercent={100} text='1 Night' type='market' />
              <CustomBarWithOpacity opacityPercent={83} text='2' type='market' />
              <CustomBarWithOpacity opacityPercent={67} text='3' type='market' />
              <CustomBarWithOpacity opacityPercent={50} text='4' type='market' />
              <CustomBarWithOpacity opacityPercent={33} text='5' type='market' />
              <CustomBarWithOpacity opacityPercent={17} text='6' type='market' />
            </CustomBarContainer>
            {!isEmptyPortfolio &&
              <CustomBarContainer>
                <CustomBarWithOpacity opacityPercent={100} text='1 Night' type='portfolio' />
                <CustomBarWithOpacity opacityPercent={83} text='2' type='portfolio' />
                <CustomBarWithOpacity opacityPercent={67} text='3' type='portfolio' />
                <CustomBarWithOpacity opacityPercent={50} text='4' type='portfolio' />
                <CustomBarWithOpacity opacityPercent={33} text='5' type='portfolio' />
                <CustomBarWithOpacity opacityPercent={17} text='6' type='portfolio' />
              </CustomBarContainer>
            }
          </BodyContainer>
        </RentalSettingsContainer>
      }
    </>
  );
}

export default RentalSettings;