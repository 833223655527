import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/ReusableComponents/Button/ButtonWithFunction';
import DOMPurify from 'dompurify';

import { CheckCircleFilled } from '@ant-design/icons';

const ButtonCSS = {
  width: '80%',
  margin: '0 auto',
  height: '50px',
};

const RevenueOSButtonCSS = {
  width: '80%',
  margin: '0 auto',
  height: '50px',
  background: '#EEEEEE',
  color: '#000'
};

const ProductContainer = styled.div`
  height: 100%;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  width: 25vw;
  min-width: 450px;
  padding: 30px 0;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  position: relative;

  display: flex;
  flex-direction: column;
`;

const ProductName = styled.div`
  color: #1BB3CD;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1.25rem;
`

const ProductPriceContainer = styled.div`
  background: ${props => props?.background || '#F5F5F5'};
  height: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ProductPrice = styled.div`
  font-size: 3rem;
  font-weight: 400;
  color: #283334;
`;

const ProductFrequency = styled.div`
  width: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #283334;
`;

const ProductFeatures = styled.div`
  margin: 2rem 1rem 0;
  flex-grow: 1;
`;

const ProductFeature = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  
  padding-bottom: 1rem;
  span {
    margin-left: 1rem;
  }
  em {
    font-style: normal;
    color: #1BB3CD;
    font-weight: 400;
  }
`;

const ListWithOutBullets = styled.ul`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  padding: 0 20px;
  list-style-type: none;
  margin: 0;

  li {
    margin-bottom: 8px;

    span {
      color: #697273;
    }
  }
`;

const renderButton = (props, redirect) => {
  // if (props.isChangePlan) {
  //   return <Button onClickFunc={props.onChangePlan} style={ButtonCSS} label={'Change Plan'} />
  // } else if (props.isDowngrade) {
  //   return <Button onClickFunc={props.onChangePlan} style={ButtonCSS} label={'Downgrade'} />
  // } else if (props.isCurrentPlan) {
  //   return;
  // } else if (props.isUpgrade) {
  //   return <Button onClickFunc={props.onChangePlan} style={ButtonCSS} label={props.name === 'Consultancy' ? 'Contact Us' : 'Upgrade'} />
  // } else if (props.buttonLabel) {
  //   return <Button onClickFunc={props.onChangePlan} style={ButtonCSS} label={props.buttonLabel} />
  // }
  if (props.name === 'RevenueOS') {
    return (
      <Button
        style={RevenueOSButtonCSS}
        label={<span>Your Plan {props.subscription?.status === 'trialing' && (`(Free trial active - ${props.daysLeft} days left)`)}</span>}
      />
    );
  } else {
    return <Button style={ButtonCSS} label={'Contact Support'} onClickFunc={redirect}/>
  }
};

export default function Product(props) {
  
  const renderHTML = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  const redirectToCalendly = () => {
    window.open('https://calendly.com/quibblerm/quibble-for-enterprise-demo', '_blank');
  };

  return (
    <ProductContainer>
      <ProductName>{props?.name}</ProductName>
      <ProductPriceContainer background={props?.priceContainerBackground}>
        <ProductPrice>
          {props?.label}
        </ProductPrice>
        <ProductFrequency>
          {props?.sublabel}
        </ProductFrequency>
      </ProductPriceContainer>
      <ProductFeatures>
        {props.features?.map((feature, index) => {
          return (
            <ProductFeature key={index}>
              <CheckCircleFilled style={{ fontSize: '1rem', color: '#1BB3CD' }} /> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feature) }}></span>
            </ProductFeature>
          )
        })}
        <ListWithOutBullets>
          {props.additionalFeatures?.map((item, index) => (
            <li key={`plan-two-${index}`}>
              <div dangerouslySetInnerHTML={renderHTML(item)} />
            </li>
          ))}
        </ListWithOutBullets>
      </ProductFeatures>
      {renderButton(props, redirectToCalendly)}
    </ProductContainer>
  )
}