import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AuthItemDiv, AuthTitle, FormDiv, Link } from '../sharedComponents/CommonCss';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from '../../../components/ReusableComponents/Button/Button';
import { useHistory } from 'react-router-dom';
import { userSignIn } from '../../../appRedux/actions';
import { useDispatch } from 'react-redux';
import { Button, message } from 'antd';
import queryString from 'query-string';
import useMutation from '../../../components/customHook/useMutation';

const SignInDiv = styled.div`
  ${AuthItemDiv};
  margin: 60px 0 0 0;
`;


const SignInButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;

const ConnectButton = styled(PrimaryButton)`
  width: 460px;
  margin: 32px 0px;
  background: none;
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  color: ${({theme}) => theme.quibbleBlue};
`;

const ForgotPassword = styled.button`
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
  background: none;
  border: none;
  outline: none;
`;


const SignIn = () => {
  const query = queryString.parse(window.location.search)
  const [profileForm, setProfileForm] = useState({
    email: {value: '', error: false},
    password: {value: '', error: false},
    aEmail: {value: '', error: false},
  });
  const { request, loading, } = useMutation({
    method: "POST",
    url: 'api/get_user_admin_otp',
    onComplete: (data) => {
      message.success(data, 10);
    },
    onError: (e) => {
      message.error(e?.response?.data || "Error Completing request", 10);
    }
  })

  const [passEmpty, setPassEmpty] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const onFormChange = useCallback((formKey, {value, error}) => {
    if (formKey === 'password') {
      setPassEmpty(() => value.length ? false : true);
    };

    if (formKey === 'email') {
      setEmailEmpty(() => value.length ? false : true);
    };

    setProfileForm((prev) => ({...prev, [formKey]: {value, error}}));
  } ,[]);

  const onForgotClick = () => {
    history.push('/forgot-passwords');
  };

  const onSignup = () => {
    history.push('/signup');
  }

  const onSignIn = async() => {
    // dispatch(userSignIn(values));
    try {
      const {email, password} = profileForm;

      if (!email.value.length){
        setEmailEmpty(true);
      };

      if ( !password.value.length) {
        setPassEmpty(true);
      };

      if (!password.value.length || !email.value.length) {
        return;
      }

    await dispatch(userSignIn({email: email.value, password: password.value, aEmail: profileForm.aEmail.value}))    


} catch (e) {
  console.error('Error:', e);
}
}


  const handleGetAdminOneTimePassword = () => {
    if (loading) return;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!profileForm.aEmail.value || !emailRegex.test(profileForm.aEmail.value)) {
      return message.error("A valid admin email is required", 10);
    }
    return request({aEmail:profileForm.aEmail.value})
  }

  const handleOnKeyPress = (e) => {
    const keyCode = e.which || e.charCode;
    if (keyCode === 13) {
      onSignIn();
    }
  };


  return (
    <SignInDiv>
      <AuthTitle>
        Welcome
      </AuthTitle>
      <FormDiv>
        <FormItemInput formKey="email" value={profileForm.email.value} label="Email" onChangeFunc={onFormChange} formItemRules={{isRequiredAndEmpty: emailEmpty}} />
        { query.type === "alogin" && <FormItemInput formKey="aEmail" value={profileForm.aEmail.value} label="Admins Email" onChangeFunc={onFormChange} formItemRules={{isRequiredAndEmpty: false}}  />}
        <FormItemInput formKey="password" label="Password" value={profileForm.password.value} isPassword onChangeFunc={onFormChange} formItemRules={{isRequiredAndEmpty: passEmpty}}  onKeyPress={handleOnKeyPress}/>
      </FormDiv>
      <SignInButton onClick={onSignIn}>
        Sign In
      </SignInButton>
      { query.type !== "alogin" ? <>
          <ForgotPassword onClick={onForgotClick}> <Link>Forgot Password?</Link></ForgotPassword>
        </> :
        <>
          <Button disabled={ loading } className="app-button-primary-forgot" onClick={ handleGetAdminOneTimePassword }>
            <span className="forgot-span">{ loading ? "Please Wait..." : "Get Admin Otp" }</span>
          </Button>
        </> }
      <ConnectButton onClick={onSignup}>
        Don't have an account? Sign Up
      </ConnectButton>
    </SignInDiv>
  )
}

export default SignIn;
