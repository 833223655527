import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { user } from "../../services/user";
import { home } from "../../services/home";
// import isEqual from "lodash/isEqual";

import {
  USER_DASHBOARD,
  MARKET_WATCH,
  LISTINGS_WATCH,
  LISTINGS_WATCH_BUILDING,
  USER_UPDATE_PROFILE,
  GET_LOGIN_USER_DETAILS,
  GET_USER_DETAILS,
  GET_USERS_LISTING,
  USER_CHANGE_PASSWORD,
  UPDATE_USER_STATUS,
  GET_COUNTRY_LIST,
  SET_EMAILFREQUENCY,
  GET_USER_METRICS_SUMMARY,
  GET_USER_METRICS_SUMMARY_SUCCESS,
  GET_USER_PORTFOLIO,
  GET_USER_PORTFOLIO_SUCCESS,
  UPDATE_USER_PMS,
  UPDATE_USER_PMS_SUCCESS,
} from "constants/ActionTypes";

import {
  updateMarketWatch,
  updateListingWatch,
  updateNotificationWatch,
  updateListingWatchBuilding,
  updateuserInfo,
  showAuthMessage,
  showUserProfileErrorMessages,
  userUpdateProfileSuccess,
  getLoginUserSuccess,
  getUserSuccess,
  updateUsersList,
  showUserErrorMessages,
  userChangePasswordSuccess,
  updateUserStatusSuccess,
  fiveHundredError,
  getCountryListSuccess,
  setInsightModalData,
} from "../../appRedux/actions/Users";
import { GET_INSIGHT_MODAL_TYPE, NOTIFICATION_WATCH } from "../../constants/ActionTypes";

// eslint-disable-next-line
import { getData, storeData } from "../../util/indexDbHelper";

/** Setting user dashboard data request */
const getUserDashboardRequest = async (data) =>
  await user
    .dashboard(data)
    .then((result) => result)
    .catch((error) => error);

/** function to get user dashboard info */
function* getUserDashboardInfo({ payload }) {
  try {
    const userDashboard = yield call(getUserDashboardRequest, payload);
    yield put(updateuserInfo(userDashboard.data));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user dashboard */
export function* userDashboardInfo() {
  yield takeLatest(USER_DASHBOARD, getUserDashboardInfo);
}




/** Setting user markets data request */
const getUserMarketsRequest = async (data) =>
  await home
    .marketsWatch(data)
    .then((result) => result)
    .catch((error) => error);

/** function to get user markets info */
function* getUserMarketsInfo({ payload }) {
  try {
      let userMarkets = yield call(getUserMarketsRequest, payload);
      yield put(updateMarketWatch(userMarkets.data));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user markets */
export function* userMarketsInfo() {
  yield takeEvery(MARKET_WATCH, getUserMarketsInfo);
}


/** Setting user listings data request */
const getUserHomeListingsRequest = async (data) =>
  await home
    .listingWatch(data)
    .then((result) => result)
    .catch((error) => error);

/** function to get user listings info */
function* getUserHomeListingsInfo({ payload }) {
  try {
    let userListings = yield call(getUserHomeListingsRequest, payload);
    yield put(updateListingWatch(userListings.data));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user listings */
export function* userHomeListingsInfo() {
  yield takeEvery(LISTINGS_WATCH, getUserHomeListingsInfo);
}

/** Setting user notification data request */
const getUserHomeNotificationRequest = async (data) =>
  await home
    .notificationWatch(data)
    .then((result) => result)
    .catch((error) => error);

    /** function to get user listings info */
function* getUserHomeNotificationsInfo({ payload }) {
  try {
    let notificationListing = yield call(getUserHomeNotificationRequest, payload);
    yield put(updateNotificationWatch(notificationListing.data));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user listings */
export function* userNotificationListingsInfo() {
  yield takeLatest(NOTIFICATION_WATCH, getUserHomeNotificationsInfo);
}

/** Setting user listings building data request */
const getUserHomeListingsBuildingRequest = async (data) =>
  await home
    .listingWatchBuilding(data)
    .then((result) => result)
    .catch((error) => error);

/** function to get user listings info */
function* getUserHomeListingsBuildingInfo({ payload }) {
  try {
    let userListings = yield call(getUserHomeListingsBuildingRequest, payload);
    yield put(updateListingWatchBuilding(userListings.data));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user listings */
export function* userHomeListingsBuildingInfo() {
  yield takeEvery(LISTINGS_WATCH_BUILDING, getUserHomeListingsBuildingInfo);
}





/** Request to Update User Details */
const updateUserProfileDetailsRequest = async (data) =>
  await user
    .userProfileUpdate(data)
    .then((authUser) => authUser)
    .catch((error) => error);

function* updateUserProfileDetails({ payload, isSelf }) {
  try {
    const updatedUser = yield call(updateUserProfileDetailsRequest, payload);
    if (updatedUser?.data?.status) {
      localStorage.setItem("currency", updatedUser?.data?.result?.currency_symbol);
      yield put(
        showUserErrorMessages({
          status: true,
          message: updatedUser?.data?.message,
        })
      );
      yield put(
        userUpdateProfileSuccess(
          updatedUser?.data?.result ? updatedUser.data.result : "",
          isSelf
        )
      );
    } else {
      if (
        updatedUser?.data?.errors &&
        Object.keys(updatedUser?.data?.errors).length > 0
      ) {
        yield put(showUserProfileErrorMessages(updatedUser?.data?.errors));
      } else {
        yield put(
          showUserErrorMessages({
            status: false,
            message: updatedUser?.data?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user update profile */
export function* updateUser() {
  yield takeEvery(USER_UPDATE_PROFILE, updateUserProfileDetails);
}

/** End Request to Update User Details */

/** Request to get auth User Detais Request */
const authUserDetailsRequest = async (data) =>
  await user
    .getUserDetail(data)
    .then((result) => result)
    .catch((error) => error);

function* authUserDetailsData({ payload }) {
  try {
    // const state = yield select();

    const authUserDetailsDataInfo = yield call(authUserDetailsRequest, payload);
    let resultData = authUserDetailsDataInfo?.data?.result
      ? authUserDetailsDataInfo.data.result
      : "";
    yield put(getLoginUserSuccess(resultData ?? {}));



  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* authUserDetails() {
  yield takeEvery(GET_LOGIN_USER_DETAILS, authUserDetailsData);
}
/** End :: Request to get get auth User Detais Request */

/** Request to get User Details Request */
function* userDetailsData({ payload }) {
  try {

    //here we will create the user with the data
    const userDetailsDataInfo = yield call(authUserDetailsRequest, payload);

    let resultData = userDetailsDataInfo?.data?.result
      ? userDetailsDataInfo.data.result
      : "";

    //The data is going to be placed with getUser
    yield put(getUserSuccess(resultData ?? {}));

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
//it listen the GET_USER_DETAIL action and use userDetailsData
export function* userDetails() {
  yield takeEvery(GET_USER_DETAILS, userDetailsData);
}
/** End :: Request to get get an User Detail Request */

/** Request to get User Listing Request */
const userListingRequest = async (data) =>
  await user
    .getUserLists(data)
    .then((result) => result)
    .catch((error) => {});

function* usersListingData({ payload }) {
  try {
    const userListInfo = yield call(userListingRequest, payload);
    //yield put(updateUsersList(userListInfo.data));

    if (userListInfo?.data?.status) {
      if (userListInfo?.data?.message !== "") {
        yield put(
          showUserErrorMessages({
            status: true,
            message: userListInfo?.data?.message,
          })
        );
      }
      yield put(updateUsersList(userListInfo?.data));
    } else {
      if (
        userListInfo?.data?.errors &&
        Object.keys(userListInfo?.data?.errors).length > 0
      ) {
        yield put(showUserProfileErrorMessages(userListInfo?.data?.errors));
      } else {
        yield put(
          showUserErrorMessages({
            status: false,
            message: userListInfo.data.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(fiveHundredError({ errorType: 500 }));
    //yield put(showAuthMessage(error));
  }
}

export function* userListing() {
  yield takeEvery(GET_USERS_LISTING, usersListingData);
}
/** End :: Request to get get auth User Detais Request */

/** Request to Change User Password */

const changeUserPasswordRequest = async (data) =>
  await user
    .userChangePassword(data)
    .then((authUser) => authUser)
    .catch((error) => error);

function* changeUserPassword({ payload }) {
  try {
    const changePasswordInfo = yield call(changeUserPasswordRequest, payload);

    if (changePasswordInfo.data.status) {
      yield put(
        showUserErrorMessages({
          status: true,
          message: changePasswordInfo.data.message,
        })
      );
      yield put(
        userChangePasswordSuccess(
          changePasswordInfo.data.result ? changePasswordInfo.data.result : ""
        )
      );
    } else {
      if (
        changePasswordInfo.data.errors &&
        Object.keys(changePasswordInfo.data.errors).length > 0
      ) {
        yield put(showUserProfileErrorMessages(changePasswordInfo.data.errors));
      } else {
        yield put(
          showUserErrorMessages({
            status: false,
            message: changePasswordInfo.data.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user change passwod */
export function* updateUserPassword() {
  yield takeEvery(USER_CHANGE_PASSWORD, changeUserPassword);
}

/** End Request to Change User Password */

/** Request to Change User Password */
const updateUserStatusRequest = async (data) =>
  await user
    .userUpdateStatusValue(data)
    .then((authUser) => authUser)
    .catch((error) => error);

function* updateUserProfileStatus({ payload }) {
  try {
    const userInfo = yield call(updateUserStatusRequest, payload);
    if (userInfo.data.status) {
      yield put(
        showUserErrorMessages({ status: true, message: userInfo.data.message })
      );
      yield put(
        updateUserStatusSuccess(
          userInfo.data.result ? userInfo.data.result : ""
        )
      );
    } else {
      if (
        userInfo.data.errors &&
        Object.keys(userInfo.data.errors).length > 0
      ) {
        yield put(showUserProfileErrorMessages(userInfo.data.errors));
      } else {
        yield put(
          showUserErrorMessages({
            status: false,
            message: userInfo.data.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Export function user change passwod */
export function* updateUserStatus() {
  yield takeEvery(UPDATE_USER_STATUS, updateUserProfileStatus);
}

/** Request to get country list */
const countryListRequest = async (data) =>
  await user
    .getCountryDetail(data)
    .then((result) => result)
    .catch((error) => error);

function* countryListData({ payload }) {
  try {
    const countryDetailsDataInfo = yield call(countryListRequest, payload);
    let resultData = countryDetailsDataInfo?.data
      ? countryDetailsDataInfo.data
      : [];
    yield put(getCountryListSuccess(resultData ?? {}));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* allCountryListDetails() {
  yield takeEvery(GET_COUNTRY_LIST, countryListData);
}
/** End :: Request to get get auth User Detais Request */

// Insight Data
const callApiModalData = async (data) =>
  await user
    .getModalData(data)
    .then((result) => result)
    .catch((error) => error);

function* getModalData({ payload }) {
  try {
    const result = yield call(callApiModalData, payload);
    let resultData = result.data
      ? result.data
      : {};
    yield put(setInsightModalData(resultData ?? {}));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// Insight Data

export function* getInsightModalData() {
  yield takeEvery(GET_INSIGHT_MODAL_TYPE, getModalData);
}

function* invokeSetEmailFrequency({payload}){
  const updatedUser = yield call(updateUserProfileDetailsRequest, payload);

  if (updatedUser.data.status) {
    yield put(
      userUpdateProfileSuccess(
        updatedUser.data.result ? updatedUser.data.result : {},
        true
      )
    );
  } else {
    if (
      updatedUser.data.errors &&
      Object.keys(updatedUser.data.errors).length > 0
    ) {
      yield put(showUserProfileErrorMessages(updatedUser.data.errors));
    } else {
      yield put(
        showUserErrorMessages({
          status: false,
          message: updatedUser.data.message,
        })
      );
    }
  }
}

export function* setEmailFrequency() {
  yield takeEvery(SET_EMAILFREQUENCY, invokeSetEmailFrequency);
}

function* getMetricsSummary({payload}){
  try {
    let params = {};

    if (payload.properties?.length > 0) params.properties = payload.properties?.join(',');
    if (payload.country) params.country = payload.country;

    if (payload.year) {
      params.year = payload.year;
    } else {
      const date = new Date(payload.month);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      params.month = month;
      params.year = year;
    }

    if (payload.sameStore) params.sameStore = payload.sameStore;
    params.view = payload.viewType;

    const summary = yield call(user.getUserMetricsSummary, { params });
    yield put({ type: GET_USER_METRICS_SUMMARY_SUCCESS, payload: summary });
  } catch (err) {
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving metrics summary',
      })
    );
  }

}

export function* getUserMetricsSummaryWatcher() {
  yield takeLatest(GET_USER_METRICS_SUMMARY, getMetricsSummary);
}

function* getPortfolio({payload}){
  try {
    let params = {};

    if (payload.properties?.length > 0) params.properties = payload.properties?.join(',');
    if (payload.country) params.country = payload.country;

    if (payload.year) {
      params.year = payload.year;
    } else {
      const date = new Date(payload.month);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      params.month = month;
      params.year = year;
    }

    if (payload.sameStore) params.sameStore = payload.sameStore;
    params.view = payload.viewType;


    const portfolio = yield call(user.getUserPortfolio, { params });
    yield put({ type: GET_USER_PORTFOLIO_SUCCESS, payload: portfolio });
  } catch (err) {
    yield put(
      showUserErrorMessages({
        status: false,
        message: 'Error retrieving metrics summary',
      })
    );
  }

}

export function* getUserPortfolioWatcher() {
  yield takeLatest(GET_USER_PORTFOLIO, getPortfolio);
}

function* updateUserPms({ payload }){
  try {
    yield call(user.updateUserPms, payload);
    yield put({ type: UPDATE_USER_PMS_SUCCESS });
  } catch (err) {
    yield put(showAuthMessage(err));
  }

}

export function* updateUserPmsWatcher() {
  yield takeLatest(UPDATE_USER_PMS, updateUserPms);
}

/** Export all */
export default function* rootSaga() {
  yield all([
    fork(userHomeListingsBuildingInfo),
    fork(userHomeListingsInfo),
    fork(userNotificationListingsInfo),
    fork(userMarketsInfo),
    fork(userDashboardInfo),
    fork(updateUser),
    fork(userDetails),
    fork(authUserDetails),
    fork(allCountryListDetails),
    fork(userListing),
    fork(updateUserPassword),
    fork(updateUserStatus),
    fork(getInsightModalData),
    fork(setEmailFrequency),
    fork(getUserMetricsSummaryWatcher),
    fork(getUserPortfolioWatcher),
    fork(updateUserPmsWatcher),
  ]);
}
