import styled from "styled-components";

import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText, BodyTitle } from 'components/ReusableComponents/Text/Text';


export const ModalBody = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.50);
  z-index: 2002;
  display: ${({$display}) => $display || 'none'};
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  ${BodyCSS}
  display: flex;
  width: 482px;
  height: 289px;

  display: flex;
  flex-direction: column;
  border-radius: 16px;
`;

export const ModalTitle = styled(BodyTitle)`
  font-family: Inter;
`;


export const ModalClose = styled(BodyText)`
  top: 4px;
  right: 12px;
  position: absolute;
  font-size: 1.2rem;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.1s linear;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 85px;
  border-bottom: var(--CompSub-Share-2, 1px) solid #E8E8E8;
  padding: 30px;
  position: relative;
`;

export const ModalContentbody = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 16px 16px; 
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 4px;
  width: fit-content;
  height: fit-content;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`; 
