import React, { useCallback, useState, memo, Fragment, Suspense, lazy } from 'react';
import styled, {useTheme} from 'styled-components';
import {useSelector} from "react-redux";
import {
  MenuUnfoldOutlined,
  DownOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom";
import { media } from '../../styles/stylesheet';
import { foldIconStyle } from '../../components/ReusableComponents/Icons/Icons';
import QuibbleLogo from '../../assets/images/quibblerm.png';
import QuibbleLogMini from 'assets/images/quibbleLogin.svg';
import useScrollPosition from '../../customHooks/useScrollPosition';
import { BodyText } from '../../components/ReusableComponents/Text/Text';
import Notifications from './components/Notifications';
import WorkingQueue from './components/WorkingQueueMenu';

const HeaderDropMenu = lazy(() => import('./components/HeaderV3Menu'));
const ChangeViewModal = lazy(() => import('./components/ChangeViewModal'));

const UnfoldIcon = styled(MenuUnfoldOutlined)`
  display: none;

  ${media.bpm} {
    ${foldIconStyle}
    display: initial;
    left: 24px;
    cursor: pointer;
    position: absolute;
    ${({theme}) => `
      font-size: ${theme.s8};
      color: ${theme.bodyFg};
    `}
  }

  ${media.bpxs} {
    left: 12px;
    font-size: ${({theme}) => theme.s6};
  }


`;

const HeaderLogo = styled(Link)`
  display: none;

  ${media.bpm} {
    display: initial;
    width: 135px;
    height: 70px;
    image-rendering: -webkit-optimize-contrast;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    background-image: url(${QuibbleLogo});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const HeaderDiv = styled.div`
  // width: 100%;
  z-index: 1000;
  display: flex;
  flex-diraction: row;
  align-items: center;
  position: relative;
  border-radius: 0px;
  min-height: 64px;
  top: 0px;
  right: 0px;
  // box-shadow: ${({boxShadow}) => boxShadow};
  // background: ${({theme}) => theme.bodyBg};
  // box-shadow: ${({theme}) => theme.isDefault ? 'rgba(0, 0, 0, 0.15) 1.95px 0.5px 1px' : 'rgba(255, 255, 255, 0.15) 1.95px 0.5px 1px' } ;


  ${media.bpm} {
    padding: 0px;
    position: fixed;
    ${({theme}) => `
      background: ${theme.bodyBg};
      box-shadow: ${theme.isDefault ? 'rgba(0, 0, 0, 0.15) 1.95px 0.5px 1px' : 'rgba(255, 255, 255, 0.15) 1.95px 0.5px 1px' } ;
    `}
  }


`;

const HeaderTitle = styled(BodyText)`
  font-weight: 400;
  color:  ${({menuActive, theme}) => (menuActive === true ? theme.quibbleBlue : 'black')};

`;

const HeaderMenuDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 99999;
  border: none;
  outline: none;
`;

const HeaderMenu = styled(HeaderMenuDiv)`
  cursor: pointer;
  padding: 0 4px;
`;

const Brandlogo = styled.img`
  background: transparent;
  border-radius: 100%;
  border: 1px solid ${({theme}) => theme.quibbleBlue};
`;

const DownLogo = styled(DownOutlined)`
  color: ${({theme}) => theme.bodyFg};
  font-size: 10px;
  margin: 2px 0 0 0;
`;

const RightMenuContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  z-index: 2000;
  right: 1rem;
  top: 1rem;
`;

const Header = ({onToggleDrawerVisible,onToggleTheme, contentRef}) => {

  const [showPopover] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const {scrollY} = useScrollPosition(contentRef);
  const {isDefault} = useTheme();
  const scrollGotTargetOffset = scrollY > 32;
  const [showViewModal, setShowViewModal] = useState(false);
  const [menuActive, setMenuActive] = useState(false)

  const authData = useSelector(({users:{authUserDetails}}) => authUserDetails);
  const {first_name, brand_logo} = authData;


  const setBoxShadow = useCallback(() => {
    if (scrollGotTargetOffset) {
      if (isDefault) {
        return 'rgba(0, 0, 0, 0.15) 1.95px 0.5px 1px';
      }
      return 'rgba(255, 255, 255, 0.15) 1.95px 0.5px 1px';
    }
    return 'none';
  }, [scrollGotTargetOffset, isDefault])

  const showViewModalFunc = useCallback(() => {
    setShowViewModal(true);
  },[])

  const hideModal = useCallback(() => {
		setShowViewModal(false);
	}, []);

  const onHeaderDrop = useCallback(() => {
    setShowDrop(prev =>!prev);
    setMenuActive(prev =>!prev);

  }, []);


  return (
    <Fragment>
      <HeaderDiv boxShadow={setBoxShadow()} showPopover={showPopover}>
        <UnfoldIcon onClick={onToggleDrawerVisible}/>
        <HeaderLogo to="/home"/>
      </HeaderDiv>
      <RightMenuContainer>
        <WorkingQueue isNative={true}/>
        <Notifications v={1} />
        <HeaderMenuDiv
          onClick={onHeaderDrop}
        >
          <HeaderMenu >
            <Brandlogo alt="brand logo" src={brand_logo || QuibbleLogMini}  width="36px" height="36px"/>
            <HeaderTitle margin='0 10px' menuActive={menuActive}> {first_name || 'Quibble User'}</HeaderTitle>
            <DownLogo />
          </HeaderMenu>
          <Suspense fallback={null}>
            <HeaderDropMenu showViewModalFunc={showViewModalFunc} onToggleTheme={onToggleTheme} showDrop={showDrop} setShowDrop={onHeaderDrop} />
          </Suspense>
        </HeaderMenuDiv>
      </RightMenuContainer>
      <Suspense fallback={null}>
        <ChangeViewModal visibleModal={showViewModal} hideModal={hideModal} />
      </Suspense>
    </Fragment>
  )
}

export default memo(Header);
