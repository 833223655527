import React, {Fragment, memo} from 'react';
import styled, { useTheme } from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle } from 'components/ReusableComponents/Text/Text';
import {currencyFormatter} from 'util/helper';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import SkLoaderOver from 'components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { isEmpty } from 'lodash';
import { EmptyBodyText } from '../CommonCSS';


const MarketPropertyMetricDiv = styled.div`
  width: 100%;
  max-width: 766px;
  height: ${({height}) => height};
  padding:  ${({padding}) => padding};
  display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
  position: relative;
  ${BodyCSS}
`;

const BoxDivs = styled.div`
  width: fit-content;
  display: flex;
  margin: 24px 0 0 0;
  flex-direction: row;
  gap: 24px;
`;

const MetricBox = styled.div`
  width: 224px;
  height: ${({height}) => height};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.dirtyGrey};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MetricValueSpan = styled.span`
  color:${({color, theme}) => color || theme.bodyFg};
  font-size: ${({theme}) => theme.s8};
  font-weight: 300;
`;

const BodyTextGrey = styled(BodyText)`
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  margin: 12px 0 0 0;
`;

const PropertyMetrics = ({monthYear, metrics, isFetchingMetrics, isMarket=false}) => {
  
  const {adr, occ, totalRent} = metrics || {};
  const theme = useTheme();

  const metricBoxHeight = isMarket ? '112px' : '136px';

  return (
    <MarketPropertyMetricDiv height={isMarket ? '200px' : '236px'} padding={isMarket ? '16px 24px' : '24px' }>
      {isEmpty(metrics) ? <EmptyBodyText>No Data Found</EmptyBodyText> : 
      <Fragment>
        <BodyTitle>
          {`${monthYear?.getFullYear() || 'year'} Metrics`}
        </BodyTitle>
        <BoxDivs>
          <MetricBox height={metricBoxHeight}>
            <MetricValueSpan color={theme.quibbleBlue}>
              {`${currencyFormatter(totalRent) || '-'}`}
            </MetricValueSpan>
            <BodyTextGrey> Total Revenue </BodyTextGrey>
          </MetricBox>
          <MetricBox height={metricBoxHeight}>
            <MetricValueSpan color={theme.quibblePurple}>
              {`${currencyFormatter(adr) || '-'} `}
            </MetricValueSpan>
            <BodyTextGrey> ADR </BodyTextGrey>
          </MetricBox>
          <MetricBox height={metricBoxHeight}>
            <MetricValueSpan color={theme.quibbleYellow}>
              {`${(occ * 100).toFixed(0)}%`}
            </MetricValueSpan>
            <BodyTextGrey> Occupancy </BodyTextGrey>
          </MetricBox>
        </BoxDivs>
      </Fragment>}
      <SkLoaderOver isLoading={isFetchingMetrics || isEmpty(metrics)} />
    </MarketPropertyMetricDiv>
  )
}

export default memo(PropertyMetrics);