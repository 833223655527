import styled from 'styled-components';

// import MHMapHeaderFilters from './MHMapHeaderFilters';

const GenericFlexContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;

const MHHeaderContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
`;

const MHHeaderFiltersContainer = styled.div`
  flex-grow: 1;
`;

const MyPortfolioLegend = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #2ECADB;
  margin-right: 5px;
`;

const MarketLegend = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #B989F6;
  margin-right: 5px;
`;

const MHHeaderLegendsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;
  padding: 0 10px;
`;

const MHMapHeader = () => {
  return (
    <MHHeaderContainer>
      <MHHeaderFiltersContainer>
        {/* <MHMapHeaderFilters /> */}
      </MHHeaderFiltersContainer>
      <MHHeaderLegendsContainer>
        <GenericFlexContainer>
          <MarketLegend/>
          Market
        </GenericFlexContainer>
        <GenericFlexContainer>
          <MyPortfolioLegend/>
          My Portfolio
        </GenericFlexContainer>
      </MHHeaderLegendsContainer>
    </MHHeaderContainer>
  );
}

export default MHMapHeader;
