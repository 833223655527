import {
  GET_PORTFOLIO_ANALYTICS
} from "../../constants/ActionTypes";


export const getPortfolioAnalytics = (metric, year, view, sameStore, property, viewType) => {
  return {
    type: GET_PORTFOLIO_ANALYTICS,
    metric,
    year,
    view,
    sameStore,
    property,
    viewType,
  };
};