import axios from "axios";
//import {jwtExpiration} from "../../src/constants/GlobalConstant";

let baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ withCredentials: false, baseURL });

const urlEncode = function(data) {
  var str = [];
  for (var p in data) {
      if (data.hasOwnProperty(p) && (!(data[p] === undefined || data[p] === null))) {
          str.push(encodeURIComponent(p) + "=" + (data[p] ? encodeURIComponent(data[p]) : ""));
      }
  }
  return str.join("&");
}

export const chargeBeeRequest = {

  generateCheckoutNewUrl: async (data) => {

    let dataVal  =  await service.post("/api/generate_checkout_new_url", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});

    return dataVal;
  },

  generateCheckoutExistingPlan: async (data) => {

    let dataVal  =  await service.post("/api/generate_checkout_existing_plan", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});

    return dataVal;
  },

  upgradeSubscription: async (data) => {

    let dataVal  =  await service.post("/api/upgrade_subscription", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },

  getSubscription: async (data) => {

    let dataVal  =  await service.post("/api/get_subscription", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },

  createNewCustomer: async (data) => {

    let dataVal  =  await service.post("/api/create_new_customer", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },

  updateCustomer: async (data) => {

    let dataVal  =  await service.post("/api/update_customer", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },

  getCustomer: async (data) => {

    let dataVal  =  await service.post("/api/get_customer", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },

  deleteCustomer: async (data) => {

    let dataVal  =  await service.post("/api/delete_customer", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },


  cleanDataOfaCustomer: async (data) => {

    let dataVal  =  await service.post("/api/clean_data_of_a_customer", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },

  getAllPlans: async (data) => {

    let dataVal  =  await service.post("/api/get_all_plans", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },

  getAllCustomers: async (data) => {

    let dataVal  =  await service.post("/api/get_all_customers", urlEncode(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    return dataVal;
  },


};
