import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import Warning from './components/Warning';
import ListingFilter from './components/ListingFilter';
import ListingTable from './components/ListingTable';
import ListingDrawer from './components/ListingDrawer';
import { useSelector } from 'react-redux';
import ListingComparison from './components/ListingComparison';
import { message } from 'antd';
// import SubscriptionPlan from '../../containers/PayWall/components/SubscriptionPlan';


const ListingsBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;


const Listings = () => {

  const [showWarning, setShowWarning] = useState(false);
  const [nothingSet, setNothingSet] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState('');
  const [updating, setUpdating] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [isDynamicPricingEnabled, setIsDynamicPricingEnabled] = useState(false);

  const [listingDrawer, setListingDrawer] = useState({
    isVisible: false,
  });
  const [comparisonDetails, setComparisonDetails] = useState({
    isVisible: false,
  });

  const { listings} = useSelector(({ markets }) => markets);

  useEffect(() => {
    if (listings.length) {
      const hasNotSet = listings.some((data) => !data.isSilentDynamicPricingEnabled);
      const nothingSetYet = listings.every((data) => !data.isSilentDynamicPricingEnabled);
      setShowWarning(hasNotSet);
      setNothingSet(nothingSetYet);
      setUpdating(false);
      message.destroy();
    };
  }, [listings]);

  return (
    <ListingsBody>
      {showWarning  && <Warning setShowWarning={setShowWarning}/>}
      <ListingFilter 
        selectedMarket={selectedMarket} 
        setSelectedMarket={setSelectedMarket} 
        setSearchString={setSearchString} 
        isDynamicPricingEnabled={isDynamicPricingEnabled}
        setIsDynamicPricingEnabled={setIsDynamicPricingEnabled}
      />
      <ListingTable 
        updating={updating}  
        setUpdating={setUpdating}
        setNothingSet={setNothingSet} 
        nothingSet={nothingSet} 
        selectedMarket={selectedMarket} 
        searchString={searchString} 
        listingDrawer={listingDrawer} 
        setListingDrawer={setListingDrawer} 
        setComparisonDetails={setComparisonDetails}
        isDynamicPricingEnabled={isDynamicPricingEnabled}
      />
      <ListingDrawer updating={updating}  listingDrawer={listingDrawer} setListingDrawer={setListingDrawer}/>
      <ListingComparison comparisonDetails={comparisonDetails} setComparisonDetails={setComparisonDetails}/>
    </ListingsBody>

  )
};

export default Listings; 