import React, {memo, useCallback} from 'react';
import {Card, Steps} from 'antd';

const {Step} = Steps;

const stepArray = [
  {
    title: 'Sign Up',
    description: "Set your account credentials",
  },
  {
    title: 'Email Verification',
    description: "Hang tight, just a simple check",
  },
  {
    title: 'Connect Your PMS',
    description: "Let's integrate your PMS",
  },
  {
    title: 'Finish and Deploy',
    description: "Time to gather your data",
  },
]

const SignupCard = ({current, title}) => {

  const renderStepArray = useCallback(() => {
    return (
      stepArray.map(({title,description}, index) => {
        const classNameString = (current === index) || (index === current + 1) ?  '' : 'app-step';
        return (
          <Step
          key={title}
          title={title}
          description={description}
          className={classNameString}
          />
        )
      })
    )
  }, [current])

  return (
    <Card
      className='app-signup-card'
      // title={title}
      title="Welcome To Quibble"
      >
      <Steps
      current={current}
      responsive={false}
      direction='horizontal'
      className='app-step-container'
      >
        {renderStepArray()}
      </Steps>
    </Card>
  )
}

export default memo(SignupCard);
