import React from 'react';
import styled from 'styled-components';
import { LoaderCSS } from './CommonCSS';


const CardLoaderDiv = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  min-height: 150px;

  margin-top: 16px;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);

  ${LoaderCSS}
`;

const MHCardLoader = () => {
  return (
    <CardLoaderDiv>
      <Skeleton />
    </CardLoaderDiv>
  )
}

export default MHCardLoader;