/** Default Import */
import React from "react";
import asyncComponent from "util/asyncComponent";
import { Route, Switch } from "react-router-dom";

/** Custom Import */
import errorsPages from "./../errorsPages/index";

import GoalPlanningV2 from './RevenuePlanning/GoalPlanningV2';
// import MarketHunter from "./MarketHunter";
// import Optimize from "./PropertyOptimize/Optimize";
import MarketHunterV2 from "./MarketHunterV2";

const BusinessTools = ({ match, sidebarCollapsed , toggleCollapseNav,  onToggleShowFooter }) => (
  <Switch>
    {/* <Route
      path={`${match.url}/markethunter`}
      render={(props) => <MarketHunter {...props} sidebarCollapsed={sidebarCollapsed} toggleCollapseNav={toggleCollapseNav} onToggleShowFooter={onToggleShowFooter} />}
    /> */}
    <Route
      path={`${match.url}/markethunter`}
      render={(props) => <MarketHunterV2 {...props} sidebarCollapsed={sidebarCollapsed} toggleCollapseNav={toggleCollapseNav} onToggleShowFooter={onToggleShowFooter} />}
    />
    <Route
      path={`${match.url}/propertyForecaster`}
      component={asyncComponent(() => import("./propertyForecaster"))}
    />
    <Route
      path={`${match.url}/planning`}
      component={GoalPlanningV2}
    />
    {/* <Route
      path={`${match.url}/property-optimize`}
      component={Optimize}
    /> */}
    <Route exact component={errorsPages} />
  </Switch>
);
export default BusinessTools;
