import changePlan from '../../assets/images/changePlan.png'
import forgotPassword from '../../assets/images/forgotPassword.png'
import updatePassword from '../../assets/images/updatePassword.png'
import summaryEmails from '../../assets/images/summaryEmails.png'

import portfolioAnalytics from '../../assets/images/portfolioAnalytics.png'
import marketAnalytics from '../../assets/images/marketAnalytics.png'
import propertyAnalytics from '../../assets/images/propertyAnalytics.png'
import competitorModelCheck from '../../assets/images/competitorModelCheck.png'
import setPrice from '../../assets/images/setPrice.png'
import ratesAdvisor from '../../assets/images/ratesAdvisor.png'
import downloadReport from '../../assets/images/downloadReport.png'
import bookingFeed from '../../assets/images/bookingFeed.png'

import priceAdjustment from '../../assets/images/priceAdjustment.png'
import checkPriceAdjustment from '../../assets/images/checkPriceAdjustment.png'
import eventsPricing from '../../assets/images/eventsPricing.png'
import setMinMaxPrice from '../../assets/images/setMinMaxPrice.png'
import stayAdjustment from '../../assets/images/stayAdjustment.png'
import overrides from '../../assets/images/overrides.png'
import pricingCalendar from '../../assets/images/pricingCalendar.png'

import dynamicPricing from '../../assets/images/dynamicPricing.png'
import activatedHostfully from '../../assets/images/activatedHostfully.png'


const generalSubHelp = [
    { 
        label: 'Introduction',
        subGuide:[],
        subVideos: [
            {
            videoUrl: 'https://www.youtube.com/embed/zwZydGEYwaQ?si=qbcci6LBswPDKEdG', 
            title:'Introducing Quibble'
            },
            {
            videoUrl: 'https://www.youtube.com/embed/1rGKd91IB-Y?si=uwSroLyAyQll7oZl', 
            title:'What Quibble Does?'
            },
            {
            videoUrl: 'https://www.youtube.com/embed/QR1gfbHEv7c?si=i0gao1zv2KgwHfXD', 
            title:'Revenue Science'
            },
            {
                videoUrl: 'https://www.youtube.com/embed/yTNAWdasp2I?si=Y98BxVWfukUJcQc1', 
                title:'Probability'
            },
            {
                videoUrl: 'https://www.youtube.com/embed/i3N-5KopLZg?si=Eb0bfEaXP6M6wapH', 
                title:'Expected Revenue'
            },
        ]
    },
    { 
        label: 'Alerts', 
        subGuide:[
        {
            title:'How to enable/disable revenue summary emails?', 
            url:'https://quibblerm.com/how-to-enable-disable-revenue-summary-emails/', 
            image: summaryEmails},
        ],
        subVideos:[]
    },
    { 
        label: 'Plans', 
        subGuide:[
            {
                title:'How to change plan?', 
                url:'https://quibblerm.com/how-to-change-plan/', 
                image: changePlan
            },
        ],
        subVideos:[]

    },
    { 
        label: 'Password', 
        subGuide:[
            {
                title:'Forgot Password?', 
                url:'https://quibblerm.com/forgot-password/', 
                image: forgotPassword
            },
            {
                title:'How to update my password?', 
                url:'https://quibblerm.com/how-to-update-my-password/', 
                image: updatePassword
            },
        ],
        subVideos:[]
    },
  ]; 

  const analyticsSubHelp = [
    { 
        label: 'Portfolio',
        subGuide:[
            {
                title:'What is Portfolio Analytics and how to use it', 
                url:'https://quibblerm.com/portfolio-analytics/', 
                image : portfolioAnalytics
            },
        ], 
        subVideos:[
        {
            videoUrl: 'https://www.youtube.com/embed/qIApxxvq0pY?si=Dp5QOrovWwRGRVfr', 
            title:'Portfolio: How to use it'
        },
        {
            videoUrl: 'https://www.youtube.com/embed/xu_K6OXxsDo?si=YRNypVD9IM4kfGnq', 
            title:'Portfolio: Buttons and Settings'
        },
        ]
    },
    { 
        label: 'Market', 
        subGuide:[
            {
                title:'What is Market Analytics and how to use it?', 
                url:'https://quibblerm.com/market-analytics/', 
                image : marketAnalytics
            },
        ],
        subVideos:[
            {
            videoUrl: 'https://www.youtube.com/embed/PM_uTU9IwDU?si=WfY-_JA086kjU39l', 
                title:'Market: How to use it'
            },
            {
                videoUrl: 'https://www.youtube.com/embed/xNA4C34qKjA?si=YJzFxsj-nopWVx87', 
                title:'Market: Buttons and Settings'
            },
        ]
    },
    { 
        label: 'Property',
        subGuide:[
            {
                title:'What is Property Analytics and how to use it?', 
                url:'https://quibblerm.com/property-analytics/', 
                image : propertyAnalytics
            },
            {
                title:'Competitor Model Check', 
                url:'https://quibblerm.com/competitor-model-check/', 
                image : competitorModelCheck
            },
            {
                title:'How to set the Far Out Price', 
                url:'https://quibblerm.com/how-to-set-the-far-out-price/', 
                image : setPrice
            },
        ],
        subVideos:[
            {
            videoUrl: 'https://www.youtube.com/embed/2OvRTzbxPvA?si=VWaxq3vtx1ADh9i6', 
            title:'Property Listing'
            },
            {
                videoUrl: 'https://www.youtube.com/embed/B7YlElRJgJQ?si=hHzwFBZ3Zn-hoJxh', 
                title:'Property: How to use it'
            },
            {
                videoUrl: 'https://www.youtube.com/embed/pDwj96X0HSk?si=gvApsWtm4-FFjnPn', 
                title:'Property: Buttons and Settings'
            },
        ]
    },
    { 
        label: 'Reports', 
        subGuide:[
        {
            title:'How to download a report?', 
            url:'https://quibblerm.com/how-to-download-a-report/',  
            image : downloadReport
        },
        ],
        subVideos:[
            {
            videoUrl: 'https://www.youtube.com/embed/74HBBMtvDng?si=gmET1oDkwVX7wm6c', 
            title:'Download Report'
            },
        ]
    },
    { 
        label: 'Rates Advisor',
        subGuide:[
        {
            title:'What is Rates Advisor and how do I use it?', 
            url:'https://quibblerm.com/rates-advisor/',  
            image : ratesAdvisor
        },
      ],
      subVideos:[]
    },
    { 
        label: 'Booking Feed',
        subGuide:[
        {
            title:'Where do I check my bookings?', 
            url:'https://quibblerm.com/Booking-Feed/',  
            image : bookingFeed},
      ],
      subVideos:[]
    }
  ];
  
  const revenueSubHelp = [
    { 
        label: 'Price Adjustment', 
        subGuide:[
        {
            title:'How to use the price adjustment?', 
            url:'https://quibblerm.com/how-to-use-the-price-adjustment/', 
            image: priceAdjustment
        },
        {
            title:'How to check the price adjustments per level?', 
            url:'https://quibblerm.com/how-to-check-the-price-adjustments-per-level/', 
            image: checkPriceAdjustment
        },
        {
            title:'Events Pricing', 
            url:'https://quibblerm.com/events-pricing/', 
            image: eventsPricing
        },
        {
            title:'How to set the far out price?', 
            url:'https://quibblerm.com/how-to-set-the-far-out-price/', 
            image: setPrice
        },
        {
            title:'How to set the Min/Max by Month?', 
            url:'https://quibblerm.com/how-to-set-the-min-max-price-by-month/', 
            image: setMinMaxPrice
        },
      ],
      subVideos:[
        {
          videoUrl: 'https://www.youtube.com/embed/3QQYhuOL6pc?si=HAGQoLfoi7H3lvih',
          title:'Price  Adjustment: How to use it'
        },
        {
          videoUrl: 'https://www.youtube.com/embed/ZaKPBB9eIyI?si=KsYaA096-_hx5Rm_', 
          title:'Events Pricing'
      },
      ]
    },

    { 
        label: 'Stay Adjustment',
        subGuide:[
        {
            title:'How to use the Stay adjustment?', 
            url:'https://quibblerm.com/how-to-use-the-stay-adjustment/', 
            image: stayAdjustment
        },
        ],
        subVideos:[]
    },
    { 
        label: 'Override',
        subGuide:[
        {
            title:'How to use override?', 
            url:'https://quibblerm.com/how-to-override/', 
            image: overrides
        },
        ],
        subVideos:[]
    },
    { 
        label: 'Pricing Calendar',
        subGuide:[
        {
            title:'How to use the pricing calendar?', 
            url:'https://quibblerm.com/how-to-use-the-pricing-calendar/', 
            image: pricingCalendar
        },
        ],
        subVideos:[]
    }
  ];
  
  const getConfigurationsSubMenu = (pms) => [
    { 
        label: 'Turning on Dynamic Pricing',
        subGuide:[
        {
            title:'Turning on Dynamic Pricing', 
            url:'https://quibblerm.com/turning-on-dynamic-pricing/', 
            image: dynamicPricing
        },
        ], 
        subVideos:[
            {
            videoUrl: 'https://www.youtube.com/embed/BNWisK5hmPg?si=tNL2TK5koWC0bUB5', 
            title:'Turning on Dynamic Pricing Part 1: Silent vs Dynamic'
            },
            {
                videoUrl: 'https://www.youtube.com/embed/6HhdykTGLic?si=ptUDbXNL9azHqiy4', 
                title:'Turning on Dynamic Pricing Part 2: Benchmark Pricing'
            },
            {
                videoUrl: 'https://www.youtube.com/embed/f0f-zck9JMI?si=rE49K1-VrYSleUM3', 
                title:'Turning on Dynamic Pricing Part 3: Model Check'
            },
        ]
    },
    { 
        label: `Have you activated this property in ${pms}?`,
        subGuide:pms === "Hostfully" ? [
        {
            title:`Have you activated this property in ${pms}?`, 
            url:'https://quibblerm.com/have-you-activated-this-property-in-hostfully/', 
            image: activatedHostfully
        },
        ] : [],
        subVideos:[]
    }
  ];
  export {generalSubHelp, analyticsSubHelp, revenueSubHelp, getConfigurationsSubMenu}