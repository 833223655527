import React from 'react';
import styled from 'styled-components';
import yellowrating from 'assets/images/generic/yellowrating.svg';
import purplerating from 'assets/images/generic/purplerating.svg';
import { BodyText } from '../Text/Text';

const StarRatingDiv = styled.div`
  width: 110px;
  min-height: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const MiddleLine = styled.div`
  width: 1px;
  height: 14px;
  border-right: 1px solid ${({theme}) => theme.quibbleBackgroundGrey};
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
`;

const RatingDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 6px;
`;

const StarRating = ({yellowLabel=0, purpleLabel=0}) => {
  return (
    <StarRatingDiv>
      <RatingDiv><BodyText>{yellowLabel?.toFixed(1) || `0.0`}</BodyText> <img src={yellowrating} alt="yellow rating"/></RatingDiv>
      <MiddleLine />
      <RatingDiv><BodyText>{purpleLabel?.toFixed(1) || `0.0`}</BodyText> <img src={purplerating} alt="purple rating"/></RatingDiv>
    </StarRatingDiv>
  )
}

export default StarRating;