import styled from "styled-components";
import imageRatingImg from 'assets/images/generic/image-rating.svg';
import { BodyText } from '../Text/Text';

const ImageRatingDiv = styled.div`
  width: ${({width}) => width};
  height:  ${({height}) => height};
  gap: 8px;
  padding: ${({padding}) => padding};

  position: absolute;
  top: ${({position}) => position};
  left: ${({position}) => position};
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 1px 4px 0px rgba(145, 165, 169, 0.50);

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageDiv = styled.img`
  width: fit-content;
  height: fit-content;
`;

const ImageRating = ({value, variant=""}) => {
  return (
    <ImageRatingDiv width={variant === "small" ? 'fit-content' : '74px'} height={variant === "small" ? '20px' : '28px'} padding={variant==="small" ? '0px 4px' : '8px'} position={variant==="small" ? '4px' : '8px'}> 
       <ImageDiv src={imageRatingImg} />
        <BodyText>
          {value}
        </BodyText>
    </ImageRatingDiv>
  )
}

export default ImageRating;