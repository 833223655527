import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CustomProgress from '../CustomProgress';
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';

const AmenitiesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;

  .ant-progress {
    margin-bottom: 0;
  }
`;

const LabelContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: relative;
`;

const Label = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;


  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const LabelHoverText = styled.div`
  opacity: 0.9;
  position: absolute;
  top: -20px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #283334;
  color: #fff;
  border-radius: 5px;

  font-size: 16px;
  font-style: normal;
  font-weight: 300;

  padding: 5px;
  max-width: 200px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  position: relative;
`;

const HoverInfo = styled.div`
  opacity: 0.9;
  position: absolute;
  top: -40px;
  left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #FFF;
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
  border-radius: 5px;

  font-family: Commissioner;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;

  padding: 5px;
`;

const MarketText = styled.div`
  color: #B989F6;
`;

const PortfolioText = styled.div`
  color: #1BB3CD;
`;

const GenericFlexContainer = styled.div`
  color: #697273;

  font-family: Commissioner;
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px;

  div {
    margin: auto;
  }
`;

const processPercentageValue = (num) => {
  let ret = num * 100;
  return ret.toFixed(0);
}

const Amenities = (props) => {

  const [chartData, setChartData] = useState([]);
  const [isEmptyPortfolio, setIsEmptyPortfolio] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverValue, setHoverValue] = useState(null);
  const [isAmenityHovered, setIsAmenityHovered] = useState(false);
  const [amenityHoverValue, setAmenityHoverValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)

    if (props.data) {
      const rawData = props.data;
      setChartData(rawData)
      setIsEmptyPortfolio(props.isEmptyPortfolio)
      setIsLoading(false);
    }
  }, [props.data, props.isEmptyPortfolio]);

  const setHoverOpen = (value) => {
    setIsHovered(true);
    setHoverValue(value);
  };

  const setHoverClose = () => {
    setIsHovered(false);
    setHoverValue(null);
  };

  const setAmenityHoverOpen = (value) => {
    setIsAmenityHovered(true);
    setAmenityHoverValue(value);
  };

  const setAmenityHoverClose = () => {
    setIsAmenityHovered(false);
    setHoverValue(null);
  };

  return (
    <>
    {isLoading ? <MHCardLoader /> :
      <AmenitiesContainer>
        <TitleContainer>
          <Title>Amenities</Title>
        </TitleContainer>
        <BodyContainer>
        {chartData && chartData.map((item) => (
          <ProgressContainer>
            <LabelContainer onMouseEnter={() => setAmenityHoverOpen(item.amenity)} onMouseLeave={setAmenityHoverClose}>
              <Label>{item.amenity}</Label>
              {(isAmenityHovered && amenityHoverValue === item.amenity) && (
                <LabelHoverText>{item.amenity}</LabelHoverText>
              )}
            </LabelContainer>
            <ProgressBarContainer onMouseEnter={() => setHoverOpen(item.amenity)} onMouseLeave={setHoverClose}>
              <CustomProgress width={processPercentageValue(item.share.market)} type='market' />
              {!isEmptyPortfolio &&
                <CustomProgress width={processPercentageValue(item.share.portfolio)} type='portfolio' />
              }
              {(isHovered && hoverValue === item.amenity) && (
                <HoverInfo>
                  <MarketText>{processPercentageValue(item.share.market)}%</MarketText>
                  {!isEmptyPortfolio &&
                    <PortfolioText>{processPercentageValue(item.share.portfolio)}%</PortfolioText>
                  }
                </HoverInfo>
              )}
            </ProgressBarContainer>
          </ProgressContainer>
        ))}
        <ProgressContainer>
            <LabelContainer></LabelContainer>
            <ProgressBarContainer>
              <GenericFlexContainer>
                <div>0%</div>
                <div>20%</div>
                <div>40%</div>
                <div>60%</div>
                <div>80%</div>
                <div>100%</div>
              </GenericFlexContainer>
            </ProgressBarContainer>
          </ProgressContainer>
        </BodyContainer>
      </AmenitiesContainer>
    }
    </>
  );
}

export default Amenities;