import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';

const HostAwayDiv = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HostAwayButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;

const HostAway = ({onSubmitForm}) => {

  const [hostAwayParams, setHostAwayParams] = useState({
    code: '',
    api_key: '',
  });
  
  const onFormChange = (formKey, {value}) => {
    setHostAwayParams((prev) => ({
      ...prev,
      [formKey]: value,
    }));
  };

  const onTokenSubmit = () => {
    const values = {
      ...hostAwayParams,
    }
    onSubmitForm(values)
  };

  return (
    <HostAwayDiv>
      <FormItemInput formKey="code" value={hostAwayParams.code}  label="Client ID" onChangeFunc={onFormChange} />
      <FormItemInput formKey="api_key" value={hostAwayParams.api_key}  label="API Key" onChangeFunc={onFormChange} />
      <HostAwayButton disabled={!hostAwayParams.code.length || !hostAwayParams.api_key.length} onClick={onTokenSubmit}> Submit </HostAwayButton>
    </HostAwayDiv>
  )
}

export default HostAway;