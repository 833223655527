import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import MarketDetailFilter from './components/MarketDetailFilter';
import MarketDescription from './components/MarketDescription';
import { darkScrollbar, lightScrollbar } from '../../../components/ReusableComponents/Scrollbar/Scrollbar';
import MarketDetailsTable from './components/MarketDetailsTable';
import { now } from '../../../constants/GlobalConstant';


const MarketDetailsBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const MarketDetails = () => {

  const [monthYear, setMonthYear] = useState(now);
  const [searchString, setSearchString] = useState('');
  const location = useLocation();
  const { state } = location;

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, []);

  const setSearchStringFunc = useCallback((value) => {
    setSearchString(value)
  }, []);

  return (
    <MarketDetailsBody>
      <MarketDetailFilter monthYear={monthYear} market={state} setSearchStringFunc={setSearchStringFunc} setMonthYearVal={setMonthYearVal}/>
        <MarketDescription monthYear={monthYear}/>
        <MarketDetailsTable searchString={searchString} monthYear={monthYear}/>
    </MarketDetailsBody>
  )
}

export default MarketDetails;
