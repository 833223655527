import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle, BodyText } from 'components/ReusableComponents/Text/Text';
import { isEmpty } from 'lodash';
import { Bar, BarChart, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import { renderActiveShape } from './RenderActiveShape';
import RenderActiveLogo from './RenderActiveLogo';
import SkLoaderOver from '../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';
import { currencyFormatter } from '../../../../../util/helper';
import CustomTick from './CustomTick';


const PriceRevenueGraphDiv = styled.div`
  ${BodyCSS}
  width: 1146px;
  min-width: 1146px;
  height: 100%;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ChartsDiv = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content:
`;


const PiechartDiv = styled.div`
  width: fit-content;
  height: fit-content;
  align-self: center;
  margin: 32px 0 0 0;
  position: relative;

`;

const BarChartDiv = styled.div`
  width: 700px;
  height: 470px;
  background: #F2FBFF;
  display: flex;
  flex-direction: column;
  padding: 16px 8px 0 16px;
  box-sizing: border-box;
`;

const LegendDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 56px;
  margin: 0 0 8px 0;
`;

const BarLegendDiv = styled.div`
  width: fit-content: 
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Legend = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const LegendBox = styled.div`
  width: 12px;
  height: 12px;
  background: ${({color}) => color};
`;

const LegendText = styled(BodyText)`
  font-size: 14px;
  margin: 0px;
`;

const ButtonLegendDiv = styled(BarLegendDiv)`
  gap: 0px
`;

const LegendButton = css`
  width: fit-content;
  height: fit-content;
  padding: 8px;
  outline: none;
  text-align: center;
  background: white;
  border: 2px solid #2ECADB;
  color: #2ECADB;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s linear;
  border-radius: 4px 0 0 4px;
  border-right: none;
  box-shadow: none;

  &:hover, &.active, &:focus {
    color: white;
    background: #2ECADB;
  }
`;

const LegendButtonMid = css`
  ${LegendButton}
  border-radius: 0px;
`;


const LegendButtonRight = css`
  ${LegendButton}
  border-radius: 0px 4px 4px 0px;
  border-right: 2px solid #2ECADB;;
`;

const TooltipDiv = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 12px 8px;
  background: white;
  
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CurrentText = styled(BodyText)`
  color: #2ECADB;
  margin: 0px;
`;

const LastyearText = styled(BodyText)`
  color: #C6A1F5;
  margin: 0px;
`;


const Reservations = ({reservationPerSource, isLoading, monthYear}) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const {name, fill, value} = !isEmpty(reservationPerSource)  ? reservationPerSource[activeIndex] : { _id: 'Loading', name: 'No Reservation Data Available', fill: 'aF8BC43' }; 
  const [barState, setBarState] = useState('Amount');

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const barStateSwitch = (value) => {
    setBarState(value)
  };

  const nowYear = monthYear?.getFullYear();
  const prevYear = monthYear?.getFullYear() - 1;


  const barData = reservationPerSource?.map((data) => {
    const {fill, ...rest} = data;
    return rest;
  });

  const barDataRevenue = barData?.sort((a,b) => b?.currentYearTotalRevenue - a?.currentYearTotalRevenue).slice(0,5);
  const barDataPercentage = barData?.sort((a,b) => b?.currentYearTotalRevenue - a?.currentYearTotalRevenue).slice(0,5);
  const barDataADR = barData?.sort((a,b) => b?.currentYearADR - a?.currentYearADR).slice(0,5);

  const barObject = {
      'Amount': {
        data: barDataRevenue,
        label: 'Total Revenue',
        current: 'currentYearTotalRevenue',
        last: 'lastYearTotalRevenue',
        // forecast: 'forecastTotalRevenue',
      },
      'Percentage': {
        data: barDataPercentage,
        label: 'Percentage',
        current: 'currentYearPercentage',
        last: 'lastYearPercentage',
        // forecast: 'forecastPercentage',
      },
      'ADR': {
        data: barDataADR,
        label: 'Average Daily Rate',
        current: 'currentYearADR',
        last: 'lastYearADR',
        // forecast: 'forecastADR',
      },
  }

  

  const yTickFormat = useCallback((data) => {
    return barState === 'Percentage' ? `${(data*100).toFixed(0)}%` : currencyFormatter(data);
  }, [barState]);


  const renderTooltip = useCallback((data) => {
    const {active, payload} = data;
    if (!active) return null;

    const currentValue = barState === 'Percentage' ?  `${(payload[1]?.value*100).toFixed(0)}%` :  currencyFormatter(payload[1]?.value);
    const prevValue = barState === 'Percentage' ?  `${(payload[0]?.value*100).toFixed(0)}%` :  currencyFormatter(payload[0]?.value);


    return (
      <TooltipDiv>
        <CurrentText>{nowYear} :  {currentValue}</CurrentText>
        <LastyearText>{prevYear} : {prevValue}</LastyearText>
      </TooltipDiv>
    )
  }, [barState, nowYear, prevYear]);

  return (
    <PriceRevenueGraphDiv>
      <BodyTitle>
          Reservation By Source
      </BodyTitle>
      <ChartsDiv>
        <PiechartDiv>
          <PieChart width={400} height={400}>
            {
              reservationPerSource ? (<Pie
              dataKey="value"
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter}
              data={reservationPerSource}
              innerRadius={110}
              outerRadius={180}
            />) : null
            }
          </PieChart>
          <RenderActiveLogo name={name} value={value }fill={fill}  />
        </PiechartDiv>
        <BarChartDiv>
          <BodyTitle margin="0 0 24px 56px">
            {barObject[barState]?.label}
          </BodyTitle>
          <LegendDiv>
            <BarLegendDiv>
              <Legend>
                <LegendBox color='#C6A1F5'/>
                <LegendText>
                {prevYear}
                </LegendText>
              </Legend>
              <Legend>
                <LegendBox color='#2ECADB'/>
                <LegendText>
                  {nowYear}
                </LegendText>
              </Legend>
              {/* <Legend>
                <LegendBox color='#C2ECF3'/>
                <LegendText>
                  Forecast
                </LegendText>
              </Legend> */}
            </BarLegendDiv>
            <ButtonLegendDiv>
              <ButtonWithFunction  className={`${barState === 'Amount' && 'active'}`}  onClickFunc={barStateSwitch} label='Amount' value='Amount' ButtonStyle={LegendButton}  />
              <ButtonWithFunction className={`${barState === 'Percentage' && 'active'}`}  onClickFunc={barStateSwitch}  label='Percentage' value='Percentage' ButtonStyle={LegendButtonMid}  />
              <ButtonWithFunction className={`${barState === 'ADR' && 'active'}`} onClickFunc={barStateSwitch}  label='ADR' value='ADR' ButtonStyle={LegendButtonRight}  />
            </ButtonLegendDiv>
          </LegendDiv>
          <BarChart
            width={640}
            height={344}
            data={barObject[barState]?.data}
            margin={{left: 16}}
          >
          <XAxis dataKey="name" minTickGap={0} tick={<CustomTick />}/>
          <YAxis tickFormatter={yTickFormat}/>
          <Tooltip content={renderTooltip} />
          <Bar barSize={16} dataKey={barObject[barState]?.last} fill="#C6A1F5" />
          <Bar barSize={16} dataKey={barObject[barState]?.current} stackId="a" fill="#2ECADB" />
          {/* <Bar barSize={16} dataKey={barObject[barState]?.forecast} stackId="a" fill="#C2ECF3" /> */}
        </BarChart>
        </BarChartDiv>
      </ChartsDiv>
      <SkLoaderOver isLoading={isLoading} type='card' />
    </PriceRevenueGraphDiv>
  )
}

export default Reservations;