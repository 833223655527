import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {auth} from "../../services/auth";

import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  RESEND_EMAIL,
  SELECTED_PMS,
  SIGNUP_GOOGLE_USER,

} from "constants/ActionTypes";

import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  showErrorMessages,
  updateUserPMSSuccess,
  userGoogleSignUpSuccess,
  userGoogleSignInSuccess,
  updateUserPMSSuccessApiKey,

} from "../../appRedux/actions/Auth";
import { message } from "antd";


const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth.userSignUp(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signUpUserWithGoogleRequest = async (email, password) =>
    await auth.userSignUp(email, password)
      .then(authUser => authUser)
      .catch(error => error);

const resendEmailRequest = async (string) =>
  await auth.resendEmail(string)
    .then(authUser => authUser)
    .catch(error => error);

const PMSRequest = async (string) =>
    await auth.profileSetup(string)
      .then(authUser => authUser)
      .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.logIn(email, password)
    .then(authUser => authUser)
    .catch(error => error);


const signOutRequest = async () =>
  await  auth.logOut()
    .then(authUser => authUser)
    .catch(error => error);


/** signup user with form  */
function* createUserWithEmailPassword({payload}) {
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);
    if (signUpUser.data.message) {
      yield put(showAuthMessage(signUpUser.data));
    } else if(signUpUser.data.errors && Object.keys(signUpUser.data.errors).length > 0){
      yield put(showErrorMessages(signUpUser.data.errors));
    }else {
      yield put(userSignUpSuccess((signUpUser.data.result) ? signUpUser.data : ''));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/** Signup user with google */
function* signUpUserWithGoogle({payload}) {
  try {
    const signUpUser = yield call(signUpUserWithGoogleRequest, payload);
    if (signUpUser.data.message) {
      yield put(showAuthMessage(signUpUser.data));
    } else if(signUpUser.data.errors && Object.keys(signUpUser.data.errors).length > 0){
      yield put(showAuthMessage(signUpUser.data.errors['errors'][0]));
    }else {
      yield put(userGoogleSignUpSuccess((signUpUser.data.result) ? signUpUser.data.result.validateString : ''));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resendEmailToUser({payload}) {
  try {
    const resendEmailData = yield call(resendEmailRequest, payload);
    if (resendEmailData.data.message) {
      yield put(showAuthMessage(resendEmailData.data));
    } else if(resendEmailData.data.errors && Object.keys(resendEmailData.data.errors).length > 0){
      yield put(showErrorMessages(resendEmailData.data.errors));
    }else {
      yield put(userSignUpSuccess((resendEmailData.data.result) ? resendEmailData.data.result.validateString : ''));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* updatePMSInfo({payload}) {
  try {
    const pmsInfo = yield call(PMSRequest, payload);
    if (pmsInfo.data.message) {
      yield put(showAuthMessage(pmsInfo.data));
      if(payload.api_key){
        yield put(updateUserPMSSuccessApiKey(''));
      }else{
        yield put(updateUserPMSSuccess((pmsInfo.data.result) ? pmsInfo.data.result.string : ''));
      }
    } else if(pmsInfo.data.errors && Object.keys(pmsInfo.data.errors).length > 0){
      yield put(showErrorMessages(pmsInfo.data.errors));
    }else {
      if(payload.api_key){
        yield put(updateUserPMSSuccessApiKey(''));
      }else{
        yield put(updateUserPMSSuccess((pmsInfo.data.result) ? pmsInfo.data.result.string : ''));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}



function* signInUserWithEmailPassword({payload}) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);


    if (!signInUser.data.status) {
      message.error(signInUser.data.message || "Error logging in");
      return;
    };

    // Throw error immediately if credentials are invalid.
    // Do not allow setting of localstorage items.
    if (!signInUser?.data?.result?.user_id
      || signInUser?.data?.message === 'Invalid login credentials')
      throw new Error('Invalid login credentials');

    localStorage.setItem('user_id', (signInUser.data.result) ? signInUser.data.result.user_id : '');
    localStorage.setItem('role', (signInUser.data.result) ? signInUser.data.result.role : '');
    localStorage.setItem('token', (signInUser.data.token) ? signInUser.data.token : '');
    localStorage.setItem('currency', (signInUser.data.result.currency) ? signInUser.data.result.currency : '');
    localStorage.setItem('currencyLabel', (signInUser.data.result.currencyName) ? signInUser.data.result.currencyName : '');
    if(!signInUser.data.loginType || signInUser.data.loginType === 'social'){
      if (signInUser.data.message) {
        yield put(showAuthMessage(signInUser.data));
        yield put(userSignInSuccess(localStorage.getItem('token')));
      } else {
        yield put(userSignInSuccess(localStorage.getItem('token')));
      }
    }else{
      yield put(userGoogleSignInSuccess(signInUser.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
      const signOutUser = yield call(signOutRequest, '');
      localStorage.clear();
      yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}




export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* sendEmail() {
  yield takeEvery(RESEND_EMAIL, resendEmailToUser);
}

export function* updateUserPMS() {
  yield takeEvery(SELECTED_PMS, updatePMSInfo);
}

// export function* signInWithGoogle() {
//   yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
// }

export function* signUpWithGoogle() {
  yield takeEvery(SIGNUP_GOOGLE_USER, signUpUserWithGoogle);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(sendEmail),
    // fork(signInWithGoogle),
    fork(signOutUser),
    fork(updateUserPMS),
    fork(signUpWithGoogle),
  ]);

}
