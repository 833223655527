import {
  USER_RESERVATION_LIST,
  USER_RESERVATION_LIST_SUCCESS,
  HIDE_MESSAGE,
  USER_RATES_LIST,
  USER_RATES_LIST_SUCCESS,
  USER_BOOKING_FEED_SUCCESS,
} from "../../constants/ActionTypes.js";

/*** Set initial state */
const INIT_STATE = {
  loader     : false,
  textMessage     : '',
  reservationData : [],
  rateListData : [],
};


const ReservationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_RESERVATION_LIST: {
      return {
        ...state,
        reservationData: [],
      };
    }

    case USER_RESERVATION_LIST_SUCCESS: {
      return {
        ...state,
        reservationData: action.payload,
      };
    }

    case USER_BOOKING_FEED_SUCCESS: {
      return {
        ...state,
        reservationData: action.payload,
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        textMessage: "",
      };
    }

    case USER_RATES_LIST: {
      return {
        ...state,
        loader       : true,
        rateListData: [],
      };
    }

    case USER_RATES_LIST_SUCCESS: {
      return {
        ...state,
        loader       : false,
        rateListData: action.payload,
      };
    }

    default:
      return state;
  }
}

export default ReservationReducer;
