import React from "react";
import {Route, Switch} from "react-router-dom";
import Widgets from "./Widgets";
import Metrics from "./Metrics";
import Analytics from "./analytics";
import Layouts from "./Layouts";
import asyncComponent from "../../util/asyncComponent";
import errorsPages from "./../errorsPages/index";
const Main = ({match}) => (
  <Switch>
    <Route path={`${match.url}/analytics`} component={Analytics}/>
    <Route path={`${match.url}/widgets`} component={Widgets}/>
    <Route path={`${match.url}/metrics`} component={Metrics}/>
    <Route path={`${match.url}/layouts`} component={Layouts}/>
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))}/>
    <Route exact component={errorsPages}/>
  </Switch>
);

export default Main;
