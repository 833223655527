import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';


const Villas365Div = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Villas365Button = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;


const Villas365 = ({ onSubmitForm }) => {

  const [key, setKey] = useState('');
  const [pass, setPass] = useState('');
  const [owner_token, setOwnerToken] = useState('');

  const onFormChange = (name, { value }) => {
    if (name === "pass") {
      return setPass(value)
    }
    if (name === "owner_token")  {
      return setOwnerToken(value)
    }
      setKey(value);
  };

  const onTokenSubmit = () => {
    const values = {
      key: key,
      pass: pass,
      owner_token
    }
    onSubmitForm(values)
  };


  return (
    <Villas365Div>
      <FormItemInput formKey="key" value={ key } label="API Key" onChangeFunc={ onFormChange }/>
      <FormItemInput formKey="owner_token" value={ owner_token } label="Owner Token" onChangeFunc={ onFormChange }/>
      <FormItemInput type="password" formKey="pass" value={ pass } label="API Password" onChangeFunc={ onFormChange }/>
      <Villas365Button disabled={ key.length === 0 || pass.length === 0 }
                       onClick={ onTokenSubmit }> Connect </Villas365Button>
    </Villas365Div>
  )
}

export default Villas365;
