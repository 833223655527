import React from "react";
import styled from "styled-components";
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import { BodyTitle, BodyText } from 'components/ReusableComponents/Text/Text';
import { lightScrollbar, darkScrollbar } from "components/ReusableComponents/Scrollbar/Scrollbar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { now } from "constants/GlobalConstant";
import { currencyFormatter } from "../../../../../util/helper";
import SkLoaderOver from "../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver";

const LatestBookingsDiv = styled.div`
  ${BodyCSS}
  width: 632px;
  min-width: 632px;
  height: 100%;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TitleDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 24px 0;
`;

const SeeAll = styled.span`
  color: #00A4E6;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
`;

const BookingList = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar };
`;

const BookingTextName = styled(BodyText)`
  margin: 0;
  font-weight: 500;
`;

const Booking = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 16px;

  cursor: pointer;
 
  &:hover {
    ${BookingTextName} {
      color: #1BB3CD;
    }
  }
`;

const BookingImage = styled.img`
  width: 100px;
  height: 80px;
  border-radius: 4px;

  object-fit: fill;
`;

const BookingInfoDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const BookingTextDate = styled(BodyText)`
  margin: 0;
  font-weight: 400;
`;

const BookingTextMetric = styled(BodyText)`
  margin: 0;
  font-weight: 300;
`;



const LatestBookings = ({recentBookingData=[], isLoading}) => {

  const { users } = useSelector(({ users }) => ({users}));
  const {authUserDetails : authData} = users;

  return (
    <LatestBookingsDiv>
      <TitleDiv>
        <BodyTitle>
            Latest Bookings
        </BodyTitle>
        <Link to={"/revenue/bookingFeed"}>
          <SeeAll>
              See All
          </SeeAll>         
        </Link>
      </TitleDiv>
      <BookingList>
        {
          recentBookingData.map((activity, index) => (
            <Booking key={`${activity?.name} - ${activity?.source}`}>
              <BookingImage src={activity.image || authData.brand_logo} alt="booking thumbnail"/>
              <BookingInfoDiv>
                <Link
                    to={{
                      pathname: "/market-analytics/property-details",
                      state: {propertyId: activity.id, date: new Date(now)}
                    }}
                >
                  <BookingTextName>
                    {activity?.name} - {activity?.source}
                  </BookingTextName>
                </Link>
                <BookingTextDate>
                  {activity?.bookedDays} nights on {activity?.checkIn}
                </BookingTextDate>
                <BookingTextMetric>
                  Total Payout: {activity?.totalPayoutAmount ? currencyFormatter(activity?.totalPayoutAmount) : '-'} | Rent: {activity?.totalRent ? currencyFormatter(activity?.totalRent) : '-'} | ADR: {activity?.bookedDays >= 1 && activity?.totalRent > 0 ? currencyFormatter(Number(activity?.totalRent / activity?.bookedDays)) : "-"}
                </BookingTextMetric>
              </BookingInfoDiv>
          </Booking>
          ))
        }
      </BookingList>
      <SkLoaderOver isLoading={isLoading} type='card' />
    </LatestBookingsDiv>
  )
} 

export default LatestBookings;