import React from 'react';
import { Button, Result } from 'antd';
import {Link} from "react-router-dom";
import { auth } from "../../services/auth"

const userId = localStorage.getItem("user_id");
const token = localStorage.getItem("token");
class ProtectedRoutesErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    async componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      //   logErrorToMyService(error, errorInfo);
      let errorData = {
        userId,
        viewType: this.props.viewType,
        token,
        error,
        errorInfo,
      };
      // eslint-disable-next-line
      let response = await auth.logError(errorData);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <Result
            status="500"
            title="500"
            subTitle="Oops, something went wrong, we have notified the Development Team. Please contact hello@quibblerm.com for support!"
            extra={
                <Button type="primary">
                  <Link to="/home">Back Home</Link>
                </Button>            
            }
          />
        );
      }
  
      return this.props.children; 
    }
  }


export default ProtectedRoutesErrorBoundary;