import React, {memo} from 'react';
import styled from 'styled-components';
import { CalendarDiv } from '../../../CommonCSS';
import { BodyText } from '../../../../../../../components/ReusableComponents/Text/Text';
import { forwardRef } from 'react';

const PropertyNameSpan = styled(BodyText)`
  font-weight: 400;
  margin: 0px 0 2px 0;
  text-transform: capitalize;
  
`;

const MarketNameSpan = styled.span`
  font-size: ${({theme}) => theme.s2};
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  transition: all 0.1s linear;

`;

const PropertyButton = styled.button`
  ${CalendarDiv}
  min-width: 224px;
  width: 224px;
  font-weight: 400;
  align-items: flex-start;
  padding: 8px 16px;
  outline: none;
  border: none;
  background: ${({theme}) => theme.bodyBg};
  border-top: 1px solid ${({theme}) => theme.dirtyGrey};
  color: ${({theme}) => theme.bodyFg};
  cursor: pointer;
  transition: all 0.1s linear;


  &:hover, &.active {
    background: ${({theme}) => theme.quibbleBlue};
    color: ${({theme}) => theme.white};
    ${MarketNameSpan} {
      color: ${({theme}) => theme.white};
    };
  }

  &:disabled {
    cursor: default;
  }
`;

const CalendarProperty = forwardRef(({selectedProperty, onSetSelectedProperty, isFetchingCalendarListing, bedrooms=0, market="", propertyId, propertyName=""}, ref) => {

  const isPropertySelected = selectedProperty === propertyId;

  const onPropertyClick = () => {
    if(isPropertySelected) {
      onSetSelectedProperty('');    
      return;
    }
    onSetSelectedProperty(propertyId);    
  };

  return (
    <PropertyButton ref={isPropertySelected ? ref : null} onClick={onPropertyClick} disabled={isFetchingCalendarListing} className={isPropertySelected && 'active'}>
      <PropertyNameSpan>
        {propertyName}
      </PropertyNameSpan>
      <MarketNameSpan>
        {`${market} | Bedrooms: ${bedrooms} ` }
      </MarketNameSpan>
    </PropertyButton>
  )
});

export default memo(CalendarProperty);