import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import GoogleMapReact from 'google-map-react';


import SkLoaderOver from "../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver";
import quibbleRm from 'assets/images/quibblerm.png';
import { DetailsDiv, EmptyBodyText } from "../../../../sharedComponents/CommonCSS";
import { BodyText, BodyTitle } from "../../../../../../components/ReusableComponents/Text/Text";
import RenderBar from "../../../../sharedComponents/MarketPropertyTable/RenderBar";
import StarRating from "../../../../../../components/ReusableComponents/Rating/StarRatings";

const MarketDetailsDiv = styled(DetailsDiv)`
  height: 200px;
`;

const ImageDiv = styled.div`
  min-width: 230px;
  min-height: 155px;
  border-radius: 6px;
  object-fit: fit;
  background-image: url(${quibbleRm});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
  border: none;
`;

const MarketInfoDiv = styled.div`
  width: fit-content;
  height: auto:
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
`;

const MarketInfoRow = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 0 0;
  gap: 16px;
`;

const MarketInfoItem = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
`;

const MarketPropertyLabel = styled(BodyText)`
  font-size: ${({theme}) => theme.s3};
  margin: 0 0 8px 0;
`;

const ValueSpan = styled(BodyText)`
  color: ${({theme}) => theme.quibbleFontGrey};
  margin: 0px;
`;


// Return map bounds based on list of places
const getMapBounds = (_, gmaps, coordinates) => {
  const bounds = new gmaps.LatLngBounds();

  coordinates.forEach((coordinate) => {
    bounds.extend(new gmaps.LatLng(
      coordinate?.lat,
      coordinate?.lon,
    ));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, _, bounds) => {
  map.addListener('idle', () => {
    window.addEventListener('resize', () => {
      map.fitBounds(bounds);
    });
  });
};

const defaultProps = {
  center: {
    lat: 53.4112,
    lng: -2.9676
  },
  zoom: 10,
};


const MarketInfo = ({marketDetails, isFetchingMarketDetails }) => {

  const [gmaps, setgmaps] = useState(null);

  const {key, currentYearAdr, forecastAdr, currentYearOccupancy,
     forecastOccupancy, marketOccupancy, averageMarketRating,
     currentYearOccupancyGap, currentYearBlockedDaysShare,
     averageRating, currentYearRevpar, forecastRevpar, coordinates}= marketDetails;

  // Fit map to its bounds after the api is loaded
const adjustMap = (map, gmaps, coordinates) => {
  // Get bounds by our listings
  const bounds = getMapBounds(map, gmaps, coordinates);
  // Fit map to bounds
  map.fitBounds(bounds);
  map.setZoom(10);
  // Bind the resize listener
  bindResizeListener(map, gmaps, bounds);

};


  const loadGoogleMap = useCallback(() => {

    const initiaLizeMap = (map, gmaps, coordinates) => {
      adjustMap(map, gmaps, coordinates);
      setgmaps(gmaps);
    };

    if (coordinates.length && !isFetchingMarketDetails) {
      return (
        <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyBfvIGHRXKZpovQBnn8tmKuIEJzOjZFjKM" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => initiaLizeMap(map, maps, coordinates)}
              draggable={false}
              options={
                {
                  mapTypeId:"terrain",
                  clickableIcons: false,
                  fullscreenControl: false,
                  zoomControlOptions: {
                    style: gmaps?.ZoomControlStyle?.SMALL,
                    position: gmaps?.ControlPosition?.RIGHT_CENTER
                },
                  styles:[
                    {
                      "featureType": "road",
                      "elementType": "labels",
                      "stylers": [
                        { "visibility": "off" }
                      ]
                    },
                  ]
                }
              }
            />
      )
    }
    return null;
  }, [coordinates, gmaps?.ControlPosition?.RIGHT_CENTER, gmaps?.ZoomControlStyle?.SMALL, isFetchingMarketDetails])


  return (
    <MarketDetailsDiv>
      {isEmpty(marketDetails) ? <EmptyBodyText>No Data Found</EmptyBodyText> :
        <Fragment>
          <ImageDiv>
            {loadGoogleMap()}
          </ImageDiv>
          <MarketInfoDiv >
            <BodyTitle>{key}</BodyTitle>
            <MarketInfoRow>
              <MarketInfoItem >
                <MarketPropertyLabel>
                  ADR
                </MarketPropertyLabel>
                <RenderBar current={currentYearAdr} forecast={forecastAdr}/>
              </MarketInfoItem>
              <MarketInfoItem >
                <MarketPropertyLabel>
                  Occupancy
                </MarketPropertyLabel>
                <RenderBar current={currentYearOccupancy}  forecast={forecastOccupancy} market={marketOccupancy} type="occupancy"/>
              </MarketInfoItem>
              <MarketInfoItem >
                <MarketPropertyLabel>
                  Rev PAR
                </MarketPropertyLabel>
                <RenderBar current={currentYearRevpar}  forecast={forecastRevpar} />
              </MarketInfoItem>
            </MarketInfoRow>
            <MarketInfoRow>
              <MarketInfoItem >
                <MarketPropertyLabel>
                  Reviews
                </MarketPropertyLabel>
                <StarRating yellowLabel={averageMarketRating} purpleLabel={averageRating}/>
              </MarketInfoItem>
              <MarketInfoItem >
                <MarketPropertyLabel>
                  Occupancy Gap
                </MarketPropertyLabel>
                <ValueSpan>{(currentYearOccupancyGap * 100).toFixed(0)}%</ValueSpan>
              </MarketInfoItem>
              <MarketInfoItem >
                <MarketPropertyLabel>
                  Blocked Days
                </MarketPropertyLabel>
                <ValueSpan>{(currentYearBlockedDaysShare * 100).toFixed(0)}%</ValueSpan>
              </MarketInfoItem>
            </MarketInfoRow>
          </MarketInfoDiv>
        </Fragment>}
      <SkLoaderOver isLoading={isFetchingMarketDetails || isEmpty(marketDetails)} type="description" />
    </MarketDetailsDiv>
  )
}

export default MarketInfo;
