import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { ALPHANUMERIC, EMAIL_VERIFY } from 'constants/GlobalConstant';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';

import { AuthItemDiv, AuthTitle, FootText, FormDiv, Link } from '../../sharedComponents/CommonCss';
import { message } from 'antd';
import { auth } from '../../../../services/auth';


const CreateDiv = styled.div`
  ${AuthItemDiv};
  margin: 60px 0 0 0;
`;



const SubmitButton = styled(PrimaryButton)`
  width: 460px;
  margin: 32px 0 32px 0;
`;


const CreateAccount = ({setAuthMenuFunc, setCreateToken}) => {

  const [profileForm, setProfileForm] = useState({
    email: {value: '', error: false},
    password: {value: '', error: false},
  });

  const [passEmpty, setPassEmpty] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);

  const onFormChange = useCallback((formKey, {value, error}) => {
    if (formKey === 'password') {
      setPassEmpty(() => !value.length);
    };

    if (formKey === 'email') {
      setEmailEmpty(() => !value.length);
    };

    setProfileForm((prev) => ({...prev, [formKey]: {value, error}}));
  } ,[]);

  const onSignup = async() => {
    try {
      const {email, password} = profileForm;

      if (!email.value.length){
        setEmailEmpty(true);
      };

      if ( !password.value.length) {
        setPassEmpty(true);
      };

      if (!password.value.length || !email.value.length) {
        return;
      }

      const signupResult = await auth.userSignUp({email: email.value, password: password.value, confirm_password:  password.value})

      if (signupResult.data.status){
        setAuthMenuFunc('verify');
        setCreateToken(signupResult.data.token)
        return;
      };

      message.error(signupResult.data.errors.errors[0] || 'Error on Signup attempt')

    } catch (e) {
      message.error('Signup attempt error. Please try again')
    }

  };

  const handleOnKeyPress = (e) => {
    const keyCode = e.which || e.charCode;
    if (keyCode === 13) {
      onSignup();
    }
  };



  return (
    <CreateDiv>
      <AuthTitle>
        Create Your Account
      </AuthTitle>
      <BodyText>
        No credit card required
      </BodyText>
      <FormDiv>
        <FormItemInput formKey="email" value={profileForm.email.value} label="Email" onChangeFunc={onFormChange} formItemRules={{isRequiredAndEmpty: emailEmpty, patternCheck: {pattern: EMAIL_VERIFY, errorMessage:'Please enter valid email address.'}}} />
        <FormItemInput formKey="password" label="Password" value={profileForm.password.value} isPassword onChangeFunc={onFormChange} formItemRules={{isRequiredAndEmpty: passEmpty, min: 4, max: 10, patternCheck: {pattern: ALPHANUMERIC, errorMessage:'Password should have at least 1 capital letter, a number and a symbol'}}}  onKeyPress={handleOnKeyPress}/>
      </FormDiv>
      <SubmitButton disabled={profileForm.email.error || profileForm.password.error} onClick={onSignup}>
        Sign Up
      </SubmitButton>
      <FootText>
        Already have an account? <Link href='/signin'> Sign In </Link>
      </FootText>
      <FootText>
        By signing up you agree to Quibble’s Terms & Conditions
      </FootText>
    </CreateDiv>
  )
}

export default CreateAccount;
