import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { FootText, Link } from '../../../sharedComponents/CommonCss';
// import { BodyText } from '../../../../../components/ReusableComponents/Text/Text';


const GuestyDiv = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GuestyButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;

// const ConnectButton = styled(PrimaryButton)`
//   width: 460px;
//   margin: 32px 0px;
//   background: none;
//   border: 1px solid ${({theme}) => theme.quibbleBlue};
//   color: ${({theme}) => theme.quibbleBlue};
// `;


const GuestyFootText = styled(FootText)`
  margin: 0 0 0 0;
`;

const SecondFootText = styled(FootText)`
  margin: 8px 0 0 0;
`;

// const LineDiv = styled.div`
//   width: 100%;
//   height: 1px;
//   border-top: 1px solid #E8E8E8;

//   margin: 32px 0 0 0;
//   position: relative;
//   text-align: center;
// `;

// const OrText = styled(BodyText)`
//   position: absolute;
//   top: -4px;
//   bottom: 0px;
//   left: 0px;
//   right: 0px;
//   margin: 0px;
//   margin: auto;
//   width: fit-content;
//   height: fit-content;
//   background: white;
// `;


const Guesty = ({onSubmitForm, onChangePMS}) => {

  const [guestyToken, setGuestyToken] = useState('');
  
  const onFormChange = (_, {value}) => {
    setGuestyToken(value);
  };

  const onTokenSubmit = () => {
    const values = {
      token: guestyToken,
    }
    onSubmitForm(values)
  };

  // const onChangeClient = () => {
  //   onChangePMS('guestyWithID');
  // };

  return (
    <GuestyDiv>
      <FormItemInput formKey="guestyToken" value={guestyToken}  label="Token" onChangeFunc={onFormChange} />
      <GuestyButton disabled={!guestyToken.length} onClick={onTokenSubmit}> Submit </GuestyButton>
      <GuestyFootText>Don’t have your Token? <Link href="https://help.quibblerm.com/how-to-connect-quibble-to-guesty" target={"_blank"}> Get my Guesty Token</Link> </GuestyFootText>
      <SecondFootText>How to <Link href="https://app.guesty.com/integrations/partners/quibble" target={"_blank"}> get my Guesty Token</Link> </SecondFootText>
      {/* <LineDiv>
        <OrText> or </OrText>
      </LineDiv>
      <ConnectButton onClick={onChangeClient}> Connect Using Client ID and Secret</ConnectButton> */}
    </GuestyDiv>
  )
}

export default Guesty;