import styled from 'styled-components';
// import { TooltipCSS } from '../Tooltip/TooltipCSS';
// import { TooltipCSS } from '../../ReusableComponents/';
import { Button as AntButton, Radio } from 'antd';
import { media } from '../../../styles/stylesheet';

export const Button = styled.button`
  border: none;
  outline: none;
  padding: 8px;
  width: fit-content;
  height: 34px;
  font-family: Commissioner;
  font-weight: 500;
  transition: all 0.2s linear;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

  ${({theme}) => `
    font-size: ${theme.s3};
    background: ${theme.buttonBg};
    color: ${theme.buttonFg};
    &:hover, &.active {
      background: ${theme.hoverBlue};
      color: ${theme.white};

    }
    &:disabled {
      background: ${theme.disabledGrey};
      color: ${theme.white};
      border: none;
    }
  `}
`;

export const LiteButton = styled(Button)`
    width: fit-content;
    font-weight: 600;
    border-radius: 4px;

    ${({theme}) => `
      background: ${theme.bodyBg};
      color: ${theme.isDefault ? theme.black: theme.white};
      border: 1px solid ${theme.hoverBlue};

      &:hover, &:focus, &.active {
        color: ${theme.white};
      }

      &:disabled {
        border: none;
        background: ${theme.disabledGrey};
        color: ${theme.white};
      }

    `}
`;

export const ChangeViewButton = styled(AntButton)`
  &:hover, &.active {
    background: ${({theme}) => theme.quibbleHoverGrey};
    color: black;
    border:none; 
    border-radius: 4px;
  }
`;

export const RadioSwitchButton = styled(Radio.Button)`
    margin: 0px 0px 0 0px;
    border: none;
    outline: none;
    padding: 8px;
    height: 40px;
    font-family: Commissioner;
    font-weight: 400;
    background-color: #000000;
    color: white;
    transition: all 0.2s linear;
    // border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 90px;
    ${media.bpm} {
      max-width: 40px;
      height: 30px;
      font-size: 12px;
    }
    ${({theme}) => `
      font-size: ${theme.s3};
      &:hover, &:focus, &:active, &.active {
        background-color: ${theme.quibbleBlue};
        color: black;
      }
      &:disabled {
        background: ${theme.disabledGrey};
        color: ${theme.white};
      }
    `}
`
export const CloseButton = styled(Button)`
  width: 28px;
  height: 28px;
  border-radius: 100%;
`;

//New Button Designs by Jott

export const PrimaryButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  padding: 12px 16px;
  border-radius: 4px;
  font-weight: 400;
  font-family: Commissioner;
  cursor: pointer;
  outline: none;
  transition: all 0.2s linear;
  box-shadow: none;
  font-size: ${({theme}) => theme.s4};
`;

export const SecondaryButton = styled(PrimaryButton)`
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  background: ${({theme}) => theme.bodyBg};
  color:  ${({theme}) => theme.quibbleBlue};

  &:hover, &.active {
    background: ${({theme}) => theme.hoverBlue};
    color: ${({theme}) => theme.white};
  };

  &:disabled {
    background: ${({theme}) => theme.disabledGrey};
    color: ${({theme}) => theme.white};
    border: none;
  };
`;
