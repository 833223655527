import React from 'react'
import styled from 'styled-components'


const HelpListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 80vh;
  max-height: 120vh;
  margin-right: -20px;
  }
`;

const HelpContainer = styled.div`
  width: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 0;  
`;

const HelpTitle = styled.p`
  font-size: 18px;
  font-family: 'Commissioner', sans-serif;
  font-weight: 100;
  padding-top: 10px;
  cursor: pointer;

  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
    
  }
`;

const HelpGuides = ({guides}) => {
  return (
    <HelpListContainer>
      {guides.map((guide, index) => (
        <HelpContainer key={index}>
          <a href={guide.url}>
            <img src={guide.image} alt={guide.title} width="560" height="315"/>
          </a>
          <HelpTitle>{guide.title}</HelpTitle>
        </HelpContainer>
      ))}
    </HelpListContainer>
  )
}

export default HelpGuides