import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { Select, Switch } from 'antd';

import searchIcon from 'assets/images/generic/search.svg';
import { getListings } from '../../../appRedux/actions';

import { BodyText } from "components/ReusableComponents/Text/Text";

const {Option} = Select;



const ListingFilterDiv = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 48px;
`;

const ListingFilterFlex = styled.div`
  min-width: 1600px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;


const MarketSelect = styled(Select)`
  width: 400px;
  height: 48px;
  font-size: ${({theme}) => theme.s4};
`;

const SearchImage = styled.img`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 16px;
  margin: auto;
`;

const SearchInput = styled.input`
  width: 300px;
  height: 100%;
  padding: 0 0 0 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-weight: 300;
  font-size: ${({theme}) => theme.s4};

  ::placeholder {
    color: #B0B0B0;
    opacity: 1; /* Firefox */
  }
`;

const SwitchDiv = styled.div`
  flex-grow: 1;
  align-items: center;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
`;

const ListingFilter = ({selectedMarket, setSelectedMarket, setSearchString, isDynamicPricingEnabled, setIsDynamicPricingEnabled}) => {

  const dispatch = useDispatch();
  const { listings } = useSelector(({ markets }) => markets);
  const [selectMarket, setSelectMarket] = useState([]);

  useEffect(() => {
    if (listings.length) {
      const markets = listings.reduce((outputData, currentData) => {
        if (outputData.includes(currentData.market) || !currentData.market) return outputData;
        return [...outputData, currentData.market]
      }, []);
      setSelectMarket(markets);
    }
    
  }, [listings]);

  useEffect(() => {
    dispatch(getListings())
  }, [dispatch]);

  const onMarketChange = (value) => {
    setSelectedMarket(value);
  };

  const onInputChange = (e) => {
    e.preventDefault();
    const searchString = e.target.value;
    setSearchString(searchString);
  };

  const onDynamicPricingChange = (e) => {
    setIsDynamicPricingEnabled(e);
  };

  return (
    <ListingFilterDiv>
      <ListingFilterFlex>
        <MarketSelect value={selectedMarket} placeholder="Select Market"  onChange={onMarketChange}>
          <Option key={'All Markets'} value={''}>All Markets</Option>
          {selectMarket.map((data) => (<Option key={data} value={data}>{data}</Option>))}
        </MarketSelect>
        <SwitchDiv>
          <BodyText margin=" 0 8px 0 0">
            Dynamic Pricing
          </BodyText>
          <Switch checked={isDynamicPricingEnabled} onChange={onDynamicPricingChange} />
        </SwitchDiv>
        <SearchInput type="search" onChange={onInputChange} placeholder='Search' />
        <SearchImage src={searchIcon}  alt="search icon"/>
      </ListingFilterFlex>
    </ListingFilterDiv>
  )
}

export default ListingFilter;