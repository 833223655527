import React, { useEffect } from "react";
import styled from "styled-components";
import { CardCSS } from "../../../components/CommonCSS";
import { BodyTitle } from "components/ReusableComponents/Text/Text";
import { Skeleton } from 'antd';
import {
  EditOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { manage, getSubscriptionCards } from "appRedux/actions";

const BillingPlanBody = styled.div`
  ${CardCSS}
  height: 211px;
  margin: 24px 0 0 0;
`;

const CardContainer = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  padding-top: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space;
  align-items: center;
`;

const Tag = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  border-radius: 50px;
  background: #F5F5F5;

  padding: 10px;
  margin-left: 80px;
`;

const NavLink = styled.a`
  display: flex;
  width: 203px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-decoration: underline;
  margin-top: 50px;
`;

const cardStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const renderCard = (cards) => {
  const card = cards.filter(card => card.default === true);

  if (card?.length > 0) {
    return <>{card[0].brand.toUpperCase()} ending in {card[0].last4} <Tag>Default</Tag></>
  } else {
    return <i>No default payment method</i>
  }
}

const BillingPlan = () => {
  const dispatch = useDispatch()

  const {
    isFetchingSubscriptionCards,
    subscriptionCards,
  } = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(getSubscriptionCards());
  }, [dispatch])

  const manageSubscription = () => {
    dispatch(manage())
  }

  return (
    <BillingPlanBody >
      <BodyTitle style={cardStyle}>
        Billing
        <EditOutlined onClick={manageSubscription} />
      </BodyTitle>
      <CardContainer>
        {isFetchingSubscriptionCards && (<Skeleton.Input active={true} />)}
        {!isFetchingSubscriptionCards && renderCard(subscriptionCards)}
      </CardContainer>

      <NavLink onClick={() => manageSubscription()}>Add Payment Information</NavLink>
    </BillingPlanBody>
  )
}

export default BillingPlan;