import React from 'react';
import styled, {css} from 'styled-components';

import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';

const DrawerBody = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  position: sticky;
  background: ${({theme}) => theme.bodyBg};
  top: 0;
  z-index: 1;
  flex-direction: row;
  border-bottom: 1px solid ${({theme}) => theme.dirtyGrey};
`;

const DrawerButton = css`
  width: 124px;
  height: 100%;
  border-radius: 0px;
  background: none;
  box-shadow: none;
  font-family: Commissioner;
  font-weight: 400;
  font-size: ${({theme}) => theme.s3};
  color: ${({theme}) => theme.bodyFg};

  &:hover, &.active {
    color: ${({theme}) => theme.quibbleBlue};
    background: none;
    border-bottom: 2px solid ${({theme}) => theme.quibbleBlue};
  }

  &:disabled {
    background: none;
    border: none;
    color: ${({theme}) => theme.disabledGrey};
  }
`;



const DrawerMenu = ({onSetActiveMenu, activeMenu}) => {

  const onMenuClick = (value) => {
    onSetActiveMenu(value);
  }

  return (
    <DrawerBody>
      <ButtonWithFunction ButtonStyle={DrawerButton} label="PRICE" value="PRICE" onClickFunc={onMenuClick} className={activeMenu==='PRICE' && 'active'}/>
      <ButtonWithFunction ButtonStyle={DrawerButton} label="STAY" value="STAY" onClickFunc={onMenuClick} className={activeMenu==='STAY' && 'active'}/>
      <ButtonWithFunction ButtonStyle={DrawerButton} label="OVERRIDES" value="OVERRIDES" onClickFunc={onMenuClick} className={activeMenu==='OVERRIDES' && 'active'}/>
    </DrawerBody>
  )
}

export default DrawerMenu;