import React,{useRef, useState, useEffect} from "react";
import {message} from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'


const ContainerDivvv = styled.div`
  display : flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
  `;
const Divv = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
`;
const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SecondDiv = styled.div`  
  width: 50%;
  position: relative;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const SkyScraperImg = styled.img`
  height: 100vh;
  width: 100%;
`;
const LeftParagraphText = styled.p`
  font-size: 2.25em;
  font-weight: 300;
  text-align: center;
  line-height:9px;

`;
const LogoImage = styled.p`
  margin: 120px 0px;
  display: flex;
  justify-content: center;
`;
const Texts = styled.p`
  position: absolute;
  width: 100%;
  color: white;
  line-height: 30px;
  padding-top: 70px;
`;

const Text1 = styled.p`
  font-size: 36px;
  font-weight: 300;
  width: 100%;
  text-align: center;

`;
const Text2 = styled.p`
  font-size: 54px;
  font-weight: 600;
  width: 100%;
  text-align:center;
  line-height: 10px;
`;
const PasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 54px;
`;
const VerificationPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 200px;
  margin: auto;
`;
const VerificationInput = styled.input`
  outline:none;
  width: 40px;
  height: 50px;
  display: flex;
  margin: 0px 5px;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  font-size: 30px;
  text-align: center;
`;

const Label = styled.label`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-weight: 300;
  width: 500px;
`;

const MyButton = styled.button`
  padding: 16px 16px;
  margin-top: 16px;
  color: white;
  border:none;
  border-radius: 4px;
  background: #1BB3CD;
  font-size: 16px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity:  ${({ isDisabled }) => (isDisabled ? '30%' : '100%')};
`;
const Resend = styled.button`
  border: none;
  background: transparent;
  cursor: ${({ isResendDisabled }) => (isResendDisabled ? 'not-allowed' : 'pointer')};
  color: ${({ isResendDisabled }) => (isResendDisabled ? '#d3d3d3' : '#D69927')};
  display: flex;

`;
const NoteLabel = styled.p`
  display: flex;
  padding-top: 25px;
  justify-content: center;
  align-items: center;
`;


const VerifyOTP = ({ setStep, email }) => {
  const otpBoxReference = useRef([]);
  const [countdown, setCountdown] = useState(120);
  const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
  const [disabled, setDisabled] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(true);
  const Code = verificationCode.join('');

  let baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (countdown === 0) {
      return () => {}; 
    } else {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
        setResendDisabled(false);
      };
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0) {
      setResendDisabled(false);
    } else {
      setResendDisabled(true);
    }
  }, [countdown]);

  const verifyOTP = async (otp) => {
    try {
      const response = await fetch(`${baseURL}/api/reset-password/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (data.message === "Otp verified successfully.") {
        message.success(data.message);
        setTimeout(() => {
          setStep('resetPassword', email, otp);
        }, 3000);
      }
      else if (data.message === "Invalid otp") {
        message.error(data.message);
      }
      else {
        message.error(data.message);
      }
    } catch (error) {
      message.error("An error occurred");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyOTP(Code);
  };

  const handleInputChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);

    if (newVerificationCode.length === verificationCode.length) {
      setDisabled(false);
    }

    if (value && index < verificationCode.length - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }
    function handleBackspaceAndEnter(e, index) {
      if (e.key === "Backspace" && e.target.value === "") {

        const prev = otpBoxReference.current[index - 1];
        if (prev) {
          prev.focus();
        }
      }

      if (e.key === "Enter" && e.target.value && index < verificationCode.length) {
        const next = otpBoxReference.current[index + 1];
        if (next) {
          next.focus();
        }
      }
    }

    const handleOtpBoxRef = (input, index) => {
      otpBoxReference.current[index] = input;
    };

    const revertStep = async (e) => {
      e.preventDefault();
      try {
            const response = await fetch(`${baseURL}/api/reset-password/otp`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email }),
            });
            if (response.status === 200) {
              message.success(`A password reset email has been sent to ${email}, please proceed.`); 
            } else {
                message.error(`Otp already sent. Kindly check ${email} `);
            }
      } catch (error) {
          message.error('An error has occured!');
      };
    }

    return (
      <ContainerDivvv>
        <Divv>
          <FirstDiv>
            <div>
              <LogoImage>
                <img src="/assets/images/quibble-logo.svg" alt="" width="200px" height="90px" />
              </LogoImage>
              <div>
                <LeftParagraphText>Verification</LeftParagraphText>
                <Label>Your one-time passcode has been sent to your registered email</Label>

                <PasswordForm onSubmit={handleSubmit}>
                  <VerificationPageContainer>
                    {verificationCode.map((value, index) => (
                      <VerificationInput
                        key={String(index)}
                        type="text"
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        onKeyDown={(e) => handleBackspaceAndEnter(e, index)}
                        ref={(input) => handleOtpBoxRef(input, index)}
                      />
                    ))}
                  </VerificationPageContainer>
                  <MyButton type="submit" isDisabled={disabled} >
                    Verify
                  </MyButton>

                  <NoteLabel>Didn't receive the email?
                    <Resend onClick={revertStep} isResendDisabled={resendDisabled}>Resend in </Resend>
                     {countdown}s</NoteLabel>
                </PasswordForm>
              </div>
            </div>

          </FirstDiv>
        </Divv>

        <SecondDiv>
          <Texts>
            <Text1>Take the guesswork out of pricing decisions </Text1>
            <Text2>Increase your revenue by 30% </Text2>
          </Texts>

          <SkyScraperImg src="/assets/images/skyscraper.png" alt="" />
        </SecondDiv>


      </ContainerDivvv>
    )
  }


export default VerifyOTP

VerifyOTP.propTypes = {
  email: PropTypes.string.isRequired,
};
