import React from 'react';
import { Table } from 'antd';
import { formatAmount } from '../../../util/formatAmount';

const ExpandedMarketRowRender = (record, datePickerVal) => {
  const currentYear = datePickerVal.year();
  const previousYear = currentYear - 1;

  const currentYearData = {
    performance: 'Current',
    key: `${currentYear}`,
    occupancy: `${record.currentYearOccupancy ? (record.currentYearOccupancy * 100).toFixed(0) : 0}%`,
    adr: `$${record.currentYearAdr ? formatAmount(record.currentYearAdr) : 0}`,
    rent: `$${record.currentYearTotalRent ? formatAmount(record.currentYearTotalRent) : 0}`,
    revenue: `$${record.currentYearTotalRevenue ? formatAmount(record.currentYearTotalRevenue) : 0}`,
    revpar: `$${record.currentYearRevpar ? formatAmount(record.currentYearRevpar) : 0}`,
    inventory: record.inventory ? record.inventory : 0,
  };

  const lastYearData = {
    performance: 'Last',
    key: `${previousYear}`,
    occupancy: `${record.lastYearOccupancy ? (record.lastYearOccupancy * 100).toFixed(0) : 0}%`,
    adr: `$${record.lastYearAdr ? formatAmount(record.lastYearAdr) : 0}`,
    rent: `$${record.lastYearTotalRent ? formatAmount(record.lastYearTotalRent) : 0}`,
    revenue: `$${record.lastYearTotalRevenue ? formatAmount(record.lastYearTotalRevenue) : 0}`,
    revpar: `$${record.lastYearRevpar ? formatAmount(record.lastYearRevpar) : 0}`,
    inventory: record.inventory ? record.inventory : 0,
  };

  const columns = [
    {
      title: 'PERFORMANCE',
      dataIndex: 'performance',
      key: 'performance',
      width: '200px',
    },
    {
      title: 'YEAR',
      dataIndex: 'key',
      key: 'key',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'OCCUPANCY',
      dataIndex: 'occupancy',
      key: 'occupancy',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'ADR',
      dataIndex: 'adr',
      key: 'adr',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'RENT',
      dataIndex: 'rent',
      key: 'rent',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'REVENUE',
      dataIndex: 'revenue',
      key: 'revenue',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'REVPAR',
      dataIndex: 'revpar',
      key: 'revpar',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'INVENTORY',
      dataIndex: 'inventory',
      key: 'inventory',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
  ];

  const dataSource = [currentYearData, lastYearData];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      showHeader={true}
      rowKey="key"
      components={
        {
          header: {
            cell: ({ children, ...props }) => (
              <th {...props} style={{ backgroundColor: '#E8E8E8' }}>{children}</th>
            ),
          },
        }
      }
    />
  );
};

export default ExpandedMarketRowRender;
