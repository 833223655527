import React, { Fragment, memo, useState } from 'react';
import styled from 'styled-components';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import priceAdjustment from 'assets/images/generic/price-adjustment-dollar.svg';
import priceAdjustmentQueue from 'assets/images/generic/price-adjustment-queue.svg';
import deleteQueue from 'assets/images/generic/trash.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMarketDetails, getMarketPricing, getPropertyDetails, getPropertyOverviewMetrics, getPropertyPricing } from 'appRedux/actions';
import { useData } from 'util/DataStore/DataContext';
import { property } from '../../../services/property';
import { getPropertyQueue } from '../../../appRedux/actions';
import { message } from 'antd';

const AdjustQueueMenuDiv = styled.div`
  width: 100%;
  transition: all 0.1s linear;
  height: ${({display}) => display};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin: 0 0 6px 0;
  padding: 0 4px;
`;

const AdjustQueueButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  gap: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;

  &:disabled {
    cursor: unset;
  };

  &:hover {
    border-bottom: 1px solid ${({theme}) => theme.bodyFg};
  }
`;


const AdjustQueueMenu = ({adjustQueueData=[], type="property", monthYear, otherFunctions, checkedRows}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [queueLoad, setQueueLoad] = useState(false);

  const {state} = useData();

  const adjustLength = adjustQueueData.length;


  const onAdjustClick = () => {
    if (type === "market") {
      history.push(
        {
          pathname: "/price-adjustment",
          state: {marketKey: adjustQueueData[0]?.key || '', date: monthYear},
        },);
  
        const month = monthYear.getMonth() + 1;
        const year =  monthYear.getFullYear();
  
        dispatch(getMarketPricing({month, year, market:adjustQueueData[0]?.key}));
        dispatch(getMarketDetails({params: { month, year, market: adjustQueueData[0]?.key}}));
        return;
    } else if (type === "property") {
      history.push(
        {
          pathname: "/price-adjustment",
          state: {propertyId: adjustQueueData[0]?.id || '', date: monthYear},
        },);
  
        const month = monthYear.getMonth() + 1;
        const year =  monthYear.getFullYear();
    
        dispatch(getPropertyPricing({month, year, propertyId: adjustQueueData[0]?.id}));
        dispatch(getPropertyDetails({propertyId: adjustQueueData[0]?.id, params: {month, year}}));
        dispatch(getPropertyOverviewMetrics({propertyId: adjustQueueData[0]?.id, params: {month, year, viewType: state.viewType}}));   
    }
  };

  const retrievePropertyQueue = () => {
    setTimeout(function() {
      dispatch(getPropertyQueue());
      //delay to make sure data already in the backend
    }, 2000);
  };

  const onPropertyQueueClick = async () => {
    if (type === "market") {
      try {
        setQueueLoad(true);
        await property.addPropertyQueue({markets: adjustQueueData.map((data) => data?.key)});
        message.success(`Successfully added ${adjustLength} ${adjustLength > 1 ? "markets":"market"} into the queue`, 3);
        //refresh queue
        retrievePropertyQueue();
        setQueueLoad(false);
      } catch (e) {
        setQueueLoad(false);
        message.error(`Error adding ${adjustLength > 1 ? "markets":"market"} into the queue`, 3)
      }
      return;
    } else if (type === "property") {
      try {
        setQueueLoad(true);
        await property.addPropertyQueue({properties: adjustQueueData.map((data) => data?.id)});
        message.success(`Successfully added ${adjustLength} ${adjustLength > 1 ? "properties":"property"} into the queue`, 3);
        
        //refresh queue
        retrievePropertyQueue();
        setQueueLoad(false);

      }catch (e) {
        setQueueLoad(false);
        message.error(`Error adding ${adjustLength > 1 ? "properties":"property"} into the queue`, 3)
      }

    }
  };

  const onDeleteClick = async() => {
    try {
      setQueueLoad(true);
      await property.deletePropertyQueue({properties: adjustQueueData.map((data) => data?.id)});
      message.success(`Successfully deleted ${adjustLength} ${adjustLength > 1 ? "properties":"property"} into the queue`, 3);
      if (otherFunctions) otherFunctions();
      setQueueLoad(false);
    } catch (e) {
      setQueueLoad(false);
      message.error(`Error deleting ${adjustLength > 1 ? "properties":"property"} into the queue`, 3)
    }

  };

  return (
    <AdjustQueueMenuDiv display={adjustQueueData.length ? '24px' : '0px'}>
      {type === 'workingqueue' ?
        <AdjustQueueButton disabled={queueLoad} onClick={onDeleteClick}>
          <img src={deleteQueue} width="24px" height="24px" alt="dollar"/>
          <BodyText margin="0px 0 0 -8px">
            {queueLoad ? `Removing ${adjustLength} ${adjustLength > 1 ? "properties":"property"}...` : 'Remove Queue'}
          </BodyText>
        </AdjustQueueButton>
      : 
      <Fragment>
        <BodyText>{checkedRows  ? `${checkedRows} selected`: ''}</BodyText>
        <AdjustQueueButton onClick={onAdjustClick}>
          <img src={priceAdjustment} width="16px" height="16px" alt="dollar"/>
          <BodyText margin="0px">
            Adjust Price
          </BodyText> 
        </AdjustQueueButton>
        <AdjustQueueButton disabled={queueLoad} onClick={onPropertyQueueClick}>
          <img src={priceAdjustmentQueue} width="16px" height="16px" alt="qeue"/>
          <BodyText margin="0px">
            {queueLoad ?  'Adding to Queue...' : 'Add to Price Adjustment Queue'}
          </BodyText>
        </AdjustQueueButton>
      </Fragment> }
      
    </AdjustQueueMenuDiv>
  )
}

export default memo(AdjustQueueMenu);