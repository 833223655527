import React from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText } from '../../../components/ReusableComponents/Text/Text';

const WarningBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 40px;
`;

const WarningFlexDiv = styled.div`
  ${BodyCSS}
  border: 1px solid var(--Orange, #EDB548);
  box-shadow: none;
  padding: 4px 16px 4px 16px;
  height: 100%;
  min-width: 1600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const YellowClose = styled(BodyText)`
  color: #EDB548;
`;

const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;


const Warning = ({setShowWarning}) => {
  const onClosebutton = () => {
    setShowWarning(false);
  };
  
  return (
    <WarningBody>
      <WarningFlexDiv>
        <BodyText>
          We noticed some new properties in your listing that haven't been activated for silent dynamic pricing. Go to settings
        </BodyText>
        <CloseButton onClick={onClosebutton}>
          <YellowClose>
            X
          </YellowClose>
        </CloseButton>
      </WarningFlexDiv>
    </WarningBody>
  )
}

export default Warning;