import { useState, useEffect } from "react";
import axiosBase from '../../services/auth';
import { REPORT_API_UTIL } from '../../util/APIUtility';

axiosBase.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers = { "Authorization": `Bearer ${ token }` };

  return config;
}, function (error) {
  return Promise.reject(error);
});
const base = {
  default: axiosBase,
  report: REPORT_API_UTIL
}

function useQueryHook({
                        url,
                        baseUri = "default",
                        onError,
                        onComplete,
                        isLazy = false,
                        payload = {},
                        silentRequest: silentRequest = false
                      }) {
  const [loading, setLoading] = useState(!isLazy);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [headers, setHeaders] = useState();

  const fetchData = async (newPayload, silentRequests) => {
    setLoading(!(silentRequest || silentRequests));

    const finalPayload = newPayload || payload
    try {
      const response = await base[baseUri].get(url, { params: newPayload || payload });
      setHeaders(response.headers);
      setData(response.data);
      onComplete?.(response.data, response.headers);
      setError(null);
    } catch (error) {
      const err = error || "Unable to complete process, try again";
      onError?.(err, finalPayload);
      setError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isLazy) {
      fetchData().catch(() => {
      });
    }
  }, []);

  const request = (newPayload, silentRequests) => {
    fetchData(newPayload, silentRequests).catch(() => {
    });
  };

  return {
    headers,
    loading,
    error,
    data,
    request
  };
}

export default useQueryHook;
