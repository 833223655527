import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from './appRedux/store';
import App from "./containers/App/index";
import { DataProvider } from './util/DataStore/DataContext';

const store = configureStore(/* provide initial state if any */);

const NextApp = () => {

  return (
    <DataProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App}/>
          </Switch>
        </ConnectedRouter>
      </Provider>
    </DataProvider>
  );
};
export default NextApp;
