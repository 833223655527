import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SET_FILTERS, SHOW_MESSAGE, SET_PARENT_FILTER} from '../../constants/ActionTypes';
import {TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "../../constants/ActionTypes";
import { formatDate, storeItem } from '../../util/localStorageHelper';

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  navCollapsed: true,
  width: window.innerWidth,
  pathname: '/',
  filters: {
    viewType: 'rmView',
    year: null,
    month: null,
    date: null,
    properties: null,
    filterBy: 'month',
    intMonth: null,
    proratedType: 'city',
    hideInactiveProperties: true,
    newCurrentDate: formatDate(new Date()),
    sameStore: false,
    calendarFilters: [],
  },
  parentFilter: {
    viewType: 'rmView',
  }
};

const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      }
    }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed
      }
    }
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', textMessage: action.payload, message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: '', textMessage: ''};
    }
    case SET_FILTERS: {
      storeItem("filters", action.payload)
      return {...state, filters: action.payload };
    }
    case SET_PARENT_FILTER: {
      storeItem("parentFilter", action.payload)
      return {...state, parentFilter: action.payload };
    }
    default:
      return state;
  }
}

export default CommonReducer;
