import styled, {css} from "styled-components";

export const BodyDescriptionText = styled.p`
  ${({theme}) => `
      font-size: ${theme.s4};
      color: ${theme.bodyFg};
  `}
  font-weight: 500;
  margin: 0px;
`;

export const BodyTitleText = styled.h1`
  ${({theme}) => `
  font-size: ${theme.s9};
  color: ${theme.bodyFg};
`}

  margin: 0px;
  font-weight: 700;
`;

//after Jott

export const BodyText = styled.p`
  font-size: ${({theme}) => theme.s4};
  margin: ${({margin}) => margin ? margin : '4px 0px'};
  font-family: Commissioner;
  color: ${({theme}) => theme.bodyFg};
  font-weight: 300;
`;

export const BodyTitle = styled.h1`
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s6};
  color: ${({theme}) => theme.bodyFg};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: ${({margin}) => margin ? margin: '0px'};
`;


export const BodyTextCSS = css`
  font-size: ${({theme}) => theme.s4};
  font-family: Commissioner;
  color: ${({theme}) => theme.bodyFg};
  font-weight: 300;
`;