import React, {memo} from "react";
import styled, {keyframes} from "styled-components";
import quibbleHouse from '../../../assets/images/quibble_house.png';
import PropTypes from 'prop-types';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 500;
  margin: auto;
  background: none;
  width: fit-content;
  height: fit-content;

`;

const ComponentsDiv = styled.div`
  width: 120px;
  min-height: 120px;
  height: fit-content;
  position: relative;
  text-align: center;
  margin: ${({marginWithLabel}) => marginWithLabel};  
`;

const LoaderDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 500;

  ${({small, theme}) => `
    top: ${small ? '-6px' : '-9px' };
    width: ${small ? '36px' : '72px' };
    height: ${small ? '36px' : '72px' };  
    border: ${ small ? `4px solid ${theme.quibbleBlue}` : `6px solid ${theme.quibbleBlue}`}; 
  `}

  margin: auto;


  border-top: 6px solid transparent;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;


const LoaderImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 500;
  margin: auto;
  border-radius: 50%;

  ${({small}) => `
    width: ${small ? '24px' : '54px' };
    height: ${small ? '24px' : '54px' };
  `}

`;

const LoaderLabel = styled.h1`
  position: absolute;
  width: fit-content;
  font-family: Commissioner;
  left: 0;
  right: 0;
  margin: 0;

  ${({small, theme}) => `
    top:  ${small ? '36px' : '68px' };
    font-size: ${ small ? theme.s3 : theme.s4};
    color: ${theme.bodyFg};
  `}

  margin: auto; 
`;

const QuibbleSpinLoader = ({small=false,loading=true, label=""}) => {

  return (
    loading ? 
    <LoaderContainer small={small}>
      <ComponentsDiv marginWithLabel={label && label.length ? '0 0 0 0' : '40px 0 0 0'}>
        <LoaderDiv small={small}/>
        <LoaderImage small={small} src={quibbleHouse} alt="loader"/> 
        <LoaderLabel small={small}> {label} </LoaderLabel>
      </ComponentsDiv>
    </LoaderContainer>
    : null
  )
};
export default memo(QuibbleSpinLoader);

QuibbleSpinLoader.propTypes = {
  loading: PropTypes.bool,
  small: PropTypes.bool,
  label: PropTypes.string,
}