import React, {useEffect, useRef, useState, useCallback, memo, useMemo, Fragment} from 'react';
import styled from 'styled-components';
import CalendarProperty from './CalendarPropertyMenu';
import MarketCalendarDay from './MarketCalendarDay';
import { CalendarDaysDiv } from '../../../CommonCSS';
import { isUndefined } from 'lodash';


const CalendarRow = styled.div`
  width: 100%;
  display: flex; 
  flex-direction: row;
`;


const CalendarDaysMap = ({calendarContent, selectedProperty, onSetSelectedProperty, isFetchingCalendarListing, showReservations}) => {
  const [calendarListPage, setCalendarListPage] = useState([0,30]);
  const bottom = useRef(null);
  const top = useRef(null);

  const handleTopObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      if (calendarContent.length < 30) {
        setCalendarListPage([0,30])
        return;
      };

      setCalendarListPage((prev) => {
        if (prev[0] > 5) {
          return [prev[0] - 5, prev[1] - 5]
        }
        if (prev[0] <= 5) {
          return [0, 30]
        }
      });
    }
  }, [calendarContent.length]);

  const handleBottomObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      if(calendarContent.length > 30) {
        setCalendarListPage((prev) => {
  
          if (prev[0] < (calendarContent.length - 30)) {
            return [prev[0] + 5, prev[1] + 5]
          }
  
          if (prev[1] > (calendarContent.length)) {
            return [(calendarContent.length - 30), calendarContent.length]
          }

          if (prev[1] === (calendarContent.length)) {
            return prev;
          }  
        })
      }
    }
  }, [calendarContent.length]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 1
    };

    const topObserver = new IntersectionObserver(handleTopObserver, option);
    if (top.current) topObserver.observe(top.current);

    const bottomObserver = new IntersectionObserver(handleBottomObserver, option);
    if (bottom.current) bottomObserver.observe(bottom.current);

    return () => {
      bottomObserver.disconnect();
      topObserver.disconnect();
    }
  }, [handleBottomObserver, handleTopObserver]);

  const newCalendar = calendarContent.slice(calendarListPage[0], calendarListPage[1]);

  const reservationsArray = useMemo(() => {
    const reservationsArrayMap = newCalendar.map((property, propertyIndex) => property.calendarDays.slice(1, 8).reduce((outputData, current, index) => {
      const {reservation} = current;

      if (reservation && index === 0) {
        return {
          ...outputData,
          [reservation.id] : {
            ...outputData[reservation.id],
            guest: reservation?.guest,
            index: !isUndefined(outputData[reservation.id]?.index) ? outputData[reservation.id]?.index : index,
            // total: (outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : 0) + reservation?.price,
            total: outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : reservation?.price,
            adr: outputData[reservation.id]?.adr ?  outputData[reservation.id]?.adr : reservation?.adr,
            length: (outputData[reservation.id]?.length ?  outputData[reservation.id]?.length : 0) + 1,
            isSunConti: reservation.id === newCalendar[propertyIndex].calendarDays[0].reservation?.id,
          }
        }
      };

      return reservation ?  {
        ...outputData,
        [reservation.id] : {
          ...outputData[reservation.id],
          guest: reservation?.guest,
          index: !isUndefined(outputData[reservation.id]?.index) ? outputData[reservation.id]?.index : index,
          // total: (outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : 0) + reservation?.price,
          total: outputData[reservation.id]?.total ?  outputData[reservation.id]?.total : reservation?.price,
          adr: outputData[reservation.id]?.adr ?  outputData[reservation.id]?.adr : reservation?.adr,
          length: (outputData[reservation.id]?.length ?  outputData[reservation.id]?.length : 0) + 1,
          isSatConti: reservation.id === newCalendar[propertyIndex].calendarDays[8]?.reservation?.id,
        }
      } : {
        ...outputData
      }
    },{}))

    return reservationsArrayMap;
  }, [newCalendar])


  return (
    <Fragment>
      <div ref={top} />
      {newCalendar.map((calendarData, propertyIndex)=> {
          return (
            <CalendarRow key={calendarData.propertyId}>
              <CalendarProperty isFetchingCalendarListing={isFetchingCalendarListing} selectedProperty={selectedProperty} onSetSelectedProperty={onSetSelectedProperty} bedrooms={calendarData.bedrooms} market={calendarData.market} propertyName={calendarData.propertyName} propertyId={calendarData.propertyId} />
              <CalendarDaysDiv>
                {calendarData.calendarDays.slice(1,8).map((data, dayIndex) => (<MarketCalendarDay showReservations={showReservations} 
                reservation={reservationsArray[propertyIndex]}  
                key={data.date} data={data} dayIndex={dayIndex}/>))}
              </CalendarDaysDiv>
            </CalendarRow>
          )
        })}
      <div ref={bottom} />
    </Fragment>
  )
}

export default memo(CalendarDaysMap);