import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropertyModel from '../../marketAnalytics/PropertyDetails/components/subComponents/PropertyMenu/PropertyModel';
import { BodyCSS } from '../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText, BodyTitle } from '../../../components/ReusableComponents/Text/Text';
import { useSelector } from 'react-redux';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';


import { media } from 'styles/stylesheet';

const ComparisonDiv = styled.div`
  width: 100vw;
  height: 100vh;

  top: 0px;
  left: 0px;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;

  display: ${({display}) => display};
  align-items: center;
  justify-content: center;

  ${media.bpxxl}{
    align-items: unset;
    justify-content: unset;
	}

  overflow: auto;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

`;

const ComparisonBody = styled.div`
  ${BodyCSS}
  min-width: 1480px;
  // max-width: 1480px;
  height: 900px;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  padding: 16px;
`;

const Header = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
  outline: none;

  color: ${({theme}) => theme.bodyFg};
  font-size: 1.5rem;
  cursor: pointer;
`;

const TitleDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

const ListingComparison = ({comparisonDetails, setComparisonDetails}) => {


  const { propertyModel } = useSelector(({ property }) => property);


  const [model, setModel] = useState({
    price: 1,
    bedrooms: 1,
    ratingValue: 0,
    reviewCount: 0,
    minNights: 0,
    jacuzzi: false,
    pool: false,
    landscapeViews: false,
    imageScore: 0,
  });


    //get model properties
    useEffect(() => {
      setModel({
        price: propertyModel?.price || 1,
        bedrooms: propertyModel?.bedrooms || 1,
        ratingValue: propertyModel?.rating_value || 0,
        reviewCount: propertyModel?.review_count || 0,
        // downtown: propertyModel?.dist || 0,
        minNights: propertyModel?.min_nights || 0,
        jacuzzi: propertyModel?.jacuzzi || false,
        pool: propertyModel?.pool || false,
        landscapeViews: propertyModel?.landscape_views || false,
        imageScore: propertyModel?.adjusted > 0 ? propertyModel?.adjusted :
        Number((propertyModel?.images?.reduce((output, current) => output + current.score, 0) /  propertyModel?.images?.length).toFixed(2)) || 0,
      })
  }, [propertyModel]);

  const onCloseComparison = () => {
    setComparisonDetails({ isVisible: false});
  };

  return (
    <ComparisonDiv display={comparisonDetails.isVisible ? 'flex' : 'none'}>
      <ComparisonBody>
        <Header>
          <TitleDiv>
            <BodyTitle>
              Comparison Model
            </BodyTitle>
            <BodyText>
              Compare your property to competitor’s properties and analyze data like nightly rate, amenities, and reviews.
            </BodyText>
          </TitleDiv>
          <CloseButton margin="0px" onClick={onCloseComparison}>
            x
          </CloseButton>
        </Header>
        <PropertyModel propertyDetails={comparisonDetails} model={model} setModel={setModel} isListing={true} />
      </ComparisonBody> 
    </ComparisonDiv>
  )
}

export default ListingComparison;