import React from 'react';
import styled from 'styled-components';

const StatusBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TrueActive = styled.div`
  width: fit-content;
  padding: 4px 8px;
  height: 20px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #DDFFE9;
  margin: 0px;

  color: #00A638;
  font-family: Commissioner;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
`;

const FalseActive = styled(TrueActive)`
  color: #697273;
  background: #E8E8E8
`;



const Status = ({value}) => {

  return (
    <StatusBody>
      {value ? 
        <TrueActive>
          ACTIVE
        </TrueActive>
        :
        <FalseActive>
          INACTIVE
        </FalseActive>
      }
    </StatusBody>
  )
}

export default Status;