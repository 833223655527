import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { InputNumber, Form, message } from 'antd';
import { PrimaryButton, SecondaryButton } from '../../../../../../components/ReusableComponents/Button/Button';

import { property } from '../../../../../../services/property';

const ParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
`;

const TitleText = styled.h1`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const ParameterItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;

  label {
    width: 120px;
    flex-grow: 1;
    color: #283334;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
  }

  .ant-input-number {
    flex: 1;
    justify-content: flex-end;
  }
`;

const TotalDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 300;
  
  span {
    color: ${(props) => (props.isValid ? '#00A638' : '#F26262')};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

const SaveButton = styled(PrimaryButton)`
  width: 200px;
  height: 50px;
  background: #1BB3CD;
  color: white;
`;

const ResetButton = styled(SecondaryButton)`
  margin-right: 20px;
  width: 200px;
  height: 50px;
`;

const parameterLabels = {
  bedrooms: 'Bedrooms',
  bathrooms: 'Bathrooms',
  guests: 'Guests',
  adr: 'ADR',
  distance: 'Distance',
  ratings: 'Ratings',
  reviews: 'Reviews',
  hotTubJacuzzi: 'Hot Tub/Jacuzzi',
  pool: 'Pool',
};

const CompetitorReplacementParameters = () => {
  const { propertyDetails } = useSelector(({ property }) => property);
  const { intelCompParams, id } = propertyDetails;

  const [weights, setWeights] = useState({
    bedrooms: 0,
    bathrooms: 0,
    guests: 0,
    adr: 0,
    distance: 0,
    ratings: 0,
    reviews: 0,
    hotTubJacuzzi: 0,
    pool: 0,
  });

  const [initialWeights, setInitialWeights] = useState(null);
  const [total, setTotal] = useState(0);
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    if (intelCompParams) {
      const newWeights = {
        bedrooms: intelCompParams.bedroom * 100,
        bathrooms: intelCompParams.bathroom * 100,
        guests: intelCompParams.guest * 100,
        adr: intelCompParams.adr * 100,
        distance: intelCompParams.distance * 100,
        ratings: intelCompParams.rating * 100,
        reviews: intelCompParams.review * 100,
        hotTubJacuzzi: intelCompParams.jacuzzi * 100,
        pool: intelCompParams.pool * 100,
      };
      setWeights(newWeights);
      setInitialWeights(newWeights);
    }
  }, [intelCompParams]);

  useEffect(() => {
    const newTotal = Object.values(weights).reduce((acc, value) => acc + value, 0);
    setTotal(newTotal);
    setInputError(newTotal !== 100);
  }, [weights]);

  const handleWeightChange = (field, value) => {
    setWeights({ ...weights, [field]: value });
  };

  const onSave = async () => {
    if (inputError) {
      message.error('Total of 100% required for selection weights.');
    } else {
      const body = {
        bedroom: weights.bedrooms / 100,
        adr: weights.adr / 100,
        distance: weights.distance / 100,
        guest: weights.guests / 100,
        rating: weights.ratings / 100,
        review: weights.reviews / 100,
        jacuzzi: weights.hotTubJacuzzi / 100,
        pool: weights.pool / 100,
        bathroom: weights.bathrooms / 100,
      };

      try {
        const response = await property.updatePropertyParameters({ propertyId: id, body });

        if (response.status === 200) {
          message.success('Selection weights saved successfully.');
        } else {
          message.error('Failed to update selection weights.');
        }
      } catch (error) {
        message.error('An error occurred while updating selection weights.');
      }
    }
  };

  const onReset = () => {
    if (initialWeights) {
      setWeights(initialWeights); 
    }
  };

  return (
    <ParametersContainer>
      <TitleText>Selection Weights</TitleText>
      <Form>
        {Object.keys(weights).map((key) => (
          <Form.Item key={key}>
            <ParameterItem>
              <label>{parameterLabels[key]}:</label>
              <InputNumber
                min={0}
                max={100}
                value={weights[key]}
                onChange={(value) => handleWeightChange(key, value)}
                formatter={(value) => `${value ? parseFloat(value).toFixed(0) : '0.00'}%`}
                parser={(value) => value ? parseFloat(value.replace('%', '')).toFixed(0) : 0}
                disabled={!['adr', 'bedrooms', 'distance'].includes(key)}
              />
            </ParameterItem>
          </Form.Item>
        ))}
      </Form>
      <TotalDiv isValid={total === 100}>
        Total:&nbsp;<span>{total.toFixed(0)}%</span>
      </TotalDiv>
      <ButtonsContainer>
        <ResetButton onClick={onReset}>Reset</ResetButton>
        <SaveButton onClick={onSave}>
          Save
        </SaveButton>
      </ButtonsContainer>
    </ParametersContainer>
  );
};

export default CompetitorReplacementParameters;
