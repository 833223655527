import React from 'react';
import styled, { css } from 'styled-components';
import { BodyTitle, } from "components/ReusableComponents/Text/Text";
import ButtonWithFunction from 'components/ReusableComponents/Button/ButtonWithFunction';

const MultiplierBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

const MultiplierTitle = styled(BodyTitle)`
  align-self: flex-start;
`;

const AdjustPricesSpan = styled.span`
  color: ${({theme}) => theme.quibbleBackgroundGrey};
  font-weight: 300;
  font-size: ${({theme}) => theme.s3};
  font-family: Commissioner;
  align-self: flex-start;
  margin: 4px 0 12px 0;
`;

const MultiplierDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin: 8px 0 0 0;
`;

const MultiplierButton = css`
  width: 110px;
  height: 24px;

  background: none;
  transition: all 0.1s linear;
  border-radius: 4px;
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s4};
  color: ${({theme}) => theme.quibbleBlue};
  border: 1px solid ${({theme}) => theme.quibbleBlue};
  box-shadow: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;


  &:hover, &.active {
    color: ${({theme}) => theme.white};
    background:  ${({theme}) => theme.quibbleBlue};
 }
  

`;


const Multiplier = ({onAppyChanges}) => {

  const onMultiplyClick = (value) => {
    onAppyChanges(value, "multiplier");
  };

  return (
    <MultiplierBody>
      <MultiplierTitle>
        Multiplier
      </MultiplierTitle>
      <AdjustPricesSpan>
        Adjust price rates by percentage
      </AdjustPricesSpan>
      <MultiplierDiv>
        <ButtonWithFunction onClickFunc={onMultiplyClick} value={1} ButtonStyle={MultiplierButton} label={"+1"} />
        <ButtonWithFunction onClickFunc={onMultiplyClick} value={5} ButtonStyle={MultiplierButton} label={"+5"} />
        <ButtonWithFunction onClickFunc={onMultiplyClick} value={10} ButtonStyle={MultiplierButton} label={"+10"} />
      </MultiplierDiv>
      <MultiplierDiv>
        <ButtonWithFunction onClickFunc={onMultiplyClick} value={-1} ButtonStyle={MultiplierButton} label={"-1"} />
        <ButtonWithFunction onClickFunc={onMultiplyClick} value={-5} ButtonStyle={MultiplierButton} label={"-5"} />
        <ButtonWithFunction onClickFunc={onMultiplyClick} value={-10} ButtonStyle={MultiplierButton} label={"-10"} />
      </MultiplierDiv>
    </MultiplierBody>
  )
}

export default Multiplier;