import React from 'react';
import styled from 'styled-components';
import { CloseButton, ModalClose, ModalContent, ModalContentbody, ModalHeader, ModalTitle } from './CommonCSS';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';
import { useState } from 'react';
import tag from 'assets/images/paywall/tag.svg';

import {subscriptionService} from 'services/subscription';
import { message } from 'antd';
import { BodyText, BodyTitle } from '../../../components/ReusableComponents/Text/Text';

const SubPlanBody = styled(ModalContent)`
  height: 556px;
`;

const SubPlanHeader = styled(ModalHeader)`
  text-align: center;
`;

const HeaderTitle = styled(ModalTitle)`
  font-size: 2.25rem;
`;

const ModalLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #E8E8E8;
  margin: 12px 0px;
`;

const Tally = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid #E8E8E8;
`;

const TallyTotal = styled(Tally)`
  border-top: 1px solid #E8E8E8;
`;

const CouponText = styled(BodyText)`
  color: #00A4E6;
`;

const BoldText = styled(BodyText)`
  font-weight: 600;
`;

const ContinueButton = styled(PrimaryButton)`
  width: 100%;
  height: 50px;
  margin: 16px 0 0 0;
`;

const CouponButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 16px 0;
  font-family: Commissioner;
`;

const CouponInputDiv = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
`;

const CouponInput = styled.input`
  width: 100%;
  height: 48px;
  padding: 0 0 0 16px;
  color: #283334;
  font-size: 1rem;
  outline: none;
  border: 1px solid #00A4E6;
  background: none;
  font-weight: 400;
  font-family: Commissioner;
  border-radius: 6px;


  &::placeholder {
    color: #78838C;
    opacity: 1; 
  }
  
  &::-ms-input-placeholder {
    color: #78838C;
  }

`;

const ApplyButton = styled(CouponButton)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 16px;
`;  

const CouponValueDiv = styled.div`
  width: fit-content;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background: #E8E8E8;
  padding: 0 10px;
  margin: 12px 0;
`;

const SubscriptionPlan = ({setSubscriptionMenu}) => {

  const [couponmenu, setCouponMenu] = useState('button');
  const [couponInput,setCouponInput] = useState('');
  const [couponValue, setCouponValue] = useState('');
  
  const onCouponClick = () => {
    setCouponMenu('input')
  };

  const onCouponInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setCouponInput(value.toUpperCase());
  };

  const onApplyCouponValue = () => {
    if(!couponInput.length) return;
    setCouponValue(couponInput);
    setCouponMenu('');
  };

  const onDeleteCoupon = () => {
    setCouponValue('');
    setCouponInput('');
    setCouponMenu('button');
  };

  const onCloseClick = () => {
    setSubscriptionMenu('subscriptionDetails');
  };


  const onSubscriptionSubmit = async () => {
    try {

      const requestBody = {

      };

      if (couponValue.length) requestBody.coupon = couponValue;
      const {data} = await subscriptionService.subscribe(requestBody);
      const {url} = data;

      if(!url?.length) {
        message.error('Subscription failed, try again');
        return;
      };

      window.location.replace(url);


    } catch (e) {
      message.error('Invalid coupon');
    }
  };

  return (
    <SubPlanBody>
      <SubPlanHeader>
        <HeaderTitle>
          Your subscription plan
        </HeaderTitle>
        <CloseButton onClick={onCloseClick}>
          <ModalClose>
            X
          </ModalClose>
        </CloseButton>
      </SubPlanHeader>
      <ModalContentbody>
        <BodyTitle>
          Summary
        </BodyTitle>
        <BodyText>
          Billing period: Monthly
        </BodyText>
        <ModalLine />
        <Tally>
          <BodyText>
            RevenueOS
          </BodyText>
          <BodyText>
            $299
          </BodyText>
        </Tally>
        <Tally>
          <BodyText>
            Subtotal
          </BodyText>
          <BodyText>
            $299
          </BodyText>
        </Tally>
        {couponmenu === 'button' && <CouponButton onClick={onCouponClick}>
          <CouponText margin="0 0 0 0" >
            Add Coupon Code
          </CouponText>
        </CouponButton>}
        {couponmenu === 'input' &&  <CouponInputDiv>
          <CouponInput value={couponInput} onChange={onCouponInputChange}/>
          <ApplyButton onClick={onApplyCouponValue}>
            Apply
          </ApplyButton>
        </CouponInputDiv>}
        {couponValue.length > 0 &&  <CouponValueDiv>
          <img src={tag} alt="tag" />
          <BodyText>
            {couponValue}
          </BodyText>
          <CouponButton onClick={onDeleteCoupon}> 
            X
          </CouponButton>
        </CouponValueDiv>}
        <TallyTotal>
          <BoldText>
            Total
          </BoldText>
          <BoldText>
            $299
          </BoldText>
        </TallyTotal>
        <ContinueButton onClick={onSubscriptionSubmit}>
          Continue
        </ContinueButton>
      </ModalContentbody>
    </SubPlanBody>
  )
}

export default SubscriptionPlan;