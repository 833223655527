import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { currencyFormatter } from "util/helper";

const BarDiv = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
`;


const CurrentDiv = styled.div`
  width: ${({width}) => width};
  height: 100%;
  background: ${({background}) => background};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 4px;

  color: ${({theme}) => theme.hoverBlue};
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s2};
`;



const OccupancyBar = ({data, type, isSelected, activeColor}) => {
  const currentWidth = Number((data * 100).toFixed(0));

  const theme = useTheme();

  const renderBar = useCallback(() => {

    if (type === 'occupancy') {
      return (
        <CurrentDiv background={isSelected && activeColor ? activeColor : theme.tableBarBlue} width={`${currentWidth}%`} >
          {currentWidth  && `${currentWidth}%`}
        </CurrentDiv>
      )
    }

    return (
      <CurrentDiv background={isSelected ? activeColor : theme.tableBarBlue} width="100%">
          {currentWidth && currencyFormatter(data)}
        </CurrentDiv>
    )

  
  },[currentWidth, data, type, activeColor, isSelected, theme.tableBarBlue])
  

  return (
    <BarDiv>
      {renderBar()}
    </BarDiv>
  )
}

export default OccupancyBar;