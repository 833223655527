import React, { useCallback } from "react";
import styled from "styled-components";

import CalendarDayTitle from './CalendarDayTitle';
import { CalendarDaysDiv, CalendarHeader } from '../../../CommonCSS';
import { CalendarPropertyHeader } from '../../../CommonCSS';
import CalendarDaysMapped from './MarketCalendarDaysMapped';
import prevButton from '../../../../../../../assets/images/pricing-calendar/prev-button.svg';
import nextButton from '../../../../../../../assets/images/pricing-calendar/next-button.svg'
import { lightScrollbar, darkScrollbar } from '../../../../../../../components/ReusableComponents/Scrollbar/Scrollbar';
import SkLoaderDistinct from "../../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderDistinct";


const MarketCalendarDiv = styled.div`
  max-width: 1800px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: ${({overflow}) => overflow};
  position: relative;
  ${({theme}) => theme.isDefault ? lightScrollbar : darkScrollbar };
`;

const ButtonImage = styled.img`
  border-radius: 100%;
  image-rendering: -webkit-optimize-contrast;
`;


const PrevWeek = styled.button`
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;

  position: absolute;
  left: 8px;
  top: 12px;

  &:hover {
    ${ButtonImage} {
      background: ${({theme}) => theme.quibbleBlue};
      color: ${({theme}) => theme.white};
    }
  }

  &:disabled {
    ${ButtonImage} {
      background: ${({theme}) => theme.disabledGrey};
      color: ${({theme}) => theme.white};
    }
  }
`;

const NextWeek = styled(PrevWeek)`
  left: unset;
  left: initial;
  right: 8px;
`;


const MarketCalendar = ({calendarContent, onChangeDates, selectedProperty, onSetSelectedProperty, isFetchingCalendarListing, showReservations}) => {

  const showCalendarDates = useCallback(() => {
    if (calendarContent.length && calendarContent[0].calendarDays.length) {
      const calendarDates = calendarContent[1].calendarDays;
      return calendarDates.slice(1,8).map((data,index) => (<CalendarDayTitle date={data.date} key={data.date} index={index}/>));
    };
    return [...Array(7).keys()].map((_,index) => (<CalendarDayTitle date={''} key={`${index}`} index={index}/>));
  }, [calendarContent])

  const onPrevClick = () => {
    onChangeDates("prev")
  };

  const onNextClick = () => {
    onChangeDates("next")
  };


  return (
    <MarketCalendarDiv overflow={isFetchingCalendarListing ? 'hidden' : 'auto'}>
      <CalendarHeader>
        <CalendarPropertyHeader>
          PROPERTIES
        </CalendarPropertyHeader>
        <CalendarDaysDiv >
          <PrevWeek onClick={onPrevClick} disabled={isFetchingCalendarListing}>
            <ButtonImage src={prevButton} alt='prevButton'/>
          </PrevWeek>
          <NextWeek onClick={onNextClick} disabled={isFetchingCalendarListing}>
            <ButtonImage src={nextButton} alt='nextButton'/>
          </NextWeek>
          {showCalendarDates()}
        </CalendarDaysDiv>
      </CalendarHeader>
      {isFetchingCalendarListing ? 
        <SkLoaderDistinct type={'table'} tableRows={8}/>
        : 
        <CalendarDaysMapped showReservations={showReservations} isFetchingCalendarListing={isFetchingCalendarListing} calendarContent={calendarContent} selectedProperty={selectedProperty} onSetSelectedProperty={onSetSelectedProperty}/>
      }
    </MarketCalendarDiv>
  )
}

export default MarketCalendar;