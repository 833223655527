import React from 'react';
import styled from 'styled-components';

import PlanProfile from './subComponents/subscriptions/CurrentPlan';
import BillingPlan from './subComponents/subscriptions/Billing';
import PaymentHistory from './subComponents/subscriptions/PaymentHistory';

const SubscriptionsBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Subscriptions = () => {
  return (
    <SubscriptionsBody>
      <PlanProfile />
      <BillingPlan />
      <PaymentHistory />
    </SubscriptionsBody>
  )
}

export default Subscriptions;