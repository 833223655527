import React from 'react';
import {useSelector} from "react-redux";
import {Button} from "antd";
import IntlMessages from "util/IntlMessages";


import SignupCard from '../components/SignupCard';
import AuthLogo from "../components/AuthLogo";
import AuthLoader from '../components/AuthLoader';
import useShowMessage from '../customHooks/useShowMessage';


const FinishSignUp = (props) => {

  const {loader, alertMessage, showMessage,alertMessageType} = useSelector(({auth}) => auth);
  useShowMessage(showMessage, alertMessageType, alertMessage);

  return (
    <div className="app-signin-wrap signup">
        <SignupCard current={3} title="Finishing up" />
        <div className="app-signin-container finishingup" >
          <div className="app-signin-form-container">
            <h1 className="app-text signin-headertext">
                Thank You!
            </h1>
            <h1 className="app-text signin-logotext verification">
              <IntlMessages id="app.finishsignup.importStarted" />
            </h1>
            <h1 className="app-text signin-logotext verification">
              <IntlMessages id="app.finishsignup.weWillNotify" />
            </h1>
            <h1 className="app-text signin-logotext verification">
              If you have any questions?
              &nbsp;
              <a href="mailto:hello@quibblerm.com" className='app-button-primary-forgot signup-back-to-signin'>
                <span className="forgot-span">
                  Contact us
                </span>
              </a>
            </h1>
            <Button  className="app-button-primary signup finishsignup" href="https://ent.quibblerm.io">
              Continue to Website
            </Button>
            <AuthLoader loader={loader} />
          </div>
          <AuthLogo />
        </div>
    </div>
  );
}

export default FinishSignUp;
