import React, {memo, useCallback} from 'react';
import { AVAILABLE_COMPANY_LOGOS, NO_SPECIALCHARS_SPACE } from 'constants/GlobalConstant';

const RenderActiveLogo = ({name, fill, value}) => {

  const activeLogo = useCallback(() => {
    //using try-catch to catch 'require' errors and return null, does logos not found will only display name, no image. 
    try {

      //remove space, special characters and set the name to lowercase.
      const parsedName = name.replace(NO_SPECIALCHARS_SPACE, "").toLowerCase();

      //find if the parsed name includes or contains one of the logos that we have.
      //this method is necessary since we have different datas for each sources for example 'airbnb' can be 'airbnb official' or 'airbnb website'.
      const logoToUse = AVAILABLE_COMPANY_LOGOS.find((value) => {
        return parsedName.includes(value);
      })

      return (<img 
        src={require(`assets/images/reservation-sources/${logoToUse}.png`).default} 
        alt="company logo" 
        className='app-add-contrast'
        />)
        
    } catch {
      return null;
    } 
  },[name]);

  return (
    <div  className='app-reservation-pie-logo'>
      {activeLogo()}
      <p className='logo-name' style={{color: fill}}>{name}</p>
      <p style={{color: fill, margin:'-10px 0 0 0'}}><b>{ value ? `(${value?.toFixed(0)}%)` : '-'}</b></p>
    </div>
  )
}

export default memo(RenderActiveLogo);