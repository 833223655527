import React from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../../../components/ReusableComponents/Card-Body/BodyCSS';
import SkLoaderOver from '../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';

const InsightsPropDiv = styled.div`
  ${BodyCSS}
  width: 436px;
  min-width: 436px;
  height: 98px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
`;

const PropItemDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const PropItemLabels = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const PropItemTitle = styled.span`
  color: #697273;
  font-family: Commissioner;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
`;

const PropItemText = styled.span`
  color: ${({$color}) => $color || 'black'};
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ImageDiv = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({$color}) => $color || 'black'};

`;

const InsightsProp = ({logo,color, title, value, isLoading}) => {
  return (
    <InsightsPropDiv>
      <PropItemDiv>
        <ImageDiv $color={color}>
          <img src={logo} alt='logo' />
        </ImageDiv>
        <PropItemLabels>
          <PropItemTitle>
            {title}
          </PropItemTitle>
          <PropItemText $color={color}>
            {value}
          </PropItemText>
        </PropItemLabels>
      </PropItemDiv>
      <SkLoaderOver  isLoading={isLoading} type='full'/>
    </InsightsPropDiv>
  )
}

export default InsightsProp;