import {React, useState} from "react";
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'

const ContainerDivvv = styled.div`
  display : flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
  `;
const Divv = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;

`;
const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SecondDiv = styled.div`
  width: 50%;
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const LeftParagraphText = styled.p`
  font-size: 36px;
  font-weight: 300;
  text-align: center;

`;
const LogoImage = styled.p`
  margin: 120px 0px;
  display: flex;
  justify-content: center;
`;
const Texts = styled.p`
  position: absolute;
  width: 100%;
  color: white;
  line-height: 30px;
  padding-top: 70px;
`;

const Text1 = styled.p`
  font-size: 36px;
  font-weight: 300;
  width: 100%;
  text-align: center;

`;
const Text2 = styled.p`
  font-size: 54px;
  font-weight: 600;
  width: 100%;
  text-align:center;
  line-height: 10px;
`;
const PasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 54px;

`;
const PasswordInput = styled.input`
  width: 500px;
  padding: 16px 8px;
  border-radius: 4px;
  border: 1px solid ${({ isPasswordError }) => (isPasswordError ? '#F02C2C' : '#CDCDCD')};
  outline: none;
  position: relative;
`;

const EyeImg = styled.img`
  position: absolute;
  margin-top: 65px;
  margin-left: 460px;
  cursor: pointer;
`;

const Label = styled.label`
  color: #283334;
  font-family: Commissioner;
  font-size: 16px;
  font-weight: 300;
  width: 500px;
  margin-top: 24px;
  margin-bottom: 5px;
`;

const WarningLabel = styled.div`
padding-top: 5px;
display: flex;
flex-direction: column;
gap: 7px;
`;
const Warning = styled.span`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
  gap: 8px;
`;
const WarningImg = styled.img`
  display: flex;
`;

const MyButton = styled.button`
  padding: 16px 16px;
  margin-top: 24px;
  color: white;
  border:none;
  border-radius: 4px;
  background: #1BB3CD;
  font-size: 16px;
  opacity:  ${({ isDisabled }) => (isDisabled ? '30%' : '100%')};
  cursor:  ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
`;
const VerificationMsg = styled.p`
  margin: auto;
  width: 100%;
  padding-top: 20px;
  text-align: center;
  color: ${({passwordError}) =>(passwordError === true ? 'red' : 'green')};
`;

const ResetPasswordV2 = ({email, otp}) => {
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [verificationResult, setVerificationResult] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const history = useHistory();

  let baseURL = process.env.REACT_APP_API_URL;


  const resetPassword = async (password) => {
    try {
      const response = await fetch(`${baseURL}/api/reset-password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, otp  }),
      });
      if (response.status === 200) {
        console.log(email, password, otp);
        setVerificationResult("Your password has been reset successfully!");
        console.log(response.status);
        setPasswordError(false);

        setTimeout(() => {
          history.push("/")
        }, 3000);
      }
    else {
        setVerificationResult("Something went wrong");
        setPasswordError(true);

      }
    } catch (error) {
      setVerificationResult("An error occurred");
      setPasswordError(true);

    }
  };

    const handleInputChange = (e) => {
    const value = e.target.value;
    setPassword(value);
      if ((value === '') || (value.length < 6)) {
        setShowError(true);
      }
      else {
        setShowError(false);
        setDisabled(false);
      }
    };

    const handleSubmit = (e) =>{
      e.preventDefault();
      resetPassword(password);
    }
  return (
    <ContainerDivvv>
      <Divv>
        <FirstDiv>
          <div>
            <LogoImage>
            <img src="/assets/images/quibble-logo.svg" alt="" width="200px" height="90px"  />
            </LogoImage>
            <div>
              <LeftParagraphText>Reset Password</LeftParagraphText>

              <PasswordForm onSubmit={handleSubmit}>
                <Label>New Password</Label>
                <PasswordInput type={showPassword ? "text" : "password"} value={password} onChange={handleInputChange} isPasswordError={showError} />
                <EyeImg src="/assets/images/eye.svg" alt="" onClick={() =>{setShowPassword(!showPassword)}}/>
                {showError && <WarningLabel>
                  <Warning><img src="/assets/images/error.svg" alt="" />Minimum of 6 characters</Warning>
                  <Warning><WarningImg src="/assets/images/green-check.svg" alt="" />Contains at least one alphanumeric character</Warning>
                </WarningLabel>}
                <MyButton isDisabled={disabled} type="submit">
                  Reset My Password
                </MyButton>
                {verificationResult && <VerificationMsg passwordError ={passwordError}>{verificationResult}</VerificationMsg>}
              </PasswordForm>
            </div>
          </div>

        </FirstDiv>
      </Divv>

      <SecondDiv>
        <Texts>
          <Text1>Take the guesswork out of pricing decisions </Text1>
          <Text2>Increase your revenue by 30% </Text2>
        </Texts>

        <img src="/assets/images/skyscraper.png" alt="" />
      </SecondDiv>


    </ContainerDivvv>
  )
}

export default ResetPasswordV2

ResetPasswordV2.propTypes = {
  email: PropTypes.string.isRequired,
  otp: PropTypes.string.isRequired,
};
