import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/ReusableComponents/Button/ButtonWithFunction';

const PaymentContainer = styled.div`
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  width: 28.5rem;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 300;
  position: relative;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .payment__form-item {
    display: flex;
    margin: 1rem 0;
    span:first-of-type {
      margin-right: auto;
    }
    &.total {
      font-weight: 700;
    }
  }
`;

const Line = styled.div`
  border-bottom: 1px solid #E8E8E8;
  width: 100%;
  margin: 1.5rem 0;
`

const CouponContainer = styled.div`
  display: flex;
`

const CouponInput = styled.input`
  height: 3rem;
  width: 18.75rem;
  border-radius: 4px;
  border: 1px solid #697273;
  background: #FFF;
  margin-right: auto;
  &::placeholder {
    font-size: 1.125rem;
    font-weight: 300;
  }
  &[type=text] {
    padding-left: 1rem;
  }
`
const ButtonContinueCSS = {
  width: '100%',
  height: '3.125rem',
  marginTop: '1.5rem',
  fontWeight: 300,
  fontSize: '1rem',
};

const ButtonApplyCSS = {
  height: '3rem',
  color: '#1BB3CD',
  background: '#FFFFFF',
  fontSize: '1.125rem',
  fontWeight: '300',
  width: '5.25rem',
  border: '1px solid #1BB3CD',
  borderRadius: '4px'
}

const FormItem = (props) => {
  const price = new Intl.NumberFormat('en-US').format(props?.value);
  return (
    <div className={`payment__form-item ${props.total ? 'total' : ''}`}>
      <span>{props.label}</span>
      <span>{props.discount ? '-' : ''}{props.currency}{price}</span>
    </div>
  )
}

export default function Payment(props) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (props.discount) {
      setTotal(props.subtotal * (1 - props.discount));
    } else {
      setTotal(props.subtotal);
    }

  }, [props.subtotal, props.discount]);

  return (
    <PaymentContainer>
      <h1>Summary</h1>
      <p>Billing period: {props?.period}</p>
      <Line />
      <FormItem label={props.plan} currency={props.currency} value={props.amount} />
      <CouponContainer>
        <CouponInput placeholder='Coupon' type='text' />
        <Button style={ButtonApplyCSS} label='Apply' onClickFunc={props.onApplyCoupon} />
      </CouponContainer>
      <Line />
      <FormItem label='Subtotal' currency={props.currency} value={props.subtotal} />
      {props.discount && <FormItem label={`Discount (${props.discount * 100}%)`} currency={props.currency} discount={true} value={props.discount * props.subtotal} />}
      <FormItem label='Total' currency={props.currency} total={true} value={total} />
      <Button disabled={props.disableContinue} style={ButtonContinueCSS} label={"Continue"} onClickFunc={props.onContinue} />
    </PaymentContainer>
  )
}