import React from 'react';
import styled from 'styled-components';
import { LoaderCSS } from './CommonCSS';

const ThumbnailLoaderDiv = styled.div`
  width: 286px;
  height: 100%;
  border-radius: 10px;

  ${LoaderCSS}
`;

const ThumbnailLoader = () => {
  return (
    <ThumbnailLoaderDiv />
  )
}

export default ThumbnailLoader;