import {
  GET_REVENUE_PLANNING,
  SET_REVENUE_PLANNING,
  GET_REVENUE_PLANS,
  CREATE_REVENUE_PLAN,
  SET_REVENUE_PLANS,
  GET_CURRENT_REVENUE_PLAN,
  SET_CURRENT_REVENUE_PLAN,
  GET_CITY_DETAILS,
  GET_CITY_DETAILS_V2,
  SET_CITY_DETAILS,
  SEARCH_CITY,
  SEARCH_CITY_V2,
  GET_PORTFOLIO_GOALS,
  GET_MARKET_GOALS,
  GET_PROPERTY_GOALS,
} from "../../constants/ActionTypes";

export const getRevenuePlanningData = (value) => {
  return {
    type: GET_REVENUE_PLANNING,
    payload: value,
  };
};

export const setRevenuePlanningData = (value) => {
  return {
    type: SET_REVENUE_PLANNING,
    payload: value,
  };
};

export const getRevenuePlans = (value) => {
  return {
    type: GET_REVENUE_PLANS,
    payload: value,
  }
}

export const setRevenuePlans = (value) => {
  return {
    type: SET_REVENUE_PLANS,
    payload: value,
  }
}

export const getCurrentRevenuePlan = (month, year) => {
  return {
    type: GET_CURRENT_REVENUE_PLAN,
    month,
    year,
  }
}

export const setCurrentRevenuePlan = (value) => {
  return {
    type: SET_CURRENT_REVENUE_PLAN,
    payload: value,
  }
}

export const createRevenuePlan = (plan) => {
  return {
    type: CREATE_REVENUE_PLAN,
    payload: plan,
  }
}

export const getCityDetails = (cityId) => {
  return {
    type: GET_CITY_DETAILS,
    cityId,
  }
}

export const getCityDetailsV2 = (cityId) => {
  return {
    type: GET_CITY_DETAILS_V2,
    cityId,
  }
}

export const searchCity = (queryString) => {
  return {
    type: SEARCH_CITY,
    queryString,
  }
}

export const searchCityV2 = (queryString) => {
  return {
    type: SEARCH_CITY_V2,
    queryString,
  }
}

export const clearCity = () => {
  return {
    type: SET_CITY_DETAILS,
    data: {
      _id: '',
      city: '',
      country: '',
      state: '',
      cityId: '',
      countryId: '',
      stateId: '',
      listings: [],
      marketSummaries: [{}, {}, {}],
      totalActiveHosts: null,
      totalActiveListings: null,
    }
  }
}

export const getPortfolioGoals = (value) => {
  return {
    type: GET_PORTFOLIO_GOALS,
    payload: value,
  };
};

export const getMarketGoals = (value) => {
  return {
    type: GET_MARKET_GOALS,
    payload: value,
  };
};

export const getPropertyGoals = (value) => {
  return {
    type: GET_PROPERTY_GOALS,
    payload: value,
  };
};