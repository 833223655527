import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import portfolioAnalytics from "../../services/portfolioAnalytics";
import {
  GET_PORTFOLIO_ANALYTICS,
  SET_PORTFOLIO_ANALYTICS,
} from "constants/ActionTypes";


function* getPortfolioAnalytics({ metric, year, view, sameStore, property, viewType }) {
  try {
    const params = {
      year,
      view,
      sameStore,
      viewType,
    };

    if (property) params.property = property;

    yield put({ type: SET_PORTFOLIO_ANALYTICS, metric, data: [] });
    const result = yield call(portfolioAnalytics.getMetric, metric, { params });
    yield put({ type: SET_PORTFOLIO_ANALYTICS, metric, data: result });
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetPortfolioAnalytics() {
  yield takeEvery(GET_PORTFOLIO_ANALYTICS, getPortfolioAnalytics);
}

/** Export all */
export default function* rootSaga() {
  yield all([
    fork(watchGetPortfolioAnalytics),
  ]);
}
