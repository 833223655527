import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { CardCSS } from "../../../components/CommonCSS";
import { BodyTitle } from "components/ReusableComponents/Text/Text";
import { Table, Tag } from 'antd';
import moment from "moment";

import { getTransactions } from 'appRedux/actions/Subscription';

const PaymentHistoryBody = styled.div`
  ${CardCSS}
  height: 400px;
  margin: 24px 0 12px 0;

  .payment-history__table {
    padding-top: 2rem;
    color: #283334;
    font-weight: 300;

    thead > tr:first-child th:first-child {
      border-radius: 0;
    }

    thead > tr > th {
      background: #FFFF;
      border-top: 1px solid #E8E8E8;
      border-radius: 0;
      text-transform: uppercase;

      ::before {
        display: none;
      }
    }

    li.ant-pagination-prev, li.ant-pagination-next {
      button {
        border: 0;
      }
      &:hover {
        button {
          border-color: #1BB3CD;
          color: #1BB3CD;
        }
      }
    }

    li.ant-pagination-item {
      background: #E8E8E8;
      color: #697273;
      &:hover {
        border-color: #1BB3CD;
      }
    }

    li.ant-pagination-item-active {
      background: #1BB3CD;
      border-color: #1BB3CD;
      a {
        color: #FFF;
      }
    }
  }
`;

const PaymentHistory = () => {
  const dispatch = useDispatch();

  const {
    transactions,
  } = useSelector((state) => state.subscription);

  // On component mount
  useEffect(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (timestamp) => <span>{moment.unix(timestamp).format('MMMM DD, YYYY')}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, _) => <span>{USDollar.format(amount / 100)}</span>, // USD for now
      // render: (amount, row) => <span>{row.currency}{amount/100}</span>, // use currency in the future
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment_method',
      key: 'payment_method',
      render: (text) => <span>{text.brand?.toUpperCase()} ending in {text.last4}</span>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        if (status === 'succeeded') return (<Tag color='green'>Paid</Tag>)
        else if (status === 'canceled') return (<Tag color='red'>{status}</Tag>)
        else return (<Tag color='yellow'>{status}</Tag>)
      },
    },
  ]

  return (
    <PaymentHistoryBody>
      <BodyTitle>
        Payment History
      </BodyTitle>
      <Table className="payment-history__table" pagination={{ pageSize: 3 }} dataSource={transactions} columns={columns} rowKey="date" />
    </PaymentHistoryBody>
  )
}

export default PaymentHistory;