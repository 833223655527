import React, { useCallback, useMemo, useState } from "react";
import styled from 'styled-components';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import RenderBar from "../marketAnalytics/sharedComponents/MarketPropertyTable/RenderBar";
import { formatAmount } from "../../util/formatAmount";
import { getData } from "../../util/localStorageHelper";

const RatesAdvisorBody = styled.div`
  width: 100%;
  background: #fff;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  opacity: 1;

  #quibbleTable {
    padding: 0 16px;
  }
`;

const NameLink = styled(BodyText)`
  margin: 0px;

  &:hover {
    color: ${({ theme }) => theme.quibbleBlue };
  }
`;

const RatesAdvisorTable = ({ portfolioData, isFetchingPortfolio }) => {

  const RatesAdvisorColumns = [
    {
      label: 'Property Name',
      width: '180px',
      align: 'left',
      customRender: (data) => <NameLink>{data?.name}</NameLink>,
    },
    {
      label: 'Occupancy',
      width: '150px',
      align: 'left',
      customRender: (data) => (
        <RenderBar
          current={data?.occupancy}
          type="occupancy"
          withoutForecast={true}
        />
      ),
    },
    {
      label: 'Market Occ',
      width: '150px',
      align: 'left',
      customRender: (data) => (
        <RenderBar
          current={data?.marketOccupancy}
          type="occupancy"
          withoutForecast={true}
        />
      ),
    },
    {
      label: 'ADR',
      align: 'right',
      customRender: (data) => `$${formatAmount(data?.adr)}`,
    },
    {
      label: 'Last ADR',
      align: 'right',
      customRender: (data) => `$${formatAmount(data?.lastAdr)}`,
    },
    {
      label: 'Revenue',
      align: 'right',
      customRender: (data) => `$${formatAmount(data?.revenue)}`,
    },
  ];

  return (
    <RatesAdvisorBody>
      <QuibbleTable
        columns={RatesAdvisorColumns}
        data={portfolioData}
        expandable={false}
        pagination={true}
        paginationSize={10}
        isLoading={isFetchingPortfolio}
      />
    </RatesAdvisorBody>
  );
};

export default RatesAdvisorTable;
