import service from "../util/APIUtility";

const portfolioAnalyticsService = {
  getMetric: async (path, data) => {
    let metric = path;
    if (path === 'revenue') {
      metric = 'occadr';
    }

    const response = await service.get(`/api/portfolio-analytics/${metric}`, data);
    return response.data.data;
  },
};

export default portfolioAnalyticsService;
