import React from 'react';
import styled from 'styled-components';

const ChartHeaderContainer = styled.div`
  border: 1px solid #D9D9D9;
  border-bottom: 0;
  display: flex;
  margin: auto;
  margin-bottom: -4.25rem;
  position: relative;
  width: ${props => props.columns === 2 ? 'calc(100% - 169.5px)' : 'calc(90% - 170px)'};
  z-index: 1;
`;

const ChartLabelGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 70%;
  padding-left: 1rem;
`;


const ChartLabel = styled.div`
  display: flex;
  padding: 0 8px;
  span {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 4px;
  }
  img {
    justify-content: center;
  }
`;

const ChartButtonGroup = styled.div`
  margin-left: auto;
  padding-right: 1rem;
`

const ChartHeaderButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0.5rem 1rem;
  color: ${ props => props.disabled ? '#9fa19f' : '#283334' };
  &:hover {
    cursor: pointer;
    color: ${ props => props.disabled ? '#9fa19f' : '#00A4E6' };
  }

  &.active {
    color: ${ props => props.disabled ? '#9fa19f' : '#00A4E6'};
    font-weight: 500;
  };
`;

const ChartHeader = (props) => {
  const { view, onChangeView, labels, columns, hideDaysFilter} = props;

  const PUBLIC_IMAGES = process.env.PUBLIC_URL + '/assets/images';

  const changeView = (e) => {
    const dataView = e.target.getAttribute('data-view');
    onChangeView(dataView);
  }

  const setClassName = (vw) => {
    if (vw === view) return 'active'; // return classes
    else return '';
  }

  return (
    <ChartHeaderContainer columns={columns}>
      <ChartLabelGroup>
        {labels?.length > 0 && labels.map((label, ix) => {
          return (
            <ChartLabel key={ix}>
              <img src={`${PUBLIC_IMAGES}/${label.image}.svg`} alt={label.image}/>
              <span>{label.label}</span>
            </ChartLabel>
          )
        })}
      </ChartLabelGroup>
      <ChartButtonGroup>
        {
          hideDaysFilter !== true &&
          (<ChartHeaderButton className={setClassName('daily')} data-view="daily" onClick={changeView}>D</ChartHeaderButton>)
        }

        <ChartHeaderButton className={setClassName('weekly')} data-view="weekly" onClick={changeView}>W</ChartHeaderButton>
        <ChartHeaderButton className={setClassName('monthly')} data-view="monthly" onClick={changeView}>M</ChartHeaderButton>
      </ChartButtonGroup>
    </ChartHeaderContainer>
  )
}
export default ChartHeader;
