import React, {memo} from 'react';
import CircularProgress from '../CircularProgress';


const AuthLoader = ({loader}) => {
  return (
    <>
    { loader ? <CircularProgress className="app-loader-view-small" /> : null}
    </>
  )
}


export default memo(AuthLoader);
