import React from 'react';
import styled from 'styled-components';
import Reservations from './subComponents/ReservationsandBookings/Reservations';
import LatestBookings from './subComponents/ReservationsandBookings/LatestBookings';



const ReservationsandBookingsDiv = styled.div`
  width: 100%;
  max-width: 1800px;

  height: 550px;
  min-height: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const ReservationsandBookings = ({pieData, recentBookingData, isLoading, monthYear}) => {
  return (
    <ReservationsandBookingsDiv>
      <Reservations reservationPerSource={pieData} isLoading={isLoading} monthYear={monthYear}/>
      <LatestBookings recentBookingData={recentBookingData} isLoading={isLoading}/>
    </ReservationsandBookingsDiv>
  )
}

export default ReservationsandBookings;