import React, { useState, useRef } from 'react';
import {
  //  useDispatch,
  useSelector
} from "react-redux";

// import { downloadOwnerReport } from '../../../../appRedux/actions';
import {
  Checkbox,
  Collapse,
  Form,
  Modal,
  Radio, Switch,
} from 'antd';
import { FULL_MONTH_NAME } from "constants/GlobalConstant";
import { currencyFormatter } from "../../../../util/helper";
import { cloneDeep } from 'lodash';

import { useReactToPrint } from 'react-to-print';
import OwnerReportPDF from './OwnerReportPDF';
import { useData } from '../../../../util/DataStore/DataContext';
import { ChangeViewButton } from '../../../../components/ReusableComponents/Button/Button';
import styled from 'styled-components';
import { getData } from '../../../../util/localStorageHelper';
import filters from '../../../../components/ReusableComponents/Filters/Filters';
import { getQuarter } from './OwnerReportPDF/OwnerReportPDF';

  export const ViewTitle = {
  rmView:"RM View",
  checkInView: "Check In",
  checkOutView: "Check Out",
}

const { Panel } = Collapse;

function OwnerReportModal(props) {
  // Dispatch & Selectors
  // const dispatch = useDispatch();
  const { generatingPropertyReport } = useSelector(({ property }) => property);
  let persistedFilter = getData("filters") === null ? filters : getData("filters");
  const propertyDetail = props?.propertyDetail || {}
  const userDetails = useSelector(({ users: { authUserDetails } }) => authUserDetails);
  const { state, updateData } = useData()
  //ref for the container
  const componentRef = useRef();

  const handleUpdateQuarter = (e) => {
    e.preventDefault()
    updateData({
      type: "SET_DATA",
      payload: { key: "PROPERTY_DATA", value: { quarter: e.target.value } }
    });

  }
  // Constants
  const KEY_METRICS = [
    {
      checked: true,
      value: "totalRevenue",
      label: "Total Revenue",
      isCurrency: true,
    },
    {
      checked: true,
      value: "totalRent",
      label: "Total Rent",
      isCurrency: true,
    },
    {
      checked: true,
      value: "ancillaryRevenue",
      label: "Ancillary Revenue",
      isCurrency: true,
    },
    {
      checked: true,
      value: "adr",
      label: "Average Daily Rate (ADR)",
      isCurrency: true,
    },
    {
      checked: true,
      value: "reservation",
      label: "Reservations",
    },
    {
      checked: true,
      value: "bookedNights",
      label: "Booked Nights",
    },
    {
      checked: true,
      value: "ownerBooking",
      label: "Owner Reservations",
    },
    {
      checked: true,
      value: "blockedDay",
      label: "Blocked Days",
    },
    {
      checked: true,
      value: "alos",
      label: "Average Length of Stay",
    },
  ]

  // const CHARTS = [
  //     // {
  //     //     checked: true,
  //     //     value: "dowRateAnalysis",
  //     //     label: "Day of Week Rate Analysis",
  //     // },
  //     {
  //         checked: true,
  //         value: "performanceSummary",
  //         label: "Performance Summary",
  //     },
  //     {
  //       checked: true,
  //       value: "totalRevenueByMonth",
  //       label: "Total Revenue By Month",
  //   },
  // ]

  // Default State
  const [keyMetrics, setKeyMetrics] = useState(KEY_METRICS);
  const [keyMetricsByMonth, setKeyMetricsByMonth] = useState(KEY_METRICS);
  // const [charts, setCharts] = useState(CHARTS);

  // Action methods
  const toggleKeyMetrics = (e, key, isByMonth) => {

    let newKeyMetrics = null;

    if (!isByMonth) { //if the table is by month, it will set the key metrics on the right table
      newKeyMetrics = keyMetrics;
    } else {
      newKeyMetrics = keyMetricsByMonth;
    }

    const updatedKeyMetrics = cloneDeep(newKeyMetrics);
    const index = updatedKeyMetrics.findIndex(km => km.value === key);

    updatedKeyMetrics[index].checked = e.target.checked;

    isByMonth ? setKeyMetricsByMonth(updatedKeyMetrics) : setKeyMetrics(updatedKeyMetrics);
  }

  // const toggleCharts = (e, key) => {
  //     const updatedCharts = cloneDeep(charts);
  //     const index = updatedCharts.findIndex(c => c.value === key);

  //     updatedCharts[index].checked = e.target.checked;
  //     setCharts(updatedCharts);
  // }

  // const submit = (e) => {
  //     e.preventDefault();
  //     if (generatingPropertyReport) return;
  //     dispatch(downloadOwnerReport(setPayload()))
  //     props.onSubmit();
  // }

  const closeModal = () => {
    if (generatingPropertyReport) return
     props.onCloseModal();
  }

  // Setters
  /**
   *
   * Set key metrics table values
   * @param {Object} km
   * @param {Array} fields
   * @returns {Object} KeyMetrics
   */
  function setMetrics(km, fields) {

    const newKm = { ...km, adr: km?.totalRent / km?.bookedNights };

    const currencyFields = ["adr", "ancillaryRevenue", "totalRevenue", "totalRent"];

    let kmClone = cloneDeep(newKm)
    Object.keys(kmClone).forEach((key) => {
      if (!fields.includes(key)) delete kmClone[key];
      else if (currencyFields.includes(key)) kmClone[key] = currencyFormatter(kmClone[key]);
    })

    return kmClone;
  }

  /**
   *
   * Set request payload
   * @returns {Object} Property report request payload
   * @private
   */

  function setPayload() {
    const metricsExport = keyMetrics.filter(km => km.checked).map(km => km.value);
    const metricsByMonthExport = keyMetricsByMonth.filter(km => km.checked).map(km => km.value);

    // const summarySVG = document.querySelector('div.report__charts div.composed-chart--summary svg.recharts-surface').outerHTML;
    // const totalRevenueByMonthSVG = document.querySelector('div.report__charts div.composed-chart--revenue svg.recharts-surface').outerHTML;
    // const dayOfWeekRateAnalysisSVG = document.querySelector('div.report__charts div.bar-chart--dowa svg.recharts-surface').outerHTML;

    let payload = {
      brandLogoUrl: userDetails.brand_logo,
      companyName: userDetails.company,
      // charts: {
      //     // dayOfWeekRateAnalysisSVG: charts[0].checked ? dayOfWeekRateAnalysisSVG : null,
      //     summarySVG: charts[0].checked ? summarySVG : null,
      //     totalRevenueByMonthSVG: charts[0].checked ? totalRevenueByMonthSVG : null,
      // },
      currentMonthCurrentYearADR:  currencyFormatter(propertyDetail.currentMonthYearAdr),
      currentMonthCurrentYearOccupancy: propertyDetail.currentMonthCurrentYearOccupancy,
      currentMonthCurrentYearTotalRent: currencyFormatter(propertyDetail.currentMonthCurrentYearTotalRent),
      keyMetrics: {
        currentYear: setMetrics(propertyDetail.keyMetricTableCurrentYear, metricsExport),
        last365: setMetrics(propertyDetail.keyMetricTableLast365Days, metricsExport),
        lastYear: setMetrics(propertyDetail.keyMetricTableLastYear, metricsExport),
      },
      keyMetricsByMonth: {
        currentMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonth, metricsByMonthExport),
        lastMonth: setMetrics(propertyDetail.keyMetricTableCurrentMonthLastYear, metricsByMonthExport),
      },
      month: FULL_MONTH_NAME[props.month - 1],
      property: {
        name: propertyDetail.result?.name,
        city: propertyDetail.result?.city,
        imageUrl: propertyDetail.result?.image
      },
      token: localStorage.getItem('token'),
      year: props.year,
    }
    return payload;
  }

  const filterTitle = getQuarter(state?.PROPERTY_DATA?.quarter, `${ FULL_MONTH_NAME[props.month - 1] }_${props.year}`, props.year,state?.PROPERTY_DATA?.quarter === 5)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => closeModal(),
    documentTitle:  `${propertyDetail?.result?.name}_${filterTitle}`?.replace?.(/\s/g, '_') || "report"
  });

  const handleUpdateFilter = () => {
    props.updateFilter()
  }

  const handleAddPmsCharge = (checked) => {
    updateData({
      type: "SET_DATA",
      payload: {
        key: "PROPERTY_DATA",
        value: { quarter: Number(state?.PROPERTY_DATA?.quarter || 0), remove_pms_charge: checked }
      }
    });
    handleUpdateFilter();
  }
  return (
    <Modal destroyOnClose  okButtonProps={ { disabled: props.error || props.loading, loading: props.loading } }
           open={ props.isExportModalVisible } title="Select Information to Download" onCancel={ closeModal }
           bodyStyle={ { padding: 0, maxHeight: "20rem", overflow: "scroll" } } onOk={ handlePrint }>
      <Form>
        <div style={ { padding: "1.5rem" } }>
          <p>View</p>
          <Radio.Group name="radiogroup" defaultValue={ 1 } style={ { fontWeight: 'lighter' } }>
            <Radio value={ 1 }>Selected View - ({ViewTitle[persistedFilter.viewType || "rmView"]})</Radio>
          </Radio.Group>
          <Switch unCheckedChildren="Include OTA Charges" checkedChildren="Remove OTA Charges"  defaultChecked={state?.PROPERTY_DATA?.remove_pms_charge} onChange={handleAddPmsCharge}  />
          <p style={ { paddingTop: '1rem' } }>Month & Year</p>
          <Radio.Group          optionType="button"  onChange={ handleUpdateQuarter } value={ Number(state?.PROPERTY_DATA?.quarter || 0) }
                       name="radiogroup"
                       defaultValue={ 0 }
                       style={ { fontWeight: 'lighter' } }>
            <Radio value={ 0 }> { FULL_MONTH_NAME[props.month - 1] } {props.year}</Radio>
            <Radio value={ 5 }> YEAR {props.year}</Radio>
            <Radio value={ 1 }>1st Qtr {props.year}</Radio>
            <Radio value={ 2 }>2nd Qtr {props.year}</Radio>
            <Radio value={ 3 }>3rd Qtr {props.year}</Radio>
            <Radio value={ 4 }>4th Qtr {props.year}</Radio>
          </Radio.Group>
        </div>
        <FilterContainer>
          <ChangeViewButton loading={props.loading} disabled={props.loading}  onClick={ handleUpdateFilter }>
            { !props.error ? "Apply filter" :  "Error Getting Report Retry"}
          </ChangeViewButton>
        </FilterContainer>
        <Collapse>
          <Panel header="Key Metrics by Year" key="1">
            { keyMetrics.map(km => {
              return (
                <Form.Item key={ km.value } style={ { paddingLeft: '1.5rem', marginBottom: 0, fontWeight: "lighter" } }>
                  <Checkbox defaultChecked checked={ km.checked } onChange={ (e) => {
                    toggleKeyMetrics(e, km.value, false)
                  } } data-metric={ km.value }>
                    { km.label }
                  </Checkbox>
                </Form.Item>
              )
            }) }
          </Panel>
          <Panel header="Key Metrics by Month" key="2">
            { keyMetricsByMonth.map(km => {
              return (
                <Form.Item key={ km.value } style={ { paddingLeft: '1.5rem', marginBottom: 0, fontWeight: "lighter" } }>
                  <Checkbox defaultChecked checked={ km.checked } onChange={ (e) => {
                    toggleKeyMetrics(e, km.value, true)
                  } } data-metric={ km.value }>
                    { km.label }
                  </Checkbox>
                </Form.Item>
              )
            }) }
          </Panel>
          {/* <Panel header="Charts" key="3">
                        {charts.map(chart => {
                            return (
                                <Form.Item key={chart.value} style={{ paddingLeft: '1.5rem', marginBottom: 0, fontWeight: "lighter" }}>
                                    <Checkbox defaultChecked checked={chart.checked} onChange={(e) => { toggleCharts(e, chart.value) }} data-metric={chart.value}>
                                        {chart.label}
                                    </Checkbox>
                                </Form.Item>
                            )
                        })}
                    </Panel> */ }
        </Collapse>
      </Form>
      <div className="report-container">
        <OwnerReportPDF year={props.year} ref={ componentRef } setPayload={ setPayload }/>
      </div>
    </Modal>
  )
}

const FilterContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
`

export default OwnerReportModal;

