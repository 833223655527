import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';

import ratings from '../../../../../assets/images/markethunter/ratings.svg'
import MHCardLoader from '../../../../../components/ReusableComponents/SkeletonLoader/Components/MHCardLoader';

const LeadingHostsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: #283334;
  font-family: Commissioner;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const GenericFlexContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
`;

const col = [
  {
    label: '',
    align: 'left',
    width: '200px',
    dataIndex: 'name',
  },
  {
    label: 'Listings',
    align: 'center',
    dataIndex: 'listings',
  },
  {
    label: 'Reviews',
    align: 'center',
    dataIndex: 'reviews',
  },
  {
    label: 'Ratings',
    align: 'center',
    dataIndex: 'ratings',
    customRender: (data) => <GenericFlexContainer><img src={ratings} alt="ratings" />&nbsp;{data.toFixed(2)}</GenericFlexContainer>
  },
]

const LeadingHosts = (props) => {

  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)

    if (props.data) {
      const rawData = props.data;
      setChartData(rawData)
      setIsLoading(false);
    }
  }, [props.data]);

  return (
    <>
    {isLoading ? <MHCardLoader /> :
      <LeadingHostsContainer>
        <TitleContainer>
          <Title>Leading Hosts</Title>
        </TitleContainer>
        <BodyContainer>
          <QuibbleTable columns={col} data={chartData && chartData}/>
        </BodyContainer>
      </LeadingHostsContainer>
    }
    </>
  );
}

export default LeadingHosts;