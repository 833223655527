import React, { Fragment, useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { BodyTitle, } from "components/ReusableComponents/Text/Text";
import {Radio} from 'antd';
import { BodyText } from "components/ReusableComponents/Text/Text";
import SelectCalendar from 'components/ReusableComponents/Calendar/SelectCalendar';
import { currentDate } from "constants/GlobalConstant";
import SelectDay from "./SelectDay";
import { OptimizeBody } from "../../CommonCSS";
import Multiplier from "./Multiplier";
import Minmax from "./MinMax";
import { PriceAdjustContext } from "../../../../PriceAdjustment";
import FarOut from "./FarOut";

const {Group} = Radio;
 

const SelectApplyTo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RadioGroup = styled(Group)`
  width: 100%;
  display: flex;
  margin: 12px 0;
  flex-direction: column;
  gap: 12px;
  
`;

const ClearText = styled.button`
  color: ${({theme}) => theme.bodyFg};
  border: none; 
  outline: none;
  background: none;
  cursor: pointer;
  font-family: Commissioner;
  font-weight: 300;
  font-size: ${({theme}) => theme.s3};
  margin: 0 0 4px 4px;
  align-self: flex-start;
  transition: all 0.1s linear;

  &:hover {
    transform: scale(1.05);
  }
`;

const BorderDiv = styled.span`
  width: 100%;
  border-top: 1px solid #D9D9D9;
  height: 0px;
  margin: 32px 0 24px 0;
`;

const notIncludedDays = (calendarArray, monthYear) => {
    return calendarArray.filter((data) => {
      const dataSplit = data.split('-');
      return !(parseInt(dataSplit[1]) !==  monthYear.getMonth() + 1 ||  new Date(data) < new Date(currentDate));
    });
};

const PriceOptimize = ({selectedDates, monthYear, setMonthYear, setSelectedDaysFunc, onAppyChanges, calendarArray, isDisabled}) => {

  const [applyValue, setApplyValue] = useState('select');
  const {adjustmentMenu, isProperty, setPropertyMonthYear} = useContext(PriceAdjustContext);


  useEffect(() => {
    if (applyValue === 'all') {
      setSelectedDaysFunc(notIncludedDays(calendarArray, monthYear));
      return;
    }
  }, [monthYear, applyValue, calendarArray, setSelectedDaysFunc]);

  const setMonthYearVal = useCallback((value) => {
    if (isProperty) {
      setPropertyMonthYear(value);
      return;
    };
    
    setMonthYear(value);
  }, [setMonthYear, isProperty, setPropertyMonthYear]);

  const onApplyChange = (e) => {
    const radioValue = e.target.value;
    setApplyValue(radioValue);

    if (radioValue === "all") {
      setSelectedDaysFunc(notIncludedDays(calendarArray, monthYear));
      return;
    }

    if (radioValue === "select" || radioValue === "days") {
      setSelectedDaysFunc([]);
      return;
    }
  };

  const onClearClick = () => {
    setSelectedDaysFunc([]);
  }


  return (
    <OptimizeBody>
      <SelectApplyTo>
        <BodyTitle> 
          Apply to
        </BodyTitle>
        <RadioGroup onChange={onApplyChange} value={applyValue}>
          <Radio value={'select'}> <BodyText>Select Dates</BodyText></Radio>
          {applyValue === 'select' && 
          <Fragment>
            <SelectCalendar disableFunc={isDisabled} setSelectedDaysFunc={setSelectedDaysFunc} selectedDates={selectedDates} monthYear={monthYear} setMonthYear={setMonthYearVal} showStrikeThrough={adjustmentMenu === "property"}/>
            <ClearText onClick={onClearClick}>Clear All</ClearText>
          </Fragment>}
          <Radio value={'days'}> <BodyText>Days of Week</BodyText> </Radio>
          {applyValue === 'days' && 
          <Fragment>
            <SelectDay  monthYear={monthYear} setSelectedDaysFunc={setSelectedDaysFunc} selectedDates={selectedDates}/> 
            <ClearText onClick={onClearClick}>Clear All</ClearText>`
          </Fragment>
          }
          <Radio value={'all'}> <BodyText>All Days</BodyText> </Radio>
        </RadioGroup>
      </SelectApplyTo>
      <Multiplier onAppyChanges={onAppyChanges} />
      {adjustmentMenu === "property" && <><BorderDiv /> <Minmax /> </>}
      {adjustmentMenu === "property" && <><BorderDiv /> <FarOut /> </>}
    </OptimizeBody>
  )
};

export default PriceOptimize;