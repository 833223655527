import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SHOW_ERROR_MESSAGES,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  RESEND_EMAIL,
  SELECTED_PMS,
  SIGNUP_USER_SUCCESS,
  SELECTED_PMS_SUCCESS,
  SIGNUP_GOOGLE_USER,
  SIGNUP_GOOGLE_USER_SUCCESS,
  API_KEY_PMS_SUCCESS,

} from "constants/ActionTypes";

/*** user signup action */
export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userResendEmail = (value) => {
  return {
    type: RESEND_EMAIL,
    payload: value
  };
};

/*** user update PMS action */
export const updateUserPMS = (value) => {
  return {
    type: SELECTED_PMS,
    payload: value
  };
};
export const updateUserPMSSuccess = (authUser) => {
  return {
    type: SELECTED_PMS_SUCCESS,
    payload: authUser
  };
};

export const updateUserPMSSuccessApiKey = (authUser) => {
  return {
    type: API_KEY_PMS_SUCCESS,
    payload: authUser
  };
};


/*** user signin action */
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};

/*** user signout action */
export const signOutUser = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showErrorMessages = (messages) => {
  return {
    type: SHOW_ERROR_MESSAGES,
    payload: messages
  };
};

/*** User signup with google  */
export const userGoogleSignUp = (authUser) => {
  return {
    type: SIGNUP_GOOGLE_USER,
    payload: authUser
  };
};
export const userGoogleSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};

/*** User signin with google  */
export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

