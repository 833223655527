import React from 'react';
import styled, { css } from 'styled-components';
import ButtonWithFunction from '../Button/ButtonWithFunction';


const SwitchButtonBody = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
`;

const SwitchButtonCss = css`
  width: 40px;
  height: 22px;
  background: ${({theme}) => theme.white};
  color: ${({theme}) => theme.activeBlue}; 
  font-size: 14px;
  font-weight: 300;
  box-shadow: none;
  border: 1px solid ${({theme}) => theme.activeBlue}; 
  border-radius: 4px 0 0 4px;

  &:hover, &.active {
    color: ${({theme}) => theme.white};
    background: ${({theme}) => theme.activeBlue}; 
  }
`;

const SwitchButtonSecond = css`
  ${SwitchButtonCss}
  border-radius: 0 4px 4px 0;
`;

const SwitchButton = ({value=false, property, onSwitchFunc}) => {
  
  const onSwitchCLick = (switchValue) => {
    if(!onSwitchFunc) return;
    onSwitchFunc(property, switchValue);
  };

  return (
    <SwitchButtonBody>
      <ButtonWithFunction className={value && 'active'} value={true} ButtonStyle={SwitchButtonCss} label="YES" onClickFunc={onSwitchCLick}/>
      <ButtonWithFunction className={!value && 'active'} value={false} ButtonStyle={SwitchButtonSecond} label="NO" onClickFunc={onSwitchCLick}/>
    </SwitchButtonBody>
  )
}

export default SwitchButton;