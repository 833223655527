import React, {useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import { Input, Select } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import { message } from 'antd';
import Upload from 'antd/lib/upload/Upload';
import Union from '../../../../assets/images/Union.svg'
import { events as eventsService } from '../../../../services/events';
import CheckBox from '../../../../components/ReusableComponents/Checkbox/CheckBox'


const { RangePicker } = DatePicker;
const { Option } = Select;

const TotalDiv = styled.div`
  background: white;
  margin: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

const Details = styled.p`
  font-size: 21px;
`;

const Left = styled.div`
  width: 60%;
`;

const Form = styled.form`

`;

const FormLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  font-size: 16px;
  gap:8px;
`;

const Settings = styled.p`
  font-size: 21px;
  padding-top: 30px;
`;

const Right = styled.div`
  width: 30%;
`;

const UploadFrame = styled.div`
  // padding: 10px 10px 0px 10px;
  padding: ${({ imageUrl }) => (imageUrl? '0px' : '10px 10px 0px 10px')};
  ${({ theme }) => ` { border: 1px solid ${theme.quibbleHoverGrey} }`};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 332px;
`;

const Frame = styled.div`
  ${({ theme }) => ` { background: ${theme.quibbleHoverGrey} }`};
  height: 254px;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const ActionButtons = styled.div`
  display: flex;
  gap: 20px;
  padding: 110px 0px 0px 0px;

`;

const Button = styled.button`
  padding: 16px 70px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.quibbleBlue};
  color: ${({ theme, blue }) => (blue ? 'white' : theme.quibbleBlue)};
  background-color: ${({ theme, blue }) => (blue ? theme.quibbleBlue : 'white')};
  border-radius: 4px;
  cursor: pointer;
`;

const StyledSelect = styled(Select)`
  .ant-select-clear {
    visibility: visible !important;
    opacity: 1 !important;
    right: 10px; /* Adjust as needed */
    top: 45%;
  }
`;


const CheckLabelDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

`;


const AddEvent = () => {
  const markets = useSelector((state) => state.users.authUserDetails.cityList);
  const [imageUrl, setImageUrl] = useState('');
  const [fileList, setFileList] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [minStayCheck, setMinStayCheck] = useState(false);

  const history = useHistory();

  const [formData, setFormData] = useState({
    name: '',
    type: [],
    startDate: '',
    endDate: '',
    imgUrl: '',
    markets: [],
    minStay: 2,
    isRecurring: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const beforeUpload = (file) => {
    setFileList([file]);
    setImageUrl(URL.createObjectURL(file)); 
    return false;
  };


  const handleUploadChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      setImageUrl(info);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleRemoveImage = (e) => {
    e.stopPropagation();
    setFileList([]);
    setImageUrl('');
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates.map(date => date.toDate());

    const formattedStartDate = moment(start).format('YYYY-MM-DD');
    const formattedEndDate = moment(end).format('YYYY-MM-DD');

    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.type.length || !formData.markets.length || !formData.startDate || !formData.endDate) {
      message.error('Please fill all required fields.');
      return;
    }

    setIsLoading(true); 
    message.loading('Adding event...', 5);


    const body = {
      name: formData.name,
      type: formData.type,
      markets: formData.markets,
      startDate: formData.startDate,
      endDate: formData.endDate,
      minStay: formData.minStay,
      isRecurring: formData.isRecurring,
      imgUrl: imageUrl,
    };

    if (!minStayCheck) {
      delete body.minStay;
    }
    
    try {
      const event = await eventsService.createEvent({ body });

      if (event) {
        message.destroy();
        message.success('Event created successfully.');

        if (fileList.length > 0) {
          const uploadResponse = await eventsService.uploadEventImage({ eventId: event._id, file: fileList[0] });

          if (uploadResponse) {
            message.success('Image uploaded successfully.');
          } else {
            throw new Error('Image upload failed');
          }
        }

        setFormData({
          name: '',
          type: [],
          startDate: '',
          endDate: '',
          imgUrl: '',
          markets: [],
          minStay: 2,
          isRecurring: false,
        });
        
        setSelectedMarkets([]);
        setFileList([]);
        setImageUrl('');

        history.push('/revenue/events-pricing');
      } else {
        message.error('Failed to create event.');
      }
    } catch (error) {
      message.error('An error occurred while creating the event.');
      console.error('Error during form submission', error);
    } finally {
      setIsLoading(false); 
    }
  };

  const cancel = () => {
    history.push('/revenue/events-pricing');
  }

  const handleSelectMarkets = (selected) => {
    let sMarkets = selected;
    if (selected.includes('All')) {
      sMarkets = markets?.map(m => m.city);
    }

    setSelectedMarkets(sMarkets);
    formData.markets = sMarkets;
  }

  const onMinstayCheckChange = (checked) => {
    setMinStayCheck(checked);
  };

  const MinStayChangeNegative = () =>{
    let newMinStay = formData.minStay - 1;
    if (newMinStay < 0) newMinStay = 0;
    setFormData((prev) => ({...prev, minStay: newMinStay}))
  }

  const MinStayChangePositive = () => {
    let newMinStay = formData.minStay + 1;
    setFormData((prev) => ({...prev, minStay: newMinStay}))
  };


  return (
    <TotalDiv>
      <Left>
        <Details>Details</Details>
        <Form onSubmit={handleSubmit}>
          <FormLabel>
            <label>Event/Holiday Name <span style={{color:'red'}}>*</span></label>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} style={{ height: '40px' }} required />
          </FormLabel>

          <FormLabel>
            <label>Type <span style={{color:'red'}}>*</span></label>
            <Select 
              onChange={(selectedValues) => formData.type = selectedValues} 
              style={{ height: '40px' }} >
              <Option value="event">Event</Option>
              <Option value="holiday">Holiday</Option>
            </Select>
          </FormLabel>

          <FormLabel>
            <label>Market <span style={{color:'red'}}>*</span></label>
            <StyledSelect
              mode="multiple"
              onChange={handleSelectMarkets}
              style={{ height: '40px' }}
              value={selectedMarkets}
              allowClear={true}
            >
              <Option key="All">All</Option>
              {
                markets && markets.map((market) => (
                  <Option
                    key={market.city}
                  >{market.city}</Option>
                ))
              }
            </StyledSelect>
          </FormLabel>

          <Settings>Settings</Settings>

          <FormLabel>
            <CheckLabelDiv>
              <CheckBox checked={minStayCheck} onCheckBoxChange={onMinstayCheckChange}/>
              <label>Minimum Stay</label>
            </CheckLabelDiv>
            <Input type="text" name="minStay" value={formData?.minStay} 
            disabled={!minStayCheck}
            prefix={<MinusCircleOutlined onClick={MinStayChangeNegative} style={{paddingRight: '110px'}}/>} 
            suffix={<PlusCircleOutlined onClick={MinStayChangePositive} />} 
            onChange={() => { }} style={{ width: '300px', height: '40px', }} />

          </FormLabel>

          <FormLabel>
            <label>Date Range <span style={{color:'red'}}>*</span></label>
            <RangePicker style={{ width: '300px' }} onChange={handleDateRangeChange}/>
          </FormLabel>

          {/* <RecurringEvent>
            Recurring event
            <Switch onChange={(checked) => formData.isRecurring = checked} disabled={true} />
          </RecurringEvent>

          <Conditions>
            <LeftCondition>
              <TopConditions>Conditions</TopConditions>
              <BottomConditions>
                The multiplier will be applied to increase the price but only if certain conditions are met
              </BottomConditions>
            </LeftCondition>

            <Switch disabled />

          </Conditions> */}

          <ActionButtons>
            <Button disabled={isLoading} type="reset" onClick={cancel}>Cancel</Button>
            <Button disabled={isLoading} type="submit" blue>Save</Button>
          </ActionButtons>
        </Form>
      </Left>

      <Right>

        <UploadFrame imageUrl={imageUrl}>
          <Frame >
            {imageUrl ? (
              <img src={imageUrl} alt="Uploaded" style={{ width: '100%', height: '100%' }} />
            ) : (
              <img src={Union} alt="Default" style={{ width: '50px', height: '50px' }} />
            )}
          </Frame>
          <Upload
            listType="picture"
            beforeUpload={beforeUpload}
            onChange={handleUploadChange}
            fileList={fileList}
            onRemove={handleRemoveImage}
            showUploadList={false}
          >
      {fileList.length === 0 ? (
        <p style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', textAlign: 'center', cursor: 'pointer', gap: '10px' }}>
          <UploadOutlined /> Upload Image
        </p>
      ) : (
          <div style={{marginTop:'10px'}}>
            <DeleteOutlined onClick={(e) => handleRemoveImage(e)} style={{ fontSize: '20px', color: 'red', cursor: 'pointer'}} />
          </div>
      )}
          </Upload>
        </UploadFrame>


      </Right>
    </TotalDiv>
  )
}
export default AddEvent