/* Custom Hook created to trigger the alert messages on the containers */

import { useEffect } from "react";
import { message } from "antd";


const useShowMessage = (showMessage, alertMessageType, alertMessage) => {
  useEffect(() => {
    if (showMessage) {
      if (!alertMessageType) {
        message.error(alertMessage.toString());
        return;
      }
      message.success(alertMessage.toString())
    }
  }, [showMessage, alertMessageType, alertMessage])
};

export default useShowMessage;
