import React from 'react';
import styled from 'styled-components';
import CheckBox from '../../../../components/ReusableComponents/Checkbox/CheckBox';
import filterStar from 'assets/images/generic/rating-filter.svg'

const ReviewFilterBody = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ReviewFilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const StarDiv = styled(ReviewFilterDiv)`
  gap: 2px;
  margin: -4px 0 0 0;
`;



const ReviewFilter = ({filterValues, onStarChange}) => {


  const onCheckBoxChange = (checked, value) => {
    onStarChange(checked, value)
  }


  return (
    <ReviewFilterBody>
      <ReviewFilterDiv>
        <CheckBox checked={filterValues?.rating?.includes(5)} onCheckBoxChange={onCheckBoxChange} value={5}/>
        <StarDiv>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
        </StarDiv>
      </ReviewFilterDiv>
      <ReviewFilterDiv>
        <CheckBox checked={filterValues?.rating?.includes(4)} onCheckBoxChange={onCheckBoxChange} value={4}/>
        <StarDiv>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
        </StarDiv>
      </ReviewFilterDiv>
      <ReviewFilterDiv>
        <CheckBox checked={filterValues?.rating?.includes(3)} onCheckBoxChange={onCheckBoxChange} value={3}/>
        <StarDiv>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
        </StarDiv>
      </ReviewFilterDiv>
      <ReviewFilterDiv>
        <CheckBox checked={filterValues?.rating?.includes(2)} onCheckBoxChange={onCheckBoxChange} value={2}/>
        <StarDiv>
          <img src={filterStar} alt="star rating"/>
          <img src={filterStar} alt="star rating"/>
        </StarDiv>
      </ReviewFilterDiv>
      <ReviewFilterDiv>
        <CheckBox checked={filterValues?.rating?.includes(1)} onCheckBoxChange={onCheckBoxChange} value={1}/>
        <StarDiv>
          <img src={filterStar} alt="star rating"/>
        </StarDiv>
      </ReviewFilterDiv>
    </ReviewFilterBody>
  )
}

export default ReviewFilter;