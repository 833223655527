import React, { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { getUserPortfolio } from '../../appRedux/actions';
import { formatDate, getData } from '../../util/localStorageHelper';
import { PrimaryButton, SecondaryButton } from '../../components/ReusableComponents/Button/Button';
import DatePickerWithFunction from '../../components/ReusableComponents/DatePicker/DatePickerWithFunction';
import { FilterOutlined, DownOutlined, RightOutlined, DownloadOutlined } from '@ant-design/icons';
import RatesAdvisorTable from './RatesAdvisorTable';
import { Tree, Button, Popover, Input } from 'antd';
import { Link } from 'react-router-dom';
import getMarketsClassification from "../../util/getMarketsClassification";
import ClassificationButtons from './ClassificationButtons';
import { currencyAbbreviationFormatter } from '../../util/helper';

import star from 'assets/images/market/quadrants/star.svg';
import gift from 'assets/images/market/quadrants/gift.svg';
import heart from 'assets/images/market/quadrants/heart.svg';
import rain from 'assets/images/market/quadrants/rain.svg';

const RatesAdvisorContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const RatesAdvisorContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TableContainerHeader = styled.div`
  background: #fff; 
  display: flex;
  padding: 16px;
`;

const FilterDiv = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

const FilterButton = styled(SecondaryButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover, &.active {
    background: ${({ theme }) => theme.quibbleBlue};
    color: ${({ theme }) => theme.white};
  }
`;

const DownLoadButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const ClassificationTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  img {
    width: 40px;
    height: 40px;
  }

  h3 {
    margin: 0;
    margin-left: 8px;
    font-size: 21px;
    font-weight: 500;
    line-height: 25.68px;
    text-align: left;

    
    color: ${({ activeFilter }) => {
      switch (activeFilter) {
        case 'underpriced':
          return '#B78EF4';
        case 'overpriced':
          return '#EDB548';
        case 'productIssues':
          return '#F38B64';
        case 'star':
        default:
          return '#00BBD2';
      }
    }};
  }

  p {
    color: #697273;
  }
`;

const IconAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

const DatePickerStyle = css`
  width: 200px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  margin: 0;
  font-family: Commissioner;
`;

const MarketFilter = styled.div`
  width: 350px;
  padding: 16px;
  height: calc(100vh - 165px);
  background: #fff;
  margin-right: 16px;
  display: flex;
  flex-direction: column;

  .ant-tree {
    flex-grow: 1;
    max-height: 90%;
    overflow: auto;
  }

  .ant-tree .ant-tree-treenode {
    padding: 6px;
  }
`;

const ResetButton = styled(SecondaryButton)`
  margin-top: 16px;
  width: 162px;
  height: 44px;
`;

const TextBanner = styled.div`
  background: #fff; 

  p {
    margin: 0 16px 16px;
    border: 1px solid #E8E8E8;
    padding: 16px;
    border-radius: 4px;
    font-family: Commissioner;
    font-size: 16px;
    font-weight: 300;

    color: ${({ activeFilter }) => {
      switch (activeFilter) {
        case 'underpriced':
        case 'star':
          return '#00A638';
        case 'overpriced':
        case 'productIssues':
          return '#EF6AE2';
        default:
          return '#000';
      }
    }};
  }

  span {
    color: #283334;
  }

  a {
    color: #00bbd2;
    cursor: pointer;
  }
`;

const DownloadIconButton = styled(Button)`
  background-color: transparent;
  color: ${({ isActive, theme }) => (isActive ? theme.white : 'inherit')};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.quibbleBlue};
    color: ${({ theme }) => theme.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.quibbleBlue};
    color: ${({ theme }) => theme.white};
  }

  &:focus {
    background-color: ${({ theme }) => theme.quibbleBlue};
    color: ${({ theme }) => theme.white};
  }
`;

const FilenameContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  width: 100%;
`;

const FileExtension = styled.span`
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  user-select: none;
`;

const PopoverContent = styled.div`
  width: 300px;
`;

const PopoverContentButton = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
`;

const DownloadButton = styled(PrimaryButton)`
  flex: 1;
  height: 50px;
`;  

const CancelButton = styled(SecondaryButton)`
  flex: 1;
  height: 50px;
`;  

const worksheetColumns = [{ wch: 30 }, { wch: 25 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

const Index = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [datePickerVal, setDatePickerVal] = useState(moment().local());
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [activeFilter, setActiveFilter] = useState('star');
  const [fileName, setFileName] = useState(`Quickview-${moment().format('MM-YYYY')}`);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const dispatch = useDispatch();
  const { portfolio, isFetchingPortfolio } = useSelector(({ users }) => users);
  const { markets } = portfolio;
  const myPortfolioData = getMarketsClassification(markets);
  const initialMarketTreeDataList = getData('defaultMarketList') || [];

  useEffect(() => {
    dispatch(getUserPortfolio({ month: formatDate(datePickerVal), viewType: 'rmView' }));
  }, [datePickerVal]);

  const onFilterToggle = useCallback(() => {
    setShowFilter((prev) => !prev);
  }, []);

  const handleDatePickerChange = async (value) => {
    const momentValue = moment(value);
    setDatePickerVal(momentValue);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  const switcherIcon = (props) => {
    if (props.expanded) {
      return <DownOutlined />;
    } else {
      return <RightOutlined />;
    }
  };

  const handleReset = () => {
    setCheckedKeys([]);
    setExpandedKeys([]);
  };

  const filterPortfolioData = (data) => {
    if (checkedKeys.length === 0) {
      return data;
    }
    return data.filter(item => checkedKeys.includes(item.city) || checkedKeys.includes(item.id));
  };

  const getFilteredData = () => {
    switch (activeFilter) {
      case 'star':
        return filterPortfolioData(myPortfolioData?.star || []);
      case 'underpriced':
        return filterPortfolioData(myPortfolioData?.underpriced || []);
      case 'overpriced':
        return filterPortfolioData(myPortfolioData?.overpriced || []);
      case 'productIssues':
        return filterPortfolioData(myPortfolioData?.productIssues || []);
      default:
        return [];
    }
  };

  const getClassificationTitle = () => {
    switch (activeFilter) {
      case 'star':
        return {
          title: 'STARS',
          icon: star,
          subtext: 'Higher Occupancy than Market, Higher ADR than prior December',
          bannerText: 'CONSIDER INCREASING DAILY RATES'
        };
      case 'underpriced':
        return {
          title: 'UNDERPRICED',
          icon: gift,
          subtext: 'Higher Occupancy than Market, Higher ADR than prior December',
          bannerText: 'CONSIDER INCREASING DAILY RATES'
        };
      case 'overpriced':
        return {
          title: 'OVERPRICED',
          icon: heart,
          subtext: 'Lower Occupancy than Market, Higher ADR than prior December',
          bannerText: 'CONSIDER REDUCING DAILY RATES'
        };
      case 'productIssues':
        return {
          title: 'PRODUCT ISSUES',
          icon: rain,
          subtext: 'Lower Occupancy than Market, Higher ADR than prior December',
          bannerText: 'CONSIDER REDUCING DAILY RATES'
        };
      default:
        return { title: '', icon: '', subtext: '', bannerText: '' };
    }
  };

  const { title, icon, subtext, bannerText } = getClassificationTitle();

  const convertPayloadToArrayofArray = (values) => {
    const lastYear = moment(datePickerVal).subtract(1, 'year').format('MM/YYYY');
    const thisYear = moment(datePickerVal).format('MM/YYYY');

    const val = values.map((item) => ({
      "PropertyID": item.id || "",
      "Name": item.name || "",
      "City": item.city || "",
      ["Occupancy (" + thisYear + ")"]: `${(item.occupancy * 100).toFixed(0)}%` || "",
      "Market Occupancy": `${(item.marketOccupancy * 100).toFixed(0)}%` || "",
      ["Occupancy (" + lastYear + ")"]: `${(item.lastOccupancy * 100).toFixed(0)}%` || "",
      ["ADR (" + thisYear + ")"]: currencyAbbreviationFormatter(item.adr, 'USD') || "",
      ["ADR (" + lastYear + ")"]: currencyAbbreviationFormatter(item.lastAdr, 'USD') || "",
      ["Revenue (" + thisYear + ")"]: currencyAbbreviationFormatter(item.revenue, 'USD') || "",
      ["Revenue (" + lastYear + ")"]: currencyAbbreviationFormatter(item.lastRevenue, 'USD') || "",
      "Revenue Opportunity": currencyAbbreviationFormatter(item.revOpp, 'USD') || "",
    }));

    return [val, Object.keys(val[0] || {})];
  };

  const exportExcel = () => {
    const starsWorksheet = convertPayloadToArrayofArray(myPortfolioData.star);
    const underpricedWorksheet = convertPayloadToArrayofArray(myPortfolioData.underpriced);
    const overpricedWorksheet = convertPayloadToArrayofArray(myPortfolioData.overpriced);
    const productIssuesWorksheet = convertPayloadToArrayofArray(myPortfolioData.productIssues);

    const worksheet1 = XLSX.utils.json_to_sheet(starsWorksheet[0], { header: starsWorksheet[1] });
    const worksheet2 = XLSX.utils.json_to_sheet(underpricedWorksheet[0], { header: underpricedWorksheet[1] });
    const worksheet3 = XLSX.utils.json_to_sheet(overpricedWorksheet[0], { header: overpricedWorksheet[1] });
    const worksheet4 = XLSX.utils.json_to_sheet(productIssuesWorksheet[0], { header: productIssuesWorksheet[1] });

    worksheet1['!cols'] = worksheetColumns;
    worksheet2['!cols'] = worksheetColumns;
    worksheet3['!cols'] = worksheetColumns;
    worksheet4['!cols'] = worksheetColumns;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Stars");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Underpriced");
    XLSX.utils.book_append_sheet(workbook, worksheet3, "Overpriced");
    XLSX.utils.book_append_sheet(workbook, worksheet4, "Product Issues");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handlePopoverConfirm = () => {
    setPopoverVisible(false);
    exportExcel();
  };

  const downloadPopoverContent = (
    <PopoverContent>
      <FilenameContainer>
        <Input
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Enter file name"
          bordered={false}
          style={{ flex: 1 }}
        />
        <FileExtension>.xls</FileExtension>
      </FilenameContainer>
      <PopoverContentButton>
        <CancelButton onClick={() => setPopoverVisible(false)} style={{ marginRight: 8 }}>
          Cancel
        </CancelButton>
        <DownloadButton onClick={handlePopoverConfirm}>
          Download
        </DownloadButton>
      </PopoverContentButton>
    </PopoverContent>
  );

  return (
    <RatesAdvisorContainer>
      <FilterDiv>
        <FilterButton onClick={onFilterToggle} className={showFilter ? 'active' : ''}>
          <FilterOutlined /> &nbsp; Filter
        </FilterButton>
        <DatePickerWithFunction
          DatepickerStyle={DatePickerStyle}
          value={datePickerVal}
          onChangeFunc={handleDatePickerChange}
          picker="month"
          format="MMMM-YYYY"
        />
        <DownLoadButtonContainer>
          <Popover
            content={downloadPopoverContent}
            title="Filename"
            trigger="click"
            placement="bottomRight"
            arrow="hide"
            open={popoverVisible}
            onOpenChange={setPopoverVisible}
          >
            <DownloadIconButton type="text" shape="circle" isActive={popoverVisible} icon={<DownloadOutlined />} />
          </Popover>
        </DownLoadButtonContainer>
      </FilterDiv>
      <RatesAdvisorContent>
        {showFilter &&
          <MarketFilter>
            <Tree
              checkable
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              treeData={initialMarketTreeDataList}
              defaultExpandAll
              switcherIcon={switcherIcon}
            />
            <ResetButton onClick={handleReset}>Reset</ResetButton>
          </MarketFilter>
        }
        <TableContainer>
          <TableContainerHeader>
            <ClassificationTitle activeFilter={activeFilter}>
              <IconAndTitle>
                {icon && <img src={icon} alt={`${title} Icon`} />}
                <h3>{title}</h3>
              </IconAndTitle>
              <p>{subtext}</p>
            </ClassificationTitle>
            <ClassificationButtons
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              myPortfolioData={myPortfolioData}
            />
          </TableContainerHeader>

          <TextBanner activeFilter={activeFilter}>
            <p>{bannerText}<span> - You can automate rates/price adjustment with <Link to={{ pathname: '/price-adjustment' }}>Dynamic Pricing </Link></span></p>
          </TextBanner>
          <RatesAdvisorTable
            portfolioData={getFilteredData()}
            isFetchingPortfolio={isFetchingPortfolio}
          />
        </TableContainer>
      </RatesAdvisorContent>
    </RatesAdvisorContainer>
  );
};

export default Index;