import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Upload, message, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
// import {  polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";


import { getCountryList, getUser } from '../../../../appRedux/actions';
import { PageTitle, CardCSS } from '../components/CommonCSS';
import { BodyText, BodyTitle } from '../../../../components/ReusableComponents/Text/Text';
import QuibbleLogoMini from '../../../../assets/images/quibble_house.png';
import QuibbleUploadCamera from '../../../../assets/images/profile/uploadCamera.png';
import FormItemInput from '../../../../components/ReusableComponents/Form/FormItemInput';
import { EMAIL_VERIFY } from '../../../../constants/GlobalConstant';
import Selection from '../../../../components/ReusableComponents/Selection/Selection';
import { CountryOptions } from '../../../../util/SelectOptions';
import { PrimaryButton } from '../../../../components/ReusableComponents/Button/Button';
import { ADMIN_ROLE, TRIAL_ROLE, FREEMIUM_ROLE, ANALYTIC_ROLE } from '../../../../constants/GlobalConstant';
import { useParams } from 'react-router-dom';
import { userUpdateProfile } from '../../../../appRedux/actions';
import QuibbleSpinLoader from '../../../../components/ReusableComponents/Loader/QuibbleSpinLoader';
import { CaretDownFilled } from '@ant-design/icons';

// polyfillCountryFlagEmojis();


const {Option} = Select;

const EditProfileBody = styled.div`
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.layoutBg};
  box-sizing: border-box;
  position: relative;
  padding: 0 0 40px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CompanyEditBody = styled.div`
  ${CardCSS};
  width: 900px;
  height: 360px;
  padding: 24px 30px 34px 30px;
  position: relative;
`;

const  CompanyUpload = styled(Upload)`
  width: 120px;
  height: 120px;
  margin: 8px 0 0 0;
  cursor: pointer;
  position: relative;
`;

const CameraUploadImage = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 20px;
`;

const UserProfileBody = styled(CompanyEditBody)`
  height: fit-content;
  margin: 24px 0 0 0;
`;

const UpdateButton = styled(PrimaryButton)`
  width: 200px;
`;

const ButtonDiv = styled.div`
  width: 900px;
  margin: 24px 0 0 0;
  padding-bottom: 24px;
`;

const FlagSpan = styled.span`
 font-size: 16px;
 margin: 0 8px 0 0;
`;

const OptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 0;
`;

const PhoneDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: -24px 0 0 0;
`;

const CodeDiv = styled.div`
  min-width: 100%;
  width: fit-content;
  height: fit-content;
`;

const CountryCode = styled.div`
  height: 48px;
  position: absolute;
  outline: none;
  background: inherit;
  font-size: ${({theme}) => theme.s4 };
  border-right: none;
  border-top: 1px solid #697273;
  border-bottom: 1px solid #697273;
  border-left: none;
  font-weight:400; 
  margin-top: 32px; 
  margin-left: 50px;
  display: flex;
  align-items: center;
`;

const PlaceholderInput = styled(FormItemInput).attrs({
  type: 'tel',
  inputMode: 'numeric',
  pattern: '[0-9]*',
  onKeyPress: (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  },
})`
  position: absolute;
  left: -725px;
  width: calc(100% + 725px); 
  &::placeholder {
    color: #999;
  }
`;

const getBase64 = (img, callback)=> {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const dummyRequest = ({ _, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const beforeUpload = (file)=> {

  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    return Upload.LIST_IGNORE;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 2MB!');
    return Upload.LIST_IGNORE;
  }
  return isJpgOrPng && isLt2M;
};

const roleOptions = (
  <>
    <Option value={ADMIN_ROLE}>Admin</Option>
    <Option value={TRIAL_ROLE}>Trial User</Option>
    <Option value={FREEMIUM_ROLE}>Q-Insights</Option>
    <Option value={ANALYTIC_ROLE}>Q-Insights Pro</Option>
  </>
)


const EditProfile = () => {
  const [profileForm, setProfileForm] = useState({
    brand_logo: {value: null, error: false},
    company: {value: '', error: false},
    first_name: {value: '', error: false},
    last_name: {value: '', error: false},
    email: {value: '', error: false},
    phoneCode: {value: '', error: false},
    phone: {value: '', error: false},
    country: {value: '', error: false},
    address: {value: '', error: false},
    city: {value: '', error: false},
    state: {value: '', error: false},
    zip_code: {value: '', error: false},
    currency: {value: '', error: false},
    role: {value: '', error: false},
  });

  const [companyUpload, setCompanyUpload] = useState({
    uploaded: '',
    loading: false,
  })

  const [updateProfileLoader, setUpdateProfileLoader] = useState({
    loading: true,
    label: 'Loading profile..',
  })

  const [isUpdated, setIsUpdated] = useState(false);
  const [phoneCode, setPhoneCode] = useState([]);
  const [currency, setCurrency] = useState([]);


  const { countryList,  userSelected, authUserDetails, loader } = useSelector(({users}) => users);
  const dispatch = useDispatch();
  const params = useParams();
  const userId = params && params.user_id ? params.user_id : localStorage.getItem("user_id");
  const token  = localStorage.getItem("token");
    

  const {countryPhoneCodeList, countryCurrencyList} = countryList;

  const hasCountryList = !isEmpty(countryList);
  const hasUserSelected = !isEmpty(userSelected);

  useEffect(() => {
    const values = {userId, token};

    if (!hasCountryList) {
      dispatch(getCountryList(values));
    }

    if (!hasUserSelected) {
      dispatch(getUser(values));
    };
  }, [dispatch, hasCountryList, hasUserSelected, userId, token]);

  useEffect(() => {
    if (!isEmpty(userSelected) && !isUpdated) {
      const {brand_logo, company, first_name, last_name, email, phone, phoneCode, country, address, city, state, zip_code, currency, role} = userSelected;

      setProfileForm((prev) => ({
        ...prev,
        brand_logo: {value: brand_logo, error: false},
        company:  {value: company, error: false},
        first_name: {value: first_name, error: false},
        last_name: {value: last_name, error: false},
        email: {value: email, error: false},
        phoneCode: {value: phoneCode, error: false},
        phone: {value: phone, error: false},
        country: {value: country, error: false},
        address: {value: address, error: false},
        city: {value: city, error: false},
        state: {value: state, error: false},
        zip_code: {value: zip_code, error: false},
        currency: {value: currency, error: false},
        role: {value: role, error: false}
      }))
      setUpdateProfileLoader({loading: false, label: null});
    }
  }, [userSelected, isUpdated]);

  useEffect(() => {
    if (countryPhoneCodeList && countryPhoneCodeList.length) {
      const codeList = countryPhoneCodeList.map((code, index) => (
        <Option key={`${code.countryCode} ${index}`} value={`${code.flag} ${code.countryCode} ${code.country} `}>
          <OptionDiv>
            <div style={{display:'flex', gap:'5px'}}>
              <FlagSpan>{`${code.flag}`}</FlagSpan>
              <BodyText>{`${code.country}`}</BodyText>
            </div>
            <BodyText>{`${code.countryCode}`}</BodyText>
          </OptionDiv>
        </Option>
      ));
      const completeArray = [
        ...codeList
      ]
      setPhoneCode(completeArray);
    }
  }, [countryPhoneCodeList]);

  const phoneCodeValue = profileForm.phoneCode.value ? String(profileForm.phoneCode.value) : '🇺🇸 +1';
  const splitPhoneCode = phoneCodeValue.split(' '); 

  const flag =  splitPhoneCode[0] || '';
  const countryCode = splitPhoneCode[1] || '';

  useEffect(() => {
    if (countryCurrencyList && countryCurrencyList.length) {
      const currencyList = countryCurrencyList.map((currency, index) => (
        <Option key={`${currency.label} ${index}`} value={`${currency.label}`}>
          <OptionDiv>
            <BodyText>{`${currency.label}`}</BodyText>
          </OptionDiv>
        </Option>
      ));
      const completeArray = [
        <Option key={`no code`} value='N/A'>
          N/A
        </Option>,
        ...currencyList
      ]
      setCurrency(completeArray);
    }
  }, [countryCurrencyList]);


  const showUploadPhoto = useCallback(() => {
    if (profileForm.brand_logo.value) {
      return (
        <img src={profileForm.brand_logo.value} alt="Brand logo"/>
      );
    }

    return <img src={QuibbleLogoMini} alt="Brand logo" />

  }, [profileForm.brand_logo.value]);


  const uploadLogo = info => {
    if (info.file.status === 'uploading') {
      setCompanyUpload((prev) => ({...prev, loading: true}));
      return;
    }

    // Get this url from response in real world.
    getBase64(info.file.originFileObj, imageUrl => {
      setProfileForm((prev) => ({...prev, brand_logo: {value: imageUrl, error: false}}));
      setCompanyUpload((prev) => ({...prev, uploaded:info.file.originFileObj, loading: false }));
    });
  };

  const onFormChange = (formKey, {value, error}) => {
    setProfileForm((prev) => ({...prev, [formKey]: {value, error}}));
  };

  const onSubmitUpdate = () => {
    try {
      const isSelf = params?.user_id ? false : true;
      const inputtedData = Object.keys(profileForm).reduce((outputData, currentData) => {
        return {
          ...outputData,
          [currentData]: profileForm[currentData].value,
        }
      }, {})

      const dataToSubmit = {
        token: localStorage.getItem("token"),
        user_id: userSelected._id,
        ...userSelected,
        ...inputtedData
      }

      //stringify json first to be added in the blob
      const json = JSON.stringify(dataToSubmit);
      const formdata = new FormData();
      const blob = new Blob([json], {
        type: 'application/json'
      });

      //send both form data and file to the backend together
      formdata.append("document", blob);
      formdata.append("file", companyUpload.uploaded);

      Object.keys(dataToSubmit).forEach((objectKey) => {
        if (dataToSubmit[objectKey] !== undefined ) {
          formdata.append(objectKey, dataToSubmit[objectKey]);
        }
      })

      console.log(formdata);

      dispatch(userUpdateProfile(formdata, isSelf));
      setIsUpdated(true);
      if (countryList?.countryCurrencyNameList) {
        countryList?.countryCurrencyNameList?.forEach(function(data) {
          if(data.value ===dataToSubmit.currency) {
            localStorage.setItem('currencyLabel',data.label);
          }
        });
      }
      message.success("Updated Profile Succesfully.")

    } catch (e) {
      message.error("Error updating profile");
    }

  }

  return (
    <EditProfileBody>
      <PageTitle margin="48px 0px">
        Profile
      </PageTitle>
        {updateProfileLoader.loading || loader ? <CompanyEditBody > <QuibbleSpinLoader loading={true} label={updateProfileLoader.label || 'Updating profile..'} /> </CompanyEditBody> : (
        <>
          <CompanyEditBody>
            <BodyTitle>
              Company
            </BodyTitle>
            <BodyText margin="24px 0 0 0">
              Company Logo
            </BodyText>
            <CompanyUpload
              listType="picture-card"
              showUploadList={false}
              multiple={false}
              customRequest={dummyRequest}
              beforeUpload={beforeUpload} onChange={uploadLogo}>
              {showUploadPhoto()}
              <CameraUploadImage src={QuibbleUploadCamera} />
            </CompanyUpload>
            <FormItemInput formKey="company" value={profileForm.company.value} label="Company Name" onChangeFunc={onFormChange}/>
          </CompanyEditBody>
          <UserProfileBody>
            <BodyTitle>
              User Profile
            </BodyTitle>
            <FormItemInput formKey="first_name" value={profileForm.first_name.value} label="First Name" onChangeFunc={onFormChange}/>
            <FormItemInput formKey="last_name" value={profileForm.last_name.value} label="Last Name" onChangeFunc={onFormChange}/>
            <FormItemInput formKey="email" value={profileForm.email.value} label="Email" onChangeFunc={onFormChange} formItemRules={{ patternCheck: {pattern: EMAIL_VERIFY, errorMessage:'Please enter valid email address.'}}} />
            <BodyText margin="24px 0 0 0"> Phone Number </BodyText>
            <PhoneDiv>
              <CodeDiv>
                <Selection formKey="phoneCode" label="" value={flag} onChangeFunc={onFormChange} jsxOptions={phoneCode} otherStyles={'border-radius: 4px 0 0 4px; border-right:none;'}/>
                <CaretDownFilled style={{position:'absolute', top:'425px', left:'60px', cursor: 'pointer'}} />
              </CodeDiv>
              <CountryCode >{countryCode}</CountryCode>
              <PlaceholderInput formKey="phone" value={profileForm.phone.value} label="" onChangeFunc={onFormChange} placeholder="(201) 555-555" otherStyles={'border-radius: 0 4px 4px 0; border-left: none;'}/>
            </PhoneDiv>
            <Selection formKey='country' label='Country' value={profileForm.country.value} onChangeFunc={onFormChange} jsxOptions={CountryOptions}/>
            <FormItemInput formKey="address" value={profileForm.address.value} label="Address" onChangeFunc={onFormChange} />
            <FormItemInput formKey="city" value={profileForm.city.value} label="City" onChangeFunc={onFormChange} />
            <FormItemInput formKey="state" value={profileForm.state.value} label="State/Province" onChangeFunc={onFormChange} />
            <FormItemInput formKey="zip_code" value={profileForm.zip_code.value} label="Postal Code" onChangeFunc={onFormChange} />
          </UserProfileBody>
          <UserProfileBody>
            <BodyTitle>
              Currency Settings
            </BodyTitle>
            <Selection formKey='currency' label='Currency' value={profileForm.currency.value} onChangeFunc={onFormChange} jsxOptions={currency}/>
          </UserProfileBody>
          {
             authUserDetails?.role === "admin" && (
              <UserProfileBody>
                <BodyTitle>
                  Admin Settings
                </BodyTitle>
                <Selection formKey='role' label='Role' value={profileForm.role.value} onChangeFunc={onFormChange} jsxOptions={roleOptions}/>
              </UserProfileBody>
             )
          }
          <ButtonDiv>
            <UpdateButton onClick={onSubmitUpdate}>
              Update
            </UpdateButton>
          </ButtonDiv>
        </>
      )}

    </EditProfileBody>
  )
};


export default EditProfile;
