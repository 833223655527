import React from 'react';
import styled, { useTheme } from 'styled-components';
import { BodyCSS } from 'components/ReusableComponents/Card-Body/BodyCSS';
import ChartContainer from '../../../../../main/analytics/PortfolioAnalyticsV2/components/ChartContainer';
import { BodyText, BodyTitle } from 'components/ReusableComponents/Text/Text';
import { currencyFormatter } from '../../../../../../util/helper';
import { PropertyMenuDiv } from '../../CommonCSS';
import SkLoaderOver from '../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver';
import { useSelector } from 'react-redux';

const PropertyOverviewDiv = styled(PropertyMenuDiv)`
  display: flex;
  flex-direction: column;
`;

const MetricBody = styled.div`
  width: 100%;
  gap: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 34px 0 0 0;
`;

const MetricDiv = styled.div`
  width: 416px;
  height: 180px;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${BodyCSS}
`;

const MetricTitle = styled(BodyText)`
  color: ${({theme}) => theme.quibbleBackgroundGrey};
`;

const MetricValue = styled(BodyTitle)`
  margin: 8px 0 0 0;
  font-size: 2rem;
  line-height: 100%;
  color: ${({metricColor}) => metricColor};
`;

const ForecastDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const ProjectedDiv = styled.div`
  width: fit-content;
  height: 30px;
  margin: 8px 0 0 0;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Commissioner;
  font-size: 16px;
  color: ${({theme}) => theme.quibbleBlue};
  font-weight: 300;

  border: 1px solid ${({theme}) => theme.quibbleBlue};
  border-style: dotted;
`;

const GoalDiv = styled(ProjectedDiv)`
  color: #EDB548;
  border: 1px solid #EDB548;
  border-style: dotted;
`;


const PropertyOverview = ({monthYear, propertyId}) => {

  const theme = useTheme();

  const { isFetchingPropertyOverviewMetrics, propertyOverviewMetrics } = useSelector(({ property }) => property);

  const {forecast, metric, percentage, goalPlan} = propertyOverviewMetrics;

  const year = monthYear.getFullYear();
  const currency = localStorage.getItem('currency');

  const defaultIntParser = (o, value) => parseInt(o[value]?.toFixed(0) || 0);

  const totalRevenueChart =    {
    title: "Total Revenue",
    metric: "revenue",
    propertyId,
    yLabelLeft: "Revenue",
    domain: [0, dataMax => Math.round((dataMax * 1.25) / 100) * 100],
    formatText:true,
    hideSameStoreFilter: true,
    revenueFormatter: function (number) {
      if (number >= 1000000) {
        return currency + (Math.round(number / 1000000)) + 'M';
      } else if(number >=1000 && number < 1000000){
        return currency + (Math.round(number / 1000)) + 'K';
      }
      else {
        return currency + number.toString();
      }
    },
    bars: [
      {
        dataKey: (o) => defaultIntParser(o, 'lastYearTotalRevenue'),
        fill: "#C6A1F5",
      },
      {
        dataKey: (o) => defaultIntParser(o, 'currentYearTotalRevenue'),
        fill: "#2ECADB",
        stackId: "stackRevenue"
      },
      {
        dataKey: (o) => parseInt((o.forecastRevenue - o.currentYearTotalRevenue) || 0, 0),
        fill: "#99E7EE",
        stackId: "stackRevenue"
      },
    ],
    labels: [
      {
        image: "chart_rectangle_purple",
        label: year - 1,
      },
      {
        image: "chart_rectangle_teal",
        label: year,
      },
      {
        image: "chart_rectangle_forecast",
        label: "Forecast",
      }
    ],
    tooltip: [
      {
        data: [
          {
            key: "Forecast",
            valueIndex: 2,
            prefix: currency,
            forecastKey: "forecastRevenue",
          },
          {
            key: year,
            valueIndex: 1,
            prefix: currency,
          },
          {
            key: year - 1,
            valueIndex: 0,
            prefix: currency,
          }
        ]
      }
    ]
  };

  return (
    <PropertyOverviewDiv>
      <BodyTitle>
        Q-Insights
      </BodyTitle>
      <MetricBody>
        <MetricDiv>
          <MetricTitle>
            Revenue
          </MetricTitle>
          <MetricValue metricColor={theme.quibbleBlue}>
            {metric?.totalRevenue ? currencyFormatter(metric.totalRevenue) : '--'}
          </MetricValue>
          <MetricTitle margin="8px 0 0 0">
            {percentage?.revenue ? `${(percentage?.revenue * 100).toFixed(0)}%` : '--'}
          </MetricTitle>
          <ForecastDiv>
            <ProjectedDiv>
              Projected: {forecast?.revenue ? currencyFormatter(forecast.revenue) : '--'}
            </ProjectedDiv>
           {goalPlan?.revenue ? <GoalDiv>
              Goal: { currencyFormatter(goalPlan.revenue)}
            </GoalDiv> : null}
          </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
        <MetricDiv>
          <MetricTitle>
            Total Rent
          </MetricTitle>
          <MetricValue metricColor='#FA6DFD'>
            {metric?.totalRent ? currencyFormatter(metric.totalRent) : '--'}
          </MetricValue>
          <MetricTitle margin="8px 0 0 0">
            {percentage?.rent ? `${(percentage?.rent * 100).toFixed(0)}%` : '--'}
          </MetricTitle>
          <ForecastDiv>
            <ProjectedDiv>
              Projected: {forecast?.rent ? currencyFormatter(forecast.rent) : '--'}
            </ProjectedDiv>
            {goalPlan?.rent ? <GoalDiv>
              Goal: {currencyFormatter(goalPlan.rent)}
            </GoalDiv> : null}
          </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
        <MetricDiv>
          <MetricTitle>
              ADR
            </MetricTitle>
            <MetricValue metricColor={theme.quibblePurple}>
              {metric?.adr ? currencyFormatter(metric.adr) : '--'}
            </MetricValue>
            <MetricTitle margin="8px 0 0 0">
              {percentage?.adr ? `${(percentage?.adr * 100).toFixed(0)}%` : '--'}
            </MetricTitle>
            <ForecastDiv>
              <ProjectedDiv>
                Projected: {forecast?.adr ? currencyFormatter(forecast.adr) : '--'}
              </ProjectedDiv>
              {goalPlan?.adr ? <GoalDiv>
                Goal: { currencyFormatter(goalPlan.adr)}
              </GoalDiv> : null}
            </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
        <MetricDiv>
          <MetricTitle>
            Occupancy
          </MetricTitle>
          <MetricValue metricColor={theme.quibbleYellow}>
            {metric?.occ ? `${(metric.occ * 100).toFixed(0)}%` : '--'}
          </MetricValue>
          <MetricTitle margin="8px 0 0 0">
            {percentage?.occ ? `${(percentage?.occ * 100).toFixed(0)}%` : '--'}
          </MetricTitle>
          <ForecastDiv>
            <ProjectedDiv>
              Projected: {forecast?.occ ? `${(forecast.occ * 100).toFixed(0)}%` : '--'}
            </ProjectedDiv>
            {goalPlan?.occupancy ? <GoalDiv>
                Goal:  { `${(goalPlan.occupancy * 100).toFixed(0)}%`}
            </GoalDiv> : null}
          </ForecastDiv>
          <SkLoaderOver isLoading={isFetchingPropertyOverviewMetrics} />
        </MetricDiv>
      </MetricBody>
      <ChartContainer {...totalRevenueChart} year={year} columns={1}/>
    </PropertyOverviewDiv>
  )
}

export default PropertyOverview;
