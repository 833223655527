import React, {memo} from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { BodyText } from '../Text/Text';


const FormDiv = styled.div`
  margin: 24px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CountrySelect = styled(Select)`
  width: 100%;
  height: 48px;
  outline: none;
  border: none;
  background: inherit;
  font-size: ${({theme}) => theme.s4};
  border-radius: 4px;
  border: 1px solid #697273;
  font-weight:400; 
  ${({selectionstyles}) => selectionstyles};

  .ant-select-selector {
    background-color: inherit !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    border: none !important;
    box-shadow: none !important;
    display: flex;

  }

  .ant-select-selection-item {
    background: none !important;
    color: inherit !important;
    border: none !important;
    font-weight: inherit !important;
    display: flex;
    align-items: center;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: inherit !important;
    color: inherit !important;
    font-weight: inherit !important;
    border: none !important;
    display: flex;
  }

  .ant-select-focused .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    border: none !important;
    display: flex;

  }

  .ant-select-selection-search-input {
    color: white;
    font-size: ${({theme}) => theme.s4};
    margin: ${({margin}) => margin ? margin : '4px 0px'};
    font-family: Commissioner;
    font-weight: 300;
  }

`;


const CountrySelection = ({formKey, onChangeFunc=()=>{}, label='', value=null, jsxOptions=<></>, options=[], otherStyles, ...otherProps}) => {
 

  const handleSelectChange = (selectedValues) => {
    const latestValue = selectedValues.length > 0 ? [selectedValues[selectedValues.length - 1]] : [];
    onChangeFunc(formKey, { value: latestValue, error: false });
  };

  const customTagRender = ({ value }) => {
    return (
      <div>
        {value} 
      </div>
    );
  };



  return (
    <FormDiv>
      <BodyText>{label}</BodyText>
      <CountrySelect value={value} mode="tags" maxTagCount={1} tagRender={customTagRender} onChange={handleSelectChange} options={options.length ? options : null} selectionstyles={otherStyles} {...otherProps} maxTagTextLength={1}>
        {jsxOptions}
      </CountrySelect>
    </FormDiv>
  )
};

export default memo(CountrySelection);
