import styled from 'styled-components';

import { useSelector } from "react-redux";

import MHMapHeader from './components/MHMapHeader';
import MHChartsHeader from './components/MHChartsHeader';

import RentalType from './components/MHChartsComponents/RentalType';
import RentalChannel from './components/MHChartsComponents/RentalChannel';
import AttributeRatings from './components/MHChartsComponents/AttributeRatings';
import RatingsChart from './components/MHChartsComponents/RatingsChart';
import RentalSize from './components/MHChartsComponents/RentalSize';
import Occupancy from './components/MHChartsComponents/Occupancy';
import LeadingHosts from './components/MHChartsComponents/LeadingHosts';
import Amenities from './components/MHChartsComponents/Amenities';
import RentalSettings from './components/MHChartsComponents/RentalSettings';
import MHMap from './components/MHMapComponents/MHMap';

const MHContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  max-height: calc(100vh - 120px);
`;

const MHMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  padding-right: 5px;
`;

const MHMapBody = styled.div`
  flex-grow: 1;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
`;

const MHMapBodyTitle = styled.div`
  color: #697273;
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  flex: 1;
`;

const MMHMapBodyAmount = styled.div`
  width: 100%;
  display: flex;
  flex: 1;

  margin-top: 16px;
`;

const HMMapBodyMarketAmount = styled.div`
  display: flex;
  flex: 1;

  color: #C6A1F5;
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const HMMapBodyPortfolioAmount = styled.div`
  display: flex;
  flex: 1;

  color: #1BB3CD;
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const MHMapCardsContainer = styled.div`
  display: flex;
`;

const MHGoogleMapContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

const MHMapCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  flex: 1;
  margin: 16px 0;

  min-height: 110px;

  border-radius: 6px;
  background: #FFF;

  /* Shadow Bottom */
  box-shadow: 0px 2px 18px 0px rgba(145, 165, 169, 0.20);

  :not(:last-child) {
    margin-right: 10px;
  }
`;

const MHChartsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 100%;
`;

const MHChartsBody = styled.div`
  flex-grow: 1;
  width: 100%;
`;

// UTILS
const processMonetaryValue = (num) => {
  let ret = 0;
  ret = num && '$' + num.toLocaleString(undefined, { maximumFractionDigits: 0 });
  return ret;
}

// UTILS
const processPercentageValue = (num) => {
  let ret = null;
  if (num){
    ret = num * 100;
    ret = ret.toFixed(0) + '%'
  }
  return ret;
}

const MarketHunter = () => {

  const {
    marketHunterCityDetails,
  } = useSelector(({ businessTools }) => businessTools);
  const {
    adm,
    amenities,
    attributeRatings,
    leadingHosts,
    listings,
    adr,
    occupancyRate,
    totalRevenue,
    rentalType,
    rentalChannel,
    ratingsChart,
    totalActiveListings,
    rentalSize,
    occupancy,
    averageBedrooms,
    averageGuests,
  } = marketHunterCityDetails;

  const hasIsUserListing = listings.some(item => "isUserListing" in item);

  const isEmptyPortfolio = !hasIsUserListing;

  return (
    <MHContainer>
      <MHMapContainer>
        <MHMapHeader/>
        <MHMapBody>
          <MHMapCardsContainer>
            <MHMapCard>
              <MHMapBodyTitle>
                Total Revenue
              </MHMapBodyTitle>
              <MMHMapBodyAmount>
                <HMMapBodyMarketAmount>
                  {totalRevenue && processMonetaryValue(totalRevenue.market)}
                </HMMapBodyMarketAmount>
                <HMMapBodyPortfolioAmount>
                  {totalRevenue && processMonetaryValue(totalRevenue.portfolio)}
                </HMMapBodyPortfolioAmount>
              </MMHMapBodyAmount>
            </MHMapCard>

            <MHMapCard>
              <MHMapBodyTitle>
                Average Daily Rate
              </MHMapBodyTitle>
              <MMHMapBodyAmount>
                <HMMapBodyMarketAmount>
                  {adr && processMonetaryValue(adr.market)}
                </HMMapBodyMarketAmount>
                <HMMapBodyPortfolioAmount>
                  {adr && processMonetaryValue(adr.portfolio)}
                </HMMapBodyPortfolioAmount>
              </MMHMapBodyAmount>
            </MHMapCard>

            <MHMapCard>
              <MHMapBodyTitle>
                Occupancy Rate
              </MHMapBodyTitle>
              <MMHMapBodyAmount>
                <HMMapBodyMarketAmount>
                  {occupancyRate && processPercentageValue(occupancyRate.market)}
                </HMMapBodyMarketAmount>
                <HMMapBodyPortfolioAmount>
                  {occupancyRate && processPercentageValue(occupancyRate.portfolio)}
                </HMMapBodyPortfolioAmount>
              </MMHMapBodyAmount>
            </MHMapCard>
          </MHMapCardsContainer>
          <MHGoogleMapContainer>
            <MHMap listings={listings} adm={adm} />
          </MHGoogleMapContainer>
        </MHMapBody>
      </MHMapContainer>
      <MHChartsContainer>
        <MHChartsHeader data={totalActiveListings} />
        <MHChartsBody>
          <div>
            <RentalType data={rentalType} isEmptyPortfolio={isEmptyPortfolio} />
            <RentalChannel data={rentalChannel} homeRentalPercentage={rentalType?.entireHome?.portfolio.share} />
            <RentalSize averageGuests={averageGuests} averageBedrooms={averageBedrooms} data={rentalSize} isEmptyPortfolio={isEmptyPortfolio} />
            <Occupancy data={occupancy} isEmptyPortfolio={isEmptyPortfolio} />
            <AttributeRatings data={attributeRatings} isEmptyPortfolio={isEmptyPortfolio} />
            <RatingsChart data={ratingsChart} />
            <LeadingHosts data={leadingHosts} />
            <Amenities data={amenities} isEmptyPortfolio={isEmptyPortfolio} />
            <RentalSettings isEmptyPortfolio={isEmptyPortfolio} />
          </div>
        </MHChartsBody>
      </MHChartsContainer>
    </MHContainer>
  );
}

export default MarketHunter;