import React, {useEffect, useState, Suspense} from "react";
import {useHistory} from "react-router-dom";
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  updateUserPMS
} from "../appRedux/actions";

import SignupCard from "../components/SignupCard";
import AuthLogo from "../components/AuthLogo";
import AuthLoader from "../components/AuthLoader";
import useShowMessage from "../customHooks/useShowMessage";


const GuestyWithToken = React.lazy(() => import('../components/PMSConnect/GuestyWithToken'));
const GuestyWithClientId = React.lazy(() => import('../components/PMSConnect/Guesty/GuestyWithClientID'));
const Hostaway = React.lazy(() => import('../components/PMSConnect/Hostaway/Hostaway'));
const OwnerRez = React.lazy(() => import('../components/PMSConnect/OwnerRez'));
const MyVR = React.lazy(() => import('../components/PMSConnect/MyVR'));
const Hostfully = React.lazy(() => import('../components/PMSConnect/Hostfully'));
const Lodgify = React.lazy(() => import('../components/PMSConnect/Lodgify'));
const AirBnB = React.lazy(() => import('../components/PMSConnect/AirBnB'));


const Option = Select.Option;


const PmsDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser,alertMessageType} = useSelector(({auth}) => auth);

  const [type, setType] = useState('');
  const [useToken, setUsetToken] = useState(true);

  const queryString = require('query-string');
  const parsed = queryString.parse(props.location.search);
  localStorage.setItem('string', parsed.string);
  useShowMessage(showMessage, alertMessageType, alertMessage);

  useEffect(() => {
    if(showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
      if (alertMessageType === true) history.push('/finishsignup');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showMessage, alertMessageType]);

  useEffect(() => {
    /** Check user loggedin or not  */
    let token = localStorage.getItem('token');
    if (token && authUser) {
      history.push('/main/dashboard/listing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authUser]);

  const handleChange = (value) => {
    setType(value);
  }


  const onSubmitForm = (values) => {
   // return  console.log(values)
    dispatch(showAuthLoader());
    dispatch(updateUserPMS({
      ...values,
      pms: type,
      type,
      string: parsed.string,
    }));
  }

  const changeUseToken = () => {
    setUsetToken((prev) => !prev);
  }

  const PMSForms = {
    'Guesty': (useToken &&  <GuestyWithToken onSubmitForm={onSubmitForm} changeUseToken={changeUseToken} />) || <GuestyWithClientId onSubmitForm={onSubmitForm} changeUseToken={changeUseToken}/>,
    'Hostaway': <Hostaway onSubmitForm={onSubmitForm}/>,
    'MyVR': <MyVR onSubmitForm={onSubmitForm} signupString={parsed.string} />,
    'Hostfully': <Hostfully onSubmitForm={onSubmitForm} signupString={parsed.string} />,
    'Lodgify': <Lodgify onSubmitForm={onSubmitForm} signupString={parsed.string} />,
    'OwnerRez': <OwnerRez onSubmitForm={onSubmitForm} type={type} signupString={parsed.string} />,
    'AirBnB': <AirBnB onSubmitForm={onSubmitForm} type={type} signupString={parsed.string} />,
  }

  return (
    <div className="app-signin-wrap signup">
      <SignupCard current={2} title="PMS Authorization" />
      <div className="app-signin-container pms">
        <div className="app-signin-form-container pms">
          <h1 className="app-text signin-headertext">
                Select your PMS
          </h1>
          <h1 className="app-text signin-logotext verification">
            Select from the list of available Property Management System Integrations below
          </h1>
          <Select
            onChange={handleChange}
            placeholder="Select PMS"
            className="app-select"
          >
            <Option value="Guesty">Guesty</Option>
            {/*<Option value="AirBnB">AirBnB</Option>*/}
            <Option value="Hostfully">Hostfully</Option>
            <Option value="Hostaway">HostAway</Option>
            <Option value="MyVR">MyVR</Option>
            <Option value="OwnerRez">OwnerRez</Option>
            <Option value="Lodgify" >Lodgify</Option>
            <Option value="Beds24" disabled>Beds24</Option>
            <Option value="FantasticStay" disabled>FantasticStay</Option>
            <Option value="Guesty for Host" disabled>Guesty for Host (YP)</Option>

            <Option value="Kigo" disabled>Kigo</Option>

            <Option value="Harmonics" disabled>Res:Harmonics</Option>
            <Option value="Streamline" disabled>Streamline</Option>
            <Option value="Track PMS" disabled>Track PMS</Option>
          </Select>

          <Suspense fallback={<AuthLoader loader={loader} />}>
            {PMSForms[type]}
          </Suspense>

          <AuthLoader loader={loader} />
        </div>
        <AuthLogo />
      </div>
    </div>
  );
};


export default PmsDetail;
